import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { LabOfferingDetail } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestForUpdateLabOrder,
  resetLabUpDateState,
  searchingPartnerLabOrders,
} from 'redux/collectionCenter/partnerLabOrderSearch/partnerLabOrderSearch'
import { RootState } from 'redux/rootReducer'
import { availablePaymentOptions } from 'utils/constants'
import { getOrderTypeCode } from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getDocumentReferencesStrings,
  getTotalAmountOfLabOfferings,
  getTotalAmountOfPartnerLabOfferings,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { OrderAmountSummaryTile } from 'views/components/generalOrder/ordarAmountSummary'
import { OrderTimeLine } from 'views/components/lab/orderDetails/orderTimeLine'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { PatientTile } from 'views/components/patients/pateintTile'
import { WelloFormItemLabel, WelloTextField } from 'wello-web-components'
import { PartnerLabOrderAmount } from '../generalOrder/partnerLabOrderAmount'
import { RestrictionsOfSelectedOfferings } from '../lab/common/restriction_detail'
import { OrderAppointmentDetails } from '../lab/orderDetails/orderAppointmentDetails'

interface Props {
  open: boolean
  locationId: string
  percentage: number
  onClose: () => void
  onLabOrderCreated: () => void
  labOrderDetail: FhirLabOrderDetail
}
export const UpdatePartnerLabOrderHandler: React.FC<Props> = ({
  open,
  onClose,
  onLabOrderCreated,
  locationId,
  percentage,
  labOrderDetail,
}) => {
  const partnerLabOrderSearchSlice = useSelector(
    (state: RootState) => state.partnerLabOrderSearchSlice
  )

  const downloadDocumentSlice = useSelector(
    (state: RootState) => state.downloadDocumentSlice
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [selectedPatient, setSelectedPatient] = useState<R4.IPatient>(
    labOrderDetail.patient
  )
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(false)

  const [selectedLabOfferings, setSelectedLabOfferings] = useState<
    LabOfferingDetail[] | undefined
  >(labOrderDetail.tests)

  const orderType = getOrderTypeCode(labOrderDetail) ?? ''
  const type = orderType.includes('onsite-sample-collection')
    ? 'onsite-collection'
    : 'home_collection'
  const [sampleCollectionPoint, setSampleCollectionPoint] =
    useState<string>(type)
  const [selectedAddress, setSelectedAddress] = useState<R4.IAddress>()
  const [selectedSlot, setSelectedSlot] = useState<FhirSlotDetail>()
  const [commission, setCommission] = useState<number>(
    getTotalAmountOfPartnerLabOfferings(
      labOrderDetail.tests ?? [],
      percentage,
      labOrderDetail.partnerLabTask
    )
  )

  useEffect(() => {
    if (partnerLabOrderSearchSlice.orderDetailsUpdated) {
      if (onLabOrderCreated) {
        onLabOrderCreated()
        resetForm()
      }
    }
  }, [partnerLabOrderSearchSlice, onLabOrderCreated])
  const reports = getDocumentReferencesStrings(labOrderDetail.partnerLabTask)

  function isEnableCreateButton(): boolean | undefined {
    if (sampleCollectionPoint === 'home_collection') {
      return (
        selectedPatient &&
        selectedLabOfferings &&
        selectedLabOfferings.length > 0
      )
    }
    return (
      selectedPatient && selectedLabOfferings && selectedLabOfferings.length > 0
    )
  }

  function resetForm() {
    // setSelectedPatient(undefined)
    // setSelectedLabOfferings(undefined)
    // setSelectedAddress(undefined)
    // setIsPatientEditMode(true)
    dispatch(resetLabUpDateState())
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth={
        selectedLabOfferings && selectedLabOfferings.length > 0 ? 'md' : 'sm'
      }
      disableBackdropClick={true}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        Edit Order Transaction #{labOrderDetail.serviceRequest.id}
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box width='100%' px={1}>
          <Card
            elevation={0}
            style={{
              padding: 0,
              margin: 0,
              borderRadius: 4,
              backgroundColor: kDialogueBackground,
            }}
          >
            <CardContent
              style={{
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                border: 1,
              }}
            >
              <Box display='flex' flexDirection='column' width='100%'>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='60%'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Patient Information' />
                        <Box
                          border={1}
                          borderRadius={4}
                          borderColor='lightGrey'
                        >
                          <PatientTile patient={labOrderDetail.patient} />
                        </Box>
                      </Box>
                    </Box>

                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Appointment Details' />

                        <OrderAppointmentDetails
                          labOrderDetail={labOrderDetail}
                          showAddress={true}
                          showDate={true}
                        />
                      </Box>
                    </Box>

                    <Box display='flex' flexDirection='row' width='100%'>
                      <RestrictionsOfSelectedOfferings
                        selectedOfferings={labOrderDetail.tests!}
                      />
                    </Box>

                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Order Summary' />
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          //   marginY={1}
                          border={1}
                          borderRadius={4}
                          borderColor='lightGrey'
                        >
                          <OrderAmountSummaryTile
                            planDefinitions={labOrderDetail.tests}
                            showTitle={false}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    width='40%'
                    display='flex'
                    flexDirection='column'
                    px={2}
                    alignContent='center'
                  >
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Payment Received Details' />
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          border={1}
                          borderRadius={4}
                          borderColor='lightGrey'
                        >
                          <PartnerLabOrderAmount
                            labOrderDetails={labOrderDetail}
                            showTitle={true}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      py={0.4}
                    >
                      <WelloSelectFHIRCoding
                        title='Payment Made To Partner In'
                        id='payment_type'
                        availableCodings={availablePaymentOptions}
                        onChanged={(tt) => {}}
                        preSelectedCoding={availablePaymentOptions[0]}
                        textFieldProps={{
                          size: 'small',
                          fullWidth: true,
                        }}
                      />
                    </Box>

                    <Box display='flex' flexDirection='column' width='100%'>
                      {/* <WelloFormItemLabel title='Payment To Partner (IN INR)' /> */}
                      <WelloTextField
                        title='Payment To Partner (IN INR)'
                        //   value=lab.labName
                        textProps={{
                          id: `actor_email_unit`,
                          value: commission,
                          type: 'number',
                          inputProps: {
                            max: 100,
                            // min:0
                          },
                          error:
                            hasErrors &&
                            (commission < 0 ||
                              commission >
                                getTotalAmountOfLabOfferings(
                                  labOrderDetail.tests ?? []
                                )),
                          helperText:
                            hasErrors &&
                            (commission < 0 ||
                              commission >
                                getTotalAmountOfLabOfferings(
                                  labOrderDetail.tests ?? []
                                ))
                              ? `Commission between 1 to ${getTotalAmountOfLabOfferings(
                                  labOrderDetail.tests ?? []
                                )}`
                              : '',
                          onChange: (changeCommission: any) => {
                            setCommission(
                              parseInt(changeCommission.target.value, 10)
                            )
                            //   handleEmailChange(changeCommission)
                          },
                        }}
                      />
                    </Box>

                    {labOrderDetail.statuses &&
                      labOrderDetail.statuses.length > 0 && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='start'
                        >
                          <WelloFormItemLabel title='Order Status' />
                        </Box>
                      )}

                    {labOrderDetail.statuses &&
                      labOrderDetail.statuses.length > 0 && (
                        <OrderTimeLine labOrderDetail={labOrderDetail} />
                      )}
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        {partnerLabOrderSearchSlice.updatingOrderDetail && (
          <CircularProgress size={30} />
        )}
        <Button
          onClick={() => {
            onClose()
            resetForm()
            dispatch(searchingPartnerLabOrders(locationId))
          }}
          variant='outlined'
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (selectedLabOfferings && selectedPatient) {
              dispatch(
                requestForUpdateLabOrder(
                  labOrderDetail,
                  locationId,
                  selectedLabOfferings,
                  selectedPatient,
                  sampleCollectionPoint,
                  commission,
                  selectedAddress,
                  selectedSlot
                )
              )
            }
          }}
          variant='contained'
          color='primary'
          disableElevation
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}
