import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { RootState } from 'redux/rootReducer'
import { LabDiagnosticTabularWithReports } from '../assessment/labTestForPlanPageTabular'
import { LabDiagnosticTabular } from '../assessment/labTestTabularTile'
import { LabDiagnosticRequestTile } from './labDiagnosticTile'

interface Props {
  fhirAppointmentDetails: FhirAppointmentDetail
  followUp?: boolean
}

export const LabDiagnosticRequestsList: React.FC<Props> = ({
  fhirAppointmentDetails,
  followUp,
}: Props) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const labDiagnosticsListSlice = useSelector(
    (state: RootState) => state.labDiagnosticsListSlice
  )

  useEffect(() => {
    dispatch(getLabRequestsOfAppointment(fhirAppointmentDetails, followUp))
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(labDiagnosticsListSlice.updatingImpressions ||
        labDiagnosticsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}

      {/* {labDiagnosticsListSlice.noResultsAvailable && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            No data available
          </Typography>
        </Box>
      )} */}
      {labDiagnosticsListSlice.lanDiagnosticRequests &&
        labDiagnosticsListSlice.lanDiagnosticRequests.length > 0 && (
          <Box display='flex' flexDirection='column' width='100%'>
            <LabDiagnosticTabularWithReports
              referrals={labDiagnosticsListSlice.lanDiagnosticRequests}
              type='referrals'
            />

            {/* {labDiagnosticsListSlice.serviceRequest.map(
              (e: R4.IServiceRequest) => (
                <Box key={e.id ?? ''}>
                  <LabDiagnosticRequestTile
                    referrals={e}
                    key={`${e.id ?? 't'}_${e.id ?? 's'}`}
                  />
                </Box>
              )
            )} */}
          </Box>
        )}
      {/* {labDiagnosticsListSlice.tasks &&
        labDiagnosticsListSlice.tasks.length > 0 && (
          <Box marginX={2} display='flex' flexDirection='column'>
            {labDiagnosticsListSlice.tasks.map((e: R4.ITask) => (
              <Box key={e.id ?? ''}>
                {e.contained &&
                  e.contained.length > 0 &&
                  e.contained.map((test) => (
                    <LabDiagnosticRequestTile
                      medication={test as R4.IPlanDefinition}
                      key={`${(test as R4.IPlanDefinition).id ?? 't'}_${'s'}`}
                    />
                  ))}
              </Box>
            ))}
          </Box>
        )} */}
    </Box>
  )
}
