import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Tooltip,
  Link,
  Grid,
  Divider,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { requestDietHistoryOfPatient } from 'redux/ipd/patientMedicalHistory/dietHistory/dietHistorySlice'
import { requestFamilyConditionHistoryOfPatient } from 'redux/patientMedicalHistory/familyConditionHistory/familyMedicalConditionsHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasAddedObsBySameDoc,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getAddButoonShow,
  getObservationCodeText,
  getObservationContent,
  getRelationFromFamilyResource,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  HistoryObjectiveIndicative,
  SOAPIndicativeElement,
} from 'wello-web-components'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { WelloSubjective } from 'models/WelloConditions'
import _ from 'lodash'
import { getValueRefValueExtensionsOfUrl } from 'utils/fhirResourcesHelper'
import { AddDietOPD } from './diet/AddDietOPD'
import { HistoryTabularFormatTile } from '../historyTabularFormatTile'
import { EditDietOPD } from '../../edition/editDietOPD'

interface DietProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  status: string
  splitview: boolean
}

export const DietOPD: React.FC<DietProps> = ({
  fhirAppointmentDetails,
  status,
  splitview,
}: DietProps) => {
  const { t } = useTranslation(['common'])
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const dietHistorySlice = useSelector(
    (state: RootState) => state.dietHistorySlice
  )
  const [currentCondition, setCurrentCondition] = useState<WelloSubjective>({
    id: _.random(1, 10000000).toString(),
  })

  const [showEdit, setShowEdit] = useState<boolean>(false)

  function createUpdateConditionData(conditionData: R4.IObservation) {
    setCurrentCondition({
      ...currentCondition,
      condition: conditionData.valueCodeableConcept
        ? conditionData.valueCodeableConcept.coding &&
          conditionData.valueCodeableConcept.coding!.length > 0
          ? conditionData.valueCodeableConcept.coding[0]
          : undefined
        : undefined,

      existingObs: conditionData,
      notes: getNotesString(conditionData.note),
    })
  }
  useEffect(() => {
    dispatch(
      requestDietHistoryOfPatient('', fhirAppointmentDetails.patient, splitview)
    )
  }, [dispatch, fhirAppointmentDetails])
  return (
    <>
      <Box display='flex' flexDirection='column' flexGrow width='100%'>
        <Box display='flex' flexDirection='row' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Diet</Typography>
          </Box>
          {/* {isMedicalServiceProvider() && status !== 'completed' && !splitview && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
        {dietHistorySlice.noResultsAvailable && (
          <Box>
            {isMedicalServiceProvider() &&
              status !== 'completed' &&
              !splitview && (
                <Box
                  display='flex'
                  flexGrow
                  flexDirection='row'
                  justifyContent='flex-start'
                  paddingBottom={1}
                >
                  <Tooltip title='' id='diet_1'>
                    <IconButton
                      aria-label='btn_ord_cancel'
                      color='primary'
                      id='diet_2'
                      onClick={() => {
                        setOpenAddDialogue(true)
                      }}
                      style={{ padding: 0 }}
                    >
                      <Typography
                        variant='subtitle2'
                        color='primary'
                        component={Link}
                        style={{ fontSize: 13 }}
                        id='diet_4'
                      >
                        {' '}
                        Diet
                      </Typography>
                      <AddCircleIcon
                        style={{ height: '22px', padding: 0 }}
                        color='primary'
                        id='diet_3'
                      />{' '}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
          </Box>
        )}

        {dietHistorySlice.resultsAvailable && dietHistorySlice.groupedObs && (
          <Box>
            {isMedicalServiceProvider() &&
              status !== 'completed' &&
              !splitview &&
              getAddButoonShow(
                dietHistorySlice.groupedObs ?? [],
                fhirAppointmentDetails.appointment.id!
              ) === false && (
                <Box
                  display='flex'
                  flexGrow
                  flexDirection='row'
                  justifyContent='flex-start'
                  paddingBottom={1}
                >
                  <Tooltip title='' id='diet_1'>
                    <IconButton
                      aria-label='btn_ord_cancel'
                      color='primary'
                      id='diet_2'
                      onClick={() => {
                        setOpenAddDialogue(true)
                      }}
                      style={{ padding: 0 }}
                    >
                      <AddCircleIcon
                        style={{ height: '16px', padding: 0 }}
                        color='primary'
                        id='diet_3'
                      />{' '}
                      <Typography
                        variant='subtitle2'
                        color='primary'
                        component={Link}
                        style={{ fontSize: 13 }}
                        id='diet_4'
                      >
                        {' '}
                        Diet
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
          </Box>
        )}

        {dietHistorySlice.noResultsAvailable && (
          <Box display='flex' flexDirection='row' flexGrow width='100%'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {' '}
              {!isMedicalServiceProvider() ||
              status === 'completed' ||
              splitview
                ? 'No data available'
                : ''}{' '}
            </Typography>
          </Box>
        )}

        {dietHistorySlice.searchingConditions && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <CircularProgress size={15} />
          </Box>
        )}
        {dietHistorySlice.errorWhileSearchingProcedures && (
          <Box display='flex' flexDirection='row' flexGrow width='100%'>
            <Typography
              variant='subtitle2'
              color='error'
              style={{
                fontWeight: 400,
              }}
            >
              Error while fetching diet
            </Typography>
          </Box>
        )}

        {dietHistorySlice.resultsAvailable && dietHistorySlice.groupedObs && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%' flexGrow>
              {dietHistorySlice.groupedObs.map((val) => (
                <Box py={1} key={val.date ?? ''}>
                  <Grid container direction='row' id='vitals'>
                    <Grid item xs={12}>
                      <Box
                        paddingX={1}
                        border={1}
                        style={{
                          backgroundColor: 'lightGrey',
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '2px 0px',
                          borderRadius: '1px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Recorded on{' '}
                            {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                          </Typography>
                        </Box>
                        {hasAddedObsBySameDoc(val.occupation[0]) &&
                          getValueRefValueExtensionsOfUrl(
                            val.occupation[0].extension ?? [],
                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                          ).split('/')[1] ===
                            fhirAppointmentDetails.appointment.id! &&
                          splitview === false && (
                            <Box
                              display='flex'
                              justifyContent='flex-end'
                              paddingLeft={1}
                              paddingTop={1}
                              paddingRight={2}
                            >
                              <Tooltip title='Edit' id='chief_tool_edit'>
                                <IconButton
                                  aria-label='btn_ord_cancel'
                                  color='primary'
                                  id='diet_tool_edit_button'
                                  style={{ padding: 0 }}
                                  onClick={() => {
                                    createUpdateConditionData(val.occupation[0])
                                    setShowEdit(true)
                                  }}
                                >
                                  <img
                                    id='chief_tool_edit_img'
                                    src={`${process.env.PUBLIC_URL}/editVector.png`}
                                    alt='Edit'
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                  <HistoryTabularFormatTile
                    obsData={val.occupation}
                    type='Diet'
                    onEditClicked={(obs: R4.IObservation) => {
                      createUpdateConditionData(obs)
                      setShowEdit(true)
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>

      <AddDietOPD
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onDietAdd={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestDietHistoryOfPatient(
              '',
              fhirAppointmentDetails.patient,
              false
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />

      <EditDietOPD
        open={showEdit}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onDietAdd={() => {
          setShowEdit(false)
          dispatch(
            requestDietHistoryOfPatient(
              '',
              fhirAppointmentDetails.patient,
              false
            )
          )
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingDiet={currentCondition}
      />

      {/* <AddMedicalConditionHandler
            open={openAddDialogue}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onMedicalConditionAdded={(condition) => {
              setOpenAddDialogue(false)
              dispatch(
                requestConditionHistoryOfPatient(
                  fhirAppointmentDetails.appointment.id!,
                  fhirAppointmentDetails.patient
                )
              )
            }}
            onClose={() => {
              setOpenAddDialogue(false)
            }}
          /> */}
    </>
  )
}
