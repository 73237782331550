import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Tooltip,
  Typography,
  Divider,
  Collapse,
  Grid,
} from '@material-ui/core'
import {
  DeleteOutlineOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Close,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { kDialogueBackground, kPrimaryMain } from 'configs/styles/muiThemes'
import {
  AssociatedComplaintData,
  ChiefComplaintData,
  ChiefComplaintTerminology,
} from 'models/chiefComplaintData'
import { ChiefComplaintsData } from 'models/chiefComplaintsData'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { LocationData } from 'models/location'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  ConfirmationDialogProps,
  ConfirmationDialog,
} from 'views/components/common/deleteConfirmationDialog'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  getChiefComplaintOfAppointment,
  resetChiefComplaintDetails,
  updateChiefComplaintDetails,
} from 'redux/consultation/chiefComplaintSlice/chiefComplaintSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { durationData, statusCodings } from 'utils/constants'
import {
  getAppointmentIdForFollowUp,
  getCodeValueForChiefComplaints,
  getStringDays,
  getStringMonth,
  getStringValueExtensionsOfUrl,
  getStringValueExtensionsOfUrlForDuration,
  getStringValueExtensionsOfUrlForDurationVal,
  getStringWeeks,
  getStringYear,
  getUnitAsString,
  getUnitAsStringForVal,
  getValueCodingFromExtension,
  getValueRefValueExtensionsOfUrl,
  removeExtensionFromExtensions,
  titleCase,
} from 'utils/fhirResourcesHelper'
import {
  getNotesFromAllergy,
  hasNotes,
  hasNotesForChief,
  hasNotesForChiefForNote,
  hasNotesForChiefForPatient,
  hasNotesForChiefForPatientText,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { logger } from 'utils/logger'
import {
  getChiefComplaintOfFollowUpAppointment,
  resetChiefComplaintDetailsFollowUp,
} from 'redux/consultation/followUpChiefComplaint/followUpChiefComplaintSlice'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateConditionDetailsFollowup } from 'utils/fhirResoureHelpers/appointmentHelpers'
import OverflowTypography from '../plan/ayurveda/overflowTypography'

interface ChiefComplaintProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const FollowUpChiefComplaint: React.FC<ChiefComplaintProps> = ({
  fhirAppointmentDetails,
  split,
}: ChiefComplaintProps) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null)

  const [t] = useTranslation(['labelCommon', 'labelCondition'])
  const dispatch = useDispatch()

  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [chiefDataArrayData, setChiefDataArrayData] = useState<
    ChiefComplaintsData[]
  >([])

  const [assiChiefDataArrayData, setAssiChiefDataArrayData] = useState<
    ChiefComplaintsData[]
  >([])
  const [selectedSeverity, setSeverity] = useState<R4.ICoding>()
  const [selectedLocation, setLocations] = useState<R4.ICoding[]>([])
  const chiefComplaintSlice = useSelector(
    (state: RootState) => state.followUpChiefComplaintSlice
  )
  const [duration, setDuration] = React.useState<string>('')
  const [character, setCharacter] = React.useState<string>('')
  const [alleviating, setAlleviating] = React.useState<string>('')
  const [radiation, setRadiation] = React.useState<string>('')
  const [temporal, setTemporal] = React.useState<string>('')
  const [lastUpdated, setLastUpdated] = React.useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [addChiefComplaints, setAddChiefComplaints] = useState<boolean>(false)
  const [editChiefComplaints, setEditChiefComplaints] = useState<boolean>(false)
  const [chiefComplaintsDetails, setChiefComplaintsDetails] =
    React.useState<ChiefComplaintsData>()

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  function updateConditions(
    condition: R4.ICondition,
    statusCoding: R4.ICoding
  ) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.ICondition = {
      ...condition,
    }

    if (oldProcedure.extension) {
      const codingData = getValueCodingFromExtension(
        oldProcedure.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
      )
      if (codingData) {
        oldProcedure.extension =
          removeExtensionFromExtensions(
            oldProcedure.extension,
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
          ) ?? []
      }
      oldProcedure.extension.push({
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext',
        valueCoding: statusCoding,
      })
    } else {
      oldProcedure.extension = [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext',
          valueCoding: statusCoding,
        },
      ]
    }
    setLoading(true)

    updateConditionDetailsFollowup(
      oldProcedure,
      getAppointmentIdForFollowUp(
        fhirAppointmentDetails.appointment.supportingInformation ?? []
      )
    ).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(
          showSuccessAlert(
            'Chief Complaint status details updated successfully'
          )
        )
        setChiefDataArrayData([])
        dispatch(resetChiefComplaintDetailsFollowUp())
        dispatch(
          getChiefComplaintOfFollowUpAppointment(
            fhirAppointmentDetails,
            split,
            true
          )
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Chief Complaint details. Please try again later'
          )
        )
      }
    })
  }

  useEffect(() => {
    dispatch(
      getChiefComplaintOfFollowUpAppointment(
        fhirAppointmentDetails,
        split,
        true
      )
    )
  }, [dispatch, fhirAppointmentDetails])
  useEffect(() => {
    if (chiefComplaintSlice.updatedConditions) {
      dispatch(resetChiefComplaintDetailsFollowUp())
      dispatch(
        getChiefComplaintOfFollowUpAppointment(
          fhirAppointmentDetails,
          split,
          true
        )
      )
    }
  }, [dispatch, chiefComplaintSlice, fhirAppointmentDetails])
  useEffect(() => {
    if (
      chiefComplaintSlice.resultsAvailable &&
      chiefComplaintSlice.chiefCondition
    ) {
      updateChiefComplaint(
        chiefComplaintSlice.chiefCondition,
        chiefComplaintSlice.associatedCondition ?? []
      )
    }
    if (chiefComplaintSlice.noResultsAvailable) {
      setChiefDataArrayData([])
      setAssiChiefDataArrayData([])
    }
  }, [chiefComplaintSlice])

  function handleCollapse(rate: boolean, index: number) {
    const values: ChiefComplaintsData[] = [...chiefDataArrayData]
    values[index].collapsed = rate
    setChiefDataArrayData(values)
  }

  const updateChiefComplaint = (
    condition: R4.ICondition[],
    associated: R4.ICondition[]
  ) => {
    const chiefDataArray: ChiefComplaintsData[] = []

    for (let i = 0; i < condition.length; i++) {
      const assichiefDataArray: AssociatedComplaintData[] = []
      const locationArr: LocationData[] = []
      const evidenceArr: ChiefComplaintData[] = []
      const conditionData: R4.ICondition = condition[i]
      let locationData: R4.ICoding[] = []
      let evidenceData: R4.ICoding[] = []

      if (
        conditionData.bodySite?.length &&
        conditionData.bodySite?.length > 0
      ) {
        const location: R4.ICoding[] = conditionData.bodySite?.map(
          (val) => val.coding?.[0] ?? {}
        )
        locationData = location
        logger.info('inside use updateChiefComplaint', location)
        setLocations(location)
      }

      if (conditionData.evidence && conditionData.evidence.length > 0) {
        if (
          conditionData.evidence[0].code &&
          conditionData.evidence[0].code.length > 0
        ) {
          const eviArr: R4.ICoding[] = []
          for (let m = 0; m < conditionData.evidence.length; m++) {
            const evi: R4.ICoding[] | undefined = conditionData.evidence[
              m
            ].code?.map((val) => val.coding?.[0] ?? {})

            if (evi) {
              eviArr.push(evi[0])
            }

            evidenceData = eviArr
          }
        }
      }

      if (associated.length > 0) {
        const filteredData = associated.filter(
          (d) =>
            getValueRefValueExtensionsOfUrl(
              d.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-chief-complaint-ext'
            )?.split('/')[1]! === conditionData.id!
        )

        if (filteredData.length > 0) {
          for (let j = 0; j < filteredData.length; j++) {
            const conditionDataAssociated: R4.ICondition = filteredData[j]
            if (conditionDataAssociated.id !== undefined) {
              const additional: AssociatedComplaintData = {
                id: 0,
                typeData: conditionDataAssociated.onsetAge
                  ? getUnitAsStringForVal(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    )
                  : conditionDataAssociated.onsetDateTime
                  ? 'custom'
                  : '',
                cui: conditionDataAssociated.code?.coding?.[0],
                preselectedAssociated:
                  conditionDataAssociated.code as ChiefComplaintTerminology,
                days: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Days'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                weeks: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Weeks'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                months: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Months'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                year: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Years'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                date: conditionDataAssociated.onsetDateTime
                  ? moment(conditionDataAssociated.onsetDateTime).toDate()
                  : new Date(),
                preselectedId: conditionDataAssociated.id ?? '',
                patientWording: conditionDataAssociated.note
                  ? hasNotesForChiefForPatientText(
                      conditionDataAssociated.note!
                    ).trim()
                  : '',
                anyOther: false,
                anyOtherData: '',
                checked: false,
              }

              assichiefDataArray.push(additional)
            }
          }
        }
      }
      if (evidenceData.length > 0) {
        for (let j = 0; j < evidenceData.length; j++) {
          const evi = {
            cui: evidenceData[j].code ?? '',

            type: '',
            icd: evidenceData[j].display ?? '',
            synonyms: evidenceData[j].display ?? '',
          }
          evidenceArr.push(evi)
        }
      }

      if (locationData.length > 0) {
        for (let j = 0; j < locationData.length; j++) {
          const loc = {
            cui: '',
            snomed_ct: '',
            display: locationData[j].display ?? '',
          }
          locationArr.push(loc)
        }
      }

      if (conditionData.severity) {
        setSeverity(conditionData.severity.coding?.[0])
      }
      if (conditionData.onsetDateTime) {
        setSelectedDate(moment(conditionData.onsetDateTime).toDate())
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
        )
      ) {
        setAlleviating(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
          )!
        )
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
        )
      ) {
        setCharacter(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
          )!
        )
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
        )
      ) {
        setRadiation(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
          )!
        )
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
        )
      ) {
        setTemporal(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
          )!
        )
      }

      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
        )
      ) {
        setDuration(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
          )!
        )
      }
      if (conditionData.meta) {
        setLastUpdated(
          moment(conditionData.meta.lastUpdated ?? '').format('Do MMMM, YYYY')
        )
      }
      const cat: string =
        conditionData.category && conditionData.category[0].coding
          ? conditionData.category[0].coding[0].code ?? ''
          : ''
      if (cat !== 'associated-complaint') {
        chiefDataArray.push({
          chiefCondition: conditionData,
          followUPComplint: getValueCodingFromExtension(
            conditionData.extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
          ),
          preselectedComplaints:
            conditionData.code as ChiefComplaintTerminology,
          collapsed: false,
          preselectedSymptoms:
            conditionData.evidence && conditionData.evidence.length > 0
              ? conditionData.evidence[0].code!
              : [],
          preSelectedLocation: locationArr,
          preSelectedDuration: {
            cui:
              getStringValueExtensionsOfUrl(
                conditionData.extension,
                'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
              ) ?? ''.length > 0
                ? getCodeValueForChiefComplaints(
                    conditionData.extension,
                    'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
                  ) ?? ''
                : '',
            snomed_ct: 'http://unitsofmeasure.org',
            display:
              getStringValueExtensionsOfUrl(
                conditionData.extension,
                'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
              ) ?? '',
            label:
              getStringValueExtensionsOfUrl(
                conditionData.extension,
                'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
              ) ?? '',
          },
          id: conditionData.id ?? '',
          selectedCondition: conditionData.code as ChiefComplaintTerminology,
          selectedSeverity: conditionData.severity
            ? conditionData.severity.coding?.[0]
            : undefined,
          selectedLocation: locationData,
          duration:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            ) ?? '',
          character: getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
          )!,
          alleviating: getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
          )!,
          radiation: getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
          )!,
          temporal:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
            ) ?? '',
          lastUpdated: conditionData.meta
            ? moment(conditionData.meta.lastUpdated ?? '').format(
                'Do MMMM, YYYY'
              )
            : '',
          selectedDate: conditionData.onsetDateTime
            ? moment(conditionData.onsetDateTime).toDate()
            : null,
          itemNumber: i + 1,
          notes: hasNotesForChiefForNote(conditionData.note!),
          patientFeeling: hasNotesForChiefForPatient(conditionData.note!),
          category:
            conditionData.category && conditionData.category[0].coding
              ? conditionData.category[0].coding[0].code ?? ''
              : '',
          evidenceData:
            conditionData.evidence && conditionData.evidence.length > 0
              ? conditionData.evidence[0].code!
              : [],
          selectedEvidence: evidenceData,
          preselectedVal: conditionData.onsetAge
            ? conditionData.onsetAge.value ?? 0
            : 0,
          preSelectedUnitType: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '')
            : conditionData.onsetDateTime
            ? 'custom'
            : '',
          unitValForDays: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Days'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitValForMonth: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Months'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitValForYear: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Years'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitValForWeek: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Weeks'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitTypeDays: conditionData.onsetAge
            ? getUnitAsStringForVal(conditionData.onsetAge.unit ?? '')
            : conditionData.onsetDateTime
            ? 'custom'
            : '',
          associatedCondition: assichiefDataArray,
          painChecked:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
            )!.length > 0 ||
            evidenceArr.length > 0,
          patientWording: conditionData.note
            ? hasNotesForChiefForPatientText(conditionData.note!).trim()
            : '',
          associatedChecked: assichiefDataArray.length > 0,
          painDuration: parseInt(
            getStringValueExtensionsOfUrlForDurationVal(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            ) ?? '1',
            10
          ),
          notesData: hasNotesForChiefForNote(conditionData.note!)
            ? hasNotesForChiefForNote(conditionData.note!)?.text ?? ''
            : '',
        })
      }
      setChiefDataArrayData(chiefDataArray)
      //   setAssiChiefDataArrayData(assichiefDataArray)
    }
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box display='flex' flexDirection='column' width='100%' flexGrow>
        <Box display='flex' width='100%' flexGrow flexDirection='row' py={0.5}>
          <ReactVisibilitySensor
            offset={{
              top: 100,
              bottom: 30,
            }}
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(
                  setSelectedSection({ selectedSection: 'chief_complaint' })
                )
              }
            }}
          >
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              id='chief_complaint'
              flexGrow
            >
              <Box
                display='flex'
                width='100%'
                flexGrow
                flexDirection='row'
                py={0.5}
              >
                <Box display='flex' flexDirection='column'>
                  <Box flexGrow={1} py={1.5}>
                    <Typography variant='subtitle1'>
                      {' '}
                      {/* {t('labelCondition:attr_labels.Condition.code')}{' '} */}
                      Previous Appointment {t('labelCommon:Chief Complaint')}
                    </Typography>
                  </Box>
                  <Box>
                    {(chiefComplaintSlice.updatingConditions ||
                      chiefComplaintSlice.searchingConditions) && (
                      <CircularProgress size={15} />
                    )}
                  </Box>
                </Box>
              </Box>
              {chiefComplaintSlice.noResultsAvailable && (
                <Box>
                  <Typography
                    variant='subtitle2'
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    No Data Available
                  </Typography>
                </Box>
              )}
              {chiefComplaintSlice.resultsAvailable &&
                chiefComplaintSlice.chiefCondition && (
                  <Box>
                    {chiefDataArrayData.map((val, index: number) => (
                      <Box key={`med_cond${val.id}`} width='100%' py={1}>
                        <Box>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Recorded on {val.lastUpdated}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title='Edit'>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapse(!val.collapsed, index)
                                    }}
                                  >
                                    {val.collapsed && <ArrowDropUpOutlined />}
                                    {!val.collapsed && (
                                      <ArrowDropDownOutlined />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.collapsed}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              border={2}
                              style={{
                                boxShadow: '0px 0px 4px #ccc',
                                margin: '4px 0px',
                                borderRadius: '4px',
                                borderColor: '#F8F8F8',
                                width: '100%',
                                height: 'auto',
                                backgroundColor: '#F8F8F8',
                              }}
                              py={0.2}
                            >
                              <Grid container>
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='Column'
                                    width='100%'
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,

                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Chief Complaints
                                          </Typography>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={2.5}
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,

                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Mapped Symptoms
                                          </Typography>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        {val.associatedCondition &&
                                          val.associatedCondition.length >
                                            0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              {val.associatedCondition.map(
                                                (
                                                  valCondition,
                                                  index1: number
                                                ) => (
                                                  <Box
                                                    key={`med_cond${val.id}`}
                                                    width='100%'
                                                  >
                                                    <Box
                                                      justifyContent='flex-start'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                      px={1}
                                                    >
                                                      <Typography
                                                        variant='subtitle2'
                                                        color='initial'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 12,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        Associated Complaints
                                                      </Typography>
                                                    </Box>

                                                    <Divider
                                                      orientation='horizontal'
                                                      flexItem
                                                      style={{
                                                        height: '2px',
                                                      }}
                                                    />
                                                    <Box
                                                      justifyContent='flex-start'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                      px={2.5}
                                                      style={{
                                                        backgroundColor:
                                                          kDialogueBackground,
                                                      }}
                                                    >
                                                      <Typography
                                                        variant='subtitle2'
                                                        color='initial'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 12,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        Mapped Symptoms
                                                      </Typography>
                                                    </Box>

                                                    <Divider
                                                      orientation='horizontal'
                                                      flexItem
                                                      style={{
                                                        height: '2px',
                                                      }}
                                                    />
                                                  </Box>
                                                )
                                              )}
                                            </Box>
                                          )}

                                        {val.selectedLocation &&
                                          val.selectedLocation.length > 0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Pain Profile : Pain Area ?
                                                </Typography>
                                              </Box>

                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                        {val.character.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Describe what the patient is
                                                experiencing?
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.alleviating.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              alignItems='center'
                                              height={40}
                                              px={1}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t(
                                                  'labelCommon:allegatingFactors'
                                                )}{' '}
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}

                                        {val.radiation.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              alignItems='center'
                                              height={40}
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t('labelCommon:radiations')}
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}

                                        {val.evidenceData &&
                                          val.evidenceData.length > 0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                alignItems='center'
                                                minHeight={30}
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Associated Symptoms
                                                </Typography>
                                              </Box>

                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                        {val.temporal.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={40}
                                              alignItems='center'
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t('labelCommon:pattern')}
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.selectedSeverity && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t(
                                                  'labelCondition:attr_labels.Condition.severity'
                                                )}{' '}
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.notes && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Additional Notes
                                              </Typography>
                                            </Box>

                                            {/* <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          /> */}
                                          </Box>
                                        )}
                                      </Box>

                                      <Box
                                        display='flex'
                                        flexGrow={1}
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: 'auto',
                                            color: 'red',
                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item xs={9}>
                                  <Box
                                    display='flex'
                                    flexDirection='Column'
                                    width='100%'
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        {/*     Main Start  */}
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexDirection='row'
                                          flexGrow
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                          width='100%'
                                        >
                                          <Box width='50%'>
                                            {val.patientFeeling &&
                                              val.patientFeeling.text &&
                                              val.patientFeeling.text.length >
                                                0 && (
                                                <OverflowTypography
                                                  text={
                                                    val.patientFeeling.text ??
                                                    ''
                                                  }
                                                  typographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'initial',
                                                  }}
                                                />
                                              )}
                                          </Box>
                                          <Box display='flex' width='2%'>
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '40px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={40}
                                            alignItems='center'
                                            px={1}
                                            width='15%'
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Current Status
                                            </Typography>
                                          </Box>
                                          <Box display='flex' width='2%'>
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '40px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                          <Box width='30%' py={0.2}>
                                            <WelloSelectFHIRCoding
                                              id='edit'
                                              availableCodings={statusCodings}
                                              onChanged={(type) => {
                                                if (
                                                  type &&
                                                  val.chiefCondition
                                                ) {
                                                  updateConditions(
                                                    val.chiefCondition,
                                                    type
                                                  )
                                                }
                                              }}
                                              textFieldProps={{
                                                size: 'small',
                                                fullWidth: true,
                                              }}
                                              preSelectedCoding={
                                                val.followUPComplint
                                              }
                                              disabled={split}
                                            />
                                          </Box>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        {/*     Main end  */}

                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            width='40%'
                                          >
                                            {val.selectedCondition &&
                                              val.selectedCondition.text && (
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  flexGrow
                                                  overflow='auto'
                                                >
                                                  <Box>
                                                    <Typography
                                                      variant='subtitle2'
                                                      color='primary'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {titleCase(
                                                        val.selectedCondition
                                                          .text
                                                      )}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              )}
                                          </Box>
                                          <Box display='flex' width='2%'>
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '30px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            width='29%'
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Duration / Start Date
                                            </Typography>
                                          </Box>
                                          <Box display='flex' width='2%'>
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '30px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            width='27%'
                                          >
                                            {val.selectedDate && (
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {moment(
                                                  val.selectedDate
                                                ).format('Do MMMM, YYYY')}
                                              </Typography>
                                            )}
                                            {val.unitValForDays > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForDays}{' '}
                                                {getStringDays(
                                                  val.unitValForDays
                                                )}
                                              </Typography>
                                            )}
                                            {val.unitValForWeek > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForWeek}{' '}
                                                {getStringWeeks(
                                                  val.unitValForWeek
                                                )}
                                              </Typography>
                                            )}
                                            {val.unitValForMonth > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForMonth}{' '}
                                                {getStringMonth(
                                                  val.unitValForMonth
                                                )}
                                              </Typography>
                                            )}
                                            {val.unitValForYear > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForYear}{' '}
                                                {getStringYear(
                                                  val.unitValForYear
                                                )}
                                              </Typography>
                                            )}
                                          </Box>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />

                                        {/*     Associated Start  */}
                                        {val.associatedCondition &&
                                          val.associatedCondition.length >
                                            0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              {val.associatedCondition.map(
                                                (
                                                  valCondition,
                                                  index1: number
                                                ) => (
                                                  <Box
                                                    key={`med_cond${val.id}`}
                                                    width='100%'
                                                  >
                                                    <Box
                                                      justifyContent='flex-start'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                      px={1}
                                                    >
                                                      {valCondition.patientWording && (
                                                        <Box
                                                          display='flex'
                                                          flexDirection='column'
                                                          width='100%'
                                                          flexGrow
                                                        >
                                                          <Box width='100%'>
                                                            {hasSpaces(
                                                              valCondition.patientWording ??
                                                                ''
                                                            ) && (
                                                              <Typography
                                                                variant='subtitle2'
                                                                color='primary'
                                                                style={{
                                                                  wordWrap:
                                                                    'break-word',
                                                                  whiteSpace:
                                                                    'pre-line',
                                                                  textOverflow:
                                                                    'ellipsis',
                                                                  fontFamily:
                                                                    'Open Sans',
                                                                  fontSize: 12,

                                                                  fontWeight: 600,
                                                                  lineHeight:
                                                                    'normal',
                                                                  // paddingLeft: '16px',
                                                                }}
                                                              >
                                                                {valCondition.patientWording ??
                                                                  ''
                                                                    .split('\n')
                                                                    .map(
                                                                      (
                                                                        i: any,
                                                                        key: any
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            i
                                                                          }
                                                                        >
                                                                          {i}
                                                                        </div>
                                                                      )
                                                                    )}
                                                              </Typography>
                                                            )}
                                                            {hasSpaces(
                                                              valCondition.patientWording ??
                                                                ''
                                                            ) === false && (
                                                              <Typography
                                                                variant='subtitle2'
                                                                color='primary'
                                                                style={{
                                                                  wordWrap:
                                                                    'break-word',
                                                                  fontFamily:
                                                                    'Open Sans',
                                                                  fontSize: 12,

                                                                  fontWeight: 600,
                                                                  lineHeight:
                                                                    'normal',
                                                                  // paddingLeft: '16px',
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    inlineSize: 500,
                                                                    overflowWrap:
                                                                      'break-word',
                                                                  }}
                                                                >
                                                                  {valCondition.patientWording ??
                                                                    ''.trim()}
                                                                </div>
                                                              </Typography>
                                                            )}
                                                          </Box>
                                                        </Box>
                                                      )}
                                                    </Box>

                                                    <Divider
                                                      orientation='horizontal'
                                                      flexItem
                                                      style={{
                                                        height: '2px',
                                                      }}
                                                    />
                                                    <Box
                                                      display='flex'
                                                      flexDirection='row'
                                                      width='100%'
                                                      style={{
                                                        backgroundColor:
                                                          kDialogueBackground,
                                                      }}
                                                    >
                                                      <Box
                                                        justifyContent='flex-start'
                                                        display='flex'
                                                        flexGrow
                                                        height={30}
                                                        alignItems='center'
                                                        px={1}
                                                        width='40%'
                                                      >
                                                        {valCondition.cui &&
                                                          valCondition.cui
                                                            .display && (
                                                            <Box
                                                              display='flex'
                                                              flexDirection='column'
                                                              width='100%'
                                                              flexGrow
                                                              overflow='auto'
                                                            >
                                                              <Box>
                                                                <Typography
                                                                  variant='subtitle2'
                                                                  color='primary'
                                                                  style={{
                                                                    fontFamily:
                                                                      'Open Sans',
                                                                    fontSize: 12,

                                                                    fontWeight: 600,
                                                                    lineHeight:
                                                                      'normal',
                                                                  }}
                                                                >
                                                                  {titleCase(
                                                                    valCondition
                                                                      .cui
                                                                      .display
                                                                  )}
                                                                </Typography>
                                                              </Box>
                                                            </Box>
                                                          )}
                                                      </Box>
                                                      <Box
                                                        display='flex'
                                                        width='2%'
                                                      >
                                                        <Divider
                                                          orientation='vertical'
                                                          flexItem
                                                          style={{
                                                            height: '30px',

                                                            width: '2px',
                                                          }}
                                                        />
                                                      </Box>
                                                      <Box
                                                        justifyContent='flex-start'
                                                        display='flex'
                                                        flexGrow
                                                        height={30}
                                                        alignItems='center'
                                                        px={1}
                                                        width='29%'
                                                      >
                                                        <Typography
                                                          variant='subtitle2'
                                                          color='initial'
                                                          style={{
                                                            fontFamily:
                                                              'Open Sans',
                                                            fontSize: 12,

                                                            fontWeight: 600,
                                                            lineHeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          Duration / Start Date
                                                        </Typography>
                                                      </Box>
                                                      <Box
                                                        display='flex'
                                                        width='2%'
                                                      >
                                                        <Divider
                                                          orientation='vertical'
                                                          flexItem
                                                          style={{
                                                            height: '30px',

                                                            width: '2px',
                                                          }}
                                                        />
                                                      </Box>
                                                      <Box
                                                        justifyContent='flex-start'
                                                        display='flex'
                                                        flexGrow
                                                        height={30}
                                                        alignItems='center'
                                                        px={1}
                                                        width='27%'
                                                      >
                                                        {valCondition.cui &&
                                                          valCondition.typeData ===
                                                            'custom' &&
                                                          valCondition.date && (
                                                            <Typography
                                                              variant='subtitle2'
                                                              color='primary'
                                                              style={{
                                                                fontFamily:
                                                                  'Open Sans',
                                                                fontSize: 12,

                                                                fontWeight: 600,
                                                                lineHeight:
                                                                  'normal',
                                                              }}
                                                            >
                                                              {moment(
                                                                valCondition.date
                                                              ).format(
                                                                'Do MMMM, YYYY'
                                                              )}
                                                            </Typography>
                                                          )}
                                                        {valCondition.days >
                                                          0 && (
                                                          <Typography
                                                            variant='subtitle2'
                                                            color='primary'
                                                            style={{
                                                              fontFamily:
                                                                'Open Sans',
                                                              fontSize: 12,

                                                              fontWeight: 600,
                                                              lineHeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            {valCondition.days}{' '}
                                                            {getStringDays(
                                                              valCondition.days
                                                            )}
                                                          </Typography>
                                                        )}
                                                        {valCondition.weeks >
                                                          0 && (
                                                          <Typography
                                                            variant='subtitle2'
                                                            color='primary'
                                                            style={{
                                                              fontFamily:
                                                                'Open Sans',
                                                              fontSize: 12,

                                                              fontWeight: 600,
                                                              lineHeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            {valCondition.weeks}{' '}
                                                            {getStringWeeks(
                                                              valCondition.weeks
                                                            )}
                                                          </Typography>
                                                        )}
                                                        {valCondition.months >
                                                          0 && (
                                                          <Typography
                                                            variant='subtitle2'
                                                            color='primary'
                                                            style={{
                                                              fontFamily:
                                                                'Open Sans',
                                                              fontSize: 12,

                                                              fontWeight: 600,
                                                              lineHeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            {
                                                              valCondition.months
                                                            }{' '}
                                                            {getStringMonth(
                                                              valCondition.months
                                                            )}
                                                          </Typography>
                                                        )}
                                                        {valCondition.year >
                                                          0 && (
                                                          <Typography
                                                            variant='subtitle2'
                                                            color='primary'
                                                            style={{
                                                              fontFamily:
                                                                'Open Sans',
                                                              fontSize: 12,

                                                              fontWeight: 600,
                                                              lineHeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            {valCondition.year}{' '}
                                                            {getStringYear(
                                                              valCondition.year
                                                            )}
                                                          </Typography>
                                                        )}
                                                      </Box>
                                                    </Box>
                                                    <Divider
                                                      orientation='horizontal'
                                                      flexItem
                                                      style={{
                                                        height: '2px',
                                                      }}
                                                    />
                                                    {/*     Associated end  */}
                                                  </Box>
                                                )
                                              )}
                                            </Box>
                                          )}

                                        {val.selectedLocation &&
                                          val.selectedLocation.length > 0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                                width='40%'
                                              >
                                                {val.selectedLocation &&
                                                  val.selectedLocation.length >
                                                    0 && (
                                                    <Box
                                                      display='flex'
                                                      flexDirection='column'
                                                      width='100%'
                                                      flexGrow
                                                      overflow='auto'
                                                    >
                                                      <Box>
                                                        <Typography
                                                          variant='subtitle2'
                                                          color='primary'
                                                          style={{
                                                            fontFamily:
                                                              'Open Sans',
                                                            fontSize: 12,

                                                            fontWeight: 600,
                                                            lineHeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          {titleCase(
                                                            val.selectedLocation
                                                              ?.map(
                                                                (e) =>
                                                                  e.display ??
                                                                  ''
                                                              )
                                                              .join(', ')
                                                          )}
                                                        </Typography>
                                                      </Box>
                                                    </Box>
                                                  )}
                                              </Box>
                                              <Box display='flex' width='2%'>
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    height: '30px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                                width='29%'
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Onset Duration
                                                </Typography>
                                              </Box>
                                              <Box display='flex' width='2%'>
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    height: '30px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                                width='27%'
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='primary'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {val.duration}
                                                </Typography>
                                              </Box>
                                            </Box>
                                          )}
                                        {val.selectedLocation &&
                                          val.selectedLocation.length > 0 && (
                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          )}
                                        {val.character.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {titleCase(
                                                  val.character.length === 0
                                                    ? `None`
                                                    : val.character
                                                )}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        )}
                                        {val.character.length > 0 && (
                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                        )}
                                        {val.alleviating.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={40}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.alleviating.length === 0
                                                  ? `${t(
                                                      'labelCommon:nofactorsFound'
                                                    )}`
                                                  : titleCase(val.alleviating)}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        )}
                                        {val.alleviating.length > 0 && (
                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                        )}
                                        {val.radiation.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexDirection='column'
                                            flexGrow
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={40}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='primary'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {val.radiation.length === 0
                                                    ? `None`
                                                    : titleCase(val.radiation)}
                                                </Typography>
                                              </Box>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.evidenceData &&
                                          val.evidenceData.length > 0 && (
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexDirection='column'
                                              flexGrow
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                minHeight={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  flexGrow
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='primary'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {titleCase(
                                                      val.evidenceData
                                                        ?.map((e) =>
                                                          titleCase(
                                                            e.text ?? ''
                                                          )
                                                        )
                                                        .join(', ')
                                                    )}
                                                  </Typography>
                                                </Box>
                                              </Box>

                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                        {val.temporal.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexDirection='column'
                                            flexGrow
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={40}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='primary'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {val.temporal.length === 0
                                                    ? `None`
                                                    : titleCase(val.temporal)}
                                                </Typography>
                                              </Box>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.selectedSeverity && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexDirection='column'
                                            flexGrow
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='primary'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {titleCase(
                                                    val.selectedSeverity
                                                      .display ?? 'None'
                                                  )}
                                                </Typography>
                                              </Box>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.notes && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            {val.notes && (
                                              <Box
                                                display='flex'
                                                flexDirection='row'
                                                width='100%'
                                                flexGrow
                                                overflow='auto'
                                              >
                                                <Box>
                                                  {hasSpaces(
                                                    val.notes.text ?? ''
                                                  ) && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      color='primary'
                                                      style={{
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'pre-line',
                                                        textOverflow:
                                                          'ellipsis',
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {val.notes.text ??
                                                        ''
                                                          .split('\n')
                                                          .map(
                                                            (
                                                              i: any,
                                                              key: any
                                                            ) => (
                                                              <div key={i}>
                                                                {i}
                                                              </div>
                                                            )
                                                          )}
                                                    </Typography>
                                                  )}
                                                  {hasSpaces(
                                                    val.notes.text ?? ''
                                                  ) === false && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      color='primary'
                                                      style={{
                                                        wordWrap: 'break-word',
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          inlineSize: 500,
                                                          overflowWrap:
                                                            'break-word',
                                                        }}
                                                      >
                                                        {val.notes.text ?? ''}
                                                      </div>
                                                    </Typography>
                                                  )}
                                                </Box>
                                              </Box>
                                            )}
                                          </Box>
                                        )}

                                        {/* <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      /> */}
                                      </Box>
                                      {/* <Box
                                      display='flex'
                                      flexGrow={1}
                                      justifyContent='flex-end'
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: 'auto',
                                          color: 'red',
                                          width: '2px',
                                        }}
                                      />
                                    </Box> */}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              {showDeleteConfirmation.open && (
                <ConfirmationDialog
                  {...showDeleteConfirmation}
                  id='confirmDialog'
                />
              )}
            </Box>
          </ReactVisibilitySensor>
        </Box>
        {/* {split === false && chiefDataArrayData.length > 0 && (
          <Box display='flex' flexGrow flexDirection='row' py={0.5}>
            <Tooltip title=''>
              <IconButton
                aria-label='btn_ord_cancel'
                color='primary'
                onClick={() => {
                  setAddChiefComplaints(true)
                }}
                style={{ padding: 0 }}
              >
                <AddCircleIcon
                  style={{ height: '16px', padding: 0 }}
                  color='primary'
                />{' '}
                <Typography
                  variant='subtitle2'
                  color='primary'
                  component={Link}
                >
                  {' '}
                  {t('labelCondition:attr_labels.Condition.code')}{' '}
                </Typography>
              </IconButton>
            </Tooltip>
          </Box>
        )} */}
      </Box>
      {/* <AddChiefComplanits
        open={addChiefComplaints}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onClose={() => {
          setAddChiefComplaints(false)
        }}
        onConditionAdded={() => {
          dispatch(
            getChiefComplaintOfAppointment(fhirAppointmentDetails, split)
          )
          setAddChiefComplaints(false)
          dispatch(resetChiefComplaintDetails)
        }}
      /> */}

      {/* <EditChiefComplanits
        open={editChiefComplaints}
        onConditionUpdated={() => {
          dispatch(
            getChiefComplaintOfAppointment(fhirAppointmentDetails, split)
          )
          setEditChiefComplaints(false)
          dispatch(resetChiefComplaintDetails)
        }}
        onClose={() => {
          setEditChiefComplaints(false)
        }}
        fhirAppointmentDetails={fhirAppointmentDetails}
        chiefComplaintsData={chiefComplaintsDetails}
      /> */}
    </MuiPickersUtilsProvider>
  )
}
