import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Tooltip,
  Link,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestAddictionsHistoryOfPatient } from 'redux/patientMedicalHistory/addictionsHistory/addictionsHistorySlice'
import { requestHabitsHistoryOfPatient } from 'redux/patientMedicalHistory//habitsHistory/habitsHistorySlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  HistoryObjectiveIndicative,
  SOAPIndicativeElement,
} from 'wello-web-components'
import { getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { requestOccupationHistoryOfPatient } from 'redux/fhirMedicalResources/ipd/occupation/occupationSearchSlice'
import moment from 'moment'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { requestMenstrualOfPatient } from 'redux/fhirMedicalResources/ipd/mensurationSearch/mensurationSearchSlice'
import { MensurationObjective } from 'views/components/LeftMenu/MensurationObjective'
import { HistoryTabularFormatMensural } from 'views/components/consultation/history/mensualHistoryTile'
import { AddMensuration } from './AddMensuration'
// import { AddAddiction } from '../addition/addAddiction'
// import { AddHabits } from '../addition/addHabits'
// import { AddOccupation } from '../occupation/Addoccupation'

interface HabitsProp {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
  splitView: boolean
}

export const MensurationDetails: React.FC<HabitsProp> = ({
  fhirAppointmentDetails,
  status,
  splitView,
}: HabitsProp) => {
  const { t } = useTranslation(['common'])
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)

  const dispatch = useDispatch()
  const mensurationSearhcSliceObs = useSelector(
    (state: RootState) => state.mensurationSearhcSliceObs
  )
  useEffect(() => {
    dispatch(requestMenstrualOfPatient('', fhirAppointmentDetails.patient))
  }, [dispatch, fhirAppointmentDetails])
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Menstrual History</Typography>
          </Box>{' '}
        </Box>
      </Box>
      {!isUnitAdmin() && status !== 'completed' && !splitView && (
        <Box
          display='flex'
          flexGrow
          flexDirection='row'
          justifyContent='flex-start'
          paddingBottom={1}
        >
          <Tooltip title='' id='men_1'>
            <IconButton
              aria-label='btn_ord_cancel'
              color='primary'
              id='men_2'
              onClick={() => {
                setOpenAddDialogue(true)
              }}
              style={{ padding: 0 }}
            >
              <Typography
                variant='subtitle2'
                color='primary'
                component={Link}
                id='men_4'
              >
                {' '}
                Menstrual History
              </Typography>
              <AddCircleIcon
                style={{ height: '16px', padding: 0 }}
                color='primary'
                id='men_3'
              />{' '}
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {mensurationSearhcSliceObs.searchingConditions && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <CircularProgress size={15} />
        </Box>
      )}

      {mensurationSearhcSliceObs.noResultsAvailable && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            {isUnitAdmin() || status === 'completed' || splitView
              ? 'No data available'
              : ''}{' '}
          </Typography>
        </Box>
      )}
      {/* {mensurationSearhcSliceObs.resultsAvailable &&
        mensurationSearhcSliceObs.occupationalList && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            {mensurationSearhcSliceObs.occupationalList.length === 0 && (
              <Box flexGrow width='100%' display='flex' flexDirection='row'>
                <Typography
                  variant='subtitle2'
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {' '}
                  {isUnitAdmin() ? 'Add Menstrual' : 'Add Menstrual'}{' '}
                </Typography>
              </Box>
            )}
          </Box>
        )} */}

      {mensurationSearhcSliceObs.errorWhileSearchingProcedures && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            color='error'
            style={{
              fontWeight: 400,
            }}
          >
            Error while fetching Menstrual
          </Typography>
        </Box>
      )}
      {mensurationSearhcSliceObs.resultsAvailable &&
        mensurationSearhcSliceObs.groupedObs && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%' flexGrow>
              {mensurationSearhcSliceObs.groupedObs.map((val) => (
                <Box py={1} key={val.date ?? ''}>
                  <Box
                    paddingX={1}
                    borderRadius={2}
                    style={{
                      backgroundColor: 'lightGrey',
                    }}
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height={40}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',

                          fontWeight: 'bold',
                        }}
                      >
                        Recorded on{' '}
                        {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                      </Typography>
                    </Box>
                  </Box>
                  <HistoryTabularFormatMensural
                    obsData={val.occupation}
                    type='Appetite'
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}

      <AddMensuration
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onAddictionAdded={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestMenstrualOfPatient('', fhirAppointmentDetails.patient)
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />
    </Box>
  )
}
