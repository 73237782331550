import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import {
  getDocumentBundle,
  getDocumentBundleForDiaogsticReport,
} from 'utils/labHelpers/fhirDocReferenceBuncle'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { PartnerOrderStatusTypes } from 'utils/constants/order_status'
import { getDiagnosticReportForLab } from 'utils/fhirResoureHelpers/observationHelpers'
import { ObsServiceDataWithVal } from 'models/obsServiceData'
import {
  getPrintResults,
  reportsAvailableForReportComplete,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { FHIRWithMasterApiClient } from 'services/FHIRClientWithMaster'
import { DocumentUploadStatus } from './documentUploadStatusTypes'
import { requestUpdateStatusOfOrder } from '../order/orderManagementSlice/orderManagementSlice'

const initialState: DocumentUploadStatus = {
  uploading: false,
  uploadingSuccessful: false,
  error: false,
  errorMessage: '',
}

const uploadSuccess: string[] = []

const uploadReportSlice = createSlice({
  name: 'uploadDocument',
  initialState,
  reducers: {
    uploadingDocument(state, action: PayloadAction<DocumentUploadStatus>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
    },

    documentUploaded(state, action: PayloadAction<DocumentUploadStatus>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.document = action.payload.document
      state.references = action.payload.references
    },

    errorInUploadingDocument(
      state,
      action: PayloadAction<DocumentUploadStatus>
    ) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetUpload(state, action: PayloadAction<DocumentUploadStatus>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.document = action.payload.document
    },
  },
})

export const uploadingDocument =
  (files: any, labOrderDetails: FhirLabOrderDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingDocumentUploadedState: DocumentUploadStatus = {
      uploading: true,
      uploadingSuccessful: false,
      error: false,
    }
    dispatch(
      uploadReportSlice.actions.uploadingDocument(addingDocumentUploadedState)
    )

    const type: string = 'application/pdf'
    const referencesDoc: string[] = []

    try {
      const panel1: R4.IDiagnosticReport = {
        ...getDiagnosticReportForLab(labOrderDetails),
      }
      let data: ObsServiceDataWithVal[] = []
      const planDefs: R4.IPlanDefinition[] = []
      if (labOrderDetails.tests && labOrderDetails.tests.length > 0) {
        for (let i = 0; i < labOrderDetails.tests.length; i++) {
          planDefs.push(labOrderDetails.tests[i].planDefinition)
        }
        data = await getPrintResults(planDefs, labOrderDetails)
      }

      const existingPanelData = await getDiagnosticReportExisting(
        labOrderDetails.serviceRequest.id!
      )

      const docBundle = getDocumentBundleForDiaogsticReport(
        planDefs,
        existingPanelData.length > 0 ? existingPanelData[0] : panel1,
        type,
        labOrderDetails,
        data
      )

      const fhirClient: FHIRWithMasterApiClient = new FHIRWithMasterApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '/',
        docBundle
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Document decoded', respDecoded.right)
        const documentResponse: R4.IBundle = respDecoded.right
        if (documentResponse.entry) {
          const resultSet: string[] = []
          for (let i = 0; i < documentResponse.entry.length; i++) {
            const docRefVal: string =
              documentResponse.entry[i].response?.location ?? ''
            const docObj = docRefVal.replace('/_history/1', '')
            referencesDoc.push(docObj)
            // uploadContentForDiaognsticReport(files[i], docObj, type)
          }
          if (
            labOrderDetails &&
            reportsAvailableForReportComplete(data, planDefs)
          ) {
            dispatch(
              requestUpdateStatusOfOrder(
                labOrderDetails,
                PartnerOrderStatusTypes[2],
                'report_uploaded',
                true
              )
            )
          }
        }
        const successUploadingDocument: DocumentUploadStatus = {
          uploading: false,
          uploadingSuccessful: true,
          error: false,
          errorMessage: '',
          references: referencesDoc,
        }
        dispatch(
          uploadReportSlice.actions.documentUploaded(successUploadingDocument)
        )
      } else {
        const errorUploadingDocument: DocumentUploadStatus = {
          uploading: false,
          uploadingSuccessful: false,
          error: true,
          errorMessage: 'Error while uploading',
        }
        dispatch(
          uploadReportSlice.actions.errorInUploadingDocument(
            errorUploadingDocument
          )
        )
      }

      return
    } catch (error) {
      const errorDocuploadedState: DocumentUploadStatus = {
        uploading: false,
        uploadingSuccessful: false,
        error: true,
        errorMessage: 'Error',
      }
      dispatch(
        uploadReportSlice.actions.errorInUploadingDocument(
          errorDocuploadedState
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(uploadReportSlice.actions.resetUpload(initialState))
}

export async function getDiagnosticReportExisting(
  serviceRequestId: string
): Promise<R4.IDiagnosticReport[]> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/DiagnosticReport?based-on=ServiceRequest/${serviceRequestId}`
  )
  const dataReportPanel: R4.IDiagnosticReport[] = []
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          const dataReport = getReportForUpload(orgResponse)
          if (dataReport) {
            dataReportPanel.push(dataReport)
          }
        }
      }
    }
  }
  return dataReportPanel
}

export function getReportForUpload(
  relativeResponse: R4.IBundle
): R4.IDiagnosticReport | undefined {
  const report: R4.IDiagnosticReport[] = []

  if (relativeResponse.total) {
    if (relativeResponse.total > 0) {
      if (relativeResponse.entry) {
        const entries: R4.IBundle_Entry[] = relativeResponse.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'DiagnosticReport':
                  report.push(value.resource as R4.IDiagnosticReport)

                  break
                default:
                  break
              }
            }
          }
        })
      }
    }
  }
  if (report.length > 0) return report[0]
  return undefined
}

async function uploadContent(file: any, documentRef: string, type: string) {
  logger.info('Document Reference')
  logger.info(documentRef)
  let result: boolean = false
  const reader = new FileReader()
  const rABS = !!reader.readAsArrayBuffer
  reader.onload = async (e) => {
    /* Parse data */
    const bstr = (e.target as FileReader).result
    const data = new Uint8Array(<ArrayBuffer>bstr)
    const convertedData = Buffer.from(data).toString('base64')
    if (data !== null) {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any | FHIRErrorResponses =
        await fhirClient.doCreateFHIRUploadResourceRequest(
          `/${documentRef}/$binary-access-write?path=DocumentReference.content.attachment`,
          convertedData,
          type
        )
      logger.info('Upload with reference')
      logger.info(response.data)
      const respDecoded: E.Either<Errors, R4.IDocumentReference> =
        R4.RTTI_DocumentReference.decode(response.data)
      if (respDecoded._tag === 'Right') {
        result = true
        uploadSuccess.push('success')
      }
    }
  }
  if (rABS) {
    reader.readAsArrayBuffer(file)
  }
}

export async function uploadContentForDiaognsticReport(
  data: string,
  documentRef: string,
  type: string
) {
  logger.info('Document Reference')
  logger.info(documentRef)
  let result: boolean = false
  if (data !== null && data.length > 0) {
    const fhirClient: FHIRWithMasterApiClient = new FHIRWithMasterApiClient()
    const response: any | FHIRErrorResponses =
      await fhirClient.doCreateFHIRUploadResourceRequest(
        `/${documentRef}/$binary-access-write?path=DiagnosticReport.presentedForm`,
        data,
        type
      )
    logger.info('Upload with reference')
    logger.info(response.data)
    const respDecoded: E.Either<Errors, R4.IDiagnosticReport> =
      R4.RTTI_DiagnosticReport.decode(response.data)
    if (respDecoded._tag === 'Right') {
      result = true
      uploadSuccess.push('success')
    }
  }
  return result
}

export default uploadReportSlice.reducer
