import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { PatientPreAppointmentDetails } from 'models/patientPreAppointmentDetails'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { resetState } from 'redux/administration/patientPreAppointment/patientPreAppointmentInvitationSetupSlice'
import { addPatientForm } from 'redux/patient/addPatient/addPatientPreAppointmentSlice'
import { RootState } from 'redux/rootReducer'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { validateMobileNumber, WelloTextField } from 'wello-web-components'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
}
export const AddPatientsPreAppointmentHandler: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick: onContinue,
}) => {
  const patientInvitationSetupSlice = useSelector(
    (state: RootState) => state.patientInvitationSetupSlice
  )
  const addPatientPreAppointmentFormSlice = useSelector(
    (state: RootState) => state.addPatientPreAppointmentFormSlice
  )

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })
  const dispatch = useDispatch()

  function handleRemove(id: number) {
    const values: PatientPreAppointmentDetails[] = [...relation]
    values.splice(id, 1)
    setRelations(values)
  }

  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const [relation, setRelations] = useState<PatientPreAppointmentDetails[]>([
    {
      id: getUniqueTempId(),
      patientEmail: '',
      patientFirstName: '',
      patientLastName: '',
      patientPhoneNumber: '',
      patientAppointmentType: 'IPD',
      patientCheckInDate: moment()
        .format('YYYY-MM-DD')
        .concat('T01:30:00.000Z') as any as Date,
    },
  ])

  function resetDetails() {
    setRelations([
      {
        id: getUniqueTempId(),
        patientEmail: '',
        patientFirstName: '',
        patientLastName: '',
        patientPhoneNumber: '',
        patientAppointmentType: 'IPD',
        patientCheckInDate: moment()
          .format('YYYY-MM-DD')
          .concat('T01:30:00.000Z') as any as Date,
      },
    ])
  }

  function handleFirstNameChange(event: any, index: number) {
    const values: PatientPreAppointmentDetails[] = [...relation]

    values[index].patientFirstName = event.target.value

    setRelations(values)
  }

  function handleLastNameChange(event: any, index: number) {
    const values: PatientPreAppointmentDetails[] = [...relation]

    values[index].patientLastName = event.target.value

    setRelations(values)
  }

  function handleEmailChange(event: any, index: number) {
    const values: PatientPreAppointmentDetails[] = [...relation]
    values[index].patientEmail = event.target.value
    setRelations(values)
  }

  function handlePhoneNumberChange(event: any, index: number) {
    const values: PatientPreAppointmentDetails[] = [...relation]
    values[index].patientPhoneNumber = event
    setRelations(values)
  }

  function handleDateChange(event: any, index: number) {
    const newDateWithTime: any = event.concat('T01:30:00.000Z')
    const newDateObject: Date = newDateWithTime

    const values: PatientPreAppointmentDetails[] = [...relation]
    values[index].patientCheckInDate = newDateObject

    setRelations(values)
  }

  const handleAddMore = () => {
    const values: PatientPreAppointmentDetails[] = [...relation]
    values.push({
      id: getUniqueTempId(),
      patientEmail: '',
      patientFirstName: '',
      patientLastName: '',
      patientPhoneNumber: '',
      patientAppointmentType: 'IPD',
      patientCheckInDate: moment()
        .format('YYYY-MM-DD')
        .concat('T01:30:00.000Z') as any as Date,
    })
    setRelations(values)
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    relation.forEach((e) => {
      if (!e.patientFirstName || e.patientLastName?.length === 0) {
        hasErrorsInData = true
      }
      if (
        !e.patientPhoneNumber ||
        e.patientPhoneNumber?.length === 0 ||
        e.patientPhoneNumber?.length !== 13 ||
        validateMobileNumber(e.patientPhoneNumber, true).length > 0
      ) {
        hasErrorsInData = true
      }
      if (
        !e.patientCheckInDate ||
        moment(e.patientCheckInDate).isBefore(moment().startOf('day'))
      ) {
        hasErrorsInData = true
      }
    })
    if (!hasErrorsInData) {
      dispatch(addPatientForm(relation))
      return
    }

    setHasErrors(hasErrorsInData)
  }

  useEffect(() => {
    if (addPatientPreAppointmentFormSlice.sentSuccessfully) {
      onContinue()
      resetDetails()
      dispatch(resetState())
    }
    return () => {}
  }, [addPatientPreAppointmentFormSlice.sentSuccessfully])

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetDetails()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='lg'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        Add Patient Basic Details
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column'>
          {addPatientPreAppointmentFormSlice.error && (
            <Box display='flex'>
              <Alert severity='error'>
                {patientInvitationSetupSlice.errorMessage}
              </Alert>
            </Box>
          )}
          {addPatientPreAppointmentFormSlice.sentSuccessfully && (
            <Box display='flex'>
              <Alert severity='success'>'Invitation sent Successfully'</Alert>
            </Box>
          )}
          {!addPatientPreAppointmentFormSlice.sentSuccessfully && (
            <Box display='flex' flexDirection='column'>
              {relation.map(
                (memRel: PatientPreAppointmentDetails, index: number) => (
                  <Box
                    display='flex'
                    flexDirection='column'
                    key={`unit_actor_small_details_${memRel.id}`}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      alignItems='flex-start'
                      key={`unit_actor_small_details_${memRel.id}`}
                    >
                      <Box flexGrow={1} p={0.5} width='20%'>
                        <WelloTextField
                          title='FIRST NAME'
                          textProps={{
                            id: `actor_name_unit_${t}`,
                            value: memRel.patientFirstName,
                            disabled: patientInvitationSetupSlice.adding,
                            error:
                              hasErrors &&
                              (!memRel.patientFirstName ||
                                memRel.patientFirstName?.length === 0),
                            helperText:
                              hasErrors &&
                              (!memRel.patientFirstName ||
                                memRel.patientFirstName?.length === 0)
                                ? 'First Name is required'
                                : '',
                            onChange: (changedValue) => {
                              handleFirstNameChange(changedValue, index)
                            },
                          }}
                        />
                      </Box>
                      <Box
                        display='flex'
                        flexGrow={1}
                        alignItems='flex-end'
                        justifyContent='center'
                        p={0.5}
                        width='20%'
                      >
                        <WelloTextField
                          title='LAST NAME'
                          textProps={{
                            id: `actor_name_unit_${t}`,
                            value: memRel.patientLastName,
                            disabled: patientInvitationSetupSlice.adding,
                            error:
                              hasErrors &&
                              (!memRel.patientLastName ||
                                memRel.patientLastName?.length === 0),
                            helperText:
                              hasErrors &&
                              (!memRel.patientLastName ||
                                memRel.patientLastName?.length === 0)
                                ? 'Last Name is required'
                                : '',
                            onChange: (changedValue) => {
                              handleLastNameChange(changedValue, index)
                            },
                          }}
                        />
                      </Box>
                      <Box
                        display='flex'
                        flexGrow={1}
                        alignItems='flex-end'
                        justifyContent='center'
                        p={0.5}
                        width='20%'
                      >
                        <WelloPhoneNumberField
                          textProps={{
                            id: `actor_phone_unit_${t}`,
                            countryCodeEditable: false,
                            defaultCountry: 'in',
                            fullWidth: true,
                            name: t('labelCommon:phone_name_label'),
                            inputMode: 'tel',
                            onlyCountries: ['in'],
                            disableDropdown: false,
                            inputProps: { maxLength: 13 },
                            disabled: patientInvitationSetupSlice.adding,
                            onChange: (
                              e:
                                | React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                | string
                            ) => {
                              if (typeof e === 'string') {
                                handlePhoneNumberChange(e, index)
                              }
                            },
                            regions: ['asia'],
                            autoFormat: false,
                            size: 'small',
                            variant: 'outlined',
                            error:
                              hasErrors &&
                              (validateMobileNumber(
                                memRel.patientPhoneNumber,
                                true
                              ).length > 0 ||
                                memRel.patientPhoneNumber.length < 13),
                            helperText:
                              hasErrors &&
                              (validateMobileNumber(
                                memRel.patientPhoneNumber,
                                true
                              ).length > 0 ||
                                memRel.patientPhoneNumber.length < 13)
                                ? 'Phone number is required'
                                : '',

                            required: true,
                          }}
                          title={t('labelCommon:phone_name_label')}
                        />
                      </Box>
                      <Box
                        display='flex'
                        flexGrow={1}
                        alignItems='flex-end'
                        justifyContent='center'
                        p={0.5}
                        width='20%'
                      >
                        <WelloTextField
                          title='Appointment Date'
                          textProps={{
                            id: 'appointment_date',
                            defaultValue: moment().format('YYYY-MM-DD'),
                            error:
                              hasErrors &&
                              moment(memRel.patientCheckInDate).isBefore(
                                moment().startOf('day')
                              ),
                            helperText:
                              hasErrors &&
                              moment(memRel.patientCheckInDate).isBefore(
                                moment().startOf('day')
                              )
                                ? 'Appointment Date must be today or future date'
                                : '',
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <Box>
                                    <Tooltip title=''>
                                      <IconButton
                                        aria-label='btn_ord_cancel'
                                        color='primary'
                                      >
                                        <img
                                          src={`${process.env.PUBLIC_URL}/Shape.png`}
                                          alt=''
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </InputAdornment>
                              ),
                            },
                            inputProps: {
                              min: moment().format('YYYY-MM-DD'),
                            },
                            type: 'date',
                            onChange: (changeDate) => {
                              handleDateChange(changeDate.target.value, index)
                            },
                          }}
                        />
                      </Box>

                      {relation.length !== 1 && (
                        <Box
                          display='flex'
                          justifyContent='center'
                          paddingTop={5}
                        >
                          <DeleteOutlineOutlined
                            fontSize='medium'
                            id={`$${index}orgContact`}
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setShowDeleteConfirmation({
                                open: true,
                                onContinueActionClicked: () => {
                                  setShowDeleteConfirmation({
                                    open: false,
                                  })
                                  handleRemove(index)
                                },
                                onCancelActionClicked: () => {
                                  setShowDeleteConfirmation({
                                    open: false,
                                  })
                                },
                                displayText:
                                  'Are you sure you want to delete this record?',
                                continueActionButtonText: 'Delete',
                                cancelActionButtonText: 'Cancel',
                              })
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    {/* {!patientInvitationSetupSlice.additionSuccessful &&
                                        relation.length - 1 === index &&
                                        relation.length < 4 && (
                                            <Box
                                                display='flex'
                                                alignItems='center'
                                                py={0.5}
                                                flexDirection='row'
                                            >
                                                <Box>
                                                    <Button
                                                        variant='text'
                                                        onClick={handleAddMore}
                                                        disabled={patientInvitationSetupSlice.adding}
                                                    >
                                                        <Typography variant='subtitle2'>
                                                            + Add Another Patient
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                                <Box flexGrow={1} paddingLeft={2}>
                                                    {' '}
                                                    <Divider />{' '}
                                                </Box>
                                            </Box>
                                        )} */}
                  </Box>
                )
              )}
            </Box>
          )}

          {showDeleteConfirmation.open && (
            <ConfirmationDialog {...showDeleteConfirmation} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {addPatientPreAppointmentFormSlice.sending && <CircularProgress />}
        <Button
          onClick={() => {
            resetDetails()
            onClose()
          }}
          variant='outlined'
          disabled={addPatientPreAppointmentFormSlice.sending}
          disableElevation
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={addPatientPreAppointmentFormSlice.sending}
          onClick={() => {
            handleSubmit()
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
