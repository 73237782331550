/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { DaysInSingleLetter } from 'utils/constants/calender_constants'
import {
  Typography,
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
} from '@material-ui/core'

import { OperationalHoursOfDay } from 'models/operationalHoursOfDay'
import { useTranslation } from 'react-i18next'
import { GetTitleKeyForFhirDay } from 'utils/fhirResoureHelpers/calender_helper'
import { Add, Delete } from '@material-ui/icons'
import {
  kBackgroundDefault,
  kBackgroundPaper,
  kDialogueBackground,
  kPrimaryMain,
} from 'configs/styles/muiThemes'
import { TimePicker } from '@material-ui/pickers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { constants } from 'zlib'
import moment from 'moment'

interface TabProps {
  selectionOfDay: OperationalHoursOfDay
  onSelectionChanges: (val: OperationalHoursOfDay) => void
  commonControl?: boolean
  isReadMode?: boolean
}

export const DayBusinessHour: React.FC<TabProps> = ({
  selectionOfDay,
  onSelectionChanges,
  commonControl = false,
  isReadMode = false,
}: TabProps) => {
  const { t } = useTranslation()
  const [operationDay, setOperationDay] =
    useState<OperationalHoursOfDay>(selectionOfDay)

  const onAddClicked = () => {
    const operationOfDay: OperationalHoursOfDay = { ...operationDay }
    const endTime = moment(
      operationOfDay.timings[operationOfDay.timings.length - 1]
        .availableEndTime,
      'H:mma'
    ).add(2, 'hours')
    operationOfDay.timings.push({
      id: getUniqueTempId(),
      availableStartTime:
        operationOfDay.timings[operationOfDay.timings.length - 1]
          .availableEndTime,
      availableEndTime: `${endTime.format('HH')}:00`,
    })
    onSelectionChanges(operationOfDay)
    setOperationDay(operationOfDay)
    onSelectionChanges(operationOfDay)
    setOperationDay(operationOfDay)
  }
  const onRemoveClicked = (id: string | undefined) => {
    const operationOfDay: OperationalHoursOfDay = { ...operationDay }
    if (id) {
      const times: any[] = operationOfDay.timings.filter((e) => e.id !== id)
      operationOfDay.timings = times
      onSelectionChanges(operationOfDay)
      setOperationDay(operationOfDay)
    }
  }
  function onStartTimeChange(index: number, value: string) {
    const opDay: OperationalHoursOfDay = { ...operationDay }
    opDay.timings[index].availableStartTime = value
    onSelectionChanges(opDay)
    setOperationDay(opDay)
  }

  function onEndTimeChange(index: number, value: string) {
    const opDay: OperationalHoursOfDay = { ...operationDay }
    opDay.timings[index].availableEndTime = value
    onSelectionChanges(opDay)
    setOperationDay(opDay)
  }

  function handleCheckBoxChange(checked: boolean) {
    const opDay: OperationalHoursOfDay = { ...operationDay }
    if (checked === true) {
      opDay.allDayOpen = checked
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      opDay.timings = [
        {
          id: opDay.dayOfWeek,
          availableStartTime: '00:00',
          availableEndTime: '23:59',
        },
      ]
    } else {
      opDay.allDayOpen = checked

      opDay.timings = [
        {
          id: opDay.dayOfWeek,
          availableStartTime: '09:00',
          availableEndTime: '15:00',
        },
      ]
    }

    onSelectionChanges(opDay)
    setOperationDay(opDay)
  }

  function handleWithoutWholeDay(checked: boolean) {
    const opDay: OperationalHoursOfDay = { ...operationDay }
    opDay.allDayOpen = checked

    opDay.timings = [
      {
        id: opDay.dayOfWeek,
        availableStartTime: '09:00',
        availableEndTime: '15:00',
      },
    ]

    onSelectionChanges(opDay)
    setOperationDay(opDay)
  }
  return (
    <Box display='flex' flexDirection='row' px={16} alignItems='baseline'>
      <Box
        display='flex'
        flexGrow={1}
        flexDirection='row'
        alignItems='baseline'
        justifyContent='space-between'
      >
        <Box>
          <Typography variant='h6'>
            {commonControl
              ? 'Every Business Day'
              : t(GetTitleKeyForFhirDay(operationDay.dayOfWeek))}
          </Typography>
        </Box>
        <Box
          display='flex'
          alignSelf='center'
          alignItems='baseline'
          py={commonControl ? 0.5 : 0}
        >
          {isReadMode && operationDay.allDayOpen && (
            <Typography> All Day</Typography>
          )}
          {!isReadMode && (
            <FormControlLabel
              label='All Day'
              style={{ padding: '0px' }}
              control={
                <Checkbox
                  checked={operationDay.allDayOpen}
                  onChange={(e) => handleCheckBoxChange(e.target.checked)}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      if (operationDay.allDayOpen === false) {
                        handleCheckBoxChange(true)
                      } else {
                        handleWithoutWholeDay(false)
                      }
                    }
                  }}
                  name='checkedB'
                  color='primary'
                  size='small'
                  style={{ padding: '0px', paddingLeft: '8px' }}
                />
              }
            />
          )}
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='flex-end'
        alignItems='center'
      >
        <Box display='flex' flexDirection='column' alignContent='center'>
          {operationDay.timings.map((eachTiming, i) => (
            <Box
              id={`box_${eachTiming}`}
              display='flex'
              flexDirection='row'
              justifyContent='center'
              py={1}
              alignItems='baseline'
              key={`key_${eachTiming.id}`}
            >
              <Box
                p={1}
                flexDirection='row'
                alignSelf='center'
                alignItems='baseline'
              >
                {isReadMode && (
                  <Typography>
                    {' '}
                    {operationDay.allDayOpen
                      ? '00:00'
                      : eachTiming.availableStartTime}{' '}
                  </Typography>
                )}
                {!isReadMode && (
                  <TextField
                    id='start_time'
                    size='small'
                    color='primary'
                    style={{
                      backgroundColor: kBackgroundPaper,
                      borderColor: 'transparent',
                      color: 'primary',
                    }}
                    onChange={(e) => {
                      onStartTimeChange(i, e.target.value)
                    }}
                    type='time'
                    disabled={operationDay.allDayOpen}
                    variant='standard'
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        disableunderline: 'true',
                        min: '00:00',
                        max: '23:59',
                        style: {
                          backgroundColor: kDialogueBackground,
                        },
                      },
                    }}
                    value={eachTiming.availableStartTime}
                  />
                )}
              </Box>
              <Box
                p={1}
                flexDirection='row'
                alignSelf='center'
                alignItems='baseline'
              >
                <Typography> - </Typography>
              </Box>

              <Box
                p={1}
                flexDirection='row'
                alignSelf='center'
                alignItems='baseline'
              >
                {isReadMode && (
                  <Typography>
                    {' '}
                    {operationDay.allDayOpen
                      ? '23:59'
                      : eachTiming.availableEndTime}{' '}
                  </Typography>
                )}
                {!isReadMode && (
                  <TextField
                    id='end_time'
                    style={{
                      color: kPrimaryMain,
                    }}
                    onChange={(e) => {
                      onEndTimeChange(i, e.target.value)
                    }}
                    size='small'
                    disabled={operationDay.allDayOpen}
                    color='primary'
                    InputLabelProps={{
                      shrink: true,
                      color: 'primary',
                    }}
                    variant='standard'
                    type='time'
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        disableunderline: 'true',
                        min: eachTiming.availableStartTime,
                        max: '23:59',
                        color: kPrimaryMain,
                        step: 300,
                        style: {
                          backgroundColor: kDialogueBackground,
                        },
                      },
                    }}
                    value={eachTiming.availableEndTime}
                  />
                )}
              </Box>
              {(commonControl || isReadMode) && (
                <Box>
                  <Button
                    hidden={true}
                    style={{
                      visibility: 'hidden',
                    }}
                    disabled={operationDay.allDayOpen}
                    onClick={onAddClicked}
                    id={`btn_add_newTime${operationDay.dayOfWeek}`}
                  >
                    <Add />
                  </Button>
                </Box>
              )}
              {i === 0 && !commonControl && !isReadMode && (
                <Box>
                  <Button
                    disabled={operationDay.allDayOpen}
                    onClick={onAddClicked}
                    id={`btn_add_newTime${operationDay.dayOfWeek}`}
                  >
                    <Add />
                  </Button>
                </Box>
              )}
              {i !== 0 && !commonControl && !isReadMode && (
                <Box>
                  <Button
                    disabled={operationDay.allDayOpen}
                    onClick={() => {
                      onRemoveClicked(eachTiming.id ?? i.toString())
                    }}
                    id={`delete_newTime${operationDay.dayOfWeek}_${i}`}
                  >
                    <Delete />
                  </Button>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
