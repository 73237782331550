import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Typography,
} from '@material-ui/core'
import { PhysiotherapyDataUpdate } from 'models/tables/physiotherapyDataUpdate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// import { updateCatalog } from 'redux/valueSet/therapyList/addTherapyCatalougeSlice';
import { updateCatalog } from 'redux/management/physiotherapyList/addPhysiotherapyTableSlice'
import { getPhysiotherapyBundleUpdate } from 'utils/labHelpers/uploadHelper'
import { WelloTextField } from 'wello-web-components'

export interface EditPhysiotherapyProps {
  open: boolean
  onCancelClick: () => void
  selectedValue: PhysiotherapyDataUpdate
}

export interface DeletePhysiotherapyProps {
  open: boolean
  onCancelClick: () => void
}

export function EditPhysiotherapy(props: EditPhysiotherapyProps) {
  const { open, selectedValue, onCancelClick: onClose } = props

  const physiotherapyDetails = selectedValue

  const dispatch = useDispatch()

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  const [physiotherapyData, setPhysiotherapyData] =
    React.useState<PhysiotherapyDataUpdate>({
      name: physiotherapyDetails?.name,
      price: physiotherapyDetails?.price ?? 0,
      therapy: physiotherapyDetails?.therapy,
      category: physiotherapyDetails?.category,
    })

  function handleNameChange(namePhysiotherapy: string) {
    setPhysiotherapyData({ ...physiotherapyData, name: namePhysiotherapy })
  }

  function handlePriceChange(pricePhysiotherapy: number) {
    setPhysiotherapyData({ ...physiotherapyData, price: pricePhysiotherapy })
  }

  function handleSubmit() {
    let hasPriceErrorInData: boolean = false

    if (
      physiotherapyData.price <= 0 ||
      physiotherapyData.price > 10000 ||
      Number.isNaN(physiotherapyData.price)
    ) {
      hasPriceErrorInData = true
    } else {
      hasPriceErrorInData = false
    }

    if (!hasPriceErrorInData) {
      const bundleData = getPhysiotherapyBundleUpdate(physiotherapyData)

      if (bundleData) {
        dispatch(updateCatalog(bundleData, '', 'Physiotherapy'))
      }
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Update Physiotherapy')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
        >
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Physiotherapy Name'
              textProps={{
                id: 'Physiotherapy Name',
                value: selectedValue?.name,
                disabled: true,
                placeholder: 'Physiotherapy name',
                onChange: (changeName) => {
                  // handleNameChange(changeName.target.value)
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Category'
              textProps={{
                value: selectedValue?.category,
                disabled: true,
                placeholder: 'Category',
                onChange: (changeCategory) => {
                  // handleCategoryChange(changeCategory.target.value)
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Price'
              textProps={{
                id: 'Price of the Physiotherapy',
                InputProps: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box>
                        {' '}
                        <Typography variant='subtitle2' align='center'>
                          {' '}
                          &#x20b9;
                        </Typography>
                      </Box>
                    </InputAdornment>
                  ),
                },
                inputProps: {
                  max: 10000,
                  maxLength: 5,
                },
                error:
                  physiotherapyData.price <= 0 ||
                  physiotherapyData.price > 10000 ||
                  Number.isNaN(physiotherapyData.price),
                helperText:
                  physiotherapyData.price <= 0 ||
                  physiotherapyData.price > 10000
                    ? 'Price should be between 1 to 10000'
                    : '',
                defaultValue: physiotherapyData.price,
                type: 'number',
                placeholder: 'Price',
                onChange: (changePrice) => {
                  handlePriceChange(parseInt(changePrice.target.value, 10))
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button
            variant='contained'
            disableElevation
            onClick={() => {
              handleSubmit()
            }}
            id='Physiotherapy Update'
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export function DeletePhysiotherapy(props: DeletePhysiotherapyProps) {
  const { open, onCancelClick: onClose } = props

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Delete Physiotherapy')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
        >
          <Box display='flex' px={2} py={1} width='100%'>
            <Typography>
              Are you sure you want to delete the Physiotherapy?
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button variant='contained' disableElevation>
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
