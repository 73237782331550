import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { truncate } from 'lodash'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { RoomPrice } from 'models/roomPrice'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getRoomPriceFromBundle } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { getExpandedServiceRequestFromBundle } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { NightPriceSearchStatus } from './nightTypeSearchStatus'

const initialState: NightPriceSearchStatus = {
  searchingPrice: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingPrice: false,
  availablePrice: 0,
  currency: 'INR',
}

const nightPriceSearchSlice = createSlice({
  name: 'nightPriceSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<NightPriceSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingPrice = action.payload.searchingPrice
      state.resultsAvailable = action.payload.resultsAvailable
      state.availablePrice = action.payload.availablePrice
      state.currency = action.payload.currency
      state.price = action.payload.price
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingPrice = action.payload.errorWhileSearchingPrice
    },
    resetPriceDetails(state, action: PayloadAction<NightPriceSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingPrice = action.payload.searchingPrice
      state.resultsAvailable = action.payload.resultsAvailable
      state.availablePrice = action.payload.availablePrice
      state.currency = action.payload.currency
      state.price = action.payload.price
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingPrice = action.payload.errorWhileSearchingPrice
    },
  },
})

export const requestForRoomPriceForNight =
  (roomType: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: NightPriceSearchStatus = {
      searchingPrice: true,
      errorWhileSearchingPrice: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      price: undefined,
      availablePrice: 0,
      currency: 'INR',
    }
    dispatch(nightPriceSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        url: `http://wellopathy.com/ChargeItemDefinition/${roomType}`,
        status: 'active',
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          '/ChargeItemDefinition',
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      const appointmentResponse: R4.IBundle = response as R4.IBundle
      if (appointmentResponse?.total && appointmentResponse?.total > 0) {
        const priceData: RoomPrice = getRoomPriceFromBundle(appointmentResponse)
        logger.info('Orders length')

        state.resultsAvailable = true
        state.searchingPrice = false
        state.availablePrice = priceData.price
        state.price = priceData.chargeDdef

        state.currency = 'INR'
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingPrice = false
        dispatch(nightPriceSearchSlice.actions.updatedStatus(state))
      } else {
        const errorSearchDoctor: NightPriceSearchStatus = {
          searchingPrice: false,
          resultsAvailable: false,
          noResultsAvailable: truncate,
          errorWhileSearchingPrice: false,
          availablePrice: 0,
          currency: 'INR',
        }
        dispatch(nightPriceSearchSlice.actions.updatedStatus(errorSearchDoctor))
      }
    } catch (error) {
      const errorSearchDoctor: NightPriceSearchStatus = {
        searchingPrice: false,
        resultsAvailable: false,
        errorWhileSearchingPrice: true,
        availablePrice: 0,
        currency: 'INR',
        errorReason: 'Error while fetching price',
      }
      dispatch(nightPriceSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export const resetPriceSearch = () => (dispatch: AppDispatch) => {
  dispatch(nightPriceSearchSlice.actions.resetPriceDetails(initialState))
}

export default nightPriceSearchSlice.reducer
