import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import _ from 'lodash'
import {
  getMedicationCategoryUrlFromCode,
  medicationMaps,
} from 'models/AyurvedaUrlCategory'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import {
  getRouteOfAdminCoding,
  getUnitOfDosageCoding,
} from 'utils/appointment_handle/medications_util'
import { oralUnits, routesOfAdministration } from 'utils/constants'
import { getAddedDate, getTomorrowDate } from 'utils/dateUtil'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import {
  addNewAyurvedaMedicationsDetails,
  editMedication,
} from 'utils/sopaNotes/plan/ayurvedaMedicationHelpers'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { AyurvedaMedicationsTempList } from 'views/components/consultation/plan/ayurveda/ayurvedaMedicationsTempList'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloTextField } from 'wello-web-components'
import anupanas from '../../../../assets/constants/common-anupanas.json'

// import { MedicationTile } from './medication_tile'

interface Props {
  open: boolean
  preselectedMedication: WelloMedication
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onMedicalConditionAdded: () => void
}
export const EditAyurvedaMedicationsHandlerIPD: React.FC<Props> = ({
  open,
  preselectedMedication,
  onClose,
  onMedicalConditionAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])
  const [addedMedications, setAddedMedications] = useState<
    WelloAyurvedaMedication[]
  >([])
  const dispatch = useDispatch()
  const [defaultSubcategoryUrl, setDefaultSubcategoryUrl] = useState<string>()
  const [defaultMedicineCategoryUrl, setDefaultMedicineCategoryUrl] =
    useState<string>(medicationMaps.all_url)

  const [selectedDatePart, setSelectedDatePart] = useState<string>(
    preselectedMedication ? 'tomo' : 'today'
  )
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('3d')

  const [addAnother, setAddAnother] = React.useState<boolean>(false)
  const [formState, setFormState] = useState(_.random(0, 109090).toFixed(2))

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [addMore, setAddMore] = useState<boolean>(false)
  const openMenu = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [other, setOther] = useState<boolean>(false)
  console.log(preselectedMedication)

  const [currentMedicationTile, setCurrentMedicationTile] =
    useState<WelloAyurvedaMedication>({
      id: preselectedMedication.medicationRequest
        ? preselectedMedication.medicationRequest.id ?? ''
        : '',
      otherDataText: '',
      base: preselectedMedication.base,
      administrationCode: preselectedMedication.administrationCode
        ? preselectedMedication.administrationCode
        : '26643006',
      date: preselectedMedication.date,
      medicationDate: preselectedMedication.medicationDate,
      medicationRequest: preselectedMedication.medicationRequest,

      medicationCode: preselectedMedication.medicationCode
        ? preselectedMedication.medicationCode
        : undefined,
      startFrom: preselectedMedication.startFrom
        ? moment(preselectedMedication.startFrom).toDate()
        : new Date(),
      till: preselectedMedication.startFrom
        ? moment(preselectedMedication.till).toDate()
        : getAddedDate(1),
      mealCode: preselectedMedication.mealCode
        ? preselectedMedication.mealCode.toLowerCase()
        : 'before',
      timeOfDayCode: preselectedMedication.timeOfDayCode
        ? preselectedMedication.timeOfDayCode
        : ['morning'],
      dosageQuantity: preselectedMedication.dosageQuantity
        ? preselectedMedication.dosageQuantity
        : undefined,
      timeCodes: preselectedMedication.timeCodes,
      administrationCoding: preselectedMedication.administrationCode
        ? getRouteOfAdminCoding(preselectedMedication.administrationCode ?? '')
        : undefined,
      dosageQuantityTypeCoding: preselectedMedication.dosageQuantityType
        ? getUnitOfDosageCoding(preselectedMedication.dosageQuantityType ?? '')
        : undefined,
      dosageQuantityType: preselectedMedication.dosageQuantityType
        ? preselectedMedication.dosageQuantityType
        : undefined,
      anupanaCode: preselectedMedication.anupanaCode
        ? preselectedMedication.anupanaCode
        : undefined,
      aushadhaKalaCode: preselectedMedication.aushadhaKalaCode
        ? preselectedMedication.aushadhaKalaCode
        : undefined,
      anupanaDosageQuantity: preselectedMedication.anupanaDosageQuantity
        ? preselectedMedication.anupanaDosageQuantity
        : undefined,
      anupanaDosageQuantityType: preselectedMedication.anupanaDosageQuantityType
        ? preselectedMedication.anupanaDosageQuantityType
        : undefined,
      anupanaDosageQuantityTypeCoding:
        preselectedMedication.anupanaDosageQuantityType
          ? getUnitOfDosageCoding(
              preselectedMedication.anupanaDosageQuantityType ?? ''
            )
          : undefined,
      notes: preselectedMedication.notes
        ? preselectedMedication.notes
        : undefined,
    })
  const addMedicationsSlice = useSelector(
    (state: RootState) => state.addMedicationsSlice
  )

  function resetForm() {
    setCurrentMedicationTile({
      otherDataText: '',
      administrationCode: '26643006',
      startFrom: new Date(),
      till: getAddedDate(0),
      mealCode: 'before',
      timeOfDayCode: ['morning'],
    })
    setDefaultSubcategoryUrl(undefined)
    setDefaultMedicineCategoryUrl(medicationMaps.all_url)
    setFormState(_.random(0, 109090).toFixed(2))
    setAddMore(false)
    setSelectedDatePart('today')
  }

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloAyurvedaMedication[] = [...addedMedications]
    const newMedication: WelloAyurvedaMedication = {
      ...currentMedicationTile,
      // notes: additionalNotes,
    }

    tempMedication.push(newMedication)
    setAddedMedications(tempMedication)
    console.log(tempMedication)
    resetForm()

    if (pushToSave) {
      //   postMedicationDetails(tempMedication)
    }
  }

  function postMedicationDetails() {
    const tempMedication: WelloAyurvedaMedication[] = [...addedMedications]
    const newMedication: WelloAyurvedaMedication = {
      ...currentMedicationTile,
      // notes: additionalNotes,
    }

    tempMedication.push(newMedication)
    setAddedMedications(tempMedication)

    setUpdateStatus({ requesting: true })

    editMedication(
      fhirAppointmentDetails.mainEncounter?.subject!,
      {
        reference: `Encounter/${fhirAppointmentDetails.mainEncounter!.id}`,
      },
      tempMedication,
      'IPD',
      fhirAppointmentDetails
    ).then((e) => {
      if (e) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Medication added successfully'))
        resetForm()
        setAddedMedications([])
        onMedicalConditionAdded()
        onClose()
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(
          showErrorAlert(
            'Error while adding Medication. Please try again later'
          )
        )
      }
    })
  }

  function isOtherSelected(): boolean {
    if (other === true && !currentMedicationTile.otherDataText) {
      return true
    }
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setAddedMedications([])
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        Edit Oushadhi (Medication)
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box
          marginY={1}
          display='flex'
          flexDirection='column'
          width='100%'
          px={1}
          key={formState}
        >
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box px={1} width='15%'>
              <ValueSetSelectSingle
                title='Category'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurvedic-medicine-types-vs'
                id='med_category'
                fuzzySearch={false}
                alwaysShowAllOptions={true}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  if (selectedKriya) {
                    const res = getMedicationCategoryUrlFromCode(
                      selectedKriya.code ?? ''
                    )
                    if (res) {
                      if (res.leaf_node) {
                        setDefaultSubcategoryUrl(undefined)
                        setDefaultMedicineCategoryUrl(res.category_url)
                      } else {
                        setDefaultSubcategoryUrl(res.category_url)
                      }
                    } else {
                      setDefaultSubcategoryUrl(undefined)
                      setDefaultMedicineCategoryUrl(medicationMaps.all_url)
                    }
                  }
                }}
                helperText={undefined}
              />
            </Box>

            <Box px={1} width='15%'>
              <ValueSetSelectSingle
                key={`${defaultSubcategoryUrl}sub_category`}
                title='Sub Category'
                url={`http://wellopathy.com/fhir/india/core/ValueSet/${defaultSubcategoryUrl}`}
                id='med_sub_category'
                fuzzySearch={true}
                preSelectedOptions={undefined}
                alwaysShowAllOptions={true}
                disabled={_.isEmpty(defaultSubcategoryUrl)}
                onOptionSelected={(selectedCategory) => {
                  if (selectedCategory) {
                    const res = getMedicationCategoryUrlFromCode(
                      selectedCategory.code ?? ''
                    )
                    if (res) {
                      setDefaultMedicineCategoryUrl(res?.category_url)
                      return
                    }
                  }

                  setDefaultMedicineCategoryUrl(medicationMaps.all_url)
                }}
                helperText={undefined}
              />
            </Box>

            <Box px={1} width='35%' flexGrow={1} display='flex'>
              <ValueSetSelectSingle
                key={`${defaultMedicineCategoryUrl}medicines`}
                title='Select Medication'
                url={`http://wellopathy.com/fhir/india/core/ValueSet/${defaultMedicineCategoryUrl}`}
                id='kriyas'
                fuzzySearch={true}
                preSelectedOptions={currentMedicationTile.medicationCode}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    medicationCode: selectedKriya,
                  })
                }}
                helperText={undefined}
              />
            </Box>

            <Box px={1} width='15%'>
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloFormItemLabel title={t('labelDosage:resourceName')} />
                <WelloTextFieldWithoutTitle
                  textProps={{
                    id: `med_dosage`,
                    placeholder: 'Dosage',
                    error:
                      currentMedicationTile.dosageQuantity! <= 0 ||
                      currentMedicationTile.dosageQuantity! >= 10000 ||
                      Number.isNaN(currentMedicationTile.dosageQuantity!),
                    type: 'number',
                    value: currentMedicationTile.dosageQuantity,
                    helperText:
                      currentMedicationTile.dosageQuantity! <= 0 ||
                      Number.isNaN(currentMedicationTile.dosageQuantity!)
                        ? `${t('labelCommon:invlaid_dosages')}`
                        : '',
                    inputProps: {
                      min: 0,
                      max: 10000,
                      step: 0.1,
                    },

                    //   InputProps: {
                    //     endAdornment: (
                    //       <InputAdornment position='end'>
                    //         {getSuffixForDosage(currentMedicationTile.administrationCode)}
                    //       </InputAdornment>
                    //     ),
                    //   },
                    //   value: tests.testPrice,
                    onChange: (changeData) => {
                      if (changeData) {
                        setCurrentMedicationTile({
                          ...currentMedicationTile,
                          dosageQuantity: parseFloat(
                            parseFloat(changeData.target.value).toFixed(1)
                          ),
                        })
                        /* setSelectedDosage(
                                parseFloat(
                                  parseFloat(changeData.target.value).toFixed(1)
                                )
                              ) */
                      }
                    },
                  }}
                />
              </Box>
            </Box>

            <Box width='15%' px={1}>
              <WelloSelect
                label='Unit'
                availableOptions={oralUnits}
                required={true}
                preSelectedOption={
                  currentMedicationTile.dosageQuantityTypeCoding
                }
                id='med_units'
                type='Choose Unit'
                displayField='display'
                valueField='code'
                onSelectionChanged={(value: any) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    dosageQuantityType: value.code,
                  })

                  // setDosageType(value.code)
                }}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box display='flex' flexDirection='row' width='30%' px={1}>
              <WelloSelectFHIRCoding
                title='Route Of Administration'
                availableCodings={routesOfAdministration}
                id='route_type'
                onChanged={(e) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    administrationCode: e.code ?? '',
                  })
                }}
                preSelectedCoding={
                  currentMedicationTile.administrationCoding ?? {
                    system: 'http://snomed.info/sct',
                    code: '26643006',
                    display: 'Oral',
                  }
                }
                textFieldProps={{
                  size: 'small',
                  fullWidth: true,
                }}
              />
            </Box>

            <Box px={1} width='20%'>
              <ValueSetSelectSingle
                title='Oushadhakala'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-aushadha-kala-vs'
                id='med_oushadhakala'
                fuzzySearch={true}
                preSelectedOptions={currentMedicationTile.aushadhaKalaCode}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    aushadhaKalaCode: selectedKriya,
                  })
                }}
                helperText={undefined}
              />
            </Box>

            <Box px={1} width='20%'>
              <WelloSelectFHIRCoding
                title='Anupana'
                onChanged={(selectedKriya) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    anupanaCode: selectedKriya,
                  })
                }}
                preSelectedCoding={preselectedMedication.anupanaCode}
                availableCodings={anupanas}
                id='med_anupana'
                textFieldProps={{
                  size: 'small',
                  fullWidth: true,
                }}
              />
              {/*   <ValueSetSelectSingle
                    title='Anupana'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-common-anupanas-vs'
                    id='med_anupana'
                    fuzzySearch={true}
                    preSelectedOptions={currentMedicationTile.medicationCode}
                    disabled={false}
                    onOptionSelected={(selectedKriya) => {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        anupanaCode: selectedKriya,
                      })
                    }}
                    helperText={undefined}
                  /> */}
            </Box>

            <Box px={1} width='15%'>
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloFormItemLabel title={t('labelDosage:resourceName')} />
                <WelloTextFieldWithoutTitle
                  textProps={{
                    id: `anupana_dosage`,
                    placeholder: 'Dosage',
                    error:
                      currentMedicationTile.anupanaDosageQuantity! <= 0 ||
                      currentMedicationTile.anupanaDosageQuantity! >= 10000 ||
                      Number.isNaN(
                        currentMedicationTile.anupanaDosageQuantity!
                      ),
                    type: 'number',
                    value: currentMedicationTile.anupanaDosageQuantity,
                    helperText:
                      currentMedicationTile.anupanaDosageQuantity! <= 0 ||
                      Number.isNaN(currentMedicationTile.anupanaDosageQuantity!)
                        ? `${t('labelCommon:invlaid_dosages')}`
                        : '',
                    inputProps: {
                      min: 0,
                      max: 10000,
                      step: 0.1,
                    },

                    //   InputProps: {
                    //     endAdornment: (
                    //       <InputAdornment position='end'>
                    //         {getSuffixForDosage(currentMedicationTile.administrationCode)}
                    //       </InputAdornment>
                    //     ),
                    //   },
                    //   value: tests.testPrice,
                    onChange: (changeData) => {
                      if (changeData) {
                        setCurrentMedicationTile({
                          ...currentMedicationTile,
                          anupanaDosageQuantity: parseFloat(
                            parseFloat(changeData.target.value).toFixed(1)
                          ),
                        })
                        /* setSelectedDosage(
                                parseFloat(
                                  parseFloat(changeData.target.value).toFixed(1)
                                )
                              ) */
                      }
                    },
                  }}
                />
              </Box>
            </Box>

            <Box width='15%' px={1}>
              <WelloSelect
                label='Unit'
                availableOptions={oralUnits}
                required={true}
                id='anupana_units'
                type='Choose Unit'
                displayField='display'
                preSelectedOption={
                  currentMedicationTile.anupanaDosageQuantityTypeCoding
                }
                valueField='code'
                onSelectionChanged={(value: any) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    anupanaDosageQuantityType: value.code,
                  })

                  // setDosageType(value.code)
                }}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box display='flex' px={1} width='33%'>
              {addMore && (
                <TabbedMultiSelector
                  size='small'
                  preSelectedValues={['morning']}
                  menuLabel={t(
                    'labelDosage:attr_labels.Dosage.timing'
                  ).toLowerCase()}
                  options={[
                    {
                      displayText: `${t('labelCommon:morning')}`,
                      value: 'morning',
                    },
                    {
                      displayText: `${t('labelCommon:afternoon')}`,
                      value: 'afternoon',
                    },
                    {
                      displayText: `${t('labelCommon:Evening')}`,
                      value: 'evening',
                    },
                    {
                      displayText: `${t('labelCommon:night')}`,
                      value: 'night',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      timeOfDayCode: selectedValue,
                    })
                    // setSelectedTimeOfDay(selectedValue)
                  }}
                />
              )}
              {addMore === false && (
                <TabbedMultiSelector
                  size='small'
                  preSelectedValues={currentMedicationTile.timeOfDayCode ?? []}
                  menuLabel={t(
                    'labelDosage:attr_labels.Dosage.timing'
                  ).toLowerCase()}
                  options={[
                    {
                      displayText: `${t('labelCommon:morning')}`,
                      value: 'morning',
                    },
                    {
                      displayText: `${t('labelCommon:afternoon')}`,
                      value: 'afternoon',
                    },
                    {
                      displayText: `${t('labelCommon:Evening')}`,
                      value: 'evening',
                    },
                    {
                      displayText: `${t('labelCommon:night')}`,
                      value: 'night',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      timeOfDayCode: selectedValue,
                    })
                    // setSelectedTimeOfDay(selectedValue)
                  }}
                />
              )}
            </Box>
            <Box px={1} width='33%'>
              {addMore === false && (
                <CustomizedDividers
                  key='_date_part'
                  size='small'
                  menuLabel='When'
                  options={[
                    {
                      displayText: `${t('labelCommon:today')}`,
                      value: 'today',
                    },
                    {
                      displayText: `${t('labelCommon:tomorrow')}`,
                      value: 'tomo',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setSelectedDatePart(selectedValue)
                    if (selectedValue === 'today') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        startFrom: new Date(),
                        till: getAddedDate(0),
                      })
                    }
                    if (selectedValue === 'tomo') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        startFrom: getTomorrowDate(),
                        till: getAddedDate(1),
                      })
                    }
                  }}
                  preSelectedValue={selectedDatePart}
                />
              )}
              {addMore && (
                <CustomizedDividers
                  key='_date_part'
                  size='small'
                  menuLabel='When'
                  options={[
                    {
                      displayText: `${t('labelCommon:today')}`,
                      value: 'today',
                    },
                    {
                      displayText: `${t('labelCommon:tomorrow')}`,
                      value: 'tomo',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setSelectedDatePart(selectedValue)
                    if (selectedValue === 'today') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        startFrom: new Date(),
                        till: getAddedDate(0),
                      })
                    }
                    if (selectedValue === 'tomo') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        startFrom: getTomorrowDate(),
                        till: getAddedDate(1),
                      })
                    }
                  }}
                  preSelectedValue={selectedDatePart}
                />
              )}
            </Box>
          </Box>

          <Box display='flex' flexDirection='column' marginLeft={1}>
            {addedMedications.length > 0 && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                px={1}
                py={2}
                pl={1}
              >
                <AyurvedaMedicationsTempList
                  medicationsList={addedMedications}
                />
              </Box>
            )}
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              flexGrow={1}
              paddingLeft={1}
              paddingRight={1.5}
            >
              <WelloFormItemLabel title='Additional Notes' />

              <TextField
                size='small'
                fullWidth
                multiline
                id='txt_edit_ayurveda_additional_notes'
                type='string'
                value={currentMedicationTile.notes}
                variant='outlined'
                onChange={(event) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    notes: event.target.value,
                  })
                  // setAdditionalNotes(event.target.value)
                }}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box
          display='flex'
          flexDirection='row'
          py={1}
          paddingRight={1.5}
          paddingLeft={1}
          flexGrow={1}
          alignItems='center'
          justifyContent='flex-end'
        >
          {updateStatus.requesting && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          {/* <Box flexGrow={1} alignSelf='center' paddingX={1}>
            <FormControlLabel
              value='end'
              control={
                <Checkbox
                  inputProps={{
                    id: 'add_another',
                  }}
                  color='primary'
                  checked={addAnother}
                  onChange={(eve, checked) => {
                    setAddAnother(checked)
                  }}
                />
              }
              label={t('labelCommon:add_another_medication')}
              labelPlacement='end'
            />
          </Box> */}
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  resetForm()
                  setAddedMedications([])
                  onClose()
                }}
                disabled={updateStatus.requesting}
                variant='outlined'
                disableElevation
                id='cancel'
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>

            <Box paddingX={1}>
              <Button
                onClick={() => {
                  if (
                    currentMedicationTile.medicationCode !== undefined &&
                    currentMedicationTile.dosageQuantity !== undefined &&
                    currentMedicationTile.dosageQuantityType !== undefined
                  ) {
                    postMedicationDetails()
                  }
                }}
                variant='contained'
                color='primary'
                disableElevation
                id='save'
                disabled={
                  currentMedicationTile.medicationCode === undefined ||
                  currentMedicationTile.dosageQuantity === undefined ||
                  currentMedicationTile.dosageQuantity! <= 0 ||
                  currentMedicationTile.dosageQuantityType === undefined ||
                  (currentMedicationTile.timeOfDayCode ?? []).length === 0 ||
                  isOtherSelected() ||
                  addMedicationsSlice.adding ||
                  validateOthers(currentMedicationTile.otherDataText!, false)
                    .length > 0
                }
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
