import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CheckCircle, CloseOutlined } from '@material-ui/icons'
import { PractitionerWorkHour } from 'models/businessHoursHelper'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { WelloOrgDetails } from 'models/welloUnit'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { sendDownlinkInviteForAdmin } from 'redux/patient/patientSearch/downloadApkLinkForAdmin/downloadLinkSliceAdmin'
import { resendInviteForAdmin } from 'redux/patient/resendInvite/resendInviteAdmin/resendInviteAdminSlice'
import { updatePractitionerDetails } from 'redux/practitioner/PractitionerManagement/practionerupdateSlice'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { RootState } from 'redux/rootReducer'
import { getEducationalColleges } from 'services/fileServices'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
  isCurrentUnitIsClinic,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import {
  getCodeOfSystemFromCodableConceptList,
  getCustomDays,
  getDefaultCodeOfSystemFromCodableConcept,
  getWorkingHours,
} from 'utils/fhirResourcesHelper'
import { UserDetailsComponent } from 'views/components/administration/actors/actor-details/user-details-component'
import { WelloTabs } from 'views/components/LeftMenu/WelloTabs'
import { UserTileWithRole } from './userTile'
import { UserTitleWithoutDetail } from './userTileWithoutDetail'

interface Props {
  businessHoursData: PractitionerWorkHour[]
  userDetailsData: PractitionerWithRole
  isOdd?: boolean
  onCloseClicked?: () => void
  onCloseClickedWithoutChange?: () => void
  dense?: boolean
  onEnabled?: () => void
}

export const UserDetails: React.FC<Props> = ({
  businessHoursData,
  userDetailsData,
  isOdd = false,
  onCloseClicked,
  onCloseClickedWithoutChange,
  dense = false,
  onEnabled,
}: Props) => {
  const practitionerSearchSlice = useSelector(
    (state: RootState) => state.practitionerManagement
  )

  const practitionerEditSlice = useSelector(
    (state: RootState) => state.practitionerEditSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const roles: R4.ICodeableConcept[] = userDetailsData.roleObject.code ?? []

  const active = userDetailsData.status ?? ''
  let roleData: string[] = []
  const name: string = userDetailsData.name ?? ''
  if (roles.length > 0) {
    if (roles[0] && roles[0].coding && roles[0].coding[0]) {
      roleData = roles.map(
        (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
      )
    }
  }
  const [showCancelHandler, setShowCancelHandler] = useState<boolean>(false)
  const [partnerDetails, setPartnerDetails] =
    useState<PractitionerWithRole>(userDetailsData)
  const [userDetails, setUserDetails] = useState<PractitionerWithRole>()
  const menu = ['profile', 'working_hours']
  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const [showEditPopUp, setShowEditPopup] = React.useState(false)
  const locationDetails = getCurrentUserUnitLocationDetails()

  const businessHours = getWorkingHours(locationDetails.hoursOfOperation ?? [])
  const dayType = getCustomDays(locationDetails.hoursOfOperation ?? [])
  const hiddenDays: number[] = []
  const weekDays: string[] = []

  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  if (Array.isArray(dayType) && dayType.length) {
    dayType.forEach((item) => {
      if (item.includes('S') || item.includes('Su')) {
        weekDays.push('1')
      }
    })
  }
  if (Array.isArray(dayType) && dayType.length) {
    const wedData = dayType.filter((dayName: string) => dayName === 'W')
    if (wedData.length === 0) hiddenDays.push(3)
    const tueSdayData = dayType.filter((dayName: string) => dayName === 'T')
    if (tueSdayData.length === 0) hiddenDays.push(2)
    const thData = dayType.filter((dayName: string) => dayName === 'Th')
    if (thData.length === 0) hiddenDays.push(4)
    const fData = dayType.filter((dayName: string) => dayName === 'F')
    if (fData.length === 0) hiddenDays.push(5)
    const sData = dayType.filter((dayName: string) => dayName === 'S')
    if (sData.length === 0) hiddenDays.push(6)
    const suData = dayType.filter((dayName: string) => dayName === 'Su')
    if (suData.length === 0) hiddenDays.push(0)
    const mData = dayType.filter((dayName: string) => dayName === 'M')
    if (mData.length === 0) hiddenDays.push(1)
  }

  const [eduColleges, setEduCollegs] = useState<WelloOrgDetails[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [roleSpecificFormErrors, setRoleSpecificFormErrors] =
    useState<boolean>(false)
  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function getCollegesList() {
    setPaymentStatus({ requesting: true })

    setLoading(true)

    getEducationalColleges()
      .then((e: WelloOrgDetails[]) => {
        setLoading(false)
        if (e) {
          setEduCollegs(e)
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching colleges list. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    getCollegesList()
  }, [])

  useEffect(() => {
    dispatch(
      fetchUser(partnerDetails.practRoleId ?? '', partnerDetails.unitDetails)
    )
    return () => {}
  }, [])

  useEffect(() => {
    if (practitionerSearchSlice.resultsAvailable) {
      if (practitionerSearchSlice.userDetails) {
        setUserDetails(practitionerSearchSlice.userDetails)
      }
    }
    if (practitionerEditSlice.updateSuccessful) {
      if (onEnabled) onEnabled()
    }
    return () => {}
  }, [
    onEnabled,
    practitionerEditSlice.updateSuccessful,
    practitionerSearchSlice.resultsAvailable,
    practitionerSearchSlice.userDetails,
  ])

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
  }

  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 2,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 4,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
            >
              <Box paddingTop={4}>
                <WelloTabs
                  preSelectedTab={t('labelCommon:' + `${selectedTab}`)}
                  onTabChange={(e) => handleChangeTab(e)}
                  menu={menu}
                />
              </Box>
              <Box display='flex' flexDirection='row'>
                {selectedTab === menu[0] && (
                  <Box display='flex' flexDirection='row'>
                    {userDetailsData.enabled === true &&
                      !roleData.includes('unit-admin') && (
                        <Box paddingTop={1.2}>
                          <Tooltip title='Disable'>
                            <IconButton
                              aria-label='btn_ord_cancel'
                              color='primary'
                              // disabled={isUnitAdmin()}
                              onClick={() => {
                                if (
                                  userDetails &&
                                  userDetails.practitionerObject
                                )
                                  dispatch(
                                    updatePractitionerDetails(
                                      userDetails.roleObject,
                                      userDetails.practitionerObject
                                    )
                                  )
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/remove.png`}
                                alt='remove'
                                height={20}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}

                    {userDetailsData.enabled === false &&
                      !roleData.includes('unit-admin') && (
                        <Box paddingTop={1.4}>
                          <Tooltip title='Enable'>
                            <IconButton
                              aria-label='btn_ord_cancel'
                              color='primary'
                              //   disabled={isUnitAdmin()}
                              onClick={() => {
                                if (
                                  userDetails &&
                                  userDetails.practitionerObject
                                )
                                  dispatch(
                                    updatePractitionerDetails(
                                      userDetails.roleObject,
                                      userDetails.practitionerObject
                                    )
                                  )
                              }}
                            >
                              <CheckCircle color='primary' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}

                    {loggedInUnitType === 'lab' && (
                      <Box>
                        {userDetailsData.status !== 'Active' &&
                          (roleData.includes('unit-admin') ||
                            roleData.includes('lab-agent') ||
                            roleData.includes('doctor-partner')) && (
                            <Box paddingTop={1.3}>
                              <Tooltip title='Resend Invite'>
                                <IconButton
                                  aria-label='btn_ord_reschedule'
                                  color='primary'
                                  onClick={() => {
                                    dispatch(
                                      resendInviteForAdmin(
                                        userDetails ? userDetails.id : '',
                                        userDetailsData.roleObject
                                      )
                                    )
                                  }}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/Send_Invite.ico`}
                                    alt='delete'
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                      </Box>
                    )}

                    {(isCurrentUnitIsClinic() ||
                      isCurrentUnitIsWellonessCenter()) && (
                      <Box>
                        {userDetailsData.status === 'Inactive' &&
                          (roleData.includes('unit-admin') ||
                            roleData.includes('doctor') ||
                            roleData.includes('receptionist') ||
                            roleData.includes('106296000')) && (
                            <Box paddingTop={1.3}>
                              <Tooltip title='Resend Invite'>
                                <IconButton
                                  aria-label='btn_ord_reschedule'
                                  color='primary'
                                  onClick={() => {
                                    dispatch(
                                      resendInviteForAdmin(
                                        userDetails ? userDetails.id : '',
                                        userDetailsData.roleObject
                                      )
                                    )
                                  }}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/Send_Invite.ico`}
                                    alt='delete'
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                      </Box>
                    )}

                    <Box>
                      {userDetails?.status === 'Active' &&
                        roleData.includes('lab-agent') && (
                          <Box paddingTop={1.5}>
                            <Tooltip title='Send APK Link'>
                              <IconButton
                                aria-label='btn_ord_reschedule'
                                color='primary'
                                onClick={() => {
                                  dispatch(
                                    sendDownlinkInviteForAdmin(
                                      userDetails ? userDetails.id : '',
                                      roleData
                                    )
                                  )
                                }}
                                tabIndex={0}
                              >
                                <img
                                  src={`${process.env.PUBLIC_URL}/Send_APK_Link.ico`}
                                  alt='delete'
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                    </Box>
                  </Box>
                )}
                <Box paddingTop={1.2}>
                  <Tooltip title={t('close') ?? 'Re-Schedule'}>
                    <IconButton
                      aria-label='btn_ord_detail_menu'
                      onClick={onCloseClickedWithoutChange}
                    >
                      <CloseOutlined color='primary' tabIndex={0} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              style={{ width: '100%', height: '2px' }}
              color='primary'
            />
            {selectedTab === menu[0] && (
              <Box>
                <Box flexDirection='row' width='100%' display='flex' p={1}>
                  {practitionerSearchSlice.searching && (
                    <Box
                      flexDirection='row'
                      display='flex'
                      alignSelf='center'
                      justifyContent='center'
                    >
                      <CircularProgress size={25} />
                    </Box>
                  )}
                </Box>
                {practitionerSearchSlice.userDetails && (
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box
                      display='flex'
                      flexDirection='column'
                      width={roleData.includes('doctor') ? '100%' : '100%'}
                    >
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box height='100%'>
                            {(roleData.includes('doctor') ||
                              roleData.includes('ayurveda') ||
                              roleData.includes('dietician') ||
                              roleData.includes('36682004') ||
                              roleData.includes('1255370008') ||
                              roleData.includes('106296000')) && (
                              <UserTileWithRole
                                userDetails={userDetailsData}
                                colleges={eduColleges}
                                onDone={() => {
                                  if (onCloseClicked) onCloseClicked()
                                }}
                              />
                            )}
                            {!roleData.includes('doctor') &&
                              !roleData.includes('ayurveda') &&
                              !roleData.includes('dietician') &&
                              !roleData.includes('36682004') &&
                              !roleData.includes('1255370008') &&
                              !roleData.includes('106296000') && (
                                <UserTitleWithoutDetail
                                  userDetails={userDetailsData}
                                  role={roleData}
                                  onDone={() => {
                                    if (onCloseClicked) onCloseClicked()
                                  }}
                                />
                              )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {selectedTab === menu[1] && (
              <Box>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      {userDetails && (
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box>
                            {(roleData.includes('lab-agent') ||
                              roleData.includes('doctor') ||
                              roleData.includes('ayurveda') ||
                              roleData.includes('dietician') ||
                              roleData.includes('36682004') ||
                              roleData.includes('1255370008') ||
                              roleData.includes('106296000')) && (
                              <Box>
                                {active === 'Active' && (
                                  <UserDetailsComponent
                                    businessHoursData={businessHoursData}
                                    selectedUser={userDetails}
                                    shoWFullWeek={weekDays.length >= 1}
                                    businessHours={businessHours}
                                    hiddenDays={hiddenDays}
                                  />
                                )}

                                {active === 'Inactive' && (
                                  <Grid
                                    container
                                    justifyContent='center'
                                    alignContent='center'
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        paddingTop: 20,
                                        paddingBottom: 20,
                                      }}
                                    >
                                      <Grid container justifyContent='center'>
                                        <Typography
                                          variant='subtitle1'
                                          color='error'
                                          align='center'
                                        >
                                          {name} has not accepted invitation
                                          yet.
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </Box>
                            )}

                            {(roleData.includes('org-admin') ||
                              roleData.includes('unit-admin') ||
                              roleData.includes('wp-admin') ||
                              roleData.includes('receptionist') ||
                              roleData.includes('partner-lab')) && (
                              <Grid
                                container
                                justifyContent='center'
                                alignContent='center'
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: 20, paddingBottom: 20 }}
                                >
                                  <Grid container justifyContent='center'>
                                    {roleData.includes('ayurveda') ===
                                      false && (
                                      <Typography
                                        variant='subtitle1'
                                        color='primary'
                                        align='center'
                                      >
                                        Working Hour can only be set for Agents
                                        and Doctors
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
