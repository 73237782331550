import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Theme,
  makeStyles,
} from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import { Autocomplete } from '@material-ui/lab'
import {
  CloseOutlined,
  Delete,
  DeleteOutlineOutlined,
  ClearOutlined,
} from '@material-ui/icons'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight, kDialogueBackground } from 'configs/styles/muiThemes'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/addAllergy/addAllergySlice'
import { RootState } from 'redux/rootReducer'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'

import { WelloLoadingIndicator, WelloTextField } from 'wello-web-components'
import { validateCheckoutDateForChiefComplaints } from 'utils/formValidators'
import {
  getAgeInDays,
  getAgeInMonths,
  getAgeInWeeks,
  getAgeInYears,
  getAgeOfPatientForChecking,
} from 'utils/fhirResourcesHelper'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import {
  durationData,
  durationDataForChiefComplaints,
  durationDataForChiefComplaintsWithOne,
  durationDataWithOne,
} from 'utils/constants'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { RadioRaisedButton } from 'views/components/LeftMenu/RadioButtons'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloAllergy } from 'models/WelloAllergy'
import _ from 'lodash'
import { AllergyMasterChosser } from '../subjective/allergyMasterChosser'
import { AllergyTileForDisplay } from './allergyTile'

interface Props {
  open: boolean
  patient: R4.IPatient
  onClose: () => void
  onAllergiesDetailsAdded: (
    createdCondition: R4.IAllergyIntolerance | undefined
  ) => void
  appointmentId?: string
}
export const AddAllergyDetailsHandler: React.FC<Props> = ({
  open,
  onClose,
  onAllergiesDetailsAdded,
  patient,
  appointmentId,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '40%',
    },
  }))
  const classes = useStyles()

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  )
  const [otherData, setOtherData] = useState<string>()
  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [selectedSeverity, setSeverity] =
    useState<R4.AllergyIntoleranceCategoryKind>()
  const [isActive, setIsActive] = useState<boolean>(true)
  const handleDateChange = (date: MaterialUiPickersDate) => {
    setCurrentCondition({
      ...currentCondition,
      selectedDate: date !== null ? moment(date).toDate() : new Date(),
    })
  }
  const [currentCondition, setCurrentCondition] = useState<WelloAllergy>({
    id: _.random(1, 10000000).toString(),
    selectedType: 'days',
    days: 1,
    month: 1,
    year: 1,
    week: 1,
    selectedDate: new Date(),
    isActive: true,
  })
  const [addedConditions, setAddedConditions] = useState<WelloAllergy[]>([])
  const [typeData, setTypeData] = useState('days')
  const [days, setDays] = React.useState<number>(1)
  const [weeks, setWeeks] = React.useState<number>(1)
  const [month, setMonth] = React.useState<number>(1)
  const [year, setYear] = React.useState<number>(1)
  const [other, setOther] = useState<boolean>(false)

  function handleMonths(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      month: rate,
    })
  }
  function handleYear(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      year: rate,
    })
  }

  function handleDays(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      days: rate,
    })
  }

  function handleWeeks(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      week: rate,
    })
  }

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloAllergy[] = [...addedConditions]
    const newMedication: WelloAllergy = {
      ...currentCondition,
    }

    tempMedication.push(newMedication)

    Promise.resolve().then(() => {
      setAddedConditions(tempMedication)
      resetDetails()
    })

    if (pushToSave) {
      dispatch(addAllergiesDetails(patient, tempMedication, appointmentId))
    }
  }

  function resetDetails() {
    setCurrentCondition({
      id: _.random(1, 10000000).toString(),
      selectedType: 'days',
      days: 1,
      month: 1,
      year: 1,
      week: 1,
      selectedDate: new Date(),
      isActive: true,
    })
  }
  function isEnableCreateButton(): boolean | undefined {
    let result: boolean = false
    if (currentCondition.condition === undefined) {
      result = true
    }
    if (currentCondition.condition !== undefined) {
      if (currentCondition.selectedSeverity === undefined) {
        result = true
      }
      if (currentCondition.selectedType) {
        if (
          currentCondition.selectedType === 'days' &&
          currentCondition.days &&
          (currentCondition.days < 1 ||
            currentCondition.days > getAgeInDays(patient))
        ) {
          result = true
        }
        if (
          currentCondition.selectedType === 'months' &&
          currentCondition.month &&
          (currentCondition.month < 1 ||
            currentCondition.month > getAgeInMonths(patient))
        ) {
          result = true
        }
        if (
          currentCondition.selectedType === 'years' &&
          currentCondition.year &&
          (currentCondition.year < 1 ||
            currentCondition.year > getAgeInYears(patient))
        ) {
          result = true
        }
        if (
          currentCondition.selectedType === 'weeks' &&
          currentCondition.week &&
          (currentCondition.week < 1 ||
            currentCondition.week > getAgeInWeeks(patient))
        ) {
          result = true
        }
        if (
          currentCondition.selectedType === 'custom' &&
          currentCondition.selectedDate &&
          validateCheckoutDateForChiefComplaints(
            moment(currentCondition.selectedDate).format('YYYY-MM-DD'),
            getAgeOfPatientForChecking(patient),
            false
          ).length > 0
        ) {
          result = true
        } else {
          result = false
        }
      }
    }

    return result
  }

  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const dispatch = useDispatch()

  const addAllergySlice = useSelector(
    (state: RootState) => state.addAllergySlice
  )

  function isOtherSelected(): boolean {
    if (other === true && !otherData) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (addAllergySlice.additionSuccessful) {
      onAllergiesDetailsAdded(addAllergySlice.allergy)
      dispatch(resetAddAllergiesState())
      setSeverity(undefined)
      setSelectedCondition(undefined)
      setIsActive(true)
      setAdditionalNotes(undefined)
      setSelectedDate(new Date())
      setOtherData(undefined)
      setOther(false)
      setTypeData('days')
      setDays(1)
      setWeeks(1)
      setMonth(1)
      setAddedConditions([])
    }
  }, [dispatch, addAllergySlice, onAllergiesDetailsAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
        setSeverity(undefined)
        setSelectedCondition(undefined)
        setIsActive(false)
        setSelectedDate(new Date())
        setOtherData(undefined)
        setOther(false)
        setTypeData('days')
        setDays(1)
        setWeeks(1)
        setMonth(1)
        setAddedConditions([])
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:add_allergy')}
      </DialogTitle>
      <DialogContent dividers={true}>
        {addAllergySlice.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!addAllergySlice.adding && (
          <Box
            marginX={1}
            paddingBottom={1}
            key={currentCondition.id}
            display='flex'
            flexDirection='column'
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container direction='column' spacing={1} wrap='nowrap'>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Box display='flex' flexDirection='row'>
                        <WelloFormItemLabel title='Select Allergies' />{' '}
                      </Box>
                      <AllergyMasterChosser
                        disabled={false}
                        onSelectionChanges={(allergy: UmlResponse) => {
                          if (allergy.cui === 'C2590123') {
                            setOther(true)
                          } else {
                            setOther(false)
                          }
                          const conditonVal1: R4.ICoding = {
                            system:
                              'http://terminology.hl7.org/CodeSystem/umls',
                            code: allergy.cui,
                            display: allergy.display.trim(),
                          }
                          setCurrentCondition({
                            ...currentCondition,
                            condition: conditonVal1,
                          })
                        }}
                      />
                      {other && (
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='column'
                          flexGrow={1}
                        >
                          <WelloTextField
                            title='Any Other'
                            textProps={{
                              id: `al19`,
                              type: 'text',
                              error:
                                validateOthers(otherData!, false).length > 0,
                              helperText:
                                validateOthers(otherData!, false).length > 0
                                  ? `${validateOthers(otherData!, false)}`
                                  : '',
                              onChange: (changeData) => {
                                setCurrentCondition({
                                  ...currentCondition,
                                  otherData: changeData.target.value,
                                })
                              },
                            }}
                          />
                        </Box>
                      )}
                      {/* <SingleSelectWithTextSearch
                        id='add_allergy'
                        availableOptions={allergyOptions}
                        detachLabel={true}
                        displayField='display'
                        isInEditMode={true}
                        labelString='Select Allergy'
                        onAddClicked={(selectedValue) => {
                          setSelectedCondition(selectedValue as R4.ICoding)
                        }}
                        placeHolderString='Select Allergy'
                        valuesField='code'
                        selectedValue={selectedCondition ?? {}}
                      >
                        {' '}
                        Chief Complaint{' '}
                      </SingleSelectWithTextSearch> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction='row' wrap='nowrap'>
                    <Grid
                      item
                      xs={
                        currentCondition.selectedType &&
                        currentCondition.selectedType === 'days'
                          ? 8
                          : currentCondition.selectedType &&
                            currentCondition.selectedType === 'custom'
                          ? 6
                          : 7
                      }
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        paddingTop={1}
                      >
                        <WelloFormItemLabel title='Onset Duration' />{' '}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'days' && (
                            <Box paddingLeft={1} width='70%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  id='onset_days'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    currentCondition.days! === 1 ||
                                    currentCondition.week! === 1
                                      ? durationDataWithOne[0]
                                      : durationData[0]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.days! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      id='al20'
                                      variant='outlined'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                              {/* <WelloSelectUnit
                                title=''
                                duration='Select Duration Unit'
                                availableCodings={
                                  currentCondition.days! === 1
                                    ? durationDataForChiefComplaintsWithOne
                                    : durationDataForChiefComplaints
                                }
                                onChanged={(type) => {
                                  if (type.display && type.display.length > 0) {
                                    if (
                                      type.display === 'Days' ||
                                      type.display === 'Day'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'days',
                                      })
                                    else if (
                                      type.display === 'Months' ||
                                      type.display === 'Month'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'months',
                                      })
                                    else if (
                                      type.display === 'Weeks' ||
                                      type.display === 'Week'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'weeks',
                                      })
                                    else if (
                                      type.display === 'Years' ||
                                      type.display === 'Year'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'years',
                                      })
                                    else
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'custom',
                                      })
                                  }
                                }}
                                textFieldProps={{
                                  size: 'small',
                                  fullWidth: true,
                                  id: `al20`,
                                }}
                                preSelectedCoding={
                                  currentCondition.days! === 1 ||
                                  currentCondition.week! === 1
                                    ? durationDataWithOne[0]
                                    : durationData[0]
                                }
                              /> */}
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'weeks' && (
                            <Box paddingLeft={1} width='65%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  id='onset_weeks'
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    currentCondition.week! === 1
                                      ? durationDataWithOne[1]
                                      : durationData[1]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.week! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      variant='outlined'
                                      id='al21'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                              {/* <WelloSelectUnit
                                title=''
                                duration='Select Duration Unit'
                                availableCodings={
                                  currentCondition.week! === 1
                                    ? durationDataForChiefComplaintsWithOne
                                    : durationDataForChiefComplaints
                                }
                                onChanged={(type) => {
                                  if (type.display && type.display.length > 0) {
                                    if (
                                      type.display === 'Days' ||
                                      type.display === 'Day'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'days',
                                      })
                                    else if (
                                      type.display === 'Months' ||
                                      type.display === 'Month'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'months',
                                      })
                                    else if (
                                      type.display === 'Weeks' ||
                                      type.display === 'Week'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'weeks',
                                      })
                                    else if (
                                      type.display === 'Years' ||
                                      type.display === 'Year'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'years',
                                      })
                                    else
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'custom',
                                      })
                                  }
                                }}
                                textFieldProps={{
                                  size: 'small',
                                  fullWidth: true,
                                  id: `al21`,
                                }}
                                preSelectedCoding={
                                  currentCondition.week! === 1
                                    ? durationDataWithOne[1]
                                    : durationData[1]
                                }
                              /> */}
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'months' && (
                            <Box paddingLeft={1} width='65%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  id='onset_months'
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    currentCondition.month! === 1
                                      ? durationDataWithOne[2]
                                      : durationData[2]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.month! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      variant='outlined'
                                      id='al22'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'years' && (
                            <Box paddingLeft={1} width='65%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  id='onset_years'
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    currentCondition.year! === 1
                                      ? durationDataWithOne[3]
                                      : durationData[3]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.year! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      variant='outlined'
                                      id='al23'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                                {/* <WelloSelectUnit
                                  title=''
                                  id='onset_years'
                                  duration='Select Duration Unit'
                                  availableCodings={
                                    currentCondition.year! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChanged={(type) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                    // setDuration(type.display ?? '')
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                    id: 'med_9',
                                  }}
                                  preSelectedCoding={
                                    currentCondition.year! === 1
                                      ? durationDataWithOne[3]
                                      : durationData[3]
                                  }
                                /> */}
                              </Box>
                              {/* <WelloSelectUnit
                                title=''
                                duration='Select Duration Unit'
                                availableCodings={
                                  currentCondition.year! === 1
                                    ? durationDataForChiefComplaintsWithOne
                                    : durationDataForChiefComplaints
                                }
                                onChanged={(type) => {
                                  if (type.display && type.display.length > 0) {
                                    if (
                                      type.display === 'Days' ||
                                      type.display === 'Day'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'days',
                                      })
                                    else if (
                                      type.display === 'Months' ||
                                      type.display === 'Month'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'months',
                                      })
                                    else if (
                                      type.display === 'Weeks' ||
                                      type.display === 'Week'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'weeks',
                                      })
                                    else if (
                                      type.display === 'Years' ||
                                      type.display === 'Year'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'years',
                                      })
                                    else
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'custom',
                                      })
                                  }
                                }}
                                textFieldProps={{
                                  size: 'small',
                                  fullWidth: true,
                                  id: `al23`,
                                }}
                                preSelectedCoding={
                                  currentCondition.year! === 1
                                    ? durationDataWithOne[3]
                                    : durationData[3]
                                }
                              /> */}
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'custom' && (
                            <Box paddingLeft={1} width='59%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  id='onset_custom'
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    durationDataForChiefComplaints[4]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.days! === 1 &&
                                    currentCondition.month! === 1 &&
                                    currentCondition.week! === 1 &&
                                    currentCondition.year! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      variant='outlined'
                                      id='al24'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                          )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={
                        currentCondition.selectedType &&
                        currentCondition.selectedType === 'days'
                          ? 4
                          : currentCondition.selectedType &&
                            currentCondition.selectedType === 'custom'
                          ? 6
                          : 5
                      }
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        paddingTop={1}
                      >
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'days' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={4.5}
                            >
                              <WelloFormItemLabel title='Enter Days' />{' '}
                              <Box width='55%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `al25`,
                                    value: currentCondition.days!,
                                    type: 'number',
                                    inputProps: {
                                      max: 365,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.days! < 1 ||
                                      currentCondition.days! >
                                        getAgeInDays(patient) ||
                                      Number.isNaN(currentCondition.days!),
                                    helperText:
                                      currentCondition.days! < 1 ||
                                      currentCondition.days! >
                                        getAgeInDays(patient) ||
                                      Number.isNaN(currentCondition.days)
                                        ? `Days should be in between 1 to ${getAgeInDays(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleDays(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'weeks' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={4.5}
                            >
                              <WelloFormItemLabel title='Enter Weeks' />{' '}
                              <Box width='59.5%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `al26`,
                                    value: currentCondition.week!,
                                    type: 'number',
                                    inputProps: {
                                      max: 52,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.week! < 1 ||
                                      currentCondition.week! >
                                        getAgeInWeeks(patient) ||
                                      Number.isNaN(currentCondition.week!),
                                    helperText:
                                      currentCondition.week! < 1 ||
                                      currentCondition.week! > 52 ||
                                      Number.isNaN(currentCondition.week!)
                                        ? `Weeks should be in between 1 to ${getAgeInWeeks(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleWeeks(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'months' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={4.5}
                            >
                              <WelloFormItemLabel title='Enter Months' />{' '}
                              <Box width='57%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `al27`,
                                    value: currentCondition.month!,
                                    type: 'number',
                                    inputProps: {
                                      max: 12,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.month! < 1 ||
                                      currentCondition.month! >
                                        getAgeInMonths(patient) ||
                                      Number.isNaN(currentCondition.month!),
                                    helperText:
                                      currentCondition.month! < 1 ||
                                      currentCondition.month! >
                                        getAgeInMonths(patient) ||
                                      Number.isNaN(currentCondition.month!)
                                        ? `Months should be in between 1 to ${getAgeInMonths(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleMonths(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'years' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                              paddingLeft={4.5}
                            >
                              <WelloFormItemLabel title='Enter Years' />{' '}
                              <Box
                                width={
                                  currentCondition.selectedType &&
                                  currentCondition.selectedType === 'years'
                                    ? '63%'
                                    : '69%'
                                }
                                paddingLeft={1}
                              >
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `al28`,
                                    value: currentCondition.year!,
                                    type: 'number',
                                    inputProps: {
                                      max: 12,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.year! < 1 ||
                                      currentCondition.year! >
                                        getAgeInYears(patient) ||
                                      Number.isNaN(currentCondition.year!),
                                    helperText:
                                      currentCondition.year! < 1 ||
                                      currentCondition.year! >
                                        getAgeInYears(patient) ||
                                      Number.isNaN(currentCondition.year!)
                                        ? `Years should be in between 1 to ${getAgeInYears(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleYear(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'custom' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={4.5}
                            >
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Box paddingTop={0.4}>
                                  <WelloFormItemLabel title='Choose Date' />
                                </Box>
                                <Box width='67%' paddingLeft={0.5}>
                                  <KeyboardDatePicker
                                    clearable
                                    id='date'
                                    tabIndex={0}
                                    maxDate={moment(
                                      currentCondition.selectedDate &&
                                        currentCondition.selectedDate
                                    )}
                                    minDate={moment(
                                      getAgeOfPatientForChecking(patient)
                                    )}
                                    value={
                                      currentCondition.selectedDate
                                        ? currentCondition.selectedDate
                                        : new Date()
                                    }
                                    format='DD-MM-YYYY'
                                    onChange={handleDateChange}
                                    error={
                                      validateCheckoutDateForChiefComplaints(
                                        moment(selectedDate).format(
                                          'YYYY-MM-DD'
                                        ),
                                        getAgeOfPatientForChecking(patient),
                                        false
                                      ).length > 0
                                    }
                                    helperText={validateCheckoutDateForChiefComplaints(
                                      moment(selectedDate).format('YYYY-MM-DD'),
                                      getAgeOfPatientForChecking(patient),
                                      false
                                    )}
                                    size='small'
                                    inputVariant='outlined'
                                  />
                                </Box>
                              </MuiPickersUtilsProvider>
                            </Box>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box width='100%' flexDirection='row' display='flex'>
                    <Box width='20.5%' py={1.3} paddingLeft={0.3}>
                      <Typography variant='subtitle2'>
                        {t('labelCommon:Status')}{' '}
                      </Typography>
                    </Box>
                    <Box width='50%' paddingLeft={1.3}>
                      <Typography component='div'>
                        <Box px={0.5}>
                          <Grid component='label' container alignItems='center'>
                            <Box>
                              <Typography variant='subtitle2'>
                                Resolved
                              </Typography>
                            </Box>
                            <Box>
                              <Switch
                                checked={currentCondition.isActive!}
                                color='primary'
                                id='med_15'
                                onChange={(val: any, checked) => {
                                  setCurrentCondition({
                                    ...currentCondition,
                                    isActive: checked,
                                  })
                                }}
                                name='checkedA'
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography variant='subtitle2'>
                                Active{' '}
                              </Typography>
                            </Box>
                          </Grid>
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box width='100%' flexDirection='row' display='flex'>
                    <Box width='20%' paddingTop={0.3}>
                      <WelloFormItemLabel
                        title={t(
                          'labelAllergyTollerance:attr_labels.AllergyIntolerance.category'
                        )}
                      />
                    </Box>

                    <Box width='75%' paddingRight={2} paddingLeft={2}>
                      <RadioRaisedButton
                        allowChanging={true}
                        availableOptions={[
                          {
                            title: t(
                              'labelCommon:' +
                                `${R4.AllergyIntoleranceCategoryKind._food.toLocaleUpperCase()}`
                            ),
                            code: R4.AllergyIntoleranceCategoryKind._food,
                          },
                          {
                            title: t(
                              'labelCommon:' +
                                `${R4.AllergyIntoleranceCategoryKind._environment.toLocaleUpperCase()}`
                            ),
                            code: R4.AllergyIntoleranceCategoryKind
                              ._environment,
                          },

                          {
                            title: t(
                              'labelCommon:' +
                                `${R4.AllergyIntoleranceCategoryKind._biologic.toLocaleUpperCase()}`
                            ),
                            code: R4.AllergyIntoleranceCategoryKind._biologic,
                          },

                          {
                            title: t(
                              'labelCommon:' +
                                `${R4.AllergyIntoleranceCategoryKind._medication.toLocaleUpperCase()}`
                            ),
                            code: R4.AllergyIntoleranceCategoryKind._medication,
                          },
                        ]}
                        displayField='title'
                        onValueChanged={(val) => {
                          setCurrentCondition({
                            ...currentCondition,
                            selectedSeverity: val.code,
                          })
                          //   setSeverity(val.code)
                        }}
                        valuesField='code'
                        selectedValue={[]}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item>
                  <Grid container direction='column' wrap='nowrap'>
                    <Grid item>
                      <WelloFormItemLabel title='Additional Notes' />
                    </Grid>
                    <Grid item>
                      <TextField
                        size='small'
                        fullWidth
                        multiline
                        id='al_33'
                        type='number'
                        value={additionalNotes}
                        variant='outlined'
                        onChange={(event) => {
                          setCurrentCondition({
                            ...currentCondition,
                            notes: event.target.value,
                          })
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>

            <Box marginY={1} display='flex' flexDirection='column'>
              {addedConditions && addedConditions.length > 0 && (
                <AllergyTileForDisplay referrals={addedConditions} />
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          paddingRight={2.7}
        >
          <Button
            onClick={() => {
              onClose()
              setSeverity(undefined)
              setSelectedCondition(undefined)
              setIsActive(false)
              setSelectedDate(new Date())
              resetDetails()
              setAddedConditions([])
            }}
            variant='outlined'
            id='al_33'
            disableElevation
            disabled={addAllergySlice.adding}
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              setOtherData(undefined)
              addCurrentDetails()
            }}
            variant='contained'
            color='primary'
            id='add_more'
            disableElevation
            disabled={
              currentCondition.condition === undefined ||
              currentCondition.selectedSeverity === undefined
            }
          >
            {t('labelCommon:add_more_new')}
          </Button>
          <Button
            onClick={() => {
              if (
                currentCondition.condition !== undefined &&
                currentCondition.selectedSeverity !== undefined
              ) {
                addCurrentDetails(true)
              } else if (addedConditions.length > 0) {
                dispatch(
                  addAllergiesDetails(patient, addedConditions, appointmentId)
                )
              }
            }}
            variant='contained'
            color='primary'
            disableElevation
            id='al_34'
            disabled={
              addAllergySlice.adding ||
              (isEnableCreateButton() && addedConditions.length === 0)
            }
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
