import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  initiateDischarge,
  saveDischargeNotesForVisit,
} from 'redux/clinic/ipdmanagement/dischargeManagement/dischargeSearchSlice'
import { requestForDischarge } from 'redux/ipd/discharge/dischargeFindSlice'
import { RootState } from 'redux/rootReducer'
import { isPhysiotherapist } from 'services/userDetailsService'
import { discharge } from 'utils/constants'
import { getDefaultCodeOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import { getCodeOfObservationInDropDown } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextField } from 'wello-web-components'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../common/deleteConfirmationDialog'
import { DischargeDiagnosisForPT } from '../consultation/assessment/dischargeDiagnosisForPT'
import { DischargeNotesTabular } from '../consultation/history/dischargeNotesTabular'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCodingWithAutocomplete } from '../LeftMenu/WelloSelectCodingWithAutoComplete'
import { WelloTextFieldWithoutTitle } from '../LeftMenu/WelloTextFieldWitoutTitle'
import { DischargeTile } from './dischargeTile'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  disable: boolean
  status: string
}

export const Discharge: React.FC<NextStepsOfConsultationProps> = ({
  fhirAppointmentDetails,
  disable,
  status,
}: NextStepsOfConsultationProps) => {
  const dischargeSlice = useSelector((state: RootState) => state.dischargeSlice)
  const { t } = useTranslation(['common', 'mediction'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [notes, setNotes] = useState<string>('')
  const [dischargeReason, setDischargeReaason] = useState<R4.ICoding>(
    discharge[0]
  )

  const [observation, setObservation] = useState<R4.IObservation>()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (dischargeSlice.dischargeSuccessful) {
      window.location.href = `/${getVendorPartId()}/dashboard`
    }
  }, [dischargeSlice.dischargeSuccessful, history])

  const dischargeFindSlice = useSelector(
    (state: RootState) => state.dischargeFindSlice
  )

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })
  useEffect(() => {
    dispatch(
      requestForDischarge(
        fhirAppointmentDetails.patient,
        fhirAppointmentDetails.mainServiceRequest.id!
      )
    )
  }, [dispatch])

  useEffect(() => {
    if (dischargeSlice.dischargeNoteUpdated) {
      setDischargeReaason(discharge[0])
      setNotes('')
      dispatch(
        requestForDischarge(
          fhirAppointmentDetails.patient,
          fhirAppointmentDetails.mainServiceRequest.id!
        )
      )
    }
  }, [dischargeSlice.dischargeNoteUpdated, dispatch])

  return (
    <Box style={{ paddingLeft: '4px' }} display='flex' flexDirection='column'>
      <Box width='100%'>
        <Grid container spacing={2} direction='column'>
          {!isPhysiotherapist() && (
            <Grid item xl>
              {status === 'completed' && (
                <Grid
                  container
                  spacing={1}
                  direction='column'
                  id='visit_summary'
                >
                  <Grid item xl>
                    <DischargeTile
                      fhirAppointmentDetails={fhirAppointmentDetails}
                    />
                  </Grid>
                </Grid>
              )}
              {status !== 'completed' && (
                <Grid
                  container
                  spacing={1}
                  direction='column'
                  id='visit_summary'
                >
                  <Grid item xs={3}>
                    <Box display='flex' py={1}>
                      {(dischargeSlice.discharging ||
                        dischargeFindSlice.searchingConditions) && (
                        <Box flexGrow={1} minWidth='400px'>
                          <LinearProgress />
                        </Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box paddingRight={2} width='100%'>
                      {!dischargeFindSlice.searchingConditions && (
                        <WelloSelectFHIRCodingWithAutocomplete
                          title='Discharge'
                          id='ipd_discharge'
                          availableCodings={discharge}
                          onChanged={(value) => {
                            setDischargeReaason(value as R4.ICoding)
                          }}
                          disabled={
                            dischargeSlice.discharging ||
                            dischargeFindSlice.searchingConditions
                          }
                          preSelectedCoding={dischargeReason}
                        />
                      )}
                      {/*  <Controls.WelloSelect
                        label='Discharge'
                        availableOptions={discharge}
                        required={true}
                        type='Select Reason'
                        displayField='display'
                        preSelectedOption={dischargeReason.code ?? ''}
                        valueField='code'
                        onSelectionChanged={(value: any) => {
                          setDischargeReaason(value as R4.ICoding)
                          // setCancelReason(value as R4.ICoding)
                        }}
                      /> */}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box paddingRight={2} width='100%' flexDirection='column'>
                      <WelloFormItemLabel title='Notes' />
                      <WelloTextFieldWithoutTitle
                        textProps={{
                          fullWidth: true,
                          id: `notes`,
                          value: notes,
                          type: 'text',
                          multiline: true,
                          disabled:
                            dischargeSlice.discharging ||
                            dischargeFindSlice.searchingConditions,
                          minRows: 4,
                          onChange: (changeEmail) => {
                            setNotes(changeEmail.target.value)
                          },
                        }}
                      />
                    </Box>

                    <Box py={1} display='flex' flexDirection='row' flexGrow>
                      <Button
                        variant='contained'
                        color='primary'
                        id='discharge_button_save'
                        disabled={
                          notes.length === 0 ||
                          disable ||
                          notes ===
                            (observation
                              ? observation.valueString ?? ''
                              : '') ||
                          (observation
                            ? getDefaultCodeOfSystemFromCodableConcept(
                                observation.code
                              ) !== dischargeReason.code
                            : false) ||
                          dischargeSlice.discharging ||
                          dischargeFindSlice.searchingConditions
                        }
                        disableElevation
                        size='small'
                        onClick={() => {
                          dispatch(
                            saveDischargeNotesForVisit(
                              fhirAppointmentDetails,
                              notes,
                              dischargeReason!,
                              undefined
                            )
                          )
                        }}
                      >
                        Save Notes
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        id='discharge_button_final'
                        disabled={
                          dischargeSlice.discharging ||
                          dischargeFindSlice.searchingConditions ||
                          notes.length === 0 ||
                          dischargeReason === undefined ||
                          disable
                        }
                        onClick={() => {
                          setShowDeleteConfirmation({
                            open: true,
                            onContinueActionClicked: () => {
                              setShowDeleteConfirmation({ open: false })
                              dispatch(
                                initiateDischarge(
                                  fhirAppointmentDetails,
                                  notes,
                                  dischargeReason!
                                )
                              )
                            },
                            onCancelActionClicked: () => {
                              setShowDeleteConfirmation({ open: false })
                            },

                            displayText:
                              'Final diagnostics and latest vitals are updated?',
                            title: 'Initiate Discharge',
                            continueActionButtonText: 'Yes',
                            cancelActionButtonText: 'No',
                          })
                        }}
                      >
                        Initiate Discharge
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {isPhysiotherapist() && (
            <Grid item xl>
              <Grid container spacing={1} direction='column' id='visit_summary'>
                <Box>
                  <DischargeDiagnosisForPT
                    encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                    patientId={fhirAppointmentDetails.patient?.id!}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>

      {dischargeFindSlice.resultsAvailable &&
        dischargeFindSlice.dateWiseVitals &&
        !isPhysiotherapist() && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%' flexGrow>
              {dischargeFindSlice.dateWiseVitals.map((val) => (
                <Box py={1} key={val.date ?? ''}>
                  <Grid container direction='row' id='vitals'>
                    <Grid item xs={12}>
                      <Box
                        paddingX={1}
                        border={1}
                        style={{
                          backgroundColor: 'lightGrey',
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '2px 0px',
                          borderRadius: '1px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Recorded on{' '}
                            {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <DischargeNotesTabular obsData={val.occupation} type='Diet' />
                </Box>
              ))}
            </Box>
          </Box>
        )}
      {showDeleteConfirmation.open && (
        <ConfirmationDialog
          {...showDeleteConfirmation}
          id='discharge_confirm_dialog'
        />
      )}
    </Box>
  )
}
