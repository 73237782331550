import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { WelloTextField } from 'wello-web-components'

export interface EditDiscountRateProps {
  open: boolean
  onCancelClick: () => void
  rowDetails: any
}

export function EditDiscountRate(props: EditDiscountRateProps) {
  const { open, rowDetails, onCancelClick: onClose } = props

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  const dispatch = useDispatch()

  const [discountRateData, setDiscountRateData] = React.useState<any>({
    day: rowDetails.day,
    premium: rowDetails.premium,
    sharing: rowDetails.suite,
    suite: rowDetails.sharing,
  })

  function handlePremiumChange(premiumDiscount: number) {
    // setIpdRoomRateData({ ...ipdRoomRateData, price: priceIpdRoom })
  }

  function handleSuiteChange(suiteDiscount: number) {
    // setIpdRoomRateData({ ...ipdRoomRateData, description: descriptionIpdRoom })
  }

  function handleSharingChange(sharingDiscount: number) {
    // setIpdRoomRateData({ ...ipdRoomRateData, description: descriptionIpdRoom })
  }

  function handleSubmit() {}

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Update Discount Rates')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
        >
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Day'
              textProps={{
                id: 'day',
                disabled: true,
                defaultValue: rowDetails?.day,
                placeholder: 'Day',
                onChange: (changeName) => {
                  // handleNameChange(changeName.target.value)
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Premium'
              textProps={{
                id: 'premium',
                defaultValue: rowDetails?.premium,
                placeholder: 'Premium',
                onChange: (changePremium) => {
                  //   handlePremiumChange(changePremium.target.value)
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Suite'
              textProps={{
                id: 'suite',
                defaultValue: rowDetails?.suite,
                placeholder: 'Suite',
                onChange: (changeSuite) => {
                  //   handleSuiteChange(changeSuite.target.value)
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Sharing'
              textProps={{
                id: 'sharing',
                defaultValue: rowDetails?.sharing,
                placeholder: 'Sharing',
                onChange: (changeSharing) => {
                  //   handleSharingChange(changeSharing.target.value)
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button
            variant='contained'
            disableElevation
            onClick={() => {
              handleSubmit()
            }}
            id='Update'
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
