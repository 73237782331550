import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import { ArrowForwardIosOutlined, ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloMedication } from 'models/welloMedication'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getWellnessProviders } from 'redux/administration/referrals/wellnessReferralProviders/wellnessReferralsProvidersSlice'
import { resetAddMedicationsState } from 'redux/consultation/addMedications/addMedicationSlice'
import { addWellnessReferralDetails } from 'redux/consultation/addWellnessReferrals/addWellnessReferralsSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCarePlanInstructions,
  getCarePlanMedications,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloLoadingIndicator } from 'wello-web-components'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onReferralsAdded: () => void
}
export const AddWellnessReferrals: React.FC<Props> = ({
  open,
  onClose,
  onReferralsAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])

  const [textFields, setTextFields] = useState<string[]>([])
  const [hvService, setHvService] = useState<R4.IHealthcareService>()

  const dispatch = useDispatch()
  const addMedicationsSlice = useSelector(
    (state: RootState) => state.addWellnessReferralsSlice
  )
  const wellnessReferralsProvidersSlice = useSelector(
    (state: RootState) => state.wellnessReferralsProvidersSlice
  )
  const referringMedicationRequests = useSelector(
    (state: RootState) => state.medicationsListSlice.raw
  )
  const referringInstructions = useSelector(
    (state: RootState) => state.instructionsListSlice.lanDiagnosticRequests
  )

  useEffect(() => {
    if (wellnessReferralsProvidersSlice.resultsAvailable) {
      if (wellnessReferralsProvidersSlice.unitsList) {
        setTextFields(
          Array.from(
            { length: wellnessReferralsProvidersSlice.unitsList.length },
            (_, i) => ''
          )
        )
      }
    }
  }, [wellnessReferralsProvidersSlice.resultsAvailable])

  useEffect(() => {
    if (addMedicationsSlice.additionSuccessful) {
      resetForm()
      onReferralsAdded()
      dispatch(resetAddMedicationsState())
    }
  }, [dispatch, addMedicationsSlice, onReferralsAdded])

  useEffect(() => {
    dispatch(getWellnessProviders())
  }, [])
  function resetForm() {
    Promise.resolve().then(() => {
      setTextFields([])
      setHvService(undefined)
    })
  }

  function addCurrentDetails(index: number, hv: R4.IHealthcareService) {
    dispatch(
      addWellnessReferralDetails(
        fhirAppointmentDetails,
        hv,
        textFields[index],
        getCarePlanMedications(referringMedicationRequests),
        getCarePlanInstructions(referringInstructions)
      )
    )
  }

  function onTextChanged(index: number, text: string) {
    const temp = [...textFields]
    temp[index] = text
    setTextFields(temp)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Wellness Referrals</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        {wellnessReferralsProvidersSlice.searching && (
          <WelloLoadingIndicator message='Searching Providers' />
        )}

        {wellnessReferralsProvidersSlice.totalCount === 0 &&
          wellnessReferralsProvidersSlice.searching === false && (
            <Typography variant='subtitle1' color='initial'>
              "No Providers Available"
              <WelloLoadingIndicator message='Searching Providers' />
            </Typography>
          )}
        {wellnessReferralsProvidersSlice.unitsList &&
          wellnessReferralsProvidersSlice.unitsList.length > 0 &&
          wellnessReferralsProvidersSlice.searching === false && (
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              px={2}
              py={1}
            >
              <WelloFormItemLabel title='Select Center' />
              <Box display='flex' flexDirection='row' width='100%'>
                <Autocomplete
                  style={{
                    borderRadius: '4px',
                  }}
                  fullWidth
                  size='small'
                  id='occupation_search'
                  disableClearable
                  getOptionSelected={(
                    option: R4.IHealthcareService,
                    valueData: R4.IHealthcareService
                  ) => option.id === valueData.id}
                  getOptionLabel={(option: R4.IHealthcareService) =>
                    `${option.name ?? ''}, ${option.providedBy?.display ?? ''}`
                  }
                  options={wellnessReferralsProvidersSlice.unitsList}
                  onChange={(e, changedValue, reason) => {
                    setHvService(changedValue)
                  }}
                  autoComplete
                  ChipProps={{
                    deleteIcon: (
                      <ClearOutlined
                        style={{
                          height: '15px',
                          color: 'white',
                        }}
                      />
                    ),
                    style: {
                      backgroundColor: kPrimaryLight,
                      borderRadius: '4px',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id='occupation_search_text'
                      variant='outlined'
                      size='small'
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                display='flex'
                width='100%'
                paddingY={1}
                flexDirection='column'
              >
                <WelloFormItemLabel title='Additional Notes' />
                <TextField
                  id='txt_extra_info_for_refer'
                  fullWidth
                  variant='outlined'
                  disabled={addMedicationsSlice.adding}
                  placeholder='Additional Notes'
                  onChange={(tex) => {
                    onTextChanged(0, tex.target.value)
                  }}
                />
              </Box>
            </Box>
          )}

        {/* {wellnessReferralsProvidersSlice.unitsList &&
          wellnessReferralsProvidersSlice.unitsList.length > 0 &&
          wellnessReferralsProvidersSlice.searching === false &&
          wellnessReferralsProvidersSlice.unitsList.map((e, index) => (
            <Box
              display='flex'
              flexDirection='row'
              key={e.id ?? e.name ?? ''}
              p={1}
              justifyContent='space-between'
              alignItems='center'
              alignContent='center'
            >
              <Box p={1}>
                <ArrowForwardIosOutlined
                  style={{
                    fontSize: '15px',
                  }}
                />
              </Box>
              <Box display='flex' flexDirection='column'>
                <Typography variant='h6' color='primary'>
                  {e.name ?? ''}
                </Typography>

                <Typography variant='subtitle2' color='initial'>
                  {e.providedBy?.display ?? ''}
                </Typography>
              </Box>
            </Box>
          ))} */}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box
          display='flex'
          flexDirection='row'
          p={1}
          flexGrow={1}
          alignItems='end'
          alignContent='end'
          justifyContent='end'
        >
          {addMedicationsSlice.adding && <CircularProgress size={15} />}

          {!addMedicationsSlice.adding && (
            <Button
              onClick={() => {
                onClose()
              }}
              id='cancel'
              variant='outlined'
              color='primary'
              disableElevation
            >
              {t('labelCommon:Cancel')}
            </Button>
          )}
        </Box>

        <Box paddingRight={0.4}>
          <Button
            variant='contained'
            color='primary'
            id='refer'
            disabled={addMedicationsSlice.adding || hvService === undefined}
            onClick={() => {
              addCurrentDetails(0, hvService!)
            }}
          >
            Refer
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
