import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { kPrimaryLight, kPrimaryMain } from 'configs/styles/muiThemes'

import React, { useContext, useEffect, useState } from 'react'

import { AccountDetails } from 'models/labReferralDetails'

interface Props {
  accountData: AccountDetails
  isOdd?: boolean
  onTap?: () => void

  dense?: boolean
  isSelected?: boolean
  index: number
}

export const AccountTile: React.FC<Props> = ({
  accountData,
  isOdd = false,
  onTap,
  dense = false,
  isSelected = false,
  index,
}: Props) => {
  console.log('test')

  return (
    <Box width='100%'>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense === false && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='15%'
              >
                <Box paddingLeft={0.1}>
                  <Typography variant='subtitle1' color='initial'>
                    Account Number
                  </Typography>
                </Box>
                <Typography variant='body2' color='initial'>
                  {accountData.accountNo}
                </Typography>

                {/* <Typography
                    variant='body2'
                    color='initial'
                    style={{ paddingLeft: 1 }}
                  >
                    {appointmentDetails.appointment.comment}
                  </Typography> */}
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='20%'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='start'
                    px={1}
                    id={`patient${index}`}
                    paddingTop={1}
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      style={{
                        textTransform: 'capitalize',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {accountData.beneficiaryName}
                    </Typography>
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    px={1}
                  >
                    <Typography
                      variant='body2'
                      color='initial'
                      style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                    >
                      {accountData.email}
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    px={1}
                  >
                    <Typography
                      variant='body2'
                      color='initial'
                      style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                    >
                      {accountData.phone}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='10%'
                paddingLeft={4}
              >
                <Typography variant='subtitle1' color='initial'>
                  Pan Number
                </Typography>

                <Box py={0.5} width='50%'>
                  <Typography variant='body2' color='initial'>
                    {accountData.panNo}
                  </Typography>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='10%'
                paddingLeft={4}
              >
                <Typography variant='subtitle1' color='initial'>
                  GST Number
                </Typography>

                <Box py={0.5} width='50%'>
                  <Typography variant='body2' color='initial'>
                    {accountData.gstNo}
                  </Typography>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                justifyContent='center'
                width='12%'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='25%'
                >
                  <Tooltip title='Edit Account' id={`start${index}`}>
                    <IconButton
                      aria-label='btn_ord_reschedule'
                      color='primary'
                      onClick={() => {
                        if (onTap) onTap()
                      }}
                      id={`start_appointment${index}`}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/editIcon.png`}
                        alt='view'
                        id={`start_img${index}`}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
