import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WelloTextField } from 'wello-web-components'
// import { WelloTherapyCategorySelector } from './WelloTherapyCategorySelector';

export interface AddSubstanceProps {
  open: boolean
  onCancelClick: () => void
}

export function AddSubstance(props: AddSubstanceProps) {
  const { open, onCancelClick: onClose } = props

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      onClose={() => {
        onClose()
      }}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Add Consumable')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
        >
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField title='Consumable' textProps={{}} />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField title='Price' textProps={{}} />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField title='Unit' textProps={{}} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button variant='contained' disableElevation>
            Add
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
