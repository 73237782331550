import { R4 } from '@ahryman40k/ts-fhir-types'
import _ from 'lodash'

export function getTargetValues(response: any): string[] {
  const outComeData: string[] = []
  if (response.results) {
    if (
      response.results.stages.target &&
      response.results.stages.target.length > 0
    ) {
      for (let i = 0; i < response.results.stages.target.length; i++) {
        const targetData = response.results.stages.target[i].cohorts ?? []
        if (targetData.length > 0) {
          for (let k = 0; k < targetData.length; k++) {
            outComeData.push(targetData[k])
          }
        }
      }
    }
  }

  return outComeData.sort().reverse()
}

export function getTreatment(response: any): string[] {
  const outComeData: string[] = []
  if (response.results) {
    if (
      response.results.stages.intervention &&
      response.results.stages.intervention.length > 0
    ) {
      for (let i = 0; i < response.results.stages.intervention.length; i++) {
        const targetData = response.results.stages.intervention[i].cohorts ?? []
        if (targetData.length > 0) {
          for (let k = 0; k < targetData.length; k++) {
            outComeData.push(targetData[k])
          }
        }
      }
    }
  }

  return _.uniq(outComeData)
}

export function getPreObs(response: any): string[] {
  const outComeData: string[] = []
  if (response.results) {
    if (
      response.results.stages.outcome &&
      response.results.stages.outcome.length > 0
    ) {
      for (let i = 0; i < response.results.stages.outcome.length; i++) {
        const targetData = response.results.stages.outcome[i].cohorts ?? []
        if (targetData.length > 0) {
          for (let k = 0; k < targetData.length; k++) {
            outComeData.push(targetData[k])
          }
        }
      }
    }
  }
  return _.uniq(outComeData)
}

export function getChartDataForCarePlanCohort(results: any, name: string): any {
  let outComeData: any = {}
  const targetDataOutCome: any[] = []

  if (results) {
    if (results.stages.target && results.stages.target.length > 0) {
      for (let i = 0; i < results.stages.target.length; i++) {
        const targetData = results.stages.target[i].cohorts ?? []
        if (targetData.length > 0) {
          for (let k = 0; k < targetData.length; k++) {
            if (targetData[k] === name) {
              targetDataOutCome.push(results.stages.target[i])
            }
          }
        }
      }
    }
    outComeData = {
      stages: {
        target: targetDataOutCome,
        intervention: results.stages.intervention,
        outcome: results.stages.outcome,
      },
      pathway_groups: results.pathway_groups.filter(
        (d: any) => d.targetCohortName === name
      ),
    }
  }
  console.log(outComeData)
  return outComeData
}

export function getLabelData(results: string): string {
  let data = results
  if (data.includes('gt')) {
    data = data.replaceAll('gt', '>')
  }
  if (data.includes('lt')) {
    data = data.replaceAll('lt', '<')
  }
  if (data.includes('SBP')) {
    data = data.replaceAll('SBP', 'Systolic')
  }
  if (data.includes('DBP')) {
    data = data.replaceAll('DBP', 'Diastolic')
  }
  return data
}
