import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { ArrowRightSharp, DeleteOutline } from '@material-ui/icons'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { isUnitAdmin } from 'services/userDetailsService'
import {
  getBodySiteName,
  getPainInText,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  deleteObservationDetails,
  getObservationsOfSpecificCategoryInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import {
  UpdateObservationsProps,
  UpdateObservationWithNotesForEncounter,
} from './updateObservationWithNotes'

interface ViewObservationsProps {
  patient: R4.IPatient
  purposeOfUse: PurposeOfUse
  encounterId: string
  category: string
  status: string
  fetchAgain: string
  categoryLabel?: string
  showNotAvailable?: boolean
  showTitleInNoData?: boolean
  allowEditing?: boolean
  onObservationFetched?: (observations: R4.IObservation[]) => void
}

export const ViewObservationsOfBodyParts: React.FC<ViewObservationsProps> = ({
  patient,
  category,
  purposeOfUse,
  encounterId,
  status,
  fetchAgain,
  categoryLabel,
  showNotAvailable = false,
  showTitleInNoData = false,
  allowEditing = true,
  onObservationFetched,
}: ViewObservationsProps) => {
  const { t } = useTranslation(['common'])
  const [addingStatus, setAddingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  const dispatch = useDispatch()
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [discardingStatus, setDiscardingStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })

  const [observations, setObservations] = useState<R4.IObservation[]>([])

  function getObservationDetails() {
    setAddingStatus({
      requesting: true,
    })

    getObservationsOfSpecificCategoryInEncounter({
      encounterId,
      patient,
      category,
    })
      .then((e) => {
        if (e) {
          setAddingStatus({
            requestSuccess: true,
          })
          setObservations(e as R4.IObservation[])
          if (onObservationFetched) {
            onObservationFetched(e as R4.IObservation[])
          }
        } else {
          setAddingStatus({
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setAddingStatus({
          requestError: true,
        })
      })
  }

  function discardObservationDetails(obs: R4.IObservation) {
    setDiscardingStatus({
      requesting: true,
    })

    deleteObservationDetails(obs)
      .then((e) => {
        if (e) {
          setDiscardingStatus({
            requestSuccess: true,
          })
          getObservationDetails()
          dispatch(showSuccessAlert('Deleted successfully'))
        } else {
          setDiscardingStatus({
            requestError: true,
          })
          dispatch(
            showErrorAlert('Error while deleting details, Please try later')
          )
        }
      })
      .catch((e) => {
        setDiscardingStatus({
          requestError: true,
        })
      })
  }

  const [updateStates, setUpdateStates] = useState<UpdateObservationsProps>({
    open: false,
  })

  const [showTextDetails, setshowTextDetails] = useState<boolean>(false)
  const [text, setText] = useState<string>()
  const [observationTitle, setObservationTitle] = useState<string>()

  useEffect(() => {
    getObservationDetails()
  }, [fetchAgain])
  return (
    <>
      <Box
        flexGrow
        width='100%'
        flexDirection='column'
        display='flex'
        paddingY={0.5}
        style={{ padding: 0, margin: 0 }}
      >
        {(observations.length > 0 || showTitleInNoData) && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography variant='subtitle1' color='initial'>
              {' '}
              {categoryLabel ?? 'Findings'}
            </Typography>
          </Box>
        )}
        {addingStatus.requesting && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <CircularProgress size={15} />
          </Box>
        )}

        {discardingStatus.requesting && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <CircularProgress size={15} />
          </Box>
        )}
        {!addingStatus.requesting &&
          observations.length === 0 &&
          showNotAvailable && (
            <Box flexGrow width='100%' display='flex' flexDirection='row'>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {' '}
                {isUnitAdmin() || status === 'completed'
                  ? 'No data available'
                  : 'Add Medical Conditions'}
              </Typography>
            </Box>
          )}
        {addingStatus.requestError && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {addingStatus.errorDescription ?? 'Error while fetching'}
            </Typography>
          </Box>
        )}
        {!addingStatus.requesting &&
          !discardingStatus.requesting &&
          observations.length > 0 && (
            <Box
              flexGrow
              width='100%'
              display='flex'
              flexDirection='row'
              maxWidth='500px'
            >
              <List
                style={{
                  width: '100%',
                }}
              >
                {observations.map((val) => (
                  <ListItem
                    key={val.id}
                    dense={false}
                    divider
                    style={{
                      width: '100%',
                    }}
                  >
                    <ListItemIcon>
                      <ArrowRightSharp fontSize='small' />
                    </ListItemIcon>
                    <ListItemText
                      primary={getBodySiteName(val)}
                      style={{
                        maxWidth: '150px',
                      }}
                      secondaryTypographyProps={{
                        style: {
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 3,
                          whiteSpace: 'pre-line',
                          color: kPrimaryMain,
                        },
                      }}
                      secondary={
                        getPainInText(val) ? getPainInText(val) : undefined
                      }
                    />
                    {val.issued && (
                      <ListItemSecondaryAction>
                        <Box display='flex' flexDirection='Row'>
                          <Box display='flex' flexDirection='column'>
                            <Typography variant='caption' color='initial'>
                              Added on
                            </Typography>
                            <Typography variant='caption' color='initial'>
                              {moment(val.issued).format('DD/MM/YYYY')}
                            </Typography>
                          </Box>

                          {allowEditing && (
                            <Box>
                              <IconButton
                                id='body_delete'
                                aria-label='edit_details'
                                onClick={(e) => {
                                  setShowDeleteConfirmation({
                                    open: true,
                                    onContinueActionClicked: () => {
                                      setShowDeleteConfirmation({ open: false })
                                      discardObservationDetails(val)
                                    },
                                    onCancelActionClicked: () => {
                                      setShowDeleteConfirmation({ open: false })
                                    },
                                    displayText: `Are you sure you want to delete pain details of ${
                                      getBodySiteName(val) ?? ''
                                    }?`,
                                    continueActionButtonText: 'Delete',
                                    cancelActionButtonText: 'Cancel',
                                  })
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
      </Box>

      <Dialog
        open={showTextDetails}
        onClose={() => {
          setshowTextDetails(false)
          setText(undefined)
          setObservationTitle(undefined)
        }}
        aria-labelledby={`${observationTitle} Details`}
        disableBackdropClick
      >
        <DialogTitle>{observationTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              padding: '16px',
            }}
          >
            <Typography
              variant='body1'
              color='initial'
              style={{ whiteSpace: 'pre-line' }}
            >
              {text}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setshowTextDetails(false)
              setText(undefined)
              setObservationTitle(undefined)
            }}
            color='default'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {updateStates.open && (
        <UpdateObservationWithNotesForEncounter {...updateStates} />
      )}
      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} />
      )}
    </>
  )
}
