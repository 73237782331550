import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  Checkbox,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ClearOutlined } from '@material-ui/icons'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { Conditions } from 'models/conditions'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addHistoryConditionDetails,
  resetAddConditionState,
} from 'redux/fhirMedicalResources/ipd/addCondition/addConditionSliceIPD'

import {
  addFamilyHistoryOPD,
  resetAddFamilyHistory,
} from 'redux/fhirMedicalResources/opd/addFamilyHistory/addFamilyHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  checkBoxConditions,
  checkConditions2,
  reminingConditons,
} from 'utils/constants'
import {
  RadioRaisedButton,
  WelloLoadingIndicator,
  WelloLoadingPage,
} from 'wello-web-components'
import { titleCase } from 'utils/fhirResourcesHelper'
import { WelloFamilyHistory } from 'models/welloSelectFamilyConditions'
import _ from 'lodash'
import { ConditonMasterChosser } from './subjective/conditonMasterChosser'
import { FamilyHistoryMasterSelector } from './subjective/familyMemberMasterSelector'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'
import { FamilyHistoryTileForDisplay } from './familyHistoryTile'
import { ValueSetSelectSingle } from '../common/SingleSelectFromValueSetUrl'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onMedicalConditionAdded: () => void
}
export const AddFamilyHistoryOPD: React.FC<Props> = ({
  open,
  onClose,
  onMedicalConditionAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'condition'])
  const [value, setValue] = React.useState(new Date())
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  )
  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [selectedRelationShip, setSelectedRelationShip] = useState<R4.ICoding>()
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const [selectedSeverity, setSeverity] = useState<R4.ICoding>()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isActiveCondition, setIsActiveCondtion] = useState<boolean>(false)
  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date !== null ? moment(date).toDate() : new Date())
  }
  const [other, setOther] = useState<boolean>(false)
  const dispatch = useDispatch()

  const [currentCondition, setCurrentCondition] = useState<WelloFamilyHistory>({
    id: _.random(1, 10000000).toString(),

    checkBoxFirstCondition: checkBoxConditions,
    checkBoxSecCondition: checkConditions2,
  })

  const [addedConditions, setAddedConditions] = useState<WelloFamilyHistory[]>(
    []
  )

  const addFamilyHistorySlice = useSelector(
    (state: RootState) => state.addFamilyHistorySliceOPD
  )

  function isEnableCreateButton(): boolean | undefined {
    for (let i = 0; i < currentCondition.checkBoxFirstCondition!.length; i++) {
      if (currentCondition.checkBoxFirstCondition![i].checked) {
        return true
      }
    }
    return false
  }
  function isEnableCreateButtonForOther(): boolean | undefined {
    for (let i = 0; i < currentCondition.checkBoxSecCondition!.length; i++) {
      if (currentCondition.checkBoxSecCondition![i].checked) {
        return true
      }
    }
    return false
  }

  function isEnableValidation(): boolean | undefined {
    const data: string[] = []
    if (currentCondition.condition === undefined) {
      data.push('1')
    }
    if (currentCondition.condition) {
      if (currentCondition.relationShips === undefined) {
        data.push('1')
      }
      if (currentCondition.selectedDate === null) {
        data.push('1')
      }
    }
    if (data.length > 0) return true
    return false
  }

  const handleChange = (
    event: boolean,
    condition: Conditions,
    index: number
  ) => {
    const values: Conditions[] = [...currentCondition.checkBoxFirstCondition!]
    values[index].checked = event
    setCurrentCondition({
      ...currentCondition,
      checkBoxFirstCondition: values,
    })
  }
  const handleChangeOther = (
    event: boolean,
    condition: Conditions,
    index: number
  ) => {
    const values: Conditions[] = [...currentCondition.checkBoxSecCondition!]
    values[index].checked = event
    setCurrentCondition({
      ...currentCondition,
      checkBoxSecCondition: values,
    })
  }

  function resetDetails() {
    const firstData: Conditions[] = []
    for (let i = 0; i < checkBoxConditions.length; i++) {
      firstData.push({
        checked: false,
        data: checkBoxConditions[i].data,
      })
    }

    const secData: Conditions[] = []
    for (let i = 0; i < checkConditions2.length; i++) {
      secData.push({
        checked: false,
        data: checkConditions2[i].data,
      })
    }

    setCurrentCondition({
      id: _.random(1, 50000000).toString(),

      checkBoxFirstCondition: firstData,
      checkBoxSecCondition: secData,
    })
  }

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloFamilyHistory[] = [...addedConditions]
    const newMedication: WelloFamilyHistory = {
      ...currentCondition,
    }

    tempMedication.push(newMedication)

    Promise.resolve().then(() => {
      setAddedConditions(tempMedication)
      resetDetails()
    })

    if (pushToSave) {
      console.log(tempMedication)
      dispatch(addFamilyHistoryOPD(fhirAppointmentDetails, tempMedication))
    }
  }

  useEffect(() => {
    if (addFamilyHistorySlice.additionSuccessful) {
      onMedicalConditionAdded()
      dispatch(resetAddFamilyHistory())
      setAddedConditions([])
      resetDetails()
    }
  }, [dispatch, addFamilyHistorySlice, onMedicalConditionAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setAddedConditions([])
        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Add Family Conditon</DialogTitle>
      <DialogContent dividers={true}>
        {addFamilyHistorySlice.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!addFamilyHistorySlice.adding && (
          <Box
            marginX={2}
            marginY={1}
            key={currentCondition.id}
            display='flex'
            flexDirection='column'
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container direction='column' spacing={1}>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='column'
                        flexGrow={1}
                      >
                        <Box>
                          <ValueSetSelectSingle
                            key='wellopathy-relationship-vs_medicines'
                            title='Select Relationship'
                            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-relationship-vs'
                            id='opd_relations'
                            fuzzySearch={true}
                            disabled={false}
                            onOptionSelected={(conditions) => {
                              if (conditions) {
                                setSelectedRelationShip(conditions)
                                setCurrentCondition({
                                  ...currentCondition,
                                  relationShips: conditions,
                                })
                              }
                            }}
                            helperText={undefined}
                          />
                          {/* <FamilyHistoryMasterSelector
                            disabled={false}
                            onSelectionChanges={(conditions: R4.ICoding) => {
                              setSelectedRelationShip(conditions)
                              setCurrentCondition({
                                ...currentCondition,
                                relationShips: conditions,
                              })
                            }}
                            selectOther={isActiveCondition}
                            id='opd_relations'
                          /> */}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='column'
                        flexGrow={1}
                        key={currentCondition.id}
                      >
                        <Box display='flex' flexDirection='row'>
                          <WelloFormItemLabel
                            title='Select Condition(s)'
                            case='yes'
                          />{' '}
                        </Box>
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            width='50%'
                            flexDirection='column'
                            flexGrow={1}
                          >
                            {currentCondition.checkBoxFirstCondition!.map(
                              (e: Conditions, index) => (
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  key={e.data.code}
                                >
                                  <Box paddingLeft={0.5}>
                                    <Checkbox
                                      edge='start'
                                      checked={e.checked}
                                      onChange={(event) => {
                                        handleChange(
                                          event.target.checked,
                                          e,
                                          index
                                        )
                                      }}
                                      onKeyDown={(eData) => {
                                        if (eData.code === 'Enter') {
                                          if (e.checked === false) {
                                            handleChange(true, e, index)
                                          } else {
                                            handleChange(false, e, index)
                                          }
                                        }
                                      }}
                                      style={{
                                        paddingTop: 0,
                                      }}
                                      tabIndex={0}
                                      size='small'
                                      color='primary'
                                      id={`left${index}`}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                    >
                                      {titleCase(e.data.display ?? '')}
                                    </Typography>
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>

                          <Box
                            display='flex'
                            width='50%'
                            flexDirection='column'
                            flexGrow
                            alignItems='flex-start'
                            paddingLeft={10}
                            key={currentCondition.id}
                          >
                            {currentCondition.checkBoxSecCondition!.map(
                              (e: Conditions, indexData: number) => (
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  key={e.data.code}
                                  flexGrow
                                >
                                  <Box paddingLeft={0.5}>
                                    <Checkbox
                                      edge='start'
                                      checked={e.checked}
                                      onChange={(event) => {
                                        handleChangeOther(
                                          event.target.checked,
                                          e,
                                          indexData
                                        )
                                      }}
                                      onKeyDown={(eData) => {
                                        if (eData.code === 'Enter') {
                                          if (e.checked === false) {
                                            handleChangeOther(
                                              true,
                                              e,
                                              indexData
                                            )
                                          } else {
                                            handleChangeOther(
                                              false,
                                              e,
                                              indexData
                                            )
                                          }
                                        }
                                      }}
                                      tabIndex={0}
                                      size='small'
                                      style={{
                                        paddingTop: 0,
                                      }}
                                      color='primary'
                                      id={`right${indexData}`}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                    >
                                      {titleCase(e.data.display ?? '')}
                                    </Typography>
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>
                        </Box>

                        <Box display='flex' flexDirection='row' width='100%'>
                          <Autocomplete
                            style={{
                              borderRadius: '4px',
                            }}
                            fullWidth
                            size='small'
                            id='condition_selector'
                            getOptionSelected={(option, valueData) =>
                              option.display === valueData.display
                            }
                            disableClearable
                            getOptionLabel={(option: R4.ICoding) =>
                              titleCase(option.display ?? '')
                            }
                            options={reminingConditons}
                            onChange={(e, changedValue, reason) => {
                              if (changedValue !== null) {
                                setCurrentCondition({
                                  ...currentCondition,
                                  condition: changedValue,
                                })
                                setSelectedCondition(changedValue)
                              }
                            }}
                            autoComplete
                            ChipProps={{
                              style: {
                                backgroundColor: kPrimaryLight,
                                borderRadius: '4px',
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t(
                                  'labelCommon:type_atleast_3_chars'
                                )}
                                {...params}
                                fullWidth
                                id='family_condition_search_textBox'
                                variant='outlined'
                                size='small'
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction='column'>
                    <Grid item>
                      <WelloFormItemLabel title='Additional Notes' />
                    </Grid>
                    <Grid item style={{ paddingBottom: '16px' }}>
                      <TextField
                        size='small'
                        fullWidth
                        id='family_notes'
                        multiline
                        type='number'
                        value={currentCondition.notes!}
                        variant='outlined'
                        onChange={(event) => {
                          setCurrentCondition({
                            ...currentCondition,
                            notes: event.target.value,
                          })
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>

            <Box
              marginY={1}
              display='flex'
              flexDirection='column'
              paddingLeft={0.5}
              paddingRight={1}
            >
              {addedConditions && addedConditions.length > 0 && (
                <FamilyHistoryTileForDisplay referrals={addedConditions} />
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              setAddedConditions([])
              resetDetails()
              onClose()
            }}
            id='fam_cancel'
            disabled={addFamilyHistorySlice.adding}
            variant='outlined'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              if (other === true) {
                setOther(false)
              } else {
                setOther(true)
              }
              addCurrentDetails()
            }}
            variant='contained'
            color='primary'
            id='add_more'
            disableElevation
            disabled={
              (currentCondition.condition === undefined &&
                isEnableCreateButton() === false &&
                isEnableCreateButtonForOther() === false) ||
              currentCondition.relationShips === undefined
            }
          >
            {t('labelCommon:add_more_new')}
          </Button>
          <Button
            onClick={() => {
              if (
                currentCondition.relationShips !== undefined &&
                (isEnableCreateButton() ||
                  isEnableCreateButtonForOther() ||
                  currentCondition.condition !== undefined)
              ) {
                addCurrentDetails(true)
              } else if (addedConditions.length > 0) {
                dispatch(
                  addFamilyHistoryOPD(fhirAppointmentDetails, addedConditions)
                )
              }
            }}
            variant='contained'
            color='primary'
            id='fam_save'
            disableElevation
            disabled={
              (addedConditions.length === 0 &&
                isEnableValidation() &&
                isEnableCreateButton() === false &&
                isEnableCreateButtonForOther() === false) ||
              addFamilyHistorySlice.adding
            }
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
