/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  Typography,
  makeStyles,
  Theme,
  TextField,
} from '@material-ui/core'
import {
  CloseOutlined,
  Delete,
  DeleteOutlineOutlined,
  ClearOutlined,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight, kDialogueBackground } from 'configs/styles/muiThemes'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'

import {
  addHabitsOPD,
  resetAddHabitState,
} from 'redux/fhirMedicalResources/opd/addHabits/habitAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  breathe,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  others,
  sleepPattern,
  teaCount,
} from 'utils/constants'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloSelectFHIRCodingForSleep } from 'views/components/LeftMenu/WelloSelectFhirCodingHabbit'
import { WelloSelectFHIRCodingWithoutSort } from 'views/components/LeftMenu/WelloSelectFhirCodingWithoutSort'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import {
  RadioRaisedButton,
  WelloLoadingIndicator,
  WelloTextField,
} from 'wello-web-components'
import { AllergyMasterChosser } from '../subjective/allergyMasterChosser'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onHabbitAdded: () => void
}
export const AddHabitsOPD: React.FC<Props> = ({
  open,
  onClose,
  onHabbitAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '45%',
    },
  }))
  const classes = useStyles()

  const [excerciseDuration, setExcersiseDuration] = useState<R4.ICoding>()
  const [excersiseIntensity, setExcersiseIntencity] = useState<R4.ICoding>()
  const [energyLevel, setEnergylevel] = useState<R4.ICoding>()
  const [breatePattern, setBreathePattern] = useState<R4.ICoding>()
  const [other, setOther] = useState<boolean>(false)
  const [otherData, setOtherData] = useState<R4.ICoding>()
  const [sleep, setSleep] = useState<R4.ICoding>()
  const [sleepDay, setSleepDay] = useState<R4.ICoding>()
  const [cofee, setCofee] = useState<R4.ICoding>()
  const [tea, setTea] = useState<R4.ICoding>()

  const dispatch = useDispatch()

  const addHabitSliceOPD = useSelector(
    (state: RootState) => state.addHabitSliceOPD
  )

  function isOtherSelected(): boolean {
    if (other === true && !otherData) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (addHabitSliceOPD.additionSuccessful) {
      onHabbitAdded()
      dispatch(resetAddHabitState())
      setExcersiseDuration(undefined)
      setExcersiseIntencity(undefined)
      setEnergylevel(undefined)
      setBreathePattern(undefined)
      setSleep(undefined)
      setSleepDay(undefined)
      setCofee(undefined)
      setTea(undefined)
      setOther(false)
      setOtherData(undefined)
    }
  }, [dispatch, addHabitSliceOPD, onHabbitAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        dispatch(resetAddHabitState())
        setExcersiseDuration(undefined)
        setExcersiseIntencity(undefined)
        setEnergylevel(undefined)
        setBreathePattern(undefined)
        setSleep(undefined)
        setCofee(undefined)
        setOther(false)
        setTea(undefined)
        setOtherData(undefined)
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Add Habits</DialogTitle>
      <DialogContent dividers={true}>
        {addHabitSliceOPD.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!addHabitSliceOPD.adding && (
          <Box marginX={2}>
            <Grid container direction='column' spacing={1} wrap='nowrap'>
              <Grid item>
                {listOfHabbits.map((val) => (
                  <Box display='flex' flexDirection='column' width='100%'>
                    {val.code === '74009-2' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Autocomplete
                              style={{
                                borderRadius: '4px',
                              }}
                              id='hab_10'
                              fullWidth
                              size='small'
                              componentName='unit'
                              autoFocus
                              disableClearable
                              multiple={false}
                              getOptionSelected={(option, valueData) =>
                                option.display === valueData.display
                              }
                              getOptionLabel={(option) => option.display ?? ''}
                              options={excersiseCode}
                              onChange={(e, type, reason) => {
                                if (type) {
                                  setExcersiseDuration(type)
                                }
                              }}
                              autoComplete
                              ChipProps={{
                                deleteIcon: (
                                  <ClearOutlined
                                    style={{
                                      height: '15px',
                                      color: 'white',
                                    }}
                                  />
                                ),
                                style: {
                                  backgroundColor: kPrimaryLight,
                                  borderRadius: '4px',
                                },
                              }}
                              renderOption={(option) => (
                                <Typography variant='subtitle2'>
                                  {option.display}
                                </Typography>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder='Select'
                                  fullWidth
                                  variant='outlined'
                                  id='hab_10'
                                  size='small'
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {val.code === '10000-2' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title='How many cups of coffee do you drink per day' />
                        </Box>
                        <Box width='40%'>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Autocomplete
                              style={{
                                borderRadius: '4px',
                              }}
                              id='hab_11'
                              fullWidth
                              size='small'
                              componentName='unit'
                              autoFocus
                              disableClearable
                              multiple={false}
                              getOptionSelected={(option, valueData) =>
                                option.display === valueData.display
                              }
                              getOptionLabel={(option) => option.display ?? ''}
                              options={teaCount}
                              onChange={(e, type, reason) => {
                                if (type) {
                                  setCofee(type)
                                }
                              }}
                              autoComplete
                              ChipProps={{
                                deleteIcon: (
                                  <ClearOutlined
                                    style={{
                                      height: '15px',
                                      color: 'white',
                                    }}
                                  />
                                ),
                                style: {
                                  backgroundColor: kPrimaryLight,
                                  borderRadius: '4px',
                                },
                              }}
                              renderOption={(option) => (
                                <Typography variant='subtitle2'>
                                  {option.display}
                                </Typography>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder='Select'
                                  fullWidth
                                  variant='outlined'
                                  id='hab_11'
                                  size='small'
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {val.code === '10000-1' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title='How many cups of tea do you drink per day' />
                        </Box>
                        <Box width='40%'>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Autocomplete
                              style={{
                                borderRadius: '4px',
                              }}
                              id='hab_12'
                              fullWidth
                              size='small'
                              componentName='unit'
                              autoFocus
                              disableClearable
                              multiple={false}
                              getOptionSelected={(option, valueData) =>
                                option.display === valueData.display
                              }
                              getOptionLabel={(option) => option.display ?? ''}
                              options={teaCount}
                              onChange={(e, type, reason) => {
                                if (type) {
                                  setTea(type)
                                }
                              }}
                              autoComplete
                              ChipProps={{
                                deleteIcon: (
                                  <ClearOutlined
                                    style={{
                                      height: '15px',
                                      color: 'white',
                                    }}
                                  />
                                ),
                                style: {
                                  backgroundColor: kPrimaryLight,
                                  borderRadius: '4px',
                                },
                              }}
                              renderOption={(option) => (
                                <Typography variant='subtitle2'>
                                  {option.display}
                                </Typography>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder='Select'
                                  fullWidth
                                  variant='outlined'
                                  id='hab_12'
                                  size='small'
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {val.code === '74008-4' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Autocomplete
                              style={{
                                borderRadius: '4px',
                              }}
                              id='hab_12'
                              fullWidth
                              size='small'
                              componentName='unit'
                              autoFocus
                              disableClearable
                              multiple={false}
                              getOptionSelected={(option, valueData) =>
                                option.display === valueData.display
                              }
                              getOptionLabel={(option) => option.display ?? ''}
                              options={intensity}
                              onChange={(e, type, reason) => {
                                if (type) {
                                  setExcersiseIntencity(type)
                                }
                              }}
                              autoComplete
                              ChipProps={{
                                deleteIcon: (
                                  <ClearOutlined
                                    style={{
                                      height: '15px',
                                      color: 'white',
                                    }}
                                  />
                                ),
                                style: {
                                  backgroundColor: kPrimaryLight,
                                  borderRadius: '4px',
                                },
                              }}
                              renderOption={(option) => (
                                <Typography variant='subtitle2'>
                                  {option.display}
                                </Typography>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder='Select'
                                  fullWidth
                                  variant='outlined'
                                  id='hab_12'
                                  size='small'
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {val.code === '65515-9' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <Autocomplete
                            style={{
                              borderRadius: '4px',
                            }}
                            id='hab_13'
                            fullWidth
                            size='small'
                            componentName='unit'
                            autoFocus
                            disableClearable
                            multiple={false}
                            getOptionSelected={(option, valueData) =>
                              option.display === valueData.display
                            }
                            getOptionLabel={(option) => option.display ?? ''}
                            options={energyLevels}
                            onChange={(e, type, reason) => {
                              if (type) {
                                setEnergylevel(type)
                              }
                            }}
                            autoComplete
                            ChipProps={{
                              deleteIcon: (
                                <ClearOutlined
                                  style={{
                                    height: '15px',
                                    color: 'white',
                                  }}
                                />
                              ),
                              style: {
                                backgroundColor: kPrimaryLight,
                                borderRadius: '4px',
                              },
                            }}
                            renderOption={(option) => (
                              <Typography variant='subtitle2'>
                                {option.display}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder='Select'
                                fullWidth
                                variant='outlined'
                                id='hab_13'
                                size='small'
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '28148-5' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <Autocomplete
                            style={{
                              borderRadius: '4px',
                            }}
                            id='hab_14'
                            fullWidth
                            size='small'
                            componentName='unit'
                            autoFocus
                            disableClearable
                            multiple={false}
                            getOptionSelected={(option, valueData) =>
                              option.display === valueData.display
                            }
                            getOptionLabel={(option) => option.display ?? ''}
                            options={breathe}
                            onChange={(e, type, reason) => {
                              if (type) {
                                setBreathePattern(type)
                              }
                            }}
                            autoComplete
                            ChipProps={{
                              deleteIcon: (
                                <ClearOutlined
                                  style={{
                                    height: '15px',
                                    color: 'white',
                                  }}
                                />
                              ),
                              style: {
                                backgroundColor: kPrimaryLight,
                                borderRadius: '4px',
                              },
                            }}
                            renderOption={(option) => (
                              <Typography variant='subtitle2'>
                                {option.display}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder='Select'
                                fullWidth
                                variant='outlined'
                                id='hab_14'
                                size='small'
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '65968-0' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <Autocomplete
                            style={{
                              borderRadius: '4px',
                            }}
                            id='hab_14'
                            fullWidth
                            size='small'
                            componentName='unit'
                            autoFocus
                            disableClearable
                            multiple={false}
                            getOptionSelected={(option, valueData) =>
                              option.display === valueData.display
                            }
                            getOptionLabel={(option) => option.display ?? ''}
                            options={sleepPattern}
                            onChange={(e, type, reason) => {
                              if (type) {
                                setSleep(type)
                              }
                            }}
                            autoComplete
                            ChipProps={{
                              deleteIcon: (
                                <ClearOutlined
                                  style={{
                                    height: '15px',
                                    color: 'white',
                                  }}
                                />
                              ),
                              style: {
                                backgroundColor: kPrimaryLight,
                                borderRadius: '4px',
                              },
                            }}
                            renderOption={(option) => (
                              <Typography variant='subtitle2'>
                                {option.display}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder='Select'
                                fullWidth
                                variant='outlined'
                                id='hab_14'
                                size='small'
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '65561-3' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <Autocomplete
                            style={{
                              borderRadius: '4px',
                            }}
                            id='hab_14'
                            fullWidth
                            size='small'
                            componentName='unit'
                            autoFocus
                            disableClearable
                            multiple={false}
                            getOptionSelected={(option, valueData) =>
                              option.display === valueData.display
                            }
                            getOptionLabel={(option) => option.display ?? ''}
                            options={sleepPattern}
                            onChange={(e, type, reason) => {
                              if (type) {
                                setSleepDay(type)
                              }
                            }}
                            autoComplete
                            ChipProps={{
                              deleteIcon: (
                                <ClearOutlined
                                  style={{
                                    height: '15px',
                                    color: 'white',
                                  }}
                                />
                              ),
                              style: {
                                backgroundColor: kPrimaryLight,
                                borderRadius: '4px',
                              },
                            }}
                            renderOption={(option) => (
                              <Typography variant='subtitle2'>
                                {option.display}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder='Select'
                                fullWidth
                                variant='outlined'
                                id='hab_14'
                                size='small'
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
                {/* <Box
                  display='flex'
                  width='100%'
                  flexDirection='row'
                  paddingTop={0.5}
                >
                  <Box width='60%'>
                    <WelloFormItemLabel title='Any Other Habit' />
                  </Box>
                  <Box width='40%'>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `actor_other`,
                        type: 'text',
                        error:
                          validateOthers(
                            otherData ? otherData!.display ?? '' : '',
                            false
                          ).length > 0,
                        helperText:
                          validateOthers(
                            otherData ? otherData!.display ?? '' : '',
                            false
                          ).length > 0
                            ? `${validateOthers(
                                otherData ? otherData!.display ?? '' : '',
                                false
                              )}`
                            : '',
                        onChange: (changeData) => {
                          setOtherData({
                            code: 'other-habit',
                            system:
                              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-habit-cs',
                            display: changeData.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                </Box> */}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={3.5}>
          <Button
            onClick={() => {
              onClose()
              setExcersiseDuration(undefined)
              setExcersiseIntencity(undefined)
              setEnergylevel(undefined)
              setBreathePattern(undefined)
              setSleep(undefined)
              setCofee(undefined)
              setTea(undefined)
              setSleepDay(undefined)
              setOther(false)
              setOtherData(undefined)
            }}
            id='hab_20'
            variant='outlined'
            disabled={addHabitSliceOPD.adding}
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                addHabitsOPD(
                  fhirAppointmentDetails,
                  excerciseDuration,
                  excersiseIntensity,
                  energyLevel,
                  breatePattern,
                  sleep,
                  cofee,
                  tea,
                  sleepDay,
                  otherData
                )
              )
            }}
            variant='contained'
            color='primary'
            id='hab_21'
            disableElevation
            disabled={
              (excerciseDuration === undefined &&
                excersiseIntensity === undefined &&
                energyLevel === undefined &&
                breatePattern === undefined &&
                sleep === undefined &&
                cofee === undefined &&
                sleepDay === undefined &&
                tea === undefined) ||
              addHabitSliceOPD.adding ||
              isOtherSelected() ||
              validateOthers(otherData ? otherData!.display ?? '' : '', false)
                .length > 0
            }
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
