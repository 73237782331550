import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'

import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloMedication } from 'models/welloMedication'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getMedicationRequestFromWelloMedicationsWithoutEncounter } from 'utils/appointment_handle/medications_util'
import { getWelloMedicationFromMedicationRequest } from 'utils/sopaNotes/plan/ayurvedaMedicationHelpers'
import { AllopathyMedicationCaptureForm } from '../../addition/allopathyMedicationCaptureComponent'
import { AyurvedaMedicationCaptureForm } from '../../addition/ayurvedaMedicationCaptureComponent'
import { AddedMedicationsList } from '../../assessment/medicationsListTable'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  preAddedMedications: R4.IMedicationRequest[]
  onClose: () => void
  onMedicalConditionAdded: (medications: R4.IMedicationRequest[]) => void
}
export const AddMedicationsFromCPGHandler: React.FC<Props> = ({
  open,
  onClose,
  onMedicalConditionAdded,
  preAddedMedications,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])
  const [addedMedications, setAddedMedications] = useState<WelloMedication[]>(
    []
  )

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '80%',
      overflow: 'visible',
    },
  }))
  const classes = useStyles()

  const [currentMedication, setCurrentMedication] = useState<WelloMedication>()

  const [currentMedicationDetails, setCurrentMedicationDetails] =
    useState<WelloMedication>(
      getWelloMedicationFromMedicationRequest(preAddedMedications[0])
    )

  const [currentIndex, setCurrentIndex] = useState(0)

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloMedication[] = [...addedMedications]
    const newMedication: WelloMedication = {
      ...currentMedication,
    }
    tempMedication.push(newMedication)
    Promise.resolve().then(() => {
      setAddedMedications(tempMedication)

      if (currentIndex + 1 < preAddedMedications.length) {
        setCurrentMedicationDetails(
          getWelloMedicationFromMedicationRequest(
            preAddedMedications[currentIndex + 1]
          )
        )
      } else {
        setAddedMedications([])
        onMedicalConditionAdded(
          tempMedication.map((e, index) =>
            getMedicationRequestFromWelloMedicationsWithoutEncounter(
              e,
              fhirAppointmentDetails,

              index
            )
          )
        )
      }
      setCurrentIndex(currentIndex + 1)
    })
    // if (pushToSave) {
    //   dispatch(addMedicationsDetails(fhirAppointmentDetails, tempMedication))
    // }
  }

  function isValidMedicationDetails() {
    if (currentMedication) {
      if (
        currentMedication.medication !== undefined ||
        currentMedication.medicationCode !== undefined
      ) {
        if (
          currentMedication.dosageQuantity !== undefined &&
          currentMedication.dosageQuantityType !== undefined &&
          currentMedication.timeOfDayCode !== undefined &&
          currentMedication.timeOfDayCode.length > 0 &&
          currentMedication.dosageQuantity > 0
        ) {
          return true
        }
      }
      return false
    }
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setAddedMedications([])
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
      aria-labelledby='responsive-dialog-title'
      BackdropProps={{}}
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      key='add_medication_dialog'
      id='add_cpg_medication_dialog'
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:add_medication')}
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          overflowY: 'auto',
          margin: 0,
          overflowX: 'hidden',
          scrollBehavior: 'smooth',
        }}
      >
        <Box marginX={2} marginY={1} display='flex' flexDirection='column'>
          {currentMedicationDetails.type === 'allopathy' && (
            <AllopathyMedicationCaptureForm
              key={(currentMedicationDetails.id ?? '') + currentIndex}
              preEnteredQueryText={
                currentMedicationDetails.medicationCode?.display ?? ''
              }
              disableStartAndEndDateSelector={false}
              onChanged={(currentMedDetails) => {
                setCurrentMedication(currentMedDetails)
              }}
            />
          )}

          {currentMedicationDetails.type === 'ayurveda' && (
            <AyurvedaMedicationCaptureForm
              key={
                (currentMedicationDetails.id ??
                  currentMedicationDetails.medicationCode?.display ??
                  '') + currentIndex
              }
              preSelectedMedicationCode={
                currentMedicationDetails.medicationCode
              }
              disableStartAndEndDateSelector={false}
              preEnteredQueryText={
                currentMedicationDetails.medicationCode?.display ?? ''
              }
              onChanged={(currentMedDetails) => {
                setCurrentMedication(currentMedDetails)
              }}
            />
          )}

          {addedMedications.length > 0 && (
            <Box display='flex' flexDirection='row' width='100%' px={2}>
              <Box
                marginY={1}
                display='flex'
                flexDirection='column'
                width='100%'
              >
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{ padding: '2px' }}
                >
                  Currently Added Medications
                </Typography>

                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'

                  //   key={e.id}
                >
                  <AddedMedicationsList
                    medication={addedMedications}
                    key='1'
                    displayTypeOfMedication={true}
                    displayStartEndDates={true}
                    appointmentId=''
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          flexDirection='row'
          p={1}
          flexGrow={1}
          alignItems='end'
          alignContent='end'
          justifyContent='end'
          width='100%'
        >
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  // resetForm()
                  onClose()
                  setAddedMedications([])
                }}
                variant='outlined'
                disableElevation
                id='cancel'
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  addCurrentDetails()
                }}
                id='another'
                variant='contained'
                color='primary'
                disableElevation
                disabled={!isValidMedicationDetails()}
              >
                {currentIndex + 1 < preAddedMedications.length
                  ? 'Add and Fill Next'
                  : 'Finish'}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
