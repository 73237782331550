import { IBundle, ICoding, IGroup } from '@ahryman40k/ts-fhir-types/lib/R4'
import MaterialTable, { Column, MTableToolbar } from '@material-table/core'
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  kBackgroundDefault,
  kDialogueBackground,
  kPrimaryLight,
} from 'configs/styles/muiThemes'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _ from 'lodash'
import { CareTeamMemberDetail } from 'models/administration/CareTeamMemberDetails'
import { CPGForTable } from 'models/administration/cpgDetailsForTable'

import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isPremiumOffer } from 'utils/admisnistration/cpg/cpgListHelper'
import {
  addStandByGroupToSelectedCarePlan,
  getCareTeamDetailsFromBundle,
  getStandByCareTeamOfCarePlanOffer,
} from 'utils/admisnistration/crossFunctional/crossFunctinalUtils'
import { titleCase } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrl'
import OverflowTwoLineTypography from 'views/components/consultation/plan/ayurveda/overflowTwoLineTypography'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '2px',
      margin: '0px',
    },

    '& .MuiTableRow': {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
      },
    },
  },
})

interface CPGListTableProps {
  medicationsList: CPGForTable
  onMembersFetched: (
    group: IGroup | false | undefined,
    members: CareTeamMemberDetail[] | undefined
  ) => void
  onClear: () => void
}

export const ExistingCareTeamList: React.FC<CPGListTableProps> = ({
  medicationsList,
  onMembersFetched,
  onClear,
}: CPGListTableProps) => {
  const ref = React.useRef<null | HTMLDivElement>(null)

  const [fetchStatus, setFetchStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [userCareStatus, setCareStatus] = useState<{
    [key: string]: ICoding[]
  }>({})

  const [userCurrentCareStatus, setCurrentCareStatus] = useState<{
    [key: string]: ICoding[]
  }>({})

  const classes = useStyles()
  const [originalData, setOriginalData] = useState<CareTeamMemberDetail[]>([])
  const [data, setData] = useState<CareTeamMemberDetail[]>()

  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const [hasStandByGroup, setHasStandByGroup] = React.useState(false)

  const { t } = useTranslation()

  function fetchStandByGroupOfCpg() {
    setFetchStatus({ requesting: true })
    getStandByCareTeamOfCarePlanOffer(medicationsList.rawCpg).then((e) => {
      if (e !== false) {
        const hcs = e as IBundle

        if (hcs.entry === undefined || hcs.entry.length === 0) {
          setHasStandByGroup(false)
          onMembersFetched(false, undefined)
          setFetchStatus({
            requesting: false,
            requestSuccess: true,
          })
        } else {
          setHasStandByGroup(true)
          const convertedList = getCareTeamDetailsFromBundle(hcs)

          const newMap: any = {}

          convertedList.forEach((ele) => {
            newMap[ele.memberId] = ele.offeredRoles
          })
          setCareStatus(newMap)
          setCurrentCareStatus(newMap)

          setOriginalData(convertedList)
          setData([...convertedList])
          onMembersFetched(hcs.entry[0].resource as IGroup, convertedList)
          setFetchStatus({
            requesting: false,
            requestSuccess: true,
          })
        }
      } else {
        setFetchStatus({
          requesting: false,
          requestError: true,
        })
      }
    })
  }

  function addStandByCareTeam() {
    setFetchStatus({ requesting: true })
    addStandByGroupToSelectedCarePlan(medicationsList.rawCpg).then((e) => {
      if (e) {
        const hcs = e as IGroup

        fetchStandByGroupOfCpg()
      } else {
        setFetchStatus({
          requesting: false,
          requestError: true,
        })
      }
    })
  }

  useEffect(() => {
    fetchStandByGroupOfCpg()
  }, [])

  const columns: Column<CareTeamMemberDetail>[] = [
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            User Name
          </Typography>
        </Box>
      ),

      field: 'memberName',
      customSort: (a: CareTeamMemberDetail, b: CareTeamMemberDetail) =>
        a.memberName > b.memberName ? 1 : b.memberName > a.memberName ? -1 : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: CareTeamMemberDetail) => (
        <Box display='flex' alignItems='center'>
          <OverflowTypography
            text={rowData.memberName ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: true,
            }}
          />
        </Box>
      ),
      filtering: false,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Unit
          </Typography>
        </Box>
      ),
      cellStyle: {
        paddingLeft: '16px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      field: 'unitName',
      customSort: (a: CareTeamMemberDetail, b: CareTeamMemberDetail) =>
        (a.unitName ?? '') > (b.unitName ?? '')
          ? 1
          : (b.unitName ?? '') > (a.unitName ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,

      render: (rowData: CareTeamMemberDetail) => (
        <Box display='flex' alignItems='center'>
          <OverflowTwoLineTypography
            text={rowData.unitName ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: false,
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Role
          </Typography>
        </Box>
      ),

      field: 'roleString',
      customSort: (a: CareTeamMemberDetail, b: CareTeamMemberDetail) =>
        (a.roleString ?? '') > (b.roleString ?? '')
          ? 1
          : (b.roleString ?? '') > (a.roleString ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,

      render: (rowData: CareTeamMemberDetail) => (
        <Box display='flex' alignItems='center'>
          <OverflowTwoLineTypography
            text={rowData.speciality ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: false,
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Care Team Roles
          </Typography>
        </Box>
      ),

      field: 'offeredRoles',

      hiddenByColumnsButton: false,
      filtering: false,

      render: (rowData: CareTeamMemberDetail) => (
        <Box display='flex' flexDirection='row' pr={0.5}>
          <Box width='150px' minWidth='150px'>
            <ValueSetSelectMultiple
              key={`wellopathy-care-team-roles-vs${rowData.memberId}`}
              title='Role'
              url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-care-team-roles-vs'
              id={`wellopathy-care-team-roles-vs${rowData.memberId}-id`}
              fuzzySearch={true}
              disabled={false}
              hideTitle={true}
              displaySelectedOptions={false}
              onOptionSelected={(selectedServiceType) => {
                if (selectedServiceType.length > 0) {
                  const newMap = { ...userCurrentCareStatus }
                  newMap[rowData.memberId] = [
                    ...selectedServiceType,
                    ...newMap[rowData.memberId],
                  ]

                  setCurrentCareStatus(newMap)
                } else {
                  const newMap = { ...userCurrentCareStatus }
                  newMap[rowData.memberId] = []
                  setCurrentCareStatus(newMap)
                }
              }}
              helperText={undefined}
            />
          </Box>
          <Box display='flex' width='150px' minWidth='150px'>
            {userCurrentCareStatus[rowData.memberId].map(
              (item, index: number) => (
                <Chip
                  key={item.code}
                  label={titleCase(item.display ?? '')}
                  id={`del${index}`}
                  size='small'
                  style={{ margin: 2 }}
                  color='primary'
                  onDelete={() => {
                    const newValue = userCurrentCareStatus[
                      rowData.memberId
                    ].filter((entry) => entry.code !== item.code)
                    const newMap = { ...userCurrentCareStatus }
                    newMap[rowData.memberId] = newValue
                    setCurrentCareStatus(newMap)
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      const newValue = userCurrentCareStatus[
                        rowData.memberId
                      ].filter((entry) => entry.code !== item.code)
                      const newMap = { ...userCurrentCareStatus }
                      newMap[rowData.memberId] = newValue
                      setCurrentCareStatus(newMap)
                    }
                  }}
                />
              )
            )}
          </Box>
        </Box>
      ),
    },
  ]

  if (fetchStatus.requesting === true) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        height='100%'
        flexGrow={1}
        px={2}
        py={0.5}
        alignContent='center'
        justifyContent='center'
      >
        <Typography
          variant='subtitle2'
          color='initial'
          style={{
            textAlign: 'center',
          }}
        >
          Loading...
        </Typography>
      </Box>
    )
  }

  if (fetchStatus.requestSuccess === true && hasStandByGroup === false) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        height='100%'
        flexGrow={1}
        px={2}
        py={0.5}
        alignContent='center'
        justifyContent='center'
      >
        <Typography
          variant='subtitle2'
          color='initial'
          style={{
            textAlign: 'center',
          }}
        >
          No standby care team found
        </Typography>

        <Box
          p={2}
          alignContent='center'
          justifyContent='center'
          display='flex'
          flexDirection='row'
          width='100%'
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              addStandByCareTeam()
            }}
          >
            Add Standby Care Team
          </Button>
        </Box>
      </Box>
    )
  }

  if (fetchStatus.requestError === false) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        height='100%'
        flexGrow={1}
        px={2}
        py={0.5}
        alignContent='center'
        justifyContent='center'
      >
        <Typography
          variant='subtitle2'
          color='initial'
          style={{
            textAlign: 'center',
          }}
        >
          Error while fetching standby care team
        </Typography>
      </Box>
    )
  }

  if (
    fetchStatus.requestSuccess === true &&
    hasStandByGroup === true &&
    data !== undefined
  ) {
    if (data.length === 0) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          height='100%'
          flexGrow={1}
          px={2}
          py={0.5}
          alignContent='center'
          justifyContent='center'
        >
          <Typography
            variant='subtitle2'
            color='initial'
            style={{
              textAlign: 'center',
            }}
          >
            No care team members found
          </Typography>
        </Box>
      )
    }
    if (data.length > 0) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          height='100%'
          overflow='auto'
        >
          <div
            style={{
              width: '100%',

              maxHeight: '350px',
              overflow: 'auto',
            }}
          >
            <MaterialTable
              components={{
                Toolbar: (props) => (
                  <div
                    /* className={classes.toolbarWrapper} */
                    style={{
                      minHeight: '0px',
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                ),
                Container: (props) => (
                  <Paper
                    {...props}
                    elevation={0}
                    style={{
                      maxHeight: '100%',
                      height: '100%',
                      backgroundColor: useTheme().palette.background.paper,
                    }}
                    classes={classes}
                  />
                ),
                Pagination: (props) => <div />,
              }}
              columns={columns}
              data={data}
              onOrderChange={(orderBy, orderDirection) => {
                const key = orderBy < 1 ? 0 : orderBy
              }}
              onRowClick={(event, rowData) => {
                /* if (rowData && onOrgSelected) {
                    onOrgSelected(rowData.orgDetails, rowData.mainOrgId ?? '')
                    setSelectedRowId(rowData ? rowData.orgName ?? '' : '')
                  } */
              }}
              options={{
                toolbar: false,
                search: false,
                showTitle: false,
                columnResizable: false,
                paginationPosition: 'bottom',

                padding: 'dense',
                searchFieldVariant: 'outlined',
                filtering: false,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 20, 30],
                //   columnsButton: true,
                // maxBodyHeight: `${height - 190}px`,
                //   minBodyHeight: `${height - 260}px`,
                tableLayout: 'fixed',
                sorting: true,
                thirdSortClick: false,

                headerStyle: {
                  backgroundColor: kBackgroundDefault,
                  width: 100,
                  minHeight: '12px',
                  maxHeight: '12px',
                  position: 'sticky',
                  top: 0,
                  zIndex: 100,
                  // height: 20,
                },
                selectionProps: (_rowData: any) => ({
                  color: 'primary',
                }),
                actionsColumnIndex: -1,
                searchFieldAlignment: 'left',
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: '{from}-{to} of {count}',
                },
                toolbar: {
                  nRowsSelected: '{0} row(s) selected',
                },
                header: {
                  actions: '',
                },
                body: {
                  emptyDataSourceMessage: 'No records to display',
                  filterRow: {
                    filterTooltip: 'Filter',
                  },
                },
              }}
            />
          </div>
          <Box display='flex' flexDirection='row-reverse'>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                if (onClear) {
                  onClear()
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={_.isEqual(userCareStatus, userCurrentCareStatus)}
              onClick={() => {
                // updateCareStatus()
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      )
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={ref}
    >
      No action
    </div>
  )
}
