import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'

import { LabReferralService, LabTabularService } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addLabTestReferralDetails,
  resetAddTestReferralsState,
} from 'redux/administration/referralSetup/addReferral/addLabTestReferralsSlice'
import {
  resetReferralOfferingSearch,
  searchLabOfferings,
} from 'redux/administration/referralSetup/labTestSearch/referralLabOfferingSearchSlice'
import {
  requestLabOrder,
  resetLabDiagnosticCreationState,
} from 'redux/consultation/createLabDiagnosticsSlice/createLabDiagnosticsSlice'
import { RootState } from 'redux/rootReducer'
import { MasterDataSelecter } from 'views/components/lab/catalougeSetup/masterDataSelector'
import { LabReferralSelector } from 'views/components/lab/orderCreationManagement/labReferralSelector'
import { getIdentifierValueBySystem } from 'utils/fhirResourcesHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { resetCatalogueSearchStatus } from 'redux/lab/masterDataSearch/masterDataSearchSlice'
import { getLabServiceDetails } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { LabDiagnosticTabularForDisplay } from '../assessment/labTestTabularDisplay'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onLabDiagnosticsAdded: () => void
}
export const AddDiagnosticsTests: React.FC<Props> = ({
  open,
  onClose,
  onLabDiagnosticsAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common'])
  const [selectedPlanningDefs, setSelectedPlanDefs] = useState<
    R4.IPlanDefinition[]
  >([])

  const [allLabTest, setAllLabTest] = useState<R4.IPlanDefinition[]>([])

  const [service, setServices] = useState<LabReferralService>()
  const [planDef, setPlanDef] = useState<R4.IPlanDefinition>()
  const [tabularArray, setTabularArray] = useState<LabTabularService[]>([])
  const [addAnother, setAddAnother] = React.useState<boolean>(true)
  const [serviceArray, setServicesArray] = useState<LabReferralService[]>([])
  const [planDefArray, setPlanDefArray] = useState<R4.IPlanDefinition[]>([])
  const [addMore, setAddMore] = useState<boolean>(false)

  const dispatch = useDispatch()

  const masterDataSearchSlice = useSelector(
    (state: RootState) => state.masterDataSearchSlice
  )

  const addLabTestReferralsSlice = useSelector(
    (state: RootState) => state.addLabTestReferralSlice
  )

  const referralLabOfferingSlice = useSelector(
    (state: RootState) => state.referralLabOfferingSlice
  )

  function enableButtons() {
    const data: string[] = []
    if (
      referralLabOfferingSlice.resultsAvailable &&
      referralLabOfferingSlice.healthCareService
    ) {
      if (planDef) {
        const tempServiceArray: LabTabularService[] = [...tabularArray]

        if (tempServiceArray.length > 0) {
          const advanceDetails: LabTabularService[] = tempServiceArray.filter(
            (e) =>
              getIdentifierValueBySystem(
                e.planDefinition!.identifier ?? [],
                'http://loinc.org'
              ) ===
              getIdentifierValueBySystem(
                planDef.identifier ?? [],
                'http://loinc.org'
              )
          )

          if (advanceDetails.length === 0) {
            data.push('1')
          }
        } else {
          data.push('1')
        }
      }
    }
    if (data.length > 0) return true
    return false
  }

  useEffect(() => {
    if (addLabTestReferralsSlice.additionSuccessful) {
      setTabularArray([])
      setAllLabTest([])
      setServicesArray([])
      resetForm()

      onLabDiagnosticsAdded()
      setSelectedPlanDefs([])
      dispatch(resetAddTestReferralsState())
    }
  }, [dispatch, addLabTestReferralsSlice, onLabDiagnosticsAdded])

  useEffect(() => {
    if (
      referralLabOfferingSlice.resultsAvailable &&
      referralLabOfferingSlice.healthCareService
    ) {
      const temData: R4.IPlanDefinition[] = [...allLabTest]
      //   const tempServiceArray: LabTabularService[] = [...tabularArray]
      //   if (planDef !== undefined) {
      //     temData.push(planDef)
      //   }
      //   tempServiceArray.push({
      //     name: planDef ? planDef.title ?? '' : '',
      //     healthService: undefined,
      //     planDefinition: planDef || undefined,
      //   })

      setAllLabTest(temData)
      //   setTabularArray(
      //     getLabServiceDetails(tempServiceArray, selectedPlanningDefs)
      //   )

      //   setTabularArray(tempServiceArray)
    }

    if (referralLabOfferingSlice.noResultsAvailable) {
      const temData: R4.IPlanDefinition[] = [...allLabTest]
      const tempServiceArray: LabTabularService[] = [...tabularArray]
      if (planDef !== undefined) {
        temData.push(planDef)
      }
      tempServiceArray.push({
        name: planDef ? planDef.title ?? '' : '',
        healthService: undefined,
        planDefinition: planDef || undefined,
      })

      setAllLabTest(temData)
      setTabularArray(
        getLabServiceDetails(tempServiceArray, selectedPlanningDefs)
      )

      setTabularArray(tempServiceArray)
    }
  }, [dispatch, referralLabOfferingSlice])

  function resetForm() {
    dispatch(resetLabDiagnosticCreationState())
    dispatch(resetReferralOfferingSearch())
    setSelectedPlanDefs([])

    setServices(undefined)
    setPlanDef(undefined)

    // setAddMore(false)
  }

  function addCurrentDetails(pushToSave?: boolean) {
    const tempServiceArray: LabReferralService[] = [...serviceArray]
    if (service !== undefined) tempServiceArray.push(service!)
    Promise.resolve().then(() => {
      setServicesArray(tempServiceArray)
      resetForm()
    })
    if (pushToSave) {
      if (tempServiceArray.length > 0) {
        dispatch(
          addLabTestReferralDetails(
            fhirAppointmentDetails,
            tempServiceArray,
            allLabTest,
            ''
          )
        )
      } else {
        console.log('1344')
        dispatch(
          addLabTestReferralDetails(fhirAppointmentDetails, [], allLabTest, '')
        )
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        setTabularArray([])
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Add Diagnostic Test</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box marginX={2} marginY={1} display='flex' flexDirection='column'>
          <Box marginY={1} display='flex' flexDirection='column'>
            <WelloFormItemLabel title='Select Test/Panel' case='yes' />
            {addMore && (
              <MasterDataSelecter
                disabled={false}
                onSelectionChanges={(plans) => {
                  setPlanDef(plans)
                  const temp: R4.IPlanDefinition[] = [...selectedPlanningDefs]
                  setAddMore(false)
                  temp.push(plans)

                  setSelectedPlanDefs(temp)
                  dispatch(
                    searchLabOfferings(
                      '',
                      getIdentifierValueBySystem(
                        plans.identifier ?? [],
                        'http://loinc.org'
                      )
                    )
                  )
                }}
                preselectedDefinition={planDef}
              />
            )}
            {!addMore && (
              <MasterDataSelecter
                disabled={false}
                onSelectionChanges={(plans) => {
                  const temp: R4.IPlanDefinition[] = [...selectedPlanningDefs]
                  setPlanDef(plans)
                  temp.push(plans)

                  setSelectedPlanDefs(temp)

                  dispatch(
                    searchLabOfferings(
                      '',
                      getIdentifierValueBySystem(
                        plans.identifier ?? [],
                        'http://loinc.org'
                      )
                    )
                  )
                }}
                preselectedDefinition={planDef}
              />
            )}
          </Box>
          {referralLabOfferingSlice.resultsAvailable &&
            referralLabOfferingSlice.healthCareService && (
              <Box marginY={1} display='flex' flexDirection='column'>
                <WelloFormItemLabel title='Select Lab' />
                <LabReferralSelector
                  data={referralLabOfferingSlice.healthCareService ?? []}
                  onSelectionChanges={(e: LabReferralService) => {
                    const tempServiceArray: LabTabularService[] = [
                      ...tabularArray,
                    ]
                    if (e.healthService.id === undefined) {
                      setServices({
                        name: e.name,
                        healthService: e.healthService,
                        planDefinition: planDef!,
                      })
                    } else {
                      setServices(e)
                    }

                    if (tempServiceArray.length > 0) {
                      const dataWithHv = tempServiceArray.filter(
                        (d: LabTabularService) =>
                          getIdentifierValueBySystem(
                            d.planDefinition!.identifier ?? [],
                            'http://loinc.org'
                          ) ===
                          getIdentifierValueBySystem(
                            planDef!.identifier ?? [],
                            'http://loinc.org'
                          )
                      )
                      if (dataWithHv.length > 0) {
                        for (let i = 0; i < tempServiceArray.length; i++) {
                          if (tempServiceArray[i].planDefinition && planDef) {
                            if (
                              getIdentifierValueBySystem(
                                tempServiceArray[i].planDefinition!
                                  .identifier ?? [],
                                'http://loinc.org'
                              ) ===
                              getIdentifierValueBySystem(
                                planDef!.identifier ?? [],
                                'http://loinc.org'
                              )
                            ) {
                              tempServiceArray[i] = {
                                name: planDef ? planDef.title ?? '' : '',
                                healthService: e.healthService,
                                planDefinition: planDef || undefined,
                              }
                            }
                          }
                        }
                      } else {
                        tempServiceArray.push({
                          name: planDef ? planDef.title ?? '' : '',
                          healthService: e.healthService,
                          planDefinition: planDef || undefined,
                        })
                      }
                    } else {
                      tempServiceArray.push({
                        name: planDef ? planDef.title ?? '' : '',
                        healthService: e.healthService,
                        planDefinition: planDef || undefined,
                      })
                    }

                    console.log(tempServiceArray)
                    setTabularArray(tempServiceArray)
                  }}
                  //   preSelectedOrdersRoles={
                  //     referralLabOfferingSlice.healthCareService.length > 0
                  //       ? referralLabOfferingSlice.healthCareService[0]
                  //       : undefined
                  //   }
                  disabled={false}
                />
              </Box>
            )}
          <Box marginY={1} display='flex' flexDirection='column'>
            {tabularArray && tabularArray.length > 0 && (
              <LabDiagnosticTabularForDisplay referrals={tabularArray} />
            )}
          </Box>

          <Box marginY={1} display='flex' flexDirection='column'>
            {referralLabOfferingSlice.searching && (
              <Box flexGrow width='100%' display='flex' flexDirection='row'>
                <CircularProgress size={15} />
              </Box>
            )}
            {referralLabOfferingSlice.error && (
              <Typography>
                {' '}
                {referralLabOfferingSlice.errorMessage ??
                  'Error while searching'}
              </Typography>
            )}
            {/* {referralLabOfferingSlice.noResultsAvailable && (
              <Typography> No labs available</Typography>
            )} */}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {addLabTestReferralsSlice.adding && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        {/* <Box flexGrow={1} alignSelf='center' paddingX={1}>

          <FormControlLabel
            value='end'
            control={
              <Checkbox
                inputProps={{
                  id: 'add_another',
                }}
                color='primary'
                checked={addAnother}
                onChange={(eve, checked) => {
                  setAddAnother(checked)
                }}
              />
            }
            label='Add another test/panel'
            labelPlacement='end'
          />
        </Box> */}
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  setTabularArray([])
                  resetForm()
                  setAllLabTest([])
                  onClose()
                }}
                variant='outlined'
                disableElevation
                id='cancel'
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>

            <Box paddingX={1}>
              <Button
                onClick={() => {
                  dispatch(resetCatalogueSearchStatus())

                  setAddMore(true)

                  setServices(undefined)
                  addCurrentDetails()
                }}
                id='another'
                variant='contained'
                color='primary'
                disableElevation
                disabled={
                  addLabTestReferralsSlice.adding ||
                  referralLabOfferingSlice.searching ||
                  enableButtons() ||
                  planDef === undefined
                }
              >
                {t('labelCommon:add_more_new')}
              </Button>
            </Box>

            <Box paddingX={1}>
              <Button
                onClick={() => {
                  addCurrentDetails(true)
                }}
                variant='contained'
                color='primary'
                disableElevation
                disabled={
                  addLabTestReferralsSlice.adding ||
                  enableButtons() ||
                  referralLabOfferingSlice.searching ||
                  (service === undefined &&
                    selectedPlanningDefs.length === 0 &&
                    tabularArray.length === 0)
                }
                id='save'
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
