/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box, Typography, useTheme } from '@material-ui/core'
import { render } from '@testing-library/react'
import { kPrimaryDark, kPrimaryLight } from 'configs/styles/muiThemes'
import React from 'react'

interface IProps {
  isSelected?: boolean
  textTitle?: string
  onClick?: () => void
}

export const EachDay: React.FC<IProps> = ({
  isSelected = true,
  textTitle = 'text',
  onClick,
}: IProps) => {
  const primaryTextColor: string = useTheme().palette.text.primary
  return (
    <Box
      display='flex'
      height='30px'
      width='30px'
      marginX={1}
      id={textTitle}
      tabIndex={-1}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      bgcolor={isSelected ? kPrimaryLight : ''}
      borderRadius={100}
      justifyContent='center'
      alignItems='center'
    >
      <Typography
        variant='subtitle2'
        align='center'
        style={{
          color: isSelected ? kPrimaryDark : primaryTextColor,
        }}
      >
        {textTitle}
      </Typography>
    </Box>
  )
}
