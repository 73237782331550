import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  CapabilityStatement_SearchParamTypeKind,
  ObservationStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  useTheme,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedOccupation } from 'models/groupedOccupations'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloGeneralExam } from 'models/WelloConditions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import {
  addGeneralExam,
  createBundleObjectForObservationsForGeneralExam,
  resetAddGeneralExaminationState,
} from 'redux/fhirMedicalResources/addGeneralExam/addGeneralExamSlice'
import {
  addVitalDetails,
  resetAddVitalsState,
} from 'redux/fhirMedicalResources/addVital/addVitalSlice'
import { RootState } from 'redux/rootReducer'
import {
  cToF,
  fToC,
  getClubbing,
  getClubbingRemarks,
  getCyn,
  getCynRemarks,
  getIct,
  getIctRemarks,
  getLymp,
  getLympRemarks,
  getNourishData,
  getPallor,
  getPallorRemarks,
  getPreBuiltData,
} from 'utils/appointment_handle/vitals_util'
import { updateAddictions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  RadioRaisedButton,
  WelloFormItemLabel,
  WelloLoadingPage,
} from 'wello-web-components'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  preSelectedGeneralExam?: GroupedOccupation
  onClose: () => void
  onVitalAdded: (createdCondition: R4.IBundle | undefined) => void
  existingExam: WelloGeneralExam
}
export const EditGeneralExxam: React.FC<Props> = ({
  open,
  onClose,
  onVitalAdded,
  fhirAppointmentDetails,

  preSelectedGeneralExam,
  existingExam,
}) => {
  const { t } = useTranslation(['common', 'observation'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '35%',
    },
  }))
  const classes = useStyles()

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const [currentCondition, setCurrentCondition] = useState<WelloGeneralExam>({
    id: existingExam.id,
    existingObs: existingExam.existingObs,
    built: existingExam.built,
    nourishment: existingExam.nourishment,
    pallor: existingExam.pallor,
    pallorRemarks: existingExam.pallorRemarks,
    ict: existingExam.ict,
    ictRemarks: existingExam.ictRemarks,
    cyn: existingExam.cyn,
    cynRemarks: existingExam.cynRemarks,
    clubbing: existingExam.clubbing,
    clubbingRemarks: existingExam.clubbingRemarks,
    lymp: existingExam.lymp,
    lympRemarks: existingExam.lympRemarks,
  })

  const dispatch = useDispatch()

  const addVitalSlice = useSelector(
    (state: RootState) => state.addGeneralExaminationSlice
  )

  function updateObsFinal() {
    setUpdateStatus({ requesting: true })
    const oldObsList: R4.IObservation[] = []
    if (
      currentCondition.existingObs &&
      currentCondition.existingObs.length > 0
    ) {
      for (let i = 0; i < currentCondition.existingObs!.length; i++) {
        const oldProcedure: R4.IObservation = {
          ...currentCondition.existingObs![i],
        }
        oldProcedure.status = ObservationStatusKind._enteredInError
        oldObsList.push(oldProcedure)
      }
    }
    console.log(currentCondition)

    const bundleObject: R4.IBundle =
      createBundleObjectForObservationsForGeneralExam(
        fhirAppointmentDetails,
        currentCondition.built,
        currentCondition.nourishment,
        currentCondition.pallor,
        currentCondition.pallorRemarks,
        currentCondition.ict,
        currentCondition.ictRemarks,
        currentCondition.cyn,
        currentCondition.cynRemarks,
        currentCondition.clubbing,
        currentCondition.clubbingRemarks,
        currentCondition.lymp,
        currentCondition.lympRemarks
      )

    for (let i = 0; i < oldObsList.length; i++) {
      bundleObject.entry?.push({
        resource: oldObsList[i],
        fullUrl: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        },
      })
    }

    setLoading(true)

    updateAddictions(bundleObject, fhirAppointmentDetails.appointment.id!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showSuccessAlert('General Exam details updated successfully')
          )
          onVitalAdded(undefined)
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating General Exam details. Please try again later'
            )
          )
        }
      }
    )
  }

  console.log(preSelectedGeneralExam)

  useEffect(() => {
    setCurrentCondition({
      id: existingExam.id,
      existingObs: existingExam.existingObs,
      built: existingExam.built,
      nourishment: existingExam.nourishment,
      pallor: existingExam.pallor,
      pallorRemarks: existingExam.pallorRemarks,
      ict: existingExam.ict,
      ictRemarks: existingExam.ictRemarks,
      cyn: existingExam.cyn,
      cynRemarks: existingExam.cynRemarks,
      clubbing: existingExam.clubbing,
      clubbingRemarks: existingExam.clubbingRemarks,
      lymp: existingExam.lymp,
      lympRemarks: existingExam.lympRemarks,
    })
  }, [existingExam])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()

        // dispatch(resetAddVitalsState())
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        Edit General Examination
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box
          marginLeft={2}
          marginY={1}
          display='flex'
          justifyContent='flex-start'
          flexDirection='column'
        >
          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Built</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <FormControl>
                <FormLabel id='demo-row-radio-buttons-group-label'>
                  {/* <Typography
                      variant='subtitle1'
                      color='textPrimary'
                      style={{ paddingLeft: 6 }}
                    >
                      Time Period
                    </Typography> */}
                </FormLabel>
                <RadioGroup
                  id='gen_5'
                  row
                  aria-label='graphType'
                  value={currentCondition.built}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentCondition({
                      ...currentCondition,
                      built: (event.target as HTMLInputElement).value,
                    })
                    // setBuiltType((event.target as HTMLInputElement).value)

                    // setBuiltChanged(true)
                  }}
                >
                  <Box
                    paddingLeft={1.5}
                    width='100%'
                    display='flex'
                    flexDirection='row'
                  >
                    <Box display='flex' flexDirection='column'>
                      <Box display='flex' flexDirection='row'>
                        <FormControlLabel
                          value='normal'
                          control={<Radio color='primary' id='gen_6' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Normal Built
                            </Typography>
                          }
                        />
                        <Box paddingLeft={2.8}>
                          <FormControlLabel
                            value='obese'
                            control={<Radio color='primary' id='gen_7' />}
                            label={
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                noWrap
                              >
                                Obese
                              </Typography>
                            }
                          />
                        </Box>
                      </Box>
                      <FormControlLabel
                        value='emicated'
                        control={<Radio color='primary' id='gen_8' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Emaciated
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Nourishment</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <FormControl>
                <RadioGroup
                  id='gen_9'
                  row
                  aria-label='graphType'
                  value={currentCondition.nourishment}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentCondition({
                      ...currentCondition,
                      nourishment: (event.target as HTMLInputElement).value,
                    })
                    // setNourishment((event.target as HTMLInputElement).value)
                    // setNmemetChanged(true)
                  }}
                >
                  <Box
                    paddingLeft={1.5}
                    width='100%'
                    display='flex'
                    flexDirection='row'
                  >
                    <FormControlLabel
                      value='wellNourished'
                      control={<Radio color='primary' id='gen_10' />}
                      label={
                        <Typography variant='subtitle2' color='initial' noWrap>
                          Well Nourished
                        </Typography>
                      }
                    />
                    <Box paddingLeft={0.8}>
                      <FormControlLabel
                        value='malnourished'
                        control={<Radio color='primary' id='gen_11' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Malnourished
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Pallor</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='gen_12'
                    row
                    aria-label='graphType'
                    value={currentCondition.pallor}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setCurrentCondition({
                          ...currentCondition,
                          pallor: (event.target as HTMLInputElement).value,
                          pallorRemarks: '',
                        })
                      } else {
                        setCurrentCondition({
                          ...currentCondition,
                          pallor: (event.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  >
                    <Box px={1.5} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_13' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.5}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_14' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {currentCondition.pallor === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      type='string'
                      id='gen_15'
                      value={currentCondition.pallorRemarks}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,
                          pallorRemarks: event.target.value,
                        })
                      }}
                      error={
                        currentCondition.pallor &&
                        currentCondition.pallorRemarks.length > 256
                      }
                      helperText={
                        currentCondition.pallor &&
                        currentCondition.pallorRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Icterus</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='gen_16'
                    row
                    aria-label='graphType'
                    value={currentCondition.ict}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setCurrentCondition({
                          ...currentCondition,
                          ict: (event.target as HTMLInputElement).value,
                          ictRemarks: '',
                        })
                      } else {
                        setCurrentCondition({
                          ...currentCondition,
                          ict: (event.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  >
                    <Box px={1.5} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_17' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.5}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_18' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>

          {currentCondition.ict === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      type='string'
                      id='gen_19'
                      value={currentCondition.ictRemarks}
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,
                          ictRemarks: event.target.value,
                        })
                      }}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      error={
                        currentCondition.ict &&
                        currentCondition.ictRemarks.length > 256
                      }
                      helperText={
                        currentCondition.ict &&
                        currentCondition.ictRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Cyanosis</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='add_unit_address_choice_rad_group'
                    row
                    aria-label='graphType'
                    value={currentCondition.cyn}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setCurrentCondition({
                          ...currentCondition,
                          cyn: (event.target as HTMLInputElement).value,
                          cynRemarks: '',
                        })
                      } else {
                        setCurrentCondition({
                          ...currentCondition,
                          cyn: (event.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  >
                    <Box px={1.5} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_20' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.5}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_21' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>

          {currentCondition.cyn === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      type='string'
                      id='gen_23'
                      value={currentCondition.cynRemarks}
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,

                          cynRemarks: event.target.value,
                        })
                      }}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      error={
                        currentCondition.cyn &&
                        currentCondition.cynRemarks.length > 256
                      }
                      helperText={
                        currentCondition.cyn &&
                        currentCondition.cynRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Clubbing</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='gen_24'
                    row
                    aria-label='graphType'
                    value={currentCondition.clubbing}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setCurrentCondition({
                          ...currentCondition,
                          clubbing: (event.target as HTMLInputElement).value,
                          clubbingRemarks: '',
                        })
                      } else {
                        setCurrentCondition({
                          ...currentCondition,
                          clubbing: (event.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  >
                    <Box px={1.3} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_25' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.7}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_26' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {currentCondition.clubbing === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      id='gen_27'
                      type='string'
                      value={currentCondition.clubbingRemarks}
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,

                          clubbingRemarks: event.target.value,
                        })
                      }}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      error={
                        currentCondition.clubbing &&
                        currentCondition.clubbingRemarks.length > 256
                      }
                      helperText={
                        currentCondition.clubbing &&
                        currentCondition.clubbingRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Lymphadenopathy</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='gen_29'
                    row
                    aria-label='graphType'
                    value={currentCondition.lymp}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setCurrentCondition({
                          ...currentCondition,
                          lymp: (event.target as HTMLInputElement).value,
                          lympRemarks: '',
                        })
                      } else {
                        setCurrentCondition({
                          ...currentCondition,
                          lymp: (event.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  >
                    <Box px={1.3} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_30' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.7}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_31' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {currentCondition.lymp === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      id='gen_32'
                      type='string'
                      value={currentCondition.lympRemarks}
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,

                          lympRemarks: event.target.value,
                        })
                      }}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      error={
                        currentCondition.lymp &&
                        currentCondition.lympRemarks.length > 256
                      }
                      helperText={
                        currentCondition.lymp &&
                        currentCondition.lympRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          width='100%'
          flexDirection='row'
          display='flex'
          justifyContent='flex-end'
          paddingRight={4.7}
        >
          {loading && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          <Button
            onClick={() => {
              onClose()
            }}
            id='gen_33'
            variant='outlined'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            id='gen_34'
            onClick={() => {
              updateObsFinal()
            }}
            variant='contained'
            color='primary'
            disabled={loading}
            disableElevation
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
