import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { Diet } from 'models/diet'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  getActualDiet,
  getDietPlanData,
  getTotalDiet,
} from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { addDietDetails } from 'redux/ipd/diet/addDiet/addDiet'
import { requestDietDetails } from 'redux/ipd/diet/viewDiet/dietSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserUnitDetails,
  isCurrentUserDoctor,
} from 'services/userDetailsService'
import { newDiet } from 'utils/constants'
import { getCatalogName } from 'utils/fhirResoureHelpers/catalogHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'

import {
  isFutureDateTimeForDiet,
  isPastDateTimeForDiet,
  isExistingDiet,
  isPastDate,
  isDietCompleted,
  addDietDataForCustom,
  getDietPlans,
  checkDisabledForSix,
  checkDisabledForBreakFast,
  checkDisabledForMidMorning,
  checkDisabledForBedTime,
  checkDisabledForLunch,
  checkDisabledForSnacks,
  checkDisabledForDinner,
  getEarlyMorningTime,
  getEarlyBreakFastTime,
  getMidMorningTime,
  getSnackTime,
  getLunchTime,
  getDinnerTime,
  getBedTime,
  getEarlyMorningTimeForPullDown,
  getBedTimeForPullDown,
  getDinnerTimeForPullDown,
  getSnackTimeForPullDown,
  getMidMorningTimeForPullDown,
  getLunchTimeForPullDown,
  getEarlyBreakFastTimeForPullDown,
  isPastDateForCustom,
  isPastDateTimeForCustomDiet,
  getDinnerTimeForDisable,
  getSnackTimeForDisable,
  getLunchTimeForDisable,
  getMidMorningTimeForDisable,
  getBreakFastForDisable,
  getEarlyMorningForDisable,
  getBedTimeForDisable,
} from 'utils/fhirResoureHelpers/ipdDietHelper'

import { CatalogSelector } from 'views/components/common/catalog_entry_selector'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { DietSelector } from '../ipdconusltation/diet/diet_selector'
import { WelloTextFieldWithoutTitle } from '../LeftMenu/WelloTextFieldWitoutTitle'

interface AssessmentCaptureProps {
  appointmentStartDate: string
  encounterID?: string
  practitionerRoleId?: string
  split?: boolean
  patientID: string
  mode?: 'new' | 'edit' | 'view'
  existingStartDate?: string
  existingEndDate?: string
  onDietAdd?: (diet: Diet[], startDate: string, endDate?: string) => void
}

export const CustomDiet: React.FC<AssessmentCaptureProps> = ({
  appointmentStartDate,
  encounterID,
  practitionerRoleId,
  split,
  patientID,
  mode = 'edit',
  existingEndDate,
  existingStartDate,
  onDietAdd,
}: AssessmentCaptureProps) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )

  const addDietSlice = useSelector((state: RootState) => state.addDietSlice)

  const getDietDetails = useSelector(
    (state: RootState) => state.dietSearchSlice
  )

  const [formState, setFormState] = useState(_.random(0, 109090).toFixed(2))
  const today = moment()
  const weekDate = today.startOf('week')
  const weekEndDate = moment().endOf('week')

  const [startDateVal, setStartDateVal] = useState<string>(
    existingStartDate
      ? moment(existingStartDate).format('YYYY-MM-DD') >
        moment().format('YYYY-MM-DD')
        ? moment(existingStartDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
  )

  const startDateForCustom = moment().format('YYYY-MM-DD')
  const endDateForCustom = moment().add(15, 'days').format('YYYY-MM-DD')

  const [endDateVal, setEndDateVal] = useState<string | undefined>(
    existingStartDate
      ? moment(existingStartDate).format('YYYY-MM-DD') >
        moment().format('YYYY-MM-DD')
        ? moment(existingStartDate).add(6, 'days').format('YYYY-MM-DD')
        : moment().add(6, 'days').format('YYYY-MM-DD')
      : moment().add(6, 'days').format('YYYY-MM-DD')
  )

  function updateCurrentSelection(
    catalogType: R4.ICatalogEntry[],
    dietDate: number,
    timeIndex: string
  ) {
    const selectedDiet: Diet = { ...dietChart[dietDate] }
    const nextSelected: Diet = { ...dietChart[dietDate + 1] }
    switch (timeIndex) {
      case getEarlyMorningTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.six = [updateNutrition(catalogType, selectedDiet.six![0])]

        break
      case getEarlyBreakFastTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.eight = [
          updateNutrition(catalogType, selectedDiet.eight![0]),
        ]

        break
      case getMidMorningTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.twelve = [
          updateNutrition(catalogType, selectedDiet.twelve![0]),
        ]

        break
      case getLunchTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.two = [updateNutrition(catalogType, selectedDiet.two![0])]
        break
      case getSnackTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.five = [
          updateNutrition(catalogType, selectedDiet.five![0]),
        ]
        break
      case getDinnerTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.seven = [
          updateNutrition(catalogType, selectedDiet.seven![0]),
        ]
        break
      case getBedTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.nine = [
          updateNutrition(catalogType, selectedDiet.nine![0]),
        ]
        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet

    if (nextSelected.repeat) {
      newDietChart[dietDate + 1].six = updateNutritionForRepeat(
        selectedDiet.six ?? [],
        nextSelected.date
      )

      newDietChart[dietDate + 1].eight = updateNutritionForRepeat(
        selectedDiet.eight ?? [],
        nextSelected.date
      )
      newDietChart[dietDate + 1].twelve = updateNutritionForRepeat(
        selectedDiet.twelve ?? [],
        nextSelected.date
      )
      newDietChart[dietDate + 1].two = updateNutritionForRepeat(
        selectedDiet.two ?? [],
        nextSelected.date
      )
      newDietChart[dietDate + 1].five = updateNutritionForRepeat(
        selectedDiet.five ?? [],
        nextSelected.date
      )

      newDietChart[dietDate + 1].seven = updateNutritionForRepeat(
        selectedDiet.seven ?? [],
        nextSelected.date
      )
    }
    console.log(newDietChart)
    setDietChart(newDietChart)
    setFormState(_.random(0, 109090).toFixed(2))
    if (onDietAdd) onDietAdd(newDietChart, startDateVal, endDateVal)
  }
  const [loading, setLoading] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [addLoading, setAddLoading] = useState<boolean>(false)
  const [addStatus, setAddStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function getDietPlanDataFunction(
    encounterId: string,
    practitionerRoleIdData: string,
    patientId: string,
    startDateData: string,
    endDateData?: string
  ) {
    setUpdateStatus({ requesting: true })
    // setDietChart([])
    setBeforeChartDiet([])
    setLoading(true)

    const finalDate = getTotalDiet(masterData, dietChart)

    setMasterData(finalDate)

    getDietPlanData(
      dietTimeSearchSlice.prSelectedCoding ?? [],
      startDateData,
      patientId,
      encounterId,
      practitionerRoleIdData,
      true,
      endDateData,
      finalDate
    )
      .then((e: Diet[]) => {
        setLoading(false)
        if (e) {
          setDietChart(getActualDiet(e, startDateData, endDateData))
          console.log(e)
          setBeforeChartDiet(getActualDiet(e, startDateData, endDateData))

          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching reports. Please try again later'
            )
          )
        }
      })
      .catch((e: any) => {
        console.log(e)
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function addDietPlan(
    diet: Diet,
    encounterId: string,
    practitionerRoleIdData: string,
    patientId: string,
    startDateData: string,
    endDateData?: string
  ) {
    setAddStatus({ requesting: true })
    setDietChart([])

    setAddLoading(true)

    addDietDataForCustom(diet, patientId, encounterId)
      .then((e: boolean) => {
        setAddLoading(false)
        if (e) {
          getDietPlanDataFunction(
            encounterID ?? '',
            practitionerRoleId ?? '',
            patientID,
            startDateData,
            endDateData
          )
          setAddStatus({
            requesting: false,
            requestSuccess: true,
          })

          dispatch(showSuccessAlert('Diet added successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setAddStatus({
            requesting: false,
            requestSuccess: true,
          })
          setAddLoading(false)
          dispatch(
            showErrorAlert('Error while adding diet. Please try again later')
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setAddStatus({
          requesting: false,
          requestSuccess: true,
        })
        setAddLoading(false)
      })
  }

  function updateCurrentSelectionForRepeat(
    date: string,
    event: any,
    timeIndex: number
  ) {
    const selectedDiet: Diet = { ...dietChart[timeIndex - 1] }
    const selectedDiet1: Diet = { ...dietChart[timeIndex] }

    const newDietChart = [...dietChart]
    const oldDietChart = [...beforeDietChart]
    newDietChart[timeIndex] = selectedDiet1
    newDietChart[timeIndex].date = date

    if (event.target.checked === false) {
      newDietChart[timeIndex] = oldDietChart[timeIndex]
      setDietChart(newDietChart)
    } else {
      newDietChart[timeIndex].repeat = event.target.checked
      newDietChart[timeIndex].id = `${date}id`

      if (selectedDiet.six) {
        if (isDietCompleted(selectedDiet1.six ?? []) === false) {
          newDietChart[timeIndex].six = updateNutritionForRepeat(
            selectedDiet.six ?? [],
            date
          )
        }
      }
      if (selectedDiet.eight) {
        if (isDietCompleted(selectedDiet1.eight ?? []) === false) {
          newDietChart[timeIndex].eight = updateNutritionForRepeat(
            selectedDiet.eight ?? [],
            date
          )
        }
      }

      if (selectedDiet.twelve) {
        if (isDietCompleted(selectedDiet1.twelve ?? []) === false) {
          newDietChart[timeIndex].twelve = updateNutritionForRepeat(
            selectedDiet.twelve ?? [],
            date
          )
        }
      }
      if (selectedDiet.two) {
        if (isDietCompleted(selectedDiet1.two ?? []) === false) {
          newDietChart[timeIndex].two = updateNutritionForRepeat(
            selectedDiet.two ?? [],
            date
          )
        }
      }
      if (selectedDiet.five) {
        if (isDietCompleted(selectedDiet1.five ?? []) === false) {
          newDietChart[timeIndex].five = updateNutritionForRepeat(
            selectedDiet.five ?? [],
            date
          )
        }
      }
      if (selectedDiet.seven) {
        if (isDietCompleted(selectedDiet1.seven ?? []) === false) {
          newDietChart[timeIndex].seven = updateNutritionForRepeat(
            selectedDiet.seven ?? [],
            date
          )
        }
      }
      if (selectedDiet.nine) {
        if (isDietCompleted(selectedDiet1.nine ?? []) === false) {
          newDietChart[timeIndex].nine = updateNutritionForRepeat(
            selectedDiet.nine ?? [],
            date
          )
        }
      }
      setDietChart(newDietChart)
    }
    console.log(newDietChart)
    if (onDietAdd) onDietAdd(newDietChart, startDateVal, endDateVal)
  }

  function handleDateChange(date: any) {
    setStartDateVal(date.target.value)
    setEndDateVal(moment(date.target.value).add(6, 'days').format('YYYY-MM-DD'))

    getDietPlanDataFunction(
      encounterID ?? '',
      practitionerRoleId ?? '',

      patientID,
      date.target.value,
      moment(date.target.value).add(6, 'days').format('YYYY-MM-DD')
    )
  }

  function handleEndDateChange(date: any) {
    setEndDateVal(date.target.value)
    getDietPlanDataFunction(
      encounterID ?? '',
      practitionerRoleId ?? '',
      patientID,
      startDateVal,
      date.target.value
    )
  }

  function updateNutritionCompletionStatus(
    no: R4.INutritionOrder[],
    dietDate: number,
    timeIndex: string
  ) {
    console.log(no)
    const selectedDiet: Diet = { ...dietChart[dietDate] }
    switch (timeIndex) {
      case getEarlyMorningTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.six = no

        break
      case getEarlyBreakFastTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.eight = no

        break
      case getMidMorningTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.twelve = no
        break
      case getLunchTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.two = no

        break
      case getSnackTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.five = no

        break
      case getDinnerTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.seven = no

        break
      case getBedTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.nine = no
        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet

    setDietChart(newDietChart)
  }

  const [dietChart, setDietChart] = useState<Diet[]>([])
  const [beforeDietChart, setBeforeChartDiet] = useState<Diet[]>([])
  const [masterData, setMasterData] = useState<Diet[]>([])

  function updateNutrition(
    catalogType: R4.ICatalogEntry[],
    nutritionResource: R4.INutritionOrder
  ) {
    let nutritionOrder: R4.INutritionOrder = { ...nutritionResource }

    nutritionOrder.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndNutritionOrderNutritionProduct',
      ],
    }

    const extList: R4.IExtension[] = []

    if (catalogType.length > 0) {
      catalogType.forEach((e) => {
        extList.push({
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext',
          valueReference: {
            reference: `CatalogEntry/${e.id}`,
            display: getCatalogName(e),
          },
        })
      })

      nutritionOrder = {
        ...nutritionOrder,
        oralDiet: {
          ...nutritionOrder.oralDiet,
          extension: [
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext',
              extension: extList,
            },
          ],
        },
      }
    } else {
      nutritionOrder = {
        ...nutritionOrder,
        oralDiet: {
          ...nutritionOrder.oralDiet,
          extension: [],
        },
      }
    }

    return nutritionOrder
  }

  function updateNutritionForRepeat(
    nutritionResource: R4.INutritionOrder[],
    date: string
  ): R4.INutritionOrder[] {
    const resource: R4.INutritionOrder[] = []
    for (let i = 0; i < nutritionResource.length; i++) {
      let nutritionOrder: R4.INutritionOrder = nutritionResource[i]

      const oralDietData: R4.INutritionOrder_OralDiet | undefined =
        nutritionOrder.oralDiet

      if (oralDietData) {
        const oralSchedule: R4.ITiming[] = oralDietData.schedule ?? []
        if (oralSchedule) {
          nutritionOrder = {
            ...nutritionOrder,
            id: getUniqueTempId(),
            meta: {
              profile: [
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndNutritionOrderNutritionProduct',
              ],
            },
            extension: [
              {
                url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                valueReference: {
                  reference: `Organization/${
                    getCurrentUserUnitDetails().id ?? ''
                  }`,
                },
              },
            ],
            dateTime: moment(date).toISOString(),
            identifier: [],

            status: 'active',
            oralDiet: {
              extension: oralDietData.extension ?? [],
              schedule: [
                {
                  event: [moment(date).toISOString()],
                  repeat: oralSchedule[0].repeat,
                  code: oralSchedule[0].code,
                },
              ],
            },
          }
        }
      }

      resource.push(nutritionOrder)
    }
    console.log(resource)
    return resource
  }

  function checkForRepeat(diet: Diet): string[] {
    const checkArr: string[] = []

    if (diet.seven) {
      for (let i = 0; i < diet.seven.length; i++) {
        if (diet.seven[i].oralDiet) {
          const { oralDiet } = diet.seven[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.five) {
      for (let i = 0; i < diet.five.length; i++) {
        if (diet.five[i].oralDiet) {
          const { oralDiet } = diet.five[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.twelve) {
      for (let i = 0; i < diet.twelve.length; i++) {
        if (diet.twelve[i].oralDiet) {
          const { oralDiet } = diet.twelve[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.two) {
      for (let i = 0; i < diet.two.length; i++) {
        if (diet.two[i].oralDiet) {
          const { oralDiet } = diet.two[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.eight) {
      for (let i = 0; i < diet.eight.length; i++) {
        if (diet.eight[i].oralDiet) {
          const { oralDiet } = diet.eight[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.six) {
      for (let i = 0; i < diet.six.length; i++) {
        if (diet.six[i].oralDiet) {
          const { oralDiet } = diet.six[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }

    if (diet.nine) {
      for (let i = 0; i < diet.nine.length; i++) {
        if (diet.nine[i].oralDiet) {
          const { oralDiet } = diet.nine[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }

    return checkArr
  }

  function disableRepeat(diet: Diet): string[] {
    console.log(diet)
    const checkArr: string[] = []
    const idArray: string[] = []

    if (diet.seven) {
      for (let i = 0; i < diet.seven.length; i++) {
        const id = diet.seven[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.seven[i].oralDiet) {
          const { oralDiet } = diet.seven[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.five) {
      for (let i = 0; i < diet.five.length; i++) {
        const id = diet.five[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.five[i].oralDiet) {
          const { oralDiet } = diet.five[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.twelve) {
      for (let i = 0; i < diet.twelve.length; i++) {
        const id = diet.twelve[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.twelve[i].oralDiet) {
          const { oralDiet } = diet.twelve[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.two) {
      for (let i = 0; i < diet.two.length; i++) {
        const id = diet.two[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.two[i].oralDiet) {
          const { oralDiet } = diet.two[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.eight) {
      for (let i = 0; i < diet.eight.length; i++) {
        const id = diet.eight[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.eight[i].oralDiet) {
          const { oralDiet } = diet.eight[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.six) {
      for (let i = 0; i < diet.six.length; i++) {
        const id = diet.six[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.six[i].oralDiet) {
          const oralDietData = diet.six[i].oralDiet
          console.log(diet)

          console.log(diet.six)
          console.log(oralDietData)
          if (
            oralDietData &&
            oralDietData.extension &&
            oralDietData.extension.length > 0
          ) {
            idArray.push('1')
          }
        }
      }
    }

    if (diet.nine) {
      for (let i = 0; i < diet.nine.length; i++) {
        const id = diet.nine[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.nine[i].oralDiet) {
          const oralDietData = diet.nine[i].oralDiet
          console.log(diet)

          console.log(diet.nine)
          console.log(oralDietData)
          if (
            oralDietData &&
            oralDietData.extension &&
            oralDietData.extension.length > 0
          ) {
            idArray.push('1')
          }
        }
      }
    }
    if (idArray.length === 0) return idArray
    return idArray
  }

  //   useEffect(() => {
  //     dispatch(
  //       requestDietDetails(
  //         fhirAppointmentDetails.mainEncounter?.id!,
  //         fhirAppointmentDetails.mainServiceRequest,
  //         fhirAppointmentDetails.mainEncounter?.id!,
  //         true
  //       )
  //     )
  //   }, [])

  useEffect(() => {
    getDietPlanDataFunction(
      encounterID ?? '',
      practitionerRoleId ?? '',

      patientID,
      startDateVal,
      endDateVal
    )
    // onDietChanged(
    //   dietChart,
    //   moment(startDate).toDate(),
    //   endDate ? moment(endDate).toDate() : moment().toDate()
    // )
  }, [])

  return (
    <Box
      style={{ paddingLeft: '4px' }}
      display='flex'
      flexDirection='column'
      width='100%'
    >
      <Box display='flex' flexDirection='row' width='100%' paddingTop={1}>
        <Box
          width='15%'
          paddingRight={1}
          paddingLeft={0.2}
          display='flex'
          flexDirection='column'
        >
          <WelloFormItemLabel title='Start Date' />
          <WelloTextFieldWithoutTitle
            textProps={{
              id: `treatment_date`,
              value: startDateVal,
              error: endDateVal ? startDateVal > endDateVal : false,

              inputProps: {
                min: startDateForCustom,
                max: endDateForCustom,
              },
              onKeyDown: (e) => {
                e.preventDefault()
              },

              type: 'date',
              onChange: (changeData) => {
                handleDateChange(changeData)
              },
            }}
          />
        </Box>

        <Box width='15%' px={1} display='flex' flexDirection='column'>
          <WelloFormItemLabel title='End Date' />
          <WelloTextFieldWithoutTitle
            textProps={{
              id: `treatment_end_date`,
              value: endDateVal,

              inputProps: {
                min: startDateVal,
                max: moment(startDateVal).add(6, 'days').format('YYYY-MM-DD'),
              },
              onKeyDown: (e) => {
                e.preventDefault()
              },

              type: 'date',
              onChange: (changeData) => {
                handleEndDateChange(changeData)
              },
            }}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' paddingTop={1}>
        <Box width='100%'>
          <Grid container spacing={2} direction='column'>
            <Grid container direction='column'>
              {addLoading && <LinearProgress />}
            </Grid>
            <Grid item>
              <ReactVisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible) {
                    dispatch(
                      setSelectedSection({ selectedSection: 'diagnosis' })
                    )
                  }
                }}
              >
                <Grid item id='diagnosis'>
                  <Grid item xl style={{ padding: 0, margin: 0 }}>
                    <Grid
                      container
                      direction='row'
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Box
                        width='100%'
                        display='flex'
                        flexDirection='row'
                        paddingTop={1}
                      >
                        <Box
                          width='6%'
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          paddingLeft={2}
                        >
                          <Typography
                            variant='subtitle2'
                            style={{
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            {' '}
                            Date
                          </Typography>
                        </Box>

                        <Box
                          width='12.5%'
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {' '}
                              {getEarlyMorningTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          width='12.5%'
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {' '}
                              {getEarlyBreakFastTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          width='12.5%'
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {' '}
                              {getMidMorningTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          width='12.5%'
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {' '}
                              {getLunchTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          width='12.5%'
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {' '}
                              {getSnackTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          width='12.5%'
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {' '}
                              {getDinnerTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          width='12.5%'
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                            paddingLeft={3}
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {' '}
                              {getBedTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  {loading && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      alignContent='center'
                      alignItems='center'
                      justifyContent='center'
                      justifyItems='center'
                    >
                      {' '}
                      <CircularProgress size={28} />
                    </Box>
                  )}

                  <Grid item xl style={{ padding: 0, margin: 0 }}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      paddingTop={1}
                    >
                      {dietTimeSearchSlice.prSelectedCoding &&
                        dietChart.map((dietRow: Diet, index: number) => (
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                            key={`diet${dietRow.date}`}
                            width='100%'
                          >
                            <Box
                              width='6%'
                              py={0.5}
                              display='flex'
                              flexDirection='column'
                            >
                              <WelloFormItemLabel
                                title={`${moment(dietRow.date).format(
                                  'DD-MM-YYYY'
                                )} ${moment(dietRow.date).format('dddd')}`}
                                displayDoneLabel={false}
                                id={`${index}date`}
                              />
                              {index > 0 && (
                                <Box paddingLeft={0.3}>
                                  {disableRepeat(dietRow).length === 0 && (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          size='small'
                                          color='primary'
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                          }}
                                          disabled={
                                            checkForRepeat(dietChart[index - 1])
                                              .length === 0
                                          }
                                          checked={
                                            dietRow.repeat
                                              ? dietRow.repeat
                                              : false
                                          }
                                          onChange={(eve, checked) => {
                                            updateCurrentSelectionForRepeat(
                                              dietRow.date,
                                              eve,
                                              index
                                            )
                                          }}
                                          id={`repeat${index}`}
                                        />
                                      }
                                      label={
                                        <Typography variant='subtitle2'>
                                          Repeat
                                        </Typography>
                                      }
                                      labelPlacement='end'
                                    />
                                  )}
                                </Box>
                              )}
                            </Box>

                            <Box
                              px={1}
                              width='12.5%'
                              py={0.5}
                              display='flex'
                              justifyContent='flex-start'
                            >
                              <DietSelector
                                dateString={dietRow.date}
                                nutritionOrders={dietRow.six ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                id={index}
                                searchType='MORN.early'
                                timeOfDay={getEarlyMorningTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                // disableSelection={
                                //   isPastDateTimeForDiet(
                                //     moment(dietRow.date).format('DD-MM-YYYY'),
                                //     getEarlyMorningTimeForPullDown(
                                //       dietTimeSearchSlice.prSelectedCoding ?? []
                                //     )
                                //   ) ||
                                //   !isCurrentUserDoctor() ||
                                //   isDietCompleted(dietRow.seven)
                                // }
                                hideChecking={
                                  isFutureDateTimeForDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getEarlyMorningTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                disableSelection={
                                  isPastDateTimeForCustomDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getEarlyMorningForDisable(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isDietCompleted(dietRow.six) ||
                                  addLoading ||
                                  checkDisabledForSix(
                                    dietTimeSearchSlice.prSelectedCoding ?? []
                                  ) ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getEarlyMorningTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                repeat={dietRow.repeat}
                              />
                            </Box>

                            <Box
                              px={1}
                              width='12.5%'
                              py={0.5}
                              display='flex'
                              justifyContent='flex-start'
                            >
                              <DietSelector
                                dateString={dietRow.date}
                                id={index}
                                nutritionOrders={dietRow.eight ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='MORN'
                                timeOfDay={getEarlyBreakFastTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                //   disableCheckingOpt={
                                //     fhirAppointmentDetails.mainServiceRequest
                                //       .status !== 'active'
                                //   }
                                hideChecking={
                                  isFutureDateTimeForDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getEarlyBreakFastTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                disableSelection={
                                  isPastDateTimeForCustomDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getBreakFastForDisable(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isDietCompleted(dietRow.eight) ||
                                  addLoading ||
                                  checkDisabledForBreakFast(
                                    dietTimeSearchSlice.prSelectedCoding ?? []
                                  ) ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  ) ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getEarlyBreakFastTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                repeat={dietRow.repeat}
                              />
                            </Box>
                            <Box
                              px={1}
                              width='12.5%'
                              py={0.5}
                              display='flex'
                              justifyContent='flex-start'
                            >
                              <DietSelector
                                dateString={dietRow.date}
                                id={index}
                                nutritionOrders={dietRow.twelve ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                //   disableCheckingOpt={
                                //     fhirAppointmentDetails.mainServiceRequest
                                //       .status !== 'active'
                                //   }
                                searchType='MORN.late'
                                timeOfDay={getMidMorningTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                hideChecking={
                                  isFutureDateTimeForDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getMidMorningTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                disableSelection={
                                  isPastDateTimeForCustomDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getMidMorningTimeForDisable(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  isDietCompleted(dietRow.twelve) ||
                                  checkDisabledForMidMorning(
                                    dietTimeSearchSlice.prSelectedCoding ?? []
                                  ) ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  ) ||
                                  addLoading ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getMidMorningTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                repeat={dietRow.repeat}
                              />
                            </Box>
                            <Box
                              px={1}
                              width='12.5%'
                              py={0.5}
                              display='flex'
                              justifyContent='flex-start'
                            >
                              <DietSelector
                                dateString={dietRow.date}
                                id={index}
                                nutritionOrders={dietRow.two ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                //   disableCheckingOpt={
                                //     fhirAppointmentDetails.mainServiceRequest
                                //       .status !== 'active'
                                //   }
                                searchType='NOON'
                                timeOfDay={getLunchTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                hideChecking={
                                  isFutureDateTimeForDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getLunchTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                disableSelection={
                                  isPastDateTimeForCustomDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getLunchTimeForDisable(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isDietCompleted(dietRow.two) ||
                                  addLoading ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  ) ||
                                  checkDisabledForLunch(
                                    dietTimeSearchSlice.prSelectedCoding ?? []
                                  ) ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getLunchTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                repeat={dietRow.repeat}
                              />
                            </Box>
                            <Box
                              px={1}
                              width='12.5%'
                              py={0.5}
                              display='flex'
                              justifyContent='flex-start'
                            >
                              <DietSelector
                                dateString={dietRow.date}
                                nutritionOrders={dietRow.five ?? []}
                                id={index}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                //   disableCheckingOpt={
                                //     fhirAppointmentDetails.mainServiceRequest
                                //       .status !== 'active'
                                //   }
                                searchType='EVE'
                                timeOfDay={getSnackTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                hideChecking={
                                  isFutureDateTimeForDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getSnackTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                disableSelection={
                                  isPastDateTimeForCustomDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getSnackTimeForDisable(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isDietCompleted(dietRow.five) ||
                                  addLoading ||
                                  checkDisabledForSnacks(
                                    dietTimeSearchSlice.prSelectedCoding ?? []
                                  ) ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getSnackTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                repeat={dietRow.repeat}
                              />
                            </Box>
                            <Box
                              px={1}
                              width='12.5%'
                              py={0.5}
                              display='flex'
                              justifyContent='flex-start'
                            >
                              <DietSelector
                                dateString={dietRow.date}
                                id={index}
                                nutritionOrders={dietRow.seven ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='NIGHT'
                                timeOfDay={getDinnerTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                //   disableCheckingOpt={
                                //     fhirAppointmentDetails.mainServiceRequest
                                //       .status !== 'active'
                                //   }
                                disableSelection={
                                  isPastDateTimeForCustomDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getDinnerTimeForDisable(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isDietCompleted(dietRow.seven) ||
                                  addLoading ||
                                  checkDisabledForDinner(
                                    dietTimeSearchSlice.prSelectedCoding ?? []
                                  )
                                }
                                hideChecking={
                                  isFutureDateTimeForDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getDinnerTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getDinnerTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                              />
                            </Box>
                            <Box
                              px={1}
                              width='12.5%'
                              py={0.5}
                              display='flex'
                              justifyContent='flex-start'
                              paddingLeft={3}
                            >
                              <DietSelector
                                dateString={dietRow.date}
                                id={index}
                                nutritionOrders={dietRow.nine ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='PCV'
                                timeOfDay={getBedTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                //   disableCheckingOpt={
                                //     fhirAppointmentDetails.mainServiceRequest
                                //       .status !== 'active'
                                //   }
                                disableSelection={
                                  isPastDateTimeForCustomDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getBedTimeForDisable(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isDietCompleted(dietRow.nine) ||
                                  addLoading ||
                                  checkDisabledForBedTime(
                                    dietTimeSearchSlice.prSelectedCoding ?? []
                                  )
                                }
                                hideChecking={
                                  isFutureDateTimeForDiet(
                                    moment(dietRow.date).format('DD-MM-YYYY'),
                                    getBedTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  ) ||
                                  !isCurrentUserDoctor() ||
                                  isPastDateForCustom(
                                    moment(moment(dietRow.date)).format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                }
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getBedTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                              />
                            </Box>
                            {/* <Box py={1} width='10%'>
                              <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                id={`${index}button`}
                                disabled={isPastDateForCustom(
                                  moment(moment(dietRow.date)).format(
                                    'YYYY-MM-DD'
                                  )
                                )}
                                onClick={() => {
                                  addDietPlan(
                                    dietChart[index],
                                    encounterID ?? '',
                                    practitionerRoleId ?? '',
                                    patientID,
                                    startDateVal,
                                    endDateVal
                                  )
                                  // dispatch(
                                  //   addDietDetails(
                                  //     dietChart[index],
                                  //     fhirAppointmentDetails
                                  //   )
                                  // )
                                }}
                              >
                                <Typography variant='subtitle2'>
                                  {isExistingDiet(dietRow) ? 'Update' : 'Add'}
                                </Typography>
                              </Button>
                            </Box> */}
                          </Box>
                        ))}
                    </Box>
                  </Grid>
                </Grid>
              </ReactVisibilitySensor>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}
