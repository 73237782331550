import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  Typography,
} from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  getLastNameOfPatient,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { getTotalAmountOfLabOfferings } from 'utils/fhirResoureHelpers/planDefinitionHelper'

interface Props {
  onOrderSelected?: (selectedOrder: FhirLabOrderDetail[]) => void
  orderList?: FhirLabOrderDetail[]
  id?: string
  valueField?: string
}

export const OrderListView: React.FC<Props> = ({
  onOrderSelected,
  orderList,
  id,
  valueField,
}: Props) => {
  const dispatch = useDispatch()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const { t } = useTranslation()

  function handleChecked(idCheck: string) {
    if (selectedIds.includes(idCheck)) {
      const tempIds: string[] = selectedIds.filter((item) => item !== id)
      updatePractitioners(tempIds)
    } else {
      const tempIds: string[] = [...selectedIds]
      tempIds.push(idCheck)
      updatePractitioners(tempIds)
    }
  }
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  function updatePractitioners(filteredIds: string[]) {
    if (onOrderSelected) {
      const selectedDoctors: FhirLabOrderDetail[] = [
        ...(orderList ?? []),
      ].filter((val) => filteredIds.includes(val.serviceRequest.id ?? ''))
      onOrderSelected(selectedDoctors)
    }
    setSelectedIds(filteredIds)
  }

  const handleChange = (event: any) => {
    if (event.target.checked) {
      const tempIds: string[] = []
      if (orderList) {
        for (let i = 0; i < orderList.length; i++) {
          tempIds.push(orderList[i].serviceRequest.id ?? '')
        }
      }
      updatePractitioners(tempIds)
    } else {
      const tempIds: string[] = []
      updatePractitioners(tempIds)
    }
  }

  return (
    <Box width='100%' paddingTop={1}>
      <Box px={2}>
        <FormControlLabel
          control={
            <Checkbox
              size='small'
              onChange={handleChange}
              name='checkedB'
              color='primary'
            />
          }
          label={t('labelCommon:select_all')}
        />
      </Box>
      <List
        style={{
          padding: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <li style={{ padding: 0, width: '100%' }}>
          {orderList &&
            orderList.map((item: FhirLabOrderDetail, index: any) => (
              <>
                <Box
                  width='100%'
                  borderRadius={1}
                  // onClick={() => {

                  // }}
                >
                  <Card
                    variant='outlined'
                    elevation={index % 2 ? 0 : 1}
                    style={{
                      padding: 0,
                      margin: 0,
                      borderRadius: 0,
                      backgroundColor: index % 2 ? '#FFFFFFAF' : '#FFFFFFAF',
                    }}
                  >
                    <CardContent
                      style={{
                        padding: 8,
                        paddingLeft: 16,
                        paddingRight: 16,
                        border: 0,
                      }}
                    >
                      <Grid container>
                        <Grid item xs={1}>
                          <Checkbox
                            edge='start'
                            checked={
                              selectedIds.indexOf(
                                item.serviceRequest.id ?? ''
                              ) !== -1
                            }
                            size='small'
                            disableRipple
                            color='primary'
                            onClick={() => {
                              if (
                                selectedIds.includes(
                                  item.serviceRequest.id ?? ''
                                )
                              ) {
                                const tempIds: string[] = selectedIds.filter(
                                  (newItem) =>
                                    newItem !== item.serviceRequest.id ?? ''
                                )
                                updatePractitioners(tempIds)
                              } else {
                                const tempIds: string[] = [...selectedIds]
                                tempIds.push(item.serviceRequest.id ?? '')
                                updatePractitioners(tempIds)
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            paddingTop={1}
                          >
                            <Box display='flex'>
                              <Typography variant='subtitle2' color='initial'>
                                Order #{item.serviceRequest.id}
                              </Typography>
                            </Box>
                            <Box
                              display='flex'
                              justifyContent='flex-start'
                              flexGrow={1}
                            >
                              <Typography variant='body2' color='primary'>
                                {`${getNameOfPatient(
                                  item.patient
                                )} ${getLastNameOfPatient(item.patient)}`}
                              </Typography>
                            </Box>
                            <Box display='flex' justifyContent='flex-start'>
                              <Typography variant='body2' color='initial'>
                                {item.tests
                                  ?.map(
                                    (e) =>
                                      e?.planDefinition.title ??
                                      e?.planDefinition.name
                                  )
                                  .join(',')}
                              </Typography>
                            </Box>

                            <Box
                              display='flex'
                              flexDirection='column'
                              justifyContent='center'
                              width='70%'
                            >
                              <Typography variant='body2' color='primary'>
                                Collect {item.tests?.length}/
                                {item.tests?.length} samples
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs>
                          <Grid container justifyContent='flex-end'>
                            <Box
                              display='flex'
                              flexDirection='column'
                              justifyContent='flex-end'
                            >
                              <Box>
                                <Typography
                                  variant='body2'
                                  color='textSecondary'
                                >
                                  Pending Balance
                                </Typography>
                                {item.tests && item.tests.length > 0 && (
                                  <Box
                                    justifyContent='flex-end'
                                    paddingLeft={6}
                                  >
                                    <Typography
                                      variant='body2'
                                      color='textSecondary'
                                    >
                                      &#x20b9;{' '}
                                      {getTotalAmountOfLabOfferings(
                                        item.tests ?? []
                                      )}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </>
            ))}
        </li>
      </List>
    </Box>
  )
}
