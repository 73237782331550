/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ObservationStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  TextField,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloOccupation } from 'models/WelloConditions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  addOccupationOPD,
  resetOccupationDetailsAdd,
} from 'redux/fhirMedicalResources/opd/addOccupation/occupationAddSlice'
import { RootState } from 'redux/rootReducer'
import { occupations, range } from 'utils/constants'
import { getColorBasedOnNumber } from 'utils/fhirResourcesHelper'
import { updateObsForEdit } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { createBundleObjectForObservations } from 'utils/fhirResoureHelpers/observationHelpers'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloLoadingIndicator } from 'wello-web-components'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onAddictionAdded: () => void
  existingOccupation: WelloOccupation
}
export const EditOccupationOPD: React.FC<Props> = ({
  open,
  onClose,
  onAddictionAdded,
  fhirAppointmentDetails,
  existingOccupation,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '40%',
    },
  }))
  const classes = useStyles()
  const [occupation, setOccupation] = useState<R4.ICoding[]>([])

  const [currentCondition, setCurrentCondition] = useState<WelloOccupation>({
    id: existingOccupation.id,
    condition: existingOccupation.condition,
    existingObs: existingOccupation.existingObs,
    mentalHealth: existingOccupation.mentalHealth,
    phyHealth: existingOccupation.phyHealth,
  })
  const [physicalVal, setPhysicalVal] = React.useState<number>(0)

  const handleChange = (event: any, newValue: number | number[]) => {
    setCurrentCondition({
      ...currentCondition,
      mentalHealth: newValue as number,
    })
  }

  const [addedConditions, setAddedConditions] = useState<WelloOccupation[]>([])

  const handleChangeForPhysical = (event: any, newValue: number | number[]) => {
    setCurrentCondition({
      ...currentCondition,
      phyHealth: newValue as number,
    })
    setPhysicalVal(newValue as number)
  }

  //   function valuetext(value: number) {
  //     return `${value}°C`
  //   }

  const [text, setText] = useState<string>('')

  const dispatch = useDispatch()

  function resetDetails() {
    setCurrentCondition({
      id: _.random(1, 10000000).toString(),
      condition: undefined,
      phyHealth: 0,
      mentalHealth: 0,
    })
  }

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  function updateObsFinal() {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IObservation = {
      ...currentCondition.existingObs!,
    }

    const newObs: R4.IObservation = createBundleObjectForObservations(
      fhirAppointmentDetails,
      currentCondition
    )
    setLoading(true)
    oldProcedure.status = ObservationStatusKind._enteredInError

    updateObsForEdit(
      oldProcedure,
      newObs,
      fhirAppointmentDetails.appointment.id!
    ).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Occupation details updated successfully'))
        onAddictionAdded()
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Occupation details. Please try again later'
          )
        )
      }
    })
  }

  const occupationalAddSlice = useSelector(
    (state: RootState) => state.occupationalAddSliceOPD
  )

  useEffect(() => {
    setCurrentCondition({
      id: existingOccupation.id,
      condition: existingOccupation.condition,
      existingObs: existingOccupation.existingObs,
      mentalHealth: existingOccupation.mentalHealth,
      phyHealth: existingOccupation.phyHealth,
    })
  }, [existingOccupation])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()

        setAddedConditions([])
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        Edit Occupational Details
      </DialogTitle>
      <DialogContent dividers={true}>
        {occupationalAddSlice.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!occupationalAddSlice.adding && (
          <Box
            marginX={2}
            marginY={1}
            id={currentCondition.id}
            display='flex'
            flexDirection='column'
          >
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    id={currentCondition.id}
                  >
                    <ValueSetSelectSingle
                      key='occupation_Search'
                      title='Select Occupation'
                      url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-occupation-vs'
                      id='occupation_search'
                      fuzzySearch={true}
                      alwaysShowAllOptions={true}
                      disabled={false}
                      onOptionSelected={(selectedKriya) => {
                        // setOccupation(changedValue)
                        if (selectedKriya) {
                          setCurrentCondition({
                            ...currentCondition,
                            condition: selectedKriya,
                          })
                        }
                      }}
                      helperText={undefined}
                      placeHolder='Search Occupation'
                      preSelectedOptions={currentCondition.condition}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Mental stress level' />
                  </Grid>
                  <Box width='100%' px={1}>
                    <Slider
                      defaultValue={10}
                      step={10}
                      id='mental_stress'
                      marks={range}
                      style={getColorBasedOnNumber(
                        10,
                        100,
                        currentCondition.mentalHealth
                      )}
                      value={currentCondition.mentalHealth}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Physical stress level' />
                  </Grid>
                  <Box width='100%' px={1}>
                    <Slider
                      defaultValue={10}
                      step={10}
                      id='physical_stress'
                      marks={range}
                      style={getColorBasedOnNumber(
                        10,
                        100,
                        currentCondition.phyHealth
                      )}
                      value={currentCondition.phyHealth}
                      onChange={handleChangeForPhysical}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Additional Notes' />
                  </Grid>
                  <Grid item style={{ paddingBottom: '16px' }}>
                    <TextField
                      size='small'
                      fullWidth
                      multiline
                      id='occ_6'
                      type='number'
                      value={currentCondition.notes}
                      variant='outlined'
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,
                          notes: event.target.value,
                        })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              onClose()
              setOccupation([])

              setAddedConditions([])
            }}
            variant='outlined'
            disabled={occupationalAddSlice.adding}
            disableElevation
            id='occ_cancel'
          >
            {t('labelCommon:cancel')}
          </Button>

          <Button
            onClick={() => {
              updateObsFinal()
            }}
            variant='contained'
            color='primary'
            id='occ_add'
            disableElevation
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
