import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Test } from 'models/Test'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetObservationState,
  searchObservations,
} from 'redux/lab/observationFinder/observationFinderSlice'
import {
  resetPlanDefState,
  searchTestNames,
} from 'redux/lab/plaDefSearch/plandefSearchSlice'
import {
  creatingCatalog,
  resetPackageState,
} from 'redux/lab/Test/addTestCatalougeSlice'
import { RootState } from 'redux/rootReducer'
import { LabCodeValueSet } from 'utils/constants/lab_test'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { displayData, getCatalogBundle } from 'utils/labHelpers/uploadHelper'
import { logger } from 'utils/logger'
import { WelloFormItemLabel, WelloTextField } from 'wello-web-components'
import { MasterDataSelecter } from '../lab/catalougeSetup/masterDataSelector'

interface Props {
  open: boolean
  onClose: () => void
  onDiscard: () => void
  locationId: string
  errors: boolean
}

export const AddPartnerLabTest: React.FC<Props> = ({
  open,
  onClose,
  onDiscard,
  locationId,
  errors,
}) => {
  const { t } = useTranslation()

  const [hasErrors, setHasErrors] = React.useState<boolean>(errors)
  const dispatch = useDispatch()
  const selectedTestName = useRef<string>()
  const [showDisplay, setShowDisplay] = React.useState<boolean>(false)
  const [selectedLabOfferings, setSelectedLabOfferings] =
    React.useState<R4.IPlanDefinition>()
  const creatCatalogSlice = useSelector(
    (state: RootState) => state.createTestSlice
  )

  const observtionSearchSlice = useSelector(
    (state: RootState) => state.observtionSearchSlice
  )

  const planDefSearchSlice = useSelector(
    (state: RootState) => state.plandefSearchSlice
  )

  const display = displayData(LabCodeValueSet)

  const [tests, setTests] = React.useState<Test>({
    id: getUniqueTempId(),
    testName: '',
    testPrice: 0,
    scheduleSummary: '',
    description: '',
    presetInstructions: '',
    reportDisplayName: '',
    code: [],
  })

  function handleNameChange(planDef: R4.IPlanDefinition) {
    const values: Test = tests
    values.testName = planDef.title ?? ''
    dispatch(searchObservations(planDef))
    const codesData: string[] = []
    setSelectedLabOfferings(planDef)

    selectedTestName.current = planDef.title ?? ''
    if (planDef.action) {
      for (let i = 0; i < planDef.action.length; i++) {
        const actionData = planDef.action[i].action ?? []
        for (let j = 0; j < actionData.length; j++) {
          const codes =
            actionData[i].definitionCanonical?.split(
              'http://wellopathy.com/ActivityDefinition/'
            )[1] ?? ''
          if (codes.length > 0) codesData.push(codes)
        }
      }
    }
    dispatch(searchTestNames(codesData[0]))
    setTests({ ...tests, testName: planDef.title ?? '' })
    setTests({ ...tests, code: codesData })
    setShowDisplay(false)
  }

  //   function handleNameChange(event: any) {
  //     setTests({ ...tests, testName: event.display })
  //     setShowDisplay(true)
  //   }

  function handlePriceChange(price: number) {
    setTests({ ...tests, testPrice: price })
  }

  function handleChangeInstructions(splIns: string) {
    setTests({ ...tests, presetInstructions: splIns })
  }

  function handleDescriptionChange(description: string) {
    setTests({ ...tests, description })
  }

  function handleScheduleSummaryChange(summary: string) {
    setTests({ ...tests, scheduleSummary: summary })
  }

  function handleReportNameChange(reportName: string) {
    setTests({ ...tests, reportDisplayName: reportName })
  }

  function handleSubmit() {
    // setTests({ ...tests, testName: selectedTestName.current ?? '' })
    let hasErrorsInData: boolean = false
    let hasPriceErrorInData: boolean = false
    let hasSummaryErrorData: boolean = false
    let reportErrorData: boolean = false

    if (tests.scheduleSummary.length === 0 || !tests.scheduleSummary.trim()) {
      hasSummaryErrorData = true
    } else {
      hasSummaryErrorData = false
    }
    if (tests.reportDisplayName.length === 0) {
      reportErrorData = true
    } else {
      reportErrorData = false
    }
    if (tests.testName.length === 0) {
      hasErrorsInData = true
    } else {
      hasErrorsInData = false
    }
    if (
      tests.testPrice <= 0 ||
      tests.testPrice > 7000 ||
      Number.isNaN(tests.testPrice)
    ) {
      hasPriceErrorInData = true
    } else {
      hasPriceErrorInData = false
    }

    if (
      !hasErrorsInData &&
      !hasPriceErrorInData &&
      !hasSummaryErrorData &&
      !reportErrorData
    ) {
      const bundleData = getCatalogBundle(
        tests,
        LabCodeValueSet,
        locationId,
        [],
        selectedTestName.current ?? ''
      )

      logger.info(bundleData)
      dispatch(creatingCatalog(bundleData, locationId, ''))
      onClose()
    }
    // if (hasErrorsInData) setHasErrors(hasErrorsInData)
    // else if (hasPriceErrorInData) setHasErrors(hasPriceErrorInData)
    // else if (hasSummaryErrorData) setHasErrors(hasSummaryErrorData)
    // else if (reportErrorData) setHasErrors(reportErrorData)
  }

  function resetDetails() {
    setHasErrors(false)
    setTests({
      id: getUniqueTempId(),
      testName: '',
      testPrice: 0,
      scheduleSummary: '',
      description: '',
      presetInstructions: '',
      reportDisplayName: '',
      code: [],
    })
  }

  useEffect(() => {
    if (creatCatalogSlice.additionSuccessful) {
      onClose()
      dispatch(resetPackageState())

      resetDetails()
    }
    return () => {}
  }, [creatCatalogSlice.additionSuccessful, onClose, dispatch])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()

        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'> Add Partner Lab Test </DialogTitle>
      <DialogContent>
        <Box flexDirection='column' display='flex'>
          <Box display='flex' flexDirection='column' px={3}>
            {planDefSearchSlice.searching && (
              <Box>
                <CircularProgress color='inherit' size={20} />
              </Box>
            )}
            {planDefSearchSlice.error && <Box>Something went Wrong</Box>}
            {planDefSearchSlice.resultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                p={1.5}
                width='100%'
              >
                <Box py={0.5}>
                  {' '}
                  <Typography variant='subtitle2' color='error'>
                    Test Name : {selectedTestName.current} already exist
                  </Typography>
                </Box>
              </Box>
            )}
            {creatCatalogSlice.error && (
              <Box display='flex'>
                <Alert severity='error'>
                  {creatCatalogSlice.errorMessage ??
                    'Error while sending invitations. Please try later'}
                </Alert>
              </Box>
            )}
            {creatCatalogSlice.additionSuccessful && (
              <Box display='flex'>
                <Alert severity='success'>
                  {creatCatalogSlice.errorMessage ??
                    'Invitation sent Successfully'}
                </Alert>
              </Box>
            )}
            <Box display='flex' flexDirection='row' alignItems='flex-start'>
              <Box
                display='flex'
                flexDirection='column'
                width='30%'
                paddingTop={1.6}
                px={1.5}
              >
                <WelloFormItemLabel title='Select Test Name' />
                <Box>
                  <MasterDataSelecter
                    onSelectionChanges={(e: R4.IPlanDefinition) => {
                      dispatch(resetObservationState)
                      dispatch(resetPlanDefState)
                      handleNameChange(e)
                    }}
                    disabled={false}
                  />
                </Box>
                {showDisplay && !selectedLabOfferings && (
                  <Box justifyContent='center' px={2}>
                    <Typography
                      color='error'
                      style={{
                        fontSize: 12,
                      }}
                    >
                      Test Name is required
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box p={1.5} width='25%'>
                <Box display='flex' flexDirection='row' alignItems='flex-start'>
                  <Box flexDirection='column'>
                    <Box py={1.1}>
                      <Typography variant='subtitle2' align='center'>
                        {' '}
                        PRICE
                      </Typography>
                    </Box>
                    <Box
                      borderRadius='15%'
                      style={{ height: 35, width: 38 }}
                      bgcolor='#F1F1F1'
                      justifyContent='center'
                      alignItems='center'
                      paddingTop={1}
                    >
                      <Typography variant='subtitle2' align='center'>
                        {' '}
                        INR
                      </Typography>
                    </Box>
                  </Box>
                  <Box py={2.1}>
                    <WelloTextField
                      title={t('')}
                      textProps={{
                        id: `actor_email_unit`,
                        inputProps: {
                          max: 7000,
                        },
                        value: tests.testPrice,
                        placeholder: 'Price',
                        error:
                          tests.testPrice <= 0 ||
                          tests.testPrice > 7000 ||
                          Number.isNaN(tests.testPrice),
                        type: 'number',
                        helperText:
                          tests.testPrice <= 0 ||
                          tests.testPrice > 7000 ||
                          Number.isNaN(tests.testPrice)
                            ? 'Price between 1 to 7000'
                            : '',
                        //   value: tests.testPrice,
                        onChange: (changePrice) => {
                          handlePriceChange(
                            parseInt(changePrice.target.value, 10)
                          )
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box p={1.5} width='30%'>
                <WelloTextField
                  title={t('SCHEDULE SUMMARY')}
                  textProps={{
                    id: `test_scheduleSummary${t}`,
                    placeholder: 'Schedule Summary',
                    error:
                      (hasErrors && tests.scheduleSummary.length === 0) ||
                      !tests.scheduleSummary.trim(),
                    type: 'text',
                    value: tests.scheduleSummary,
                    helperText:
                      (hasErrors && tests.scheduleSummary.length === 0) ||
                      !tests.scheduleSummary.trim()
                        ? 'Schedule Summary is required'
                        : '',
                    //   value: tests.testPrice,
                    onChange: (changeSummary) => {
                      handleScheduleSummaryChange(changeSummary.target.value)
                    },
                  }}
                />
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' alignItems='flex-start'>
              <Box p={1.5} width='42%'>
                <WelloTextField
                  title={t('labelCommon:test_add_description_label')}
                  textProps={{
                    id: `testDescription${t}`,
                    value: tests.description,
                    placeholder: 'Description',
                    type: 'text',
                    rows: 4,
                    rowsMax: 6,
                    multiline: true,
                    inputProps: {
                      maxLength: 310,
                    },
                    onChange: (changeDescription) => {
                      handleDescriptionChange(changeDescription.target.value)
                    },
                  }}
                />
              </Box>
              <Box p={1.5} width='43%'>
                <WelloTextField
                  title={t('labelCommon:test_restrictions')}
                  textProps={{
                    id: `splInstructions${t}`,
                    type: 'text',
                    placeholder: 'Pretest Instructions',
                    rows: 4,
                    rowsMax: 6,
                    multiline: true,
                    value: tests.presetInstructions,
                    error: hasErrors && tests.presetInstructions.length > 250,
                    helperText:
                      hasErrors && tests.presetInstructions.length > 250
                        ? 'Pretest Instructions should be less than 250 chars'
                        : '',
                    onChange: (changeInstructions) => {
                      handleChangeInstructions(changeInstructions.target.value)
                    },
                  }}
                />
              </Box>
            </Box>
            <Box p={1.5} width='42%'>
              <WelloTextField
                title={t('REPORT DISPLAY NAME')}
                textProps={{
                  id: `test_reportDisplayName${t}`,
                  placeholder: 'Report Display Name',
                  value: tests.reportDisplayName,
                  error:
                    (hasErrors && tests.reportDisplayName.length === 0) ||
                    !tests.reportDisplayName.trim(),
                  type: 'text',
                  helperText:
                    (hasErrors && tests.reportDisplayName.length === 0) ||
                    !tests.reportDisplayName.trim()
                      ? 'Report Display Name is required'
                      : '',
                  //   value: tests.testPrice,
                  onChange: (changeSummary) => {
                    handleReportNameChange(changeSummary.target.value)
                  },
                }}
              />
            </Box>
            {observtionSearchSlice.searching && (
              <Box>
                <CircularProgress color='inherit' size={20} />
              </Box>
            )}
            {observtionSearchSlice.noResultsAvailable && (
              <Box>No Parameters Available</Box>
            )}

            {observtionSearchSlice.error && <Box>Something went Wrong</Box>}
            {observtionSearchSlice.resultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                p={1.5}
                width='100%'
              >
                <Box py={0.5}>
                  {' '}
                  <Typography variant='subtitle2' color='textPrimary'>
                    Test Parameters for {tests.testName}
                  </Typography>
                </Box>
                <Typography variant='body1' color='textSecondary'>
                  [{' '}
                  {observtionSearchSlice.observations?.map((e) => e).join(',')}{' '}
                  ]
                </Typography>
              </Box>
            )}
          </Box>

          <Divider style={{ border: 1 }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          {creatCatalogSlice.adding && <CircularProgress />}
          <Button
            //   color='primary'
            onClick={() => {
              setHasErrors(false)
              onClose()
            }}
            variant='outlined'
            disabled={
              creatCatalogSlice.adding && planDefSearchSlice.noResultsAvailable
            }
            disableElevation
            size='small'
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            size='small'
            color='primary'
            disabled={planDefSearchSlice.resultsAvailable}
            onClick={() => {
              handleSubmit()
            }}
          >
            Add Test
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
