/* eslint-disable react/no-array-index-key */
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestAddictionsHistoryOfPatient } from 'redux/patientMedicalHistory/addictionsHistory/addictionsHistorySlice'
import { requestHabitsHistoryOfPatient } from 'redux/patientMedicalHistory//habitsHistory/habitsHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import {
  getMentalHealth,
  getObservationCodeText,
  getObservationContent,
  getPhyicalHealth,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  HistoryObjectiveIndicative,
  SOAPIndicativeElement,
} from 'wello-web-components'
import { getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { requestOccupationHistoryOfPatient } from 'redux/fhirMedicalResources/ipd/occupation/occupationSearchSlice'
import moment from 'moment'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import {
  getCodeOfSystemCodings,
  getColorBasedOnNumber,
} from 'utils/fhirResourcesHelper'
import { GroupedOccupation } from 'models/groupedOccupations'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateObs } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { WelloOccupation } from 'models/WelloConditions'
import _ from 'lodash'
import { AddoccupationOPD } from './occupation/AddoccupationOPD'
import { StatementOccupationTableTile } from './statementOccupationTabular'
import { EditOccupationOPD } from '../../edition/editOccupationOPD'

interface HabitsProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  status: string
  splitView: boolean
}

export const OccupationalsOPD: React.FC<HabitsProp> = ({
  fhirAppointmentDetails,
  status,
  splitView,
}: HabitsProp) => {
  const { t } = useTranslation(['common'])
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)

  const dispatch = useDispatch()
  const occupationSearchSlice = useSelector(
    (state: RootState) => state.occupationSearchSlice
  )
  const [groupVitals, setGroupVitals] = useState<GroupedOccupation[]>([])
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const [currentCondition, setCurrentCondition] = useState<WelloOccupation>({
    id: _.random(1, 10000000).toString(),
    phyHealth: 0,
    mentalHealth: 0,
  })

  function acceptOccupation(procedure: R4.IObservation) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IObservation = {
      ...procedure,
    }
    setLoading(true)
    oldProcedure.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy--status-ext',
        valueCodeableConcept: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
              code: 'confirmed',
              display: 'Confirmed',
            },
          ],
          text: 'Confirmed',
        },
      },
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${fhirAppointmentDetails.appointment.id!}`,
        },
      },
    ]
    updateObs(oldProcedure, fhirAppointmentDetails.appointment.id!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Occupation details updated successfully'))
          dispatch(
            requestOccupationHistoryOfPatient(
              fhirAppointmentDetails.appointment.id ?? '',
              fhirAppointmentDetails.patient,
              'OPD'
            )
          )
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Occupation details. Please try again later'
            )
          )
        }
      }
    )
  }

  function rejectOccupation(procedure: R4.IObservation) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IObservation = {
      ...procedure,
    }
    setLoading(true)
    oldProcedure.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-verification-status-ext',
        valueCodeableConcept: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
              code: 'refuted',
              display: 'Refuted',
            },
          ],
          text: 'Refuted',
        },
      },
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${fhirAppointmentDetails.appointment.id!}`,
        },
      },
    ]
    updateObs(oldProcedure, fhirAppointmentDetails.appointment.id!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Occupation details updated successfully'))
          dispatch(
            requestOccupationHistoryOfPatient(
              fhirAppointmentDetails.appointment.id ?? '',
              fhirAppointmentDetails.patient,
              'OPD'
            )
          )
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Procedure details. Please try again later'
            )
          )
        }
      }
    )
  }

  const [showEdit, setShowEdit] = useState<boolean>(false)

  function createUpdateConditionData(conditionData: R4.IObservation) {
    setCurrentCondition({
      ...currentCondition,
      condition: conditionData.valueCodeableConcept
        ? conditionData.valueCodeableConcept.coding &&
          conditionData.valueCodeableConcept.coding!.length > 0
          ? conditionData.valueCodeableConcept.coding[0]
          : undefined
        : undefined,
      mentalHealth: getMentalHealth(conditionData),
      phyHealth: getPhyicalHealth(conditionData),
      existingObs: conditionData,
      notes: getNotesString(conditionData.note),
    })
  }

  useEffect(() => {
    dispatch(
      requestOccupationHistoryOfPatient(
        fhirAppointmentDetails.appointment.id ?? '',
        fhirAppointmentDetails.patient,
        'OPD'
      )
    )
  }, [dispatch, fhirAppointmentDetails])

  useEffect(() => {
    if (
      occupationSearchSlice.resultsAvailable &&
      occupationSearchSlice.groupedOccupationList
    ) {
      updateVitals(occupationSearchSlice.groupedOccupationList)
    }
  }, [occupationSearchSlice])

  function updateVitals(vitalList: GroupedOccupation[]) {
    const results: GroupedOccupation[] = []
    for (let i = 0; i < vitalList.length; i++) {
      results.push({
        date: vitalList[i].date,
        occupation: vitalList[i].occupation,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedOccupation[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Occupational History</Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
      </Box>

      {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
        <Box
          display='flex'
          flexGrow
          flexDirection='row'
          justifyContent='flex-start'
          paddingBottom={1}
        >
          <Tooltip title='' id='occu_1'>
            <IconButton
              aria-label='btn_ord_cancel'
              id='occ_2'
              color='primary'
              onClick={() => {
                setOpenAddDialogue(true)
              }}
              style={{ padding: 0 }}
            >
              <Typography
                variant='subtitle2'
                color='primary'
                component={Link}
                id='occ_4'
                style={{ fontSize: 13 }}
              >
                {' '}
                Occupational History
              </Typography>
              <AddCircleIcon
                id='occ_3'
                style={{ height: '22px', padding: 0 }}
                color='primary'
              />{' '}
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {occupationSearchSlice.searchingConditions && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <CircularProgress size={15} />
        </Box>
      )}

      {occupationSearchSlice.noResultsAvailable && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            {!isMedicalServiceProvider() || status === 'completed' || splitView
              ? 'No data available'
              : ''}{' '}
          </Typography>
        </Box>
      )}
      {occupationSearchSlice.resultsAvailable &&
        occupationSearchSlice.occupationalList && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            {occupationSearchSlice.occupationalList.length === 0 && (
              <Box flexGrow width='100%' display='flex' flexDirection='row'>
                <Typography
                  variant='subtitle2'
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {' '}
                  {!isMedicalServiceProvider()
                    ? 'Add Occupation'
                    : 'Add Occupation'}{' '}
                </Typography>
              </Box>
            )}
          </Box>
        )}

      {occupationSearchSlice.errorWhileSearchingProcedures && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            color='error'
            style={{
              fontWeight: 400,
            }}
          >
            Error while fetching occupations
          </Typography>
        </Box>
      )}
      {occupationSearchSlice.resultsAvailable &&
        occupationSearchSlice.groupedOccupationList && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%' flexGrow>
              {groupVitals.map((val, index: number) => (
                <Box
                  py={1}
                  key={val.date ?? ''}
                  id={`${moment(val.date!).format(
                    'DD-MM-YYYY'
                  )}occupation${index}`}
                >
                  <Box
                    paddingX={1}
                    borderRadius={2}
                    style={{
                      backgroundColor: 'lightGrey',
                    }}
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height={40}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',

                          fontWeight: 'bold',
                        }}
                      >
                        Recorded on{' '}
                        {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                      </Typography>
                    </Box>
                    <Box
                      justifyContent='flex-end'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Box px={1}>
                        <Tooltip title=''>
                          <IconButton
                            aria-label='collapse_order_type'
                            size='small'
                            onClick={() => {
                              handleCollapseForPanel1(!val.checked, index)
                            }}
                          >
                            {val.checked && <ArrowDropUpOutlined />}
                            {!val.checked && <ArrowDropDownOutlined />}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Collapse
                    in={val.checked}
                    style={{
                      width: '100%',
                    }}
                  >
                    <StatementOccupationTableTile
                      occupations={val.occupation}
                      split={splitView}
                      status={status}
                      appointmentId={fhirAppointmentDetails.appointment.id!}
                      onOccupationAccepted={(obs: R4.IObservation) => {
                        acceptOccupation(obs)
                      }}
                      onOccupationRejected={(obs: R4.IObservation) => {
                        rejectOccupation(obs)
                      }}
                      onEditClicked={(obs: R4.IObservation) => {
                        createUpdateConditionData(obs)
                        setShowEdit(true)
                      }}
                      id={`${moment(val.date!).format(
                        'DD-MM-YYYY'
                      )}occupation${index}`}
                    />
                  </Collapse>
                </Box>
              ))}
            </Box>
          </Box>
        )}

      <AddoccupationOPD
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onAddictionAdded={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestOccupationHistoryOfPatient(
              fhirAppointmentDetails.appointment.id ?? '',
              fhirAppointmentDetails.patient,
              'OPD'
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />

      <EditOccupationOPD
        open={showEdit}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onAddictionAdded={() => {
          setShowEdit(false)
          dispatch(
            requestOccupationHistoryOfPatient(
              fhirAppointmentDetails.appointment.id ?? '',
              fhirAppointmentDetails.patient,
              'OPD'
            )
          )
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingOccupation={currentCondition}
      />
    </Box>
  )
}
