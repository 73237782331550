import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import { dayCareData } from 'utils/constants'
import { WelloSelectFHIRCoding } from '../LeftMenu/WelloSelectFHIRCoding'

interface Props {
  open: boolean
  id: number
  onClose: () => void
  onTypeSelected: (type: string) => void
}
export const AppointmentTypeOptions: React.FC<Props> = ({
  open,
  id,
  onClose,
  onTypeSelected,
}) => (
  <Dialog
    open={open}
    onClose={() => {
      onClose()
    }}
    aria-labelledby='responsive-dialog-title'
    maxWidth='xs'
    fullWidth
    disableBackdropClick={true}
  >
    <DialogTitle id='scroll-dialog-title'>OPD Appointment Type</DialogTitle>
    <DialogContent>
      <Box display='flex' flexDirection='row' width='100%' py={2} px={4}>
        <WelloSelectFHIRCoding
          title='Select Appointment Type'
          id={`${id}appointment_type`}
          availableCodings={dayCareData}
          onChanged={(type) => {
            onTypeSelected(type.code ?? '')
          }}
          textFieldProps={{
            size: 'small',
            fullWidth: true,
          }}
        />
      </Box>
    </DialogContent>

    <DialogActions
      style={{ background: useTheme().palette.background.default }}
    >
      <Button
        onClick={() => {
          onClose()
        }}
        variant='outlined'
        disableElevation
      >
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
)
