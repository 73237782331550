import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { GroupedFamilyHistory } from 'models/groupedFamilyHistory'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestFamilyConditionHistoryOfPatient } from 'redux/patientMedicalHistory/familyConditionHistory/familyMedicalConditionsHistorySlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import {
  getNotesFromAllergy,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { getRelationFromFamilyResource } from 'utils/fhirResoureHelpers/observationHelpers'
import { FamilyHistoryTabularTile } from 'views/components/consultation/history/familyHistoryStatementTile'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { SOAPIndicativeElement } from 'wello-web-components'
import { AddMedicalConditionHandler } from '../addition/addMedicalConditions'
import { AddFamilyMedicalCondtionHandler } from '../subjective/addFamilyHistory'

interface FamilyMedicalConditionsProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
  splitView: boolean
}

export const FamilyMedicalConditions: React.FC<FamilyMedicalConditionsProps> =
  ({
    fhirAppointmentDetails,
    status,
    splitView,
  }: FamilyMedicalConditionsProps) => {
    const { t } = useTranslation(['common'])
    const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
    const dispatch = useDispatch()
    const medicalHistorySlice = useSelector(
      (state: RootState) => state.familyMedicalConditionsHistorySlice
    )
    const [groupVitals, setGroupVitals] = useState<GroupedFamilyHistory[]>([])
    useEffect(() => {
      dispatch(
        requestFamilyConditionHistoryOfPatient(
          '',
          fhirAppointmentDetails.patient,
          splitView
        )
      )
    }, [dispatch, fhirAppointmentDetails])

    useEffect(() => {
      if (
        medicalHistorySlice.resultsAvailable &&
        medicalHistorySlice.groupedHistory
      ) {
        updateConditions(medicalHistorySlice.groupedHistory)
      }
    }, [medicalHistorySlice])

    function updateConditions(vitalList: GroupedFamilyHistory[]) {
      const results: GroupedFamilyHistory[] = []
      for (let i = 0; i < vitalList.length; i++) {
        results.push({
          date: vitalList[i].date,
          conditions: vitalList[i].conditions,
          checked: true,
        })
      }
      setGroupVitals(results)
    }

    function handleCollapseForPanel1(rate: boolean, index: number) {
      const values: GroupedFamilyHistory[] = [...groupVitals]
      values[index].checked = rate
      setGroupVitals(values)
    }
    return (
      <>
        <Box display='flex' flexDirection='column' flexGrow width='100%'>
          <Box display='flex' flexDirection='row' flexGrow width='100%'>
            <Box py={1}>
              <Typography variant='subtitle1'>
                {' '}
                {t('labelCommon:family_history')}{' '}
              </Typography>
            </Box>
            {/* {!isUnitAdmin() && status !== 'completed' && !splitView && (
              <IconButton
                style={{ padding: '4px' }}
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
              >
                <Add style={{ height: '14px' }} color='primary' />
              </IconButton>
            )} */}
          </Box>

          {!isUnitAdmin() && status !== 'completed' && !splitView && (
            <Box
              display='flex'
              flexGrow
              flexDirection='row'
              justifyContent='flex-start'
              paddingBottom={1}
            >
              <Tooltip title='' id='fm_1'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  color='primary'
                  id='fm_2'
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                  style={{ padding: 0 }}
                >
                  <Typography
                    variant='subtitle2'
                    color='primary'
                    component={Link}
                    id='f4_1'
                    style={{ fontSize: 13 }}
                  >
                    {' '}
                    {t('labelCommon:family_history')}{' '}
                  </Typography>
                  <AddCircleIcon
                    style={{ height: '22px', padding: 0 }}
                    color='primary'
                    id='fm_3'
                  />{' '}
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {medicalHistorySlice.noResultsAvailable && (
            <Box display='flex' flexDirection='row' flexGrow width='100%'>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {' '}
                {isUnitAdmin() || status === 'completed' || splitView
                  ? 'No data available'
                  : ''}{' '}
              </Typography>
            </Box>
          )}

          {medicalHistorySlice.searchingConditions && (
            <Box flexGrow width='100%' display='flex' flexDirection='row'>
              <CircularProgress size={15} />
            </Box>
          )}
          {medicalHistorySlice.errorWhileSearchingConditions && (
            <Box display='flex' flexDirection='row' flexGrow width='100%'>
              <Typography
                variant='subtitle2'
                color='error'
                style={{
                  fontWeight: 400,
                }}
              >
                Error while fetching famly history conditions
              </Typography>
            </Box>
          )}

          {medicalHistorySlice.resultsAvailable &&
            medicalHistorySlice.groupedHistory && (
              <Box flexGrow width='100%' display='flex' flexDirection='row'>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow
                >
                  {groupVitals.map((val, index: number) => (
                    <Box py={1} key={val.date ?? ''}>
                      <Box
                        paddingX={1}
                        borderRadius={2}
                        style={{
                          backgroundColor: 'lightGrey',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Recorded on{' '}
                            {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                          </Typography>
                        </Box>
                        <Box
                          justifyContent='flex-end'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Box px={1}>
                            <Tooltip title=''>
                              <IconButton
                                aria-label='collapse_order_type'
                                size='small'
                                onClick={() => {
                                  handleCollapseForPanel1(!val.checked, index)
                                }}
                              >
                                {val.checked && <ArrowDropUpOutlined />}
                                {!val.checked && <ArrowDropDownOutlined />}
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                      <Collapse
                        in={val.checked}
                        style={{
                          width: '100%',
                        }}
                      >
                        <FamilyHistoryTabularTile
                          occupations={val.conditions}
                        />
                      </Collapse>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
        </Box>

        <AddFamilyMedicalCondtionHandler
          open={openAddDialogue}
          fhirAppointmentDetails={fhirAppointmentDetails}
          onMedicalConditionAdded={() => {
            setOpenAddDialogue(false)
            dispatch(
              requestFamilyConditionHistoryOfPatient(
                '',
                fhirAppointmentDetails.patient,
                splitView
              )
            )
          }}
          onClose={() => {
            setOpenAddDialogue(false)
          }}
        />

        {/* <AddMedicalConditionHandler
          open={openAddDialogue}
          fhirAppointmentDetails={fhirAppointmentDetails}
          onMedicalConditionAdded={(condition) => {
            setOpenAddDialogue(false)
            dispatch(
              requestConditionHistoryOfPatient(
                fhirAppointmentDetails.appointment.id!,
                fhirAppointmentDetails.patient
              )
            )
          }}
          onClose={() => {
            setOpenAddDialogue(false)
          }}
        /> */}
      </>
    )
  }
