import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Checkbox,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { Conditions } from 'models/conditions'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addFamilyHistory,
  resetAddFamilyHistory,
} from 'redux/fhirMedicalResources/ipd/addFamilyHistory/addFamilyHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  checkBoxConditions,
  checkConditions2,
  reminingConditons,
} from 'utils/constants'
import { titleCase } from 'utils/fhirResourcesHelper'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloLoadingIndicator } from 'wello-web-components'
import { ConditonMasterChosser } from './conditonMasterChosser'
import { FamilyHistoryMasterSelector } from './familyMemberMasterSelector'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onMedicalConditionAdded: () => void
}
export const AddFamilyMedicalCondtionHandler: React.FC<Props> = ({
  open,
  onClose,
  onMedicalConditionAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'condition'])
  const [value, setValue] = React.useState(new Date())
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  )

  const [selectedConditionForCheckBox, setSelectedConditionForCheckBox] =
    useState<Conditions[]>(checkBoxConditions)

  const [
    selectedConditionForCheckBoxForOther,
    setSelectedConditionForCheckBoxForOther,
  ] = useState<Conditions[]>(checkConditions2)

  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [selectedRelationShip, setSelectedRelationShip] = useState<R4.ICoding>()
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const [selectedSeverity, setSeverity] = useState<R4.ICoding>()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isActiveCondition, setIsActiveCondtion] = useState<boolean>(false)
  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date !== null ? moment(date).toDate() : new Date())
  }
  const dispatch = useDispatch()

  const addFamilyHistorySlice = useSelector(
    (state: RootState) => state.addFamilyHistorySlice
  )

  const handleChange = (
    event: boolean,
    condition: Conditions,
    index: number
  ) => {
    const values: Conditions[] = [...selectedConditionForCheckBox]
    values[index].checked = event
    setSelectedConditionForCheckBox(values)
  }
  const handleChangeOther = (
    event: boolean,
    condition: Conditions,
    index: number
  ) => {
    const values: Conditions[] = [...selectedConditionForCheckBoxForOther]
    values[index].checked = event
    setSelectedConditionForCheckBoxForOther(values)
  }

  function isEnableCreateButton(): boolean | undefined {
    for (let i = 0; i < selectedConditionForCheckBox.length; i++) {
      if (selectedConditionForCheckBox[i].checked) {
        return true
      }
    }
    return false
  }
  function isEnableCreateButtonForOther(): boolean | undefined {
    for (let i = 0; i < selectedConditionForCheckBoxForOther.length; i++) {
      if (selectedConditionForCheckBoxForOther[i].checked) {
        return true
      }
    }
    return false
  }

  function resetDetails() {
    setSelectedDate(new Date())
    setSelectedCondition(undefined)
    setSelectedRelationShip(undefined)
    setSeverity(undefined)
    setIsActive(false)
    setAdditionalNotes('')
    setIsActiveCondtion(false)
    setSelectedConditionForCheckBox(checkBoxConditions)
    setSelectedConditionForCheckBoxForOther(checkConditions2)
  }

  useEffect(() => {
    if (addFamilyHistorySlice.additionSuccessful) {
      onMedicalConditionAdded()
      dispatch(resetAddFamilyHistory())
      resetDetails()
    }
  }, [dispatch, addFamilyHistorySlice, onMedicalConditionAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>Add Family Conditon</DialogTitle>
      <DialogContent dividers={true}>
        {addFamilyHistorySlice.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!addFamilyHistorySlice.adding && (
          <Box marginX={2} marginY={1}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container direction='column' spacing={1}>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='column'
                        flexGrow={1}
                      >
                        {/* <Box display='flex' flexDirection='row'>
                          <WelloFormItemLabel title='Select Relationship' />{' '}
                        </Box> */}

                        <Box>
                          <ValueSetSelectSingle
                            key='wellopathy-relationship-vs_medicines'
                            title='Select Relationship'
                            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-relationship-vs'
                            id='opd_relations'
                            fuzzySearch={true}
                            disabled={false}
                            onOptionSelected={(
                              conditionsData: R4.ICoding | undefined
                            ) => {
                              if (conditionsData) {
                                setSelectedRelationShip(conditionsData)
                              }
                            }}
                            helperText={undefined}
                          />
                          {/* <FamilyHistoryMasterSelector
                            disabled={false}
                            onSelectionChanges={(conditions: R4.ICoding) => {
                              setSelectedRelationShip(conditions)
                            }}
                            selectOther={isActiveCondition}
                          /> */}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='column'
                        flexGrow={1}
                      >
                        <Box display='flex' flexDirection='row'>
                          <WelloFormItemLabel title='Select Condition' />{' '}
                        </Box>
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            width='50%'
                            flexDirection='column'
                            flexGrow={1}
                          >
                            {selectedConditionForCheckBox.map(
                              (e: Conditions, index) => (
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  key={e.data.code}
                                >
                                  <Box paddingLeft={0.5}>
                                    <Checkbox
                                      edge='start'
                                      checked={e.checked}
                                      onKeyDown={(eData) => {
                                        if (eData.code === 'Enter') {
                                          if (e.checked === false) {
                                            handleChange(true, e, index)
                                          } else {
                                            handleChange(false, e, index)
                                          }
                                        }
                                      }}
                                      onChange={(event) => {
                                        handleChange(
                                          event.target.checked,
                                          e,
                                          index
                                        )
                                      }}
                                      style={{
                                        paddingTop: 0,
                                      }}
                                      tabIndex={0}
                                      size='small'
                                      color='primary'
                                      inputProps={{
                                        'aria-labelledby': 'consent',
                                      }}
                                      id={`otherId${index}`}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                    >
                                      {titleCase(e.data.display ?? '')}
                                    </Typography>
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>
                          <Box
                            display='flex'
                            width='50%'
                            flexDirection='column'
                            flexGrow
                            alignItems='flex-start'
                            paddingLeft={10}
                          >
                            {selectedConditionForCheckBoxForOther.map(
                              (e: Conditions, indexData: number) => (
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  key={e.data.code}
                                  flexGrow
                                >
                                  <Box paddingLeft={0.5}>
                                    <Checkbox
                                      edge='start'
                                      checked={e.checked}
                                      onKeyDown={(eData) => {
                                        if (eData.code === 'Enter') {
                                          if (e.checked === false) {
                                            handleChangeOther(
                                              true,
                                              e,
                                              indexData
                                            )
                                          } else {
                                            handleChangeOther(
                                              false,
                                              e,
                                              indexData
                                            )
                                          }
                                        }
                                      }}
                                      onChange={(event) => {
                                        handleChangeOther(
                                          event.target.checked,
                                          e,
                                          indexData
                                        )
                                      }}
                                      tabIndex={0}
                                      size='small'
                                      style={{
                                        paddingTop: 0,
                                      }}
                                      color='primary'
                                      inputProps={{
                                        'aria-labelledby': 'consent',
                                      }}
                                      id={`other${indexData}`}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                    >
                                      {titleCase(e.data.display ?? '')}
                                    </Typography>
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>
                        </Box>
                        <Box display='flex' flexDirection='row' width='100%'>
                          <Autocomplete
                            style={{
                              borderRadius: '4px',
                            }}
                            fullWidth
                            size='small'
                            id='condition_selector'
                            getOptionSelected={(option, valueData) =>
                              option.display === valueData.display
                            }
                            disableClearable
                            getOptionLabel={(option: R4.ICoding) =>
                              titleCase(option.display ?? '')
                            }
                            options={reminingConditons}
                            onChange={(e, changedValue, reason) => {
                              if (changedValue !== null) {
                                setSelectedCondition(changedValue)
                              }
                            }}
                            autoComplete
                            ChipProps={{
                              style: {
                                backgroundColor: kPrimaryLight,
                                borderRadius: '4px',
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t(
                                  'labelCommon:type_atleast_3_chars'
                                )}
                                {...params}
                                fullWidth
                                id='family_condition_search_textBox'
                                variant='outlined'
                                size='small'
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item>
                  <Grid container direction='row'>
                    <Grid item xs={1} />
                    <Grid item xs={5}>
                      <Grid container direction='column' spacing={1}>
                        <Grid item>
                          <Typography>
                            {t(
                              'labelAllergyTollerance:attr_labels.AllergyIntolerance.clinicalStatus'
                            )}{' '}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography component='div'>
                            <Grid
                              component='label'
                              container
                              alignItems='center'
                              spacing={1}
                            >
                              <Grid item>{t('labelCommon:Inactive')}</Grid>
                              <Grid item>
                                <Switch
                                  checked={isActive}
                                  color='primary'
                                  onChange={(val: any, checked) => {
                                    setIsActive(checked)
                                  }}
                                  name='checkedA'
                                  inputProps={{
                                    'aria-label': 'secondary checkbox',
                                  }}
                                />
                              </Grid>
                              <Grid item>{t('labelCommon:active')}</Grid>
                            </Grid>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}

                <Grid item>
                  <Grid container direction='column'>
                    <Grid item>
                      <WelloFormItemLabel title='Additional Notes' />
                    </Grid>
                    <Grid item style={{ paddingBottom: '16px' }}>
                      <TextField
                        size='small'
                        fullWidth
                        multiline
                        type='number'
                        value={additionalNotes}
                        variant='outlined'
                        id='fm_11'
                        onChange={(event) => {
                          setAdditionalNotes(event.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={3.5}>
          <Button
            onClick={() => {
              resetDetails()
              onClose()
            }}
            id='fm_12'
            disabled={addFamilyHistorySlice.adding}
            variant='outlined'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                addFamilyHistory(
                  fhirAppointmentDetails,
                  selectedCondition!,
                  selectedRelationShip!,
                  selectedDate!,
                  isActive,
                  additionalNotes ?? '',
                  selectedConditionForCheckBox,
                  selectedConditionForCheckBoxForOther
                )
              )
            }}
            id='fm_14'
            variant='contained'
            color='primary'
            disableElevation
            disabled={
              (selectedCondition === undefined &&
                isEnableCreateButton() === false &&
                isEnableCreateButtonForOther() === false) ||
              selectedRelationShip === undefined ||
              selectedDate === null ||
              addFamilyHistorySlice.adding
            }
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
