import { R4 } from '@ahryman40k/ts-fhir-types'
import { Avatar, Box, Paper } from '@material-ui/core'
import React from 'react'
import {
  getAgeOfPatientData,
  getProfilePicPatient,
} from 'utils/fhirResourcesHelper'
import { getNameOfPatient } from 'wello-web-components'
import female from '../../../assets/icons/f.png'
import male from '../../../assets/icons/m.png'
import { ProfilePicViewer } from '../common/profilePicViewer'
import { SelectedFile } from '../LeftMenu/WelloFilePickerForProfile'

interface Props {
  patient: R4.IPatient
}

export const ProfilePicWithAgeAndGender: React.FC<Props> = ({
  patient,
}: Props) => (
  <Box p={1}>
    {/* <Avatar
      alt={getNameOfPatient(patient)}
      src={getProfilePicPatient(patient)}
      style={{ height: '80px', width: '80px' }}
    /> */}

    <ProfilePicViewer
      contentType='image/png'
      existingFile={false}
      decodingRequired={true}
      imagePath='Patient.photo'
      resourceId={patient.id ?? ''}
      resourceType='Patient'
      onDataFetched={(value: string) => {
        // setDataFile(true)
      }}
      onSelectionChanged={(value: SelectedFile) => {
        // if (valueData) {
        //   if (value.path && value.files) {
        //     const imgContent: string = value.path as string
        //     setExistingPatient({
        //       ...existingPatient,
        //       imgName: imgContent.split(',')[1],
        //       type: value.files?.type,
        //       photoUri: value.path as string,
        //     })
        //   }
        // }
      }}
      update={false}
      purpose='tile3'
      prescription={true}
    />

    <Box
      display='flex'
      justifyContent='center'
      style={{
        position: 'relative',
        top: -10,
        zIndex: 3,
      }}
      alignContent='center'
    >
      <Paper>
        <Box flexShrink={0} px={2} py={0.5} justifyItems='flex-start'>
          {patient.gender === R4.PatientGenderKind._male ? (
            <img
              src={male}
              alt='male'
              height='12'
              style={{ paddingRight: 4 }}
            />
          ) : (
            <img
              src={female}
              alt='female'
              height='12'
              style={{ paddingRight: 4 }}
            />
          )}
          {getAgeOfPatientData(patient)}
        </Box>
      </Paper>
    </Box>
  </Box>
)
