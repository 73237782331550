import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestDietPlanDetails } from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { RootState } from 'redux/rootReducer'
import { DietPlanCardForEncounterView } from 'views/components/dietplan/dayDietPlanForEncounterView'

interface Props {
  fhirAppointmentDetails: FhirAppointmentDetail
  onClick?: () => void
}

export const DietPlanList: React.FC<Props> = ({
  fhirAppointmentDetails,
  onClick,
}: Props) => {
  const dispatch = useDispatch()

  const labDiagnosticsListSlice = useSelector(
    (state: RootState) => state.existingDietPlanSearchSlice
  )

  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )

  useEffect(() => {
    if (fhirAppointmentDetails.encounter !== undefined)
      dispatch(
        requestDietPlanDetails({
          dietTime: dietTimeSearchSlice.prSelectedCoding ?? [],
          patientId: fhirAppointmentDetails.patient.id!,
          encounterId: fhirAppointmentDetails.encounter.id,
          practitionerRoleId:
            fhirAppointmentDetails.practitionerDetail.practitionerRole.id!,
          showPlanSuggestedBySameDoctor: true,
        })
      )
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column'>
      {labDiagnosticsListSlice.searchingTreatment && (
        <CircularProgress size={15} />
      )}
      {/* {labDiagnosticsListSlice.noResultsAvailable && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            No data available
          </Typography>
        </Box>
      )} */}
      {labDiagnosticsListSlice.resultsAvailable &&
        labDiagnosticsListSlice.dietList!.length > 0 && (
          <DietPlanCardForEncounterView
            patientID={fhirAppointmentDetails.patient.id!}
            encounterID={fhirAppointmentDetails.encounter?.id!}
            practitionerRoleId={
              fhirAppointmentDetails.practitionerDetail.practitionerRole.id!
            }
            displayRecordedDate={false}
          />
        )}
    </Box>
  )
}
