import _, { debounce } from 'lodash'
import React, { useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Divider from '@mui/material/Divider'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'

import { R4 } from '@ahryman40k/ts-fhir-types'

import match from 'autosuggest-highlight/match'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Tooltip,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  IconButton,
  Checkbox,
  withStyles,
  Switch,
} from '@material-ui/core'
import Info from '@material-ui/icons/Info'

import { kDialogueBackground } from 'configs/styles/muiThemes'
import { getFoodTimeCoding } from 'utils/fhirResoureHelpers/dietPlanHelpers'
import { DietTiming } from '../../../models/dietSetting/dietTImeSettings'
import { dietTimeData } from '../../../utils/constants'
import appCancelReasons from '../../../assets/constants/cancel_reasons.json'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from '../LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from '../LeftMenu/WelloTextFieldWitoutTitle'
import { WelloSelectTime } from '../LeftMenu/WelloSelectTIme'

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

export interface ValueSetSearchProps {
  id: string
  preSelectedOptions?: DietTiming[]

  onOptionSelected: (selectedOption: DietTiming[]) => void
}

export const ValueSetSelectMultipleWithSwitchForDietSetting: React.FC<ValueSetSearchProps> =
  ({
    id,
    preSelectedOptions,

    onOptionSelected,
  }) => {
    const [value, setValue] = React.useState<DietTiming[]>(
      preSelectedOptions ?? []
    )
    console.log(value)
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState<DietTiming[]>(dietTimeData)

    const [loading, setLoading] = React.useState(false)

    const handleChange = (
      event: boolean,
      dateTimeData: DietTiming,
      index: number
    ) => {
      const values: DietTiming[] = [...value]
      if (event === true) {
        const finalData = values.filter(
          (d: DietTiming) => d.dietTime === dateTimeData.dietTime
        )
        if (finalData.length === 0) {
          values.push({
            dietTime: dateTimeData.dietTime,
            active: true,
            time: dateTimeData.time,
            displayLabel: '',
            timeOfTheDay: '',
            isEdit: true,
          })
        }
        if (finalData.length > 0) {
          const valueData = { ...values[index] }

          valueData.active = true
          valueData.isEdit = true
          values[index] = valueData
        }
      } else {
        const indexData = values.findIndex(
          (x) => x.dietTime === dateTimeData.dietTime
        )

        const valueData = { ...values[indexData] }
        // values[index].active = true
        // values[index].dietTime = dateTimeData.dietTime
        // values[index].time = dateTimeData.time
        // values[index].timeOfTheDay = dateTimeData.timeOfTheDay
        valueData.displayLabel = ''
        valueData.isEdit = true
        valueData.active = false
        valueData.timeOfTheDay = ''
        values[indexData] = valueData
        // values[indexData].active = false
        // values[indexData].dietTime = ''
        // values[indexData].time = undefined
        // values[indexData].timeOfTheDay = ''
        // if (indexData > -1) {
        //   // only splice array when item is found
        //   values.splice(indexData, 1) // 2nd parameter means remove one item only
        // }
      }
      console.log(values)
      setValue(values)
      onOptionSelected(values)
    }

    const handleChangeDisplayLabel = (
      event: string,
      dateTimeData: DietTiming,
      index: number
    ) => {
      const values: DietTiming[] = [...value]

      const finalData = values.filter(
        (d: DietTiming) => d.dietTime === dateTimeData.dietTime
      )
      if (finalData.length === 0) {
        values.push({
          dietTime: dateTimeData.dietTime,
          active: true,
          time: dateTimeData.time,
          displayLabel: event,
          timeOfTheDay: '',
          isEdit: true,
        })
      }
      if (finalData.length > 0) {
        const valueData = { ...values[index] }
        // values[index].active = true
        // values[index].dietTime = dateTimeData.dietTime
        // values[index].time = dateTimeData.time
        // values[index].timeOfTheDay = dateTimeData.timeOfTheDay
        valueData.displayLabel = event
        values[index] = valueData
        valueData.isEdit = true
      }

      setValue(values)
      onOptionSelected(values)
    }

    const onCancelReasonChanged = (
      condition: R4.ICoding,
      dateTimeData: DietTiming,
      index: number
    ) => {
      const values: DietTiming[] = [...value]

      const finalData = values.filter(
        (d: DietTiming) => d.dietTime === dateTimeData.dietTime
      )
      if (finalData.length === 0) {
        values.push({
          dietTime: dateTimeData.dietTime,
          active: true,
          time: condition,
          displayLabel: '',
          timeOfTheDay: '',
          isEdit: true,
        })
      }
      if (finalData.length > 0) {
        const valueData = { ...values[index] }
        // values[index].active = true
        // values[index].dietTime = dateTimeData.dietTime
        // values[index].time = dateTimeData.time
        // values[index].timeOfTheDay = dateTimeData.timeOfTheDay
        valueData.dietTime = dateTimeData.dietTime
        valueData.time = condition
        valueData.timeOfTheDay = dateTimeData.timeOfTheDay
        // values[index] = valueData
        // values[index].dietTime = dateTimeData.dietTime
        // values[index].time = condition
        values[index] = valueData
        valueData.isEdit = true
      }

      setValue(values)
      onOptionSelected(values)
    }

    return (
      <Box display='flex' flexDirection='column' width='100%'>
        {loading && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
            px={1}
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Box width='100%' display='flex' flexDirection='row' py={1}>
          {loading === false && options.length > 0 && (
            <Box width='100%'>
              {options.map((coding: DietTiming, qindex: number) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  key={coding.dietTime}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    key={coding.dietTime}
                    width='100%'
                    height={40}
                  >
                    <Box
                      width='20%'
                      paddingBottom={1}
                      paddingRight={1}
                      paddingLeft={2}
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                    >
                      <Typography variant='subtitle2' color='initial'>
                        {coding.dietTime}
                      </Typography>
                    </Box>
                    <Box
                      paddingLeft={0.5}
                      width='20%'
                      paddingBottom={1}
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        justifyContent='flex-start'
                        paddingLeft={5}
                      >
                        <AntSwitch
                          onChange={(event) => {
                            if (coding) {
                              handleChange(event.target.checked, coding, qindex)
                            }
                          }}
                          onKeyDown={(e) => {
                            console.log(e)
                            if (e.code === 'Enter') {
                              if (
                                value.filter(
                                  (org: DietTiming) =>
                                    org.dietTime === coding.dietTime &&
                                    org.active === true
                                ).length > 0
                              ) {
                                handleChange(false, coding, qindex)
                              } else {
                                handleChange(true, coding, qindex)
                              }
                            }
                          }}
                          name=''
                          checked={
                            value.filter(
                              (org: DietTiming) =>
                                org.dietTime === coding.dietTime &&
                                org.active === true
                            ).length > 0
                          }
                        />
                      </Box>
                    </Box>
                    <Box
                      paddingLeft={0.5}
                      width='20%'
                      paddingBottom={1}
                      paddingTop={0.5}
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                    >
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box width='50%'>
                          <WelloSelectTime
                            id='cancelReason'
                            availableCodings={getFoodTimeCoding(
                              coding.dietTime
                            )}
                            preSelectedCoding={
                              value[qindex] ? value[qindex].time : undefined
                            }
                            onChanged={(e) => {
                              if (e) {
                                onCancelReasonChanged(
                                  e as R4.ICoding,
                                  coding,
                                  qindex
                                )
                              }
                            }}
                            textFieldProps={{
                              size: 'small',
                              disabled:
                                value.filter(
                                  (org: DietTiming) =>
                                    org.dietTime === coding.dietTime &&
                                    org.active === true
                                ).length === 0,
                            }}
                          />
                        </Box>

                        {/* <Box width='50%'>
                            <WelloSelectTime
                              id='cancelReason'
                              availableCodings={getFoodTimeCoding(
                                coding.dietTime
                              )}
                              preSelectedCoding={undefined}
                              onChanged={(e) => {
                                if (e) {
                                  onCancelReasonChanged(
                                    e as R4.ICoding,
                                    coding,
                                    qindex
                                  )
                                }
                              }}
                              textFieldProps={{
                                size: 'small',
                              }}
                            />
                          </Box> */}

                        <Box paddingLeft={1} />

                        <Box
                          paddingLeft={1.6}
                          width='20%'
                          paddingBottom={1}
                          paddingTop={0.5}
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          borderRadius={4}
                          bgcolor={kDialogueBackground}
                        >
                          <Typography variant='subtitle2' color='initial'>
                            {coding.dietTime === 'Early Morning' ||
                            coding.dietTime === 'Breakfast' ||
                            coding.dietTime === 'Mid Morning'
                              ? 'AM'
                              : 'PM'}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      paddingLeft={0.5}
                      width='20%'
                      paddingBottom={1}
                      paddingTop={0.5}
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                    >
                      <WelloTextFieldWithoutTitle
                        textProps={{
                          id: `medical_qualification_reg_num_${qindex}`,
                          disabled:
                            value.filter(
                              (org: DietTiming) =>
                                org.dietTime === coding.dietTime &&
                                org.active === true
                            ).length === 0,
                          placeholder: coding.dietTime,
                          value:
                            value.filter(
                              (org: DietTiming) =>
                                org.dietTime === coding.dietTime &&
                                org.active === true
                            ).length > 0
                              ? value.filter(
                                  (org: DietTiming) =>
                                    org.dietTime === coding.dietTime
                                )[0].displayLabel
                              : '',

                          onChange: (e) => {
                            handleChangeDisplayLabel(
                              e.target.value,
                              coding,
                              qindex
                            )
                            //  handleChangeDisplayLabel(
                            //    index,
                            //    e.target.value
                            //  )
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '1px',
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    )
  }
