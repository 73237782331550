import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { ConvenienceFee } from 'models/convinienceFeeDetails'
import { LabReferralDetails } from 'models/labReferralDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { paymentTypes } from 'utils/constants'
import { UnitTypesForSetting } from 'utils/constants/unit_types'

import {
  getAmountForConvenienceFee,
  getAmountForReferralFee,
  getAmountForReferralFeeInPercent,
  getServiceTypeData,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { logger } from 'utils/logger'
import { ConvenienceSearchStatus } from './convinienceSearchStatus'

const initialState: ConvenienceSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const convenienceFeeSearchSlice = createSlice({
  name: 'convenienceFeeSearchSlice',
  initialState,
  reducers: {
    searchingCFee(state, action: PayloadAction<ConvenienceSearchStatus>) {},

    searchResults(state, action: PayloadAction<ConvenienceSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<ConvenienceSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },

    errorWhileSearching(state, action: PayloadAction<ConvenienceSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },
  },
})

export const searchingConvFee = (): AppThunk => async (dispatch: any) => {
  const errorFetchingUnits: ConvenienceSearchStatus = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: true,
  }
  dispatch(
    convenienceFeeSearchSlice.actions.errorWhileSearching(errorFetchingUnits)
  )
  try {
    let searchParameter = {}
    searchParameter = {
      _count: 500,
    }
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response = await fhirClient.doGetResource(
      `/ChargeItemDefinition?charge-defn-code=convenience-fee&status=active`,
      searchParameter
    )

    const orgResponse: R4.IBundle = response
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          const rFeeArray: R4.IChargeItemDefinition[] = orgResponse.entry.map(
            (item) => item.resource as R4.IChargeItemDefinition
          )

          const referralFeeArray: ConvenienceFee[] = []
          for (let i = 0; i < rFeeArray.length; i++) {
            const refArrayDataConText = rFeeArray[i].useContext ?? []
            if (refArrayDataConText) {
              const serviceTypeContext =
                refArrayDataConText && refArrayDataConText.length > 0
                  ? refArrayDataConText.filter(
                      (e) => e.code && e.code.code === 'online'
                    )
                  : []

              const unitTypeContext =
                refArrayDataConText && refArrayDataConText.length > 0
                  ? refArrayDataConText.filter(
                      (e) => e.code && e.code.code !== undefined
                    )
                  : []
              referralFeeArray.push({
                id: rFeeArray[i].id ?? '',
                amount: getAmountForConvenienceFee(
                  rFeeArray[i].propertyGroup ?? []
                ),
                amountInPercentage: getAmountForReferralFeeInPercent(
                  rFeeArray[i].propertyGroup ?? []
                ),
                percentage: true,
                specialtyCoding: [],
                eventTime: [],
                cFee: rFeeArray[i],
                paymentType:
                  getAmountForConvenienceFee(
                    rFeeArray[i].propertyGroup ?? []
                  ) &&
                  getAmountForConvenienceFee(rFeeArray[i].propertyGroup ?? []) >
                    0
                    ? paymentTypes[0]
                    : paymentTypes[1],
                serviceType:
                  serviceTypeContext.length > 0
                    ? getServiceTypeData(serviceTypeContext[0].code)
                    : undefined,
                unitType:
                  unitTypeContext.length > 0
                    ? unitTypeContext[0].code.display === 'Clinic'
                      ? UnitTypesForSetting[0]
                      : UnitTypesForSetting[1]
                    : undefined,
                patientCount: 1,
                serviceString:
                  serviceTypeContext.length > 0
                    ? serviceTypeContext[0].code.display
                    : '',
                untTypeArray:
                  unitTypeContext.length > 0
                    ? unitTypeContext[0].code.display
                    : '',
              })
            }
          }

          const fetchUnitListResult: ConvenienceSearchStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,

            referralList: referralFeeArray,
          }
          dispatch(
            convenienceFeeSearchSlice.actions.searchResults(fetchUnitListResult)
          )
          return
        }
      }
    }
    const noSearchResults: ConvenienceSearchStatus = {
      error: false,
      noResultsAvailable: true,
      resultsAvailable: false,
      searching: false,
    }
    dispatch(
      convenienceFeeSearchSlice.actions.noDataFoundForSearch(noSearchResults)
    )
    return
  } catch (error) {
    logger.error(error)
    const errorWhileSearchPatient: ConvenienceSearchStatus = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error',
    }
    dispatch(
      convenienceFeeSearchSlice.actions.errorWhileSearching(
        errorWhileSearchPatient
      )
    )
  }
}

export default convenienceFeeSearchSlice.reducer
