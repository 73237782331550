import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  TextField,
  Typography,
  makeStyles,
  PopperProps,
  Popper,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import {} from 'models/chiefComplaintData'
import { MedicationGroupedData } from 'models/medicationGrouppedData'
import { Medication, UmlResponse } from 'models/umlResponse'
import React, { useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  searchMedicationsIdnain,
  resetState,
} from 'redux/umls/medication/indianMedication/indianMedicationSearchSlice'
import { getMedicationCodeSystemForSearch } from 'utils/appointment_handle/medications_util'
import { logger } from 'utils/logger'

interface LabOfferingSelectorProps {
  onSelectionChanges?: (selectedValues: MedicationGroupedData) => void
  doctorListId?: string
  preSelectedComplaints?: MedicationGroupedData
  preSelectAll?: boolean
  disabled: boolean
  selectOther: boolean
  preSearch?: string
  preSearchCode?: string
  preSelectedSystem?: string
}

const useStyle = makeStyles(() => ({
  popperRoot: {
    [`& .MuiAutocomplete-noOptions`]: {
      display: 'none',
    },
  },
}))

export const MedicationMasterSelector: React.FC<LabOfferingSelectorProps> = ({
  onSelectionChanges,
  doctorListId,
  preSelectedComplaints,
  preSelectAll = false,
  disabled,
  selectOther,
  preSearch,
  preSearchCode,
  preSelectedSystem,
}: LabOfferingSelectorProps) => {
  const indianMedicationSearchSlice = useSelector(
    (state: RootState) => state.indianMedicationSearchSlice
  )
  const dispatch = useDispatch()
  const classes = useStyle()
  const { t } = useTranslation(['common', 'medication'])
  const [text, setText] = useState<string>(preSearch ?? preSelectedSystem ?? '')
  const [codeText, setCodeText] = useState<string>(preSearchCode ?? '')
  const [open, SetOpen] = useState<boolean>(false)
  const offSet = useRef<number>(1)
  useEffect(() => {
    if (preSearch) {
      dispatch(
        searchMedicationsIdnain(
          offSet.current,
          '',
          false,
          getMedicationCodeSystemForSearch(preSearch ?? ''),
          indianMedicationSearchSlice.medicationList ?? []
        )
      )
      SetOpen(true)
    }
  }, [dispatch])

  function loadMoreResults(pageNumber: number) {
    dispatch(searchMedicationsIdnain(pageNumber, text, selectOther))
  }
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching medication list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(
                searchMedicationsIdnain(
                  offSet.current,
                  '',
                  selectOther,
                  getMedicationCodeSystemForSearch(preSearch ?? '')
                )
              )
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box width='100%'>
        <Box width='100%'>
          <Autocomplete
            // PopperComponent={(props: PopperProps) => (
            //   <Popper
            //     {...props}
            //     disablePortal
            //     className={classes.popperRoot}
            //     placement='top'
            //   />
            // )}
            id='allopathyMedication'
            // disablePortal={true}
            ListboxProps={{
              onScroll: (event: React.SyntheticEvent) => {
                const listboxNode = event.currentTarget

                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                  listboxNode.scrollHeight
                ) {
                  if (
                    indianMedicationSearchSlice.medicationList &&
                    indianMedicationSearchSlice.medicationList.length >= 10
                  ) {
                    offSet.current += 10
                    loadMoreResults(offSet.current)
                  }
                }
              },
              style: {
                maxHeight: 400,
              },
            }}
            multiple={false}
            open={open}
            onOpen={() => {}}
            onClose={() => {
              dispatch(resetState())
            }}
            style={{
              borderRadius: '4px',
            }}
            disableClearable
            fullWidth
            size='small'
            getOptionSelected={(option, value) => option.id === value.id}
            inputValue={text}
            disabled={disabled}
            defaultValue={preSelectedComplaints}
            getOptionLabel={(option) => option.name!}
            options={indianMedicationSearchSlice.medicationList ?? []}
            loading={indianMedicationSearchSlice.searching}
            autoComplete
            includeInputInList
            onChange={(e, changedValue, reason) => {
              logger.info(changedValue)
              if (onSelectionChanges) {
                setText(changedValue.name ?? '')
                SetOpen(false)
                onSelectionChanges(changedValue ?? [])
              }
            }}
            ChipProps={{
              deleteIcon: (
                <ClearOutlined
                  style={{
                    height: '15px',
                    color: 'white',
                  }}
                />
              ),
              style: {
                backgroundColor: kPrimaryLight,
                borderRadius: '4px',
              },
            }}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.name!, inputValue)
              const parts = parse(option.name!, matches)
              return (
                <ListItem id={`lab_medication_opt_${option.id}`}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={part.text}
                        style={{
                          backgroundColor: part.highlight
                            ? kPrimaryLight
                            : undefined,
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </ListItem>
              )
            }}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Type at least 3 characters'
                variant='outlined'
                id='medication_search'
                onChange={(e) => {
                  SetOpen(true)
                  dispatch(
                    searchMedicationsIdnain(1, e.target.value, selectOther)
                  )
                  setText(e.target.value)
                }}
                InputProps={{
                  ...params.InputProps,

                  endAdornment: (
                    <>
                      {indianMedicationSearchSlice.searching ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>

        {indianMedicationSearchSlice.error && (
          <Typography>
            {' '}
            {indianMedicationSearchSlice.errorMessage ??
              'Error while searching'}
          </Typography>
        )}
        {indianMedicationSearchSlice.noResultsAvailable && (
          <Typography> No Results available</Typography>
        )}
      </Box>
    </ErrorBoundary>
  )
}
