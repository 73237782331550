import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Alert, Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addConvFee,
  resetConvenienceFeeAdd,
} from 'redux/administration/payment-settings/addConvinienceFee/addConvFeeSlice'
import {
  addReferralDetails,
  resetReferralAdd,
} from 'redux/administration/referralSetup/referralInvite/addLabReferralSlice'
import { searchUnits } from 'redux/administration/referralSetup/unitSearch/unitSearchSlice'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import { paymentTypes } from 'utils/constants'
import {
  serviceTypeForLab,
  serviceTypeForSetting,
  UnitTypes,
  UnitTypesForSetting,
} from 'utils/constants/unit_types'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'

interface Props {
  open: boolean
  title: string
  onBackClick: () => void
  onContinueClick: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogCustomizedWidth: {
    'max-width': '30%',
  },
}))
export const AddReferralAmount: React.FC<Props> = ({
  open,
  title,
  onBackClick: onClose,
  onContinueClick,
}) => {
  const classes = useStyles()
  const addReferralSlice = useSelector(
    (state: RootState) => state.addConvenienceFeeSlice
  )

  const unitSearchSlice = useSelector(
    (state: RootState) => state.unitSearchSlice
  )

  const [percentageData, setPercentageData] = React.useState<number>()

  const [selectedPaymentType, setselectedPaymentType] = useState<R4.ICoding>(
    paymentTypes[0]
  )
  const [unitType, setUnitType] = useState<R4.ICoding>(UnitTypesForSetting[0])
  const [serviceType, setServiceType] = useState<R4.ICoding>(
    unitType.code === 'clinic' ? serviceTypeForSetting[0] : serviceTypeForLab[0]
  )
  const [amount, setAmount] = React.useState<number>()

  const dispatch = useDispatch()

  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const [org, setOrg] = React.useState<R4.IOrganization>()

  function resetDetails() {
    setPercentageData(undefined)
    setAmount(undefined)
    setselectedPaymentType(paymentTypes[0])
    setUnitType(UnitTypesForSetting[0])
    setServiceType(serviceTypeForSetting[0])
  }

  function handleSubmit() {
    dispatch(
      addConvFee(
        unitType,
        serviceType,
        selectedPaymentType.code!,
        amount!,
        percentageData!
      )
    )
  }

  function enableSave() {
    const error: string[] = []
    if (selectedPaymentType.code && selectedPaymentType.code === 'amount') {
      if (amount !== undefined) {
        if (amount < 0 || amount > 100) {
          error.push('1')
        }
      }

      if (amount === undefined) {
        error.push('1')
      }
    } else {
      if (percentageData !== undefined) {
        if (percentageData < 0 || percentageData > 100) {
          error.push('1')
        }
      }

      if (percentageData === undefined) {
        error.push('1')
      }
    }
    if (error.length > 0) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (addReferralSlice.additionSuccessful) {
      resetDetails()
      dispatch(resetConvenienceFeeAdd())
      onClose()
    }
    return () => {}
  }, [addReferralSlice.additionSuccessful])

  useEffect(() => {
    if (unitSearchSlice.resultsAvailable && unitSearchSlice.unitsList) {
      setOrg(unitSearchSlice.unitsList[0])
    }

    return () => {}
  }, [unitSearchSlice.resultsAvailable, unitSearchSlice.unitsList])

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(resetReferralAdd())
        resetDetails()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>{`${title} Settings`}</DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column' width='100%'>
          {addReferralSlice.error && (
            <Box display='flex'>
              <Alert severity='error'>{addReferralSlice.errorMessage}</Alert>
            </Box>
          )}
          {addReferralSlice.additionSuccessful && (
            <Box display='flex'>
              <Alert severity='success'>{`${title} Settings added successfully`}</Alert>
            </Box>
          )}
          <Box px={2}>
            {unitSearchSlice.searching && (
              <CircularProgress size={18} color='inherit' />
            )}
          </Box>
          <Box display='flex' flexDirection='column' width='100%'>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box width='50%' paddingRight={1}>
                <WelloSelectFHIRCoding
                  title='Unit Type'
                  availableCodings={UnitTypesForSetting}
                  onChanged={(e) => {
                    if (e) {
                      if (e.code === 'lab') {
                        setServiceType(serviceTypeForLab[0])
                      } else {
                        setServiceType(serviceTypeForSetting[0])
                      }
                      setUnitType(e)
                    }
                  }}
                  id='id_unit_details'
                  textFieldProps={{
                    size: 'small',
                  }}
                  preSelectedCoding={unitType}
                />
              </Box>
              {unitType.code === 'clinic' && (
                <Box width='50%' paddingLeft={1}>
                  <WelloSelectFHIRCoding
                    title='Service Type'
                    availableCodings={
                      unitType.code === 'clinic'
                        ? serviceTypeForSetting
                        : serviceTypeForLab
                    }
                    onChanged={(e) => {
                      if (e) {
                        setServiceType(e)
                      }
                    }}
                    id='id_unit_details'
                    textFieldProps={{
                      size: 'small',
                    }}
                    preSelectedCoding={serviceType}
                  />
                </Box>
              )}

              {unitType.code === 'lab' && (
                <Box width='50%' paddingLeft={1}>
                  <WelloSelectFHIRCoding
                    title='Service Type'
                    availableCodings={serviceTypeForLab}
                    onChanged={(e) => {
                      if (e) {
                        setServiceType(e)
                      }
                    }}
                    id='id_unit_details'
                    textFieldProps={{
                      size: 'small',
                    }}
                    preSelectedCoding={serviceType}
                  />
                </Box>
              )}
            </Box>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box width='50%' paddingRight={1}>
                <WelloSelectFHIRCoding
                  title='Fee Type'
                  id='payment_type'
                  availableCodings={paymentTypes}
                  onChanged={(type) => {
                    setselectedPaymentType(type)

                    if (type.code === 'amount') {
                      setPercentageData(undefined)
                    }
                    if (type.code === 'percent') {
                      setAmount(undefined)
                    }
                  }}
                  textFieldProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                  preSelectedCoding={selectedPaymentType!}
                  role='payment_type'
                />
              </Box>
              <Box width='50%' paddingLeft={1}>
                {selectedPaymentType &&
                  selectedPaymentType.code &&
                  selectedPaymentType.code === 'percent' && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Fee Percentage' />
                      <TextField
                        size='small'
                        variant='outlined'
                        type='number'
                        placeholder=''
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: 0.25,
                          },
                        }}
                        value={percentageData}
                        onChange={(e) => {
                          const newValue = Number(e.target.value)

                          if (newValue >= 0 && newValue <= 15) {
                            setPercentageData(Number(e.target.value))
                          } else if (/^\d+$/.test(e.target.value)) {
                            dispatch(
                              showErrorAlert(
                                `${title} Percentage cannot be more than 15`
                              )
                            )
                          }
                        }}
                      />
                    </Box>
                  )}

                {selectedPaymentType &&
                  selectedPaymentType.code &&
                  selectedPaymentType.code === 'amount' && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Fee Amount' />
                      <TextField
                        size='small'
                        variant='outlined'
                        type='number'
                        placeholder=''
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: 0.25,
                          },
                        }}
                        value={amount}
                        onChange={(e) => {
                          const newValue = Number(e.target.value)
                          if (newValue >= 0 && newValue <= 100) {
                            setAmount(Number(e.target.value))
                          } else if (/^\d+$/.test(e.target.value)) {
                            dispatch(
                              showErrorAlert(
                                `${title} Amount cannot be more than 100`
                              )
                            )
                          }
                        }}
                      />
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {addReferralSlice.adding && (
          <CircularProgress color='inherit' size={20} />
        )}
        <Box paddingRight={1.5}>
          <Button
            onClick={() => {
              resetDetails()
              onClose()
            }}
            variant='outlined'
            disabled={addReferralSlice.adding}
            disableElevation
          >
            {t('labelCommon:back')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={enableSave()}
            onClick={() => {
              handleSubmit()
            }}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
