import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  makeStyles,
  Theme,
  Tooltip,
  Button,
  Typography,
} from '@material-ui/core'

import {
  FhirAppointmentDetail,
  FhirPendingAppointmentDetails,
} from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestAppointmentsCountForToday } from 'redux/appointments/appointmentCount/appointmentCountSlice'
import {
  FinishAppointment,
  getPreviousAppointmentCount,
  getPreviousAppointmentList,
} from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import { FhirPendingListView } from './pendingAppointmentList'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    big: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
)

interface IProps {
  appointmentDetails: FhirAppointmentDetail
  openPreview: boolean
  onCloseClicked: () => void
}

export const PendingAppointments: React.FC<IProps> = ({
  appointmentDetails,
  openPreview,

  onCloseClicked,
}: IProps) => {
  const dispatch = useDispatch()
  const [countUpdateStatus, setCountUpdateStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

  const [UpdateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [loadingCount, setLoadingCount] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [appointmentData, setAppointmentData] =
    useState<FhirPendingAppointmentDetails[]>()

  const [appointmentDataForEncounter, setAppointmentDataForEncounter] =
    useState<FhirPendingAppointmentDetails>()

  function getPreviousAppointmentData(
    appointmentDetailsData: FhirAppointmentDetail
  ) {
    setCountUpdateStatus({ requesting: true })

    setLoadingCount(true)

    getPreviousAppointmentList(appointmentDetailsData)
      .then((e: FhirPendingAppointmentDetails[]) => {
        setLoadingCount(false)
        if (e) {
          setAppointmentData(e)
          setCountUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setCountUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoadingCount(false)
          dispatch(
            showErrorAlert(
              'Error while fetching pending appointments. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoadingCount(false)
        setCountUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function endAppointment(
    appointmentDetailsData: FhirPendingAppointmentDetails
  ) {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    FinishAppointment(appointmentDetailsData)
      .then((e: boolean) => {
        setLoading(false)
        if (e) {
          dispatch(showSuccessAlert('Appointment ended successfully'))
          getPreviousAppointmentData(appointmentDetails)
          dispatch(requestAppointmentsCountForToday())
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          //   dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching updating appointment. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    getPreviousAppointmentData(appointmentDetails)
  }, [])

  return (
    <Dialog
      maxWidth='sm'
      open={openPreview}
      onClose={() => {
        onCloseClicked()
      }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          justifyContent='center'
        >
          <Box>Pending Appointments</Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' width='100%' flexDirection='column'>
          <Box
            display='flex'
            width='100%'
            flexDirection='row'
            justifyContent='center'
          >
            {loadingCount && <CircularProgress />}
          </Box>

          <Box
            display='flex'
            width='100%'
            flexDirection='row'
            justifyContent='center'
          >
            {loading && <CircularProgress />}
          </Box>

          {appointmentData && appointmentData.length === 0 && (
            <Box
              display='flex'
              width='100%'
              flexDirection='row'
              justifyContent='center'
              py={1}
            >
              <Typography variant='subtitle1' color='initial' align='center'>
                No Pending Appointments Available.
              </Typography>
            </Box>
          )}

          {appointmentData && appointmentData.length > 0 && (
            <FhirPendingListView
              doctorAppointments={appointmentData}
              onAppointmentSelected={(
                appointmentDataFinal: FhirPendingAppointmentDetails
              ) => {
                if (appointmentDataFinal) endAppointment(appointmentDataFinal)
              }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box paddingRight={2.4}>
          <Button
            onClick={() => {
              onCloseClicked()
            }}
            variant='outlined'
            disableElevation
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
