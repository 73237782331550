import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRoomsOfCurrentUnit } from 'redux/administration/unit/roomList/roomsListSlice'
import { RootState } from 'redux/rootReducer'
import { getIdentifierValueOfUrlType } from 'utils/fhirResourcesHelper'
import { getRoomTypeLabel } from 'utils/fhirResoureHelpers/roomsHelper'
import { WelloLoadingIndicator } from 'wello-web-components'
import { RoomSListOfSelectedType } from './roomsOfSelectType'

export interface IUnitAdmin {
  children?: React.ReactNode
}

export const RoomsOfOrganization: React.FC = () => {
  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.roomListSlice
  )
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  useEffect(() => {
    dispatch(fetchRoomsOfCurrentUnit())
  }, [dispatch])

  return (
    <Box display='flex' flexDirection='column' width='100%' px={2}>
      <Box display='flex' flexDirection='row' py={2} width='100%'>
        <Typography variant='h6'>Rooms and Bed Management</Typography>
      </Box>
      <Box>
        {unitsOfOrganizationSlice.searchingPrice && (
          <WelloLoadingIndicator message='Searching' />
        )}
        {unitsOfOrganizationSlice.resultsAvailable &&
          unitsOfOrganizationSlice.roomsList && (
            <Box display='flex' flexDirection='column' width='100%'>
              {unitsOfOrganizationSlice.roomsList.map((e) => (
                <Accordion
                  key={`room_key_${e.category ?? ''}`}
                  id={`room_id_${e.category ?? ''}`}
                  expanded={expanded === e.category ?? ''}
                  onChange={handleChange(e.category ?? '')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2a-content'
                    id='panel2a-header'
                  >
                    <Typography variant='h6' color='primary'>
                      {getRoomTypeLabel(e.category)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RoomSListOfSelectedType
                      rooms={e.rooms
                        .slice()
                        .sort((a, b) =>
                          (
                            getIdentifierValueOfUrlType(
                              a.identifier ?? [],
                              'SNO',
                              'http://terminology.hl7.org/CodeSystem/v2-0203'
                            ) ?? ''
                          )
                            .toLowerCase()
                            .localeCompare(
                              (
                                getIdentifierValueOfUrlType(
                                  b.identifier ?? [],
                                  'SNO',
                                  'http://terminology.hl7.org/CodeSystem/v2-0203'
                                ) ?? ''
                              ).toLowerCase()
                            )
                        )}
                      roomTypeCode={e.category}
                      price={e.price}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
        {unitsOfOrganizationSlice.noResultsAvailable && (
          <Typography> Please contact admin for Room and Bed Setup </Typography>
        )}

        {unitsOfOrganizationSlice.errorWhileSearchingPrice && (
          <Typography>
            {' '}
            {unitsOfOrganizationSlice.errorReason ??
              'Error while searching Rooms . Please try again'}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
