/* eslint-disable no-self-assign */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-lone-blocks */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { IInvoice_LineItem } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { CloseOutlined, DeleteOutlineOutlined } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { BillingDetails } from 'models/BillingDetails'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { SplitPaymentDetails } from 'models/splitPayment/splitPaymentDetails'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import { isGre, isUnitAdmin } from 'services/userDetailsService'
import { paymentTypes } from 'utils/constants'
import { PaymentTypeCodes } from 'utils/constants/payment_types'
import { getSymbolForCurrency } from 'utils/fhirResoureHelpers/currency_helper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  changeCodingForDiscount,
  checkForDuplicateReference,
  completePayment,
  deleteLineItemsForHelper,
  deleteLineItemsInServer,
  getAdVanceData,
  getAdVanceDataCheck,
  getBalanceAmount,
  getBalanceAmountForSplitPayment,
  getBalanceAmountNumber,
  getBasePriceOfLineItem,
  getDateDifferceinDays,
  getDateOfLineItem,
  getDiscount,
  getDiscountAmountForInitialData,
  getDiscountAmountInString,
  getDiscountAmountInStringForUpdate,
  getDiscountAmountInStringForUpdateDelete,
  getDiscountAmountPercentageUpdate,
  getDiscountPercentage,
  getGrandTotalAmountInString,
  getGrandTotalPayableAmount,
  getGrandTotalPayableAmountWithPercentage,
  getInvoiceDetailsInPdf,
  getInvoiceOfIPDDetails,
  getNameOfLineItem,
  getNetPayableAmount,
  getNetPayableAmountInString,
  getPaidAmount,
  getPayableForMembership,
  getPayableForMembershipForAdd,
  getPayableForMembershipForDelete,
  getPaymentType,
  getQuantityChangedLineItems,
  getQuantityOfLineItem,
  getRefundAmount,
  getRefundData,
  getRefundString,
  getResponsibleDoctor,
  getSplitPayment,
  getTotalOfLineItem,
  getTotalOfLineItemInString,
  isDischarged,
  isDischargeInitiated,
  updateAdvanceDetails,
  updateInvoiceDetailsToServer,
  updatePaymentDetails,
  updateSplitPayment,
  updateSplitPaymentStatus,
} from 'utils/fhirResoureHelpers/invoice_helper'
import {
  getIdentificationNumber,
  isOPD,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import {
  getMemberPlanId,
  getMemberShipAvailableCredit,
  getMemberShipAvailableCreditForSplit,
  getPlanNameFromCoverage,
} from 'utils/membershipUtils'
import {
  addLineItems,
  addPackageManualEntry,
  deleteLineItems,
  discountCalculationHelperFunction,
  isPackageManualEntry,
  modifyQuantityOfInvoice,
  updateInvoiceQuantityOverstay,
} from 'wello-web-components'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../common/deleteConfirmationDialog'
import { RefundDialog } from '../LeftMenu/refundDialog'
import { NoteWarningDialog } from '../LeftMenu/WarningDialog'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from '../LeftMenu/WelloSelectFHIRCoding'
import { PractitionerSingleSelector } from '../selectors/providers/pracitionerSelector'
import { PatientAndRoomDetailsOfIPDForInvoice } from './patientAndRoomDetailsOfIPDForInvoice'

interface Props {
  ipdDetails: FhirClinicIpdDetails
  open: boolean
  onClose: () => void
}
export const ViewBillingOfIPD: React.FC<Props> = ({
  ipdDetails,
  open,
  onClose,
}) => {
  const dispatch = useDispatch()
  let checkInDate = moment(
    ipdDetails.serviceRequest.occurrencePeriod?.start
  ).format('YYYY-MM-DD')

  const nightChargeDate = moment(
    ipdDetails.serviceRequest.occurrencePeriod?.start
  ).format('YYYY-MM-DD')

  if (ipdDetails.type === 'ipd') {
    checkInDate = moment(checkInDate).subtract(1, 'day').format('YYYY-MM-DD')
  }

  const checkOutDate = moment(
    moment(ipdDetails.serviceRequest.occurrencePeriod?.end).isAfter(moment())
      ? Date()
      : ipdDetails.serviceRequest.occurrencePeriod?.end
  ).format('YYYY-MM-DD')
  const completeIPDDetails = useSelector(
    (state: RootState) => state.ipdActiveAppointmentSlice.activeIPDActionDetails
  )
  const nightChargeSearchSlice = useSelector(
    (state: RootState) => state.nightPriceSearchSlice
  )

  const [subscriptionDetails, setSubscriptionDetails] = useState<{
    fetched: boolean
    subscribed?: boolean
    subscriptionCoverage?: R4.ICoverage
  }>({
    fetched: false,
  })

  const appointmentType = ipdDetails.serviceRequest.code
    ? ipdDetails.serviceRequest.code.coding
      ? ipdDetails.serviceRequest.code.coding[0].code ?? ''
      : ''
    : ''
  const ipdDayCare: boolean =
    appointmentType === '304903009' || appointmentType === '33022008'

  const [paymentDate, setPaymentDate] = useState<Date>()
  const [selectedPaymentType, setselectedPaymentType] = useState<R4.ICoding>()
  const [paymentReferance, setPaymentReferance] = useState<string>()
  const [doctorRef, setDoctorRef] = useState<string>()
  const [checked, setChecked] = useState<boolean>(false)
  const [checkedAdvance, setCheckedAdvance] = useState<boolean>(false)
  const [hide, setHide] = useState<boolean>(false)
  const [advanceCheck, setAdvanceCheck] = React.useState<boolean>()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [showFullPayment, setShowFullPayment] = React.useState<boolean>(false)
  const [paymentOptions, setPaymentOptions] =
    useState<R4.ICoding[]>(PaymentTypeCodes)

  const [enableSave, setEnableSave] = React.useState<boolean>(false)

  const [columns, setColumns] = useState<string[]>([])

  //   const [selectedPaymentType, setSelectedPaymentType] = useState<R4.ICoding>()

  const [paymentOptionsForAdvance, setPaymentOptionsForAdvance] =
    useState<R4.ICoding[]>(PaymentTypeCodes)

  const [advance, setAdvance] = React.useState<SplitPaymentDetails[]>([
    {
      id: 0,
      amount: 0,
      isDelete: false,
    },
  ])

  const [refund, setRefund] = React.useState<SplitPaymentDetails[]>([
    {
      id: 0,
      amount: 0,
      isDelete: false,
    },
  ])

  const [preAdvance, setPreAdvance] = React.useState<SplitPaymentDetails[]>([
    {
      id: 0,
      amount: 0,
      isDelete: false,
    },
  ])

  const [split, setSplit] = React.useState<SplitPaymentDetails[]>([
    {
      id: 0,
      amount: 0,
      Date: moment().toDate(),
      isDelete: false,
    },
  ])

  const detailsFetching = useSelector(
    (state: RootState) =>
      state.ipdActiveAppointmentSlice.fetchingAppointmentDetails
  )
  const [totalDiscount, setTotalDiscount] = useState<number>(0)
  const [previousVal, setPreviousVal] = useState<number>(0)

  const [totalDiscountInString, setTotalDiscountInString] = useState<number>(0)
  const [totalDiscountPercent, setTotalDiscountPercent] = useState<number>(0)
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })

  const [updatingStatus, setUpdatingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  const [discountInFocus, setDiscountInFocus] = useState<boolean>(false)
  const [deleteSplit, setDeleteSplit] = useState<boolean>(false)
  const [overStay, setOverStay] = useState<boolean>(false)
  const [overStayMessage, setOverStayMessage] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [overStayQty, setOverStayQty] = useState<number>(0)

  const [discountChangeFirst, setDiscountChangeFirst] = useState<boolean>(false)
  const [discountPercentageChangeFirst, setDiscountPercentageChangeFirst] =
    useState<boolean>(false)

  const [discountInFocusPercent, setDiscountInFocusPercent] =
    useState<boolean>(false)
  const [generatingStatus, setGeneratingStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })

  const [paymentUpdateStatus, setPaymentUpdateStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })

  const [invoiceDetails, setInvoiceDetails] =
    React.useState<BillingDetails | undefined>()

  const [lineItems, setLineItems] = React.useState<R4.IInvoice_LineItem[]>([])
  const [previousLineItems, setPreviousLineItems] = React.useState<
    R4.IInvoice_LineItem[]
  >([])

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [showDeleteConfirmationForSplit, setShowDeleteConfirmationForSplit] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [
    showDeleteConfirmationForAdvance,
    setShowDeleteConfirmationForAdvance,
  ] = useState<ConfirmationDialogProps>({
    open: false,
  })

  const [showQtyConfirmationForOverStay, setShowQtyConfirmationForOverStay] =
    useState<ConfirmationDialogProps>({
      open: false,
      title: 'Overstay Warning',
    })

  const handleAddMorePayment = () => {
    const values: SplitPaymentDetails[] = [...split]
    values.push({
      Date: new Date(),
      id: 1,
      amount: 0,
      isDelete: false,
    })
    setDeleteSplit(false)
    setSplit(values)
  }

  const handleAddMoreAdvance = () => {
    const values: SplitPaymentDetails[] = [...advance]
    values.push({
      id: 1,
      amount: 0,
      isDelete: false,
    })
    setAdvance(values)
  }

  const handleAddMore = () => {
    setShowText(false)
    setEnableSave(false)
    const values: R4.IInvoice_LineItem[] = [...lineItems]
    const indexNumber: number | undefined = values[values.length - 1].sequence
    values.push({
      id: getUniqueTempId(),
      sequence: indexNumber ? indexNumber + 1 : 0,
      extension: [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/invoice-time',
          valueDateTime: new Date().toISOString(),
        },
      ],
      priceComponent: [
        {
          factor: 1,
          type: R4.Invoice_PriceComponentTypeKind._base,
          amount: {
            currency: 'INR',
            value: 0,
          },
        },
      ],
    })
    setLineItems(values)
    if (invoiceDetails) {
      const oldInvoiceDetails: BillingDetails = invoiceDetails
      const invoice: R4.IInvoice = addLineItems(invoiceDetails.invoice, [
        {
          id: getUniqueTempId(),
          sequence: indexNumber ? indexNumber + 1 : 0,
          extension: [
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/invoice-time',
              valueDateTime: new Date().toISOString(),
            },
          ],
          priceComponent: [
            {
              factor: 1,
              type: R4.Invoice_PriceComponentTypeKind._base,
              amount: {
                currency: 'INR',
                value: 0,
              },
            },
          ],
        },
      ])
      setInvoiceDetails({
        id: oldInvoiceDetails.id,
        invoice,
        paymentReconciliation: oldInvoiceDetails.paymentReconciliation,
      })
    }
  }

  function deleteItemSplit(id: number) {
    const currentItems: SplitPaymentDetails[] = [...split]

    if (
      currentItems[id].paymentType &&
      currentItems[id].paymentType?.code === 'membership'
    ) {
      if (columns.includes('membership')) setColumns([])
      currentItems.splice(id, 1)
    } else {
      setColumns([])
      currentItems.splice(id, 1)
    }
    for (let k = 0; k < currentItems.length; k++) {
      const paymentTypeData: R4.ICoding | undefined =
        currentItems[k].paymentType

      if (paymentTypeData && paymentTypeData.code === 'membership') {
        if (
          getMemberShipAvailableCredit(
            subscriptionDetails.subscriptionCoverage!
          )! >=
          getPayableForMembershipForAdd(
            lineItems,
            0,
            0,
            0,
            advance,
            currentItems
          )
        ) {
          currentItems[k].amount = getPayableForMembershipForAdd(
            lineItems,
            0,
            0,
            0,
            advance,
            currentItems
          )
          currentItems[k].Date = currentItems[k].Date
          currentItems[k].paymentType = {
            code: 'membership',
            display: 'Membership',
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-type-cs',
          }
        } else if (
          getMemberShipAvailableCreditForSplit(
            subscriptionDetails.subscriptionCoverage!,
            currentItems
          ) > 0
        ) {
          currentItems[k].Date = currentItems[k].Date
          currentItems[k].paymentType = {
            code: 'membership',
            display: 'Membership',
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-type-cs',
          }
          currentItems[k].amount =
            getPayableForMembershipForAdd(
              lineItems,
              0,
              0,
              0,
              advance,
              currentItems
            ) +
            (getMemberShipAvailableCredit(
              subscriptionDetails.subscriptionCoverage!
            ) -
              getPayableForMembershipForAdd(
                lineItems,
                0,
                0,
                0,
                advance,
                currentItems
              ))
        }
      }
    }
    setDeleteSplit(true)
    setSplit(currentItems)
  }

  function deleteAdvance(id: number) {
    const currentItems: SplitPaymentDetails[] = [...advance]
    currentItems.splice(id, 1)
    setAdvance(currentItems)
  }

  function deleteItem(i: number, night_charge: boolean) {
    const currentItems = [...lineItems]

    if (lineItems[i].id === undefined) {
      deleteLineItem(lineItems[i])
    } else {
      if (lineItems[i].chargeItemCodeableConcept) {
        const data: R4.ICodeableConcept | undefined =
          lineItems[i].chargeItemCodeableConcept
        if (data) {
          const nightData = data.coding ? data.coding[0].code ?? '' : ''
          {
            if (nightData.length > 0 && nightData === 'night_charge') {
              const splitValues: SplitPaymentDetails[] = [...split]
              const afterAddLines: R4.IInvoice_LineItem[] = [...lineItems]
              for (let j = 0; j < splitValues.length; j++) {
                if (
                  splitValues[j].paymentType &&
                  splitValues[j].paymentType?.code === 'membership'
                ) {
                  if (
                    getMemberShipAvailableCredit(
                      subscriptionDetails.subscriptionCoverage!
                    )! >=
                    getPayableForMembershipForDelete(
                      afterAddLines,
                      0,
                      0,
                      nightChargeSearchSlice.availablePrice,
                      advance,
                      splitValues
                    )
                  ) {
                    splitValues[j].amount = getPayableForMembershipForDelete(
                      afterAddLines,
                      0,
                      0,
                      nightChargeSearchSlice.availablePrice,
                      advance,
                      splitValues
                    )
                  }
                }
              }
              setSplit(splitValues)
            }
            setChecked(false)
          }
        }
      }

      if (invoiceDetails) {
        const oldInvoiceDetails: BillingDetails = invoiceDetails
        let invoice: R4.IInvoice = { ...oldInvoiceDetails.invoice }
        const data: R4.ICodeableConcept | undefined =
          lineItems[i].chargeItemCodeableConcept
        if (data) {
          const nightData = data.coding ? data.coding[0].code ?? '' : ''
          if (nightData.length > 0 && nightData === 'night_charge') {
            invoice = deleteLineItemsForHelper(invoice, currentItems[i])
          } else {
            invoice = deleteLineItems(invoice, [currentItems[i]])
          }
        } else {
          invoice = deleteLineItemsForHelper(invoice, currentItems[i])
        }
        setEnableSave(true)
        currentItems.splice(i, 1)
        if (selectedPaymentType && selectedPaymentType.code === 'percent') {
          setTotalDiscount(
            getDiscountAmountInStringForUpdateDelete(
              getGrandTotalPayableAmount(currentItems),
              0,
              totalDiscountPercent
            )
          )

          invoice = changeCodingForDiscount(invoice, 'percent')
        }
        if (selectedPaymentType && selectedPaymentType.code === 'amount') {
          setTotalDiscountPercent(
            getDiscountAmountPercentageUpdate(
              getGrandTotalPayableAmount(currentItems),
              night_charge ? 0 : nightChargeSearchSlice.availablePrice,
              totalDiscount
            )
          )

          invoice = changeCodingForDiscount(invoice, 'amount')
        }

        setInvoiceDetails({
          id: oldInvoiceDetails.id,
          invoice,
          paymentReconciliation: oldInvoiceDetails.paymentReconciliation,
        })
      }

      setLineItems(currentItems)
    }
  }

  function fetchInvoice() {
    if (
      completeIPDDetails?.mainEncounter &&
      completeIPDDetails?.mainEncounter.account &&
      completeIPDDetails?.mainEncounter.account.length > 0
    )
      setFetchingStatus({
        requesting: true,
      })
    getInvoiceOfIPDDetails(
      completeIPDDetails?.mainEncounter!.account![0].reference!.split('/')[1]!
    )
      .then((e) => {
        if ('invoice' in e) {
          const invoiceData: R4.IInvoice = calculateOverStay(e.invoice)
          const invoiceDetailsData: BillingDetails = {
            id: e.id,
            paymentReconciliation: e.paymentReconciliation,
            invoice: invoiceData,
          }

          setInvoiceDetails(invoiceDetailsData)
          if (
            getPaymentType(
              (invoiceDetailsData as BillingDetails).invoice.lineItem ?? []
            )
          ) {
            setselectedPaymentType(
              getPaymentType(
                (invoiceDetailsData as BillingDetails).invoice.lineItem ?? []
              )
            )
          }

          if (getAdVanceData(invoiceDetailsData).length > 0) {
            setAdvance(getAdVanceData(invoiceDetailsData))
            setPreAdvance(getAdVanceData(invoiceDetailsData))
            setAdvanceCheck(getAdVanceDataCheck(invoiceDetailsData))
            setCheckedAdvance(getAdVanceDataCheck(invoiceDetailsData))
          } else if (isDischargeInitiated(ipdDetails.task!)) {
            setHide(true)
          }

          if (getResponsibleDoctor(invoiceDetailsData)) {
            setDoctorRef(getResponsibleDoctor(invoiceDetailsData))
          }

          if (getSplitPayment(invoiceDetailsData).length > 0) {
            setSplit(getSplitPayment(invoiceDetailsData))
          }

          if (getRefundData(invoiceDetailsData).length > 0) {
            setRefund(getRefundData(invoiceDetailsData))
          }

          setLineItems([
            ...((invoiceDetailsData as BillingDetails).invoice.lineItem ?? []),
          ])

          setPreviousLineItems([
            ...((invoiceDetailsData as BillingDetails).invoice.lineItem ?? []),
          ])
          if (
            ((invoiceDetailsData as BillingDetails).invoice.lineItem ?? [])
              .length > 0
          ) {
            for (
              let i = 0;
              i <
              ((invoiceDetailsData as BillingDetails).invoice.lineItem ?? [])
                .length;
              i++
            ) {
              if (
                getNameOfLineItem(
                  ((invoiceDetailsData as BillingDetails).invoice.lineItem ??
                    [])[i]
                ) === 'Night Charges. Includes Food and Accommodation'
              ) {
                if (checked === false) {
                  setChecked(true)
                }
              }
            }
          }

          setTotalDiscount(
            getDiscountAmountInString(
              (invoiceDetailsData as BillingDetails).invoice.lineItem ?? []
            )
          )

          setTotalDiscountPercent(
            getDiscountAmountForInitialData(
              (invoiceDetailsData as BillingDetails).invoice.lineItem ?? []
            )
          )

          setFetchingStatus({
            requesting: false,
            requestSuccess: true,
          })
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  function calculateOverStay(invoice: R4.IInvoice): R4.IInvoice {
    const actualDays = getDateDifferceinDays(
      moment().toISOString(),
      ipdDetails.serviceRequest.occurrencePeriod?.start
    ) // call helper function
    const days = getDateDifferceinDays(
      ipdDetails.serviceRequest.occurrencePeriod?.end,
      ipdDetails.serviceRequest.occurrencePeriod?.start
    ) // call helper function
    const overStaying = actualDays > days
    if (
      overStaying &&
      !isOPD(ipdDetails) &&
      !isDischargeInitiated(ipdDetails.task!) &&
      !isPackageManualEntry(invoice)
    ) {
      invoice = updateInvoiceQuantityOverstay(
        days,
        actualDays,
        ipdDetails.serviceRequest,
        invoice,
        ipdDetails.task!
      )
      setOverStayQty(actualDays)
      setMessage(
        `Package quantity changed to ${actualDays} due to overstaying, If you want you can modify it`
      )
      setEnableSave(true)
    } else if (
      overStaying &&
      !isOPD(ipdDetails) &&
      !isDischargeInitiated(ipdDetails.task!) &&
      isPackageManualEntry(invoice)
    ) {
      if (invoice.lineItem && invoice.lineItem.length > 0) {
        if (
          getQuantityOfLineItem(invoice.lineItem[0]) > actualDays ||
          getQuantityOfLineItem(invoice.lineItem[0]) < actualDays
        ) {
          setOverStayQty(actualDays)
          setMessage(
            `Package quantity changed to ${actualDays} due to overstaying, If you want you can modify it`
          )
        }
      }
    }
    return invoice
  }

  function resetDetails() {
    setselectedPaymentType(undefined)
  }

  function updateBasePriceComponentForBlur(
    i: number,
    basePrice: R4.IInvoice_PriceComponent,
    name?: string,
    date?: string,
    nameChanged?: boolean,
    priceComp?: R4.IInvoice_PriceComponent,
    attendantLineItem?: R4.IInvoice_PriceComponent,
    packagePriceComp?: R4.IInvoice_PriceComponent
  ) {
    const currentLineItems = [...previousLineItems]
    const currentLineTime = currentLineItems[i]
    const extraLineItem = currentLineItems[i + 1]
    const packageItem = currentLineItems[i - 1]
    const dateExt: R4.IExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/invoice-time',
      valueDateTime: date ?? getDateOfLineItem(currentLineTime),
    }
    if (
      name !==
      'Package charges including naturopathy, ayurvedic treatment, yoga therapy, diet therapy and accomodation'
    ) {
      const updateLineItem: R4.IInvoice_LineItem = {
        ...currentLineTime,
        extension: [dateExt],
        chargeItemCodeableConcept: {
          text: name ?? '',
          coding: [
            {
              system:
                name === 'Night Charges. Includes Food and Accommodation'
                  ? 'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-codes-cs'
                  : 'http://terminology.hl7.org/CodeSystem/v3-NullFlavor',
              code:
                name === 'Night Charges. Includes Food and Accommodation'
                  ? 'night_charge'
                  : 'UNK',
              display:
                name === 'Night Charges. Includes Food and Accommodation'
                  ? 'Night Charge'
                  : 'unknown',
            },
          ],
        },
        priceComponent: [basePrice],
      }
      currentLineItems[i] = updateLineItem
      if (name === 'Attendant Charges') {
        if (priceComp && packagePriceComp) {
          const updateLineItemForPackage: R4.IInvoice_LineItem = {
            ...packageItem,
            extension: [dateExt],
            priceComponent: [priceComp, packagePriceComp],
          }
          currentLineItems[i - 1] = updateLineItemForPackage
        }
      }
    } else if (priceComp) {
      const updateLineItemForPackage: R4.IInvoice_LineItem = {
        ...currentLineTime,
        extension: [dateExt],
        priceComponent: [basePrice, priceComp],
      }
      currentLineItems[i] = updateLineItemForPackage
      if (attendantLineItem) {
        const updateLineItemForAttendant: R4.IInvoice_LineItem = {
          ...extraLineItem,
          extension: [dateExt],
          priceComponent: [attendantLineItem],
        }
        currentLineItems[i + 1] = updateLineItemForAttendant
      }
    }

    setPreviousLineItems(currentLineItems)

    if (invoiceDetails) {
      const oldInvoiceDetails: BillingDetails = invoiceDetails

      let invoice: R4.IInvoice = { ...oldInvoiceDetails.invoice }
      invoice.lineItem = currentLineItems

      invoice = modifyQuantityOfInvoice(invoice)
      if (
        name ===
          'Package charges including naturopathy, ayurvedic treatment, yoga therapy, diet therapy and accomodation' ||
        name === 'Attendant Charges'
      ) {
        addPackageManualEntry(invoice)
      }
      //   setEnableSave(true)
    }
  }

  function updateBasePriceComponent(
    i: number,
    basePrice: R4.IInvoice_PriceComponent,
    name?: string,
    date?: string,
    nameChanged?: boolean,
    priceComp?: R4.IInvoice_PriceComponent,
    attendantLineItem?: R4.IInvoice_PriceComponent,
    packagePriceComp?: R4.IInvoice_PriceComponent
  ) {
    const currentLineItems = [...lineItems]
    const currentLineTime = currentLineItems[i]
    const extraLineItem = currentLineItems[i + 1]
    const packageItem = currentLineItems[i - 1]
    const dateExt: R4.IExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/invoice-time',
      valueDateTime: date ?? getDateOfLineItem(currentLineTime),
    }
    if (
      name !==
      'Package charges including naturopathy, ayurvedic treatment, yoga therapy, diet therapy and accomodation'
    ) {
      const updateLineItem: R4.IInvoice_LineItem = {
        ...currentLineTime,
        extension: [dateExt],
        chargeItemCodeableConcept: {
          text: name ?? '',
          coding: [
            {
              system:
                name === 'Night Charges. Includes Food and Accommodation'
                  ? 'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-codes-cs'
                  : 'http://terminology.hl7.org/CodeSystem/v3-NullFlavor',
              code:
                name === 'Night Charges. Includes Food and Accommodation'
                  ? 'night_charge'
                  : 'UNK',
              display:
                name === 'Night Charges. Includes Food and Accommodation'
                  ? 'Night Charge'
                  : 'unknown',
            },
          ],
        },
        priceComponent: [basePrice],
      }
      currentLineItems[i] = updateLineItem
      if (name === 'Attendant Charges') {
        if (priceComp && packagePriceComp) {
          const updateLineItemForPackage: R4.IInvoice_LineItem = {
            ...packageItem,
            extension: [dateExt],
            priceComponent: [priceComp, packagePriceComp],
          }
          currentLineItems[i - 1] = updateLineItemForPackage
        }
      }
    } else if (priceComp) {
      const updateLineItemForPackage: R4.IInvoice_LineItem = {
        ...currentLineTime,
        extension: [dateExt],
        priceComponent: [basePrice, priceComp],
      }
      currentLineItems[i] = updateLineItemForPackage
      if (attendantLineItem) {
        const updateLineItemForAttendant: R4.IInvoice_LineItem = {
          ...extraLineItem,
          extension: [dateExt],
          priceComponent: [attendantLineItem],
        }
        currentLineItems[i + 1] = updateLineItemForAttendant
      }
    }

    setLineItems(currentLineItems)

    if (invoiceDetails) {
      const oldInvoiceDetails: BillingDetails = invoiceDetails

      let invoice: R4.IInvoice = { ...oldInvoiceDetails.invoice }
      invoice.lineItem = currentLineItems

      invoice = modifyQuantityOfInvoice(invoice)
      if (
        name ===
          'Package charges including naturopathy, ayurvedic treatment, yoga therapy, diet therapy and accomodation' ||
        name === 'Attendant Charges'
      ) {
        addPackageManualEntry(invoice)
      }
      setEnableSave(true)

      if (nameChanged === false || nameChanged === undefined) {
        if (selectedPaymentType && selectedPaymentType.code === 'percent') {
          setTotalDiscount(
            getDiscountAmountInStringForUpdate(
              getGrandTotalPayableAmount(currentLineItems),
              0,
              totalDiscountPercent
            )
          )
          //   if (invoiceDetails) {
          //     invoice = discountCalculationHelperFunction(
          //       invoice,
          //       getDiscountAmountInStringForUpdate(
          //         getGrandTotalPayableAmount(currentLineItems),
          //         0,
          //         totalDiscountPercent
          //       ),
          //       undefined
          //     )
          //   }
          invoice = changeCodingForDiscount(invoice, 'percent')
        }
        if (selectedPaymentType && selectedPaymentType.code === 'amount') {
          setTotalDiscountPercent(
            getDiscountAmountPercentageUpdate(
              getGrandTotalPayableAmount(currentLineItems),
              0,
              totalDiscount
            )
          )

          //   if (invoiceDetails) {
          //     invoice = discountCalculationHelperFunction(
          //       invoice,
          //       undefined,
          //       getDiscountAmountPercentageUpdate(
          //         getGrandTotalPayableAmount(currentLineItems),
          //         0,
          //         totalDiscount
          //       )
          //     )
          //   }
          invoice = changeCodingForDiscount(invoice, 'amount')
        }
      }

      setInvoiceDetails({
        id: oldInvoiceDetails.id,
        invoice,
        paymentReconciliation: oldInvoiceDetails.paymentReconciliation,
      })
    }

    const splitValues: SplitPaymentDetails[] = [...split]
    for (let k = 0; k < splitValues.length; k++) {
      const paymentTypeData: R4.ICoding | undefined = splitValues[k].paymentType

      if (paymentTypeData && paymentTypeData.code === 'membership') {
        if (
          getMemberShipAvailableCredit(
            subscriptionDetails.subscriptionCoverage!
          )! >=
          getPayableForMembershipForAdd(
            currentLineItems,
            0,
            0,
            0,
            advance,
            splitValues
          )
        ) {
          splitValues[k].amount = getPayableForMembershipForAdd(
            currentLineItems,
            0,
            0,
            0,
            advance,
            splitValues
          )
        } else if (
          getMemberShipAvailableCreditForSplit(
            subscriptionDetails.subscriptionCoverage!,
            splitValues
          ) > 0
        ) {
          splitValues[k].amount =
            getPayableForMembershipForAdd(
              currentLineItems,
              0,
              0,
              0,
              advance,
              splitValues
            ) +
            (getMemberShipAvailableCredit(
              subscriptionDetails.subscriptionCoverage!
            ) -
              getPayableForMembershipForAdd(
                currentLineItems,
                0,
                0,
                0,
                advance,
                splitValues
              ))
        }
      }
    }

    setSplit(splitValues)
  }

  function getPriceCompOfLineItem(lineItem: R4.IInvoice_LineItem) {
    let priceComponent: R4.IInvoice_PriceComponent = {
      factor: 0,
      type: R4.Invoice_PriceComponentTypeKind._base,
      amount: {
        currency: 'INR',
        value: 0,
      },
    }
    if (
      lineItem.priceComponent !== undefined &&
      lineItem.priceComponent!.length > 0
    ) {
      const priceCompIndex: number =
        lineItem.priceComponent?.findIndex((e) => e.type === 'base') ?? -1
      if (priceCompIndex >= 0) {
        priceComponent = { ...lineItem.priceComponent![priceCompIndex] }
      }
    }
    return priceComponent
  }

  function updateNameOfLineItem(i: number, name: string) {
    const priceComponent = getPriceCompOfLineItem(lineItems[i])
    updateBasePriceComponent(i, priceComponent, name, undefined, true)
  }

  function updateDateOfLineItem(i: number, date: string) {
    const priceComponent = getPriceCompOfLineItem(lineItems[i])
    const name = getNameOfLineItem(lineItems[i])
    updateBasePriceComponent(i, priceComponent, name, date)
  }

  function updateAmountOfLineItem(i: number, val: number) {
    const priceComponent = getPriceCompOfLineItem(lineItems[i])
    priceComponent.amount = {
      ...priceComponent.amount,
      value: Number(val.toFixed(2)),
    }

    const name = getNameOfLineItem(lineItems[i])
    updateBasePriceComponent(i, priceComponent, name)
  }

  function updateFactorOfLineItemOnBlur(i: number, val: number) {
    const priceComponent = getPriceCompOfLineItem(lineItems[i])

    const name = getNameOfLineItem(lineItems[i])
    if (
      name ===
      'Package charges including naturopathy, ayurvedic treatment, yoga therapy, diet therapy and accomodation'
    ) {
      if (overStayQty > 0 && (val < overStayQty || val > overStayQty)) {
        setShowQtyConfirmationForOverStay({
          open: true,
          title: 'Overstay Warning',
          onContinueActionClicked: () => {
            priceComponent.factor = val
            const priceCompData: R4.IInvoice_PriceComponent[] =
              lineItems[i].priceComponent ?? []

            if (priceCompData && priceCompData.length > 0) {
              if (lineItems.length > 1) {
                const attendantLineItemName = getNameOfLineItem(
                  lineItems[i + 1]
                )
                if (attendantLineItemName.includes('Attendant Charges')) {
                  const priceComponentForAttendant = getPriceCompOfLineItem(
                    lineItems[i + 1]
                  )

                  priceComponentForAttendant.factor = val

                  updateBasePriceComponent(
                    i,
                    priceComponent,
                    name,
                    undefined,
                    false,
                    priceCompData[1],
                    priceComponentForAttendant
                  )

                  updateBasePriceComponentForBlur(
                    i,
                    priceComponent,
                    name,
                    undefined,
                    false,
                    priceCompData[1],
                    priceComponentForAttendant
                  )
                }
              } else {
                updateBasePriceComponent(
                  i,
                  priceComponent,
                  name,
                  undefined,
                  false,
                  priceCompData[1]
                )
                updateBasePriceComponentForBlur(
                  i,
                  priceComponent,
                  name,
                  undefined,
                  false,
                  priceCompData[1]
                )
              }
            }
            setShowQtyConfirmationForOverStay({ open: false })
          },
          onCancelActionClicked: () => {
            const newVal =
              getQuantityOfLineItem(lineItems[i]) < overStayQty
                ? getQuantityOfLineItem(lineItems[i]) + 1
                : getQuantityOfLineItem(lineItems[i]) - 1

            priceComponent.factor = getQuantityOfLineItem(previousLineItems[i])
            const priceCompData: R4.IInvoice_PriceComponent[] =
              lineItems[i].priceComponent ?? []

            if (priceCompData && priceCompData.length > 0) {
              if (lineItems.length > 1) {
                const attendantLineItemName = getNameOfLineItem(
                  lineItems[i + 1]
                )
                if (attendantLineItemName.includes('Attendant Charges')) {
                  const priceComponentForAttendant = getPriceCompOfLineItem(
                    lineItems[i + 1]
                  )

                  priceComponentForAttendant.factor = getQuantityOfLineItem(
                    previousLineItems[i]
                  )
                  updateBasePriceComponent(
                    i,
                    priceComponent,
                    name,
                    undefined,
                    false,
                    priceCompData[1],
                    priceComponentForAttendant
                  )
                }
              } else {
                updateBasePriceComponent(
                  i,
                  priceComponent,
                  name,
                  undefined,
                  false,
                  priceCompData[1]
                )
              }
            }
            setShowQtyConfirmationForOverStay({ open: false })
          },
          displayText: `Package charges are applicable for ${overStayQty} days. Do you want to change the no. of days ?`,
          continueActionButtonText: 'Confirm',
          cancelActionButtonText: 'Cancel',
        })
      } else if (overStayQty > 0 && val === overStayQty) {
        priceComponent.factor = val
        const priceCompData: R4.IInvoice_PriceComponent[] =
          lineItems[i].priceComponent ?? []

        if (priceCompData && priceCompData.length > 0) {
          if (lineItems.length > 1) {
            const attendantLineItemName = getNameOfLineItem(lineItems[i + 1])
            if (attendantLineItemName.includes('Attendant Charges')) {
              const priceComponentForAttendant = getPriceCompOfLineItem(
                lineItems[i + 1]
              )

              priceComponentForAttendant.factor = val
              updateBasePriceComponent(
                i,
                priceComponent,
                name,
                undefined,
                false,
                priceCompData[1],
                priceComponentForAttendant
              )
            }
          } else {
            updateBasePriceComponent(
              i,
              priceComponent,
              name,
              undefined,
              false,
              priceCompData[1]
            )
          }
        }
      }
      priceComponent.factor = val

      const priceCompData: R4.IInvoice_PriceComponent[] =
        lineItems[i].priceComponent ?? []

      if (priceCompData && priceCompData.length > 0) {
        if (lineItems.length > 1) {
          const attendantLineItemName = getNameOfLineItem(lineItems[i + 1])
          if (attendantLineItemName.includes('Attendant Charges')) {
            const priceComponentForAttendant = getPriceCompOfLineItem(
              lineItems[i + 1]
            )

            priceComponentForAttendant.factor = val
            updateBasePriceComponent(
              i,
              priceComponent,
              name,
              undefined,
              false,
              priceCompData[1],
              priceComponentForAttendant
            )
          } else {
            updateBasePriceComponent(
              i,
              priceComponent,
              name,
              undefined,
              false,
              priceCompData[1]
            )
          }
        } else {
          updateBasePriceComponent(
            i,
            priceComponent,
            name,
            undefined,
            false,
            priceCompData[1]
          )
        }
      }
    } else if (name === 'Attendant Charges') {
      priceComponent.factor = val
      const priceCompData: R4.IInvoice_PriceComponent[] =
        lineItems[i - 1].priceComponent ?? []
      const priceComponentForPackage = getPriceCompOfLineItem(lineItems[i - 1])
      priceComponentForPackage.factor = val
      updateBasePriceComponent(
        i,
        priceComponent,
        name,
        undefined,
        false,
        priceComponentForPackage,
        undefined,
        priceCompData[1]
      )
    } else {
      priceComponent.factor = val
      updateBasePriceComponent(i, priceComponent, name, undefined, false)
    }
  }

  function updateFactorOfLineItem(i: number, val: number) {
    const priceComponent = getPriceCompOfLineItem(lineItems[i])

    const name = getNameOfLineItem(lineItems[i])
    if (
      name ===
      'Package charges including naturopathy, ayurvedic treatment, yoga therapy, diet therapy and accomodation'
    ) {
      setPreviousVal(getQuantityOfLineItem(lineItems[i]))
      if (overStayQty > 0 && val === overStayQty) {
        priceComponent.factor = val
        const priceCompData: R4.IInvoice_PriceComponent[] =
          lineItems[i].priceComponent ?? []

        if (priceCompData && priceCompData.length > 0) {
          if (lineItems.length > 1) {
            const attendantLineItemName = getNameOfLineItem(lineItems[i + 1])
            if (attendantLineItemName.includes('Attendant Charges')) {
              const priceComponentForAttendant = getPriceCompOfLineItem(
                lineItems[i + 1]
              )

              priceComponentForAttendant.factor = val
              updateBasePriceComponent(
                i,
                priceComponent,
                name,
                undefined,
                false,
                priceCompData[1],
                priceComponentForAttendant
              )
            }
          } else {
            updateBasePriceComponent(
              i,
              priceComponent,
              name,
              undefined,
              false,
              priceCompData[1]
            )
          }
        }
      }
      priceComponent.factor = val

      const priceCompData: R4.IInvoice_PriceComponent[] =
        lineItems[i].priceComponent ?? []

      if (priceCompData && priceCompData.length > 0) {
        if (lineItems.length > 1) {
          const attendantLineItemName = getNameOfLineItem(lineItems[i + 1])
          if (attendantLineItemName.includes('Attendant Charges')) {
            const priceComponentForAttendant = getPriceCompOfLineItem(
              lineItems[i + 1]
            )

            priceComponentForAttendant.factor = val
            updateBasePriceComponent(
              i,
              priceComponent,
              name,
              undefined,
              false,
              priceCompData[1],
              priceComponentForAttendant
            )
          } else {
            updateBasePriceComponent(
              i,
              priceComponent,
              name,
              undefined,
              false,
              priceCompData[1]
            )
          }
        } else {
          updateBasePriceComponent(
            i,
            priceComponent,
            name,
            undefined,
            false,
            priceCompData[1]
          )
        }
      }
    } else if (name === 'Attendant Charges') {
      priceComponent.factor = val
      const priceCompData: R4.IInvoice_PriceComponent[] =
        lineItems[i - 1].priceComponent ?? []
      const priceComponentForPackage = getPriceCompOfLineItem(lineItems[i - 1])
      priceComponentForPackage.factor = val
      updateBasePriceComponent(
        i,
        priceComponent,
        name,
        undefined,
        false,
        priceComponentForPackage,
        undefined,
        priceCompData[1]
      )
    } else {
      priceComponent.factor = val
      updateBasePriceComponent(i, priceComponent, name, undefined, false)
    }
  }

  function isAdvancePaymentSaveEnabled() {
    let isValid: boolean = true
    const advanceDetails: SplitPaymentDetails[] = [...advance].filter(
      (e) =>
        e.amount &&
        e.amount > 0 &&
        e.Date &&
        e.paymentType &&
        e.isDelete === false
    )

    if (advanceDetails.length === 0) {
      isValid = false
    }
    if (advanceDetails.length > 0) {
      const unique = advanceDetails.filter(
        (obj, index) =>
          advanceDetails.findIndex(
            (item) =>
              item.paymentReference &&
              obj.paymentReference &&
              item.paymentReference.trim() === obj.paymentReference.trim()
          ) === index
      )
      if (unique.length > 0) {
        isValid = true
      } else {
        isValid = false
      }
    }
    return isValid
  }

  function checkForSplit() {
    const splitPaymentDetails: SplitPaymentDetails[] = [...split].filter(
      (e) =>
        e.amount &&
        e.amount > 0 &&
        e.Date &&
        e.paymentType &&
        e.paymentReference &&
        e.paymentReference.trim().length > 0 &&
        e.isDelete === false
    )
    if (splitPaymentDetails.length > 0) {
      return true
    }

    return false
  }

  function checkForAdvance() {
    const splitPaymentDetails: SplitPaymentDetails[] = [...advance].filter(
      (e) =>
        e.amount &&
        e.amount > 0 &&
        e.Date &&
        e.paymentType &&
        e.paymentReference &&
        e.paymentReference.trim().length > 0 &&
        e.isDelete === false
    )
    if (splitPaymentDetails.length > 0) {
      return true
    }

    return false
  }

  function isSplitButtonPaymentEnabled() {
    let isValid: boolean = false

    const splitPaymentDetails: SplitPaymentDetails[] = [...split].filter(
      (e) => e.amount && e.amount > 0 && e.Date && e.paymentType
    )

    if (splitPaymentDetails.length === 0) {
      isValid = false
    }
    if (splitPaymentDetails.length > 0) {
      const unique = splitPaymentDetails.filter(
        (obj, index) =>
          splitPaymentDetails.findIndex(
            (item) =>
              item.paymentReference &&
              obj.paymentReference &&
              item.paymentReference.trim() === obj.paymentReference.trim()
          ) === index
      )
      if (unique.length > 0) {
        isValid = true
      } else {
        isValid = false
      }
    }
    if (split.length > 0) {
      const emptyRef: string[] = []
      for (let i = 0; i < split.length; i++) {
        if (
          split[i].paymentReference === undefined ||
          split[i].paymentReference?.length === 0 ||
          split[i].Date === undefined
        ) {
          emptyRef.push('1')
        }
      }
      if (emptyRef.length > 0) {
        isValid = false
      } else {
        isValid = true
      }
    }

    return isValid
  }

  function isDataChanged(): boolean {
    if (
      getDiscountAmountInString(invoiceDetails?.invoice.lineItem ?? []) !==
      totalDiscount
    ) {
      return true
    }
    if (checkedAdvance && isAdvancePaymentSaveEnabled() === true) {
      return true
    }

    if (enableSave) {
      return true
    }

    if (invoiceDetails) {
      if (
        getQuantityChangedLineItems(invoiceDetails.invoice!, lineItems).length >
        0
      ) {
        return true
      }
    }

    return false
  }

  function isLineItemNameEmpty() {
    let hasNewLine1: boolean = false
    lineItems.forEach((e) => {
      const name = getNameOfLineItem(e)
      if (name.length === 0) {
        hasNewLine1 = true
      }
    })
    if (hasNewLine1) {
      return true
    }
    return hasNewLine1
  }

  function isLineItemPriceEmpty() {
    let hasNewLine: boolean = false
    lineItems.forEach((e) => {
      const basePrice = getBasePriceOfLineItem(e)
      if (basePrice === 0) {
        hasNewLine = true
      }
    })

    if (hasNewLine) {
      return true
    }
    return hasNewLine
  }

  function isEditionAllowed() {
    if (
      ipdDetails.serviceRequest.status === 'completed' ||
      ipdDetails.serviceRequest.status === 'cancelled'
    ) {
      return false
    }
    return true
  }

  function checkAddedItems() {
    let isValid: boolean = true
    const listItems: IInvoice_LineItem[] = [...lineItems].filter(
      (e) => e.id && e.id.length > 0
    )
    if (listItems.length === 0) {
      isValid = false
      return isValid
    }
    listItems.forEach((e) => {
      if (getTotalOfLineItem(e) <= 0) {
        isValid = false
      }
      if (getNameOfLineItem(e).trim().length === 0) {
        isValid = false
      }
    })
    if (!isValid)
      dispatch(
        showErrorAlert('Looks like Price/Quantity/Name of Item is empty')
      )

    return isValid
  }

  function isValidChangedData() {
    let isValid: boolean = true
    const listItems: IInvoice_LineItem[] = [...lineItems].filter(
      (e) => e.id && e.id.length > 0
    )
    if (listItems.length > 0) {
      listItems.forEach((e) => {
        if (getTotalOfLineItem(e) <= 0) {
          isValid = false
        }
        if (getNameOfLineItem(e).trim().length === 0) {
          isValid = false
        }
      })
    }
    return isValid
  }

  function handleUpdateButton(forSave: boolean) {
    setUpdatingStatus({
      requesting: true,
    })

    updateDetailsToServer(forSave).then((e) => {
      if (e) {
        setUpdatingStatus({
          requesting: false,
          requestSuccess: true,
        })
        fetchInvoice()
      } else {
        setUpdatingStatus({
          requesting: false,
          requestSuccess: true,
        })
      }
    })
  }

  function deleteLineItem(item: R4.IInvoice_LineItem) {
    setUpdatingStatus({
      requesting: true,
    })

    deleteLineItemsInServer([item], ipdDetails.serviceRequest.id ?? '').then(
      (e) => {
        if (e) {
          if (item.chargeItemCodeableConcept) {
            const data: R4.ICodeableConcept | undefined =
              item.chargeItemCodeableConcept
            if (data) {
              const nightData = data.coding ? data.coding[0].code ?? '' : ''
              {
                if (nightData.length > 0 && nightData === 'night_charge')
                  setChecked(false)
              }
            }
          }
          setUpdatingStatus({
            requesting: false,
            requestSuccess: true,
          })
          fetchInvoice()
        } else {
          setUpdatingStatus({
            requesting: false,
            requestSuccess: true,
          })
        }
      }
    )
  }

  async function handlePaymentDetails() {
    let continuePaymentDetails = true

    setPaymentUpdateStatus({
      requesting: true,
    })

    if (isDataChanged()) {
      try {
        continuePaymentDetails = await updateDetailsToServer(false)
      } catch (error) {
        logger.error('Error in Updating Bill', error)
      }

      if (continuePaymentDetails === false) {
        setPaymentUpdateStatus({
          requesting: false,
          requestError: true,
        })
        dispatch(
          showErrorAlert('Error while updating bills , Please try later')
        )
      }
    }

    if (continuePaymentDetails) {
      updateSplitPaymentStatus(
        split,
        ipdDetails.serviceRequest.id ?? '',
        doctorRef!,
        ipdDayCare,
        refund,
        getRefundAmount(lineItems, totalDiscount, advance, split) > 0,
        getRefundAmount(lineItems, totalDiscount, advance, split),
        subscriptionDetails!.subscriptionCoverage
          ? subscriptionDetails!.subscriptionCoverage.id
          : ''
      ).then((e) => {
        if (e) {
          /*  sleep(10000) */

          setPaymentUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          fetchInvoice()
        } else {
          setPaymentUpdateStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
    }
  }

  async function completePaymentDetials() {
    let continuePaymentDetails = true

    setPaymentUpdateStatus({
      requesting: true,
    })

    if (isDataChanged()) {
      try {
        continuePaymentDetails = await updateDetailsToServer(false)
      } catch (error) {
        logger.error('Error in Updating Bill', error)
      }

      if (continuePaymentDetails === false) {
        setPaymentUpdateStatus({
          requesting: false,
          requestError: true,
        })
        dispatch(
          showErrorAlert('Error while updating bills , Please try later')
        )
      }
    }

    if (continuePaymentDetails) {
      completePayment(
        ipdDetails.serviceRequest.id ?? '',
        doctorRef!,
        ipdDayCare,
        refund,
        getRefundAmount(lineItems, totalDiscount, advance, split) > 0,
        getRefundAmount(lineItems, totalDiscount, advance, split),
        subscriptionDetails!.subscriptionCoverage
          ? subscriptionDetails!.subscriptionCoverage.id
          : ''
      ).then((e) => {
        if (e) {
          /*  sleep(10000) */

          setPaymentUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          fetchInvoice()
        } else {
          setPaymentUpdateStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
    }
  }

  function handleInvoiceGenerationButton() {
    setGeneratingStatus({
      requesting: true,
    })

    getInvoiceDetailsInPdf(invoiceDetails?.id ?? '').then((e) => {
      if (e) {
        setGeneratingStatus({
          requesting: false,
          requestSuccess: true,
        })
      } else {
        setGeneratingStatus({
          requesting: false,
          requestError: true,
        })
      }
    })
  }

  async function updateDetailsToServer(forSave: boolean): Promise<boolean> {
    let resOfTotal = false
    if (checkAddedItems() || enableSave) {
      const listItems: IInvoice_LineItem[] = [...lineItems].filter(
        (e) => e.id && e.id.length > 0
      )
      if (invoiceDetails) {
        const oldInvoiceDetails: BillingDetails = invoiceDetails
        if (selectedPaymentType && selectedPaymentType.code === 'percent') {
          const invoice: R4.IInvoice = discountCalculationHelperFunction(
            oldInvoiceDetails.invoice,
            undefined,
            totalDiscountPercent / 100
          )
          setInvoiceDetails({
            id: oldInvoiceDetails.id,
            invoice,
            paymentReconciliation: oldInvoiceDetails.paymentReconciliation,
          })
        } else {
          const invoice: R4.IInvoice = discountCalculationHelperFunction(
            oldInvoiceDetails.invoice,
            totalDiscount,
            undefined
          )
          setInvoiceDetails({
            id: oldInvoiceDetails.id,
            invoice,
            paymentReconciliation: oldInvoiceDetails.paymentReconciliation,
          })
        }
      }
      if (invoiceDetails) {
        resOfTotal = await updateInvoiceDetailsToServer(
          invoiceDetails.invoice,
          ipdDetails.serviceRequest.id ?? ''
        )
        if (resOfTotal === false) {
          dispatch(
            showErrorAlert('Error while adding details. Please check later')
          )
        } else {
          setEnableSave(false)
        }
      }
    }
    // if (
    //   getDiscountAmountInString(invoiceDetails?.invoice.lineItem ?? []) !==
    //   totalDiscount
    // ) {
    //   if (invoiceDetails) {
    //     resOfTotal = await updateInvoiceDetailsToServer(
    //       invoiceDetails.invoice,
    //       ipdDetails.serviceRequest.id ?? ''
    //     )
    //     if (resOfTotal === false) {
    //       dispatch(
    //         showErrorAlert('Error while adding details. Please check later')
    //       )
    //     }
    //   }
    // }
    // if (
    //   getQuantityChangedLineItems(invoiceDetails!.invoice!, lineItems).length >
    //   0
    // ) {
    //   if (invoiceDetails) {
    //     resOfTotal = await updateInvoiceDetailsToServer(
    //       invoiceDetails.invoice,
    //       ipdDetails.serviceRequest.id ?? ''
    //     )
    //     if (resOfTotal === false) {
    //       dispatch(
    //         showErrorAlert('Error while adding details. Please check later')
    //       )
    //     }
    //   }
    // }

    if (doctorRef!) {
      resOfTotal = await updatePaymentDetails(
        ipdDetails.serviceRequest.id ?? '',
        doctorRef!,
        ipdDayCare,
        subscriptionDetails!.subscriptionCoverage
          ? subscriptionDetails!.subscriptionCoverage.id
          : ''
      )
    }
    if (forSave) {
      if (checkForSplit()) {
        resOfTotal = await updateSplitPayment(
          split,
          ipdDetails.serviceRequest.id ?? '',
          doctorRef ?? '',
          ipdDayCare,
          subscriptionDetails!.subscriptionCoverage
            ? subscriptionDetails!.subscriptionCoverage.id
            : ''
        )
      }
    }
    if (checkForAdvance()) {
      resOfTotal = await updateAdvanceDetails(
        advance,
        ipdDayCare,
        ipdDetails.serviceRequest.id ?? ''
      )
    }

    return resOfTotal
  }

  const handleChangeAdvance = (event: any) => {
    if (checkedAdvance === false) {
      setCheckedAdvance(true)
    } else {
      setShowDeleteConfirmationForAdvance({
        open: true,
        onContinueActionClicked: () => {
          setShowDeleteConfirmationForAdvance({ open: false })

          setCheckedAdvance(false)
          setAdvance([
            {
              id: 0,
              amount: 0,
              isDelete: false,
            },
          ])
        },
        onCancelActionClicked: () => {
          setShowDeleteConfirmationForAdvance({ open: false })
          setCheckedAdvance(true)
        },
        displayText: 'Are you sure you want to delete this record?',
        continueActionButtonText: 'Delete',
        cancelActionButtonText: 'Cancel',
      })
    }
  }

  function handleSplitDateChange(e: any, index: number) {
    const values: SplitPaymentDetails[] = [...split]
    values[index].Date = new Date(e.target.value)
    setSplit(values)
  }

  function handlePaymentTypeChangeForRefund(e: R4.ICoding, index: number) {
    const values: SplitPaymentDetails[] = [...refund]
    values[index].paymentType = e
    setRefund(values)
  }

  function handleRefund(e: SplitPaymentDetails[]) {
    const values: SplitPaymentDetails[] = [...refund]
    values[0].paymentType = e[0].paymentType
    values[0].paymentReference = e[0].paymentReference
    values[0].Date = e[0].Date
    values[0].isDelete = e[0].isDelete
    values[0].amount = e[0].amount
    setRefund(values)
  }

  function handlePaymentReferenceForRefund(e: string, index: number) {
    const values: SplitPaymentDetails[] = [...refund]
    values[index].paymentReference = e
    setRefund(values)
  }

  function handleRefundDateChange(e: any, index: number) {
    const values: SplitPaymentDetails[] = [...refund]
    values[index].Date = new Date(e.target.value)
    setRefund(values)
  }

  function handleSplitAmountChange(e: any, index: number) {
    const values: SplitPaymentDetails[] = [...split]
    values[index].amount = Number(e.target.value)
    setSplit(values)
  }

  function handlePaymentTypeChnage(e: R4.ICoding, index: number) {
    const values: SplitPaymentDetails[] = [...split]

    if (e.code === 'membership') {
      setColumns(['membership'])
      values[index].amount = 0
      setSplit(values)
      const advanceDetails: SplitPaymentDetails[] = [...split].filter(
        (existing) =>
          existing.paymentType && existing.paymentType.code === 'membership'
      )

      if (advanceDetails.length === 0) {
        values[index].paymentReference = getMemberPlanId(
          subscriptionDetails.subscriptionCoverage!
        )

        if (
          getMemberShipAvailableCreditForSplit(
            subscriptionDetails.subscriptionCoverage!,
            split
          )! === 0
        ) {
          values[index].paymentType = e
          dispatch(showErrorAlert('Membership credit balance is 0'))
        } else if (
          getMemberShipAvailableCreditForSplit(
            subscriptionDetails.subscriptionCoverage!,
            split
          )! >= getPayableForMembership(lineItems, 0, 0, advance, split)
        ) {
          values[index].paymentType = e
          values[index].primaryCoverageId =
            subscriptionDetails!.subscriptionCoverage!.id!
          values[index].amount = getPayableForMembership(
            lineItems,
            0,
            0,
            advance,
            split
          )
        } else if (
          getMemberShipAvailableCreditForSplit(
            subscriptionDetails.subscriptionCoverage!,
            split
          )! <= getPayableForMembership(lineItems, 0, 0, advance, split)
        ) {
          values[index].primaryCoverageId =
            subscriptionDetails!.subscriptionCoverage!.id!
          values[index].paymentType = e
          values[index].amount = getMemberShipAvailableCreditForSplit(
            subscriptionDetails.subscriptionCoverage!,
            split
          )!
        }
      } else {
        values[index].paymentType = e
        dispatch(showErrorAlert('Membership already chosen as credit balance'))
      }
    } else {
      if (
        values[index].paymentType &&
        values[index].paymentType?.code === 'membership'
      ) {
        setColumns([])
      }
      values[index].paymentType = e
      values[index].paymentReference = ''
      values[index].amount = 0
    }
    setSplit(values)
  }

  function handleSplitPaymentRefrerence(e: string, index: number) {
    const values: SplitPaymentDetails[] = [...split]
    values[index].paymentReference = e
    setSplit(values)
  }

  function handleAdvanceDateChange(e: any, index: number) {
    const values: SplitPaymentDetails[] = [...advance]
    values[index].Date = new Date(e.target.value)
    setAdvance(values)
  }

  function handleAdvanceAmountChange(e: any, index: number) {
    const values: SplitPaymentDetails[] = [...advance]
    values[index].amount = Number(e.target.value)
    setAdvance(values)
  }

  function handlePaymentTypeChnageForAdvance(e: R4.ICoding, index: number) {
    const values: SplitPaymentDetails[] = [...advance]
    values[index].paymentType = e
    setAdvance(values)
  }

  function handleAdvancePaymentRefrerence(e: string, index: number) {
    const values: SplitPaymentDetails[] = [...advance]
    values[index].paymentReference = e
    setAdvance(values)
  }

  const handleChange = (event: any) => {
    const ref: R4.IReference = {
      id: nightChargeSearchSlice.price
        ? nightChargeSearchSlice.price.id ?? ''
        : '',
      reference: nightChargeSearchSlice.price
        ? `${nightChargeSearchSlice.price.resourceType}/${nightChargeSearchSlice.price.id}`
        : '',
      display: nightChargeSearchSlice.price
        ? nightChargeSearchSlice.price.description ?? ''
        : '',
    }
    setEnableSave(true)

    const values: R4.IInvoice_LineItem[] = [...lineItems]
    const indexNumber: number | undefined = values[values.length - 1].sequence
    if (checked === false) {
      values.push({
        id: getUniqueTempId(),
        sequence: indexNumber ? indexNumber + 1 : 0,
        extension: [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/invoice-time',
            valueDateTime: moment(nightChargeDate)
              .subtract(1, 'day')
              .toISOString(),
          },
        ],
        chargeItemReference: ref,
        chargeItemCodeableConcept: {
          text: 'Night Charges. Includes Food and Accommodation',
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-codes-cs',
              code: 'night_charge',
              display: 'Night Charge',
            },
          ],
        },
        priceComponent: [
          {
            factor: 1,
            type: R4.Invoice_PriceComponentTypeKind._base,
            amount: {
              currency: 'INR',
              value: nightChargeSearchSlice.availablePrice,
            },
          },
        ],
      })

      setLineItems(values)

      if (invoiceDetails) {
        const oldInvoiceDetails: BillingDetails = invoiceDetails
        let invoice: R4.IInvoice = addLineItems(invoiceDetails.invoice, [
          {
            id: getUniqueTempId(),
            sequence: indexNumber ? indexNumber + 1 : 0,
            extension: [
              {
                url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/invoice-time',
                valueDateTime: moment(nightChargeDate)
                  .subtract(1, 'day')
                  .toISOString(),
              },
            ],
            chargeItemReference: ref,
            chargeItemCodeableConcept: {
              text: 'Night Charges. Includes Food and Accommodation',
              coding: [
                {
                  system:
                    'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-codes-cs',
                  code: 'night_charge',
                  display: 'Night Charge',
                },
              ],
            },
            priceComponent: [
              {
                factor: 1,
                type: R4.Invoice_PriceComponentTypeKind._base,
                amount: {
                  currency: 'INR',
                  value: nightChargeSearchSlice.availablePrice,
                },
              },
            ],
          },
        ])

        if (selectedPaymentType && selectedPaymentType.code === 'percent') {
          setTotalDiscount(
            getDiscountAmountInStringForUpdate(
              getGrandTotalPayableAmount(values),
              0,
              totalDiscountPercent
            )
          )
          invoice = discountCalculationHelperFunction(
            invoice,
            undefined,
            getDiscountAmountPercentageUpdate(
              getGrandTotalPayableAmount(values),
              0,
              totalDiscount
            ) / 100
          )
        }
        if (selectedPaymentType && selectedPaymentType.code === 'amount') {
          setTotalDiscountPercent(
            getDiscountAmountPercentageUpdate(
              getGrandTotalPayableAmount(values),
              0,
              totalDiscount
            )
          )
          invoice = discountCalculationHelperFunction(
            invoice,
            getDiscountAmountInStringForUpdate(
              getGrandTotalPayableAmount(values),
              0,
              totalDiscountPercent
            ),
            undefined
          )
        }
        setInvoiceDetails({
          id: oldInvoiceDetails.id,
          invoice,
          paymentReconciliation: oldInvoiceDetails.paymentReconciliation,
        })
      }

      const splitValues: SplitPaymentDetails[] = [...split]
      const afterAddLines: R4.IInvoice_LineItem[] = [...lineItems]
      for (let i = 0; i < splitValues.length; i++) {
        const paymentTypeData: R4.ICoding | undefined =
          splitValues[i].paymentType

        if (paymentTypeData && paymentTypeData.code === 'membership') {
          if (
            getMemberShipAvailableCredit(
              subscriptionDetails.subscriptionCoverage!
            )! >=
            getPayableForMembershipForAdd(
              afterAddLines,
              0,
              0,
              nightChargeSearchSlice.availablePrice,
              advance,
              splitValues
            )
          ) {
            splitValues[i].amount = getPayableForMembershipForAdd(
              afterAddLines,
              0,
              0,
              nightChargeSearchSlice.availablePrice,
              advance,
              splitValues
            )
          } else if (
            getMemberShipAvailableCreditForSplit(
              subscriptionDetails.subscriptionCoverage!,
              splitValues
            ) > 0
          ) {
            splitValues[i].amount =
              getPayableForMembershipForAdd(
                afterAddLines,
                0,
                0,
                nightChargeSearchSlice.availablePrice,
                advance,
                splitValues
              ) +
              (getMemberShipAvailableCredit(
                subscriptionDetails.subscriptionCoverage!
              ) -
                getPayableForMembershipForAdd(
                  afterAddLines,
                  0,
                  0,
                  nightChargeSearchSlice.availablePrice,
                  advance,
                  splitValues
                ))
          }
        }
      }

      setSplit(splitValues)
      setChecked(true)
    } else {
      for (let i = 0; i < values.length; i++) {
        if (values[i].chargeItemCodeableConcept) {
          const data: R4.ICodeableConcept | undefined =
            values[i].chargeItemCodeableConcept
          if (data) {
            const nightData = data.coding ? data.coding[0].code ?? '' : ''
            {
              if (nightData.length > 0 && nightData === 'night_charge') {
                setShowDeleteConfirmation({
                  open: true,
                  onContinueActionClicked: () => {
                    setShowDeleteConfirmation({ open: false })
                    setChecked(false)
                    deleteItem(i, true)
                  },
                  onCancelActionClicked: () => {
                    setShowDeleteConfirmation({ open: false })
                    setChecked(false)
                  },
                  displayText: 'Are you sure you want to delete this record?',
                  continueActionButtonText: 'Delete',
                  cancelActionButtonText: 'Cancel',
                })
                break
              } else {
                setChecked(false)
              }
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    // i18n.changeLanguage(language ?? '')
    if (detailsFetching === false) {
      fetchInvoice()
    }
  }, [detailsFetching])

  //

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
      }}
      role='billing'
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box />
          <Box>{`Billing of #${getIdentificationNumber(ipdDetails)}`}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetDetails()
              }}
              id='close_icon'
              role='close_icon'
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column'>
          {fetchingStatus.requestError && (
            <Box display='flex'>
              <Alert severity='error'>
                {fetchingStatus.errorDescription ?? 'Error while fetching'}
              </Alert>
            </Box>
          )}
          {invoiceDetails && (
            <Box display='flex' flexDirection='column' role='patient_details'>
              <Box display='flex' flexDirection='row'>
                <Box width='70%'>
                  <PatientAndRoomDetailsOfIPDForInvoice
                    ipdDetails={ipdDetails}
                    id='_billing_patient'
                    onSubscriptionDetailsFetched={({
                      isSubscribed,
                      coverage,
                    }: {
                      isSubscribed: boolean
                      coverage?: R4.ICoverage | undefined
                    }) => {
                      setSubscriptionDetails({
                        fetched: true,
                        subscribed: isSubscribed,
                        subscriptionCoverage: coverage,
                      })

                      if (isSubscribed === true && coverage) {
                        setPaymentOptions([
                          ...PaymentTypeCodes,
                          {
                            code: 'membership',
                            display: 'Membership',
                            system:
                              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-type-cs',
                          },
                        ])
                      }
                    }}
                  />
                </Box>
                <Box>
                  <Box px={1} width='100%'>
                    {subscriptionDetails.fetched &&
                      subscriptionDetails.subscriptionCoverage && (
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          justifyContent='flex-start'
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            px={1}
                            alignContent='center'
                            id='_membershipDetailsPlan'
                          >
                            <Typography
                              variant='subtitle1'
                              color='initial'
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {`Plan : `}
                            </Typography>
                            <Typography
                              variant='subtitle1'
                              color='initial'
                              style={{ paddingLeft: 3.2 }}
                            >
                              {`${getMemberPlanId(
                                subscriptionDetails.subscriptionCoverage!
                              )} (${getPlanNameFromCoverage(
                                subscriptionDetails.subscriptionCoverage
                              )})`}
                            </Typography>
                          </Box>

                          <Box
                            display='flex'
                            flexDirection='row'
                            px={1}
                            alignContent='center'
                            id='_billing_membership_balance'
                          >
                            <Typography
                              variant='subtitle1'
                              color='initial'
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Available Plan Credit :
                            </Typography>
                            <Typography
                              variant='subtitle1'
                              color='initial'
                              style={{ paddingLeft: 3.2 }}
                            >
                              {getSymbolForCurrency('')}{' '}
                              {getMemberShipAvailableCreditForSplit(
                                subscriptionDetails.subscriptionCoverage!,
                                split
                              )}
                              {/* {getMemberShipAvailableCredit(
                                subscriptionDetails.subscriptionCoverage
                              )} */}
                            </Typography>
                          </Box>
                        </Box>
                      )}

                    {!subscriptionDetails.fetched && (
                      <Box display='flex' flexDirection='row'>
                        Checking for any plan subscription
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box display='flex' flexGrow={1} width='100%' pt={1} pb={0.5}>
                {' '}
                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  style={{ width: '100%', height: '1px' }}
                  color='primary'
                />
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                alignItems='flex-start'
                key='unit_actor_small_details_col'
              >
                <Box flexGrow={1} p={0.5} width='8%'>
                  <WelloFormItemLabel title='SR No.' id='sr_no' />
                </Box>
                <Box flexGrow={1} p={0.5} width='15%'>
                  <WelloFormItemLabel title='Date' id='date' />
                </Box>
                <Box
                  display='flex'
                  flexGrow={1}
                  p={1}
                  width='40%'
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  justifyItems='flex-start'
                  paddingLeft={2.5}
                >
                  <WelloFormItemLabel title='Item' id='item' />
                </Box>
                <Box
                  display='flex'
                  flexGrow={1}
                  py={0.5}
                  width='15%'
                  paddingLeft={0.8}
                >
                  <WelloFormItemLabel title='Price' id='price' />
                </Box>
                <Box
                  display='flex'
                  flexGrow={1}
                  py={0.5}
                  width='10%'
                  paddingLeft={0.5}
                >
                  <WelloFormItemLabel title='Quantity' id='qty' />
                </Box>

                <Box
                  display='flex'
                  flexGrow={1}
                  p={0.5}
                  width='5%'
                  alignContent='right'
                  alignItems='right'
                  justifyContent='right'
                />

                <Box
                  display='flex'
                  flexGrow={1}
                  py={0.5}
                  width='10%'
                  alignContent='right'
                  alignItems='right'
                  paddingLeft={1.8}
                  justifyContent='flex-start'
                >
                  <WelloFormItemLabel title='Total' id='total' />
                </Box>
                <Box flexGrow={1} p={0.5} width='5%' />
              </Box>
              {lineItems.map((memRel: R4.IInvoice_LineItem, index: number) => (
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='flex-start'
                  justifyContent='center'
                  justifyItems='center'
                  key={`invoice_line_item_${memRel.sequence}`}
                  role='line_items'
                >
                  <Box
                    flexGrow={1}
                    p={0.5}
                    width='8%'
                    justifyContent='center'
                    justifyItems='center'
                    justifySelf='center'
                    alignContent='center'
                    alignItems='center'
                    alignSelf='center'
                  >
                    <Typography variant='body2' color='initial'>
                      {index + 1}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} py={0.5} paddingRight={0.8} width='15%'>
                    <TextField
                      size='small'
                      variant='outlined'
                      type='date'
                      id={`${index}_billing_date_line`}
                      inputProps={{
                        min: checkInDate,
                        max: checkOutDate,
                        contentEditable: false,
                      }}
                      onChange={(e) => {
                        updateDateOfLineItem(index, e.target.value)
                      }}
                      value={getDateOfLineItem(memRel)}
                      disabled={
                        memRel.id === undefined ||
                        memRel.id.length === 0 ||
                        updatingStatus.requesting === true ||
                        invoiceDetails?.invoice?.status === 'balanced'
                      }
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                    py={0.5}
                    paddingLeft={1.3}
                    paddingRight={0.5}
                    width='40%'
                  >
                    <TextField
                      size='small'
                      fullWidth
                      variant='outlined'
                      value={getNameOfLineItem(memRel) ?? ''}
                      id={`${index}_billing_name_line`}
                      placeholder='Line Item Name'
                      disabled={
                        memRel.id === undefined ||
                        memRel.id.length === 0 ||
                        updatingStatus.requesting === true ||
                        invoiceDetails?.invoice?.status === 'balanced' ||
                        getNameOfLineItem(memRel) ===
                          'Night Charges. Includes Food and Accommodation'
                      }
                      onChange={(e) => {
                        updateNameOfLineItem(index, e.target.value)
                      }}
                    />
                  </Box>
                  <Box display='flex' flexGrow={1} p={0.5} width='15%'>
                    <TextField
                      size='small'
                      variant='outlined'
                      type='number'
                      id={`${index}_billing_price_line`}
                      placeholder={`line_item_amount${memRel.sequence}`}
                      InputProps={{
                        startAdornment: getSymbolForCurrency(''),
                        inputProps: {
                          min: 0,
                          step: 0.25,
                        },
                      }}
                      //   onKeyPress={(e) => {
                      //     if (e.key === '.') {
                      //       e.preventDefault()
                      //     }
                      //   }}
                      value={getBasePriceOfLineItem(memRel)}
                      disabled={
                        memRel.id === undefined ||
                        memRel.id.length === 0 ||
                        updatingStatus.requesting === true ||
                        invoiceDetails?.invoice?.status === 'balanced' ||
                        getNameOfLineItem(memRel) ===
                          'Night Charges. Includes Food and Accommodation'
                      }
                      onChange={(e) => {
                        const newValue = Number(e.target.value)
                        if (newValue >= 0 && newValue <= 100000) {
                          updateAmountOfLineItem(
                            index,
                            Number(e.target.value ?? 0)
                          )
                        } else if (/^\d+$/.test(e.target.value)) {
                          dispatch(
                            showErrorAlert('Price cannot be more than 100000')
                          )
                        }
                      }}
                    />
                  </Box>
                  <Box display='flex' flexGrow={1} p={0.5} width='10%'>
                    <TextField
                      size='small'
                      variant='outlined'
                      type='number'
                      placeholder={`line_item_qty${memRel.sequence}`}
                      inputProps={{
                        step: '1',
                      }}
                      id={`${index}_billing_qty_line`}
                      value={getQuantityOfLineItem(memRel)}
                      disabled={
                        updatingStatus.requesting === true ||
                        invoiceDetails?.invoice?.status === 'balanced' ||
                        (isOPD(ipdDetails) &&
                          getNameOfLineItem(memRel).includes('OPD'))
                      }
                      onKeyPress={(e) => {
                        if (e.key === '.') {
                          e.preventDefault()
                        }
                      }}
                      onBlur={(e) => {
                        const newValue = Number(e.target.value)
                        updateFactorOfLineItemOnBlur(
                          index,
                          Number(newValue ?? 0)
                        )
                      }}
                      onChange={(e) => {
                        const newValue = Number(e.target.value)
                        if (newValue <= 0) {
                          showErrorAlert('Quantity cannot be 0')
                        } else if (newValue > 0 && newValue <= 1000) {
                          updateFactorOfLineItem(index, Number(newValue ?? 0))
                        } else if (/^\d+$/.test(e.target.value)) {
                          dispatch(
                            showErrorAlert('Quantity cannot be more than 1000')
                          )
                        }
                      }}
                    />
                  </Box>
                  <Box
                    display='flex'
                    py={0.5}
                    width='8%'
                    justifyContent='center'
                    justifyItems='center'
                    justifySelf='center'
                    alignContent='center'
                    alignItems='center'
                    alignSelf='center'
                    id={`${index}_billing_total_line_currency`}
                    role={`billing_total_currency${memRel.sequence}`}
                  >
                    <Box width='96%' />
                    <Box
                      width='4%'
                      justifyContent='flex-end'
                      justifyItems='center'
                      justifySelf='center'
                      alignContent='center'
                      alignItems='center'
                      alignSelf='center'
                      paddingLeft={4}
                      paddingRight={1}
                    >
                      <Typography
                        variant='body2'
                        color='initial'
                        id={`${index}_billing_total_label_line`}
                      >
                        {getSymbolForCurrency('')}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display='flex'
                    flexGrow={1}
                    p={0.5}
                    width='7%'
                    justifyContent='right'
                    justifyItems='center'
                    justifySelf='center'
                    alignContent='center'
                    alignItems='center'
                    alignSelf='center'
                    id={`${index}_billing_total_line`}
                    role={`billing_total_line${memRel.sequence}`}
                  >
                    <Typography
                      variant='body2'
                      color='initial'
                      id={`${index}_billing_total_label_line`}
                    >
                      {getTotalOfLineItemInString(memRel)}
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                    p={0.5}
                    width='4.9%'
                    justifyContent='right'
                    justifyItems='center'
                    justifySelf='center'
                    alignContent='center'
                    alignItems='center'
                    alignSelf='center'
                  >
                    {!(
                      getNameOfLineItem(memRel)
                        .toLowerCase()
                        .includes('room') ||
                      getNameOfLineItem(memRel)
                        .toLowerCase()
                        .includes('package charges') ||
                      getNameOfLineItem(memRel).toLowerCase().includes('opd') ||
                      getNameOfLineItem(memRel)
                        .toLowerCase()
                        .includes('attendant')
                    ) &&
                      invoiceDetails?.invoice?.status !== 'balanced' && (
                        <IconButton
                          role={`delete_item${memRel.sequence}`}
                          id={`delete_item${memRel.sequence}`}
                          onClick={() => {
                            setShowDeleteConfirmation({
                              open: true,
                              onContinueActionClicked: () => {
                                setShowDeleteConfirmation({ open: false })
                                if (
                                  getNameOfLineItem(memRel) ===
                                  'Night Charges. Includes Food and Accommodation'
                                ) {
                                  setChecked(false)
                                  deleteItem(index, true)
                                } else {
                                  deleteItem(index, false)
                                }
                              },
                              onCancelActionClicked: () => {
                                setShowDeleteConfirmation({ open: false })
                              },
                              displayText:
                                'Are you sure you want to delete this record?',
                              continueActionButtonText: 'Delete',
                              cancelActionButtonText: 'Cancel',
                            })
                          }}
                        >
                          <DeleteOutlineOutlined
                            fontSize='small'
                            style={{
                              cursor: 'pointer',
                            }}
                            id={`delete_item_icon${memRel.sequence}`}
                          />
                        </IconButton>
                      )}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {invoiceDetails && selectedPaymentType && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              role='patient_details'
            >
              <Box display='flex' flexDirection='column' width='100%'>
                <Box display='flex' alignItems='center' flexDirection='row'>
                  <Box>
                    {invoiceDetails?.invoice?.status !== 'balanced' && (
                      <Button
                        variant='text'
                        onClick={handleAddMore}
                        disabled={
                          fetchingStatus.requesting || updatingStatus.requesting
                        }
                        style={{
                          paddingLeft: '4px',
                        }}
                        role='add_more_line'
                        id='add_more_line'
                      >
                        <Typography variant='subtitle2' color='primary'>
                          + Add More Item
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
                {!isOPD(ipdDetails) && checked === false && (
                  <Box display='flex' flexDirection='row'>
                    <Box paddingLeft={1}>
                      <Checkbox
                        edge='start'
                        checked={checked}
                        onClick={handleChange}
                        role='night_charge'
                        size='small'
                        disabled={
                          !fetchingStatus.requesting &&
                          !updatingStatus.requesting &&
                          !paymentUpdateStatus.requesting &&
                          invoiceDetails?.invoice?.status === 'balanced'
                        }
                        disableRipple
                        color='primary'
                        inputProps={{ 'aria-labelledby': 'consent' }}
                        id='billing_night'
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                    <Box py={1.3}>
                      <Typography variant='subtitle2' color='initial'>
                        Night Charges
                      </Typography>
                    </Box>
                  </Box>
                )}

                <Box display='flex' flexDirection='column'>
                  <Box display='flex' flexDirection='row' alignItems='flex-end'>
                    <Box
                      display='flex'
                      flexGrow={1}
                      flexDirection='row'
                      width='11.6%'
                      justifyContent='flex-end'
                      alignContent='center'
                      alignItems='center'
                      alignSelf='center'
                      paddingRight={2}
                      paddingLeft={2}
                      id='grand_total'
                      role='grand_total'
                    >
                      Grand Total
                    </Box>

                    <Box
                      display='flex'
                      minWidth='5%'
                      justifyContent='center'
                      alignContent='right'
                      alignItems='right'
                    >
                      <Box width='96%' />
                      <Box
                        width='5%'
                        justifyContent='flex-end'
                        alignContent='right'
                        alignItems='right'
                        paddingLeft={5}
                        paddingRight={1}
                      >
                        <Typography variant='body2' color='initial'>
                          {getSymbolForCurrency('')}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      //   px={0.5}
                      //   paddingLeft={1}
                      width='6.2%'
                      justifyItems='center'
                      justifySelf='center'
                      alignContent='center'
                      alignItems='center'
                      alignSelf='center'
                      justifyContent='right'
                      id='grand_total_val'
                      role='grand_total_val'
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        {getGrandTotalAmountInString(lineItems)}
                      </Typography>
                    </Box>
                    <Box p={0.5} width='5%' />
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='71%'
                      justifyContent='flex-end'
                      alignItems='center'
                      px={2}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                        alignItems='center'
                      >
                        <Box
                          display='flex'
                          px={0.5}
                          flexDirection='row'
                          justifyContent='flex-end'
                          alignContent='center'
                          alignItems='center'
                          alignSelf='center'
                          id='discount_inr'
                          role='discount_inr'
                        >
                          Discount
                        </Box>
                        <Box maxWidth='110px'>
                          <Box
                            maxWidth='110px'
                            paddingLeft={1}
                            paddingRight={
                              selectedPaymentType &&
                              selectedPaymentType.code?.trim() === 'amount'
                                ? 1
                                : 0
                            }
                            role='payment_type_block'
                          >
                            <WelloSelectFHIRCoding
                              title=''
                              id='payment_type'
                              availableCodings={paymentTypes}
                              onChanged={(type) => {
                                setselectedPaymentType(type)
                                setEnableSave(true)
                                if (invoiceDetails) {
                                  const oldInvoiceDetails: BillingDetails =
                                    invoiceDetails
                                  if (type.code === 'amount') {
                                    const invoice: R4.IInvoice =
                                      discountCalculationHelperFunction(
                                        oldInvoiceDetails.invoice,
                                        totalDiscount,
                                        undefined
                                      )
                                    setInvoiceDetails({
                                      id: oldInvoiceDetails.id,
                                      invoice,
                                      paymentReconciliation:
                                        oldInvoiceDetails.paymentReconciliation,
                                    })
                                  }
                                  if (type.code === 'percent') {
                                    const invoice: R4.IInvoice =
                                      discountCalculationHelperFunction(
                                        oldInvoiceDetails.invoice,
                                        undefined,
                                        totalDiscountPercent / 100
                                      )
                                    setInvoiceDetails({
                                      id: oldInvoiceDetails.id,
                                      invoice,
                                      paymentReconciliation:
                                        oldInvoiceDetails.paymentReconciliation,
                                    })
                                  }
                                }
                              }}
                              textFieldProps={{
                                size: 'small',
                                fullWidth: true,
                              }}
                              preSelectedCoding={selectedPaymentType!}
                              role='payment_type'
                            />
                          </Box>
                        </Box>
                      </Box>
                      {selectedPaymentType &&
                        selectedPaymentType.code?.trim() === 'amount' && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                            justifyContent='center'
                            justifyItems='center'
                            maxWidth='150px'
                          >
                            <Box
                              display='flex'
                              justifyContent='center'
                              alignContent='center'
                              alignItems='center'
                              alignSelf='center'
                              maxWidth='150px'
                            >
                              <TextField
                                size='small'
                                variant='outlined'
                                fullWidth
                                id='disc_inr'
                                placeholder='disc_inr'
                                onFocus={(e) => {
                                  setDiscountInFocus(true)
                                }}
                                onBlur={(e) => {
                                  setDiscountInFocus(false)
                                }}
                                InputProps={{
                                  type: 'number',
                                  inputProps: {
                                    style: { textAlign: 'right' },
                                    min: 0,
                                    max: getGrandTotalPayableAmount(lineItems),
                                    step: 0.25,
                                  },
                                }}
                                value={
                                  discountInFocus
                                    ? totalDiscount
                                    : totalDiscount.toFixed(2)
                                }
                                onChange={(e) => {
                                  if (
                                    Number(e.target.value) <=
                                    getGrandTotalPayableAmount(lineItems)
                                  ) {
                                    setTotalDiscount(Number(e.target.value))
                                    if (invoiceDetails) {
                                      const oldInvoiceDetails: BillingDetails =
                                        invoiceDetails
                                      console.log(oldInvoiceDetails.invoice)
                                      console.log(Number(e.target.value))
                                      const invoice: R4.IInvoice =
                                        discountCalculationHelperFunction(
                                          oldInvoiceDetails.invoice,
                                          Number(e.target.value),
                                          undefined
                                        )
                                      console.log(invoice)
                                      setInvoiceDetails({
                                        id: oldInvoiceDetails.id,
                                        invoice,
                                        paymentReconciliation:
                                          oldInvoiceDetails.paymentReconciliation,
                                      })
                                      setEnableSave(true)
                                    }
                                    setTotalDiscountPercent(
                                      getDiscountPercentage(
                                        getGrandTotalPayableAmount(lineItems),
                                        Number(e.target.value)
                                      )
                                    )
                                    setDiscountPercentageChangeFirst(false)
                                    setDiscountChangeFirst(true)
                                  } else if (/^\d+$/.test(e.target.value)) {
                                    dispatch(
                                      showErrorAlert(
                                        'Discount cannot be greater than Grand Total'
                                      )
                                    )
                                  }
                                }}
                                disabled={
                                  updatingStatus.requesting ||
                                  invoiceDetails?.invoice?.status === 'balanced'
                                }
                              />
                            </Box>

                            <Box px={0.5} width='5%' />
                          </Box>
                        )}
                      {selectedPaymentType &&
                        selectedPaymentType.code &&
                        selectedPaymentType.code.trim() === 'percent' && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                            justifyContent='center'
                            justifyItems='center'
                            maxWidth='115px'
                          >
                            <Box
                              display='flex'
                              justifyContent='center'
                              alignContent='center'
                              alignItems='center'
                              alignSelf='center'
                              maxWidth='115px'
                              px={1}
                            >
                              <TextField
                                size='small'
                                variant='outlined'
                                id='disc_percent'
                                onFocus={(e) => {
                                  setDiscountInFocusPercent(true)
                                }}
                                onBlur={(e) => {
                                  setDiscountInFocusPercent(false)
                                }}
                                // onKeyPress={(e) => {
                                //   if (e.key === '.') {
                                //     e.preventDefault()
                                //   }
                                // }}
                                InputProps={{
                                  placeholder: 'Discount in percent',
                                  type: 'number',
                                  inputProps: {
                                    style: { textAlign: 'left' },
                                    min: 0,
                                    max: getGrandTotalPayableAmount(lineItems),
                                    step: 0.25,
                                  },
                                }}
                                value={
                                  discountInFocusPercent
                                    ? totalDiscountPercent
                                    : totalDiscountPercent
                                }
                                onChange={(e) => {
                                  if (
                                    Number(
                                      getGrandTotalPayableAmountWithPercentage(
                                        lineItems,
                                        Number(e.target.value)
                                      )
                                    ) <= getGrandTotalPayableAmount(lineItems)
                                  ) {
                                    setTotalDiscount(
                                      getDiscount(
                                        getGrandTotalPayableAmount(lineItems),
                                        Number(e.target.value)
                                      )
                                    )
                                    setTotalDiscountPercent(
                                      Number(e.target.value)
                                    )

                                    setDiscountPercentageChangeFirst(true)
                                    setDiscountChangeFirst(false)
                                    setEnableSave(true)
                                  } else if (/^\d+$/.test(e.target.value)) {
                                    dispatch(
                                      showErrorAlert(
                                        'Discount percentage cannot be greater than Grand Total'
                                      )
                                    )
                                  }
                                }}
                                disabled={
                                  updatingStatus.requesting ||
                                  invoiceDetails?.invoice?.status === 'balanced'
                                }
                              />
                            </Box>

                            <Box px={0.5} width='5%' />
                          </Box>
                        )}
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='row'
                      alignItems='flex-end'
                      width='8.17%'
                    >
                      <Box
                        display='flex'
                        flexGrow={1}
                        flexDirection='row'
                        justifyContent='flex-end'
                        alignContent='center'
                        alignItems='center'
                        alignSelf='center'
                        role='discount_inr_label'
                        id='discount_inr_label'
                      >
                        Discount
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      py={0.5}
                      minWidth='9.45%'
                      justifyContent='center'
                      justifyItems='center'
                      justifySelf='center'
                      alignContent='center'
                      alignItems='center'
                      alignSelf='center'
                    >
                      <Box width='96%' />
                      <Box
                        width='5%'
                        justifyContent='flex-end'
                        justifyItems='center'
                        justifySelf='center'
                        alignContent='center'
                        alignItems='center'
                        alignSelf='center'
                        paddingLeft={6}
                        paddingRight={0.8}
                      >
                        <Typography variant='body2' color='initial'>
                          {getSymbolForCurrency('')}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      width='6.3%'
                      alignItems='center'
                      alignSelf='center'
                      justifyContent='flex-end'
                      role='discount_amount_in_inr'
                      paddingLeft={1}
                      id='discount_amount_in_inr'
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        {totalDiscount.toFixed(2)}
                      </Typography>
                    </Box>
                    {/* <Box px={0.5} width='4.2%' /> */}
                  </Box>

                  <Box display='flex' flexDirection='row' alignItems='flex-end'>
                    <Box
                      display='flex'
                      flexGrow={1}
                      flexDirection='row'
                      width='11.6%'
                      justifyContent='flex-end'
                      alignContent='center'
                      alignItems='center'
                      alignSelf='center'
                      paddingLeft={3.3}
                      paddingRight={1.9}
                      id='grand_total'
                      role='grand_total'
                    >
                      Net Payable
                    </Box>
                    <Box
                      display='flex'
                      minWidth='2%'
                      justifyContent='center'
                      alignContent='right'
                      alignItems='right'
                    >
                      <Box width='96%' />
                      <Box
                        width='5%'
                        justifyContent='flex-end'
                        justifyItems='center'
                        justifySelf='center'
                        alignContent='center'
                        alignItems='center'
                        alignSelf='center'
                        paddingLeft={5}
                        paddingRight={1.1}
                      >
                        <Typography variant='body2' color='initial'>
                          {getSymbolForCurrency('')}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      //   px={0.5}
                      //   paddingLeft={1}
                      width='6.2%'
                      alignContent='right'
                      alignItems='right'
                      justifyContent='right'
                      role='net_payable_amount'
                      id='net_payable_amount'
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        {getNetPayableAmountInString(
                          lineItems,
                          totalDiscount,
                          0
                        )}
                      </Typography>
                    </Box>
                    <Box p={0.5} width='4.89%' />
                  </Box>

                  {checkedAdvance === false &&
                    (isDischargeInitiated(ipdDetails.task!) === false ||
                      isDischarged(ipdDetails.task!) === false) && (
                      <Box display='flex' flexDirection='column'>
                        {isDischargeInitiated(ipdDetails.task!) === false &&
                          isDischarged(ipdDetails.task!) === false && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              alignItems='flex-end'
                              paddingTop={1}
                            >
                              <Box
                                display='flex'
                                flexGrow={1}
                                flexDirection='row'
                                width='11.6%'
                                justifyContent='flex-end'
                                alignContent='center'
                                alignItems='center'
                                alignSelf='center'
                                px={2}
                              >
                                Paid
                              </Box>
                              <Box
                                display='flex'
                                minWidth='10.8%'
                                justifyContent='center'
                                alignContent='right'
                                alignItems='right'
                              >
                                <Box width='96%' />
                                <Box
                                  width='5%'
                                  justifyContent='flex-end'
                                  justifyItems='center'
                                  justifySelf='center'
                                  alignContent='center'
                                  alignItems='center'
                                  alignSelf='center'
                                  paddingLeft={5}
                                  paddingRight={1.28}
                                >
                                  <Typography variant='body2' color='initial'>
                                    {getSymbolForCurrency('')}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box
                                display='flex'
                                width='6.2%'
                                justifyContent='flex-end'
                                alignContent='right'
                                alignItems='right'
                              >
                                <Typography
                                  variant='subtitle2'
                                  style={{
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {getPaidAmount(advance, split)}
                                </Typography>
                                {/* <WelloFormItemLabel
                                  title={getPaidAmount(advance, split)}
                                /> */}
                              </Box>

                              <Box p={0.5} width='4.75%' />
                            </Box>
                          )}
                        {isDischargeInitiated(ipdDetails.task!) === false &&
                          isDischarged(ipdDetails.task!) === false && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              alignItems='flex-end'
                              paddingTop={1}
                            >
                              <Box
                                display='flex'
                                flexGrow={1}
                                flexDirection='row'
                                width='11.6%'
                                justifyContent='flex-end'
                                alignContent='center'
                                alignItems='center'
                                alignSelf='center'
                                px={2}
                              >
                                Balance
                              </Box>

                              <Box
                                display='flex'
                                minWidth='8.7%'
                                justifyContent='center'
                                alignContent='right'
                                alignItems='right'
                              >
                                <Box width='96%' />
                                <Box
                                  width='5%'
                                  justifyContent='flex-end'
                                  justifyItems='center'
                                  justifySelf='center'
                                  alignContent='center'
                                  alignItems='center'
                                  alignSelf='center'
                                  paddingLeft={5}
                                  paddingRight={1.2}
                                >
                                  <Typography variant='body2' color='initial'>
                                    {getSymbolForCurrency('')}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box
                                display='flex'
                                //   p={0.5}

                                width='6.2%'
                                alignContent='right'
                                alignItems='right'
                                justifyContent='flex-end'
                              >
                                <Typography
                                  variant='subtitle2'
                                  style={{
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {getBalanceAmount(
                                    lineItems,
                                    totalDiscount,
                                    advance,
                                    split
                                  )}
                                </Typography>
                                {/* <WelloFormItemLabel
                                  title={getBalanceAmount(
                                    lineItems,
                                    totalDiscount,
                                    advance,
                                    split
                                  )}
                                /> */}
                              </Box>

                              <Box p={0.5} width='4.69%' />
                            </Box>
                          )}
                        {isDischargeInitiated(ipdDetails.task!) === false &&
                          isDischarged(ipdDetails.task!) === false && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              alignItems='flex-end'
                              paddingTop={1}
                            >
                              <Box
                                display='flex'
                                flexGrow={1}
                                flexDirection='row'
                                width='11.6%'
                                justifyContent='flex-end'
                                alignContent='center'
                                alignItems='center'
                                alignSelf='center'
                                px={2}
                              >
                                Refund
                              </Box>

                              <Box
                                display='flex'
                                minWidth='9.1%'
                                justifyContent='center'
                                alignContent='right'
                                alignItems='right'
                              >
                                <Box width='96%' />
                                <Box
                                  width='5%'
                                  justifyContent='flex-end'
                                  justifyItems='center'
                                  justifySelf='center'
                                  alignContent='center'
                                  alignItems='center'
                                  alignSelf='center'
                                  paddingLeft={5}
                                  paddingRight={1.1}
                                >
                                  <Typography variant='body2' color='initial'>
                                    {getSymbolForCurrency('')}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box
                                display='flex'
                                width='6.2%'
                                alignContent='right'
                                alignItems='right'
                                justifyContent='flex-end'
                              >
                                <Typography
                                  variant='subtitle2'
                                  style={{
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {getRefundString(
                                    lineItems,
                                    totalDiscount,
                                    advance,
                                    split
                                  ).replace('-', '')}
                                </Typography>
                              </Box>

                              <Box p={0.5} width='4.69%' />
                            </Box>
                          )}
                      </Box>
                    )}
                </Box>
              </Box>
            </Box>
          )}
          {/* {invoiceDetails && !isOPD(ipdDetails) && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box flexGrow={1} marginY={1}>
                <Divider />
              </Box>
              <Box py={1}>
                <Typography variant='subtitle1' color='initial'>
                  Payment Information
                </Typography>
              </Box>
            </Box>
          )} */}
          {/* {invoiceDetails &&
            isOPD(ipdDetails) &&
            isDischargeInitiated(ipdDetails.task!) && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box flexGrow={1} marginY={1}>
                  <Divider />
                </Box>
                <Box py={1}>
                  <Typography variant='subtitle1' color='initial'>
                    Payment Information
                  </Typography>
                </Box>
              </Box>
            )} */}
          {invoiceDetails && hide === false && (
            <Box display='flex' flexDirection='column' width='100%'>
              {/* <Box display='flex' flexDirection='row'>
                <Box py={1.3}>
                  <Typography variant='subtitle1' color='initial'>
                    Payment Information
                  </Typography>
                </Box>
              </Box> */}
              {/* {checkedAdvance && <Box role='checkBoxVal'>Hi</Box>} */}

              {!isOPD(ipdDetails) && (
                <Box display='flex' flexDirection='row'>
                  <Box py={1.3}>
                    <Typography variant='subtitle1' color='initial'>
                      Advance Payment
                    </Typography>
                  </Box>
                  <Box>
                    <Checkbox
                      checked={checkedAdvance}
                      onClick={handleChangeAdvance}
                      size='small'
                      role='checkbox1'
                      id='checkbox_advance'
                      style={{
                        cursor: 'pointer',
                      }}
                      disabled={
                        (fetchingStatus.requesting &&
                          !updatingStatus.requesting) ||
                        !(isGre() || isUnitAdmin()) ||
                        advanceCheck ||
                        isDischargeInitiated(ipdDetails.task!) ||
                        isDischarged(ipdDetails.task!)
                      }
                      // disabled={
                      //   (!fetchingStatus.requesting &&
                      //     !updatingStatus.requesting &&
                      //     !paymentUpdateStatus.requesting &&
                      //     !fetchingStatus.requesting &&
                      //     invoiceDetails?.invoice?.status === 'balanced') ||

                      //   advanceCheck ||
                      //   !(isGre() || isUnitAdmin())
                      // }
                      disableRipple
                      color='primary'
                    />
                  </Box>
                </Box>
              )}
              {checkedAdvance && (
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  paddingLeft={3}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='85%'
                    px={6.5}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      justifyContent='space-between'
                    >
                      <Box width={130}>
                        <WelloFormItemLabel title='Date' id='adv_date' />
                      </Box>
                      <Box width={130} px={0.5}>
                        <WelloFormItemLabel title='Type' id='adv_type' />
                      </Box>
                      <Box width={130}>
                        <WelloFormItemLabel title='Amount' id='adv_amount' />
                      </Box>
                      <Box width={130}>
                        <WelloFormItemLabel
                          title='Reference'
                          id='adv_reference'
                        />
                      </Box>
                    </Box>
                  </Box>
                  {advance.map(
                    (payments: SplitPaymentDetails, index: number) => (
                      <Box
                        display='flex'
                        flexDirection='column'
                        width={
                          payments.isDelete || advance.length === 1
                            ? '85%'
                            : '93.5%'
                        }
                        key={payments.id}
                        px={6.5}
                        py={0.5}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          justifyContent='space-between'
                        >
                          <Box display='flex' flexDirection='column'>
                            {/* <Box minWidth='30px' px={0.5}>
                              <WelloFormItemLabel title='Date' />
                            </Box> */}
                            <TextField
                              size='small'
                              variant='outlined'
                              type='date'
                              id={`split${index}date`}
                              defaultValue={
                                payments.Date
                                  ? moment(payments.Date).format('YYYY-MM-DD')
                                  : undefined
                              }
                              inputProps={{
                                min: checkInDate,
                                max: moment(new Date()).format('YYYY-MM-DD'),
                                contentEditable: false,
                              }}
                              disabled={
                                invoiceDetails?.invoice?.status ===
                                  'balanced' ||
                                !(isGre() || isUnitAdmin()) ||
                                payments.isDelete
                              }
                              onKeyDown={(e) => {
                                e.preventDefault()
                              }}
                              //   onKeyDown= (e) => {
                              //     e.preventDefault()
                              //   },
                              onChange={(e) => {
                                handleAdvanceDateChange(e, index)
                              }}
                            />
                          </Box>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width={payments.isDelete ? 130 : 130}
                          >
                            {/* <Box minWidth='30px' px={0.5}>
                              <WelloFormItemLabel title='Type' />
                            </Box> */}

                            <WelloSelectFHIRCoding
                              availableCodings={paymentOptionsForAdvance}
                              id={`adv_billing${index}`}
                              onChanged={(e) => {
                                handlePaymentTypeChnageForAdvance(e, index)
                              }}
                              preSelectedCoding={payments.paymentType}
                              textFieldProps={{
                                disabled:
                                  invoiceDetails?.invoice?.status ===
                                    'balanced' ||
                                  !(isGre() || isUnitAdmin()) ||
                                  payments.isDelete,
                              }}
                            />
                          </Box>

                          <Box
                            display='flex'
                            flexDirection='column'
                            width={payments.isDelete ? 130 : 130}
                          >
                            {/* <Box minWidth='70px' px={0.5} paddingRight={2}>
                              <WelloFormItemLabel title='Amount' />
                            </Box> */}
                            <TextField
                              size='small'
                              variant='outlined'
                              id={`adv_payment${index}`}
                              placeholder={`advance${payments.id}`}
                              onFocus={(e) => {
                                setDiscountInFocus(true)
                              }}
                              onBlur={(e) => {
                                setDiscountInFocus(false)
                              }}
                              InputProps={{
                                type: 'tel',

                                inputProps: {
                                  style: { textAlign: 'right' },
                                  min: 0,
                                  max: getNetPayableAmount(
                                    lineItems,
                                    totalDiscount,
                                    0,
                                    preAdvance,
                                    split
                                  ),
                                },
                                startAdornment: getSymbolForCurrency(''),
                              }}
                              value={payments.amount}
                              onChange={(e) => {
                                if (
                                  Number(e.target.value) <=
                                  getNetPayableAmount(
                                    lineItems,
                                    totalDiscount,
                                    0,
                                    preAdvance,
                                    split
                                  )
                                ) {
                                  handleAdvanceAmountChange(e, index)
                                } else if (/^\d+$/.test(e.target.value)) {
                                  dispatch(
                                    showErrorAlert(
                                      'Advance amount cannot be greater than Net Payable'
                                    )
                                  )
                                }
                              }}
                              disabled={
                                updatingStatus.requesting ||
                                invoiceDetails?.invoice?.status ===
                                  'balanced' ||
                                payments.isDelete
                              }
                            />
                          </Box>

                          <Box
                            display='flex'
                            flexDirection='column'
                            width={payments.isDelete ? 130 : 130}
                          >
                            {/* <Box minWidth='70px' px={0.5} paddingRight={2}>
                              <WelloFormItemLabel title='Reference' />
                            </Box> */}
                            <TextField
                              size='small'
                              variant='outlined'
                              type='string'
                              id={`adv_payment_ref${index}`}
                              value={payments.paymentReference}
                              defaultValue={payments.paymentReference}
                              disabled={
                                invoiceDetails?.invoice?.status ===
                                  'balanced' ||
                                !(isGre() || isUnitAdmin()) ||
                                payments.isDelete
                              }
                              inputProps={{ maxLength: 30 }}
                              onChange={(e) => {
                                if (e.target.value.length <= 30) {
                                  const regex = /^[0-9a-zA-Z(\-)]+$/
                                  if (
                                    e.target.value.match(regex) ||
                                    e.target.value === ''
                                  ) {
                                    if (
                                      checkForDuplicateReference(
                                        advance,
                                        e.target.value
                                      ) === false
                                    ) {
                                      handleAdvancePaymentRefrerence(
                                        e.target.value.trim(),
                                        index
                                      )
                                    } else {
                                      dispatch(
                                        showErrorAlert(
                                          'Payment References can not be same'
                                        )
                                      )
                                    }
                                  }
                                }
                              }}
                            />
                          </Box>

                          {advance.length > 1 && payments.isDelete === false && (
                            <Box
                              display='flex'
                              px={0.5}
                              width='5%'
                              justifyContent='right'
                              justifyItems='center'
                              justifySelf='center'
                              alignContent='center'
                              alignItems='center'
                              alignSelf='center'
                            >
                              <DeleteOutlineOutlined
                                fontSize='small'
                                style={{
                                  cursor: 'pointer',
                                }}
                                id={`adv_delete${index}`}
                                onClick={() => {
                                  setShowDeleteConfirmationForAdvance({
                                    open: true,
                                    onContinueActionClicked: () => {
                                      setShowDeleteConfirmationForAdvance({
                                        open: false,
                                      })
                                      deleteAdvance(index)
                                    },
                                    onCancelActionClicked: () => {
                                      setShowDeleteConfirmationForAdvance({
                                        open: false,
                                      })
                                    },
                                    displayText:
                                      'Are you sure you want to delete this record?',
                                    continueActionButtonText: 'Delete',
                                    cancelActionButtonText: 'Cancel',
                                  })
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                        {/* {advance.length !== 4 ||
                          (isDischargeInitiated(ipdDetails.task!) === false && (
                            <Box
                              display='flex'
                              flexGrow={1}
                              width='100%'
                              pt={1}
                              pb={0.5}
                            >
                              {' '}
                              <Divider
                                orientation='horizontal'
                                variant='fullWidth'
                                flexItem
                                style={{ width: '100%', height: '1px' }}
                                color='primary'
                              />
                            </Box>
                          ))} */}
                      </Box>
                    )
                  )}
                  {advance.length < 4 &&
                    isDischargeInitiated(ipdDetails.task!) === false &&
                    getBalanceAmountNumber(
                      lineItems,
                      totalDiscount,
                      advance,
                      split
                    ) > 0 && (
                      <Box>
                        <Button
                          variant='text'
                          onClick={handleAddMoreAdvance}
                          role='add_more_advance'
                          id='adv_add_more'
                        >
                          <Typography variant='subtitle2' color='primary'>
                            + Add More Advance
                          </Typography>
                        </Button>
                      </Box>
                    )}
                </Box>
              )}
            </Box>
          )}
          {checkedAdvance &&
            (isDischargeInitiated(ipdDetails.task!) === false ||
              isDischarged(ipdDetails.task!) === false) && (
              <Box flexGrow={1} marginY={1}>
                <Divider />
              </Box>
            )}
          {checkedAdvance &&
            (isDischargeInitiated(ipdDetails.task!) === false ||
              isDischarged(ipdDetails.task!) === false) && (
              <Box display='flex' flexDirection='row' width='100%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box display='flex' flexDirection='column'>
                    {isDischargeInitiated(ipdDetails.task!) === false &&
                      isDischarged(ipdDetails.task!) === false && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          alignItems='flex-end'
                          paddingTop={1}
                        >
                          <Box
                            display='flex'
                            flexGrow={1}
                            flexDirection='row'
                            width='11.6%'
                            justifyContent='flex-end'
                            alignContent='center'
                            alignItems='center'
                            alignSelf='center'
                            px={2}
                          >
                            Paid
                          </Box>
                          <Box
                            display='flex'
                            minWidth='10.8%'
                            justifyContent='center'
                            alignContent='right'
                            alignItems='right'
                          >
                            <Box width='96%' />
                            <Box
                              width='5%'
                              justifyContent='flex-end'
                              justifyItems='center'
                              justifySelf='center'
                              alignContent='center'
                              alignItems='center'
                              alignSelf='center'
                              paddingLeft={5}
                              paddingRight={1.28}
                            >
                              <Typography variant='body2' color='initial'>
                                {getSymbolForCurrency('')}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            display='flex'
                            width='6.2%'
                            justifyContent='flex-end'
                            alignContent='right'
                            alignItems='right'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                textTransform: 'uppercase',
                              }}
                            >
                              {getPaidAmount(advance, split)}
                            </Typography>
                            {/* <WelloFormItemLabel
                            title={getPaidAmount(advance, split)}
                          /> */}
                          </Box>

                          <Box p={0.5} width='4.75%' />
                        </Box>
                      )}
                    {isDischargeInitiated(ipdDetails.task!) === false &&
                      isDischarged(ipdDetails.task!) === false && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          alignItems='flex-end'
                          paddingTop={1}
                        >
                          <Box
                            display='flex'
                            flexGrow={1}
                            flexDirection='row'
                            width='11.6%'
                            justifyContent='flex-end'
                            alignContent='center'
                            alignItems='center'
                            alignSelf='center'
                            px={2}
                          >
                            Balance
                          </Box>

                          <Box
                            display='flex'
                            minWidth='8.7%'
                            justifyContent='center'
                            alignContent='right'
                            alignItems='right'
                          >
                            <Box width='96%' />
                            <Box
                              width='5%'
                              justifyContent='flex-end'
                              justifyItems='center'
                              justifySelf='center'
                              alignContent='center'
                              alignItems='center'
                              alignSelf='center'
                              paddingLeft={5}
                              paddingRight={1.2}
                            >
                              <Typography variant='body2' color='initial'>
                                {getSymbolForCurrency('')}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            display='flex'
                            //   p={0.5}

                            width='6.2%'
                            alignContent='right'
                            alignItems='right'
                            justifyContent='flex-end'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                textTransform: 'uppercase',
                              }}
                            >
                              {getBalanceAmount(
                                lineItems,
                                totalDiscount,
                                advance,
                                split
                              )}
                            </Typography>
                            {/* <WelloFormItemLabel
                            title={getBalanceAmount(
                              lineItems,
                              totalDiscount,
                              advance,
                              split
                            )}
                          /> */}
                          </Box>

                          <Box p={0.5} width='4.69%' />
                        </Box>
                      )}
                    {isDischargeInitiated(ipdDetails.task!) === false &&
                      isDischarged(ipdDetails.task!) === false && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          alignItems='flex-end'
                          paddingTop={1}
                        >
                          <Box
                            display='flex'
                            flexGrow={1}
                            flexDirection='row'
                            width='11.6%'
                            justifyContent='flex-end'
                            alignContent='center'
                            alignItems='center'
                            alignSelf='center'
                            px={2}
                          >
                            Refund
                          </Box>

                          <Box
                            display='flex'
                            minWidth='9.1%'
                            justifyContent='center'
                            alignContent='right'
                            alignItems='right'
                          >
                            <Box width='96%' />
                            <Box
                              width='5%'
                              justifyContent='flex-end'
                              justifyItems='center'
                              justifySelf='center'
                              alignContent='center'
                              alignItems='center'
                              alignSelf='center'
                              paddingLeft={5}
                              paddingRight={1.1}
                            >
                              <Typography variant='body2' color='initial'>
                                {getSymbolForCurrency('')}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            display='flex'
                            width='6.2%'
                            alignContent='right'
                            alignItems='right'
                            justifyContent='flex-end'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                textTransform: 'uppercase',
                              }}
                            >
                              {getRefundString(
                                lineItems,
                                totalDiscount,
                                advance,
                                split
                              ).replace('-', '')}
                            </Typography>
                          </Box>

                          <Box p={0.5} width='4.69%' />
                        </Box>
                      )}
                  </Box>
                </Box>
              </Box>
            )}
          {/* {!fetchingStatus.requesting &&
            !updatingStatus.requesting &&
            (invoiceDetails?.invoice?.status === 'balanced' ||
              isDischargeInitiated(ipdDetails.task!)) && (
              <Box flexGrow={1} marginY={1}>
                <Divider />
              </Box>
            )} */}
          {!fetchingStatus.requesting &&
            !updatingStatus.requesting &&
            invoiceDetails &&
            (isGre() || isUnitAdmin()) &&
            (invoiceDetails?.invoice?.status === 'balanced' ||
              isDischargeInitiated(ipdDetails.task!)) && (
              <Box display='flex' flexDirection='row' alignItems='flex-end'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box display='flex' flexDirection='row' role='final' py={1}>
                    <Typography variant='subtitle1' color='initial'>
                      Final Payment
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    paddingLeft={1}
                  >
                    {getBalanceAmountForSplitPayment(
                      lineItems,
                      totalDiscount,
                      advance
                    ) > 0 &&
                      deleteSplit === false && (
                        <Box display='flex' flexDirection='column'>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='85%'
                            px={8}
                          >
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              justifyContent='space-between'
                            >
                              <Box width={130}>
                                <WelloFormItemLabel
                                  title='Date'
                                  id='final_date'
                                />
                              </Box>
                              <Box width={130} paddingLeft={1.5}>
                                <WelloFormItemLabel
                                  title='Type'
                                  id='final_type'
                                />
                              </Box>

                              <Box width={130} paddingLeft={1.5}>
                                <WelloFormItemLabel
                                  title='Amount'
                                  id='final_amount'
                                />
                              </Box>

                              <Box width={130} paddingLeft={1.5}>
                                <WelloFormItemLabel
                                  title='Reference'
                                  id='final_ref'
                                />
                              </Box>
                            </Box>
                          </Box>

                          {split.map(
                            (payments: SplitPaymentDetails, index: number) => (
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                key={payments.id}
                                px={8}
                                py={0.5}
                                //   paddingLeft={44}
                                //   paddingRight={60}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width={
                                    split.length === 1 || payments.isDelete
                                      ? '85%'
                                      : '93.5%'
                                  }
                                  justifyContent='space-between'
                                >
                                  <Box display='flex' flexDirection='column'>
                                    {/* <Box minWidth='30px' px={0.5}>
                                    <WelloFormItemLabel title='Date' />
                                  </Box> */}
                                    <TextField
                                      size='small'
                                      variant='outlined'
                                      type='date'
                                      defaultValue={
                                        payments.Date
                                          ? moment(payments.Date).format(
                                              'YYYY-MM-DD'
                                            )
                                          : moment().format('YYYY-MM-DD')
                                      }
                                      inputProps={{
                                        min: checkInDate,
                                        max: moment(new Date()).format(
                                          'YYYY-MM-DD'
                                        ),
                                        contentEditable: false,
                                      }}
                                      disabled={
                                        invoiceDetails?.invoice?.status ===
                                          'balanced' ||
                                        !(isGre() || isUnitAdmin()) ||
                                        payments.isDelete
                                      }
                                      onKeyDown={(e) => {
                                        e.preventDefault()
                                      }}
                                      onChange={(e) => {
                                        handleSplitDateChange(e, index)
                                        //   setPaymentDate(new Date(e.target.value))
                                      }}
                                      id={`final_payment${index}date`}
                                    />
                                  </Box>
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    paddingLeft={0.5}
                                    width={
                                      payments.isDelete || split.length === 1
                                        ? 131
                                        : 140
                                    }
                                  >
                                    {/* <Box minWidth='30px' px={0.5}>
                                    <WelloFormItemLabel title='Type' />
                                  </Box> */}

                                    <WelloSelectFHIRCoding
                                      availableCodings={
                                        getMemberShipAvailableCredit(
                                          subscriptionDetails.subscriptionCoverage!
                                        )! === 0 &&
                                        invoiceDetails?.invoice?.status !==
                                          'balanced'
                                          ? paymentOptionsForAdvance
                                          : paymentOptions
                                      }
                                      id={`final_payment_billing_type${index}`}
                                      onChanged={(e) => {
                                        handlePaymentTypeChnage(e, index)
                                      }}
                                      preSelectedCoding={payments.paymentType}
                                      textFieldProps={{
                                        disabled:
                                          invoiceDetails?.invoice?.status ===
                                            'balanced' ||
                                          !(isGre() || isUnitAdmin()) ||
                                          payments.isDelete,
                                      }}
                                      selectedColumn={columns}
                                    />
                                  </Box>
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width={
                                      payments.isDelete || split.length === 1
                                        ? 130
                                        : 140
                                    }
                                  >
                                    <Box display='flex' flexDirection='column'>
                                      {/* <Box
                                      minWidth='70px'
                                      px={0.5}
                                      paddingRight={2}
                                    >
                                      <WelloFormItemLabel title='Amount' />
                                    </Box> */}
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        id={`final_payment_amount${index}`}
                                        placeholder={`payment${payments.id}`}
                                        InputProps={{
                                          type: 'number',
                                          disabled: payments.isDelete,
                                          inputProps: {
                                            style: { textAlign: 'right' },
                                            min: 0,
                                            max: getBalanceAmountNumber(
                                              lineItems,
                                              totalDiscount,
                                              advance
                                            ),
                                          },
                                          startAdornment:
                                            getSymbolForCurrency(''),
                                        }}
                                        value={Number(
                                          split[index].amount.toFixed(2)
                                        )}
                                        onChange={(e) => {
                                          if (
                                            (payments.paymentType &&
                                              payments.paymentType.code !==
                                                'membership') ||
                                            payments.paymentType === undefined
                                          ) {
                                            if (
                                              Number(e.target.value) <=
                                              getBalanceAmountNumber(
                                                lineItems,
                                                totalDiscount,
                                                advance
                                              )
                                            ) {
                                              handleSplitAmountChange(e, index)
                                            } else if (
                                              /^\d+$/.test(e.target.value)
                                            ) {
                                              dispatch(
                                                showErrorAlert(
                                                  'Amount cannot be greater than Balance'
                                                )
                                              )
                                            }
                                          } else if (
                                            getMemberShipAvailableCredit(
                                              subscriptionDetails.subscriptionCoverage!
                                            )! !== 0
                                          ) {
                                            if (
                                              Number(e.target.value) <=
                                              getMemberShipAvailableCredit(
                                                subscriptionDetails.subscriptionCoverage!
                                              )!
                                            ) {
                                              if (
                                                Number(e.target.value) <=
                                                getPayableForMembership(
                                                  lineItems,
                                                  totalDiscount,
                                                  0,
                                                  advance
                                                )
                                              ) {
                                                handleSplitAmountChange(
                                                  e,
                                                  index
                                                )
                                              } else if (
                                                /^\d+$/.test(e.target.value)
                                              ) {
                                                dispatch(
                                                  showErrorAlert(
                                                    'Amount cannot be greater than Package charges including attendant charge and night charge'
                                                  )
                                                )
                                              }
                                            } else if (
                                              /^\d+$/.test(e.target.value)
                                            ) {
                                              dispatch(
                                                showErrorAlert(
                                                  'Amount cannot be greater than membership available credit'
                                                )
                                              )
                                            }
                                          } else if (
                                            getMemberShipAvailableCredit(
                                              subscriptionDetails.subscriptionCoverage!
                                            )! === 0
                                          ) {
                                            dispatch(
                                              showErrorAlert(
                                                'Membership balance is 0'
                                              )
                                            )
                                          }
                                        }}
                                        disabled={
                                          updatingStatus.requesting ||
                                          invoiceDetails?.invoice?.status ===
                                            'balanced' ||
                                          payments.isDelete
                                        }
                                      />
                                    </Box>
                                    {/* {getPaidAmountNumber(split) >
                                    getBalanceAmountNumber(
                                      lineItems,
                                      totalDiscount,
                                      advance
                                    ) && (
                                    <Box>
                                      {dispatch(
                                        showErrorAlert(
                                          'Amount cannot be greater than balance amount'
                                        )
                                      )}
                                    </Box>
                                  )} */}
                                  </Box>

                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width={
                                      payments.isDelete || split.length === 1
                                        ? 135
                                        : 135
                                    }
                                  >
                                    {/* <Box
                                    minWidth='70px'
                                    px={0.5}
                                    paddingRight={2}
                                  >
                                    <WelloFormItemLabel title='Reference' />
                                  </Box> */}
                                    <TextField
                                      size='small'
                                      variant='outlined'
                                      type='string'
                                      value={payments.paymentReference ?? ''}
                                      defaultValue={
                                        payments.paymentReference ?? ''
                                      }
                                      id={`final_payment_ref${index}`}
                                      disabled={
                                        invoiceDetails?.invoice?.status ===
                                          'balanced' ||
                                        !(isGre() || isUnitAdmin()) ||
                                        payments.paymentType?.code ===
                                          'membership' ||
                                        payments.isDelete
                                      }
                                      inputProps={{ maxLength: 30 }}
                                      onChange={(e) => {
                                        if (e.target.value.length <= 30) {
                                          const regex = /^[0-9a-zA-Z(\-)]+$/
                                          if (
                                            e.target.value.match(regex) ||
                                            e.target.value === ''
                                          ) {
                                            if (
                                              checkForDuplicateReference(
                                                split,
                                                e.target.value
                                              ) === false
                                            ) {
                                              handleSplitPaymentRefrerence(
                                                e.target.value.trim(),
                                                index
                                              )
                                            } else {
                                              dispatch(
                                                showErrorAlert(
                                                  'Payment Reference can not be same'
                                                )
                                              )
                                            }
                                          }
                                        }
                                      }}
                                    />
                                  </Box>

                                  {split.length > 1 &&
                                    payments.isDelete === false && (
                                      <Box
                                        display='flex'
                                        px={0.5}
                                        width='5%'
                                        justifyContent='right'
                                        justifyItems='center'
                                        justifySelf='center'
                                        alignContent='center'
                                        alignItems='center'
                                        alignSelf='center'
                                      >
                                        <DeleteOutlineOutlined
                                          fontSize='small'
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          id={`final_payment_del${index}`}
                                          onClick={() => {
                                            setShowDeleteConfirmationForSplit({
                                              open: true,
                                              onContinueActionClicked: () => {
                                                setShowDeleteConfirmationForSplit(
                                                  {
                                                    open: false,
                                                  }
                                                )
                                                deleteItemSplit(index)
                                              },
                                              onCancelActionClicked: () => {
                                                setShowDeleteConfirmationForSplit(
                                                  {
                                                    open: false,
                                                  }
                                                )
                                              },
                                              displayText:
                                                'Are you sure you want to delete this record?',
                                              continueActionButtonText:
                                                'Delete',
                                              cancelActionButtonText: 'Cancel',
                                            })
                                          }}
                                        />
                                      </Box>
                                    )}
                                </Box>
                                {/* <Box
                                display='flex'
                                flexGrow={1}
                                width='100%'
                                pt={1}
                                pb={0.5}
                              >
                                {' '}
                                <Divider
                                  orientation='horizontal'
                                  variant='fullWidth'
                                  flexItem
                                  style={{ width: '100%', height: '1px' }}
                                  color='primary'
                                />
                              </Box> */}
                              </Box>
                            )
                          )}
                        </Box>
                      )}
                    {getBalanceAmountForSplitPayment(
                      lineItems,
                      totalDiscount,
                      advance
                    ) > 0 &&
                      deleteSplit && (
                        <Box display='flex' flexDirection='column'>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='85%'
                            px={8}
                          >
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              justifyContent='space-between'
                            >
                              <Box width={130}>
                                <WelloFormItemLabel
                                  title='Date'
                                  id='split_date'
                                />
                              </Box>
                              <Box width={130} paddingLeft={1.5}>
                                <WelloFormItemLabel
                                  title='Type'
                                  id='split_type'
                                />
                              </Box>

                              <Box width={130} paddingLeft={1.5}>
                                <WelloFormItemLabel
                                  title='Amount'
                                  id='split_amount'
                                />
                              </Box>

                              <Box width={130} paddingLeft={1.5}>
                                <WelloFormItemLabel
                                  title='Reference'
                                  id='split_ref'
                                />
                              </Box>
                            </Box>
                          </Box>

                          {split.map(
                            (payments: SplitPaymentDetails, index: number) => (
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                key={payments.id}
                                px={8}
                                py={0.5}
                                //   paddingLeft={44}
                                //   paddingRight={60}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width={
                                    split.length === 1 || payments.isDelete
                                      ? '85%'
                                      : '93.5%'
                                  }
                                  justifyContent='space-between'
                                >
                                  <Box display='flex' flexDirection='column'>
                                    {/* <Box minWidth='30px' px={0.5}>
                                    <WelloFormItemLabel title='Date' />
                                  </Box> */}
                                    <TextField
                                      size='small'
                                      variant='outlined'
                                      type='date'
                                      id={`split_date${index}`}
                                      defaultValue={
                                        payments.Date
                                          ? moment(payments.Date).format(
                                              'YYYY-MM-DD'
                                            )
                                          : undefined
                                      }
                                      inputProps={{
                                        min: checkInDate,
                                        max: moment(new Date()).format(
                                          'YYYY-MM-DD'
                                        ),
                                        contentEditable: false,
                                      }}
                                      disabled={
                                        invoiceDetails?.invoice?.status ===
                                          'balanced' ||
                                        !(isGre() || isUnitAdmin()) ||
                                        payments.isDelete
                                      }
                                      onKeyDown={(e) => {
                                        e.preventDefault()
                                      }}
                                      onChange={(e) => {
                                        handleSplitDateChange(e, index)
                                        //   setPaymentDate(new Date(e.target.value))
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    paddingLeft={0.5}
                                    width={
                                      payments.isDelete || split.length === 1
                                        ? 131
                                        : 140
                                    }
                                  >
                                    {/* <Box minWidth='30px' px={0.5}>
                                    <WelloFormItemLabel title='Type' />
                                  </Box> */}

                                    <WelloSelectFHIRCoding
                                      availableCodings={
                                        getMemberShipAvailableCredit(
                                          subscriptionDetails.subscriptionCoverage!
                                        )! === 0 &&
                                        invoiceDetails?.invoice?.status !==
                                          'balanced'
                                          ? paymentOptionsForAdvance
                                          : paymentOptions
                                      }
                                      id={`split_billing_type${index}`}
                                      onChanged={(e) => {
                                        handlePaymentTypeChnage(e, index)
                                      }}
                                      preSelectedCoding={payments.paymentType}
                                      textFieldProps={{
                                        disabled:
                                          invoiceDetails?.invoice?.status ===
                                            'balanced' ||
                                          !(isGre() || isUnitAdmin()) ||
                                          payments.isDelete,
                                      }}
                                      selectedColumn={columns}
                                    />
                                  </Box>
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width={
                                      payments.isDelete || split.length === 1
                                        ? 130
                                        : 140
                                    }
                                  >
                                    <Box display='flex' flexDirection='column'>
                                      {/* <Box
                                      minWidth='70px'
                                      px={0.5}
                                      paddingRight={2}
                                    >
                                      <WelloFormItemLabel title='Amount' />
                                    </Box> */}
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        id={`split_payment${index}`}
                                        placeholder={`payment${payments.id}`}
                                        InputProps={{
                                          type: 'number',
                                          disabled: payments.isDelete,
                                          inputProps: {
                                            style: { textAlign: 'right' },
                                            min: 0,
                                            max: getBalanceAmountNumber(
                                              lineItems,
                                              totalDiscount,
                                              advance
                                            ),
                                          },
                                          startAdornment:
                                            getSymbolForCurrency(''),
                                        }}
                                        value={Number(
                                          split[index].amount.toFixed(2)
                                        )}
                                        onChange={(e) => {
                                          if (
                                            (payments.paymentType &&
                                              payments.paymentType.code !==
                                                'membership') ||
                                            payments.paymentType === undefined
                                          ) {
                                            if (
                                              Number(e.target.value) <=
                                              getBalanceAmountNumber(
                                                lineItems,
                                                totalDiscount,
                                                advance
                                              )
                                            ) {
                                              handleSplitAmountChange(e, index)
                                            } else if (
                                              /^\d+$/.test(e.target.value)
                                            ) {
                                              dispatch(
                                                showErrorAlert(
                                                  'Amount cannot be greater than Balance'
                                                )
                                              )
                                            }
                                          } else if (
                                            getMemberShipAvailableCredit(
                                              subscriptionDetails.subscriptionCoverage!
                                            )! !== 0
                                          ) {
                                            if (
                                              Number(e.target.value) <=
                                              getMemberShipAvailableCredit(
                                                subscriptionDetails.subscriptionCoverage!
                                              )!
                                            ) {
                                              if (
                                                Number(e.target.value) <=
                                                getPayableForMembership(
                                                  lineItems,
                                                  totalDiscount,
                                                  0,
                                                  advance
                                                )
                                              ) {
                                                handleSplitAmountChange(
                                                  e,
                                                  index
                                                )
                                              } else if (
                                                /^\d+$/.test(e.target.value)
                                              ) {
                                                dispatch(
                                                  showErrorAlert(
                                                    'Amount cannot be greater than Package charges including attendant charge and night charge'
                                                  )
                                                )
                                              }
                                            } else if (
                                              /^\d+$/.test(e.target.value)
                                            ) {
                                              dispatch(
                                                showErrorAlert(
                                                  'Amount cannot be greater than membership available credit'
                                                )
                                              )
                                            }
                                          } else if (
                                            getMemberShipAvailableCredit(
                                              subscriptionDetails.subscriptionCoverage!
                                            )! === 0
                                          ) {
                                            dispatch(
                                              showErrorAlert(
                                                'Membership balance is 0'
                                              )
                                            )
                                          }
                                        }}
                                        disabled={
                                          updatingStatus.requesting ||
                                          invoiceDetails?.invoice?.status ===
                                            'balanced' ||
                                          payments.isDelete
                                        }
                                      />
                                    </Box>
                                    {/* {getPaidAmountNumber(split) >
                                    getBalanceAmountNumber(
                                      lineItems,
                                      totalDiscount,
                                      advance
                                    ) && (
                                    <Box>
                                      {dispatch(
                                        showErrorAlert(
                                          'Amount cannot be greater than balance amount'
                                        )
                                      )}
                                    </Box>
                                  )} */}
                                  </Box>

                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width={
                                      payments.isDelete || split.length === 1
                                        ? 135
                                        : 135
                                    }
                                  >
                                    {/* <Box
                                    minWidth='70px'
                                    px={0.5}
                                    paddingRight={2}
                                  >
                                    <WelloFormItemLabel title='Reference' />
                                  </Box> */}
                                    <TextField
                                      size='small'
                                      variant='outlined'
                                      type='string'
                                      value={payments.paymentReference ?? ''}
                                      defaultValue={
                                        payments.paymentReference ?? ''
                                      }
                                      disabled={
                                        invoiceDetails?.invoice?.status ===
                                          'balanced' ||
                                        !(isGre() || isUnitAdmin()) ||
                                        payments.paymentType?.code ===
                                          'membership' ||
                                        payments.isDelete
                                      }
                                      id={`split_ref${index}`}
                                      inputProps={{ maxLength: 30 }}
                                      onChange={(e) => {
                                        if (e.target.value.length <= 30) {
                                          const regex = /^[0-9a-zA-Z(\-)]+$/
                                          if (
                                            e.target.value.match(regex) ||
                                            e.target.value === ''
                                          ) {
                                            if (
                                              checkForDuplicateReference(
                                                split,
                                                e.target.value
                                              ) === false
                                            ) {
                                              handleSplitPaymentRefrerence(
                                                e.target.value.trim(),
                                                index
                                              )
                                            } else {
                                              dispatch(
                                                showErrorAlert(
                                                  'Payment Reference can not be same'
                                                )
                                              )
                                            }
                                          }
                                        }
                                      }}
                                    />
                                  </Box>

                                  {split.length > 1 &&
                                    payments.isDelete === false && (
                                      <Box
                                        display='flex'
                                        px={0.5}
                                        width='5%'
                                        justifyContent='right'
                                        justifyItems='center'
                                        justifySelf='center'
                                        alignContent='center'
                                        alignItems='center'
                                        alignSelf='center'
                                      >
                                        <DeleteOutlineOutlined
                                          fontSize='small'
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          id={`split_delete${index}`}
                                          onClick={() => {
                                            setShowDeleteConfirmationForSplit({
                                              open: true,
                                              onContinueActionClicked: () => {
                                                setShowDeleteConfirmationForSplit(
                                                  {
                                                    open: false,
                                                  }
                                                )
                                                deleteItemSplit(index)
                                              },
                                              onCancelActionClicked: () => {
                                                setShowDeleteConfirmationForSplit(
                                                  {
                                                    open: false,
                                                  }
                                                )
                                              },
                                              displayText:
                                                'Are you sure you want to delete this record?',
                                              continueActionButtonText:
                                                'Delete',
                                              cancelActionButtonText: 'Cancel',
                                            })
                                          }}
                                        />
                                      </Box>
                                    )}
                                </Box>
                                {/* <Box
                                display='flex'
                                flexGrow={1}
                                width='100%'
                                pt={1}
                                pb={0.5}
                              >
                                {' '}
                                <Divider
                                  orientation='horizontal'
                                  variant='fullWidth'
                                  flexItem
                                  style={{ width: '100%', height: '1px' }}
                                  color='primary'
                                />
                              </Box> */}
                              </Box>
                            )
                          )}
                        </Box>
                      )}
                  </Box>

                  {getNetPayableAmount(
                    lineItems,
                    totalDiscount,
                    0,
                    advance,
                    split
                  ) > 0 &&
                    split.length < 4 && (
                      <Box>
                        <Button
                          variant='text'
                          onClick={handleAddMorePayment}
                          id='split_add_more'
                        >
                          <Typography variant='subtitle2' color='primary'>
                            + Add More
                          </Typography>
                        </Button>
                      </Box>
                    )}

                  {invoiceDetails?.invoice?.status === 'balanced' &&
                    isDischarged(ipdDetails.task!) && <Box py={1.5} />}

                  <Box display='flex' flexDirection='row' alignItems='flex-end'>
                    <Box
                      display='flex'
                      flexGrow={1}
                      flexDirection='row'
                      width='11.6%'
                      justifyContent='flex-end'
                      alignContent='center'
                      alignItems='center'
                      alignSelf='center'
                      px={2}
                    >
                      Paid
                    </Box>
                    <Box
                      display='flex'
                      minWidth='10.8%'
                      justifyContent='center'
                      alignContent='right'
                      alignItems='right'
                    >
                      <Box width='96%' />
                      <Box
                        width='5%'
                        justifyContent='flex-end'
                        justifyItems='center'
                        justifySelf='center'
                        alignContent='center'
                        alignItems='center'
                        alignSelf='center'
                        paddingLeft={5}
                        paddingRight={1.28}
                      >
                        <Typography variant='body2' color='initial'>
                          {getSymbolForCurrency('')}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      width='6.2%'
                      justifyContent='flex-end'
                      alignContent='right'
                      alignItems='right'
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        {getPaidAmount(advance, split)}
                      </Typography>
                      {/* <WelloFormItemLabel
                            title={getPaidAmount(advance, split)}
                          /> */}
                    </Box>

                    <Box p={0.5} width='4.75%' />
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='72%'
                      height='34px'
                    >
                      <Box minWidth='72px' py={1}>
                        <Box
                          py={1}
                          px={0.25}
                          display='flex'
                          flexDirection='row'
                        >
                          <Typography variant='subtitle1' color='initial'>
                            Doctor
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='row'
                        flexGrow={1}
                        maxWidth='230px'
                        minWidth={isUnitAdmin() ? '230px' : '200px'}
                        py={1}
                      >
                        <PractitionerSingleSelector
                          onPractitionerSelectionChanged={(e) => {
                            setDoctorRef(e.roleObject.id)
                          }}
                          disabled={
                            invoiceDetails?.invoice?.status === 'balanced' ||
                            !(isGre() || isUnitAdmin())
                          }
                          doctorListId='appointmentDoctor'
                          providerType='doctor,ayurveda,1255370008'
                          preSelectedPractitionerRoleId={getResponsibleDoctor(
                            invoiceDetails!
                          )}
                        />
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='row'
                      alignItems='flex-end'
                    >
                      <Box
                        display='flex'
                        flexGrow={1}
                        flexDirection='row'
                        justifyContent='flex-end'
                        alignContent='center'
                        alignItems='center'
                        alignSelf='center'
                        px={2}
                      >
                        Balance
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      minWidth='8.65%'
                      justifyContent='center'
                      alignContent='right'
                      alignItems='right'
                    >
                      <Box width='96%' />
                      <Box
                        width='5%'
                        justifyContent='flex-end'
                        justifyItems='center'
                        justifySelf='center'
                        alignContent='center'
                        alignItems='center'
                        alignSelf='center'
                        paddingLeft={5}
                        paddingRight={1.2}
                      >
                        <Typography variant='body2' color='initial'>
                          {getSymbolForCurrency('')}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      //   p={0.5}

                      width='6.35%'
                      justifySelf='center'
                      alignContent='center'
                      alignItems='center'
                      alignSelf='center'
                      justifyContent='flex-end'
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        {getBalanceAmount(
                          lineItems,
                          totalDiscount,
                          advance,
                          split
                        )}
                      </Typography>
                    </Box>

                    <Box p={0.5} width='4%' />
                  </Box>
                  <Box display='flex' flexDirection='row' alignItems='flex-end'>
                    <Box
                      display='flex'
                      flexGrow={1}
                      flexDirection='row'
                      width='11.6%'
                      justifyContent='flex-end'
                      alignContent='center'
                      alignItems='center'
                      alignSelf='center'
                      px={2}
                    >
                      Refund
                    </Box>

                    <Box
                      display='flex'
                      minWidth='8.9%'
                      justifyContent='center'
                      alignContent='right'
                      alignItems='right'
                    >
                      <Box width='96%' />
                      <Box
                        width='5%'
                        justifyContent='flex-end'
                        justifyItems='center'
                        justifySelf='center'
                        alignContent='center'
                        alignItems='center'
                        alignSelf='center'
                        paddingLeft={5}
                        paddingRight={1.1}
                      >
                        <Typography variant='body2' color='initial'>
                          {getSymbolForCurrency('')}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      width='6.36%'
                      alignContent='right'
                      alignItems='right'
                      justifyContent='flex-end'
                      paddingLeft={0.1}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        {getRefundString(
                          lineItems,
                          totalDiscount,
                          advance,
                          split
                        ).replace('-', '')}
                      </Typography>
                    </Box>

                    <Box p={0.5} width='4.69%' />
                  </Box>
                </Box>
              </Box>
            )}
          {getRefundAmount(lineItems, totalDiscount, advance, split) > 0 &&
            invoiceDetails?.invoice?.status === 'balanced' &&
            (isDischargeInitiated(ipdDetails.task!) ||
              isDischarged(ipdDetails.task!)) && (
              <Box display='flex' flexDirection='column' py={1}>
                <Box display='flex' flexDirection='row'>
                  <Typography variant='subtitle1' color='initial'>
                    Refund Information
                  </Typography>
                </Box>
                <Box display='flex' flexDirection='column' paddingLeft={3}>
                  {refund.map(
                    (payments: SplitPaymentDetails, index: number) => (
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        py={0.5}
                        px={6}
                        key={payments.id}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='83%'
                          justifyContent='space-between'
                        >
                          <Box display='flex' flexDirection='column'>
                            <Box minWidth='30px'>
                              <WelloFormItemLabel
                                title='Date'
                                id='refund_date'
                              />
                            </Box>
                            <TextField
                              size='small'
                              variant='outlined'
                              type='date'
                              id={`refund_date${index}`}
                              defaultValue={
                                payments.Date
                                  ? moment(payments.Date).format('YYYY-MM-DD')
                                  : undefined
                              }
                              inputProps={{
                                min: checkInDate,
                                max: moment(new Date()).format('YYYY-MM-DD'),
                                contentEditable: false,
                              }}
                              disabled={
                                invoiceDetails?.invoice?.status ===
                                  'balanced' ||
                                !(isGre() || isUnitAdmin()) ||
                                payments.Date !== undefined
                              }
                              onChange={(e) => {
                                e.preventDefault()
                                handleRefundDateChange(e, index)
                                //   setPaymentDate(new Date(e.target.value))
                              }}
                            />
                          </Box>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width={payments.isDelete ? 130 : 130}
                          >
                            <Box minWidth='30px'>
                              <WelloFormItemLabel title='Type' />
                            </Box>

                            <WelloSelectFHIRCoding
                              availableCodings={paymentOptionsForAdvance}
                              id={`refund_billing_type${index}`}
                              onChanged={(e) => {
                                handlePaymentTypeChangeForRefund(e, index)
                              }}
                              preSelectedCoding={payments.paymentType}
                              textFieldProps={{
                                disabled:
                                  invoiceDetails?.invoice?.status ===
                                    'balanced' || !(isGre() || isUnitAdmin()),
                              }}
                            />
                          </Box>

                          <Box
                            display='flex'
                            flexDirection='column'
                            width={payments.isDelete ? 130 : 130}
                          >
                            <Box minWidth='70px' paddingRight={2}>
                              <WelloFormItemLabel
                                title='Amount'
                                id='ref_amount'
                              />
                            </Box>
                            <TextField
                              size='small'
                              variant='outlined'
                              // onFocus={(e) => {
                              //   setDiscountInFocus(true)
                              // }}
                              // onBlur={(e) => {
                              //   setDiscountInFocus(false)
                              // }}
                              id={`refund_amount${index}`}
                              InputProps={{
                                type: 'tel',
                                disabled:
                                  getRefundAmount(
                                    lineItems,
                                    totalDiscount,
                                    advance,
                                    split
                                  ) > 0,
                                inputProps: {
                                  style: { textAlign: 'right' },
                                  min: 0,
                                  max: getNetPayableAmount(
                                    lineItems,
                                    totalDiscount,
                                    0,
                                    advance,
                                    split
                                  ),
                                },
                                startAdornment: getSymbolForCurrency(''),
                              }}
                              value={Number(
                                getRefundAmount(
                                  lineItems,
                                  totalDiscount,
                                  advance,
                                  split
                                ).toFixed(2)
                              )}
                              onChange={(e) => {
                                //
                              }}
                              disabled={
                                updatingStatus.requesting ||
                                invoiceDetails?.invoice?.status === 'balanced'
                              }
                            />
                          </Box>

                          <Box
                            display='flex'
                            flexDirection='column'
                            width={payments.isDelete ? 130 : 130}
                          >
                            <Box minWidth='70px' paddingRight={1}>
                              <WelloFormItemLabel
                                title='Reference'
                                id='ref_referrence'
                              />
                            </Box>
                            <TextField
                              size='small'
                              variant='outlined'
                              type='string'
                              id={`refund_ref_payment${index}`}
                              value={payments.paymentReference ?? ''}
                              defaultValue={payments.paymentReference ?? ''}
                              disabled={
                                invoiceDetails?.invoice?.status ===
                                  'balanced' || !(isGre() || isUnitAdmin())
                              }
                              inputProps={{ maxLength: 30 }}
                              onChange={(e) => {
                                if (e.target.value.length <= 30) {
                                  const regex = /^[0-9a-zA-Z(\-)]+$/
                                  if (
                                    e.target.value.match(regex) ||
                                    e.target.value === ''
                                  ) {
                                    handlePaymentReferenceForRefund(
                                      e.target.value.trim(),
                                      index
                                    )
                                  }
                                }
                              }}
                            />
                          </Box>
                        </Box>
                        {/* <Box
                          display='flex'
                          flexGrow={1}
                          width='100%'
                          pt={1}
                          pb={0.5}
                        >
                          {' '}
                          <Divider
                            orientation='horizontal'
                            variant='fullWidth'
                            flexItem
                            style={{ width: '100%', height: '1px' }}
                            color='primary'
                          />
                        </Box> */}
                      </Box>
                    )
                  )}
                </Box>
              </Box>
            )}
          <Box
            px={4}
            py={
              columns.includes('membership') &&
              getMemberShipAvailableCreditForSplit(
                subscriptionDetails.subscriptionCoverage!,
                split
              ) === 0
                ? 1
                : 0
            }
          >
            {columns.includes('membership') &&
              getMemberShipAvailableCreditForSplit(
                subscriptionDetails.subscriptionCoverage!,
                split
              ) === 0 && (
                <Alert severity='warning' id='membership_warmning'>
                  Membership Available credit is zero.
                </Alert>
              )}
          </Box>
          <RefundDialog
            id='notesTile_opd_refund123'
            open={showText}
            onAdd={(refundDetails: SplitPaymentDetails[]) => {
              handleRefund(refundDetails)

              setShowText(false)

              completePaymentDetials()
            }}
            onClose={() => {
              setShowText(false)
            }}
            refundAmount={getRefundAmount(
              lineItems,
              totalDiscount,
              advance,
              split
            )}
          />
          <RefundDialog
            id='notesTile_opd_refund'
            open={showFullPayment}
            onAdd={(refundDetails: SplitPaymentDetails[]) => {
              handleRefund(refundDetails)
              setShowFullPayment(false)
              handlePaymentDetails()
            }}
            onClose={() => {
              setShowFullPayment(false)
            }}
            refundAmount={getRefundAmount(
              lineItems,
              totalDiscount,
              advance,
              split
            )}
          />
          <NoteWarningDialog
            open={overStay}
            onClose={() => {
              setMessage('')
              setOverStay(false)
            }}
            notesData={message}
            dialogText='Overstay warning'
          />
          <NoteWarningDialog
            open={overStayMessage}
            onClose={() => {
              setMessage('')
              setOverStayMessage(false)
            }}
            notesData={message}
            dialogText='Overstay warning'
          />
          {showDeleteConfirmation.open && (
            <ConfirmationDialog {...showDeleteConfirmation} id='delete' />
          )}
          {showQtyConfirmationForOverStay.open && (
            <ConfirmationDialog
              {...showQtyConfirmationForOverStay}
              id='overstay'
            />
          )}

          {showDeleteConfirmationForSplit.open && (
            <ConfirmationDialog
              {...showDeleteConfirmationForSplit}
              id='split'
            />
          )}
          {showDeleteConfirmationForAdvance.open && (
            <ConfirmationDialog
              {...showDeleteConfirmationForAdvance}
              id='advance'
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {(fetchingStatus.requesting ||
          updatingStatus.requesting ||
          generatingStatus.requesting ||
          paymentUpdateStatus.requesting) && <CircularProgress size={25} />}
        {!fetchingStatus.requesting &&
          !updatingStatus.requesting &&
          !paymentUpdateStatus.requesting &&
          isDischargeInitiated(ipdDetails.task!) &&
          invoiceDetails?.invoice?.status !== 'balanced' &&
          getBalanceAmountForSplitPayment(lineItems, totalDiscount, advance) >
            0 &&
          (isGre() || isUnitAdmin()) && (
            <Button
              variant='contained'
              color='primary'
              id='save_complete'
              disabled={
                fetchingStatus.requesting ||
                fetchingStatus.requestError ||
                !isSplitButtonPaymentEnabled() ||
                getBalanceAmountNumber(
                  lineItems,
                  totalDiscount,
                  advance,
                  split
                ) > 0 ||
                doctorRef === undefined
              }
              onClick={() => {
                if (
                  getRefundAmount(lineItems, totalDiscount, advance, split) > 0
                ) {
                  setShowFullPayment(true)
                } else {
                  handlePaymentDetails()
                }
              }}
            >
              Save & Complete Payment
            </Button>
          )}

        {!fetchingStatus.requesting &&
          !updatingStatus.requesting &&
          !paymentUpdateStatus.requesting &&
          isDischargeInitiated(ipdDetails.task!) &&
          invoiceDetails?.invoice?.status !== 'balanced' &&
          getBalanceAmountForSplitPayment(lineItems, totalDiscount, advance) <=
            0 &&
          (isGre() || isUnitAdmin()) && (
            <Button
              variant='contained'
              color='primary'
              id='final_complete'
              disabled={
                fetchingStatus.requesting ||
                fetchingStatus.requestError ||
                doctorRef === undefined
              }
              onClick={() => {
                if (
                  getRefundAmount(lineItems, totalDiscount, advance, split) > 0
                ) {
                  setShowText(true)
                } else {
                  completePaymentDetials()
                }
              }}
            >
              Save & Complete Payment
            </Button>
          )}

        {!fetchingStatus.requesting &&
          !updatingStatus.requesting &&
          !paymentUpdateStatus.requesting &&
          invoiceDetails?.invoice?.status === 'balanced' && (
            <Button
              onClick={() => {
                handleInvoiceGenerationButton()
              }}
              variant='outlined'
              disableElevation
              id='final_invoice'
            >
              Generate Invoice
            </Button>
          )}

        {!fetchingStatus.requesting &&
          !updatingStatus.requesting &&
          !paymentUpdateStatus.requesting &&
          invoiceDetails?.invoice?.status !== 'balanced' && (
            <Button
              onClick={() => {
                handleUpdateButton(true)
              }}
              variant='outlined'
              disableElevation
              id='save'
              disabled={
                !isEditionAllowed() ||
                !isDataChanged() ||
                generatingStatus.requesting ||
                isLineItemNameEmpty() ||
                isLineItemPriceEmpty()
              }
            >
              Save
            </Button>
          )}

        <Button
          onClick={() => {
            onClose()
            resetDetails()
          }}
          variant='outlined'
          disableElevation
          id='cancel'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
