/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { SadhkaResponse } from 'models/sadkahResponse'
import { UnitServices } from 'models/units/unitServices'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  finshDayCare,
  resetStateForIPdFinishDayCare,
} from 'redux/clinic/dayCare/dayCareFinsih/dayCareFinshSlice'
import {
  registerDayCare,
  resetDayCareRegister,
} from 'redux/clinic/dayCare/ipdRegister/dayCareRegisterSlice'
import { resetPriceSearch } from 'redux/clinic/ipdmanagement/roomPriceSearch/roomPriceSearchSlice'
import { resetUpdateUserState } from 'redux/patient/addVisaDetails/addVisaDetailsSlice'
import { RootState } from 'redux/rootReducer'
import { isCurrentUnitIsWellonessCenter } from 'services/userDetailsService'
import { answerOptions, purposeOfVisit, questions } from 'utils/constants'
import {
  getAgeOfPatientInYears,
  getExtensionValueOfUrlPatient,
} from 'utils/fhirResourcesHelper'
import { getDateDifferceinDays } from 'utils/fhirResoureHelpers/ipdHelper'
import {
  inBetWeenTime,
  isCurrentDate,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { ConfirmationDialogMessage } from 'views/components/LeftMenu/confirmationDialog'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { PatientTile } from 'views/components/patients/pateintTile'
import { PatientSearchByText } from 'views/components/patients/patientSearchByText'
import { PatientTileSplit } from 'views/components/patients/patientTileSplit'
import { WelloTextField } from 'wello-web-components'
import { AddVisaDetails } from '../addVisaDetails'
import { ConsentDialog } from '../consentDialog'
import { DayCarePriceSummary } from './dayCarePriceSummary'

const useStyles = makeStyles((theme) => ({
  datepicker: {
    margin: '2px 0px 2px 0px',
    height: '60px',
    // width: 'fit-content',
    padding: '20px 15px 10px',
    borderBottom: '2px solid blue',
    backgroundColor: '#fff',
    color: '#2c2c2c',
    width: 300,
    fontWeight: 600,
  },
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface Props {
  id: number
  open: boolean
  onClose: () => void
  onLabOrderCreated: (ipdId: string, paymentType: string) => void
  locationId?: string
  preselectedPatient?: R4.IPatient
  partnerLabUser?: R4.IPractitionerRole
  referralId?: string
}

export const DayCareManagement: React.FC<Props> = ({
  id,
  open,
  onClose,
  onLabOrderCreated,
  locationId,
  partnerLabUser,
  preselectedPatient,
  referralId,
}) => {
  const ipdRegisterSlice = useSelector(
    (state: RootState) => state.dayCareegisterSlice
  )

  const roomNumberSearchSlice = useSelector(
    (state: RootState) => state.roomNoSearchSlice
  )

  const ipdFinsih = useSelector((state: RootState) => state.dayCareFinsih)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const classes = useStyles()
  const [selectedPatient, setSelectedPatient] =
    useState<R4.IPatient | undefined>(preselectedPatient)
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(
    preselectedPatient === undefined
  )
  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice.unitServices
  )

  let unitServices: UnitServices[] = []

  if (unitsOfOrganizationSlice && unitsOfOrganizationSlice.length > 0) {
    unitServices = unitsOfOrganizationSlice
  }

  const [selectedServiceMedicine, setSelectedServiceMedicine] =
    useState<R4.ICoding>(
      isCurrentUnitIsWellonessCenter()
        ? {
            system: 'http://snomed.info/sct',
            code: '439809005',
            display: 'Naturopathy',
          }
        : {
            system: 'http://snomed.info/sct',
            code: '1259939000',
            display: 'Ayurveda medicine',
          }
    )

  const [cancel, setCancel] = React.useState<boolean>(false)
  const [serviceRequest, setServiceRequest] = useState<string>()

  const [travelDate1, setTravelDate1] = React.useState<string>('')
  const [travelDate2, setTravelDate2] = React.useState<string>('')
  const [travelDate3, setTravelDate3] = React.useState<string>('')
  const [visaDataFinal, setVisaDataFinal] = useState<string>()
  const [travelDate4, setTravelDate4] = React.useState<string>(
    moment().subtract(2, 'days').format('YYYY-MM-DD')
  )
  const [travelDate5, setTravelDate5] = React.useState<string>(
    moment().subtract(2, 'days').format('YYYY-MM-DD')
  )
  const [travelDate6, setTravelDate6] = React.useState<string>(
    moment().subtract(2, 'days').format('YYYY-MM-DD')
  )

  const [foreign, setForeign] = useState<boolean>(true)
  const [sadhaFinsh, setSadhakFinish] = useState<SadhkaResponse>()

  const sadhKaRef = useRef<SadhkaResponse>()

  let sadhakModelData: SadhkaResponse[] = []
  const [showConditions, setShowConditions] = useState<boolean>(false)

  const [value, setValue] = useState('file')
  const [photoType, setPhotoType] = useState('both')

  const res = sessionStorage.getItem('sadhakList')
  if (res !== null) sadhakModelData = JSON.parse(res)

  const [selectedRoomType, setSelectedRoomType] = useState<R4.ICoding>()
  const [selectedCharef, setSelectedChargeDef] =
    useState<R4.IChargeItemDefinition>()
  const [selectedVistPurpose, setSelectedVisitPurpose] = useState<R4.ICoding>(
    purposeOfVisit[0]
  )
  const [count, setCount] = React.useState<number>(0)
  const [addShadka, setAddShadka] = useState<boolean>(false)
  const [discount, setDiscount] = React.useState<number>(0)
  const [roomNo, setRoomNo] = React.useState<string>()
  const [startDate, setStartDate] = React.useState(new Date())

  const [endDate, setEndDate] = React.useState('')

  const [checkedQuestion, setCheckedQuestion] = useState<boolean>(false)

  const handleChangeQuestion = (event: any) => {
    setCheckedQuestion(event.target.checked)
  }

  const [questionResponse, setQuestionResponse] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([
    {
      linkId: questions.item[0].linkId,
      text: questions.item[0].text,
    },
  ])
  const [questionResponse1, setQuestionResponse1] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse3, setQuestionResponse3] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse4, setQuestionResponse4] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse2, setQuestionResponse2] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([
    {
      linkId: '3',
      text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
    },
  ])

  const [innerQuestionResponse1, setInnerQuestionResponse1] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: questions.item[0].item[0].linkId,
      text: questions.item[0].item[0].text,
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })

  function isDatesCorrect(): boolean | undefined {
    if (travelDate1.length > 0 && travelDate4 && travelDate1 > travelDate4) {
      return true
    }
    return false
  }

  function isDatesCorrectFor2nd(): boolean | undefined {
    if (
      travelDate2.length > 0 &&
      travelDate5.length > 0 &&
      travelDate2 > travelDate5
    ) {
      return true
    }
    return false
  }

  function isDatesCorrectFor3rd(): boolean | undefined {
    if (travelDate3.length > 0 && travelDate6 && travelDate3 > travelDate6) {
      return true
    }
    return false
  }

  const [innerQuestionResponse2, setInnerQuestionResponse2] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: questions.item[0].item[1].linkId,
      text: questions.item[0].item[1].text,
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })
  const [innerQuestionResponse3, setInnerQuestionResponse3] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: questions.item[0].item[2].linkId,
      text: questions.item[0].item[2].text,
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })
  const [innerQuestionResponse4, setInnerQuestionResponse4] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: questions.item[0].item[3].linkId,
      text: questions.item[0].item[3].text,
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })
  const [innerQuestionResponse5, setInnerQuestionResponse5] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse6, setInnerQuestionResponse6] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse7, setInnerQuestionResponse7] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse8, setInnerQuestionResponse8] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: '3.1',
      text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })
  const [innerQuestionResponse9, setInnerQuestionResponse9] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse10, setInnerQuestionResponse10] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse11, setInnerQuestionResponse11] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse12, setInnerQuestionResponse12] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse13, setInnerQuestionResponse13] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse14, setInnerQuestionResponse14] =
    React.useState<R4.IQuestionnaireResponse_Item>()

  const [checked, setChecked] = React.useState(false)

  const [visaDetails, setVisaDetails] = useState<boolean>(false)

  const handleChange = (event: any) => {
    setChecked(event.target.checked)
  }

  const handleChangeForForegin = (event: any) => {
    setCheckedForForeign(event.target.checked)
    setVisaDetails(true)
  }

  const [checkedForForegin, setCheckedForForeign] = useState<boolean>(false)

  function isEnableCreateButton(): boolean | undefined {
    if (
      selectedPatient &&
      selectedCharef &&
      getAgeOfPatientInYears(selectedPatient) >= 12 &&
      getAgeOfPatientInYears(selectedPatient) <= 80
    ) {
      return true
    }
    return false
  }

  function isCovidDataFilledForOne(): boolean {
    if (
      ((innerQuestionResponse5 || innerQuestionResponse7) &&
        innerQuestionResponse6 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0] &&
        innerQuestionResponse6.answer[0].valueString === undefined) ||
      ((innerQuestionResponse5 || innerQuestionResponse7) &&
        innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0] &&
        innerQuestionResponse6.answer[0].valueString?.length === 0) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0].valueString &&
        innerQuestionResponse5 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0] &&
        innerQuestionResponse6.answer[0].valueString?.length === 0 &&
        innerQuestionResponse7 &&
        innerQuestionResponse5 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer === undefined &&
        innerQuestionResponse7 &&
        innerQuestionResponse5 === undefined) ||
      (innerQuestionResponse6 === undefined &&
        innerQuestionResponse7 &&
        innerQuestionResponse5 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0].valueString &&
        innerQuestionResponse7 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0] &&
        innerQuestionResponse6.answer[0].valueString?.length === 0 &&
        innerQuestionResponse5 &&
        innerQuestionResponse7 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer === undefined &&
        innerQuestionResponse5 &&
        innerQuestionResponse7 === undefined) ||
      (innerQuestionResponse6 === undefined &&
        innerQuestionResponse5 &&
        innerQuestionResponse7 === undefined)
    ) {
      return true
    }
    return false
  }

  function isCovidDataFilledForTwo(): boolean {
    if (
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0] &&
        innerQuestionResponse9.answer[0].valueString === undefined) ||
      ((innerQuestionResponse10 || innerQuestionResponse11) &&
        innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0] &&
        innerQuestionResponse9.answer[0].valueString?.length === 0) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0].valueString &&
        innerQuestionResponse10 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0] &&
        innerQuestionResponse9.answer[0].valueString?.length === 0 &&
        innerQuestionResponse11 &&
        innerQuestionResponse10 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer === undefined &&
        innerQuestionResponse11 &&
        innerQuestionResponse10 === undefined) ||
      (innerQuestionResponse9 === undefined &&
        innerQuestionResponse11 &&
        innerQuestionResponse10 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0].valueString &&
        innerQuestionResponse11 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0] &&
        innerQuestionResponse9.answer[0].valueString?.length === 0 &&
        innerQuestionResponse10 &&
        innerQuestionResponse11 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer === undefined &&
        innerQuestionResponse10 &&
        innerQuestionResponse11 === undefined) ||
      (innerQuestionResponse9 === undefined &&
        innerQuestionResponse10 &&
        innerQuestionResponse11 === undefined) ||
      ((innerQuestionResponse10 || innerQuestionResponse11) &&
        innerQuestionResponse9 === undefined)
    ) {
      return true
    }
    return false
  }

  function isCovidDataFilledForThree(): boolean {
    if (
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0] &&
        innerQuestionResponse12.answer[0].valueString === undefined) ||
      ((innerQuestionResponse13 || innerQuestionResponse14) &&
        innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0] &&
        innerQuestionResponse12.answer[0].valueString?.length === 0) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0].valueString &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0] &&
        innerQuestionResponse12.answer[0].valueString?.length === 0 &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0] &&
        innerQuestionResponse12.answer[0].valueString?.length === 0 &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      ((innerQuestionResponse13 || innerQuestionResponse14) &&
        innerQuestionResponse12 === undefined)
    ) {
      return true
    }
    return false
  }

  function isFinsihButtonEnabled(): boolean | undefined {
    if (
      questionResponse.length > 0 &&
      questionResponse2.length > 0 &&
      innerQuestionResponse1 &&
      innerQuestionResponse2 &&
      innerQuestionResponse3 &&
      innerQuestionResponse4 &&
      innerQuestionResponse8 &&
      checked
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (ipdRegisterSlice.additionSuccessful) {
      sadhKaRef.current = undefined
      setServiceRequest(ipdRegisterSlice.serviceRequest ?? '')
      dispatch(resetPriceSearch())
    }

    if (ipdFinsih.additionSuccessful) {
      setTravelDate1('')
      setTravelDate2('')
      setTravelDate3('')
      setTravelDate4('')
      setTravelDate5('')
      setTravelDate6('')
      setQuestionResponse([
        {
          linkId: questions.item[0].linkId,
          text: questions.item[0].text,
        },
      ])
      setQuestionResponse1([])
      setQuestionResponse2([
        {
          linkId: '3',
          text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
        },
      ])
      setInnerQuestionResponse1({
        linkId: questions.item[0].item[0].linkId,
        text: questions.item[0].item[0].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse2({
        linkId: questions.item[0].item[1].linkId,
        text: questions.item[0].item[1].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse3({
        linkId: questions.item[0].item[2].linkId,
        text: questions.item[0].item[2].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse4({
        linkId: questions.item[0].item[3].linkId,
        text: questions.item[0].item[3].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse8({
        linkId: '3.1',
        text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse5(undefined)
      setInnerQuestionResponse6(undefined)
      setInnerQuestionResponse7(undefined)
      setInnerQuestionResponse9(undefined)
      setInnerQuestionResponse10(undefined)
      setInnerQuestionResponse11(undefined)
      setInnerQuestionResponse12(undefined)
      setInnerQuestionResponse13(undefined)
      setInnerQuestionResponse14(undefined)
    }

    if (ipdFinsih.additionSuccessful) {
      dispatch(resetDayCareRegister())
      dispatch(resetStateForIPdFinishDayCare())
      setChecked(false)
      if (onLabOrderCreated) {
        onLabOrderCreated('1', '')
        resetForm()
      }
    }
    // resetForm()
  }, [ipdFinsih.additionSuccessful, ipdRegisterSlice, onLabOrderCreated])

  function resetForm() {
    setSelectedServiceMedicine(
      isCurrentUnitIsWellonessCenter()
        ? {
            system: 'http://snomed.info/sct',
            code: '439809005',
            display: 'Naturopathy',
          }
        : {
            system: 'http://snomed.info/sct',
            code: '1259939000',
            display: 'Ayurveda medicine',
          }
    )
    setTravelDate1('')
    setTravelDate2('')
    setTravelDate3('')
    setTravelDate4(moment().subtract(2, 'days').format('YYYY-MM-DD'))
    setTravelDate5(moment().subtract(2, 'days').format('YYYY-MM-DD'))
    setTravelDate6(moment().subtract(2, 'days').format('YYYY-MM-DD'))
    setChecked(false)
    setCheckedForForeign(false)
    setSadhakFinish(undefined)
    setVisaDataFinal(undefined)
    sadhKaRef.current = undefined
    setIsPatientEditMode(true)
    setSelectedRoomType(undefined)
    setSelectedPatient(undefined)
    setEndDate('')
    setCount(0)
    setChecked(false)
    setRoomNo(undefined)
    setVisaDetails(false)
    setForeign(true)

    setValue('file')
    setPhotoType('both')
    setStartDate(new Date())
    setDiscount(0)
    setEndDate('')
    setSelectedVisitPurpose(purposeOfVisit[0])
    setSadhakFinish(undefined)
    const res1 = sessionStorage.getItem('sadhakList')
    if (res1 !== null) sessionStorage.removeItem('sadhakList')
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
        dispatch(resetDayCareRegister())
        dispatch(resetStateForIPdFinishDayCare())
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Day Care Registration</Box>
          <Box>
            <CloseOutlined
              id={`${id}close_icon`}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                setCancel(true)
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        {!ipdRegisterSlice.additionSuccessful && (
          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              flexDirection='column'
              flexGrow={4}
              maxWidth={width * 0.65}
            >
              <Box display='flex' flexDirection='row'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <WelloFormItemLabel
                      title={
                        selectedPatient
                          ? 'Selected Patient'
                          : t('labelCommon:select_patient')
                      }
                    />
                    {!isPatientEditMode && preselectedPatient === undefined && (
                      <Button
                        id={`${id}but_lab_ord_cre_modify_pat`}
                        variant='text'
                        color='primary'
                        onClick={(e) => {
                          setIsPatientEditMode(true)
                          setSelectedPatient(undefined)
                          setSelectedRoomType(undefined)
                          setSelectedServiceMedicine(
                            isCurrentUnitIsWellonessCenter()
                              ? {
                                  system: 'http://snomed.info/sct',
                                  code: '439809005',
                                  display: 'Naturopathy',
                                }
                              : {
                                  system: 'http://snomed.info/sct',
                                  code: '1259939000',
                                  display: 'Ayurveda medicine',
                                }
                          )
                        }}
                      >
                        Modify
                      </Button>
                    )}
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='row'>
                      {isPatientEditMode && (
                        <PatientSearchByText
                          id={4}
                          onNewPatientButtonClicked={() => {}}
                          onPatientSelected={(patient) => {
                            getAgeOfPatientInYears(patient)
                            setSelectedPatient(patient)
                            setIsPatientEditMode(false)
                            if (
                              getExtensionValueOfUrlPatient(
                                patient.extension ?? [],
                                'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                              ) !== 'IN' &&
                              getExtensionValueOfUrlPatient(
                                patient.extension ?? [],
                                'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                              ) !== 'India' &&
                              getExtensionValueOfUrlPatient(
                                patient.extension ?? [],
                                'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                              ) !== 'india'
                            ) {
                              setVisaDetails(true)
                            }
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    {!isPatientEditMode && selectedPatient && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <PatientTileSplit patient={selectedPatient} />

                        {getExtensionValueOfUrlPatient(
                          selectedPatient
                            ? selectedPatient.extension ?? []
                            : [],
                          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                        ) !== 'IN' &&
                          getExtensionValueOfUrlPatient(
                            selectedPatient
                              ? selectedPatient.extension ?? []
                              : [],
                            'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                          ) !== 'India' &&
                          getExtensionValueOfUrlPatient(
                            selectedPatient
                              ? selectedPatient.extension ?? []
                              : [],
                            'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                          ) !== 'india' &&
                          visaDataFinal === undefined && (
                            <Box py={1} display='flex' flexDirection='row'>
                              <Checkbox
                                edge='start'
                                checked={checkedForForegin}
                                onChange={handleChangeForForegin}
                                tabIndex={-1}
                                size='small'
                                disableRipple
                                color='primary'
                                inputProps={{ 'aria-labelledby': 'consent' }}
                                id={`${id}check_consent`}
                              />
                              <Box py={1.3}>
                                <Typography variant='subtitle2' color='primary'>
                                  Add Visa Details
                                </Typography>
                              </Box>
                            </Box>
                          )}
                      </Box>
                    )}
                  </Box>
                  {ipdRegisterSlice.error && (
                    <Box>
                      <Typography variant='subtitle2' color='error'>
                        {ipdRegisterSlice.patientError
                          ? ipdRegisterSlice.patientError.length > 0
                            ? ipdRegisterSlice.patientError
                            : ''
                          : ''}
                      </Typography>
                    </Box>
                  )}
                  {selectedPatient &&
                    getAgeOfPatientInYears(selectedPatient) < 12 && (
                      <Box>
                        <Typography variant='subtitle2' color='error'>
                          Must be at least 12 years old
                        </Typography>
                      </Box>
                    )}{' '}
                  {selectedPatient &&
                    getAgeOfPatientInYears(selectedPatient) > 80 && (
                      <Box>
                        <Typography variant='subtitle2' color='error'>
                          Should not be greater than 80 years old
                        </Typography>
                      </Box>
                    )}
                  {!inBetWeenTime(
                    moment().format('HH:mm'),
                    '08:00',
                    '18:00'
                  ) && (
                    <Box py={1}>
                      <Typography variant='subtitle2' color='error'>
                        Appointment can be booked in between 08:00 AM to 6:00 PM
                      </Typography>
                    </Box>
                  )}
                  {/* {inBetWeenTime(
                    moment().format('HH:mm'),
                    '08:00',
                    '22:00'
                  ) && (
                    <Box py={1}>
                      <Typography variant='subtitle2' color='error'>
                        Appointment can be booked in between 08:00 AM to 6:00 PM
                      </Typography>
                    </Box>
                  )} */}
                  <Box
                    display='flex'
                    flexDirection='column'
                    my={2}
                    width='100%'
                  >
                    {selectedPatient && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='100%'>
                          {selectedPatient && (
                            <Box
                              id='12'
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='35%'
                                paddingRight={1}
                              >
                                <WelloSelectFHIRCodingWithAutocomplete
                                  title='Purpose of visit'
                                  id={`${id}ipd_purpose_of_visit`}
                                  availableCodings={purposeOfVisit}
                                  onChanged={(type) => {
                                    setSelectedVisitPurpose(type)
                                  }}
                                  preSelectedCoding={selectedVistPurpose}
                                />
                              </Box>
                              {/* {unitServices && unitServices.length > 0 && (
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='35%'
                                  paddingLeft={1}
                                >
                                  <WelloSelectFHIRCoding
                                    title='System of Medicine'
                                    //   id='ipd_purpose_of_visit'
                                    id={`${id}systemOfMedicine`}
                                    availableCodings={
                                      unitServices[0].systemOfMedicine ?? []
                                    }
                                    role='System of Medicine'
                                    // duration='System of Medicine'
                                    onChanged={(type) => {
                                      setSelectedServiceMedicine(type)
                                    }}
                                    // preSelectedCoding={
                                    //   unitServices[0].systemOfMedicine
                                    //     ? unitServices[0].systemOfMedicine[0]
                                    //     : undefined
                                    // }
                                    textFieldProps={{
                                      size: 'small',
                                      fullWidth: true,
                                    }}
                                  />
                                </Box>
                              )} */}
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='30%'
                                paddingLeft={1}
                                paddingRight={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <WelloFormItemLabel title='Discount (%)' />
                                  <WelloTextFieldWithoutTitle
                                    textProps={{
                                      id: `${id}_discount`,
                                      defaultValue: 0,
                                      type: 'number',
                                      inputProps: {
                                        max: 100,
                                        step: 0.25,
                                        min: 0,
                                      },
                                      error:
                                        discount < 0 ||
                                        discount > 100 ||
                                        Number.isNaN(discount),
                                      helperText:
                                        discount < 0 ||
                                        discount > 100 ||
                                        Number.isNaN(discount)
                                          ? 'Discount between 1 to 100'
                                          : '',
                                      onChange: (discounData) => {
                                        setDiscount(
                                          parseFloat(
                                            parseFloat(
                                              discounData.target.value
                                            ).toFixed(2)
                                          )
                                        )
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          )}

                          {selectedPatient && (
                            <Box display='flex' flexDirection='column'>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                my={2}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <WelloFormItemLabel title='Price Summary' />
                                  <DayCarePriceSummary
                                    days={getDateDifferceinDays(
                                      moment(startDate).format('DD-MM-YYYY'),
                                      moment(endDate).format('DD-MM-YYYY')
                                    )}
                                    discount={discount}
                                    onPriceFetched={(
                                      charDef: R4.IChargeItemDefinition
                                    ) => {
                                      setSelectedChargeDef(charDef)
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* {selectedPatient && (
              <Box
                display='flex'
                flexDirection='column'
                paddingLeft={2}
                minWidth='400px'
                maxWidth='400px'
              >
                <Box display='flex' flexDirection='row' width='100%' my={2}>
                  <DayCarePriceSummary
                    days={getDateDifferceinDays(
                      moment(startDate).format('DD-MM-YYYY'),
                      moment(endDate).format('DD-MM-YYYY')
                    )}
                    discount={discount}
                    onPriceFetched={(charDef: R4.IChargeItemDefinition) => {
                      setSelectedChargeDef(charDef)
                    }}
                  />
                </Box>
              </Box>
            )} */}
          </Box>
        )}

        {ipdRegisterSlice.additionSuccessful && (
          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              flexDirection='column'
              flexGrow={4}
              maxWidth={width * 0.65}
            >
              <Box
                display='flex'
                flexDirection='column'
                // alignContent='center'
                alignItems='flex-start'
                width='100%'
              >
                <Box display='flex' flexDirection='row'>
                  <Box>
                    <Checkbox
                      checked={checkedQuestion}
                      onClick={handleChangeQuestion}
                      size='small'
                      role='checkbox1'
                      id='checkbox_advance'
                      style={{
                        cursor: 'pointer',
                        paddingLeft: 0,
                      }}
                      disableRipple
                      color='primary'
                    />
                  </Box>
                  <Box py={1.1}>
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: 'black',
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}
                    >
                      COVID 19 Questionnaire
                    </Typography>
                  </Box>
                </Box>
                {!ipdFinsih.additionSuccessful && checkedQuestion && (
                  <Box width='100%'>
                    {questions.item.map((itemData, index) => (
                      <Box key={itemData.linkId}>
                        {itemData.linkId === '1.1' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{ color: 'black' }}
                            >
                              1. {itemData.text}
                            </Typography>

                            {itemData.item && itemData.item.length > 0 && (
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                {itemData.item[0].type === 'choice' &&
                                  itemData.item[0].linkId === '1.1.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='35%'
                                      paddingRight={1}
                                    >
                                      <WelloSelectFHIRCoding
                                        id={`${id}0patientans1`}
                                        title={itemData.item[0].text}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.item[0].linkId,
                                              text: itemData.item[0].text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse1(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}

                                {itemData.item[1].type === 'choice' &&
                                  itemData.item[1].linkId === '1.1.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='21.66%'
                                      px={1}
                                    >
                                      <WelloSelectFHIRCoding
                                        title={itemData.item[1].text}
                                        id={`${id}1patientans2`}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.item[1].linkId,
                                              text: itemData.item[1].text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse2(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}

                                {itemData.item[2].type === 'choice' &&
                                  itemData.item[2].linkId === '1.1.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='21.66%'
                                      px={1}
                                    >
                                      <WelloSelectFHIRCoding
                                        title={itemData.item[2].text}
                                        id={`${id}2patientans3`}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.item[2].linkId,
                                              text: itemData.item[2].text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse3(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}

                                {itemData.item[3].type === 'choice' &&
                                  itemData.item[3].linkId === '1.1.4' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='21.66%'
                                      paddingLeft={1}
                                    >
                                      <WelloSelectFHIRCoding
                                        id={`${id}3patientans4`}
                                        title={itemData.item[3].text}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.item[3].linkId,
                                              text: itemData.item[3].text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse4(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}
                              </Box>
                            )}
                          </Box>
                        )}

                        {itemData.linkId === '2' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            py={2}
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{ color: 'black' }}
                            >
                              2. {itemData.text}
                            </Typography>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              py={1}
                            >
                              {itemData.item.map((qIn1, qindex) => (
                                <Box
                                  key={qIn1.linkId}
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  {qIn1.linkId === '2.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      px={1}
                                    >
                                      <WelloFormItemLabel title={qIn1.text} />
                                      <WelloTextFieldWithoutTitle
                                        textProps={{
                                          id: `${id}patient_country1`,

                                          type: 'text',
                                          placeholder: 'Country/City',

                                          onChange: (discounData) => {
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse1([outerRes])
                                            }
                                            if (
                                              discounData.target.value.length >
                                              0
                                            ) {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString:
                                                    discounData.target.value,
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse6(
                                                innerRes
                                              )
                                            } else {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: '',
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse6(
                                                innerRes
                                              )
                                            }
                                          },
                                        }}
                                      />
                                      {(innerQuestionResponse5 ||
                                        innerQuestionResponse7) &&
                                        innerQuestionResponse6 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0] &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString === undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {/* <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='error'
                                        >
                                          {innerQuestionResponse6
                                            ? innerQuestionResponse6.answer
                                              ? innerQuestionResponse6.answer[0]
                                                ? innerQuestionResponse6
                                                    .answer[0].valueString
                                                  ? innerQuestionResponse6
                                                      .answer[0].valueString
                                                  : innerQuestionResponse6
                                                      .answer[0].valueString
                                                      ?.length
                                                : '2'
                                              : '3'
                                            : '4'}
                                        </Typography>
                                      </Box> */}

                                      {(innerQuestionResponse5 ||
                                        innerQuestionResponse7) &&
                                        innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0] &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString?.length === 0 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        paddingRight={1}
                                      >
                                        <WelloFormItemLabel title={qIn1.text} />
                                        <WelloTextFieldWithoutTitle
                                          textProps={{
                                            id: `${id}patient_arrival_date1`,
                                            type: 'date',
                                            inputProps: {
                                              max: moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ),
                                              min: isCurrentDate(travelDate4)
                                                ? moment()
                                                    .subtract(2, 'days')
                                                    .format('YYYY-MM-DD')
                                                : travelDate4,
                                            },
                                            onKeyDown: (e) => {
                                              e.preventDefault()
                                            },
                                            onChange: (changedValue) => {
                                              changedValue.preventDefault()
                                              setTravelDate1(
                                                moment(
                                                  changedValue.target.value
                                                ).format('YYYY-MM-DD')
                                              )
                                              const outerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: itemData.linkId,
                                                  text: itemData.text,
                                                }
                                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                                questionResponse1.filter(
                                                  (d) => d.linkId === '2'
                                                )
                                              if (outerFilter.length === 0) {
                                                setQuestionResponse1([outerRes])
                                              }

                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: moment(
                                                    changedValue.target.value
                                                  ).format('DD-MM-YYYY'),
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse5(
                                                innerRes
                                              )
                                            },
                                          }}
                                        />
                                        {innerQuestionResponse6 &&
                                          innerQuestionResponse6.answer &&
                                          innerQuestionResponse6.answer[0]
                                            .valueString &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse6 &&
                                          innerQuestionResponse6.answer &&
                                          innerQuestionResponse6.answer[0] &&
                                          innerQuestionResponse6.answer[0]
                                            .valueString?.length === 0 &&
                                          innerQuestionResponse7 &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse6 &&
                                          innerQuestionResponse6.answer ===
                                            undefined &&
                                          innerQuestionResponse7 &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                        {innerQuestionResponse6 === undefined &&
                                          innerQuestionResponse7 &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      paddingLeft={1}
                                    >
                                      <WelloFormItemLabel title={qIn1.text} />
                                      <WelloTextFieldWithoutTitle
                                        textProps={{
                                          id: `${id}patient_depart_date1`,
                                          type: 'date',
                                          inputProps: {
                                            max: moment(new Date()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            min:
                                              travelDate1.length === 0
                                                ? moment(new Date()).format(
                                                    'YYYY-MM-DD'
                                                  )
                                                : isCurrentDate(travelDate1)
                                                ? moment(new Date()).format(
                                                    'YYYY-MM-DD'
                                                  )
                                                : travelDate1,
                                          },
                                          onKeyDown: (e) => {
                                            e.preventDefault()
                                          },
                                          onChange: (changedValue) => {
                                            setTravelDate4(
                                              moment(
                                                changedValue.target.value
                                              ).format('YYYY-MM-DD')
                                            )
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse1([outerRes])
                                            }

                                            const ans1: R4.IQuestionnaireResponse_Answer =
                                              {
                                                valueString: moment(
                                                  changedValue.target.value
                                                ).format('DD-MM-YYYY'),
                                              }

                                            const innerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: qIn1.linkId,
                                                text: qIn1.text,
                                                answer: [ans1],
                                              }
                                            setInnerQuestionResponse7(innerRes)
                                          },
                                        }}
                                      />
                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {travelDate1 &&
                                        travelDate4 &&
                                        travelDate1 > travelDate4 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              {innerQuestionResponse7 ===
                                              undefined
                                                ? ''
                                                : 'Departure date should be greater than arrival date'}
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0] &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString?.length === 0 &&
                                        innerQuestionResponse5 &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer ===
                                          undefined &&
                                        innerQuestionResponse5 &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {/* {innerQuestionResponse6 === undefined &&
                                        innerQuestionResponse5 &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )} */}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              {itemData.item.map((qIn1, qindex) => (
                                <Box
                                  key={qIn1.linkId}
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  {qIn1.linkId === '2.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      px={1}
                                    >
                                      <WelloFormItemLabel title={qIn1.text} />
                                      <WelloTextFieldWithoutTitle
                                        textProps={{
                                          id: `${id}patient1_country2`,

                                          type: 'text',
                                          placeholder: 'Country/City',

                                          onChange: (discounData) => {
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse3([outerRes])
                                            }
                                            if (
                                              discounData.target.value.length >
                                              0
                                            ) {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString:
                                                    discounData.target.value,
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse9(
                                                innerRes
                                              )
                                            } else {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: '',
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse9(
                                                innerRes
                                              )
                                            }
                                          },
                                        }}
                                      />
                                      {(innerQuestionResponse10 ||
                                        innerQuestionResponse11) &&
                                        innerQuestionResponse9 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0] &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString === undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {/* <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='error'
                                        >
                                          {innerQuestionResponse6
                                            ? innerQuestionResponse6.answer
                                              ? innerQuestionResponse6.answer[0]
                                                ? innerQuestionResponse6
                                                    .answer[0].valueString
                                                  ? innerQuestionResponse6
                                                      .answer[0].valueString
                                                  : innerQuestionResponse6
                                                      .answer[0].valueString
                                                      ?.length
                                                : '2'
                                              : '3'
                                            : '4'}
                                        </Typography>
                                      </Box> */}

                                      {(innerQuestionResponse10 ||
                                        innerQuestionResponse11) &&
                                        innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0] &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString?.length === 0 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        paddingRight={1}
                                      >
                                        <WelloFormItemLabel title={qIn1.text} />
                                        <WelloTextFieldWithoutTitle
                                          textProps={{
                                            id: `${id}patient_arrival_date2`,
                                            type: 'date',
                                            inputProps: {
                                              max: moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ),
                                              min: isCurrentDate(travelDate5)
                                                ? moment()
                                                    .subtract(2, 'days')
                                                    .format('YYYY-MM-DD')
                                                : travelDate5,
                                            },
                                            onKeyDown: (e) => {
                                              e.preventDefault()
                                            },
                                            onChange: (changedValue) => {
                                              setTravelDate2(
                                                moment(
                                                  changedValue.target.value
                                                ).format('YYYY-MM-DD')
                                              )
                                              const outerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: itemData.linkId,
                                                  text: itemData.text,
                                                }
                                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                                questionResponse1.filter(
                                                  (d) => d.linkId === '2'
                                                )
                                              if (outerFilter.length === 0) {
                                                setQuestionResponse3([outerRes])
                                              }

                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: moment(
                                                    changedValue.target.value
                                                  ).format('DD-MM-YYYY'),
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse10(
                                                innerRes
                                              )
                                            },
                                          }}
                                        />

                                        {innerQuestionResponse9 &&
                                          innerQuestionResponse9.answer &&
                                          innerQuestionResponse9.answer[0]
                                            .valueString &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse9 &&
                                          innerQuestionResponse9.answer &&
                                          innerQuestionResponse9.answer[0] &&
                                          innerQuestionResponse9.answer[0]
                                            .valueString?.length === 0 &&
                                          innerQuestionResponse11 &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse9 &&
                                          innerQuestionResponse9.answer ===
                                            undefined &&
                                          innerQuestionResponse11 &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                        {innerQuestionResponse9 === undefined &&
                                          innerQuestionResponse11 &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      paddingLeft={1}
                                    >
                                      <WelloFormItemLabel title={qIn1.text} />
                                      <WelloTextFieldWithoutTitle
                                        textProps={{
                                          id: `${id}patient_depart_date2`,
                                          type: 'date',

                                          inputProps: {
                                            max: moment(new Date()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            min:
                                              travelDate2.length === 0
                                                ? moment(new Date()).format(
                                                    'YYYY-MM-DD'
                                                  )
                                                : isCurrentDate(travelDate2)
                                                ? moment(new Date()).format(
                                                    'YYYY-MM-DD'
                                                  )
                                                : travelDate2,
                                          },
                                          //   inputProps: {
                                          //     max: moment(new Date()).format(
                                          //       'YYYY-MM-DD'
                                          //     ),
                                          //     min: travelDate2,
                                          //   },
                                          onKeyDown: (e) => {
                                            e.preventDefault()
                                          },
                                          onChange: (changedValue) => {
                                            setTravelDate5(
                                              moment(
                                                changedValue.target.value
                                              ).format('YYYY-MM-DD')
                                            )
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse3([outerRes])
                                            }

                                            const ans1: R4.IQuestionnaireResponse_Answer =
                                              {
                                                valueString: moment(
                                                  changedValue.target.value
                                                ).format('DD-MM-YYYY'),
                                              }

                                            const innerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: qIn1.linkId,
                                                text: qIn1.text,
                                                answer: [ans1],
                                              }
                                            setInnerQuestionResponse11(innerRes)
                                          },
                                        }}
                                      />

                                      {travelDate2 &&
                                        travelDate5 &&
                                        travelDate2 > travelDate5 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              {innerQuestionResponse9 ===
                                              undefined
                                                ? 'Departure date is required'
                                                : 'Departure date should be greater than arrival date'}
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0] &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString?.length === 0 &&
                                        innerQuestionResponse10 &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer ===
                                          undefined &&
                                        innerQuestionResponse10 &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {/* {innerQuestionResponse9 === undefined &&
                                        innerQuestionResponse10 &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )} */}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              py={1}
                            >
                              {itemData.item.map((qIn1, qindex) => (
                                <Box
                                  key={qIn1.linkId}
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  {qIn1.linkId === '2.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      px={1}
                                    >
                                      <WelloFormItemLabel title={qIn1.text} />
                                      <WelloTextFieldWithoutTitle
                                        // title={qIn1.text}
                                        textProps={{
                                          id: `${id}patient2_countr3`,

                                          type: 'text',
                                          placeholder: 'Country/City',

                                          onChange: (discounData) => {
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse4([outerRes])
                                            }

                                            if (
                                              discounData.target.value.length >
                                              0
                                            ) {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString:
                                                    discounData.target.value,
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse12(
                                                innerRes
                                              )
                                            } else {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: '',
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse12(
                                                innerRes
                                              )
                                            }
                                          },
                                        }}
                                      />
                                      {(innerQuestionResponse13 ||
                                        innerQuestionResponse14) &&
                                        innerQuestionResponse12 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0] &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString === undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {/* <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='error'
                                        >
                                          {innerQuestionResponse6
                                            ? innerQuestionResponse6.answer
                                              ? innerQuestionResponse6.answer[0]
                                                ? innerQuestionResponse6
                                                    .answer[0].valueString
                                                  ? innerQuestionResponse6
                                                      .answer[0].valueString
                                                  : innerQuestionResponse6
                                                      .answer[0].valueString
                                                      ?.length
                                                : '2'
                                              : '3'
                                            : '4'}
                                        </Typography>
                                      </Box> */}

                                      {(innerQuestionResponse13 ||
                                        innerQuestionResponse14) &&
                                        innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0] &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString?.length === 0 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        paddingRight={1}
                                      >
                                        <WelloFormItemLabel title='Arrival Date' />
                                        <WelloTextFieldWithoutTitle
                                          textProps={{
                                            id: `${id}patient_arrival_date3`,
                                            type: 'date',
                                            inputProps: {
                                              max: moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ),
                                              min: isCurrentDate(travelDate6)
                                                ? moment()
                                                    .subtract(2, 'days')
                                                    .format('YYYY-MM-DD')
                                                : travelDate6,
                                            },
                                            onKeyDown: (e) => {
                                              e.preventDefault()
                                            },
                                            onChange: (changedValue) => {
                                              setTravelDate3(
                                                moment(
                                                  changedValue.target.value
                                                ).format('YYYY-MM-DD')
                                              )
                                              const outerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: itemData.linkId,
                                                  text: itemData.text,
                                                }
                                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                                questionResponse1.filter(
                                                  (d) => d.linkId === '2'
                                                )
                                              if (outerFilter.length === 0) {
                                                setQuestionResponse4([outerRes])
                                              }

                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: moment(
                                                    changedValue.target.value
                                                  ).format('DD-MM-YYYY'),
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse13(
                                                innerRes
                                              )
                                            },
                                          }}
                                        />

                                        {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer &&
                                          innerQuestionResponse12.answer[0]
                                            .valueString &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer &&
                                          innerQuestionResponse12.answer[0] &&
                                          innerQuestionResponse12.answer[0]
                                            .valueString?.length === 0 &&
                                          innerQuestionResponse14 &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer ===
                                            undefined &&
                                          innerQuestionResponse14 &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                        {innerQuestionResponse12 ===
                                          undefined &&
                                          innerQuestionResponse14 &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      paddingLeft={1}
                                    >
                                      <WelloFormItemLabel title={qIn1.text} />
                                      <WelloTextFieldWithoutTitle
                                        // title={qIn1.text}
                                        textProps={{
                                          id: `${id}patient_depart_date3`,
                                          type: 'date',

                                          inputProps: {
                                            max: moment(new Date()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            min:
                                              travelDate3.length === 0
                                                ? moment(new Date()).format(
                                                    'YYYY-MM-DD'
                                                  )
                                                : isCurrentDate(travelDate3)
                                                ? moment(new Date()).format(
                                                    'YYYY-MM-DD'
                                                  )
                                                : travelDate3,
                                          },
                                          //   inputProps: {
                                          //     max: moment(new Date()).format(
                                          //       'YYYY-MM-DD'
                                          //     ),
                                          //     min: travelDate3,
                                          //   },
                                          onKeyDown: (e) => {
                                            e.preventDefault()
                                          },
                                          onChange: (changedValue) => {
                                            setTravelDate6(
                                              moment(
                                                changedValue.target.value
                                              ).format('YYYY-MM-DD')
                                            )
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse4([outerRes])
                                            }

                                            const ans1: R4.IQuestionnaireResponse_Answer =
                                              {
                                                valueString: moment(
                                                  changedValue.target.value
                                                ).format('DD-MM-YYYY'),
                                              }

                                            const innerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: qIn1.linkId,
                                                text: qIn1.text,
                                                answer: [ans1],
                                              }
                                            setInnerQuestionResponse14(innerRes)
                                          },
                                        }}
                                      />

                                      {travelDate3 &&
                                        travelDate6 &&
                                        travelDate3 > travelDate6 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              {innerQuestionResponse12
                                                ? 'Departure date should be greater than arrival date'
                                                : 'Departure date is required'}
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0] &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString?.length === 0 &&
                                        innerQuestionResponse13 &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer ===
                                          undefined &&
                                        innerQuestionResponse13 &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {/* {innerQuestionResponse12 === undefined &&
                                        innerQuestionResponse13 &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )} */}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}

                        {itemData.linkId === '3' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            py={1}
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{ color: 'black' }}
                            >
                              3. {itemData.text}
                            </Typography>

                            {itemData.item.map((qIn2, qindex) => (
                              <Box
                                key={qIn2.linkId}
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                {qIn2.linkId === '3.1' && (
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                  >
                                    <Box
                                      display='flex'
                                      flexGrow={1}
                                      alignItems='flex-end'
                                      justifyContent='center'
                                      width='70%'
                                      paddingTop={1}
                                      paddingRight={1}
                                    >
                                      <WelloFormItemLabel title={qIn2.text} />
                                    </Box>
                                    <Box
                                      display='flex'
                                      flexGrow={1}
                                      alignItems='flex-end'
                                      justifyContent='center'
                                      paddingLeft={1}
                                      width='30%'
                                    >
                                      <WelloSelectFHIRCoding
                                        id={`${id}${qindex}patientcovidans`}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse2.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse2([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn2.linkId,
                                              text: qIn2.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse8(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
                <Box width='50%' px={2}>
                  <Button
                    variant='contained'
                    id={`${id}consent`}
                    size='small'
                    color='primary'
                    disabled={checked}
                    onClick={() => {
                      setShowConditions(true)
                    }}
                  >
                    <Typography variant='subtitle2'>
                      {' '}
                      Consent, Rules & Regulations
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <ConsentDialog
          id='602'
          open={showConditions}
          data={false}
          onClose={(checkData: boolean) => {
            setShowConditions(false)
            setChecked(checkData)
          }}
        />

        <ConfirmationDialogMessage
          open={cancel}
          onClose={() => {
            setCancel(false)
          }}
          onYes={() => {
            setCancel(false)
            resetForm()
            dispatch(resetDayCareRegister())
            dispatch(resetStateForIPdFinishDayCare())
            onClose()
          }}
          notesData='IPD/OPD admission is incomplete. Are you sure you want to quit?'
          dialogText='Confirmation'
        />

        {selectedPatient && (
          <AddVisaDetails
            patient={selectedPatient}
            open={visaDetails}
            onClose={(checkData: boolean) => {
              setVisaDetails(false)
              setCheckedForForeign(false)
            }}
            onVisaDetailsAdded={(visaData?: string) => {
              if (visaData) {
                setVisaDataFinal(visaData)
              }
              dispatch(resetUpdateUserState())
              setCheckedForForeign(false)
              setForeign(false)
              setVisaDetails(false)
            }}
          />
        )}
      </DialogContent>

      <DialogActions>
        {(ipdRegisterSlice.adding || ipdFinsih.adding) && (
          <CircularProgress size={30} />
        )}
        {!ipdRegisterSlice.additionSuccessful && (
          <Box paddingRight={1.6}>
            <Button
              onClick={() => {
                if (
                  selectedCharef &&
                  selectedPatient &&
                  inBetWeenTime(moment().format('HH:mm'), '08:00', '18:00')
                ) {
                  dispatch(
                    registerDayCare(
                      discount,

                      selectedCharef,
                      selectedPatient,
                      selectedServiceMedicine!,
                      undefined,
                      visaDataFinal,
                      referralId
                    )
                  )
                }
              }}
              variant='contained'
              color='primary'
              disableElevation
              disabled={
                !isEnableCreateButton() ||
                selectedServiceMedicine === undefined ||
                !inBetWeenTime(moment().format('HH:mm'), '08:00', '18:00') ||
                discount < 0 ||
                discount > 100 ||
                Number.isNaN(discount) ||
                (foreign &&
                  getExtensionValueOfUrlPatient(
                    selectedPatient ? selectedPatient.extension ?? [] : [],
                    'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                  ) !== 'IN' &&
                  getExtensionValueOfUrlPatient(
                    selectedPatient ? selectedPatient.extension ?? [] : [],
                    'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                  ) !== 'India' &&
                  getExtensionValueOfUrlPatient(
                    selectedPatient ? selectedPatient.extension ?? [] : [],
                    'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                  ) !== 'india')
              }
              id={`${id}continue_btn`}
            >
              Continue
            </Button>
          </Box>
        )}

        {ipdRegisterSlice.additionSuccessful &&
          !ipdFinsih.adding &&
          !ipdFinsih.additionSuccessful && (
            <Button
              onClick={() => {
                if (
                  questionResponse.length > 0 &&
                  questionResponse2.length > 0 &&
                  innerQuestionResponse1 &&
                  innerQuestionResponse2 &&
                  innerQuestionResponse3 &&
                  innerQuestionResponse4 &&
                  innerQuestionResponse8 &&
                  checked
                ) {
                  dispatch(
                    finshDayCare(
                      value,
                      photoType,
                      selectedPatient ? selectedPatient.id ?? '' : '',
                      serviceRequest || '',

                      questionResponse,
                      innerQuestionResponse1,
                      innerQuestionResponse2,
                      innerQuestionResponse3,
                      innerQuestionResponse4,
                      questionResponse2,
                      innerQuestionResponse8,
                      questionResponse1,
                      questionResponse3,
                      questionResponse4,
                      innerQuestionResponse5,
                      innerQuestionResponse6,
                      innerQuestionResponse7,

                      innerQuestionResponse9,
                      innerQuestionResponse10,
                      innerQuestionResponse11,

                      innerQuestionResponse12,
                      innerQuestionResponse13,
                      innerQuestionResponse14,

                      selectedPatient
                    )
                  )
                }
              }}
              variant='contained'
              color='primary'
              disableElevation
              disabled={
                !isFinsihButtonEnabled() ||
                isCovidDataFilledForOne() ||
                isCovidDataFilledForTwo() ||
                isCovidDataFilledForThree() ||
                isDatesCorrect() ||
                isDatesCorrectFor2nd() ||
                isDatesCorrectFor3rd()
              }
              id={`${id}finish_btn`}
            >
              Finish
            </Button>
          )}

        {/* {ipdFinsihSadhka.additionSuccessful */}
      </DialogActions>
    </Dialog>
  )
}
