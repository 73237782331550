import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  List,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import MuiListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import {
  kBackGround,
  kPrimaryLight,
  kSelectedTabBackground,
  kContrastText,
} from 'configs/styles/muiThemes'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchUnits } from 'redux/administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'
import { resetUnitAddReducerState } from 'redux/administration/unitSetup/unitInvitationSlice/unitInvitationSetupSlice'
import { searchInvitations } from 'redux/administration/userSetup/userOnBoardInvitations/userOnboardInvitationSlice'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetObservationState } from 'redux/lab/observationFinder/observationFinderSlice'
import { requestOrdersForTheDateRange } from 'redux/lab/order/orderSearchSlice/orderSearchSlice'
import { resetCatalogueSearchStatus } from 'redux/lab/packageSearch/packageSearchSlice'
import { resetPackageState } from 'redux/lab/Test/addTestCatalougeSlice'
import { resetState } from 'redux/patient/addPatient/addPatientSlice'
import { searchPractitioners } from 'redux/practitioner/practitionerSearchHandler/practitionerSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
  getUnitDetailsFromServer,
  isAgent,
  isAyurvedaDoctor,
  isCurrentUnitIsClinic,
  isCurrentUnitIsWellonessCenter,
  isDietician,
  isGre,
  isMedicalServiceProvider,
  isOnlyDoctor,
  isOrgAdmin,
  isPartnerLab,
  isPhysiotherapist,
  isTherapist,
  isUnitAdmin,
  isWpAdmin,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { AccountDetailsPage } from 'views/components/administration/account/accountList'
import { AddActorsHandler } from 'views/components/administration/actors/add_actors'
import { AddPartnerLabHandler } from 'views/components/administration/actors/add_partner_lab'
import { AddUnitAdminsHandler } from 'views/components/administration/actors/add_unit_admins'
import { CPGListPage } from 'views/components/administration/carePlans/cpgListPage'
import { CPSubscriptionsListPage } from 'views/components/administration/carePlans/cpSubscriptionsListPage'
import { CareTeamsForCarePlansComponents } from 'views/components/administration/crossFunctionalCare/careTeam/careTeamsForCarePlans'
import { CrossFunctionalCareComponent } from 'views/components/administration/crossFunctionalCare/crossOrgFunctional/crossFunctionalCareComponant'
import { MembershipSearch } from 'views/components/administration/membership/membershipSearch'
import { Cancellation } from 'views/components/administration/payment_settings.tsx/cancellation'
import { ConvSearch } from 'views/components/administration/payment_settings.tsx/convinienceFee'
import { ReferralFeeSearch } from 'views/components/administration/payment_settings.tsx/referralFee'
import { SettlementFile } from 'views/components/administration/payment_settings.tsx/settlement'

import { ReferralSearch } from 'views/components/administration/referrals/referralSearch'
import { AddUnitsHandler } from 'views/components/administration/units/add_unit_dialog'
import { RoomsOfOrganization } from 'views/components/administration/units/roomAndBeds/roomsAndBedsList'
import { LabProfileDetails } from 'views/components/administration/units/unitLists/lab_profile_update'
import { UnitsOfOrganization } from 'views/components/administration/units/unitLists/unitsOfOrganization'
import { ProfilleDetails } from 'views/components/administration/units/unitLists/unit_profile_update'
import { WorkingHourSettings } from 'views/components/administration/units/workingHourSetting'
import { PartnerLabSearch } from 'views/components/collectionCenter/partnerLabSearch'
import { CatalogueSearch } from 'views/components/lab/catalougeList/catalougeSearch'
import { Upload } from 'views/components/lab/catalougeSetup/upload'
import { CreateLabOrderHandler } from 'views/components/lab/orderCreationManagement/createLabOrderManagement'
import { AddPackageHandler } from 'views/components/lab/package/addPackage'
import { PackageSearch } from 'views/components/lab/package/packahgeDataView'
import { AddLabNewPatient } from 'views/components/lab/patientSetup/add_patient'
import { PatientSetUp } from 'views/components/lab/patientSetup/patientSetup'
import { ReportSearch } from 'views/components/lab/Report/reportSearch'
import { UploadReport } from 'views/components/lab/Report/uploadReport'
import { MedicalProviderProfileDetails } from 'views/components/medical_provider_profile_details'
import { NavListItem } from 'views/components/primitive/nav_list_item'
import '../../../App.css'
import { AddNewPatient } from '../../components/add_new_patient'
import { AppointmentRate } from './appointmentRate'
import { DietSearch } from './dietSearch'
import { DietSettings } from './dietSettings'
import { DiscountRateSearch } from './discountSearch'
import { FollowUpSelector } from './followUpSelector'
import { PreAppointmentQuestions } from './preAppointmentQuestions'
import { ServicePriceSelector } from './servicePriceSelector'
import { SubstanceSearch } from './substanceSearch'
import { PhysiotherapySearch } from './tables/physioTherapy/physiotherapySearch'
import { TherapySearch } from './therapySearch'
import { Signature } from './uploadSignature'
import { UserSearch } from './userSearch'

const useStyles = makeStyles((theme) => ({
  first: {
    marginLeft: '25px',
  },
  second: {
    marginLeft: '30px',
  },
  subcategory: {
    marginLeft: '8px',
  },
  serviceMapping: {
    marginLeft: '1px',
  },
}))

const ListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: 'kPrimaryLight',
      color: 'black',
    },
    '&$selected:hover': {
      backgroundColor: 'kPrimaryLight',
      color: 'black',
    },
  },
  selected: {},
})(MuiListItem)

export interface IUnitAdmin {
  onTap: () => void
}

export const UserSettingsPage: React.FC<IUnitAdmin> = ({ onTap }) => {
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const [openPopup, SetOpenPopup] = React.useState(false)
  const [openAddPatientPopup, setOpenAddPatientPopup] = useState(false)
  const [openUploadReportPopup, setOpenUploadReportPopup] = useState(false)
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const [openPackage, setOpenPackage] = useState(false)
  const selectedDoctors = useRef<PractitionerWithRole[] | undefined>()
  const selectedRef = useRef<string>('')
  const [selectedOrderTypes, setSelectedOrderTypes] = useState<string[]>([
    'onsite-sample-collection',
    'home-sample-collection',
  ])
  const [showAddUsers, setShowAddUsers] = useState<boolean>(false)
  const [selectedTests, setSelectedTests] = useState<LabOfferingDetail[]>([])
  const [showAddUnitDetails, setShowAddUnitDetails] = useState<boolean>(false)
  const [showAddUnitAdminDetails, setShowAddUnitAdminDetails] =
    useState<boolean>(false)

  const [selectedOption, setSelectedOption] = useState<string>(
    isOrgAdmin() ? 'units' : isWpAdmin() ? 'conv_fee' : 'unit'
  )
  const [focusVal, setFocusVal] = useState<string>('')

  const [userDetails, setUserDetails] = useState<PractitionerWithRole>()
  const [selectedOrder, setSelectedOrder] = useState<FhirLabOrderDetail>()
  const [openCreateLabOrderPopup, setOpenCreateLabOrderPopup] = useState(false)
  const [viewType, setViewType] = useState<string | undefined>('list')
  const [currentLocation, setCurrentLocation] = useState<R4.ILocation>(
    getCurrentUserUnitLocationDetails()
  )

  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice
  )

  const [finalData, setFinalData] = useState<string[]>([])

  function fetchInvoice() {
    const dataSetup: string[] = []
    getUnitDetailsFromServer(getCurrentUserUnitDetails().id ?? '')
      .then((e) => {
        if (e.length > 0) {
          console.log(e)
          //   if (e[0].systemOfMedicine && e[0].systemOfMedicine.length > 0) {
          //     data = e[0].systemOfMedicine.filter(
          //       (org: R4.ICoding) => org.code === '1259939000'
          //     )
          //   }
          const data: string[] = []
          for (let i = 0; i < e.length; i++) {
            if (e[i].orgs.id === getCurrentUserUnitDetails().id) {
              if (e[i].ipdService) {
                data.push('1')
              }
            }
          }
          if (data.length > 0) {
            dataSetup.push('1')

            setFinalData(dataSetup)
          }
        }
      })
      .catch((e) => {})
  }

  useEffect(() => {
    fetchInvoice()
    dispatch(searchPractitioners())
  }, [selectedRef])

  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const classes = useStyles()

  const [expandedTabs, setExpandedTabs] = useState<string[]>([])

  const handleNavTabExpandClick = (navTabTile: string) => {
    if (expandedTabs.includes(navTabTile)) {
      setExpandedTabs(expandedTabs.filter((item) => item !== navTabTile))
    } else {
      setExpandedTabs([...expandedTabs, navTabTile])
    }
  }

  const [text, setText] = useState<string>()

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            width={1 / 5}
            minWidth={1 / 5}
            style={{ overflow: 'auto', height: '100%', maxWidth: '280px' }}
          >
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
                padding: '2px',
              }}
            >
              <Box width='100%'>
                <Box flexDirection='column' display='flex' p={1}>
                  <Box>
                    <Typography
                      variant='h6'
                      color='primary'
                      style={{
                        fontFamily: 'Open Sans',

                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      {t('labelCommon:settings')}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      style={{
                        fontWeight: 600,
                        fontSize: 10,
                      }}
                    >
                      {`${
                        isUnitAdmin() ? getCurrentUserUnitDetails().name : ''
                      } ${getCurrentUserMainOrganizationDetails().name ?? ''}`}
                    </Typography>
                  </Box>
                </Box>

                <List>
                  {isOrgAdmin() && (
                    <NavListItem
                      isSelected={selectedOption === 'units'}
                      text='Unit Details'
                      onSectionSelected={() => {
                        if (selectedOption !== 'units') {
                          setSelectedOption('units')
                        }
                      }}
                    />
                  )}

                  {isOrgAdmin() && (
                    <NavListItem
                      isSelected={selectedOption === 'contact_info'}
                      text='Contact Information'
                      onSectionSelected={() => {
                        if (selectedOption !== 'contact_info') {
                          setSelectedOption('contact_info')
                        }
                      }}
                    />
                  )}
                  {isUnitAdmin() && loggedInUnitType === 'Lab' && (
                    <NavListItem
                      isSelected={selectedOption === 'unit'}
                      text={t('labelCommon:contact_information')}
                      onSectionSelected={() => {
                        if (selectedOption !== 'unit') {
                          setSelectedOption('unit')
                        }
                      }}
                    />
                  )}

                  {isUnitAdmin() &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'Working_Hours'}
                        text={t('labelCommon:workingHoursHolidays')}
                        onSectionSelected={() => {
                          setSelectedOption('Working_Hours')
                        }}
                      />
                    )}
                  {isUnitAdmin() &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'uploadSign'}
                        text='Upload Signature'
                        onSectionSelected={() => {
                          if (selectedOption !== 'uploadSign') {
                            setSelectedOption('uploadSign')
                          }
                        }}
                      />
                    )}

                  {isOrgAdmin() && (
                    <NavListItem
                      isSelected={selectedOption === 'users'}
                      text={t('labelCommon:users')}
                      onSectionSelected={() => {
                        setSelectedOption('users')
                      }}
                    />
                  )}

                  {/* {isWpAdmin() && (
                    <NavListItem
                      isSelected={selectedOption === 'care-plan-subscription'}
                      text='CARE Plan'
                      onSectionSelected={() => {
                        setSelectedOption('care-plan-subscription')
                      }}
                    />
                  )} */}

                  {/* {isWpAdmin() && (
                    <NavListItem
                      isSelected={selectedOption === 'payment_settings'}
                      text='Payment Settings'
                      onSectionSelected={() => {
                        setSelectedOption('payment_settings')
                      }}
                    />
                  )} */}
                  {/* 
                  {isWpAdmin() && (
                    <NavListItem
                      isSelected={selectedOption === 'c-p-g'}
                      text='CPGs'
                      onSectionSelected={() => {
                        setSelectedOption('c-p-g')
                      }}
                    />
                  )} */}

                  {(isMedicalServiceProvider() ||
                    isPhysiotherapist() ||
                    isDietician() ||
                    isTherapist() ||
                    isAgent() ||
                    isGre()) &&
                    !isUnitAdmin() &&
                    !isOrgAdmin() && (
                      <NavListItem
                        isSelected={selectedOption === 'unit'}
                        text={t('labelCommon:contact_information')}
                        onSectionSelected={() => {
                          if (selectedOption !== 'unit') {
                            setSelectedOption('unit')
                          }
                        }}
                      />
                    )}
                  {isOrgAdmin() && (
                    <NavListItem
                      isSelected={selectedOption === 'acc_details'}
                      text='Account Details'
                      onSectionSelected={() => {
                        setSelectedOption('acc_details')
                      }}
                    />
                  )}

                  {(isMedicalServiceProvider() ||
                    isPhysiotherapist() ||
                    isAgent() ||
                    isDietician()) &&
                    !isUnitAdmin() && (
                      <NavListItem
                        isSelected={selectedOption === 'my_working_hour'}
                        text='My Working Hours'
                        onSectionSelected={() => {
                          if (selectedOption !== 'my_working_hour') {
                            setSelectedOption('my_working_hour')
                          }
                        }}
                      />
                    )}

                  {(isOnlyDoctor() || isAyurvedaDoctor()) && !isUnitAdmin() && (
                    <NavListItem
                      isSelected={selectedOption === 'pre-appointment'}
                      text='Pre-Appointment Questionnaire'
                      onSectionSelected={() => {
                        if (selectedOption !== 'pre-appointment') {
                          setSelectedOption('pre-appointment')
                        }
                      }}
                    />
                  )}
                  {(isOnlyDoctor() || isAyurvedaDoctor()) && !isUnitAdmin() && (
                    <NavListItem
                      isSelected={selectedOption === 'diet'}
                      text='Diet'
                      onSectionSelected={() => {
                        if (selectedOption !== 'diet') {
                          setSelectedOption('diet')
                        }
                      }}
                    />
                  )}

                  {(isMedicalServiceProvider() ||
                    isPhysiotherapist() ||
                    isDietician()) &&
                    !isUnitAdmin() &&
                    (loggedInUnitType === 'Wellness Center' ||
                      isCurrentUnitIsClinic()) && (
                      <NavListItem
                        isSelected={selectedOption === 'uploadSign'}
                        text='Upload Signature'
                        onSectionSelected={() => {
                          if (selectedOption !== 'uploadSign') {
                            setSelectedOption('uploadSign')
                          }
                        }}
                      />
                    )}

                  {(isUnitAdmin() || isOrgAdmin()) &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'users'}
                        text={t('labelCommon:users')}
                        onSectionSelected={() => {
                          setSelectedOption('users')
                        }}
                      />
                    )}

                  {(isUnitAdmin() || isOrgAdmin()) &&
                    loggedInUnitType === 'Collection Center' && (
                      <NavListItem
                        isSelected={selectedOption === 'Labs'}
                        text={t('labelCommon:labs')}
                        onSectionSelected={() => {
                          if (selectedOption !== 'Labs') {
                            setSelectedOption('Labs')
                          }
                        }}
                      />
                    )}

                  {/* {(isUnitAdmin() || isOrgAdmin()) &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'Tests Upload'}
                        text={t('labelCommon:testsUpload')}
                        onSectionSelected={() => {
                          if (selectedOption === 'Tests Upload') {
                            SetOpenPopup(true)
                          } else {
                            setSelectedOption('Tests Upload')
                            SetOpenPopup(true)
                          }
                        }}
                      />
                    )} */}

                  {(isUnitAdmin() || isOrgAdmin()) &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'Tests'}
                        text='Tests/Panels'
                        onSectionSelected={() => {
                          SetOpenPopup(false)
                          setSelectedOption('Tests')
                          if (selectedOption === 'Tests') {
                            setSelectedOption('Tests')
                          } else {
                            setSelectedOption('Tests')
                          }
                        }}
                      />
                    )}

                  {isUnitAdmin() &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'Packages'}
                        text='Packages'
                        onSectionSelected={() => {
                          if (selectedOption === 'Packages') {
                            SetOpenPopup(true)
                          } else {
                            setSelectedOption('Packages')
                            SetOpenPopup(true)
                          }
                        }}
                      />
                    )}

                  {(isUnitAdmin() || isOrgAdmin()) &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'referrals'}
                        text='Referrals'
                        onSectionSelected={() => {
                          setSelectedOption('referrals')
                          if (selectedOption === 'referrals') {
                            setSelectedOption('referrals')
                          } else {
                            setSelectedOption('referrals')
                          }
                        }}
                      />
                    )}

                  {/* {(isUnitAdmin() || isOrgAdmin()) &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'Patients_Upload'}
                        text={t('labelCommon:uploadPatient')}
                        onSectionSelected={() => {
                          setSelectedOption('Patients_Upload')
                          SetOpenPopup(true)
                        }}
                      />
                    )} */}

                  {/* {(isUnitAdmin() || isOrgAdmin()) &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'reports'}
                        text='Reports'
                        onSectionSelected={() => {
                          setSelectedOption('reports')
                        }}
                      />
                    )} */}

                  {isGre() && (
                    <NavListItem
                      isSelected={selectedOption === 'referrals'}
                      text='Referrals Details'
                      onSectionSelected={() => {
                        setSelectedOption('referrals')
                      }}
                    />
                  )}

                  {isGre() && (
                    <NavListItem
                      isSelected={selectedOption === 'rooms'}
                      text='Room'
                      onSectionSelected={() => {
                        setSelectedOption('rooms')
                      }}
                    />
                  )}

                  {/* {(isGre() ||
                    (isUnitAdmin() && !isDoctor()) ||
                    isOrgAdmin()) &&
                    (loggedInUnitType === 'Clinic' ||
                      loggedInUnitType === 'Wellness Center') && (
                      <NavListItem
                        isSelected={selectedOption === 'membership'}
                        text='Membership'
                        onSectionSelected={() => {
                          setSelectedOption('membership')
                        }}
                      />
                    )} */}

                  {!isUnitAdmin() && !isWpAdmin() && (
                    <NavListItem
                      isSelected={false}
                      text='Reset Password'
                      onSectionSelected={() => {
                        window.open(
                          `${process.env.REACT_APP_AUTH_URL}/oidc/myAccount`
                        )
                      }}
                    />
                  )}

                  {isUnitAdmin() &&
                    (loggedInUnitType === 'Lab' ||
                      loggedInUnitType === 'Collection Center') && (
                      <NavListItem
                        isSelected={false}
                        text='Reset Password'
                        onSectionSelected={() => {
                          window.open(
                            `${process.env.REACT_APP_AUTH_URL}/oidc/myAccount`
                          )
                        }}
                      />
                    )}
                </List>
                {isWpAdmin() && (
                  <List>
                    <NavListItem
                      isSelected={selectedOption === 'acc_details'}
                      text='Account Details'
                      onSectionSelected={() => {
                        setSelectedOption('acc_details')
                      }}
                    />
                    <NavListItem
                      isSelected={selectedOption === 'conv_fee'}
                      text='Fee'
                      onSectionSelected={() => {
                        setSelectedOption('conv_fee')
                      }}
                    />
                    <NavListItem
                      isSelected={selectedOption === 'settlement'}
                      text='Settlement'
                      onSectionSelected={() => {
                        setSelectedOption('settlement')
                      }}
                    />
                    <NavListItem
                      isSelected={selectedOption === 'cancellation'}
                      text='Cancellation'
                      onSectionSelected={() => {
                        setSelectedOption('cancellation')
                      }}
                    />
                    <NavListItem
                      isSelected={false}
                      text='Reset Password'
                      onSectionSelected={() => {
                        window.open(
                          `${process.env.REACT_APP_AUTH_URL}/oidc/myAccount`
                        )
                      }}
                    />
                  </List>
                )}

                {isUnitAdmin() &&
                  (loggedInUnitType === 'Clinic' ||
                    loggedInUnitType === 'Wellness Center') && (
                    <nav>
                      <List>
                        <ListItem
                          style={{
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                          tabIndex={0}
                          button
                          onClick={(event) => {
                            handleNavTabExpandClick('unit-details')
                          }}
                        >
                          {expandedTabs.includes('unit-details') ? (
                            <ExpandMore />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          <Box paddingX={2}>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              style={{
                                textTransform: 'capitalize',
                                letterSpacing: 'normal',
                                whiteSpace: 'nowrap',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                              }}
                            >
                              Unit Details
                            </Typography>
                          </Box>
                        </ListItem>
                        <Collapse
                          in={expandedTabs.includes('unit-details')}
                          timeout='auto'
                          unmountOnExit
                        >
                          <List component='div' disablePadding>
                            {isUnitAdmin() && (
                              <ListItem
                                style={{
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'unit' &&
                                    focusVal === 'unit'
                                      ? kPrimaryLight
                                      : focusVal === 'unit'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                disableRipple={false}
                                button
                                tabIndex={0}
                                className={classes.first}
                                onClick={(event) => {
                                  if (selectedOption !== 'unit') {
                                    setFocusVal('')
                                    setSelectedOption('unit')
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.code === 'Enter') {
                                    if (selectedOption !== 'unit') {
                                      setSelectedOption('unit')
                                    }
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'unit') {
                                    setFocusVal('unit')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Contact Information
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}

                            {isUnitAdmin() && (
                              <ListItem
                                button
                                style={{
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'Working_Hours' &&
                                    focusVal === 'Working_Hours'
                                      ? kPrimaryLight
                                      : focusVal === 'Working_Hours'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                tabIndex={0}
                                className={classes.first}
                                selected={selectedOption === 'Working_Hours'}
                                onClick={(event) => {
                                  if (selectedOption !== 'Working_Hours') {
                                    setSelectedOption('Working_Hours')
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.code === 'Enter') {
                                    if (selectedOption !== 'Working_Hours') {
                                      setSelectedOption('Working_Hours')
                                    }
                                  }
                                }}
                                onFocus={(e) => {
                                  setFocusVal('Working_Hours')
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Working Hours and Holidays
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}

                            {(isMedicalServiceProvider() ||
                              isPhysiotherapist() ||
                              isDietician()) && (
                              <ListItem
                                button
                                style={{
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'my_working_hour' &&
                                    focusVal === 'my_working_hour'
                                      ? kPrimaryLight
                                      : focusVal === 'my_working_hour'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                tabIndex={0}
                                className={classes.first}
                                selected={selectedOption === 'my_working_hour'}
                                onClick={(event) => {
                                  if (selectedOption !== 'my_working_hour') {
                                    setSelectedOption('my_working_hour')
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.code === 'Enter') {
                                    if (selectedOption !== 'my_working_hour') {
                                      setSelectedOption('my_working_hour')
                                    }
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'my_working_hour') {
                                    setFocusVal('my_working_hour')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    My Working Hours
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}
                          </List>
                        </Collapse>
                        <ListItem
                          button
                          style={{
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                          onClick={(event) => {
                            handleNavTabExpandClick('user-details')
                          }}
                          tabIndex={0}
                        >
                          {expandedTabs.includes('user-details') ? (
                            <ExpandMore />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          <Box paddingX={2}>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              style={{
                                textTransform: 'capitalize',
                                letterSpacing: 'normal',
                                whiteSpace: 'nowrap',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                              }}
                            >
                              User details
                            </Typography>
                          </Box>
                        </ListItem>

                        <Collapse
                          in={expandedTabs.includes('user-details')}
                          timeout='auto'
                          unmountOnExit
                        >
                          <List component='div' disablePadding>
                            {isUnitAdmin() && (
                              <ListItem
                                button
                                style={{
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'users' &&
                                    focusVal === 'users'
                                      ? kPrimaryLight
                                      : focusVal === 'users'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                tabIndex={0}
                                onKeyDown={(e) => {
                                  if (e.code === 'Enter') {
                                    if (selectedOption !== 'users') {
                                      setSelectedOption('users')
                                    }
                                  }
                                }}
                                className={classes.first}
                                selected={selectedOption === 'users'}
                                onClick={(event) => {
                                  if (selectedOption !== 'users') {
                                    setSelectedOption('users')
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'users') {
                                    setFocusVal('users')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Users
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}

                            {isMedicalServiceProvider() &&
                              (loggedInUnitType === 'Clinic' ||
                                isCurrentUnitIsWellonessCenter()) && (
                                <ListItem
                                  button
                                  style={{
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',

                                    backgroundColor:
                                      selectedOption === 'uploadSign' &&
                                      focusVal === 'uploadSign'
                                        ? kPrimaryLight
                                        : focusVal === 'uploadSign'
                                        ? kContrastText
                                        : 'transparent',
                                  }}
                                  tabIndex={0}
                                  onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                      if (selectedOption !== 'uploadSign') {
                                        setSelectedOption('uploadSign')
                                      }
                                    }
                                  }}
                                  className={classes.first}
                                  selected={selectedOption === 'uploadSign'}
                                  onClick={(event) => {
                                    if (selectedOption !== 'uploadSign') {
                                      setSelectedOption('uploadSign')
                                    }
                                  }}
                                  onFocus={(e) => {
                                    if (focusVal !== 'uploadSign') {
                                      setFocusVal('uploadSign')
                                    }
                                  }}
                                >
                                  <Box paddingX={2}>
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{
                                        textTransform: 'capitalize',
                                        letterSpacing: 'normal',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Upload Signature
                                    </Typography>
                                  </Box>
                                </ListItem>
                              )}
                          </List>
                        </Collapse>

                        <ListItem
                          button
                          style={{
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                          tabIndex={0}
                          onClick={(event) => {
                            handleNavTabExpandClick('membership-details')
                          }}
                        >
                          {expandedTabs.includes('membership-details') ? (
                            <ExpandMore />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          <Box paddingX={2}>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              style={{
                                textTransform: 'capitalize',
                                letterSpacing: 'normal',
                                whiteSpace: 'nowrap',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                              }}
                            >
                              Membership details
                            </Typography>
                          </Box>
                        </ListItem>
                        <Collapse
                          in={expandedTabs.includes('membership-details')}
                          timeout='auto'
                          unmountOnExit
                        >
                          <List component='div' disablePadding>
                            {isUnitAdmin() && (
                              <ListItem
                                button
                                tabIndex={0}
                                style={{
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'membership' &&
                                    focusVal === 'membership'
                                      ? kPrimaryLight
                                      : focusVal === 'membership'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                className={classes.first}
                                selected={selectedOption === 'membership'}
                                onClick={(event) => {
                                  if (selectedOption !== 'membership') {
                                    setSelectedOption('membership')
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'membership') {
                                    setFocusVal('membership')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Membership
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}
                          </List>
                        </Collapse>

                        {isUnitAdmin() && isMedicalServiceProvider() && (
                          <ListItem
                            button
                            tabIndex={0}
                            style={{
                              paddingLeft: '4px',
                              paddingRight: '4px',
                              paddingTop: '8px',
                              paddingBottom: '8px',
                            }}
                            onClick={(event) => {
                              handleNavTabExpandClick(
                                'pre-appointment-questionnaire'
                              )
                            }}
                          >
                            {expandedTabs.includes(
                              'pre-appointment-questionnaire'
                            ) ? (
                              <ExpandMore />
                            ) : (
                              <ChevronRightIcon />
                            )}
                            <Box paddingX={2}>
                              <Typography
                                variant='subtitle2'
                                color='primary'
                                style={{
                                  textTransform: 'capitalize',
                                  letterSpacing: 'normal',
                                  whiteSpace: 'nowrap',
                                  justifyContent: 'flex-start',
                                  cursor: 'pointer',
                                }}
                              >
                                Pre-Appointment Questionnaire
                              </Typography>
                            </Box>
                          </ListItem>
                        )}
                        {isUnitAdmin() && isMedicalServiceProvider() && (
                          <Collapse
                            in={expandedTabs.includes(
                              'pre-appointment-questionnaire'
                            )}
                            timeout='auto'
                            unmountOnExit
                          >
                            <List component='div' disablePadding>
                              {isUnitAdmin() && (
                                <ListItem
                                  button
                                  style={{
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    backgroundColor:
                                      selectedOption === 'pre-appointment' &&
                                      focusVal === 'pre-appointment'
                                        ? kPrimaryLight
                                        : focusVal === 'pre-appointment'
                                        ? kContrastText
                                        : 'transparent',
                                  }}
                                  className={classes.first}
                                  selected={
                                    selectedOption === 'pre-appointment'
                                  }
                                  onClick={(event) => {
                                    if (selectedOption !== 'pre-appointment') {
                                      setSelectedOption('pre-appointment')
                                    }
                                  }}
                                  onFocus={(e) => {
                                    if (focusVal !== 'pre-appointment') {
                                      setFocusVal('pre-appointment')
                                    }
                                  }}
                                >
                                  <Box paddingX={2}>
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{
                                        textTransform: 'capitalize',
                                        letterSpacing: 'normal',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Questionnaire
                                    </Typography>
                                  </Box>
                                </ListItem>
                              )}
                            </List>
                          </Collapse>
                        )}

                        {isUnitAdmin() && (
                          <ListItem
                            button
                            style={{
                              paddingLeft: '4px',
                              paddingRight: '4px',
                              paddingTop: '8px',
                              paddingBottom: '8px',
                            }}
                            tabIndex={0}
                            onClick={(event) => {
                              handleNavTabExpandClick('care-plan-subscription')
                            }}
                          >
                            {expandedTabs.includes('care-plan-subscription') ? (
                              <ExpandMore />
                            ) : (
                              <ChevronRightIcon />
                            )}
                            <Box paddingX={2}>
                              <Typography
                                variant='subtitle2'
                                color='primary'
                                style={{
                                  textTransform: 'capitalize',
                                  letterSpacing: 'normal',
                                  whiteSpace: 'nowrap',
                                  justifyContent: 'flex-start',
                                  cursor: 'pointer',
                                }}
                              >
                                Care Plans
                              </Typography>
                            </Box>
                          </ListItem>
                        )}

                        {isUnitAdmin() && (
                          <Collapse
                            in={expandedTabs.includes('care-plan-subscription')}
                            timeout='auto'
                            unmountOnExit
                          >
                            <List component='div' disablePadding>
                              {isUnitAdmin() && (
                                <ListItem
                                  button
                                  tabIndex={0}
                                  style={{
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    backgroundColor:
                                      selectedOption ===
                                        'care-plan-subscription' &&
                                      focusVal === 'care-plan-subscription'
                                        ? kPrimaryLight
                                        : focusVal === 'care-plan-subscription'
                                        ? kContrastText
                                        : 'transparent',
                                  }}
                                  className={classes.first}
                                  selected={
                                    selectedOption === 'care-plan-subscription'
                                  }
                                  onClick={(event) => {
                                    if (
                                      selectedOption !==
                                      'care-plan-subscription'
                                    ) {
                                      setSelectedOption(
                                        'care-plan-subscription'
                                      )
                                    }
                                  }}
                                  onFocus={(e) => {
                                    if (focusVal !== 'care-plan-subscription') {
                                      setFocusVal('care-plan-subscription')
                                    }
                                  }}
                                >
                                  <Box paddingX={2}>
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{
                                        textTransform: 'capitalize',
                                        letterSpacing: 'normal',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Care Plan Offerings
                                    </Typography>
                                  </Box>
                                </ListItem>
                              )}

                              {isUnitAdmin() && (
                                <ListItem
                                  button
                                  tabIndex={0}
                                  style={{
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    backgroundColor:
                                      selectedOption === 'care-team' &&
                                      focusVal === 'care-team'
                                        ? kPrimaryLight
                                        : focusVal === 'care-team'
                                        ? kContrastText
                                        : 'transparent',
                                  }}
                                  className={classes.first}
                                  selected={selectedOption === 'care-team'}
                                  onClick={(event) => {
                                    if (selectedOption !== 'care-team') {
                                      setSelectedOption('care-team')
                                    }
                                  }}
                                  onFocus={(e) => {
                                    if (focusVal !== 'care-teamData') {
                                      setFocusVal('care-teamData')
                                    }
                                  }}
                                >
                                  <Box paddingX={2}>
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{
                                        textTransform: 'capitalize',
                                        letterSpacing: 'normal',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Care Teams
                                    </Typography>
                                  </Box>
                                </ListItem>
                              )}
                              {isUnitAdmin() && (
                                <ListItem
                                  button
                                  tabIndex={0}
                                  style={{
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    backgroundColor:
                                      selectedOption ===
                                        'cross-org-functional' &&
                                      focusVal === 'cross-org-functional'
                                        ? kPrimaryLight
                                        : focusVal === 'cross-org-functional'
                                        ? kContrastText
                                        : 'transparent',
                                  }}
                                  className={classes.first}
                                  selected={
                                    selectedOption === 'cross-org-functional'
                                  }
                                  onClick={(event) => {
                                    if (
                                      selectedOption !== 'cross-org-functional'
                                    ) {
                                      setSelectedOption('cross-org-functional')
                                    }
                                  }}
                                  onFocus={(e) => {
                                    if (focusVal !== 'cross-org-functional') {
                                      setFocusVal('cross-org-functional')
                                    }
                                  }}
                                >
                                  <Box paddingX={2}>
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{
                                        textTransform: 'capitalize',
                                        letterSpacing: 'normal',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Cross org care network
                                    </Typography>
                                  </Box>
                                </ListItem>
                              )}
                            </List>
                          </Collapse>
                        )}

                        <ListItem
                          button
                          tabIndex={0}
                          style={{
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                          onClick={(event) => {
                            handleNavTabExpandClick('referral-details')
                          }}
                        >
                          {expandedTabs.includes('referral-details') ? (
                            <ExpandMore />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          <Box paddingX={2}>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              style={{
                                textTransform: 'capitalize',
                                letterSpacing: 'normal',
                                whiteSpace: 'nowrap',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                              }}
                            >
                              Referral Details
                            </Typography>
                          </Box>
                        </ListItem>
                        <Collapse
                          in={expandedTabs.includes('referral-details')}
                          timeout='auto'
                          unmountOnExit
                        >
                          <List component='div' disablePadding>
                            {(isUnitAdmin() || isGre()) && (
                              <ListItem
                                button
                                tabIndex={0}
                                style={{
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'referrals' &&
                                    focusVal === 'referrals'
                                      ? kPrimaryLight
                                      : focusVal === 'referrals'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                className={classes.first}
                                selected={selectedOption === 'referrals'}
                                onClick={(event) => {
                                  if (selectedOption !== 'referrals') {
                                    setSelectedOption('referrals')
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'referrals') {
                                    setFocusVal('referrals')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Referrals
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}
                          </List>
                        </Collapse>

                        <ListItem
                          button
                          tabIndex={0}
                          style={{
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                          onClick={(event) => {
                            handleNavTabExpandClick('service-details')
                          }}
                        >
                          {expandedTabs.includes('service-details') ? (
                            <ExpandMore />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          <Box paddingX={2}>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              style={{
                                textTransform: 'capitalize',
                                letterSpacing: 'normal',
                                whiteSpace: 'nowrap',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                              }}
                            >
                              Service Details
                            </Typography>
                          </Box>
                        </ListItem>
                        <Collapse
                          in={expandedTabs.includes('service-details')}
                          timeout='auto'
                          unmountOnExit
                        >
                          <List component='div' disablePadding>
                            {isUnitAdmin() &&
                              loggedInUnitType === 'Wellness Center' && (
                                <ListItem
                                  button
                                  tabIndex={0}
                                  style={{
                                    paddingLeft: '10px',
                                    paddingRight: '4px',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    backgroundColor:
                                      selectedOption === 'discount_settings' &&
                                      focusVal === 'discount_settings'
                                        ? kPrimaryLight
                                        : focusVal === 'discount_settings'
                                        ? kContrastText
                                        : 'transparent',
                                  }}
                                  className={classes.first}
                                  selected={
                                    selectedOption === 'discount_settings'
                                  }
                                  onClick={(event) => {
                                    if (
                                      selectedOption !== 'discount_settings'
                                    ) {
                                      setSelectedOption('discount_settings')
                                    }
                                  }}
                                  onFocus={(e) => {
                                    if (focusVal !== 'discount_settings') {
                                      setFocusVal('discount_settings')
                                    }
                                  }}
                                >
                                  <Box paddingX={2}>
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{
                                        textTransform: 'capitalize',
                                        letterSpacing: 'normal',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Discount settings
                                    </Typography>
                                  </Box>
                                </ListItem>
                              )}

                            {isUnitAdmin() &&
                              (loggedInUnitType === 'Clinic' ||
                                loggedInUnitType === 'Wellness Center') && (
                                <ListItem
                                  button
                                  tabIndex={0}
                                  style={{
                                    paddingLeft: '10px',
                                    paddingRight: '4px',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    backgroundColor:
                                      selectedOption === 'service_price' &&
                                      focusVal === 'service_price'
                                        ? kPrimaryLight
                                        : focusVal === 'service_price'
                                        ? kContrastText
                                        : 'transparent',
                                  }}
                                  className={classes.first}
                                  selected={selectedOption === 'service_price'}
                                  onClick={(event) => {
                                    if (selectedOption !== 'service_price') {
                                      setSelectedOption('service_price')
                                    }
                                  }}
                                  onFocus={(e) => {
                                    if (focusVal !== 'service_price') {
                                      setFocusVal('service_price')
                                    }
                                  }}
                                >
                                  <Box paddingX={2}>
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{
                                        textTransform: 'capitalize',
                                        letterSpacing: 'normal',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Price
                                    </Typography>
                                  </Box>
                                </ListItem>
                              )}

                            {isUnitAdmin() && loggedInUnitType === 'Clinic' && (
                              <ListItem
                                button
                                tabIndex={0}
                                style={{
                                  paddingLeft: '10px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'service_followUp' &&
                                    focusVal === 'service_followUp'
                                      ? kPrimaryLight
                                      : focusVal === 'service_followUp'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                className={classes.first}
                                selected={selectedOption === 'service_followUp'}
                                onClick={(event) => {
                                  if (selectedOption !== 'service_followUp') {
                                    setSelectedOption('service_followUp')
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'service_followUp') {
                                    setFocusVal('service_followUp')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Follow Ups
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}
                          </List>
                        </Collapse>
                        <ListItem
                          button
                          tabIndex={0}
                          style={{
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                          onClick={(event) => {
                            handleNavTabExpandClick('facilities-for-services')
                          }}
                        >
                          {expandedTabs.includes('facilities-for-services') ? (
                            <ExpandMore />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          <Box paddingX={2}>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              style={{
                                textTransform: 'capitalize',
                                letterSpacing: 'normal',
                                whiteSpace: 'nowrap',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                              }}
                            >
                              Facilities for Services
                            </Typography>
                          </Box>
                        </ListItem>
                        <Collapse
                          in={expandedTabs.includes('facilities-for-services')}
                          timeout='auto'
                          unmountOnExit
                        >
                          <List component='div' disablePadding>
                            {loggedInUnitType !== 'Clinic' && (
                              <ListItem
                                button
                                tabIndex={0}
                                style={{
                                  paddingLeft: '14px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'substance' &&
                                    focusVal === 'substance'
                                      ? kPrimaryLight
                                      : focusVal === 'substance'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                className={classes.second}
                                selected={selectedOption === 'substance'}
                                onClick={(event) => {
                                  if (selectedOption !== 'substance') {
                                    setSelectedOption('substance')
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'substance') {
                                    setFocusVal('substance')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Consumable
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}
                            <ListItem
                              button
                              tabIndex={0}
                              style={{
                                paddingLeft: '14px',
                                paddingRight: '4px',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                                backgroundColor:
                                  selectedOption === 'diet' &&
                                  focusVal === 'diet'
                                    ? kPrimaryLight
                                    : focusVal === 'diet'
                                    ? kContrastText
                                    : 'transparent',
                              }}
                              className={classes.second}
                              selected={selectedOption === 'diet'}
                              onClick={(event) => {
                                if (selectedOption !== 'diet') {
                                  setSelectedOption('diet')
                                }
                              }}
                              onFocus={(e) => {
                                if (focusVal !== 'diet') {
                                  setFocusVal('diet')
                                }
                              }}
                            >
                              <Box paddingX={2}>
                                <Typography
                                  variant='subtitle2'
                                  color='primary'
                                  style={{
                                    textTransform: 'capitalize',
                                    letterSpacing: 'normal',
                                    whiteSpace: 'nowrap',
                                    justifyContent: 'flex-start',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Diet
                                </Typography>
                              </Box>
                            </ListItem>
                            {loggedInUnitType !== 'Clinic' && (
                              <ListItem
                                button
                                tabIndex={0}
                                style={{
                                  paddingLeft: '14px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'therapy' &&
                                    focusVal === 'therapy'
                                      ? kPrimaryLight
                                      : focusVal === 'therapy'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                className={classes.second}
                                selected={selectedOption === 'therapy'}
                                onClick={(event) => {
                                  if (selectedOption !== 'therapy') {
                                    setSelectedOption('therapy')
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'therapy') {
                                    setFocusVal('therapy')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Naturopathy based Therapy
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}
                            {loggedInUnitType !== 'Clinic' && (
                              <ListItem
                                button
                                tabIndex={0}
                                style={{
                                  paddingLeft: '14px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'physiotherapy' &&
                                    focusVal === 'physiotherapy'
                                      ? kPrimaryLight
                                      : focusVal === 'physiotherapy'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                className={classes.second}
                                selected={selectedOption === 'physiotherapy'}
                                onClick={(event) => {
                                  if (selectedOption !== 'physiotherapy') {
                                    setSelectedOption('physiotherapy')
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'physiotherapy') {
                                    setFocusVal('physiotherapy')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Physiotherapy
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}
                            {isUnitAdmin() &&
                              (isCurrentUnitIsClinic() ||
                                isCurrentUnitIsWellonessCenter()) &&
                              finalData.length > 0 && (
                                <ListItem
                                  button
                                  tabIndex={0}
                                  style={{
                                    paddingLeft: '14px',
                                    paddingRight: '4px',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    backgroundColor:
                                      selectedOption === 'rooms' &&
                                      focusVal === 'rooms'
                                        ? kPrimaryLight
                                        : focusVal === 'rooms'
                                        ? kContrastText
                                        : 'transparent',
                                  }}
                                  className={classes.second}
                                  selected={selectedOption === 'rooms'}
                                  onClick={(event) => {
                                    if (selectedOption !== 'rooms') {
                                      setSelectedOption('rooms')
                                    }
                                  }}
                                  onFocus={(e) => {
                                    if (focusVal !== 'rooms') {
                                      setFocusVal('rooms')
                                    }
                                  }}
                                >
                                  <Box paddingX={2}>
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{
                                        textTransform: 'capitalize',
                                        letterSpacing: 'normal',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Rooms
                                    </Typography>
                                  </Box>
                                </ListItem>
                              )}
                          </List>
                        </Collapse>

                        <ListItem
                          button
                          style={{
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                          onClick={(event) => {
                            handleNavTabExpandClick('finance-services')
                          }}
                          tabIndex={0}
                        >
                          {expandedTabs.includes('finance-services') ? (
                            <ExpandMore />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          <Box paddingX={2}>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              style={{
                                textTransform: 'capitalize',
                                letterSpacing: 'normal',
                                whiteSpace: 'nowrap',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                              }}
                            >
                              Finance Services
                            </Typography>
                          </Box>
                        </ListItem>

                        {/* {isUnitAdmin() && (
                        <List component='nav'>
                          <ListItem
                            style={{
                              paddingLeft: '4px',
                              paddingRight: '4px',
                              paddingTop: '8px',
                              paddingBottom: '8px',
                            }}
                            button
                            onClick={(event) => {
                              handleNavTabExpandClick('payment-settings')
                            }}
                          >
                            {expandedTabs.includes('payment-settings') ? (
                              <ExpandMore />
                            ) : (
                              <ChevronRightIcon />
                            )}
                            <Box paddingX={2}>
                              <Typography
                                variant='subtitle2'
                                color='primary'
                                style={{
                                  textTransform: 'capitalize',
                                  letterSpacing: 'normal',
                                  whiteSpace: 'nowrap',
                                  justifyContent: 'flex-start',
                                  cursor: 'pointer',
                                }}
                              >
                                Payment Settings
                              </Typography>
                            </Box>
                          </ListItem>
                          <Collapse
                            in={expandedTabs.includes('payment-settings')}
                            timeout='auto'
                            unmountOnExit
                          >
                            <List component='div' disablePadding>
                              <ListItem
                                style={{
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'acc_details'
                                      ? kPrimaryLight
                                      : 'transparent',
                                }}
                                button
                                className={classes.first}
                                selected={selectedOption === 'acc_details'}
                                onClick={(event) => {
                                  if (selectedOption !== 'acc_details') {
                                    setSelectedOption('acc_details')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Account Management
                                  </Typography>
                                </Box>
                              </ListItem>

                              <ListItem
                                style={{
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'referral_fee'
                                      ? kPrimaryLight
                                      : 'transparent',
                                }}
                                button
                                className={classes.first}
                                selected={selectedOption === 'referral_fee'}
                                onClick={(event) => {
                                  if (selectedOption !== 'referral_fee') {
                                    setSelectedOption('referral_fee')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Referral Fee
                                  </Typography>
                                </Box>
                              </ListItem>

                              <ListItem
                                button
                                style={{
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'conv_fee'
                                      ? kPrimaryLight
                                      : 'transparent',
                                }}
                                className={classes.first}
                                selected={selectedOption === 'conv_fee'}
                                onClick={(event) => {
                                  if (selectedOption !== 'conv_fee') {
                                    setSelectedOption('conv_fee')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Convenience Fee
                                  </Typography>
                                </Box>
                              </ListItem>
                            </List>
                          </Collapse>
                        </List>
                      )} */}

                        <Collapse
                          in={expandedTabs.includes('finance-services')}
                          timeout='auto'
                          unmountOnExit
                        >
                          <List component='div' disablePadding>
                            {isUnitAdmin() && (
                              <ListItem
                                button
                                tabIndex={0}
                                style={{
                                  paddingLeft: '14px',
                                  paddingRight: '4px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  backgroundColor:
                                    selectedOption === 'referral_fee' &&
                                    focusVal === 'referral_fee'
                                      ? kPrimaryLight
                                      : focusVal === 'referral_fee'
                                      ? kContrastText
                                      : 'transparent',
                                }}
                                className={classes.second}
                                selected={selectedOption === 'referral_fee'}
                                onClick={(event) => {
                                  if (selectedOption !== 'referral_fee') {
                                    setSelectedOption('referral_fee')
                                  }
                                }}
                                onFocus={(e) => {
                                  if (focusVal !== 'referral_fee') {
                                    setFocusVal('referral_fee')
                                  }
                                }}
                              >
                                <Box paddingX={2}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                    style={{
                                      textTransform: 'capitalize',
                                      letterSpacing: 'normal',
                                      whiteSpace: 'nowrap',
                                      justifyContent: 'flex-start',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Referral fees
                                  </Typography>
                                </Box>
                              </ListItem>
                            )}
                            {/* {isUnitAdmin() && (
                            <ListItem
                              button
                              style={{
                                paddingLeft: '14px',
                                paddingRight: '4px',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                                backgroundColor:
                                  selectedIndex === 21
                                    ? kPrimaryLight
                                    : 'transparent',
                              }}
                              className={classes.second}
                              selected={selectedIndex === 21}
                              onClick={(event) => {
                                handleListItemClick(event, 21)
                                if (selectedOption !== 'account') {
                                  setSelectedOption('account')
                                }
                              }}
                            >
                              <Box paddingX={2}>
                                <Typography
                                  variant='subtitle2'
                                  color='primary'
                                  style={{
                                    textTransform: 'capitalize',
                                    letterSpacing: 'normal',
                                    whiteSpace: 'nowrap',
                                    justifyContent: 'flex-start',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Account
                                </Typography>
                              </Box>
                            </ListItem>
                          )} */}
                          </List>
                        </Collapse>

                        <ListItem
                          button
                          style={{
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                          tabIndex={0}
                          onClick={(event) => {
                            window.open(
                              `${process.env.REACT_APP_AUTH_URL}/oidc/myAccount`
                            )
                          }}
                        >
                          <Box paddingX={2}>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              style={{
                                textTransform: 'capitalize',
                                letterSpacing: 'normal',
                                whiteSpace: 'nowrap',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                              }}
                            >
                              Reset Password
                            </Typography>
                          </Box>
                        </ListItem>
                      </List>
                    </nav>
                  )}
              </Box>
            </SimpleBar>
          </Box>
          <Box display='flex'>
            <Divider orientation='vertical' />
          </Box>
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box flexDirection='row' width='100%' display='flex' paddingTop={2}>
              <Box flexDirection='row' display='flex' flexGrow paddingX={1}>
                {isOrgAdmin() &&
                  selectedOption === 'units' &&
                  unitsOfOrganizationSlice.unitsList &&
                  unitsOfOrganizationSlice.unitsList.length > 0 && (
                    <Button
                      id='btn_dash_add_unit_members'
                      color='primary'
                      variant='contained'
                      size='small'
                      onClick={() => {
                        setShowAddUnitAdminDetails(true)
                      }}
                    >
                      {t('labelCommon:attach_actors_to_unit')}
                    </Button>
                  )}

                {isOrgAdmin() && selectedOption === 'units' && (
                  <Button
                    id='btn_dash_add_units'
                    color='primary'
                    variant='contained'
                    size='small'
                    onClick={() => {
                      setShowAddUnitDetails(true)
                    }}
                  >
                    {t('labelCommon:add_unit')}
                  </Button>
                )}
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-end'
                alignContent='flex-end'
                flexGrow='1'
              >
                {selectedOption === 'Labs' && (
                  <Box paddingTop={1} px={1} paddingBottom={0.5}>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setOpenPartnerLabPopup(true)
                      }}
                    >
                      {t('labelCommon:add_partner_lab')}
                    </Button>
                  </Box>
                )}

                {selectedOption === 'users' && (
                  <Box
                    paddingTop={2}
                    px={1}
                    paddingBottom={0.5}
                    flexDirection='row'
                    display='flex'
                    width='100%'
                    alignContent='space-between'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Box
                      display='flex'
                      px={1}
                      justifyContent='center'
                      //   height='30'
                    >
                      <TextField
                        variant='outlined'
                        onChange={(event) => {
                          setText(event.target.value)
                          dispatch(searchPractitioners(event.target.value))
                        }}
                        size='small'
                        value={text}
                        autoFocus={true}
                        placeholder='Search By User Name'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                src={`${process.env.PUBLIC_URL}/search.png`}
                                alt='s'
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    {isUnitAdmin() && (
                      <Button
                        id='btn_dash_add_unit_members'
                        color='primary'
                        variant='contained'
                        size='small'
                        onClick={() => {
                          setShowAddUsers(true)
                        }}
                        style={{ height: 35 }}
                      >
                        {isOrgAdmin()
                          ? t('labelCommon:add_org_actors_label')
                          : t('labelCommon:add_unit_actors_label')}
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            {selectedOption === 'Tests' && (isOrgAdmin() || isUnitAdmin()) && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                paddingTop={2}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-end'
                  paddingRight={2.5}
                >
                  <Button
                    id='btn_dash_add_unit_members'
                    color='primary'
                    variant='contained'
                    size='medium'
                    onClick={() => {
                      SetOpenPopup(true)
                      // setShowAddUsers(true)
                    }}
                  >
                    Add Lab Test/Panel
                  </Button>
                </Box>
                <CatalogueSearch />
              </Box>
            )}

            {selectedOption === 'Packages' && (
              <PackageSearch onTap={() => {}} />
            )}
            {selectedOption === 'referrals' &&
              (isUnitAdmin() || isGre() || isOrgAdmin()) && <ReferralSearch />}

            {selectedOption === 'uploadSign' && <Signature />}
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#ececec',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                flexGrow={1}
                height='100%'
                style={{ overflow: 'auto', maxHeight: '100%' }}
                width='100%'
              >
                {selectedOption === 'unit' &&
                  (isUnitAdmin() ||
                    isMedicalServiceProvider() ||
                    isGre() ||
                    isAgent() ||
                    isTherapist() ||
                    isPartnerLab()) &&
                  loggedInUnitType !== 'Lab' &&
                  loggedInUnitType !== 'Clinic' &&
                  loggedInUnitType !== 'Wellness Center' && (
                    <ProfilleDetails
                      unitOrgDetail={currentOrganizationDetails}
                      unitLocationDetail={currentLocation}
                      onUpdated={() => {
                        setSelectedOption('unit')
                      }}
                    />
                  )}
                {selectedOption === 'contact_info' && isOrgAdmin() && (
                  <LabProfileDetails
                    unitOrgDetail={currentOrganizationDetails}
                    unitLocationDetail={currentLocation}
                    onUpdated={() => {
                      setSelectedOption('contact_info')
                    }}
                  />
                )}

                {selectedOption === 'unit' &&
                  (isUnitAdmin() ||
                    isMedicalServiceProvider() ||
                    isAgent() ||
                    isPartnerLab()) &&
                  loggedInUnitType === 'Lab' && (
                    <LabProfileDetails
                      unitOrgDetail={currentOrganizationDetails}
                      unitLocationDetail={currentLocation}
                      onUpdated={() => {
                        setSelectedOption('unit')
                      }}
                    />
                  )}
                {selectedOption === 'unit' &&
                  (isUnitAdmin() ||
                    isMedicalServiceProvider() ||
                    isPartnerLab() ||
                    isGre() ||
                    isTherapist() ||
                    isDietician()) &&
                  (loggedInUnitType === 'Clinic' ||
                    loggedInUnitType === 'Wellness Center') && (
                    <LabProfileDetails
                      unitOrgDetail={currentOrganizationDetails}
                      unitLocationDetail={currentLocation}
                      onUpdated={() => {
                        setSelectedOption('unit')
                      }}
                    />
                  )}
                {selectedOption === 'units' &&
                  (isOrgAdmin() || isUnitAdmin()) && <UnitsOfOrganization />}
                {selectedOption === 'rooms' && (isUnitAdmin() || isGre()) && (
                  <RoomsOfOrganization />
                )}
              </Box>
            </Box>

            {selectedOption === 'membership' &&
              (isUnitAdmin() || isGre() || isOrgAdmin()) && (
                <MembershipSearch />
              )}

            {selectedOption === 'referral_fee' &&
              (isUnitAdmin() || isOrgAdmin() || isWpAdmin()) && (
                <ReferralFeeSearch />
              )}

            {selectedOption === 'conv_fee' &&
              (isUnitAdmin() || isOrgAdmin() || isWpAdmin()) && <ConvSearch />}

            {selectedOption === 'acc_details' && <AccountDetailsPage />}

            {selectedOption === 'care-plan-subscription' &&
              (isUnitAdmin() || isOrgAdmin()) && <CPSubscriptionsListPage />}

            {selectedOption === 'care-team' && isUnitAdmin() && (
              <CareTeamsForCarePlansComponents />
            )}

            {selectedOption === 'c-p-g' && (isUnitAdmin() || isOrgAdmin()) && (
              <CPGListPage />
            )}

            {selectedOption === 'users' &&
              (isOrgAdmin() || isUnitAdmin() || isGre()) && (
                <UserSearch
                  onClosed={() => {
                    setText('')
                  }}
                  searchText={text}
                />
              )}

            {selectedOption === 'cross-org-functional' && (
              <CrossFunctionalCareComponent />
            )}
            {selectedOption === 'settlement' && <SettlementFile />}
            {selectedOption === 'cancellation' && <Cancellation />}

            {selectedOption === 'therapy' && <TherapySearch />}
            {selectedOption === 'physiotherapy' && <PhysiotherapySearch />}
            {selectedOption === 'diet' && <DietSettings />}
            {selectedOption === 'substance' && <SubstanceSearch />}
            {selectedOption === 'reports' && (
              <Box paddingTop={8.8}>
                <ReportSearch onTap={() => {}} />
              </Box>
            )}

            {selectedOption === 'rate' && <AppointmentRate onTap={() => {}} />}
            {selectedOption === 'service_price' && <ServicePriceSelector />}
            {selectedOption === 'service_followUp' && <FollowUpSelector />}

            {selectedOption === 'discount_settings' && <DiscountRateSearch />}
            {selectedOption === 'Labs' && <PartnerLabSearch onTap={() => {}} />}
            {selectedOption === 'my_working_hour' && (
              <MedicalProviderProfileDetails />
            )}
            {selectedOption === 'pre-appointment' && (
              <PreAppointmentQuestions />
            )}
            {selectedOption === 'Working_Hours' && (
              <WorkingHourSettings
                unitOrgDetail={currentOrganizationDetails}
                unitLocationDetail={getCurrentUserUnitLocationDetails()}
                onUpdated={() => {
                  setSelectedOption('Working_Hours')
                  setCurrentLocation(getCurrentUserUnitLocationDetails())
                }}
              />
            )}

            {selectedOption === 'Tests' &&
              openPopup &&
              (isOrgAdmin() || isUnitAdmin()) && (
                <Upload
                  open={openPopup}
                  onClose={() => {
                    dispatch(resetObservationState)
                    SetOpenPopup(false)
                  }}
                  onDiscard={() => {
                    dispatch(resetObservationState)
                    SetOpenPopup(false)
                  }}
                />
              )}

            {selectedOption === 'Patients_Upload' &&
              (isOrgAdmin() || isUnitAdmin()) && (
                <PatientSetUp
                  open={openPopup}
                  onClose={() => {
                    SetOpenPopup(false)
                  }}
                  onDiscard={() => {
                    SetOpenPopup(false)
                  }}
                />
              )}
          </Box>
        </Box>
        <Box />
      </Paper>

      {openAddPatientPopup === true && loggedInUnitType !== 'Lab' && (
        <AddNewPatient
          isLab={true}
          isAppointment={false}
          popup={openAddPatientPopup}
          onPatientCreated={() => {
            dispatch(resetState())
            setOpenAddPatientPopup(false)
            requestOrdersForTheDateRange(
              selectedDoctors.current ?? [],
              selectedDate,
              selectedOrderTypes
            )
          }}
          onError={() => {
            dispatch(
              showErrorAlert('Error while adding patient. Please try later')
            )
            dispatch(resetState())
            setOpenAddPatientPopup(false)
          }}
          onCancelClicked={() => {
            dispatch(resetState())
            setOpenAddPatientPopup(false)
          }}
        />
      )}

      {openAddPatientPopup === true &&
        loggedInUnitType !== 'Collection Center' && (
          <AddNewPatient
            isLab={true}
            isAppointment={false}
            popup={openAddPatientPopup}
            onPatientCreated={() => {
              dispatch(showSuccessAlert('Patient added successfully'))
              dispatch(resetState())
              setOpenAddPatientPopup(false)
              requestOrdersForTheDateRange(
                selectedDoctors.current ?? [],
                selectedDate,
                selectedOrderTypes
              )
            }}
            onError={() => {
              dispatch(
                showErrorAlert('Error while adding patient. Please try later')
              )
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
            onCancelClicked={() => {
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
          />
        )}

      {openAddPatientPopup === true &&
        (loggedInUnitType === 'Lab' ||
          loggedInUnitType === 'Collection Center') && (
          <AddLabNewPatient
            popup={openAddPatientPopup}
            onPatientCreated={() => {
              dispatch(showSuccessAlert('Patient added successfully'))
              dispatch(resetState())
              setOpenAddPatientPopup(false)
              requestOrdersForTheDateRange(
                selectedDoctors.current ?? [],
                selectedDate,
                selectedOrderTypes
              )
            }}
            onError={() => {
              dispatch(
                showErrorAlert('Error while adding patient. Please try later')
              )
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
            onCancelClicked={() => {
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
          />
        )}

      <AddPackageHandler
        open={openPackage}
        onContinueClick={() => {
          dispatch(resetPackageState())
          setOpenPackage(false)
        }}
        onBackClick={() => {
          dispatch(resetPackageState())
          dispatch(resetCatalogueSearchStatus())
          setOpenPackage(false)
        }}
      />

      {openPartnerLabPopup === true && (
        <AddPartnerLabHandler
          open={openPartnerLabPopup}
          onContinueClick={() => {
            setOpenPartnerLabPopup(false)
          }}
          onBackClick={() => {
            setOpenPartnerLabPopup(false)
          }}
        />
      )}

      {/* {openUploadReportPopup === true && (
        <UploadReport
          open={openUploadReportPopup}
          onClose={() => {
            dispatch(searchInvitations())
            setOpenUploadReportPopup(false)
          }}
          onDiscard={() => {
            dispatch(searchInvitations())
            setOpenUploadReportPopup(false)
          }}
        />
      )} */}

      {showAddUnitAdminDetails && (
        <AddUnitAdminsHandler
          open={showAddUnitAdminDetails}
          onBackClick={() => {
            setShowAddUnitAdminDetails(false)
          }}
          onContinueClick={() => {
            dispatch(searchInvitations())
            setShowAddUnitAdminDetails(false)
          }}
        />
      )}
      <AddUnitsHandler
        open={showAddUnitDetails}
        onBackClick={() => {
          setShowAddUnitDetails(false)
        }}
        onContinueClick={() => {
          dispatch(searchUnits())
          setShowAddUnitDetails(false)
          dispatch(resetUnitAddReducerState())
        }}
      />
      {showAddUsers && (
        <AddActorsHandler
          open={showAddUsers}
          onBackClick={() => {
            setShowAddUsers(false)
          }}
          onContinueClick={() => {
            setShowAddUsers(false)
          }}
        />
      )}

      <CreateLabOrderHandler
        open={openCreateLabOrderPopup}
        onLabOrderCreated={() => {
          setOpenCreateLabOrderPopup(false)
        }}
        onClose={() => {
          setOpenCreateLabOrderPopup(false)
        }}
      />
    </div>
  )
}
