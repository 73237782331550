import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  IChargeItemDefinition_Applicability,
  IOrganization,
  IPractitionerRole,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { Comonad, cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _, { uniqBy } from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloPrescriptionLabTest } from 'models/fhirPrescriptionDetails'
import { FhirReferralRequest } from 'models/fhirReferralRequest'
import { LabReferralService } from 'models/labOfferDetail'
import { StringIfPlural } from 'react-i18next'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  getAddress,
  getDisplayOfSystemFromCodableConcept,
  getNameFromHumanName,
  getNameOfPatient,
  getStringValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { getLoincCodeFromPlanDef } from 'utils/patientHelper/cds_helper'
import { context } from '../../../webpack.config'

export interface CareTeamMember {
  roleId: string
  practId: string
  practitioner: R4.IPractitioner
  practitionerRole: IPractitionerRole
  roleInCareTeam: R4.ICodeableConcept[]
}

export function getReferralRequestsFromBundle(
  responseSlots: R4.IBundle
): FhirReferralRequest[] {
  const convertedAppointments: FhirReferralRequest[] = []
  const serviceRequests: any = {}
  const patients: any = {}
  const planDefinitions: any = {}
  const provenances: R4.ITask[] = []
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                case 'Task':
                  provenances.push(value.resource as R4.ITask)
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            let taskOfRequest: R4.ITask | undefined
            const indexOfTask = provenances.findIndex((e) =>
              e.focus?.reference?.includes(currentAppointment.id ?? 'xxx')
            )
            if (indexOfTask > -1) {
              taskOfRequest = provenances[indexOfTask]
            }
            convertedAppointments.push({
              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              task: taskOfRequest,
            })
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getDbReferralDetailsFromFhir(request: FhirReferralRequest) {
  let orderDetail: string = ''

  if (
    request.appointment &&
    request.appointment.practitionerDetail &&
    request.appointment.practitionerDetail.practitioner
  ) {
    const doctorName: string = getNameFromHumanName(
      request.appointment.practitionerDetail.practitioner.name ?? []
    )
    const slot: string = ''
    const slotRes: R4.ISlot | undefined = request.appointment.slot

    orderDetail = `Referral with Dr.${doctorName}`
  } else {
    const specialityString: string | undefined = getSpecialtyInputFromTask(
      request.task!
    )
    if (specialityString)
      orderDetail = `Appointment to be scheduled with a doctor specialized in ${specialityString}`
  }

  return orderDetail
}

export function getSpecialtyInputFromTask(
  bundle: R4.ITask
): string | undefined {
  if (bundle.input && bundle.input.length > 0) {
    const index: number = bundle.input.findIndex(
      (e) => e.type?.coding?.[0].code === 'preferred-specialty-snomed'
    )
    if (index > -1)
      return bundle.input?.[index].valueCodeableConcept?.coding?.[0].display
  }
  return undefined
}

export async function getAvailableWellnessReferrals(): Promise<
  R4.IHealthcareService[] | undefined
> {
  try {
    const resp = await fetchKnowReferralServices('wellness-therapy-referral')

    if (resp) {
      if (resp.entry && resp.entry.length > 0) {
        const healthCareServiesEntries = resp.entry.filter((e) => {
          if (e.resource) {
            if (
              e.resource.resourceType &&
              e.resource.resourceType === 'HealthcareService'
            ) {
              return true
            }
          }
          return false
        })

        return healthCareServiesEntries.map(
          (e) => e.resource as R4.IHealthcareService
        )
      }
    }
  } catch (error) {}
  return undefined
}

export async function fetchKnowReferralServices(
  referralType: string
): Promise<R4.IBundle | undefined> {
  const searchParams = {
    'service-type': referralType,
    active: true,
  }

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const response: any = await fhirClient.doGetResource(
    '/HealthcareService',
    searchParams
  )

  if (response?.total && response?.total > 0) {
    return response as R4.IBundle
  }

  return undefined
}

export function createBundleObjectForWellnessReferral(
  appointment: FhirAppointmentDetail,
  encounter: R4.IEncounter,
  medications: R4.IHealthcareService,
  addInformation?: string
): R4.IBundle {
  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }
  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
    type: encounter.resourceType,
  }
  let providerDetails: R4.IOrganization | undefined

  if (medications.providedBy) {
    if (
      medications.providedBy.reference &&
      medications.providedBy.reference.startsWith('#')
    ) {
      const id = medications.providedBy.reference ?? ''
      const searchId = id.split('#')[1]
      if (medications.contained && medications.contained.length > 0) {
        const index = medications.contained?.findIndex((e) => e.id === searchId)
        providerDetails = medications.contained[index] as IOrganization
      }
    }
  }
  const allergyDetails: R4.IServiceRequest[] = [
    {
      resourceType: 'ServiceRequest',
      subject: {
        display: getNameOfPatient(appointment.patient),
        reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
        id: appointment.patient.id,
        type: appointment.patient.resourceType,
      },
      contained: providerDetails === undefined ? [] : [providerDetails],
      asNeededBoolean: true,
      category: medications.category?.map((e) => ({
        coding: e.coding?.map((c) => ({
          ...c,
          display: medications.name,
        })),
      })),
      code: medications.type?.[0],
      performer:
        providerDetails !== undefined
          ? [
              {
                reference: `#${providerDetails.id}`,
                display: providerDetails.name,
                type: 'Organization',
              },
            ]
          : [],
      intent: 'proposal',
      status: 'active',
      encounter: encounterRef,
      authoredOn: new Date().toISOString(),
      requester: {
        display: getNameFromHumanName(
          appointment.practitionerDetail.practitioner.name ?? []
        ),
        id: appointment.practitionerDetail.practitionerRole.id,
        reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
        type: appointment.practitionerDetail.practitionerRole.resourceType,
      },
      patientInstruction: addInformation,
    },
  ]

  const bundleEntries: R4.IBundle_Entry[] = allergyDetails.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function getAddressFromServiceRequest(e: R4.IServiceRequest) {
  if (e.performer && e.performer.length > 0) {
    const index = e.performer.findIndex(
      (performer) => performer.display !== undefined
    )
    if (index > -1) {
      return e.performer[index].display ?? ''
    }
  }
  return ''
}

export function getPatientFromContainedResource(
  healthcareService: R4.IServiceRequest
): R4.IPatient | undefined {
  if (healthcareService.contained && healthcareService.contained.length > 0) {
    const id = healthcareService.subject.reference
    const searchId = id?.split('#')[1]

    const index = healthcareService.contained.findIndex(
      (e) => e.id === searchId
    )

    if (index > -1) {
      return healthcareService.contained[index] as R4.IPatient
    }
  }

  return undefined
}

export function getUnitFromContainedResource(
  healthcareService: R4.IServiceRequest
): R4.IOrganization | undefined {
  if (healthcareService.contained && healthcareService.contained.length > 0) {
    const index = healthcareService.contained.findIndex(
      (e) => e.id === 'requestingUnit'
    )

    if (index > -1) {
      return healthcareService.contained[index] as R4.IOrganization
    }
  }

  return undefined
}

export function getTestNameFromOrderDetails(
  healthcareService: R4.IServiceRequest
): string {
  const testNames: string[] = []
  if (
    healthcareService.orderDetail &&
    healthcareService.orderDetail.length > 0
  ) {
    for (let i = 0; healthcareService.orderDetail.length; i++) {
      const coding: R4.ICoding[] = healthcareService.orderDetail[i].coding ?? []
      if (coding && coding.length > 0) {
        for (let j = 0; j < coding.length; j++) {
          testNames.push(coding[j].display ?? '')
        }
      }
      if (testNames.length > 0) {
        if (testNames.length > 1) return testNames.join(',')
        if (testNames.length === 1) return testNames[0]
      }
      return ''
    }
  }

  return ''
}

export function getLabTestDataForPrescription(
  healthcareService: R4.IServiceRequest
): WelloPrescriptionLabTest[] {
  const testNames: WelloPrescriptionLabTest[] = []
  if (
    healthcareService.orderDetail &&
    healthcareService.orderDetail.length > 0
  ) {
    for (let i = 0; i < healthcareService.orderDetail.length; i++) {
      const coding: R4.ICoding[] = healthcareService.orderDetail[i].coding ?? []
      if (coding && coding.length > 0) {
        for (let j = 0; j < coding.length; j++) {
          const data: WelloPrescriptionLabTest = {
            testName:
              coding[j].display ?? healthcareService.patientInstruction ?? '',
            labName: healthcareService.performer
              ? healthcareService.performer[0].display ?? ''
              : '',
          }
          testNames.push(data)
        }
      }
    }
  }

  return testNames
}

export function getTestNameFromOrderDetailsForReferralTile(
  healthcareService: R4.IServiceRequest
): string {
  const testNames: string[] = []
  if (
    healthcareService.orderDetail &&
    healthcareService.orderDetail.length > 0
  ) {
    healthcareService.orderDetail.forEach((e: R4.ICodeableConcept) => {
      if (e.coding) {
        e.coding.forEach((e1: R4.ICoding) => {
          testNames.push(e1.display ?? '')
        })
      }
    })
  }

  if (testNames.length > 0) {
    if (testNames.length > 1) return testNames.join(', ')
    if (testNames.length === 1) return testNames[0]
  }
  return ''
}

export function getTestLionCode(healthcareService: R4.IServiceRequest): string {
  const testNames: string[] = []
  if (
    healthcareService.orderDetail &&
    healthcareService.orderDetail.length > 0
  ) {
    for (let i = 0; healthcareService.orderDetail.length; i++) {
      const coding: R4.ICoding[] = healthcareService.orderDetail[i].coding ?? []
      if (coding && coding.length > 0) {
        for (let j = 0; j < coding.length; j++) {
          testNames.push(coding[j].code ?? '')
        }
      }
      if (testNames.length > 0) {
        if (testNames.length > 1) return testNames.join(',')
        if (testNames.length === 1) return testNames[0]
      }
      return ''
    }
  }

  return ''
}

export function getTestLionCodeForReferralTile(
  healthcareService: R4.IServiceRequest
): string {
  const testNames: string[] = []
  if (
    healthcareService.orderDetail &&
    healthcareService.orderDetail.length > 0
  ) {
    healthcareService.orderDetail.forEach((e: R4.ICodeableConcept) => {
      if (e.coding) {
        e.coding.forEach((e1: R4.ICoding) => {
          testNames.push(e1.code ?? '')
        })
      }
    })
  }
  if (testNames.length > 0) {
    if (testNames.length > 1) return testNames.join(', ')
    if (testNames.length === 1) return testNames[0]
  }
  return ''
}

export function getUnitFromProvidedBy(
  healthcareService: R4.IServiceRequest
): string | undefined {
  if (healthcareService.performer && healthcareService.performer.length > 0) {
    const index = healthcareService.performer.findIndex((e) => e.reference)
    if (index > -1) {
      return healthcareService.performer[index].display as string
    }
  }

  return undefined
}

export function getPractRoleFromContainedResource(
  healthcareService: R4.IServiceRequest
): R4.IPractitionerRole | undefined {
  if (healthcareService.contained && healthcareService.contained.length > 0) {
    const id = healthcareService.requester?.reference

    const searchId = id?.split('#')[1]

    const index = healthcareService.contained.findIndex(
      (e) => e.id === searchId
    )

    if (index > -1) {
      return healthcareService.contained[index] as R4.IPractitionerRole
    }
  }

  return undefined
}

export function getPractitionerFromContainedResource(
  healthcareService: R4.IServiceRequest
): R4.IPractitioner | undefined {
  if (healthcareService.contained && healthcareService.contained.length > 0) {
    const index = healthcareService.contained.findIndex(
      (e) => e.id === 'practitioner'
    )

    if (index > -1) {
      return healthcareService.contained[index] as R4.IPractitioner
    }
  }

  return undefined
}

function getReferencesForReferrals() {}

export function getCarePlanMedications(medications?: R4.IMedicationRequest[]) {
  if (medications && medications.length > 0) {
    const res = medications.filter((e) => {
      if (e.meta) {
        if (e.meta.source && e.meta.source.length > 0) {
          return true
        }
      }
      return false
    })

    return res
  }
  return undefined
}

export function getCarePlanInstructions(
  medications?: R4.ICommunicationRequest[]
) {
  if (medications && medications.length > 0) {
    const res = medications.filter((e) => {
      if (e.meta) {
        if (e.meta.source && e.meta.source.length > 0) {
          return true
        }
      }
      return false
    })

    return res
  }
  return undefined
}

export function createBundleObjectForLabReferral(
  appointment: FhirAppointmentDetail,

  encounter: R4.IEncounter,
  labReferral: LabReferralService[],
  addInformation?: string
): R4.IBundle {
  const role = getCurrentUserPractitionerRoleDetails()
  const practitioner = getCurrentUserPractitionerDetails()

  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }
  const allergyDetailsFinal: R4.IServiceRequest[] = []
  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
    type: encounter.resourceType,
  }

  const hvService: R4.IHealthcareService[] = []
  for (let i = 0; i < labReferral.length; i++) {
    hvService.push(labReferral[i].healthService ?? '')
  }
  const uniqueArray = uniqBy(hvService, (obj) => obj.id)
  let providerDetails: R4.IOrganization | undefined

  for (let k = 0; k < uniqueArray.length; k++) {
    const details: any[] = []
    const planDefDetails: string[] = []
    for (let i = 0; i < labReferral.length; i++) {
      if (uniqueArray[k].id === labReferral[i].healthService.id) {
        if (labReferral[i].healthService.providedBy) {
          if (labReferral[i].healthService.providedBy!.reference) {
            const id = labReferral[i].healthService.providedBy!.reference ?? ''
            const searchId = id.split('/')[1]
            if (
              labReferral[i].healthService.contained &&
              labReferral[i].healthService.contained!.length > 0
            ) {
              const index = labReferral[i].healthService.contained?.findIndex(
                (e) => e.id === searchId
              )
              providerDetails = labReferral[i].healthService.contained![
                index!
              ] as IOrganization
            }
          }
        }
      }
      details.push({
        coding: [
          {
            system: 'http://loinc.org',
            code: getLoincCodeFromPlanDef(labReferral[i].planDefinition),
            display: labReferral[i].planDefinition.title ?? '',
          },
        ],
      })
      planDefDetails.push(labReferral[i].planDefinition.title ?? '')
    }

    const allergyDetails: R4.IServiceRequest = {
      resourceType: 'ServiceRequest',
      subject: {
        display: getNameOfPatient(appointment.patient),
        reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
        id: appointment.patient.id,
        type: appointment.patient.resourceType,
      },
      contained: providerDetails === undefined ? [] : [providerDetails],
      asNeededBoolean: true,
      category: uniqueArray[k].category?.map((e) => ({
        coding: e.coding?.map((c) => ({
          ...c,
          display: 'Laboratory procedure',
        })),
      })),
      code: uniqueArray[k].type?.[0],
      performer:
        providerDetails !== undefined
          ? [
              {
                reference: `Organization/${providerDetails.id}`,
                display: providerDetails.name,
                type: 'Organization',
              },
            ]
          : [],
      intent: 'proposal',
      status: 'active',
      encounter: encounterRef,
      orderDetail: details,
      authoredOn: new Date().toISOString(),
      requester: {
        display: getNameFromHumanName(
          appointment.practitionerDetail.practitioner.name ?? []
        ),
        id: appointment.practitionerDetail.practitionerRole.id,
        reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
        type: appointment.practitionerDetail.practitionerRole.resourceType,
      },
      patientInstruction: planDefDetails.join(', '),
    }
    allergyDetailsFinal.push(allergyDetails)
  }

  const bundleEntries: R4.IBundle_Entry[] = allergyDetailsFinal.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)

  return requestBundle
}

export function getAmountForReferralFee(
  test: R4.IChargeItemDefinition_PropertyGroup[]
): number {
  let discountAmt: number = 0
  test.forEach((e) => {
    if (e.priceComponent && e.priceComponent.length > 0) {
      const res = e.priceComponent?.findIndex((l) => l.type === 'base') ?? -1
      if (res >= 0) {
        const priceComp: R4.IChargeItemDefinition_PriceComponent =
          e.priceComponent[res]
        const amt: R4.IMoney = priceComp.amount!
        discountAmt += amt.value ?? 0
      }
    }
  })
  return discountAmt
}

export function getAmountForReferralFeeInPercent(
  test: R4.IChargeItemDefinition_PropertyGroup[]
): number {
  let discountAmt: number = 0
  test.forEach((e) => {
    if (e.priceComponent && e.priceComponent.length > 0) {
      const component = e.priceComponent ?? []
      if (component.length > 0) {
        for (let i = 0; i < component.length; i++) {
          if (component[i].type && component[i].type === 'base') {
            const codingData = component[i].code
            if (codingData) {
              const codingDataArray = codingData.coding ?? []
              if (codingDataArray.length > 0) {
                const code = codingDataArray[0].code ?? ''
                if (code === '%') {
                  discountAmt += component[i].factor ?? 0 * 100
                }
              }
            }
          }
        }
      }
    }
  })
  return discountAmt
}

export function getPercentageLessThan(
  test: R4.IChargeItemDefinition_PropertyGroup[]
): number {
  let discountAmt: number = 0
  test.forEach((e) => {
    if (
      e.priceComponent &&
      e.priceComponent.length > 0 &&
      e.applicability &&
      e.applicability.length > 0
    ) {
      const component = e.priceComponent ?? []
      const applicability = e.applicability ?? []
      const data = applicability.filter(
        (d: IChargeItemDefinition_Applicability) =>
          d.description && d.description === 'less than'
      )
      if (component.length > 0 && data.length > 0) {
        for (let i = 0; i < component.length; i++) {
          if (component[i].type && component[i].type === 'base') {
            const codingData = component[i].code
            if (codingData) {
              const codingDataArray = codingData.coding ?? []
              if (codingDataArray.length > 0) {
                const code = codingDataArray[0].code ?? ''
                if (code === '%') {
                  discountAmt += component[i].factor ?? 0 * 100
                }
              }
            }
          }
        }
      }
    }
  })
  return discountAmt
}

export function getPercentageGreaterThan(
  test: R4.IChargeItemDefinition_PropertyGroup[]
): number {
  let discountAmt: number = 0
  test.forEach((e) => {
    if (
      e.priceComponent &&
      e.priceComponent.length > 0 &&
      e.applicability &&
      e.applicability.length > 0
    ) {
      const component = e.priceComponent ?? []
      const applicability = e.applicability ?? []
      const data = applicability.filter(
        (d: IChargeItemDefinition_Applicability) =>
          d.description && d.description === 'greater than'
      )
      if (component.length > 0 && data.length > 0) {
        for (let i = 0; i < component.length; i++) {
          if (component[i].type && component[i].type === 'base') {
            const codingData = component[i].code
            if (codingData) {
              const codingDataArray = codingData.coding ?? []
              if (codingDataArray.length > 0) {
                const code = codingDataArray[0].code ?? ''
                if (code === '%') {
                  discountAmt += component[i].factor ?? 0 * 100
                }
              }
            }
          }
        }
      }
    }
  })
  return discountAmt
}

export function getAmountForConvenienceFee(
  test: R4.IChargeItemDefinition_PropertyGroup[]
): number {
  let discountAmt: number = 0
  test.forEach((e) => {
    if (e.priceComponent && e.priceComponent.length > 0) {
      const component = e.priceComponent ?? []
      if (component.length > 0) {
        for (let i = 0; i < component.length; i++) {
          if (component[i].type && component[i].type === 'base') {
            const codingData = component[i].code
            if (component[i].amount) {
              discountAmt = component[i].amount!.value ?? 0
            }
            //   if (codingData) {
            //     const codingDataArray = codingData.coding ?? []
            //     if (codingDataArray.length > 0) {
            //       const code = codingDataArray[0].code ?? ''
            //       if (code === '%') {
            //         discountAmt += component[i].factor ?? 0 * 100
            //       }
            //     }
            //   }
          }
        }
      }
    }
  })
  return discountAmt
}

export function createBundleObjectForLabReferralForNoReferral(
  appointment: FhirAppointmentDetail,

  encounter: R4.IEncounter,
  planDefinition: R4.IPlanDefinition[],
  addInformation?: string
): R4.IBundle {
  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }
  const finalService: R4.IServiceRequest[] = []
  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
    type: encounter.resourceType,
  }
  let providerDetails: R4.IOrganization | undefined
  const details: any[] = []
  const planDefDetails: string[] = []
  for (let i = 0; i < planDefinition.length; i++) {
    details.push({
      coding: [
        {
          system: 'http://loinc.org',
          code: getLoincCodeFromPlanDef(planDefinition[i]),
          display: planDefinition[i].title ?? '',
        },
      ],
    })
    planDefDetails.push(planDefinition[i].title ?? '')
  }
  const allergyDetails: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },
    contained: providerDetails === undefined ? [] : [providerDetails],
    asNeededBoolean: true,
    performer: [getCurrentUserUnitReference()!],
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '108252007',
            display: 'Laboratory procedure',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '266753000',
          display: 'Laboratory Tests',
        },
      ],
    },

    intent: 'proposal',
    status: 'active',
    encounter: encounterRef,
    orderDetail: details,
    authoredOn: new Date().toISOString(),
    requester: {
      display: getNameFromHumanName(
        appointment.practitionerDetail.practitioner.name ?? []
      ),
      id: appointment.practitionerDetail.practitionerRole.id,
      reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
      type: appointment.practitionerDetail.practitionerRole.resourceType,
    },
    patientInstruction: planDefDetails.join(', '),
  }

  finalService.push(allergyDetails)

  const bundleEntries: R4.IBundle_Entry[] = finalService.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function createBundleObjectForLabReferralForIPD(
  appointment: FhirActiveIPDDetailsForMedicalRole,

  encounter: R4.IEncounter,
  labReferral: LabReferralService[],
  selectedPlans: R4.IPlanDefinition[],
  addInformation?: string
): R4.IBundle {
  const role = getCurrentUserPractitionerRoleDetails()
  const practitioner = getCurrentUserPractitionerDetails()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  for (let i = 0; i < labReferral.length; i++) {
    let providerDetails: R4.IOrganization | undefined

    if (labReferral[i].healthService.providedBy) {
      if (
        labReferral[i].healthService.providedBy!.reference &&
        labReferral[i].healthService.providedBy!.reference!.startsWith('#')
      ) {
        const id = labReferral[i].healthService.providedBy!.reference ?? ''
        const searchId = id.split('#')[1]
        if (
          labReferral[i].healthService.contained &&
          labReferral[i].healthService.contained!.length > 0
        ) {
          const index = labReferral[i].healthService.contained!.findIndex(
            (e) => e.id === searchId
          )
          providerDetails = labReferral[i].healthService.contained![
            index
          ] as IOrganization
        }
      }
    }

    const serviceData: R4.IServiceRequest = {
      resourceType: 'ServiceRequest',
      subject: {
        display: getNameOfPatient(appointment.patient),
        reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
        id: appointment.patient.id,
        type: appointment.patient.resourceType,
      },
      contained: providerDetails === undefined ? [] : [providerDetails],
      asNeededBoolean: true,
      category: labReferral[i].healthService.category?.map((e) => ({
        coding: e.coding?.map((c) => ({
          ...c,
          display: 'Laboratory procedure',
        })),
      })),
      code: labReferral[i].healthService.type?.[0],
      performer:
        providerDetails !== undefined
          ? [
              {
                reference: `#${providerDetails.id}`,
                display: providerDetails.name,
                type: 'Organization',
              },
            ]
          : [],
      intent: 'proposal',
      status: 'active',
      encounter: {
        reference: `${encounter.resourceType}/${encounter.id ?? ''}`,
        type: encounter.resourceType,
      },
      orderDetail: [
        {
          coding: [
            {
              system: 'http://loinc.org',
              code: getLoincCodeFromPlanDef(labReferral[i].planDefinition),
              display: labReferral[i].planDefinition.title ?? '',
            },
          ],
        },
      ],
      authoredOn: new Date().toISOString(),
      requester: {
        display: getNameFromHumanName(practitioner.name ?? []),
        id: role.id,
        reference: `${role.resourceType}/${role.id}`,
        type: role.resourceType,
      },
      patientInstruction: labReferral[i].planDefinition.title ?? '',
    }
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: serviceData.resourceType,
      },
      resource: serviceData,
    }
    requestBundle.entry?.push(newBundleEntry)
  }
  const filteredPlans: R4.IPlanDefinition[] = []
  //   if(labReferral.length > 0 )
  //   {
  //     for(let i=0;i<labReferral.length;i++)
  //     {
  //      const   labReferral.filter(
  //           (org: R4.ICoding) => org.code === '1259939000'
  //         )
  //     }
  //   }

  console.log(requestBundle)
  return requestBundle
}

export function createBundleObjectForLabReferralForNoReferralForIPD(
  appointment: FhirActiveIPDDetailsForMedicalRole,

  encounter: R4.IEncounter,
  planDefinition: R4.IPlanDefinition[],
  addInformation?: string
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const role = getCurrentUserPractitionerRoleDetails()
  const practitioner = getCurrentUserPractitionerDetails()
  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/${encounter.id ?? ''}`,
    type: encounter.resourceType,
  }
  const finalService: R4.IServiceRequest[] = []
  let providerDetails: R4.IOrganization | undefined
  for (let i = 0; i < planDefinition.length; i++) {
    const allergyDetails: R4.IServiceRequest = {
      resourceType: 'ServiceRequest',
      subject: {
        display: getNameOfPatient(appointment.patient),
        reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
        id: appointment.patient.id,
        type: appointment.patient.resourceType,
      },
      contained: providerDetails === undefined ? [] : [providerDetails],
      asNeededBoolean: true,
      performer: [getCurrentUserUnitReference()!],
      category: [
        {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '108252007',
              display: 'Laboratory procedure',
            },
          ],
        },
      ],
      code: {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '266753000',
            display: 'Laboratory Tests',
          },
        ],
      },

      intent: 'proposal',
      status: 'active',
      encounter: encounterRef,
      orderDetail: [
        {
          coding: [
            {
              system: 'http://loinc.org',
              code: getLoincCodeFromPlanDef(planDefinition[i]),
              display: planDefinition[i].title ?? '',
            },
          ],
        },
      ],
      authoredOn: new Date().toISOString(),
      requester: {
        display: getNameFromHumanName(practitioner.name ?? []),
        id: role.id,
        reference: `${role.resourceType}/${role.id}`,
        type: role.resourceType,
      },
      patientInstruction: planDefinition[i].title ?? '',
    }

    finalService.push(allergyDetails)
  }

  const bundleEntries: R4.IBundle_Entry[] = finalService.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function createBundleObjectForWellnessReferralIPD(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  encounter: R4.IEncounter,
  medications: R4.IHealthcareService,
  addInformation?: string
): R4.IBundle {
  const role = getCurrentUserPractitionerRoleDetails()
  const practitioner = getCurrentUserPractitionerDetails()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/${encounter.id!}`,
    type: encounter.resourceType,
  }
  let providerDetails: R4.IOrganization | undefined

  if (medications.providedBy) {
    if (
      medications.providedBy.reference &&
      medications.providedBy.reference.startsWith('#')
    ) {
      const id = medications.providedBy.reference ?? ''
      const searchId = id.split('#')[1]
      if (medications.contained && medications.contained.length > 0) {
        const index = medications.contained?.findIndex((e) => e.id === searchId)
        providerDetails = medications.contained[index] as IOrganization
      }
    }
  }
  const allergyDetails: R4.IServiceRequest[] = [
    {
      resourceType: 'ServiceRequest',
      subject: {
        display: getNameOfPatient(appointment.patient),
        reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
        id: appointment.patient.id,
        type: appointment.patient.resourceType,
      },
      contained: providerDetails === undefined ? [] : [providerDetails],
      asNeededBoolean: true,
      category: medications.category?.map((e) => ({
        coding: e.coding?.map((c) => ({
          ...c,
          display: medications.name,
        })),
      })),
      code: medications.type?.[0],
      performer:
        providerDetails !== undefined
          ? [
              {
                reference: `#${providerDetails.id}`,
                display: providerDetails.name,
                type: 'Organization',
              },
            ]
          : [],
      intent: 'proposal',
      status: 'active',
      encounter: encounterRef,
      authoredOn: new Date().toISOString(),
      requester: {
        display: getNameFromHumanName(practitioner.name ?? []),
        id: role.id,
        reference: `${role.resourceType}/${role.id}`,
        type: role.resourceType,
      },
      patientInstruction: addInformation,
    },
  ]

  const bundleEntries: R4.IBundle_Entry[] = allergyDetails.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

/// get care team from server of care plan id with practitioner details
export async function getCareTeamDetails(
  carePlanId: string
): Promise<CareTeamMember[] | undefined> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParams = {
    _id: carePlanId,
  }
  const response: any = await fhirClient.doGetResource(
    '/CarePlan?_include:iterate=CareTeam:participant&_include:iterate=PractitionerRole:practitioner&_include:iterate=PractitionerRole:organization&_include:iterate=Organization:partof&_include:iterate=CarePlan:care-team',
    searchParams
  )
  if (response?.total && response?.total > 0) {
    const practitionerRoles: R4.IPractitionerRole[] =
      getPractitionerRoleListFromBundle(response)
    const availablePractitionersList: CareTeamMember[] = []

    const careTeams: R4.ICareTeam[] = getCareTeamsFromBundle(response)

    practitionerRoles.forEach((practitionerRole) => {
      const practId =
        practitionerRole.practitioner?.reference?.split('/')[1] ?? ''
      const practitioner: R4.IPractitioner | undefined =
        getPractitionerFromBundleFromId(response, practId)

      availablePractitionersList.push({
        roleId: practitionerRole.id!,
        practId,
        practitioner: practitioner!,
        practitionerRole,
        roleInCareTeam:
          getCareTeamRolesWithPractitionerRole(careTeams, practitionerRole) ??
          [],
      })
    })

    console.log(
      '--------------- availablePractitionersList ---------------',
      availablePractitionersList
    )
    return availablePractitionersList
  }
  return undefined
}

/// get practitioner of Id  from from bundle

export function getPractitionerFromBundleFromId(
  bundle: R4.IBundle,
  practitionerId: string
): R4.IPractitioner | undefined {
  if (bundle.entry && bundle.entry.length > 0) {
    const index: number = bundle.entry.findIndex(
      (e) =>
        e.resource?.resourceType === 'Practitioner' &&
        e.resource?.id === practitionerId
    )
    if (index !== undefined && index > -1)
      return bundle.entry[index].resource as R4.IPractitioner
  }
  return undefined
}

/// get practitioner of Id  from from bundle

export function getPractitionerRoleListFromBundle(
  bundle: R4.IBundle
): R4.IPractitionerRole[] {
  const currentPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const practitionerRoles: R4.IPractitionerRole[] = []
  bundle.entry?.forEach((entry) => {
    if (entry.resource?.resourceType === 'PractitionerRole') {
      if (entry.resource.id !== currentPractitionerRole.id) {
        practitionerRoles.push(entry.resource as R4.IPractitionerRole)
      }
    }
  })

  return practitionerRoles
}

/// get practitionerRole's care team role from care teams

export function getPractitionerRoleCareTeamRole(
  careTeam: R4.ICareTeam,
  practitionerRole: R4.IPractitionerRole
): R4.ICodeableConcept[] | undefined {
  if (careTeam.participant && careTeam.participant.length > 0) {
    const index = careTeam.participant.findIndex(
      (e) => e.member?.reference === `PractitionerRole/${practitionerRole.id}`
    )
    if (index > -1) {
      return careTeam.participant[index].role
    }
  }
  return undefined
}

/// get Care teams form bundle

export function getCareTeamsFromBundle(bundle: R4.IBundle): R4.ICareTeam[] {
  const careTeams: R4.ICareTeam[] = []
  bundle.entry?.forEach((entry) => {
    if (entry.resource?.resourceType === 'CareTeam') {
      careTeams.push(entry.resource as R4.ICareTeam)
    }
  })

  return careTeams
}

/// care teams roles with practitioner role

export function getCareTeamRolesWithPractitionerRole(
  careTeams: R4.ICareTeam[],
  practitionerRole: R4.IPractitionerRole
): R4.ICodeableConcept[] | undefined {
  const roles: R4.ICodeableConcept[] = []

  careTeams.forEach((careTeam) => {
    const role = getPractitionerRoleCareTeamRole(careTeam, practitionerRole)
    if (role) {
      roles.push(...role)
    }
  })
  return roles
}

export function getServiceTypeData(test: R4.ICoding): R4.ICoding | undefined {
  if (test.display === 'Online Appointment') {
    return {
      system: '',
      code: 'online',
      display: 'Online Appointment',
    }
  }
  if (test.display === 'Video Appointment') {
    return {
      system: 'e',
      code: 'video',
      display: 'Video Appointment',
    }
  }
  if (test.display === 'Order') {
    return {
      system: '',
      code: 'lab',
      display: 'Order',
    }
  }
  return undefined
}

export function getSettlement(basicData: R4.IBasic): string {
  if (basicData.extension) {
    const data =
      getStringValueExtensionsOfUrl(
        basicData.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-business-settlement-ext'
      ) ?? ''
    return data.trim()
  }
  return ''
}
