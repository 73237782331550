import { Box, CircularProgress, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloMedication } from 'models/welloMedication'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { RootState } from 'redux/rootReducer'
import { EditAyurvedaMedicationsHandlerIPD } from 'views/components/ipdconusltation/assessment/editMedicationList'
import { EditMedicationHandlerAllopathy } from '../edition/editMedicationAllopathy'
import { EditMedicationAyurvedaOPD } from '../edition/editMedicationAyurveda'
import { MedicationRequestTile } from './medicationRequestTile'
import { AddedMedicationsList } from './medicationsListTable'

interface MedicationsListProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
  followUp?: boolean
}

export const MedicationsList: React.FC<MedicationsListProps> = ({
  fhirAppointmentDetails,
  split,
  followUp,
}: MedicationsListProps) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const medicationsListSlice = useSelector(
    (state: RootState) => state.medicationsListSlice
  )

  const [existingMedication, setExistingMedication] =
    useState<WelloMedication>()
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getMedicationsOfAppointment(fhirAppointmentDetails, followUp))
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(medicationsListSlice.updatingImpressions ||
        medicationsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}

      {/* {medicationsListSlice.noResultsAvailable && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            No data available
          </Typography>
        </Box>
      )} */}
      {medicationsListSlice.medications &&
        medicationsListSlice.medications.length > 0 && (
          <Box display='flex' flexDirection='column' width='100%'>
            <AddedMedicationsList
              medication={medicationsListSlice.medications}
              patientDetails={fhirAppointmentDetails.patient}
              appointmentId={fhirAppointmentDetails.appointment.id ?? ''}
              key='1'
              displayTypeOfMedication={false}
              displayStartEndDates={true}
              orderAllowed={split !== true}
              history={false}
              split={false}
              onEditCLicked={(data: WelloMedication) => {
                setExistingMedication(data)
              }}
            />
          </Box>
        )}
      {existingMedication &&
        existingMedication.type &&
        existingMedication.type === 'allopathy' && (
          <EditMedicationHandlerAllopathy
            open={existingMedication !== undefined}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onClose={() => {
              dispatch(
                getMedicationsOfAppointment(fhirAppointmentDetails, followUp)
              )
              setExistingMedication(undefined)
            }}
            onMedicalConditionAdded={() => {
              dispatch(
                getMedicationsOfAppointment(fhirAppointmentDetails, followUp)
              )
              setExistingMedication(undefined)
            }}
            preselectedMedication={existingMedication!}
          />
        )}

      {existingMedication &&
        existingMedication.type &&
        existingMedication.type === 'ayurveda' && (
          <EditMedicationAyurvedaOPD
            open={existingMedication !== undefined}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onClose={() => {
              dispatch(
                getMedicationsOfAppointment(fhirAppointmentDetails, followUp)
              )
              setExistingMedication(undefined)
            }}
            onMedicalConditionAdded={() => {
              dispatch(
                getMedicationsOfAppointment(fhirAppointmentDetails, followUp)
              )
              setExistingMedication(undefined)
            }}
            preselectedMedication={existingMedication!}
          />
        )}
    </Box>
  )
}
