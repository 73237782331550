/* eslint-disable react/display-name */
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  makeStyles,
  Paper,
  TablePagination,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getRolesAsStringFromPractRole } from 'services/userDetailsService'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { AddPartnerLabHandler } from 'views/components/administration/actors/add_partner_lab'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      // padding: 4,
    },
  },
})

interface ICardProperties {
  usersData: PractitionerWithRole[]
  onUserSelected: (selectedDetail: PractitionerWithRole) => void
}

export const UsersDataTable: React.FC<ICardProperties> = ({
  usersData,
  onUserSelected,
}: ICardProperties) => {
  const classes = useStyles()
  const convertedData = usersData
  const { height, width } = useWindowDimensions()
  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const { t } = useTranslation()

  const handleDarkModeChange = () => {
    // setPreferDarkMode(!preferDarkMode)
    // localStorage.setItem('_tableDarkMode', !preferDarkMode)
  }

  const columns = [
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography variant='subtitle1' color='primary'>
            {t('labelCommon:userName')}
          </Typography>
        </Box>
      ),
      field: 'fullName',
      customSort: (a: any, b: any) =>
        a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            {rowData.fullName}
          </Typography>
        </Box>
      ),
      width: 250,
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            {t('labelCommon:Primary_Contact')}
          </Typography>
        </Box>
      ),
      field: 'primaryContact',
      //   cellStyle: {
      //     width: 250,
      //     maxWidth: 250,
      //   },
      render: (rowData: any) => (
        <Box>
          {rowData.primaryContact !== undefined && (
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              {rowData.primaryContact}
            </Typography>
          )}
        </Box>
      ),
      width: 250,
    },

    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            {t('labelCommon:phone_number_label')}
          </Typography>
        </Box>
      ),
      field: 'phone',

      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {rowData.phone}
          </Typography>
        </Box>
      ),
      width: 250,
    },

    {
      title: (
        <Box minHeight='15px' maxHeight='15px'>
          <Typography variant='subtitle1' color='primary' noWrap>
            &nbsp;{t('labelCommon:Status')}
          </Typography>
        </Box>
      ),
      field: 'status',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              {rowData.status}
            </Typography>
          </Box>
        </Box>
      ),
      width: 100,
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            Role
          </Typography>
        </Box>
      ),
      field: 'role',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {getRolesAsStringFromPractRole(rowData.roleObject)}
          </Typography>
        </Box>
      ),
      width: 100,
    },
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography variant='subtitle1' color='primary'>
            Enabled
          </Typography>
        </Box>
      ),
      field: 'enabled',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              {rowData.enabled === true ? 'Yes' : 'No'}
            </Typography>
          </Box>
        </Box>
      ),
      width: 100,
    },
  ]

  return (
    <Box
      style={{ overflow: 'none', width: `${width - 224}px` }}
      paddingLeft={1.5}
    >
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                // padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '5px',
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        // actions={[
        //     {
        //       icon: () => <Button
        //       variant='contained'
        //       color='primary'
        //       onClick={() => {
        //         setOpenPartnerLabPopup(true)
        //       }}
        //     >
        //       {t('add_partner_lab')}
        //     </Button>,
        //      position: "toolbar",
        //       tooltip: "Toggle light/dark mode",
        //       onClick: handleDarkModeChange,
        //       isFreeAction: true
        //     }
        //   ]}
        columns={columns}
        data={convertedData}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          if (rowData) onUserSelected(rowData)
          setSelectedRow(true)
          setSelectedRowId(rowData?.id ?? '')
        }}
        options={{
          searchFieldStyle: { padding: 4 },
          toolbar: false,
          search: false,
          showTitle: false,

          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          maxBodyHeight: `${height - 169}px`,
          minBodyHeight: `${height - 240}px`,
          tableLayout: 'auto',
          sorting: true,
          thirdSortClick: false,
          headerStyle: {
            backgroundColor: useTheme().palette.background.default,
            color: '#FFF',
            width: 100,
            minHeight: '15px',
            maxHeight: '15px',
            position: 'sticky',
            top: 0,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.id === selectedRowId
                ? { backgroundColor: '#9E9DDC', height: 50 }
                : { backgroundColor: kDialogueBackground }
              : selectedRow && _data.id === selectedRowId
              ? { backgroundColor: '#9E9DDC', height: 50 }
              : {},
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
      {openPartnerLabPopup === true && (
        <AddPartnerLabHandler
          open={openPartnerLabPopup}
          onContinueClick={() => {
            setOpenPartnerLabPopup(false)
          }}
          onBackClick={() => {
            setOpenPartnerLabPopup(false)
          }}
        />
      )}
    </Box>
  )
}
