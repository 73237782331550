import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Step,
  StepButton,
  Stepper,
  useTheme,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import SimpleBar from 'simplebar-react'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { getPrescriptionDetails } from 'redux/prescription/medicationsListSlice/prescriptionSearchSlice'
import { RootState } from 'redux/rootReducer'
import { isAyurvedaDoctor } from 'services/userDetailsService'

import { getReferralInsights } from 'redux/patientMedicalHistory/referralHistory/referralHistorySearchSlice'
import { AyurvedaPrescriptionData } from './AyurvddeaPrescription'
import { PrescriptionData } from './prescriptionData'
import { ReferralHistorySubjective } from '../referralHistory.tsx/referralSubjectiveConsultation'
import { ReferralObjectivePartOfConsultation } from '../referralHistory.tsx/referralObjectiveConsultation'
import { ReferralAssessmentHistory } from '../referralHistory.tsx/referralAssesment'
import { ReferralTile } from '../referralHistory.tsx/referralTile'
import { PlanHistory } from '../referralHistory.tsx/historyPlan'

interface Props {
  open: boolean
  appointmentDetails: FhirAppointmentDetail
  onClose: () => void
}

const steps = ['Subjective', 'Objective', 'Assessment', 'Plan']

export const ReferralHistory: React.FC<Props> = ({
  open,
  appointmentDetails,
  onClose,
}) => {
  const dispatch = useDispatch()
  const referralHistorySlice = useSelector(
    (state: RootState) => state.referralHistorySlice
  )
  const [activeStep, setActiveStep] = React.useState<number>(0)
  const [lastStepData, setLastStepData] = React.useState<number>(0)
  const [completed, setCompleted] = React.useState([])
  const paperColor = useTheme().palette.background.paper

  const { t } = useTranslation(['common'])

  const totalSteps = () => steps.length

  const completedSteps = () => Object.keys(completed).length

  const isLastStep = () => activeStep === totalSteps() - 1

  const allStepsCompleted = () => completedSteps() === totalSteps()

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1

    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: any) => () => {
    setLastStepData(activeStep)
    setActiveStep(step)
  }

  const handleComplete = () => {
    const newCompleted = completed
    // newCompleted[activeStep] = true
    // setCompleted(newCompleted)
    handleNext()
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted([])
  }

  useEffect(() => {
    setActiveStep(0)
    if (open) {
      dispatch(getReferralInsights(appointmentDetails))
    }
  }, [appointmentDetails, dispatch, open])

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby='responsive-dialog-title'
      maxWidth='lg'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Referral Insights</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
          backgroundColor: '#EDF2FA',
          padding: 0,
        }}
      >
        {referralHistorySlice.fetchingImpressions && (
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            alignContent='center'
            alignItems='center'
            justifyContent='center'
            justifyItems='center'
          >
            <CircularProgress size={28} />
          </Box>
        )}
        {referralHistorySlice.referralDetails && (
          <Box
            display='flex'
            flexDirection='column'
            height='76vh'
            width='100%'
            style={{ backgroundColor: '#EDF2FA' }}
          >
            <ReferralTile
              referralData={
                referralHistorySlice.referralDetails.referralDetails
              }
              doctor={referralHistorySlice.referralDetails.practDetails}
              unit={referralHistorySlice.referralDetails.unitDetails}
            />
            <Box width='100%' />

            <Box
              sx={{ width: '100%' }}
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='row'
              bgcolor={kPrimaryMain}
            >
              <Box
                sx={{
                  width: '100%',
                }}
                bgcolor={kPrimaryMain}
                px={3}
                paddingTop={1.5}
              >
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  style={{
                    borderRadius: 3,
                    backgroundColor: kPrimaryMain,
                    height: 25,
                  }}
                >
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton
                        color='red'
                        onClick={handleStep(index)}
                        id={`${label}${index}`}
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            textTransform: 'uppercase',
                            color: paperColor,
                          }}
                        >
                          {t('labelCommon:' + `${label}`)}
                          {/* {t('labelCommon:view_profile')} */}
                        </Typography>
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Box>

            <Box
              display='flex'
              overflow='auto'
              flexDirection='row'
              // borderRadius={12}
              py={1.5}
              width='100%'
            >
              <Box
                flexDirection='column'
                overflow='auto'
                width='100%'
                id='rootEL'
                borderRadius={12}
                alignItems='center'
                justifyContent='center'
                //   paddingLeft={activeStep === 4 ? 24 : 0}
                //   paddingRight={activeStep === 4 ? 24 : 0}
              >
                {' '}
                <SimpleBar
                  autoHide={false}
                  style={{
                    height: '100%',
                    overflowX: 'hidden',
                    padding: '4px',
                  }}
                >
                  <Box
                    flexDirection='column'
                    width={activeStep === 4 ? '100%' : '100%'}
                    bgcolor={paperColor}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      py={2}
                      px={3}
                      style={{ backgroundColor: '#EDF2FA' }}
                    >
                      {activeStep === 0 && (
                        <ReferralHistorySubjective
                          historyDetails={referralHistorySlice.referralDetails}
                          split={false}
                        />
                      )}
                      {activeStep === 1 && (
                        <ReferralObjectivePartOfConsultation
                          referralDetails={referralHistorySlice.referralDetails}
                          patientData={appointmentDetails.patient}
                        />
                      )}

                      {activeStep === 2 && (
                        <ReferralAssessmentHistory
                          referralDetails={referralHistorySlice.referralDetails}
                        />
                      )}

                      {activeStep === 3 && (
                        <Box display='flex' flexDirection='row' width='90%'>
                          <PlanHistory
                            referralDetails={
                              referralHistorySlice.referralDetails
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </SimpleBar>
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            onClose()
          }}
          disabled={referralHistorySlice.fetchingImpressions}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
