import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors, NullType } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloSignExam } from 'models/WelloConditions'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestGeneralExaminationOfPatient } from 'redux/consultation/generalExamination/generalExaminationSlice'
import { requestForPhysicalExam } from 'redux/consultation/physicalExamSearch/physicalExamSearchSlice'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { fToC } from 'utils/appointment_handle/vitals_util'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getVitalsObservationObject } from 'utils/fhirResoureHelpers/observationHelpers'
import { PhysicalExamAddStatus } from './addPhysicalExamStatus'

const initialState: PhysicalExamAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addPhysicalExaminationSlice = createSlice({
  name: 'addPhysicalExaminationSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<PhysicalExamAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<PhysicalExamAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addPhysicalExam =
  (appointment: FhirAppointmentDetail, findings: WelloSignExam[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: PhysicalExamAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addPhysicalExaminationSlice.actions.updateAddVitalsStatus(addingState)
    )

    try {
      const bundleObject: R4.IBundle =
        createBundleObjectForObservationsPhysicalExam(appointment, findings)

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          addingState = {
            adding: false,
            additionSuccessful: true,
            error: false,
            errorMessage: '',
          }
          dispatch(
            requestForPhysicalExam(
              appointment.patient,
              appointment.appointment.id!
            )
          )

          dispatch(showSuccessAlert('Signs on Examination Added Successfully'))
          dispatch(
            addPhysicalExaminationSlice.actions.updateAddVitalsStatus(
              addingState
            )
          )
        }
      } else {
        const errorCreatePersonState: PhysicalExamAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating Signs on Examination',
        }
        dispatch(
          addPhysicalExaminationSlice.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: PhysicalExamAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding Signs on Examination',
      }
      dispatch(
        addPhysicalExaminationSlice.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetPhysicalExamState = () => (dispatch: AppDispatch) => {
  dispatch(
    addPhysicalExaminationSlice.actions.resetAddVitalsDetails(initialState)
  )
}

export function createBundleObjectForObservationsPhysicalExam(
  appointment: FhirAppointmentDetail,
  findings: WelloSignExam[]
): R4.IBundle {
  const encounter: R4.IEncounter = getEncounterObjectForAppointment(appointment)
  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }
  const practitionerRoleDetail: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()

  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
    type: encounter.resourceType,
  }

  for (let i = 0; i < findings.length; i++) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    if (findings[i].singExam) {
      observationObject.code = findings[i].singExam!
    }
    const extArray: R4.IExtension[] = []
    if (findings[i].site) {
      const siteExt: R4.IExtension = {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-site-of-examination-ext',
        valueCodeableConcept: {
          coding: [findings[i].site!],
        },
      }
      extArray.push(siteExt)
    }
    if (findings[i].type) {
      const siteExt: R4.IExtension = {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-examination-type-ext',
        valueCodeableConcept: {
          coding: [findings[i].type!],
        },
      }
      extArray.push(siteExt)
    }
    if (extArray.length > 0) {
      observationObject.extension = extArray
    }

    observationObject.status = R4.ObservationStatusKind._final

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default addPhysicalExaminationSlice.reducer
