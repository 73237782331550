import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { FhirPrescriptionDetails } from 'models/fhirPrescriptionDetails'
import { ObsServiceDataWithVal } from 'models/obsServiceData'
import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  getCurrentUserUnitDetails,
  isAyurvedaDoctor,
} from 'services/userDetailsService'
import {
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { getOrderCollectionTime, getOrderSampleReceived } from 'utils/dateUtil'
import {
  getAddress,
  getAgeOfPatientData,
  getCodingValForDoc,
  getDifferenceBetweenDates,
  getGenderOfPatient,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameFromHumanName,
  getNameOfPatient,
  getRegistrationNumber,
  getSpecializationsList,
  getTelecomFromContactPoints,
  getTelecomOfPatient,
  getValueAttachmentFromExtension,
  getValueCodingFromExtension,
} from 'utils/fhirResourcesHelper'
import { getDateForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getErrorStatus,
  reportsAvailable,
  reportsAvailableForReportPartial,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { LogoViewer } from 'views/components/common/logoViewer'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'

interface Props {
  labOrderDetails: FhirLabOrderDetail
  refData?: any
  resultsData: ObsServiceDataWithVal[]
}
export const PrintResults: React.FC<Props> = ({
  refData,
  labOrderDetails,
  resultsData,
}) => {
  const dispatch = useDispatch()
  console.log(labOrderDetails)
  console.log(resultsData)

  function getMinError(
    valData: R4.IObservationDefinition,
    patient: R4.IPatient
  ) {
    let lowVal: number | undefined = 0

    if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
      for (let i = 0; i < valData.qualifiedInterval.length; i++) {
        if (valData.qualifiedInterval[i].gender) {
          if (
            valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
          ) {
            lowVal = valData.qualifiedInterval[i].range
              ? valData.qualifiedInterval[i].range?.low?.value
              : undefined
          }
        } else {
          lowVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.low?.value
            : 0
        }
      }
    }

    return lowVal
  }

  function getHighError(
    valData: R4.IObservationDefinition,
    patient: R4.IPatient
  ) {
    let highVal: number | undefined
    if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
      for (let i = 0; i < valData.qualifiedInterval.length; i++) {
        if (valData.qualifiedInterval[i].gender) {
          if (
            valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
          ) {
            highVal = valData.qualifiedInterval[i].range
              ? valData.qualifiedInterval[i].range?.high?.value
              : 0
          }
        } else {
          highVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.high?.value
            : 0
        }
      }
    }

    return highVal
  }

  return (
    <Box
      {...{ ref: refData }}
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      //   style={{ overflow: 'scroll' }}
    >
      <header>
        {' '}
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' width='100%'>
            <Box
              display='flex'
              flexDirection='row'
              width='50%'
              justifyContent='flex-start'
            >
              {getValueAttachmentFromExtension(
                getCurrentUserUnitDetails().extension,
                'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'
              ) === '1' && (
                <Box
                  display='flex'
                  width='30%'
                  flexDirection='row'
                  justifyContent='flex-start'
                  paddingLeft={2}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                  >
                    <Box
                      display='flex'
                      width='100%'
                      flexDirection='row'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      height='50%'
                    >
                      <LogoViewer
                        contentType='image/png'
                        decodingRequired={true}
                        imagePath="Organization.extension('http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo').value.as(Attachment)"
                        resourceId={getCurrentUserUnitDetails().id ?? ''}
                        resourceType='Organization'
                        onSelectionChanged={(value: SelectedFile) => {}}
                        onDataFetched={(value: string) => {}}
                        update={false}
                        purpose='tile3'
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              width='50%'
              paddingRight={10}
              py={2}
              justifyContent='flex-end'
            >
              <Box
                display='flex'
                flexDirection='row'
                width='30%'
                paddingLeft={1}
                justifyContent='center'
              >
                <Box display='flex' flexDirection='column' width='100%'>
                  <Typography variant='subtitle1' color='initial'>
                    {getAddress(getCurrentUserUnitDetails().address ?? []) ??
                      ''}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color='initial'
                    style={{
                      textTransform: 'capitalize',
                      fontFamily: 'Open Sans',

                      lineHeight: 'normal',
                      fontWeight: 600,
                    }}
                  >
                    {getCurrentUserUnitDetails().contact
                      ? getTelecomFromContactPoints(
                          getCurrentUserUnitDetails().contact![0].telecom ?? [],
                          ContactPointSystemKind._email
                        )
                      : ''}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color='initial'
                    style={{
                      textTransform: 'capitalize',
                      fontFamily: 'Open Sans',

                      lineHeight: 'normal',
                      fontWeight: 600,
                    }}
                  >
                    {getCurrentUserUnitDetails().contact
                      ? getTelecomFromContactPoints(
                          getCurrentUserUnitDetails().contact![0].telecom ?? [],
                          ContactPointSystemKind._phone
                        )
                      : ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* <Box
            display='flex'
            flexDirection='row'
            width='70%'
            py={2}
            justifyContent='flex-start'
          >
            <Typography
              variant='h5'
              style={{ color: 'black', fontWeight: 'bold' }}
            >
              {getCurrentUserUnitDetails().name ?? ''}
            </Typography>
          </Box> */}
          </Box>
          <Box display='flex' flexDirection='column' paddingTop={1.5} px={2}>
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='flex-start'
              border={4}
              style={{
                boxShadow: '0px 0px 4px #ccc',
                margin: '2px 0px',
                borderRadius: '1px',
                borderColor: '#F8F8F8',
                width: '100%',
                height: 'auto',
                backgroundColor: '#F8F8F8',
              }}
            >
              <Grid container direction='column' spacing={1}>
                <Grid container direction='row' id='vitals'>
                  <Grid item xs={2}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Patient Name
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Box display='flex' flexDirection='row'>
                            {getMiddleNameOfPatient(labOrderDetails.patient)
                              .length > 0 && (
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={{
                                  textTransform: 'capitalize',
                                  //   color: 'black',
                                  //   fontWeight: 'bold',
                                }}
                              >
                                {getNameOfPatient(labOrderDetails.patient)}{' '}
                                {getMiddleNameOfPatient(
                                  labOrderDetails.patient
                                ) ?? ''}{' '}
                                {getLastNameOfPatient(labOrderDetails.patient)}
                              </Typography>
                            )}

                            {getMiddleNameOfPatient(labOrderDetails.patient)
                              .length === 0 && (
                              <Typography
                                variant='subtitle2'
                                style={{
                                  textTransform: 'capitalize',
                                  //   color: 'black',
                                  //   fontWeight: 'bold',
                                }}
                              >
                                {getNameOfPatient(labOrderDetails.patient)}{' '}
                                {getLastNameOfPatient(labOrderDetails.patient)}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Collection Time
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Box display='flex' flexDirection='row'>
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                // color: 'black',
                                // fontWeight: 'bold',
                              }}
                            >
                              {getOrderCollectionTime(
                                labOrderDetails.statuses ?? []
                              )}{' '}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container direction='row' id='vitals'>
                  <Grid item xs={12}>
                    <Box width='100%'>
                      <Divider
                        orientation='horizontal'
                        flexItem
                        style={{
                          height: '2px',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container direction='row' id='vitals'>
                  <Grid item xs={2}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Age/Sex
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Box display='flex' flexDirection='row'>
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                // color: 'black',
                                // fontWeight: 'bold',
                              }}
                            >
                              {getAgeOfPatientData(labOrderDetails.patient)}
                            </Typography>
                            <Box px={0.3}>
                              <Typography variant='subtitle2' color='initial'>
                                /
                              </Typography>
                            </Box>
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                // color: 'black',
                                // fontWeight: 'bold',
                              }}
                            >
                              {labOrderDetails.patient.gender ===
                              R4.PatientGenderKind._male
                                ? 'Male'
                                : 'Female'}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Receiving Time
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Box display='flex' flexDirection='row'>
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                // color: 'black',
                                // fontWeight: 'bold',
                              }}
                            >
                              {getOrderSampleReceived(
                                labOrderDetails.statuses ?? []
                              )}{' '}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction='row' id='vitals'>
                  <Grid item xs={12}>
                    <Box width='100%'>
                      <Divider
                        orientation='horizontal'
                        flexItem
                        style={{
                          height: '2px',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container direction='row' id='vitals'>
                  <Grid item xs={2}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Phone
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Box display='flex' flexDirection='row'>
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                // color: 'black',
                                // fontWeight: 'bold',
                              }}
                            >
                              {getTelecomOfPatient(
                                labOrderDetails.patient,
                                R4.ContactPointSystemKind._phone
                              )}{' '}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Reporting Time
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Box display='flex' flexDirection='row'>
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                // color: 'black',
                                // fontWeight: 'bold',
                              }}
                            >
                              {moment().format('MMM DD, YYYY, h:mm:ss A')}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </header>

      <main>
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          marginBottom={10}
          marginTop={5}
          px={1}
        >
          <Box display='flex' flexDirection='column' width='100%' py={1} px={1}>
            {resultsData && (
              <Box display='flex' flexDirection='column'>
                {resultsData.map((result, index) => (
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    key={result.name}
                  >
                    {reportsAvailable(result) && (
                      <Box width='100%' py={1}>
                        <Typography
                          variant='subtitle1'
                          style={{
                            fontFamily: 'Open Sans',

                            fontWeight: 'bolder',
                            lineHeight: 'normal',
                          }}
                        >
                          {result.name}
                        </Typography>
                      </Box>
                    )}

                    {reportsAvailable(result) && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        width='100%'
                        height={reportsAvailable(result) ? 40 : 0}
                        style={{
                          backgroundColor: reportsAvailable(result)
                            ? 'lightGrey'
                            : 'white',
                        }}
                        key={result.name}
                      >
                        <Box
                          width='100%'
                          display='flex'
                          flexDirection='row'
                          px={1}
                        >
                          <Box width='30%'>
                            <Typography
                              variant='subtitle2'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              {' '}
                              Parameter Name
                            </Typography>
                          </Box>
                          <Box
                            width='20%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              {' '}
                              Value
                            </Typography>
                          </Box>
                          <Box
                            width='15%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              {' '}
                              Unit
                            </Typography>
                          </Box>
                          <Box
                            width='15%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              {' '}
                              Normal Range
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {reportsAvailable(result) && (
                      <Box display='flex' flexDirection='column'>
                        {result.obsDef
                          .sort((a, b) =>
                            a.obsDef.preferredReportName! >
                            b.obsDef.preferredReportName!
                              ? 1
                              : b.obsDef.preferredReportName! >
                                a.obsDef.preferredReportName!
                              ? -1
                              : 0
                          )
                          .map((item, index1) => (
                            <Box
                              width='100%'
                              display='flex'
                              flexDirection='row'
                              px={1}
                              py={1}
                              key={item.obsDef.id}
                            >
                              <Box width='30%' py={1}>
                                <Typography
                                  variant='subtitle2'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,

                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                  }}
                                >
                                  {item.obsDef.preferredReportName ?? ''}
                                </Typography>
                              </Box>
                              {item.obsDef.permittedDataType &&
                                item.obsDef.permittedDataType[0] ===
                                  'Quantity' && (
                                  <Box
                                    width='20%'
                                    display='flex'
                                    flexDirection='row'
                                    justifyContent='center'
                                    py={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: item.valInt
                                          ? getErrorStatus(
                                              item.obsDef,
                                              labOrderDetails.patient,
                                              item.valInt
                                            )
                                            ? 'bolder'
                                            : 400
                                          : 400,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {item.valInt && item.valInt > 0
                                        ? item.valInt
                                        : ''}
                                    </Typography>
                                  </Box>
                                )}
                              {item.obsDef.permittedDataType &&
                                item.obsDef.permittedDataType[0] !==
                                  'Quantity' && (
                                  <Box
                                    width='20%'
                                    display='flex'
                                    flexDirection='row'
                                    justifyContent='center'
                                    py={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 400,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {item.valStringData ?? ''}
                                    </Typography>
                                  </Box>
                                )}
                              {item.obsDef.permittedDataType &&
                                item.obsDef.permittedDataType[0] ===
                                  'Quantity' && (
                                  <Box
                                    py={1}
                                    display='flex'
                                    flexDirection='row'
                                    justifyContent='center'
                                    width='15%'
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {item.obsDef.quantitativeDetails
                                        ? item.obsDef.quantitativeDetails.unit
                                          ? item.obsDef.quantitativeDetails.unit
                                              .coding![0].code!
                                          : ''
                                        : ''}
                                    </Typography>
                                  </Box>
                                )}
                              {item.obsDef.permittedDataType &&
                                item.obsDef.permittedDataType[0] ===
                                  'Quantity' && (
                                  <Box
                                    width='15%'
                                    display='flex'
                                    flexDirection='row'
                                    justifyContent='center'
                                    py={1}
                                  >
                                    {getMinError(
                                      item.obsDef,
                                      labOrderDetails.patient
                                    ) !== undefined &&
                                      getHighError(
                                        item.obsDef,
                                        labOrderDetails.patient
                                      ) === undefined && (
                                        <Typography
                                          variant='subtitle2'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,

                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {` >= ${getMinError(
                                            item.obsDef,
                                            labOrderDetails.patient
                                          )}`}
                                        </Typography>
                                      )}

                                    {getMinError(
                                      item.obsDef,
                                      labOrderDetails.patient
                                    ) === undefined &&
                                      getHighError(
                                        item.obsDef,
                                        labOrderDetails.patient
                                      ) !== undefined && (
                                        <Typography
                                          variant='subtitle2'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,

                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {` <= ${getHighError(
                                            item.obsDef,
                                            labOrderDetails.patient
                                          )}`}
                                        </Typography>
                                      )}
                                    {getMinError(
                                      item.obsDef,
                                      labOrderDetails.patient
                                    ) !== undefined &&
                                      getHighError(
                                        item.obsDef,
                                        labOrderDetails.patient
                                      ) !== undefined && (
                                        <Typography
                                          variant='subtitle2'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,

                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {`${getMinError(
                                            item.obsDef,
                                            labOrderDetails.patient
                                          )} - ${getHighError(
                                            item.obsDef,
                                            labOrderDetails.patient
                                          )}`}
                                        </Typography>
                                      )}
                                  </Box>
                                )}
                            </Box>
                          ))}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </main>

      <footer>
        {' '}
        {reportsAvailableForReportPartial(resultsData ?? []) && (
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='center'
            paddingTop={6}
          >
            <Typography variant='subtitle2'>
              -------- End of the report --------
            </Typography>
          </Box>
        )}
      </footer>
    </Box>
  )
}
