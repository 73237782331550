import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, Button, Typography, TextField } from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { User } from 'models/user'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { resetUpdateStatus } from 'redux/collectionCenter/partnerLabManagementSlice'
import { requestForUupdateUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/practitionerSearchSlice'
import { RootState } from 'redux/rootReducer'
import { IdProofType } from 'utils/constants/proof_types'
import {
  getAddress,
  getNameFromHumanName,
  getProfilePicPractitionerForDoc,
  getTelecomFromContactPoints,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionDataForUrl,
  getValueCodingFromExtension,
} from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { LogoViewer } from 'views/components/common/logoViewer'
import {
  SelectedFile,
  WelloFilePicker,
} from 'views/components/LeftMenu/WelloFilePicker'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  validateEmail,
  validateMobileNumber,
  WelloTextField,
} from 'wello-web-components'

interface Props {
  userDetails?: PractitionerWithRole
  role: string[]
  onDone?: () => void
}
export const UserTitleWithoutDetail: React.FC<Props> = ({
  userDetails,
  role,
  onDone,
}) => {
  const practitionerManagement = useSelector(
    (state: RootState) => state.practitionerManagement
  )

  const { t } = useTranslation()

  const actorInvitationSetupSlice = useSelector(
    (state: RootState) => state.actorInvitationSetupSlice
  )

  const { height, width } = useWindowDimensions()

  const seq = ''
  let lName: string | undefined = ''
  let emailL: string | undefined = ''
  let address: string | undefined = ''
  let phoneData: string | undefined = ''
  let datOfBirth: string | undefined
  let genderData: string | undefined
  let profileData: string | undefined
  const dispatch = useDispatch()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [userData, SetUserData] = useState<PractitionerWithRole>()
  const [frontFile, setFrontFile] = useState<SelectedFile>()
  const [backFile, setBackFile] = useState<SelectedFile>()
  const [idProofTypeData, setIdProofTypeData] = useState<
    R4.ICoding | undefined
  >(
    userDetails && userDetails.practitionerObject
      ? getValueCodingFromExtension(
          userDetails.practitionerObject.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofType'
        ) ?? undefined
      : undefined
  )
  const [proofNumber, setProofNumber] = useState<string>(
    userDetails && userDetails.practitionerObject
      ? getValueAttachmentFromExtensionDataForUrl(
          userDetails.practitionerObject.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofValue'
        ) ?? ''
      : ''
  )
  const [profilePic, setProfilePic] = useState<string>(
    userDetails && userDetails.practitionerObject
      ? getProfilePicPractitionerForDoc(userDetails.practitionerObject)
      : ''
  )

  if (userDetails?.practitionerObject) {
    lName = getNameFromHumanName(userDetails.practitionerObject.name ?? [])
    emailL =
      getTelecomFromContactPoints(
        userDetails.practitionerObject?.telecom ?? [],
        ContactPointSystemKind._email
      ) ?? ''
    address = userDetails.practitionerObject.address
      ? getAddress(userDetails.practitionerObject.address)
      : ''
    phoneData =
      getTelecomFromContactPoints(
        userDetails.practitionerObject?.telecom ?? [],
        ContactPointSystemKind._phone
      ) ?? ''
  }

  const [user, setUser] = useState<User>({
    id: _.random(1, 10000000).toString(),
    labName: '',
    labAddress: '',
    name: '',
    email: '',
    phone: '',
    secondaryPhone: '',
    address: '',
    gender: '',
    dob: '',
    profileDescription: '',
    qualiFication: [],
    lat: 0,
    lng: 0,
    isEditAvailable: false,
  })

  function resetDetails() {
    setUser({
      id: _.random(1, 10000000).toString(),
      labName: '',
      labAddress: '',
      name: '',
      email: '',
      phone: '',
      secondaryPhone: '',
      address: '',
      gender: '',
      dob: '',
      profileDescription: '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })
  }

  function handleNameChange(name: string) {
    setUser({ ...user, name, isEditAvailable: true })
  }

  function handleEmailChange(event: any) {
    setUser({ ...user, email: event.target.value, isEditAvailable: true })
  }

  function handlePhoneNumberChange(phone: string) {
    setUser({ ...user, phone, isEditAvailable: true })
  }

  function handleAddressChange(event: any) {
    setUser({ ...user, address: event.target.value, isEditAvailable: true })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    if (!user.name || user.name?.length === 0) {
      hasErrorsInData = true
    }

    if (
      !user.email ||
      user.email?.length === 0 ||
      validateEmail(user.email, true).length > 0
    ) {
      hasErrorsInData = true
    }

    if (
      !user.phone ||
      user.phone?.length === 0 ||
      validateMobileNumber(user.phone, true).length > 0
    ) {
      hasErrorsInData = true
    }

    if (!user.address || user.address?.length === 0) {
      hasErrorsInData = true
    }

    if (!hasErrorsInData) {
      const mapCenter = {
        lat: 0,
        lng: 0,
      }
      dispatch(
        requestForUupdateUser(
          mapCenter,
          user,
          [],
          userDetails,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          profilePic,
          idProofTypeData,
          proofNumber,
          undefined,
          frontFile,
          backFile
        )
      )
      dispatch(fetchUser(userDetails?.roleObject.id ?? ''))
    }
    setHasErrors(hasErrorsInData)
    if (onDone) onDone()
  }

  function handleCancel() {
    setUser({
      id: _.random(1, 10000000).toString(),
      labName: '',
      labAddress: '',
      name: lName || '',
      email: emailL || '',
      phone: phoneData || '',
      secondaryPhone: '',
      address: address || '',
      gender: genderData || '',
      dob: datOfBirth || '',
      profileDescription: profileData || '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })

    setIdProofTypeData(
      userDetails && userDetails.practitionerObject
        ? getValueCodingFromExtension(
            userDetails.practitionerObject.extension,
            'http://wellopathy.com/fhir/india/core/Code/identityProofType'
          ) ?? undefined
        : undefined
    )
    setProofNumber(
      userDetails && userDetails.practitionerObject
        ? getValueAttachmentFromExtensionDataForUrl(
            userDetails.practitionerObject.extension,
            'http://wellopathy.com/fhir/india/core/Code/identityProofValue'
          ) ?? ''
        : ''
    )
    setProfilePic(
      userDetails && userDetails.practitionerObject
        ? getProfilePicPractitionerForDoc(userDetails.practitionerObject)
        : ''
    )
  }

  useEffect(() => {
    setUser({
      id: _.random(1, 10000000).toString(),
      labName: '',
      labAddress: '',
      name: lName || '',
      email: emailL || '',
      phone: phoneData || '',
      secondaryPhone: '',
      address: address || '',
      gender: genderData || '',
      dob: datOfBirth || '',
      profileDescription: profileData || '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })

    if (practitionerManagement.userDetailsUpdated) {
      resetDetails()
      dispatch(resetUpdateStatus())
      if (onDone) onDone()
    }
    return () => {}
  }, [practitionerManagement.userDetailsUpdated, dispatch])

  return (
    <Box width='100%' px={1} height={height - 324} key={user.id}>
      {userDetails?.practitionerObject && (
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              flexGrow
              alignItems='center'
              justifyContent='flex-start'
              px={0.5}
              width='10%'
            >
              <Box>
                <WelloFilePicker
                  fileTypeToPick='photo'
                  id='unit_actor_profile_pic'
                  labelOrientation='column'
                  name='profilePic'
                  allowUpdate={true}
                  displaySelectedPhoto='circle'
                  preSelectedFile={{
                    type: 'image',
                    path: profilePic,
                  }}
                  inputProps={{}}
                  onFileChanged={(selectedField) => {
                    setUser({ ...user, isEditAvailable: true })
                    if (selectedField.path)
                      setProfilePic(selectedField.path as string)
                  }}
                  required={false}
                />
              </Box>
            </Box>

            <Box
              display='flex'
              flexGrow
              alignItems='center'
              justifyContent='flex-start'
              px={0.5}
              width='30%'
            >
              <WelloTextField
                title={t('labelCommon:userName')}
                //   value=lab.labName
                textProps={{
                  id: `actor_name_unit_${t}`,
                  value: user.name,
                  defaultValue: 'test',
                  error: hasErrors && (!user.name || user.name?.length === 0),
                  helperText:
                    hasErrors && (!user.name || user.name?.length === 0)
                      ? t('labelCommon:validName')
                      : '',
                  onChange: (changedValue) => {
                    handleNameChange(changedValue.target.value)
                  },
                }}
              />
            </Box>

            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-end'
              justifyContent='flex-start'
              px={0.5}
              width='30%'
            >
              <WelloTextField
                title={t('labelCommon:email_address')}
                //   value=lab.labName
                textProps={{
                  id: `actor_email_unit`,
                  value: user.email,
                  defaultValue: 'test',
                  disabled: true,
                  error:
                    hasErrors && validateEmail(user.email, true).length > 0,
                  helperText:
                    hasErrors && validateEmail(user.email, true).length > 0
                      ? t('labelCommon:validEmail')
                      : '',
                  onChange: (changedValue) => {
                    handleEmailChange(changedValue.target.value)
                  },
                }}
              />
            </Box>
            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-end'
              justifyContent='flex-start'
              px={0.5}
              width='30%'
            >
              <WelloPhoneNumberField
                textProps={{
                  id: `actor_phone_unit_${t}`,
                  value: user.phone,
                  countryCodeEditable: false,
                  defaultCountry: 'in',
                  fullWidth: true,
                  disabled: true,
                  name: t('phone_name_label'),
                  inputMode: 'tel',
                  onlyCountries: ['in'],
                  disableDropdown: false,
                  inputProps: { maxLength: 13 },
                  onChange: (
                    e:
                      | React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      | string
                  ) => {
                    if (typeof e === 'string') {
                      handlePhoneNumberChange(e)
                    }
                  },
                  regions: ['asia'],
                  autoFormat: false,
                  size: 'small',
                  variant: 'outlined',
                  error:
                    hasErrors &&
                    validateMobileNumber(user.phone, true).length > 0,
                  helperText:
                    hasErrors &&
                    validateMobileNumber(user.phone, true).length > 0
                      ? t('labelCommon:validPhone')
                      : '',

                  required: true,
                }}
                title={t('labelCommon:phone_number_label')}
              />
            </Box>
          </Box>

          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-start'
              justifyContent='flex-start'
              p={0.5}
              width='10%'
            />

            <Box
              display='flex'
              // flexGrow={1}
              alignItems='flex-start'
              justifyContent='flex-start'
              width='30%'
              p={0.5}
            >
              <WelloTextField
                title={t('labelCommon:lab_Address_label')}
                textProps={{
                  id: `actor_name_unit_${t}`,
                  value: user.address,
                  type: 'text',
                  rows: 2,
                  rowsMax: 4,
                  multiline: true,
                  error:
                    hasErrors && (!user.address || user.address?.length === 0),
                  helperText:
                    hasErrors && (!user.address || user.address?.length === 0)
                      ? 'Enter valid address'
                      : '',
                  onChange: (changedValue) => {
                    handleAddressChange(changedValue)
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            display='flex'
            width='100%'
            flexDirection='row'
            justifyContent='flex-start'
            py={1}
          >
            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-start'
              justifyContent='flex-start'
              p={0.5}
              width='10%'
            />
            <Box
              display='flex'
              width='30%'
              flexDirection='column'
              justifyContent='flex-start'
            >
              <Box display='flex' width='100%' px={0.5}>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel
                    title={t('labelCommon:select_proof_type')}
                  />
                  <Autocomplete
                    style={{
                      borderRadius: '4px',
                    }}
                    fullWidth
                    size='small'
                    id='patient_address_proof_type'
                    autoFocus
                    disableClearable
                    getOptionSelected={(option, value) =>
                      option.display === value.display
                    }
                    defaultValue={idProofTypeData}
                    getOptionLabel={(option) => option.display ?? ''}
                    options={IdProofType}
                    onChange={(e, val, reason) => {
                      if (val) {
                        setUser({ ...user, isEditAvailable: true })
                        setIdProofTypeData(val)
                      }
                    }}
                    autoComplete
                    ChipProps={{
                      deleteIcon: (
                        <ClearOutlined
                          style={{
                            height: '15px',
                            color: 'white',
                          }}
                        />
                      ),
                      style: {
                        backgroundColor: kPrimaryLight,
                        borderRadius: '4px',
                      },
                    }}
                    renderOption={(option) => (
                      <Typography variant='subtitle2'>
                        {option.display}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select Proof Type'
                        fullWidth
                        id='patient_address_proof_type_search'
                        variant='outlined'
                        size='small'
                        required={true}
                        // error={!!errors.identityProofTypeCode}
                        // helperText={errors.identityProofTypeCode}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box
                display='flex'
                width='100%'
                px={0.5}
                justifyContent='flex-start'
              >
                <WelloTextField
                  title={t('labelCommon:proof_number')}
                  textProps={{
                    id: `unit_actor_id_proof_number`,
                    required: true,
                    value: proofNumber,
                    name: 'identityProofNumber',
                    error: proofNumber.length === 0,
                    helperText:
                      proofNumber.length === 0
                        ? 'Proof Number is required'
                        : '',
                    fullWidth: true,
                    size: 'small',
                    onChange: (e) => {
                      setUser({ ...user, isEditAvailable: true })
                      setProofNumber(e.target.value)
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              width='60%'
              paddingLeft={2}
            >
              <Box
                display='flex'
                width='40%'
                flexDirection='column'
                justifyContent='flex-start'
                py={1}
              >
                <Box width='100%' justifyContent='flex-start'>
                  <Typography
                    variant='subtitle2'
                    style={{
                      textTransform: 'uppercase',
                    }}
                  >
                    Identity Proof Front
                  </Typography>
                </Box>
                {userDetails &&
                  userDetails.practitionerObject &&
                  getValueAttachmentFromExtension(
                    userDetails.practitionerObject.extension,
                    'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                  ) === '1' && (
                    <Box
                      display='flex'
                      width='40%'
                      flexDirection='row'
                      justifyContent='flex-start'
                      py={0.5}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        alignContent='center'
                      >
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                          height='90%'
                          paddingLeft={0.2}
                        >
                          <LogoViewer
                            contentType='image/png'
                            decodingRequired={true}
                            imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                            resourceId={userDetails.practitionerObject.id ?? ''}
                            resourceType='Practitioner'
                            onSelectionChanged={(value: SelectedFile) => {
                              setUser({ ...user, isEditAvailable: true })
                              setFrontFile(value)
                            }}
                            update={true}
                            purpose='tile2'
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                {userDetails &&
                  userDetails.practitionerObject &&
                  getValueAttachmentFromExtension(
                    userDetails.practitionerObject.extension,
                    'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                  ) === '0' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      flexGrow={1}
                      width='100%'
                    >
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                      >
                        <WelloFilePicker
                          fileTypeToPick='photo'
                          id='unit_actor_profile_pic_front'
                          labelOrientation='column'
                          name='logo'
                          labelName='Identity Proof Front'
                          allowUpdate={true}
                          displaySelectedPhoto='square'
                          preSelectedFile={frontFile}
                          inputProps={{}}
                          onFileChanged={(selectedField: SelectedFile) => {
                            setUser({ ...user, isEditAvailable: true })
                            if (selectedField) setFrontFile(selectedField)
                          }}
                        />
                      </Box>
                    </Box>
                  )}
              </Box>
              <Box
                display='flex'
                width='40%'
                flexDirection='column'
                justifyContent='flex-start'
                py={1}
              >
                <Box width='100%' justifyContent='flex-start'>
                  <Typography
                    variant='subtitle2'
                    style={{
                      textTransform: 'uppercase',
                    }}
                  >
                    Identity Proof Back
                  </Typography>
                </Box>
                {userDetails &&
                  userDetails.practitionerObject &&
                  getValueAttachmentFromExtension(
                    userDetails.practitionerObject.extension,
                    'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                  ) === '0' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      flexGrow={1}
                      width='100%'
                    >
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                      >
                        <WelloFilePicker
                          fileTypeToPick='photo'
                          id='unit_actor_profile_pic_back'
                          labelOrientation='column'
                          labelName='Identity Proof Back'
                          name='logo'
                          allowUpdate={true}
                          displaySelectedPhoto='square'
                          preSelectedFile={backFile}
                          inputProps={{}}
                          onFileChanged={(selectedField: SelectedFile) => {
                            if (selectedField) setBackFile(selectedField)
                            setUser({ ...user, isEditAvailable: true })
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                {userDetails &&
                  userDetails.practitionerObject &&
                  getValueAttachmentFromExtension(
                    userDetails.practitionerObject.extension,
                    'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                  ) === '1' && (
                    <Box
                      display='flex'
                      width='40%'
                      flexDirection='row'
                      justifyContent='flex-start'
                      py={0.5}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        alignContent='center'
                      >
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                          height='90%'
                          paddingLeft={0.2}
                        >
                          <LogoViewer
                            contentType='image/png'
                            decodingRequired={true}
                            imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)"
                            resourceId={userDetails.practitionerObject.id ?? ''}
                            resourceType='Practitioner'
                            onSelectionChanged={(value: SelectedFile) => {
                              setBackFile(value)
                              setUser({ ...user, isEditAvailable: true })
                            }}
                            update={true}
                            purpose='tile1'
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>

          <Box
            display='flex'
            flexGrow={1}
            // alignItems='flex-end'
            justifyContent='flex-end'
            p={0.5}
            width='100%'
          >
            <Button
              variant='contained'
              color='primary'
              disabled={
                practitionerManagement.userDetailsUpdating ||
                user.isEditAvailable === false ||
                (userDetails && userDetails.status === 'Inactive')
              }
              onClick={() => {
                handleSubmit()
              }}
            >
              {t('labelCommon:Save')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={
                practitionerManagement.userDetailsUpdating ||
                user.isEditAvailable === false ||
                (userDetails && userDetails.status === 'Inactive')
              }
              onClick={() => {
                handleCancel()
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
