import { R4 } from '@ahryman40k/ts-fhir-types'
import DateFnsUtils from '@date-io/date-fns'
import {
  Box,
  Button,
  CardContent,
  CardActions,
  Typography,
  Card,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ArrowDownward, CalendarToday, CloseOutlined } from '@material-ui/icons'

import MomentUtils from '@date-io/moment'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { LabOfferingDetail } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestLabOrder,
  resetLabOrderCreationState,
} from 'redux/lab/order/createOrderSlice/createOrderSlice'
import { RootState } from 'redux/rootReducer'
import { availablePaymentOptions } from 'utils/constants'
import {
  getAddress,
  getLanguages,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { OrderAmountSummaryTile } from 'views/components/generalOrder/ordarAmountSummary'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { PatientTile } from 'views/components/patients/pateintTile'
import { PatientSearchByText } from 'views/components/patients/patientSearchByText'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloFormItemLabel } from 'wello-web-components'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import { requestForDateWiseCollectionGraph } from 'redux/administration/graph/dateWiseGraph/dateWiseSearchGraphSearchSlice'
import { requestForServiceTypeCollectionGraph } from 'redux/administration/graph/ServiceTypeGraph/serviceWiseCollectionSlice'
import { requestForPaymentTypeCollectionGraph } from 'redux/administration/graph/PaymentTypeWiseGraph/paymentTypeWiseCollectionSlice'
import {
  requestDataForDashboardTable,
  resetReportState,
} from 'redux/administration/dashboardTable/dahsboardTableSearchSlice'
import {
  requestForMonthWiseCollectionGraph,
  resetMonthState,
} from 'redux/administration/graph/monthWiseGraph/monthWiseSearchGraphSearchSlice'
import {
  requestForLocationWisePatientData,
  resetLocationState,
} from 'redux/administration/graph/locationWiseGraph/locationWiseSearchSlice'
import {
  validateFinanceEndMonth,
  valiDateFinanceStartMonth,
  valiDateFinanceStartMonthForMessage,
} from 'utils/formValidators'
import { DateWiseCollection } from '../../graphs/dateWiseCollection'
import { PieGraphChart } from '../../graphs/pieGraphChart'
import { PaymentTypePieGraph } from '../../graphs/paymentTypePieGraph'
import { InvoiceDataTable } from '../../graphs/InvoiceDataTable'
import { MonthWiseCollection } from '../../graphs/monthWise'
import { LocationWisePAtientData } from '../../graphs/locationWisePatientGraph'

interface Props {
  id: string
  org: R4.IOrganization
}
export const OrgDialog: React.FC<Props> = ({ id, org }) => {
  const { t } = useTranslation()

  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice
  )

  let units: R4.IOrganization[] = []

  if (
    unitsOfOrganizationSlice.unitsList &&
    unitsOfOrganizationSlice.unitsList.length > 0
  ) {
    units = unitsOfOrganizationSlice.unitsList
  }

  function resetForm() {}
  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(30, 'days').toDate()
  )
  const [rangeStart, setRangeStart] = useState(
    moment().subtract(30, 'days').toDate()
  )
  const [rangeEnd, setRangeEnd] = useState(new Date())

  const [startMonth, setStartMonth] = useState(
    moment().subtract(60, 'days').toDate()
  )
  const [endMonth, setEndMonth] = useState(new Date())
  let unitArr: R4.IOrganization[] = []
  const res = sessionStorage.getItem('unitDetails')
  if (res !== null) unitArr = JSON.parse(res)

  const dispatch = useDispatch()
  const [value, setValue] = useState('month')
  const [type, setType] = useState('dateWise')
  const [location, setLocation] = useState('city_count')

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date !== null ? moment(date).toDate() : new Date())
    dispatch(
      requestForDateWiseCollectionGraph(
        date !== null ? moment(date).toDate() : new Date()
      )
    )
    dispatch(
      requestForServiceTypeCollectionGraph(
        date !== null ? moment(date).toDate() : new Date()
      )
    )

    dispatch(
      requestForPaymentTypeCollectionGraph(
        date !== null ? moment(date).toDate() : new Date()
      )
    )
    dispatch(
      requestDataForDashboardTable(
        date !== null ? moment(date).toDate() : new Date()
      )
    )
  }

  const handleDateChangeForRangeStart = (date: MaterialUiPickersDate) => {
    setRangeStart(date !== null ? moment(date).toDate() : new Date())
    dispatch(resetReportState())
    dispatch(resetMonthState())
    dispatch(resetLocationState())
    dispatch(
      requestForDateWiseCollectionGraph(
        date !== null ? moment(date).toDate() : new Date(),
        rangeEnd,
        'range'
      )
    )

    dispatch(
      requestForLocationWisePatientData(
        unitArr.length > 1 ? units[0].id ?? '' : '',
        date !== null ? moment(date).toDate() : new Date(),
        location,
        rangeEnd,
        'day'
      )
    )
    dispatch(
      requestForServiceTypeCollectionGraph(
        date !== null ? moment(date).toDate() : new Date(),
        rangeEnd,
        'range'
      )
    )

    dispatch(
      requestForPaymentTypeCollectionGraph(
        date !== null ? moment(date).toDate() : new Date(),
        rangeEnd,
        'range'
      )
    )

    dispatch(
      requestDataForDashboardTable(
        date !== null ? moment(date).toDate() : new Date(),
        rangeEnd,
        'range'
      )
    )
  }
  const handleDateChangeForRangeEnd = (date: MaterialUiPickersDate) => {
    dispatch(resetReportState())
    dispatch(resetMonthState())
    dispatch(resetLocationState())
    dispatch(
      requestForDateWiseCollectionGraph(
        rangeStart,
        date !== null ? moment(date).toDate() : new Date(),
        'range'
      )
    )

    dispatch(
      requestForLocationWisePatientData(
        units.length > 1 ? units[0].id ?? '' : org.id ?? '',
        rangeStart,
        location,
        date !== null ? moment(date).toDate() : new Date(),

        'day'
      )
    )
    dispatch(
      requestForServiceTypeCollectionGraph(
        rangeStart,
        date !== null ? moment(date).toDate() : new Date(),
        'range'
      )
    )
    dispatch(
      requestForPaymentTypeCollectionGraph(
        rangeStart,
        date !== null ? moment(date).toDate() : new Date(),
        'range'
      )
    )

    dispatch(
      requestDataForDashboardTable(
        rangeStart,
        date !== null ? moment(date).toDate() : new Date(),
        'range'
      )
    )
    setRangeEnd(date !== null ? moment(date).toDate() : new Date())
  }

  const handleEndMonthChange = (date: MaterialUiPickersDate) => {
    setEndMonth(date !== null ? moment(date).toDate() : new Date())
    dispatch(resetReportState())
    dispatch(resetMonthState())
    dispatch(resetLocationState())
    dispatch(
      requestForMonthWiseCollectionGraph(
        startMonth,
        date !== null ? moment(date).toDate() : new Date()
      )
    )

    dispatch(
      requestForLocationWisePatientData(
        units.length > 1 ? units[0].id ?? '' : org.id ?? '',
        startMonth,
        location,
        date !== null ? moment(date).toDate() : new Date()
      )
    )
    dispatch(
      requestForServiceTypeCollectionGraph(
        startMonth,
        date !== null ? moment(date).toDate() : new Date()
      )
    )

    dispatch(
      requestForPaymentTypeCollectionGraph(
        startMonth,
        date !== null ? moment(date).toDate() : new Date()
      )
    )

    dispatch(
      requestDataForDashboardTable(
        startMonth,
        date !== null ? moment(date).toDate() : new Date()
      )
    )
  }

  const handleStartMonthChange = (date: MaterialUiPickersDate) => {
    setStartMonth(date !== null ? moment(date).toDate() : new Date())

    dispatch(resetReportState())
    dispatch(resetMonthState())
    dispatch(resetLocationState())

    dispatch(
      requestForMonthWiseCollectionGraph(
        date !== null ? moment(date).toDate() : new Date(),
        endMonth,
        'range'
      )
    )

    dispatch(
      requestForLocationWisePatientData(
        units.length > 1 ? units[0].id ?? '' : org.id ?? '',
        date !== null ? moment(date).toDate() : new Date(),
        location,
        endMonth
      )
    )
    dispatch(
      requestForServiceTypeCollectionGraph(
        date !== null ? moment(date).toDate() : new Date(),
        endMonth
      )
    )

    dispatch(
      requestForPaymentTypeCollectionGraph(
        date !== null ? moment(date).toDate() : new Date(),
        endMonth
      )
    )

    dispatch(
      requestDataForDashboardTable(
        date !== null ? moment(date).toDate() : new Date(),
        rangeEnd
      )
    )
  }

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box display='flex' flexDirection='row' width='100%'>
          <Box display='flex' flexDirection='column' width='100%'>
            <Box
              paddingTop={1}
              paddingBottom={1}
              px={1}
              display='flex'
              flexDirection='row'
              width='100%'
            >
              <Box
                paddingTop={1}
                paddingBottom={1}
                px={1}
                display='flex'
                flexDirection='row'
              >
                <Box minWidth='80px' paddingLeft={1} paddingRight={2.4}>
                  <Box py={1} px={0.25} display='flex' flexDirection='row'>
                    <Typography
                      variant='subtitle2'
                      style={{
                        textTransform: 'uppercase',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      Time Period
                    </Typography>
                  </Box>
                  {/* <WelloFormItemLabel title=' Time Period' /> */}
                </Box>
                <FormControl>
                  {/* <FormLabel id='demo-row-radio-buttons-group-label'>
                  <Typography
                    variant='subtitle2'
                    style={{
                      textTransform: 'uppercase',
                      paddingLeft: 6,
                    }}
                    color='initial'
                  >
                    Time Period
                  </Typography>
                </FormLabel> */}
                  <RadioGroup
                    id='add_unit_address_choice_rad_group'
                    row
                    aria-label='graphType'
                    value={type}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setType((event.target as HTMLInputElement).value)
                      if (
                        (event.target as HTMLInputElement).value === 'dateWise'
                      ) {
                        dispatch(resetReportState())
                        dispatch(resetMonthState())
                        dispatch(resetLocationState())
                        setStartMonth(new Date())
                        setEndMonth(new Date())
                        setValue('month')
                        setSelectedDate(moment().subtract(30, 'days').toDate())
                        setRangeStart(moment().subtract(30, 'days').toDate())
                        setRangeEnd(new Date())

                        dispatch(
                          requestForDateWiseCollectionGraph(
                            moment().subtract(30, 'days').toDate()
                          )
                        )

                        dispatch(
                          requestForLocationWisePatientData(
                            units.length > 1 ? units[0].id ?? '' : org.id ?? '',
                            moment().subtract(30, 'days').toDate(),
                            location,
                            new Date(),
                            'day'
                          )
                        )
                        dispatch(
                          requestForServiceTypeCollectionGraph(
                            moment().subtract(30, 'days').toDate(),
                            new Date(),
                            'range'
                          )
                        )
                        dispatch(
                          requestForPaymentTypeCollectionGraph(
                            moment().subtract(30, 'days').toDate(),
                            new Date(),
                            'range'
                          )
                        )
                        dispatch(
                          requestDataForDashboardTable(
                            moment().subtract(30, 'days').toDate(),
                            new Date(),
                            'range'
                          )
                        )
                      } else {
                        dispatch(resetReportState())
                        dispatch(resetMonthState())
                        dispatch(resetLocationState())
                        setStartMonth(moment().subtract(60, 'days').toDate())
                        setEndMonth(new Date())
                        setValue('month')
                        setSelectedDate(moment().subtract(30, 'days').toDate())
                        setRangeStart(moment().subtract(30, 'days').toDate())
                        setRangeEnd(new Date())

                        dispatch(
                          requestForServiceTypeCollectionGraph(
                            moment().subtract(60, 'days').toDate(),
                            new Date(),
                            'range'
                          )
                        )

                        dispatch(
                          requestForLocationWisePatientData(
                            units.length > 1 ? units[0].id ?? '' : org.id ?? '',
                            moment().subtract(60, 'days').toDate(),
                            location,
                            new Date()
                          )
                        )

                        dispatch(
                          requestForPaymentTypeCollectionGraph(
                            moment().subtract(60, 'days').toDate(),
                            new Date(),

                            'range'
                          )
                        )

                        dispatch(
                          requestDataForDashboardTable(
                            moment().subtract(60, 'days').toDate(),
                            new Date()
                          )
                        )
                      }
                    }}
                  >
                    <Box px={1.5}>
                      <FormControlLabel
                        value='dateWise'
                        control={<Radio color='primary' />}
                        label='By Date'
                      />
                      <FormControlLabel
                        value='MonthWise'
                        control={<Radio color='primary' />}
                        label='By Month'
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                {type === 'MonthWise' && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='50%'
                    paddingTop={1}
                  >
                    <Box
                      paddingRight={1}
                      display='flex'
                      flexDirection='row'
                      width='40%'
                    >
                      <Box paddingRight={2.5}>
                        <Box
                          py={1}
                          px={0.25}
                          display='flex'
                          flexDirection='row'
                        >
                          <Typography
                            variant='subtitle2'
                            style={{
                              textTransform: 'uppercase',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            Start Month
                          </Typography>
                        </Box>
                        {/* <WelloFormItemLabel title='Start Month' /> */}
                      </Box>
                      <Box display='flex' flexDirection='column' width='50%'>
                        <DatePicker
                          views={['month', 'year']}
                          openTo='month'
                          autoOk={true}
                          style={{
                            cursor: 'pointer',
                          }}
                          inputVariant='outlined'
                          size='small'
                          error={
                            valiDateFinanceStartMonth(
                              moment(rangeStart).format('YYYY-MM-DD'),
                              moment(rangeEnd).format('YYYY-MM-DD'),
                              false
                            ).length > 0
                          }
                          helperText={
                            valiDateFinanceStartMonth(
                              moment(startMonth).format('YYYY-MM-DD'),
                              moment(endMonth).format('YYYY-MM-DD'),
                              false
                            ).length > 0
                              ? valiDateFinanceStartMonth(
                                  moment(startMonth).format('YYYY-MM-DD'),
                                  moment(endMonth).format('YYYY-MM-DD'),
                                  false
                                )
                              : ''
                          }
                          value={startMonth}
                          maxDate={moment(new Date())}
                          onChange={handleStartMonthChange}
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <CalendarToday
                                fontSize='small'
                                style={{
                                  margin: '2px 8px',
                                }}
                              />
                            ),
                            margin: 'dense',
                          }}
                        />
                        {valiDateFinanceStartMonthForMessage(
                          moment(startMonth).format('YYYY-MM-DD'),
                          moment(endMonth).format('YYYY-MM-DD'),
                          false
                        ).length > 0 && (
                          <Box paddingLeft={0.5}>
                            <Typography variant='subtitle2' color='error'>
                              {valiDateFinanceStartMonthForMessage(
                                moment(startMonth).format('YYYY-MM-DD'),
                                moment(endMonth).format('YYYY-MM-DD'),
                                false
                              )}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box
                      paddingRight={1}
                      display='flex'
                      flexDirection='row'
                      width='50%'
                    >
                      <Box paddingRight={2.5}>
                        <Box
                          py={1}
                          px={0.25}
                          display='flex'
                          flexDirection='row'
                        >
                          <Typography
                            variant='subtitle2'
                            style={{
                              textTransform: 'uppercase',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            END MONTH
                          </Typography>
                        </Box>
                        {/* <WelloFormItemLabel title='END MONTH' /> */}
                      </Box>
                      <Box display='flex' flexDirection='row' width='50%'>
                        <DatePicker
                          views={['month', 'year']}
                          openTo='month'
                          autoOk={true}
                          style={{
                            cursor: 'pointer',
                          }}
                          inputVariant='outlined'
                          size='small'
                          value={endMonth}
                          minDate={moment(startMonth)}
                          maxDate={moment(new Date())}
                          error={
                            validateFinanceEndMonth(
                              moment(rangeStart).format('YYYY-MM-DD'),
                              moment(rangeEnd).format('YYYY-MM-DD'),
                              false
                            ).length > 0
                          }
                          helperText={
                            validateFinanceEndMonth(
                              moment(startMonth).format('YYYY-MM-DD'),
                              moment(endMonth).format('YYYY-MM-DD'),
                              false
                            ).length > 0
                              ? validateFinanceEndMonth(
                                  moment(startMonth).format('YYYY-MM-DD'),
                                  moment(endMonth).format('YYYY-MM-DD'),
                                  false
                                )
                              : ''
                          }
                          onChange={handleEndMonthChange}
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <CalendarToday
                                fontSize='small'
                                style={{
                                  margin: '2px 8px',
                                }}
                              />
                            ),
                            margin: 'dense',
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                {type === 'dateWise' && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='50%'
                    paddingTop={1}
                  >
                    <Box
                      paddingRight={1}
                      display='flex'
                      flexDirection='row'
                      width='43%'
                    >
                      <Box paddingRight={0.5}>
                        <Box
                          py={1}
                          px={0.25}
                          display='flex'
                          flexDirection='row'
                        >
                          <Typography
                            variant='subtitle2'
                            style={{
                              textTransform: 'uppercase',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            Date Of Admission
                          </Typography>
                        </Box>
                        {/* <WelloFormItemLabel title=' Admission Date' /> */}
                      </Box>
                      {/* <WelloFormItemLabel title='Admission Date' /> */}

                      <Box display='flex' flexDirection='row' width='50%'>
                        <KeyboardDatePicker
                          clearable
                          id={`${id}check_out_date_picker`}
                          maxDate={moment()}
                          value={rangeStart}
                          size='small'
                          format='DD-MM-YYYY'
                          onChange={handleDateChangeForRangeStart}
                          inputVariant='outlined'
                          tabIndex={0}
                        />
                      </Box>
                    </Box>
                    <Box
                      paddingRight={1}
                      display='flex'
                      flexDirection='row'
                      width='43%'
                    >
                      <Box paddingRight={0.5}>
                        <Box
                          py={1}
                          px={0.25}
                          display='flex'
                          flexDirection='row'
                        >
                          <Typography
                            variant='subtitle2'
                            style={{
                              textTransform: 'uppercase',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            Date Of Discharge
                          </Typography>
                        </Box>
                        {/* <WelloFormItemLabel title='Check-out Date' /> */}
                      </Box>
                      <Box display='flex' flexDirection='row' width='50%'>
                        <KeyboardDatePicker
                          clearable
                          id={`${id}check_out_date_picker`}
                          maxDate={moment()}
                          minDate={moment(rangeStart)}
                          value={rangeEnd}
                          size='small'
                          format='DD-MM-YYYY'
                          onChange={handleDateChangeForRangeEnd}
                          inputVariant='outlined'
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </MuiPickersUtilsProvider>
            </Box>

            <Box px={2}>
              <Box display='flex' flexDirection='row' width='100%'>
                {type === 'dateWise' && (
                  <Box width='70%' py={1} overflow='auto'>
                    <DateWiseCollection date={rangeStart} endDate={rangeEnd} />
                  </Box>
                )}

                {type === 'MonthWise' && (
                  <Box width='70%' py={1}>
                    <MonthWiseCollection date={startMonth} endDate={endMonth} />
                  </Box>
                )}
                <Box width='30%' px={2} py={1}>
                  <PieGraphChart date={selectedDate} />
                </Box>
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box paddingTop={1} px={1} display='flex' flexDirection='row'>
                <Box minWidth='80px' px={0.5}>
                  <Box py={1} px={0.25} display='flex' flexDirection='row'>
                    <Typography
                      variant='subtitle2'
                      style={{
                        textTransform: 'uppercase',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      Location
                    </Typography>
                  </Box>
                  {/* <WelloFormItemLabel title='Location' /> */}
                </Box>
                <FormControl>
                  {/* <FormLabel id='demo-row-radio-buttons-group-label'>
                    <Typography
                      variant='subtitle2'
                      style={{
                        textTransform: 'uppercase',
                        paddingLeft: 6,
                      }}
                      color='initial'
                    >
                      Location
                    </Typography>
                  </FormLabel> */}
                  <RadioGroup
                    id='add_unit_address_choice_rad_group'
                    row
                    aria-label='graphType'
                    value={location}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLocation((event.target as HTMLInputElement).value)
                      if (
                        (event.target as HTMLInputElement).value ===
                        'city_count'
                      ) {
                        dispatch(resetLocationState())

                        if (type === 'dateWise') {
                          dispatch(
                            requestForLocationWisePatientData(
                              units.length > 1
                                ? units[0].id ?? ''
                                : org.id ?? '',
                              moment().subtract(30, 'days').toDate(),
                              location,
                              new Date(),
                              'day'
                            )
                          )
                        } else {
                          dispatch(
                            requestForLocationWisePatientData(
                              units.length > 1
                                ? units[0].id ?? ''
                                : org.id ?? '',
                              moment().subtract(60, 'days').toDate(),
                              location,
                              new Date()
                            )
                          )
                        }
                      } else {
                        dispatch(resetLocationState())
                        if (type === 'dateWise') {
                          dispatch(
                            requestForLocationWisePatientData(
                              units.length > 1
                                ? units[0].id ?? ''
                                : org.id ?? '',
                              moment().subtract(30, 'days').toDate(),
                              location,
                              new Date(),
                              'day'
                            )
                          )
                        } else {
                          dispatch(
                            requestForLocationWisePatientData(
                              units.length > 1
                                ? units[0].id ?? ''
                                : org.id ?? '',
                              moment().subtract(60, 'days').toDate(),
                              location,
                              new Date()
                            )
                          )
                        }
                      }
                    }}
                  >
                    <Box px={1.5}>
                      <FormControlLabel
                        value='city_count'
                        control={<Radio color='primary' />}
                        label='By City'
                      />
                      <FormControlLabel
                        value='state_count'
                        control={<Radio color='primary' />}
                        label='By State'
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box width='69.5%' py={1} overflow='auto' paddingLeft={2}>
                <LocationWisePAtientData
                  date={rangeStart}
                  location={location}
                  endDate={rangeEnd}
                  type={type}
                  unit={org.id ?? ''}
                />
              </Box>
              <Box width='29%' paddingLeft={2.2} paddingRight={1.1} py={1}>
                <PaymentTypePieGraph date={selectedDate} />
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box width='70%' py={1} overflow='auto'>
                <InvoiceDataTable date={selectedDate} />
              </Box>
            </Box>
          </Box>

          {/* <Box display='flex' flexDirection='column' width='20%' paddingTop={4}>
            <Box width='100%' px={2}>
              <PieGraphChart date={selectedDate} />
            </Box>
            <Box width='100%' px={2}>
              <PaymentTypePieGraph date={selectedDate} />
            </Box>
          </Box> */}
        </Box>
      </Paper>
    </div>
  )
}
