/* eslint-disable prefer-destructuring */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { PatientDetails } from 'models/fhirPatientDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { ObsServiceDataWithVal } from 'models/obsServiceData'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { fetchOrderDetails } from 'redux/lab/order/orderManagementSlice/orderManagementSlice'
import { getPrescriptionDetails } from 'redux/prescription/medicationsListSlice/prescriptionSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getLabReportFromUML } from 'redux/umls/condition/conditionSearchSlice'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitDetails,
  isAyurvedaDoctor,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { getOrderCollectionTime, getOrderSampleReceived } from 'utils/dateUtil'
import {
  getAddress,
  getAgeOfPatientData,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameFromHumanName,
  getNameOfPatient,
  getTelecomFromContactPoints,
  getTelecomOfPatient,
} from 'utils/fhirResourcesHelper'
import { getDataForReport } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { LogoViewer } from 'views/components/common/logoViewer'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePickerForProfile'

import { PrintResults } from './printResults'

interface Props {
  open: boolean
  labOrderDetails: FhirLabOrderDetail
  resultsData: ObsServiceDataWithVal[]
  onPrint: () => void
  onClose: () => void
}
export const ViewAllReports: React.FC<Props> = ({
  open,
  labOrderDetails,
  resultsData,
  onPrint,
  onClose,
}) => {
  const pageStyle = `
    @page {
      size: A4 portrait;
    }`
  const orderManagementSlice = useSelector(
    (state: RootState) => state.orderManagementSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const componentRef = useRef<any>()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const [logoData, setLogoData] = useState<string>('')
  const [signData, setSignData] = useState<string>('')

  function getLabReportData(orderDetails: FhirLabOrderDetail) {
    setUpdateStatus({ requesting: true })

    setLoading(true)
    let imgData: string = ''
    let signDataLatest: string = ''

    if (logoData.length > 0) {
      imgData = logoData.split(',')[1]
    }
    if (signData.length > 0) {
      signDataLatest = signData.split(',')[1]
    }

    const patientData: PatientDetails = {
      patientName:
        getMiddleNameOfPatient(orderDetails.patient).length > 0
          ? `${getNameOfPatient(labOrderDetails.patient)} ${
              getMiddleNameOfPatient(labOrderDetails.patient) ?? ''
            } ${getLastNameOfPatient(labOrderDetails.patient)}`
          : `${getNameOfPatient(
              labOrderDetails.patient
            )} ${getLastNameOfPatient(labOrderDetails.patient)}`,
      address: getAddress(getCurrentUserUnitDetails().address ?? []) ?? '',
      phoneNumber:
        getTelecomOfPatient(
          labOrderDetails.patient,
          R4.ContactPointSystemKind._phone
        ) ?? '',
      collectionTime: getOrderCollectionTime(orderDetails.statuses ?? []),
      receivingTime: getOrderSampleReceived(orderDetails.statuses ?? []),
      reportGenerationTime: moment().format('MMM DD, YYYY, h:mm:ss A'),
      age: `${getAgeOfPatientData(labOrderDetails.patient)} / ${
        labOrderDetails.patient.gender === R4.PatientGenderKind._male
          ? 'Male'
          : 'Female'
      }`,
      adminEmail: getCurrentUserUnitDetails().contact
        ? getTelecomFromContactPoints(
            getCurrentUserUnitDetails().contact![0].telecom ?? [],
            ContactPointSystemKind._email
          ) ?? ''
        : '',
      adminPhone: getCurrentUserUnitDetails().contact
        ? getTelecomFromContactPoints(
            getCurrentUserUnitDetails().contact![0].telecom ?? [],
            ContactPointSystemKind._phone
          ) ?? ''
        : '',
      logo: imgData,
      sign: signDataLatest,
      doctorName: getNameFromHumanName(
        getCurrentUserPractitionerDetails().name ?? []
      ),
    }

    getLabReportFromUML(
      patientData,
      orderDetails,
      getDataForReport(resultsData, orderDetails),
      dispatch
    )
      .then((e: boolean) => {
        setLoading(false)
        if (e) {
          onPrint()
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          dispatch(showSuccessAlert('Lab reports generated successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while generating reports details. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    dispatch(fetchOrderDetails(labOrderDetails.serviceRequest.id ?? ''))
    return () => {}
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>View Report</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          //   overflow: 'auto',
          backgroundColor: '#FFFFFF',
          padding: 0,
        }}
      >
        <Box width='100%' display='flex' flexDirection='column'>
          <Box style={{ display: 'none' }}>
            <LogoViewer
              contentType='image/png'
              decodingRequired={true}
              imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext').value.as(Attachment)"
              resourceId={getCurrentUserPractitionerDetails().id ?? ''}
              resourceType='Practitioner'
              onSelectionChanged={(value: SelectedFile) => {
                //   setFile(value)
              }}
              update={false}
              onDataFetched={(value: string) => {
                setSignData(value)
              }}
              purpose='tile3'
              prescription={true}
            />
            <LogoViewer
              contentType='image/png'
              decodingRequired={true}
              imagePath="Organization.extension('http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo').value.as(Attachment)"
              resourceId={getCurrentUserUnitDetails().id ?? ''}
              resourceType='Organization'
              onSelectionChanged={(value: SelectedFile) => {
                //   setFile(value)
              }}
              onDataFetched={(value: string) => {
                setLogoData(value)
              }}
              update={false}
              purpose='tile3'
            />
          </Box>
          {orderManagementSlice.orderDetailsAvailable &&
            orderManagementSlice.orderDetail && (
              <PrintResults
                labOrderDetails={orderManagementSlice.orderDetail}
                refData={componentRef}
                resultsData={resultsData}
              />
            )}
        </Box>
      </DialogContent>
      <DialogActions>
        {orderManagementSlice.orderDetailsAvailable &&
          orderManagementSlice.orderDetail && (
            <Button
              variant='contained'
              color='primary'
              disabled={orderManagementSlice.fetchingOrderDetails || loading}
              onClick={() => {
                getLabReportData(orderManagementSlice.orderDetail!)
              }}
            >
              Upload
            </Button>
          )}
      </DialogActions>
    </Dialog>
  )
}
