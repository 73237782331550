import { R4 } from '@ahryman40k/ts-fhir-types'
import _ from 'lodash'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { ObsServiceDataWithVal } from 'models/obsServiceData'
import moment from 'moment'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getIdentifierValueBySystem } from 'utils/fhirResourcesHelper'
import { reportsAvailable } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'

export function getDocumentBundle(
  type: string,
  files: any,
  labOrderDetails?: FhirLabOrderDetail
): R4.IBundle {
  const bundleArray: any = []
  const untiData = getCurrentUserUnitReference()!
  for (let i = 0; i < files.length; i++) {
    const documentObj: R4.IDocumentReference = {
      resourceType: 'DocumentReference',
      authenticator: {
        reference: untiData.reference,
      },
      status: R4.DocumentReferenceStatusKind._current,
      type: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '11502-2',
            display: 'Lab Report',
          },
        ],
      },
      identifier: [
        {
          system: 'fileName',
          value: `${files[i].name}`,
        },
      ],
      content: [
        {
          attachment: {
            contentType: `${type}`,
          },
        },
      ],
    }
    if (labOrderDetails) {
      const refArray: R4.IReference[] = []
      const orderRef: R4.IReference = {
        reference: `ServiceRequest/${labOrderDetails.serviceRequest.id ?? ''}`,
        display: 'ServiceRequest',
      }
      refArray.push(orderRef)
      documentObj.context = {
        related: refArray,
      }
      const patientRef: R4.IReference = {
        reference: `Patient/${labOrderDetails.patient.id ?? ''}`,
        display: 'Patient',
      }
      documentObj.subject = patientRef

      const emcounterRefArray: R4.IReference[] = []
      const encounterRef: R4.IReference | undefined =
        labOrderDetails.serviceRequest.encounter
      if (encounterRef) {
        emcounterRefArray.push(encounterRef)
        documentObj.context.encounter = emcounterRefArray
      }
    }

    const iRequest: R4.IBundle_Request = {}
    iRequest.method = R4.Bundle_RequestMethodKind._post
    iRequest.url = 'DocumentReference'
    const bundleEntry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:documentReference${i}`,
      request: iRequest,
      resource: documentObj,
    }
    bundleArray.push(bundleEntry)
  }
  const documentBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return documentBundle
}

export function getDocumentBundleForDiaogsticReport(
  planDef: R4.IPlanDefinition[],
  report: R4.IDiagnosticReport,
  type: string,
  labOrderDetails: FhirLabOrderDetail,
  data: ObsServiceDataWithVal[]
): R4.IBundle {
  const bundleArray: any = []
  const reportFile: R4.IDiagnosticReport = { ...report }
  reportFile.presentedForm = [
    {
      contentType: type,
    },
  ]
  if (labOrderDetails.serviceRequest.encounter) {
    reportFile.encounter = labOrderDetails.serviceRequest.encounter
  }
  const resultData = reportFile.result ?? []
  const codingData = reportFile.code.coding ?? []
  for (let i = 0; i < planDef.length; i++) {
    for (let k = 0; k < data.length; k++) {
      if (reportsAvailable(data[k])) {
        if (
          data[k].name === planDef[i].name! ||
          data[k].name === planDef[i].title!
        ) {
          for (let m = 0; m < data[k].obsDef.length; m++)
            if (resultData.length > 0) {
              resultData.push({
                id: data[k].obsDef[m].obs!.id!,
                reference: `Observation/${data[k].obsDef[m].obs!.id!}`,
              })
            } else if (data[k].obsDef[k].obs) {
              resultData.push({
                id: data[k].obsDef[m].obs!.id!,
                reference: `Observation/${data[k].obsDef[m].obs!.id!}`,
              })
            }

          if (codingData.length > 0) {
            codingData.push({
              system: 'http://loinc.org',
              code: getIdentifierValueBySystem(
                planDef[i].identifier ?? [],
                'http://loinc.org'
              ),
              display: planDef[i].title,
            })
          } else {
            codingData.push({
              system: 'http://loinc.org',
              code: getIdentifierValueBySystem(
                planDef[i].identifier ?? [],
                'http://loinc.org'
              ),
              display: planDef[i].title,
            })
          }
        }
      }
    }
  }

  reportFile.code = {
    coding: _.uniqBy(codingData, 'code'),
  }
  reportFile.result = _.uniqBy(resultData, 'id')
  reportFile.effectiveDateTime = moment().toISOString()
  reportFile.issued = moment().toISOString()

  if (reportFile.id === undefined) {
    const iRequest: R4.IBundle_Request = {}
    iRequest.method = R4.Bundle_RequestMethodKind._post
    iRequest.url = 'DiagnosticReport'
    const bundleEntry: R4.IBundle_Entry = {
      fullUrl: `${reportFile.resourceType}/`,
      request: iRequest,
      resource: reportFile,
    }
    bundleArray.push(bundleEntry)
  } else {
    const mainTaskMatchString: string = `W/${JSON.stringify(
      reportFile.meta?.versionId ?? ''
    )}`

    bundleArray.push({
      fullUrl: `DiagnosticReport/${reportFile.id}`,
      request: {
        ifMatch: mainTaskMatchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `DiagnosticReport/${reportFile.id}`,
      },
      resource: reportFile,
    })
  }

  const documentBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return documentBundle
}

export function getDocumentReferenceBundleForEncounter(
  files: SelectedFile[],
  encounterId: string,
  patientId: string
): R4.IBundle {
  const bundleArray: any = []
  const untiData = getCurrentUserUnitReference()!
  const practRole = getCurrentUserPractitionerRoleDetails()
  for (let i = 0; i < files.length; i++) {
    const documentObj: R4.IDocumentReference = {
      resourceType: 'DocumentReference',
      meta: {
        profile: [
          'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndDocumentReferenceBase',
        ],
      },

      status: R4.DocumentReferenceStatusKind._current,
      subject: {
        reference: `Patient/${patientId}`,
      },
      authenticator: {
        reference: `${practRole.resourceType}/${practRole.id}`,
      },
      type: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '11502-2',
            display: 'Lab Report',
          },
        ],
      },
      identifier: [
        {
          system: 'fileName',
          value: `${files[i].name}`,
        },
      ],
      content: [
        {
          attachment: {
            contentType: `${files[i].type}`,
            title: `${files[i].name}`,
          },
        },
      ],
      context: {
        encounter: [
          {
            reference: `Encounter/${encounterId}`,
          },
        ],
      },
    }
    const iRequest: R4.IBundle_Request = {}
    iRequest.method = R4.Bundle_RequestMethodKind._post
    iRequest.url = 'DocumentReference'
    const bundleEntry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:documentReference${i}`,
      request: iRequest,
      resource: documentObj,
    }
    bundleArray.push(bundleEntry)
  }
  const documentBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return documentBundle
}
