import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Theme,
  makeStyles,
} from '@material-ui/core'
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import {
  WelloLoadingIndicator,
  WelloLoadingPage,
  WelloTextField,
} from 'wello-web-components'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addHistoryConditionDetails,
  resetAddConditionState,
} from 'redux/fhirMedicalResources/addCondition/addConditionSlice'
import { RootState } from 'redux/rootReducer'
import {
  getAgeInDays,
  getAgeInMonths,
  getAgeInWeeks,
  getAgeInYears,
  getAgeOfPatientForChecking,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { validateCheckoutDateForChiefComplaints } from 'utils/formValidators'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import {
  durationData,
  durationDataForChiefComplaints,
  durationDataForChiefComplaintsWithOne,
  durationDataWithOne,
  paymentTypes,
  serviceType,
} from 'utils/constants'
import { RadioRaisedButton } from 'views/components/LeftMenu/RadioButtons'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { PurposeOfUse } from 'models/purposeOfUse'
import { WelloCondition } from 'models/WelloConditions'
import _ from 'lodash'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { getCurrentUserPractitionerDetails } from 'services/userDetailsService'
import {
  updateConvFee,
  updateMedicalConditions,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { ConvenienceFee } from 'models/convinienceFeeDetails'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'

import {
  UnitTypesForSetting,
  serviceTypeForSetting,
  serviceTypeForLab,
} from 'utils/constants/unit_types'

interface Props {
  open: boolean
  existingCondition: ConvenienceFee
  onClose: () => void
  onMedicalConditionAdded: () => void
}
export const EditConvFee: React.FC<Props> = ({
  open,
  onClose,

  onMedicalConditionAdded,
  existingCondition,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '35%',
    },
  }))
  const classes = useStyles()
  const { t } = useTranslation(['common', 'condition'])
  const [value, setValue] = React.useState(new Date())
  const [currentCondition, setCurrentCondition] =
    useState<ConvenienceFee>(existingCondition)

  const dispatch = useDispatch()
  const [additionalNotes, setAdditionalNotes] = useState<string>()

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const historyConditionSlice = useSelector(
    (state: RootState) => state.historyConditionSlice
  )

  function enableSave() {
    const error: string[] = []
    if (currentCondition) {
      if (
        currentCondition.paymentType &&
        currentCondition.paymentType.code === 'amount'
      ) {
        if (currentCondition.amount !== undefined) {
          if (currentCondition.amount < 0 || currentCondition.amount > 100) {
            error.push('1')
          }
        }

        if (currentCondition.amount === undefined) {
          error.push('1')
        }
      } else {
        if (currentCondition.amountInPercentage !== undefined) {
          if (
            currentCondition.amountInPercentage < 0 ||
            currentCondition.amountInPercentage > 100
          ) {
            error.push('1')
          }
        }

        if (currentCondition.amountInPercentage === undefined) {
          error.push('1')
        }
      }
    }

    if (error.length > 0) {
      return true
    }
    return false
  }

  function updateConditions() {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IChargeItemDefinition = {
      ...currentCondition.cFee!,
    }
    if (
      currentCondition.paymentType &&
      currentCondition.paymentType.code === 'amount'
    ) {
      oldProcedure.propertyGroup = [
        {
          priceComponent: [
            {
              type: 'base',
              amount: {
                value: currentCondition.amount ?? 0,
                currency: 'INR',
              },
            },
          ],
        },
      ]
    } else {
      oldProcedure.propertyGroup = [
        {
          priceComponent: [
            {
              type: 'base',
              code: {
                coding: [
                  {
                    system: 'http://unitsofmeasure.org',
                    code: '%',
                    display: 'percent',
                  },
                ],
              },
              factor: parseInt(
                (currentCondition.amountInPercentage ?? 0 / 100).toFixed(2),
                10
              ),
            },
          ],
        },
      ]
    }

    setLoading(true)

    updateConvFee(oldProcedure).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Convenience Fee updated successfully'))
        onMedicalConditionAdded()
        // dispatch(
        //   requestConditionHistoryOfPatient(
        //     patient,
        //     purposeOfUse,
        //     splitview,
        //     requestingAppointmentId,
        //     encounterId
        //   )
        // )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Condition details. Please try again later'
          )
        )
      }
    })
  }

  useEffect(() => {
    setCurrentCondition(existingCondition)
  }, [existingCondition])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Edit Convenience Fee</DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='column' width='100%'>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box width='50%' paddingRight={1}>
                <WelloSelectFHIRCoding
                  title='Unit Type'
                  availableCodings={UnitTypesForSetting}
                  onChanged={(e) => {
                    if (e) {
                      //   setUnitType(e)
                    }
                  }}
                  id='id_unit_details'
                  textFieldProps={{
                    size: 'small',
                  }}
                  preSelectedCoding={currentCondition.unitType}
                  disabled={true}
                />
              </Box>
              <Box width='50%' paddingLeft={1}>
                <WelloSelectFHIRCoding
                  title='Service Type'
                  availableCodings={
                    currentCondition.serviceType
                      ? currentCondition.serviceType.code === 'online'
                        ? serviceTypeForSetting
                        : serviceTypeForLab
                      : serviceTypeForSetting
                  }
                  onChanged={(e) => {
                    if (e) {
                      //   setServiceType(e)
                    }
                  }}
                  id='id_unit_details'
                  textFieldProps={{
                    size: 'small',
                  }}
                  preSelectedCoding={currentCondition.serviceType}
                  disabled={true}
                />
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box width='50%' paddingRight={1}>
                <WelloSelectFHIRCoding
                  title='Fee Type'
                  id='payment_type'
                  availableCodings={paymentTypes}
                  onChanged={(type) => {
                    setCurrentCondition({
                      ...currentCondition,
                      paymentType: type,
                    })
                  }}
                  textFieldProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                  preSelectedCoding={currentCondition.paymentType}
                  role='payment_type'
                />
              </Box>
              <Box width='50%' paddingLeft={1}>
                {currentCondition.paymentType &&
                  currentCondition.paymentType.code &&
                  currentCondition.paymentType.code === 'percent' && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Fee Percentage' />
                      <TextField
                        size='small'
                        variant='outlined'
                        type='number'
                        placeholder=''
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: 0.25,
                          },
                        }}
                        value={currentCondition.amountInPercentage ?? 0}
                        onChange={(e) => {
                          const newValue = Number(e.target.value)

                          if (newValue >= 0 && newValue <= 15) {
                            setCurrentCondition({
                              ...currentCondition,
                              amountInPercentage: newValue,
                            })
                          } else if (/^\d+$/.test(e.target.value)) {
                            dispatch(
                              showErrorAlert(
                                `Percentage cannot be more than 15`
                              )
                            )
                          }
                        }}
                      />
                    </Box>
                  )}

                {currentCondition.paymentType &&
                  currentCondition.paymentType.code &&
                  currentCondition.paymentType.code === 'amount' && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Fee Amount' />
                      <TextField
                        size='small'
                        variant='outlined'
                        type='number'
                        placeholder=''
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: 0.25,
                          },
                        }}
                        value={currentCondition.amount ?? 0}
                        onChange={(e) => {
                          const newValue = Number(e.target.value)
                          if (newValue >= 0 && newValue <= 100) {
                            setCurrentCondition({
                              ...currentCondition,
                              amount: newValue,
                            })
                          } else if (/^\d+$/.test(e.target.value)) {
                            dispatch(
                              showErrorAlert(`Amount cannot be more than 100`)
                            )
                          }
                        }}
                      />
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box
          width='100%'
          justifyContent='flex-end'
          paddingRight={3.4}
          display='flex'
          flexDirection='row'
        >
          <Button
            onClick={() => {
              onClose()
            }}
            id='med_17'
            variant='outlined'
            disabled={loading}
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>

          {/* <Button
            onClick={() => {
              setOtherData(undefined)
              addCurrentDetails()
            }}
            variant='contained'
            color='primary'
            id='add_more'
            disableElevation
            disabled={currentCondition.condition === undefined}
          >
            {t('labelCommon:add_more_new')}
          </Button> */}

          <Button
            onClick={() => {
              if (currentCondition) {
                updateConditions()
              }
            }}
            variant='contained'
            color='primary'
            disableElevation
            id='med_18'
            disabled={enableSave() || loading}
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
