import { Grid, Typography, Box } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { Anthropometric } from 'models/fhirLabOrderDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloReports } from 'models/WelloConditions'
import React, { useContext, useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getReportsForSocket } from 'redux/consultation/reports/reportsSearchSlice'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { fetchAnthropometric } from 'utils/fhirResoureHelpers/observationHelpers'
import SocketContext from 'views/contexts/socketMessageContext'
import { AyurvedaObjective } from './objective/ayurvedaObjective'
import { FollowupLabReports } from './objective/followupLabReports'
import { FollowUpLabResults } from './objective/followupLabResults'
import { FollwupVitals } from './objective/followUpVitals'
import { GeneralExamination } from './objective/generalExamination'
import { LabReports } from './objective/labReports'
import { LabResults } from './objective/labResults'
import { ObesityCalculation } from './objective/ObesityCalculation'
import { ObesityObjective } from './objective/obesityObjective'
import { Vitals } from './objective/vitals'

interface ObjectivePartOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
  displayReport: boolean
}

export const ObjectivePartOfConsultation: React.FC<ObjectivePartOfConsultationProps> =
  ({
    fhirAppointmentDetails,
    split,
    displayReport,
  }: ObjectivePartOfConsultationProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [reportData, setReportData] = useState<WelloReports>()

    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: true,
    })

    const { eventData } = useContext(SocketContext)

    function fetchReports() {
      setFetchingStatus({
        requesting: true,
      })
      getReportsForSocket(fhirAppointmentDetails, split)
        .then((e) => {
          if (e) {
            setReportData(e)
            setFetchingStatus({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          }
        })
        .catch((e) => {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
        })
    }

    useEffect(() => {
      const convertedData = eventData as any
      console.log(JSON.stringify(convertedData))
      console.log(convertedData)
      if (convertedData !== null) {
        fetchReports()
        if (
          convertedData.infoMessages &&
          convertedData.infoMessages.length > 0
        ) {
          dispatch(showSuccessAlert(convertedData.infoMessages[0]))
        }
        if (
          convertedData.errorMessages &&
          convertedData.errorMessages.length > 0
        ) {
          dispatch(showErrorAlert(convertedData.errorMessages[0]))
        }
      }
    }, [eventData])

    //   useEffect(() => {
    //     if (
    //       fhirAppointmentDetails.encounter ||
    //       fhirAppointmentDetails.appointment.basedOn
    //     )
    //       dispatch(getLabReportsForTheAppointment(fhirAppointmentDetails, split))
    //   }, [dispatch, fhirAppointmentDetails])

    useEffect(() => {
      fetchReports()
    }, [])

    function showReports(): boolean {
      const data: string[] = []
      if (reportData) {
        if (
          reportData.diagnosticReports &&
          reportData.diagnosticReports.length > 0
        ) {
          data.push('1')
        }
        if (reportData.reportFiles && reportData.reportFiles.length > 0) {
          data.push('1')
        }
      }
      if (data.length > 0) return true
      return false
    }

    return (
      <Grid
        container
        spacing={1}
        direction='column'
        style={{ flexWrap: 'nowrap' }}
      >
        <Grid item>
          <Typography
            variant='h5'
            color='primary'
            style={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            {' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width={split === true ? '100%' : '80%'}>
            <GeneralExamination
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width={split === true ? '100%' : '80%'}>
            <Vitals
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width={split === true ? '100%' : '80%'}>
            <LabResults
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Box>
        </Grid>

        {(displayReport || showReports()) && (
          <Grid item xs={12}>
            {' '}
            <Box display='flex' width={split === true ? '100%' : '80%'}>
              <LabReports
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            </Box>
          </Grid>
        )}
        {isAyurvedaDoctor() === false && (
          <Grid item xs={12}>
            <Box display='flex' flexDirection='column' width='100%'>
              <Box display='flex' width={split === true ? '100%' : '80%'}>
                <ObesityObjective
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  onDataAdded={() => {
                    // getScoreDetails()
                  }}
                  isReadOnly={split}
                />
              </Box>
            </Box>
          </Grid>
        )}

        {getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0 && (
          <Grid item xs={12}>
            {' '}
            <Box display='flex' width={split === true ? '100%' : '80%'}>
              <FollwupVitals
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
                followUp={true}
              />
            </Box>
          </Grid>
        )}
        {getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0 && (
          <Grid item xs={12}>
            {' '}
            <Box display='flex' width={split === true ? '100%' : '80%'}>
              <FollowUpLabResults
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            </Box>
          </Grid>
        )}

        {getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0 && (
          <Grid item xs={12}>
            {' '}
            <Box display='flex' width={split === true ? '100%' : '80%'}>
              <FollowupLabReports
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={true}
              />
            </Box>
          </Grid>
        )}

        {isAyurvedaDoctor() && (
          <Grid item xs={12}>
            {' '}
            <Box display='flex' flexDirection='column' width='100%'>
              <Box display='flex' width={split === true ? '100%' : '80%'}>
                <AyurvedaObjective
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  isReadOnly={split}
                />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    )
  }
