/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  Typography,
  makeStyles,
  Theme,
  TextField,
} from '@material-ui/core'
import {
  CloseOutlined,
  Delete,
  DeleteOutlineOutlined,
  ClearOutlined,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight, kDialogueBackground } from 'configs/styles/muiThemes'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAddictionsOP,
  resetAddictions,
} from 'redux/fhirMedicalResources/opd/addictions/addictionsAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  breathe,
  drugCount,
  drugOpinion,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { RadioRaisedButton, WelloLoadingIndicator } from 'wello-web-components'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { AllergyMasterChosser } from '../subjective/allergyMasterChosser'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onAddictionAdded: () => void
}
export const AddAddictionOPD: React.FC<Props> = ({
  open,
  onClose,
  onAddictionAdded,
  fhirAppointmentDetails,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '30%',
    },
  }))
  const classes = useStyles()
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [smoke, setSmoke] = useState<R4.ICoding>()
  const [tobaco, setTobaco] = useState<R4.ICoding>()
  const [alcohol, setAlcohol] = useState<R4.ICoding>()
  const [tea, setTea] = useState<R4.ICoding>()
  const [cofee, setCofee] = useState<R4.ICoding>()
  const [drug, setDrug] = useState<R4.ICoding>()
  const dispatch = useDispatch()

  const addAddictionsIPD = useSelector(
    (state: RootState) => state.addAddictionsOPD
  )

  useEffect(() => {
    if (addAddictionsIPD.additionSuccessful) {
      onAddictionAdded()
      dispatch(resetAddictions())
      setSmoke(undefined)
      setTobaco(undefined)
      setTea(undefined)
      setCofee(undefined)
      setDrug(undefined)
      setAlcohol(undefined)
    }
  }, [dispatch, addAddictionsIPD, onAddictionAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setSmoke(undefined)
        setTobaco(undefined)
        setTea(undefined)
        setCofee(undefined)
        setDrug(undefined)
        setAlcohol(undefined)
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Add Addictions</DialogTitle>
      <DialogContent dividers={true}>
        {addAddictionsIPD.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!addAddictionsIPD.adding && (
          <Box marginX={2} marginY={1}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                {addictions.map((val) => (
                  <Box display='flex' flexDirection='column' width='100%'>
                    {val.code === '68518-0' && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='How often do you have a drink containing alcohol' />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          id='hab_13'
                          fullWidth
                          size='small'
                          componentName='unit'
                          autoFocus
                          disableClearable
                          multiple={false}
                          getOptionSelected={(option, valueData) =>
                            option.display === valueData.display
                          }
                          getOptionLabel={(option) => option.display ?? ''}
                          options={alcoholCount}
                          onChange={(e, type, reason) => {
                            if (type) {
                              setAlcohol(type)
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.display}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select'
                              fullWidth
                              variant='outlined'
                              id='hab_13'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                        {/* <WelloSelectFHIRCoding
                          title='How often do you have a drink containing alcohol'
                          availableCodings={alcoholCount}
                          onChanged={(type) => {
                            setAlcohol(type)
                          }}
                          id='addictions_option1'
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        /> */}
                      </Box>
                    )}

                    {/* {val.code === '10000-2' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='How many cups of coffee do you drink per day'
                          availableCodings={teaCount}
                          onChanged={(type) => {
                            setCofee(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        />
                      </Box>
                    )} */}
                    {val.code === '96103-7' && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Tobacco/Paan/Gutkha per day' />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          id='hab_13'
                          fullWidth
                          size='small'
                          componentName='unit'
                          autoFocus
                          disableClearable
                          multiple={false}
                          getOptionSelected={(option, valueData) =>
                            option.display === valueData.display
                          }
                          getOptionLabel={(option) => option.display ?? ''}
                          options={tobacoCount}
                          onChange={(e, type, reason) => {
                            if (type) {
                              setTobaco(type)
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.display}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select'
                              fullWidth
                              variant='outlined'
                              id='hab_13'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                        {/* <WelloSelectFHIRCoding
                          title='Tobacco/Paan/Gutkha per day'
                          availableCodings={tobacoCount}
                          onChanged={(type) => {
                            setTobaco(type)
                          }}
                          id='addictions_option2'
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        /> */}
                      </Box>
                    )}

                    {val.code === '10000-3' && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Do you take drugs' />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          id='hab_13'
                          fullWidth
                          size='small'
                          componentName='unit'
                          autoFocus
                          disableClearable
                          multiple={false}
                          getOptionSelected={(option, valueData) =>
                            option.display === valueData.display
                          }
                          getOptionLabel={(option) => option.display ?? ''}
                          options={drugOpinion}
                          onChange={(e, type, reason) => {
                            if (type) {
                              setDrug(type)
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.display}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select'
                              fullWidth
                              variant='outlined'
                              id='hab_13'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                        {/* <WelloSelectFHIRCoding
                          title='Do you take drugs'
                          id='addictions_option3'
                          availableCodings={drugOpinion}
                          onChanged={(type) => {
                            setDrug(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        /> */}
                      </Box>
                    )}

                    {val.code === '64218-1' && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='How many cigarettes do you smoke per day now' />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          id='hab_13'
                          fullWidth
                          size='small'
                          componentName='unit'
                          autoFocus
                          disableClearable
                          multiple={false}
                          getOptionSelected={(option, valueData) =>
                            option.display === valueData.display
                          }
                          getOptionLabel={(option) => option.display ?? ''}
                          options={smokeCounts}
                          onChange={(e, type, reason) => {
                            if (type) {
                              setSmoke(type)
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.display}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select'
                              fullWidth
                              variant='outlined'
                              id='hab_13'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                        {/* <WelloSelectFHIRCoding
                          title='How many cigarettes do you smoke per day now'
                          availableCodings={smokeCounts}
                          id='addictions_option4'
                          onChanged={(type) => {
                            setSmoke(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        /> */}
                      </Box>
                    )}

                    {/* {val.code === '10000-1' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='How many cups of tea do you drink per day'
                          availableCodings={teaCount}
                          onChanged={(type) => {
                            setTea(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        />
                      </Box>
                    )} */}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              onClose()
              setSmoke(undefined)
              setTobaco(undefined)
              setTea(undefined)
              setCofee(undefined)
              setDrug(undefined)
              setAlcohol(undefined)
            }}
            disabled={addAddictionsIPD.adding}
            variant='outlined'
            disableElevation
            id='cancsel_addictions'
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                addAddictionsOP(
                  fhirAppointmentDetails,
                  smoke,
                  tobaco,
                  alcohol,
                  tea,
                  cofee,
                  drug
                )
              )
            }}
            variant='contained'
            color='primary'
            id='save_addiction'
            disableElevation
            disabled={
              (tea === undefined &&
                cofee === undefined &&
                drug === undefined &&
                alcohol === undefined &&
                smoke === undefined &&
                drug === undefined &&
                tobaco === undefined) ||
              addAddictionsIPD.adding
            }
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
