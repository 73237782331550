import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import formatHumanName from 'wello-fhir-l10n/dist/in/en/formatoverlay-partials/HumanName-format.json'

interface Props {
  open: boolean
  onClose: () => void
}

interface IFormInput {
  patientName: string
  email: string
}
const defaultValues = {
  patientName: '',
  email: '',
  gender: '',
}

export const AddPatientData: React.FC<Props> = ({ open, onClose }) => {
  const dispatch = useDispatch()

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm<IFormInput>()

  const { ref, ...inputProps } = register('patientName', {
    required: formatHumanName.attr_formats['HumanName.family.required'],
    maxLength: formatHumanName.attr_formats['HumanName.family.maxLength'],
    minLength: formatHumanName.attr_formats['HumanName.family.minLength'],
  })
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const methods = useForm<IFormInput>({ defaultValues })
  const { control, setValue, watch } = methods

  //   const { emailRef, ...inputProps } = register('email', {
  //     required: formatHumanName.attr_formats['HumanName.family.required'],
  //     maxLength: formatHumanName.attr_formats['HumanName.family.maxLength'],
  //     minLength: formatHumanName.attr_formats['HumanName.family.minLength'],
  //   })
  const onSubmit = (data: IFormInput) => {}

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        Use of React-hook-forms
      </DialogTitle>
      <DialogContent
        style={{
          padding: 0,
          overflow: 'hidden',
          margin: 0,
        }}
      >
        <Box px={4} display='flex' flexDirection='column'>
          <Box p={0.5} width='35%'>
            <Box py={1} px={0.25} display='flex' flexDirection='row'>
              <Typography
                variant='subtitle2'
                style={{
                  textTransform: 'uppercase',
                }}
              >
                Name
              </Typography>
            </Box>
            <TextField
              variant='outlined'
              size='small'
              error={!!errors.patientName}
              helperText={
                (errors?.patientName?.type === 'maxLength' && (
                  <span>
                    Name should be less than{' '}
                    {formatHumanName.attr_formats['HumanName.given.maxLength']}{' '}
                  </span>
                )) ||
                (errors?.patientName?.type === 'required' && (
                  <span>Name is required</span>
                ))
              }
              inputRef={ref}
              {...inputProps}
            />
          </Box>
          <Box p={0.5} width='35%'>
            <Box py={1} px={0.25} display='flex' flexDirection='row'>
              <Typography
                variant='subtitle2'
                style={{
                  textTransform: 'uppercase',
                }}
              >
                Email
              </Typography>
            </Box>
            <TextField
              variant='outlined'
              size='small'
              type='email'
              {...register('email', {
                required: true,
                pattern:
                  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
              })}
              error={!!errors.email}
              helperText={
                (errors?.email?.type === 'pattern' && (
                  <span>Email is not valid</span>
                )) ||
                (errors?.email?.type === 'required' && (
                  <span>Email is required</span>
                ))
              }
            />
          </Box>

          <Box py={1} px={0.25} display='flex' flexDirection='row' />
          {/* <FormInputDropdown
            name='dropdownValue'
            control={control}
            label='Dropdown Input'
            title='Gender'
          /> */}
        </Box>
      </DialogContent>
      <DialogActions
        style={{ background: useTheme().palette.background.default }}
      >
        <Button onClick={handleSubmit(onSubmit)} variant='contained'>
          {' '}
          Submit{' '}
        </Button>
        <Button onClick={() => reset()} variant='outlined'>
          {' '}
          Reset{' '}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
