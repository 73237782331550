import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import { CloseSharp } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubscriberRelations } from 'utils/constants/relation'
import { addNewMemberToMembership } from 'utils/membershipUtils'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { PatientSelectorWithSearchOption } from 'views/components/patients/patientSelectorBySearch'
import { PatientSmallTile } from 'views/components/patients/patientTileSmallTile'
import { WelloFormItemLabel } from 'wello-web-components'

export interface SecondaryMemberUI {
  patient: R4.IPatient
  relation: R4.ICodeableConcept
  isAllowed: boolean
}

export interface AddNewMemberToMembershipFormProps {
  open: boolean
  onClosed: () => void
  mainCoverageResourceId: string
  mainMemberPatientId: string
  maxNumbersOfMembers?: number
  existingMembers?: string[] | undefined
}
export const AddNewMemberToMembershipForm: React.FC<AddNewMemberToMembershipFormProps> =
  ({
    open,
    onClosed: onClose,
    mainCoverageResourceId,
    mainMemberPatientId,
    existingMembers,
    maxNumbersOfMembers,
  }) => {
    const [reference, setReference] = useState<string | undefined>()

    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

    const [selectedSecondaryMembers, setSelectedSecondaryMembers] = useState<
      SecondaryMemberUI[]
    >([])
    const [hasErrors, setHasErrors] = useState<boolean>(false)
    const language = sessionStorage.getItem('lang')
    const { t, i18n } = useTranslation(['en', 'labelCommon'])

    const isValidData = () => {
      if (selectedSecondaryMembers && selectedSecondaryMembers.length > 0) {
        let res = true

        selectedSecondaryMembers.forEach((member) => {
          if (!member.relation) {
            res = false
          }
          if (!member.patient) {
            res = false
          }
          if (member.relation) {
            if (
              member.relation.coding === undefined ||
              member.relation.coding.length === 0
            ) {
              res = false
            }
          }
        })
        return res
      }
      return false
    }

    function resetDetails() {
      setReference(undefined)

      setSelectedSecondaryMembers([])
    }

    function handleSubmit() {
      if (isValidData()) {
        setFetchingStatus({
          requesting: true,
        })

        addNewMemberToMembership(
          mainCoverageResourceId,
          mainMemberPatientId,
          selectedSecondaryMembers.map((member) => ({
            patientId: member.patient.id!,
            relation: member.relation,
          }))
        )
          .then((e) => {
            if ('status' in e) {
              setFetchingStatus({
                requesting: false,
                requestError: true,
              })
            } else {
              setFetchingStatus({
                requesting: false,
                requestSuccess: true,
              })

              onClose()
            }
          })
          .catch((e) => {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          })
      }
    }

    return (
      <Dialog
        open={open}
        onClose={() => {
          resetDetails()
          onClose()
        }}
        aria-labelledby='responsive-dialog-title'
        maxWidth='sm'
        fullWidth
        disableBackdropClick={true}
      >
        <DialogTitle id='scroll-dialog-title'>Add Membership</DialogTitle>
        <DialogContent dividers={true}>
          <Box display='flex' flexDirection='column' width='100%'>
            {fetchingStatus.requestError && (
              <Box display='flex'>
                <Alert severity='error'>
                  {fetchingStatus.errorDescription ??
                    'Error while adding new member details'}
                </Alert>
              </Box>
            )}
            {fetchingStatus.requestSuccess && (
              <Box display='flex'>
                <Alert severity='success'>
                  'New Members details added successfully'
                </Alert>
              </Box>
            )}

            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              minHeight='400px'
            >
              <WelloFormItemLabel title='Add Secondary Members' />
              {selectedSecondaryMembers.length <=
                (maxNumbersOfMembers ?? 10) && (
                <PatientSelectorWithSearchOption
                  disabled={false}
                  onSelectionChanges={(e) => {
                    setSelectedSecondaryMembers([
                      ...selectedSecondaryMembers,
                      { patient: e, relation: {}, isAllowed: false },
                    ])
                  }}
                  preSelectedComplaints={[
                    ...(existingMembers ?? []),
                    ...selectedSecondaryMembers.map((e) => e.patient.id ?? ''),
                  ]}
                  doctorListId='secondary-member'
                />
              )}
              {selectedSecondaryMembers.length > 0 && (
                <Box display='flex' flexDirection='column' width='100%' py={1}>
                  {selectedSecondaryMembers.map((e, index) => (
                    <Box
                      key={e.patient.id}
                      display='flex'
                      flexDirection='row'
                      alignContent='center'
                      alignItems='center'
                      my={1}
                      style={{
                        backgroundColor: 'white',
                      }}
                    >
                      <PatientSmallTile
                        patient={e.patient}
                        hideAge={true}
                        displayDelete={false}
                        onDelete={() => {
                          setSelectedSecondaryMembers(
                            selectedSecondaryMembers.filter(
                              (mem) => e.patient.id !== mem.patient.id
                            )
                          )
                        }}
                        onSubscriptionDetailsFetched={({
                          isSubscribed,
                          coverage,
                        }: {
                          isSubscribed: boolean
                          coverage?: R4.ICoverage | undefined
                        }) => {
                          setSelectedSecondaryMembers(
                            selectedSecondaryMembers.map((mem) => {
                              if (mem.patient.id === e.patient.id) {
                                return {
                                  ...mem,
                                  isAllowed: !isSubscribed,
                                  relation: {},
                                }
                              }
                              return mem
                            })
                          )
                        }}
                      />
                      <Box width='35%'>
                        <WelloSelectFHIRCoding
                          id='unit_person'
                          preSelectedCoding={e.relation}
                          availableCodings={SubscriberRelations}
                          onChanged={(selctedCoding) => {
                            if (e) {
                              setSelectedSecondaryMembers(
                                selectedSecondaryMembers.map((mem) => {
                                  if (mem.patient.id === e.patient.id) {
                                    return {
                                      ...mem,
                                      relation: {
                                        coding: [selctedCoding],
                                      },
                                    }
                                  }
                                  return mem
                                })
                              )
                            }
                          }}
                          textFieldProps={{
                            size: 'small',
                            label: 'Relation',
                            disabled: fetchingStatus.requesting || !e.isAllowed,
                            style: {
                              border: '0px solid #e0e0e0',
                            },
                          }}
                        />
                      </Box>
                      <IconButton
                        aria-label='remove member'
                        size='small'
                        onClick={() => {
                          setSelectedSecondaryMembers(
                            selectedSecondaryMembers.filter(
                              (mem) => e.patient.id !== mem.patient.id
                            )
                          )
                        }}
                      >
                        <CloseSharp />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {fetchingStatus.requesting && (
            <CircularProgress color='inherit' size={20} />
          )}
          <Button
            onClick={() => {
              resetDetails()
              onClose()
            }}
            variant='outlined'
            disabled={fetchingStatus.requesting}
            disableElevation
          >
            {t('labelCommon:back')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={!isValidData() || fetchingStatus.requesting}
            onClick={() => {
              handleSubmit()
            }}
          >
            Add Member
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
