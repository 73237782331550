import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import moment from 'moment'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { FHIRDefaultApiClient } from 'services/fhirDefaultServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getOnTheFlyWalkInSlotForDoctor } from 'utils/appointment_handle/appointmentCreationHelper'
import {
  getNameFromHumanName,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getAppointmentSuccessfulMessage,
  getChargeItemForSelectedAppointment,
  getProvenanceForTask,
  getTaskForAppointment,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getSlotAmountForAppointment } from 'utils/fhirResoureHelpers/fhirSlotHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getTransactionBodyForOfflinePayment,
  getTransactionBodyResourceAppointment,
  getTransactionBodyResourceAppointmentOnline,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getPatientResourceAfterAdd,
  getPatientResourceFromQuick,
} from 'utils/formHelper'
import { logger } from 'utils/logger'
import { requestAppointmentsCountForToday } from '../appointmentCount/appointmentCountSlice'
import {
  ACTIONS,
  AppointmentManagementStatus,
} from './appointmentManagerStatus'

const initialState: AppointmentManagementStatus = {
  currentAction: ACTIONS.SlotSelection,
  enableContinueButton: false,
  creatingAppointment: false,
  errorWhileCreatingAppointment: false,
  appointmentCreatedSuccessfully: false,
  isWalkIn: false,
  isQuickEntry: false,
  decidingAction: false,
}

const appointmentManagerSlice = createSlice({
  name: 'appointmentManager',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AppointmentManagementStatus>) {
      state.currentAction = action.payload.currentAction
      state.enableContinueButton = action.payload.enableContinueButton
      state.isWalkIn = action.payload.isWalkIn
      state.isQuickEntry = action.payload.isQuickEntry
      state.selectedPatient = action.payload.selectedPatient
      state.selectedSlot = action.payload.selectedSlot
      state.errorReason = action.payload.errorReason
      state.errorWhileCreatingAppointment =
        action.payload.errorWhileCreatingAppointment
      state.creatingAppointment = action.payload.creatingAppointment
      state.createdAppointment = action.payload.createdAppointment
      state.appointmentCreatedSuccessfully =
        action.payload.appointmentCreatedSuccessfully
      state.decidingAction = action.payload.decidingAction
      state.question = action.payload.question
    },
  },
})

export const onSlotSelected =
  (
    activeState: AppointmentManagementStatus,
    selectedSlot: FhirSlotDetail,
    preselectedPatient?: R4.IPatient,
    questionData?: R4.IQuestionnaire
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentManagementStatus = {
      currentAction: ACTIONS.SlotSelection,
      enableContinueButton: true,
      selectedSlot,
      selectedPatient: preselectedPatient ?? activeState.selectedPatient,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      isWalkIn: activeState.isWalkIn,
      question: questionData,
    }
    if (preselectedPatient) {
      state.currentAction = ACTIONS.PatientSelection
    }
    dispatch(appointmentManagerSlice.actions.updatedStatus(state))
  }

export const onWalkInSlotSelected =
  (
    activeState: AppointmentManagementStatus,
    preselectedPatient?: R4.IPatient
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const firstState: AppointmentManagementStatus = {
      currentAction: ACTIONS.PatientSelection,
      enableContinueButton: true,

      isWalkIn: true,
      selectedPatient: preselectedPatient ?? activeState.selectedPatient,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      decidingAction: true,
    }

    dispatch(appointmentManagerSlice.actions.updatedStatus(firstState))

    const slot = await getOnTheFlyWalkInSlotForDoctor(
      getCurrentUserPractitionerRoleDetails().id!
    )
    console.log(slot)
    const question = await getQuestion(getCurrentUserPractitionerRoleDetails())
    const state: AppointmentManagementStatus = {
      currentAction: ACTIONS.PatientSelection,
      enableContinueButton: false,
      selectedSlot: slot,
      question,
      isQuickEntry: true,
      isWalkIn: true,
      selectedPatient: preselectedPatient ?? activeState.selectedPatient,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      decidingAction: false,
    }
    if (preselectedPatient) {
      state.currentAction = ACTIONS.PatientSelection
    }
    dispatch(appointmentManagerSlice.actions.updatedStatus(state))
  }

export const onPatientSelected =
  (
    activeState: AppointmentManagementStatus,
    modifiled: boolean,
    selectedPatients?: R4.IPatient
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentManagementStatus = {
      currentAction: ACTIONS.PatientSelection,
      enableContinueButton: modifiled === true,
      selectedPatient: selectedPatients,
      selectedSlot: activeState.selectedSlot,
      question: activeState.question,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      isWalkIn: activeState.isWalkIn,
      isQuickEntry: activeState.isQuickEntry,
    }
    dispatch(appointmentManagerSlice.actions.updatedStatus(state))
  }

export const onQuickSelected =
  (activeState: AppointmentManagementStatus, isQuickEntry: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentManagementStatus = {
      currentAction: ACTIONS.PatientSelection,
      enableContinueButton: isQuickEntry,
      selectedPatient: activeState.selectedPatient,
      selectedSlot: activeState.selectedSlot,
      question: activeState.question,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      isWalkIn: activeState.isWalkIn,
      isQuickEntry,
    }
    dispatch(appointmentManagerSlice.actions.updatedStatus(state))
  }

export const continueButtonClicked =
  (activeState: AppointmentManagementStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (activeState.currentAction === ACTIONS.SlotSelection) {
      const state: AppointmentManagementStatus = {
        currentAction: ACTIONS.PatientSelection,
        enableContinueButton: false,
        selectedPatient: activeState.selectedPatient,
        selectedSlot: activeState.selectedSlot,
        question: activeState.question,
        creatingAppointment: false,
        errorWhileCreatingAppointment: false,
        appointmentCreatedSuccessfully: false,
        isWalkIn: activeState.isWalkIn,
        isQuickEntry: activeState.isQuickEntry,
      }
      dispatch(appointmentManagerSlice.actions.updatedStatus(state))
    }
  }
export const disableButtonClicked =
  (activeState: AppointmentManagementStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (activeState.currentAction === ACTIONS.SlotSelection) {
      const state: AppointmentManagementStatus = {
        currentAction: activeState.currentAction,
        enableContinueButton: false,
        selectedPatient: activeState.selectedPatient,
        selectedSlot: activeState.selectedSlot,
        creatingAppointment: activeState.creatingAppointment,
        errorWhileCreatingAppointment:
          activeState.errorWhileCreatingAppointment,
        appointmentCreatedSuccessfully:
          activeState.appointmentCreatedSuccessfully,
        isWalkIn: activeState.isWalkIn,
      }
      dispatch(appointmentManagerSlice.actions.updatedStatus(state))
    }
  }

export const resetAppointmentState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: AppointmentManagementStatus = {
      currentAction: ACTIONS.SlotSelection,
      enableContinueButton: false,
      selectedPatient: undefined,
      selectedSlot: undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(appointmentManagerSlice.actions.updatedStatus(state))
  }

export const resetAppointmentStateForModifySlot =
  (
    activeState: AppointmentManagementStatus,
    preselectedPatient?: R4.IPatient
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentManagementStatus = {
      currentAction: ACTIONS.SlotSelection,
      enableContinueButton: false,
      selectedPatient: preselectedPatient,
      selectedSlot: undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      isWalkIn: activeState.isWalkIn,
    }
    dispatch(appointmentManagerSlice.actions.updatedStatus(state))
  }

export const requestAppointment =
  (
    selectedDoctorSlot: FhirSlotDetail,
    selectedPatient: R4.IPatient,
    selectedServiceType: string,
    isPrePaid: boolean,
    fhirAppointment?: FhirAppointmentDetail,
    isWalkIn?: boolean,
    followUp?: boolean,
    isQuickEntry?: boolean,
    questionData?: R4.IQuestionnaire
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentManagementStatus = {
      currentAction: ACTIONS.PatientSelection,
      enableContinueButton: false,
      creatingAppointment: true,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(appointmentManagerSlice.actions.updatedStatus(state))
    console.log(questionData)

    if (isWalkIn) {
      try {
        const currentSlotState: R4.ISlot | undefined = selectedDoctorSlot.slot
        const bundleObject: R4.IBundle = getWalkInAppointmentTransactionObject(
          selectedDoctorSlot,
          isPrePaid,
          selectedServiceType,
          selectedPatient,
          selectedDoctorSlot,
          fhirAppointment,
          followUp,
          questionData
        )
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          const appointmentResponse: R4.IBundle = relatedFhirDecodeRes.right
          let appointment: string = ''
          let paymentReconciliation: string = ''
          if (appointmentResponse.entry) {
            for (let i = 0; i < appointmentResponse.entry.length; i++) {
              const entryResponse = appointmentResponse.entry[i].response
              if (entryResponse) {
                if (entryResponse.location) {
                  if (entryResponse.location.includes('Appointment')) {
                    appointment = entryResponse.location.replace(
                      '/_history/1',
                      ''
                    )
                  }
                  if (
                    entryResponse.location.includes('PaymentReconciliation')
                  ) {
                    paymentReconciliation = entryResponse.location.replace(
                      '/_history/1',
                      ''
                    )
                  }
                }
              }
            }
          }
          if (!isPrePaid) {
            const invResp: boolean = await finishTransaction(
              selectedServiceType,
              selectedDoctorSlot,
              appointment,
              paymentReconciliation,
              'cash',
              'doctor-appointment',
              selectedPatient.id ?? ''
            )
            if (invResp) {
              state.appointmentCreatedSuccessfully = true
              state.creatingAppointment = false
              const linkResponseForFinal: boolean = await handleNotification(
                appointment,
                ''
              )
              dispatch(
                showSuccessAlert(
                  getAppointmentSuccessfulMessage(
                    selectedPatient,
                    selectedDoctorSlot.practitioner,
                    currentSlotState!
                  )
                )
              )
              dispatch(requestAppointmentsCountForToday())
              dispatch(appointmentManagerSlice.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: AppointmentManagementStatus = {
                currentAction: ACTIONS.PatientSelection,
                enableContinueButton: false,
                creatingAppointment: false,
                errorWhileCreatingAppointment: true,
                appointmentCreatedSuccessfully: false,
                errorReason: 'Error',
              }
              dispatch(
                appointmentManagerSlice.actions.updatedStatus(errorSearchDoctor)
              )
            }
          } else {
            const transactionResp: boolean = await createTransactionLink(
              selectedServiceType,
              selectedDoctorSlot,
              appointment,
              paymentReconciliation,
              'online',
              'doctor-appointment',
              selectedPatient.id ?? ''
            )

            if (transactionResp) {
              state.appointmentCreatedSuccessfully = true
              state.creatingAppointment = false

              dispatch(
                showSuccessAlert(
                  getAppointmentSuccessfulMessage(
                    selectedPatient,
                    selectedDoctorSlot.practitioner,
                    currentSlotState!
                  )
                )
              )
              dispatch(requestAppointmentsCountForToday())
              dispatch(appointmentManagerSlice.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: AppointmentManagementStatus = {
                currentAction: ACTIONS.PatientSelection,
                enableContinueButton: false,
                creatingAppointment: false,
                errorWhileCreatingAppointment: true,
                appointmentCreatedSuccessfully: false,
                errorReason: 'Error',
              }
              dispatch(
                appointmentManagerSlice.actions.updatedStatus(errorSearchDoctor)
              )
            }
          }
        }
      } catch (error) {
        logger.error(error)
        const errorSearchDoctor: AppointmentManagementStatus = {
          currentAction: ACTIONS.PatientSelection,
          enableContinueButton: false,
          creatingAppointment: false,
          errorWhileCreatingAppointment: true,
          appointmentCreatedSuccessfully: false,
          errorReason: 'Error',
        }
        dispatch(
          appointmentManagerSlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } else if (followUp) {
      try {
        const currentSlotState: R4.ISlot | undefined =
          await getSlotCurrentState(selectedDoctorSlot.slot)
        if (currentSlotState?.status === R4.SlotStatusKind._free) {
          const bundleObject: R4.IBundle = getAppointmentTransactionObject(
            currentSlotState,
            isPrePaid,
            selectedServiceType,
            selectedPatient,
            selectedDoctorSlot,
            fhirAppointment,
            questionData
          )
          const fhirApi: FHIRApiClient = new FHIRApiClient()
          const response = await fhirApi.doCreateFHIRTransaction(
            '',
            bundleObject
          )
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (relatedFhirDecodeRes._tag === 'Right') {
            const appointmentResponse: R4.IBundle = relatedFhirDecodeRes.right
            let appointment: string = ''
            let paymentReconciliation: string = ''
            if (appointmentResponse.entry) {
              for (let i = 0; i < appointmentResponse.entry.length; i++) {
                const entryResponse = appointmentResponse.entry[i].response
                if (entryResponse) {
                  if (entryResponse.location) {
                    if (entryResponse.location.includes('Appointment')) {
                      appointment = entryResponse.location.replace(
                        '/_history/1',
                        ''
                      )
                    }
                    if (
                      entryResponse.location.includes('PaymentReconciliation')
                    ) {
                      paymentReconciliation = entryResponse.location.replace(
                        '/_history/1',
                        ''
                      )
                    }
                  }
                }
              }
            }
            if (!isPrePaid) {
              const invResp: boolean = await finishTransaction(
                selectedServiceType,
                selectedDoctorSlot,
                appointment,
                paymentReconciliation,
                'cash',
                'doctor-appointment',
                selectedPatient.id ?? ''
              )
              if (invResp) {
                state.appointmentCreatedSuccessfully = true
                state.creatingAppointment = false

                dispatch(
                  showSuccessAlert(
                    getAppointmentSuccessfulMessage(
                      selectedPatient,
                      selectedDoctorSlot.practitioner,
                      currentSlotState
                    )
                  )
                )
                dispatch(requestAppointmentsCountForToday())
                dispatch(appointmentManagerSlice.actions.updatedStatus(state))
              } else {
                const errorSearchDoctor: AppointmentManagementStatus = {
                  currentAction: ACTIONS.PatientSelection,
                  enableContinueButton: false,
                  creatingAppointment: false,
                  errorWhileCreatingAppointment: true,
                  appointmentCreatedSuccessfully: false,
                  errorReason: 'Error',
                }
                dispatch(
                  appointmentManagerSlice.actions.updatedStatus(
                    errorSearchDoctor
                  )
                )
              }
            } else {
              const transactionResp: boolean = await createTransactionLink(
                selectedServiceType,
                selectedDoctorSlot,
                appointment,
                paymentReconciliation,
                'online',
                'doctor-appointment',
                selectedPatient.id ?? ''
              )

              if (transactionResp) {
                state.appointmentCreatedSuccessfully = true
                state.creatingAppointment = false
                const linkResponseForFinal: boolean = await handleNotification(
                  appointment,
                  ''
                )

                dispatch(
                  showSuccessAlert(
                    getAppointmentSuccessfulMessage(
                      selectedPatient,
                      selectedDoctorSlot.practitioner,
                      currentSlotState
                    )
                  )
                )

                dispatch(requestAppointmentsCountForToday())
                dispatch(appointmentManagerSlice.actions.updatedStatus(state))
              } else {
                const errorSearchDoctor: AppointmentManagementStatus = {
                  currentAction: ACTIONS.PatientSelection,
                  enableContinueButton: false,
                  creatingAppointment: false,
                  errorWhileCreatingAppointment: true,
                  appointmentCreatedSuccessfully: false,
                  errorReason: 'Error',
                }
                dispatch(
                  appointmentManagerSlice.actions.updatedStatus(
                    errorSearchDoctor
                  )
                )
              }
            }
          }
        } else {
          // slot is occupied
        }
      } catch (error) {
        logger.error(error)
        const errorSearchDoctor: AppointmentManagementStatus = {
          currentAction: ACTIONS.PatientSelection,
          enableContinueButton: false,
          creatingAppointment: false,
          errorWhileCreatingAppointment: true,
          appointmentCreatedSuccessfully: false,
          errorReason: 'Error',
        }
        dispatch(
          appointmentManagerSlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } else {
      try {
        const currentSlotState: R4.ISlot | undefined =
          await getSlotCurrentState(selectedDoctorSlot.slot)
        if (currentSlotState?.status === R4.SlotStatusKind._free) {
          const bundleObject: R4.IBundle = getAppointmentTransactionObject(
            currentSlotState,
            isPrePaid,
            selectedServiceType,
            selectedPatient,
            selectedDoctorSlot,
            fhirAppointment,
            questionData
          )
          const fhirApi: FHIRApiClient = new FHIRApiClient()
          const response = await fhirApi.doCreateFHIRTransaction(
            '',
            bundleObject
          )
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (relatedFhirDecodeRes._tag === 'Right') {
            const appointmentResponse: R4.IBundle = relatedFhirDecodeRes.right
            let appointment: string = ''
            let paymentReconciliation: string = ''
            if (appointmentResponse.entry) {
              for (let i = 0; i < appointmentResponse.entry.length; i++) {
                const entryResponse = appointmentResponse.entry[i].response
                if (entryResponse) {
                  if (entryResponse.location) {
                    if (entryResponse.location.includes('Appointment')) {
                      appointment = entryResponse.location.replace(
                        '/_history/1',
                        ''
                      )
                    }
                    if (
                      entryResponse.location.includes('PaymentReconciliation')
                    ) {
                      paymentReconciliation = entryResponse.location.replace(
                        '/_history/1',
                        ''
                      )
                    }
                  }
                }
              }
            }
            if (!isPrePaid) {
              const invResp: boolean = await finishTransaction(
                selectedServiceType,
                selectedDoctorSlot,
                appointment,
                paymentReconciliation,
                'cash',
                'doctor-appointment',
                selectedPatient.id ?? ''
              )
              if (invResp) {
                const linkResponse: boolean = await sendAppointmentLink(
                  appointment,
                  selectedPatient.id ?? ''
                )
                if (linkResponse) {
                  state.appointmentCreatedSuccessfully = true
                  state.creatingAppointment = false

                  dispatch(
                    showSuccessAlert(
                      getAppointmentSuccessfulMessage(
                        selectedPatient,
                        selectedDoctorSlot.practitioner,
                        currentSlotState
                      )
                    )
                  )
                } else {
                  state.appointmentCreatedSuccessfully = true
                  state.creatingAppointment = false

                  dispatch(
                    showSuccessAlert(
                      getAppointmentSuccessfulMessage(
                        selectedPatient,
                        selectedDoctorSlot.practitioner,
                        currentSlotState
                      )
                    )
                  )
                  dispatch(showErrorAlert('Sending link failed'))
                }
                const linkResponseForFinal: boolean = await handleNotification(
                  appointment,
                  ''
                )

                dispatch(requestAppointmentsCountForToday())
                dispatch(appointmentManagerSlice.actions.updatedStatus(state))
              } else {
                const errorSearchDoctor: AppointmentManagementStatus = {
                  currentAction: ACTIONS.PatientSelection,
                  enableContinueButton: false,
                  creatingAppointment: false,
                  errorWhileCreatingAppointment: true,
                  appointmentCreatedSuccessfully: false,
                  errorReason: 'Error',
                }
                dispatch(
                  appointmentManagerSlice.actions.updatedStatus(
                    errorSearchDoctor
                  )
                )
              }
            } else {
              const transactionResp: boolean = await createTransactionLink(
                selectedServiceType,
                selectedDoctorSlot,
                appointment,
                paymentReconciliation,
                'online',
                'doctor-appointment',
                selectedPatient.id ?? ''
              )

              if (transactionResp) {
                const linkResponse: boolean = await sendAppointmentLink(
                  appointment,
                  selectedPatient.id ?? ''
                )
                const linkResponseForFinal: boolean = await handleNotification(
                  appointment,
                  ''
                )
                if (linkResponse) {
                  state.appointmentCreatedSuccessfully = true
                  state.creatingAppointment = false

                  dispatch(
                    showSuccessAlert(
                      getAppointmentSuccessfulMessage(
                        selectedPatient,
                        selectedDoctorSlot.practitioner,
                        currentSlotState
                      )
                    )
                  )
                } else {
                  state.appointmentCreatedSuccessfully = true
                  state.creatingAppointment = false

                  dispatch(
                    showSuccessAlert(
                      getAppointmentSuccessfulMessage(
                        selectedPatient,
                        selectedDoctorSlot.practitioner,
                        currentSlotState
                      )
                    )
                  )
                  dispatch(showErrorAlert('Sending link failed'))
                }

                dispatch(requestAppointmentsCountForToday())
                dispatch(appointmentManagerSlice.actions.updatedStatus(state))
              } else {
                const errorSearchDoctor: AppointmentManagementStatus = {
                  currentAction: ACTIONS.PatientSelection,
                  enableContinueButton: false,
                  creatingAppointment: false,
                  errorWhileCreatingAppointment: true,
                  appointmentCreatedSuccessfully: false,
                  errorReason: 'Error',
                }
                dispatch(
                  appointmentManagerSlice.actions.updatedStatus(
                    errorSearchDoctor
                  )
                )
              }
            }
          }
        } else {
          // slot is occupied
        }
      } catch (error) {
        logger.error(error)
        const errorSearchDoctor: AppointmentManagementStatus = {
          currentAction: ACTIONS.PatientSelection,
          enableContinueButton: false,
          creatingAppointment: false,
          errorWhileCreatingAppointment: true,
          appointmentCreatedSuccessfully: false,
          errorReason: 'Error',
        }
        dispatch(
          appointmentManagerSlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
    }
  }

export const requestAppointmentForQuickDataEntry =
  (
    selectedDoctorSlot: FhirSlotDetail,
    firstName: string,
    middleName: string,
    lastName: string,
    phone: string,
    selectedServiceType: string,
    isPrePaid: boolean,
    fhirAppointment?: FhirAppointmentDetail,
    isQuickEntry?: boolean,
    isWalkIn?: boolean,
    referredId?: string,
    isPrimary?: boolean,
    relation?: R4.ICoding,
    question?: R4.IQuestionnaire
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentManagementStatus = {
      currentAction: ACTIONS.PatientSelection,
      enableContinueButton: false,
      creatingAppointment: true,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(appointmentManagerSlice.actions.updatedStatus(state))
    try {
      if (isWalkIn === undefined) {
        const currentSlotState: R4.ISlot | undefined =
          await getSlotCurrentState(selectedDoctorSlot.slot)

        if (currentSlotState?.status === R4.SlotStatusKind._free) {
          const patient: R4.IPatient = getPatientResourceFromQuick(
            firstName,
            middleName,
            lastName,
            phone
          )
          const practRole: R4.IPractitionerRole =
            getCurrentUserPractitionerRoleDetails()
          const transObj: R4.IBundle = getPatientBundle(patient)
          const resource = {
            patient: transObj,
            practitionerRoleId: practRole.id ?? '',
            relatedPatientId: referredId || '',
            isShared: !!referredId,
            primaryPatientId:
              isPrimary && isPrimary === true ? referredId || '' : null,
            relation: relation ? [relation] : [],
          }

          //   const fhirClient: FHIRApiClient = new FHIRApiClient()
          const enRolClient: EnrolCient = new EnrolCient()
          const responseForpatient: any =
            await enRolClient.doCreateEnrolmentFlowRequest(
              '/enrolment/create-patient',
              resource
            )

          const relatedFhirDecodeResForPatient: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(responseForpatient)
          if (relatedFhirDecodeResForPatient._tag === 'Right') {
            const orderResponse: R4.IBundle =
              relatedFhirDecodeResForPatient.right
            let patientId: string = ''

            if (orderResponse.entry) {
              for (let i = 0; i < orderResponse.entry.length; i++) {
                const entryResponse = orderResponse.entry[i].response
                if (entryResponse) {
                  if (entryResponse.location) {
                    if (entryResponse.location.includes('Patient')) {
                      patientId = entryResponse.location.replace(
                        '/_history/1',
                        ''
                      )
                    }
                  }
                }
              }
            }
            const selectedPatient: R4.IPatient = getPatientResourceAfterAdd(
              firstName,
              middleName,
              lastName,
              phone,
              patientId.split('/')[1]
            )

            const bundleObject: R4.IBundle = getAppointmentTransactionObject(
              currentSlotState,
              isPrePaid,
              selectedServiceType,
              selectedPatient,
              selectedDoctorSlot,
              fhirAppointment,
              question
            )
            const fhirApi: FHIRApiClient = new FHIRApiClient()
            const response = await fhirApi.doCreateFHIRTransaction(
              '',
              bundleObject
            )
            const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
              R4.RTTI_Bundle.decode(response)
            if (relatedFhirDecodeRes._tag === 'Right') {
              const appointmentResponse: R4.IBundle = relatedFhirDecodeRes.right
              let appointment: string = ''
              let paymentReconciliation: string = ''
              if (appointmentResponse.entry) {
                for (let i = 0; i < appointmentResponse.entry.length; i++) {
                  const entryResponse = appointmentResponse.entry[i].response
                  if (entryResponse) {
                    if (entryResponse.location) {
                      if (entryResponse.location.includes('Appointment')) {
                        appointment = entryResponse.location.replace(
                          '/_history/1',
                          ''
                        )
                      }
                      if (
                        entryResponse.location.includes('PaymentReconciliation')
                      ) {
                        paymentReconciliation = entryResponse.location.replace(
                          '/_history/1',
                          ''
                        )
                      }
                    }
                  }
                }
              }
              if (!isPrePaid) {
                const invResp: boolean = await finishTransaction(
                  selectedServiceType,
                  selectedDoctorSlot,
                  appointment,
                  paymentReconciliation,
                  'cash',
                  'doctor-appointment',
                  patientId.split('/')[1]
                )
                if (invResp) {
                  const linkResponse: boolean = await sendAppointmentLink(
                    appointment,
                    patientId.split('/')[1]
                  )
                  if (linkResponse) {
                    state.appointmentCreatedSuccessfully = true
                    state.creatingAppointment = false

                    dispatch(
                      showSuccessAlert(
                        getAppointmentSuccessfulMessage(
                          selectedPatient,
                          selectedDoctorSlot.practitioner,
                          currentSlotState
                        )
                      )
                    )
                  } else {
                    state.appointmentCreatedSuccessfully = true
                    state.creatingAppointment = false

                    dispatch(
                      showSuccessAlert(
                        getAppointmentSuccessfulMessage(
                          selectedPatient,
                          selectedDoctorSlot.practitioner,
                          currentSlotState
                        )
                      )
                    )
                    dispatch(showErrorAlert('Sending link failed'))
                  }
                  const linkResponseForFinal: boolean =
                    await handleNotification(
                      appointment,
                      patientId.split('/')[1]
                    )

                  dispatch(requestAppointmentsCountForToday())
                  dispatch(appointmentManagerSlice.actions.updatedStatus(state))
                } else {
                  const errorSearchDoctor: AppointmentManagementStatus = {
                    currentAction: ACTIONS.PatientSelection,
                    enableContinueButton: false,
                    creatingAppointment: false,
                    errorWhileCreatingAppointment: true,
                    appointmentCreatedSuccessfully: false,
                    errorReason: 'Error',
                  }
                  dispatch(
                    appointmentManagerSlice.actions.updatedStatus(
                      errorSearchDoctor
                    )
                  )
                }
              } else {
                const transactionResp: boolean = await createTransactionLink(
                  selectedServiceType,
                  selectedDoctorSlot,
                  appointment,
                  paymentReconciliation,
                  'online',
                  'doctor-appointment',
                  patientId.split('/')[1]
                )

                if (transactionResp) {
                  const linkResponse: boolean = await sendAppointmentLink(
                    appointment,
                    patientId.split('/')[1]
                  )
                  if (linkResponse) {
                    state.appointmentCreatedSuccessfully = true
                    state.creatingAppointment = false

                    dispatch(
                      showSuccessAlert(
                        getAppointmentSuccessfulMessage(
                          selectedPatient,
                          selectedDoctorSlot.practitioner,
                          currentSlotState
                        )
                      )
                    )
                  } else {
                    state.appointmentCreatedSuccessfully = true
                    state.creatingAppointment = false

                    dispatch(
                      showSuccessAlert(
                        getAppointmentSuccessfulMessage(
                          selectedPatient,
                          selectedDoctorSlot.practitioner,
                          currentSlotState
                        )
                      )
                    )
                    dispatch(showErrorAlert('Sending link failed'))
                  }

                  const linkResponseForFinal: boolean =
                    await handleNotification(
                      appointment,
                      patientId.split('/')[1]
                    )

                  dispatch(requestAppointmentsCountForToday())
                  dispatch(appointmentManagerSlice.actions.updatedStatus(state))
                } else {
                  const errorSearchDoctor: AppointmentManagementStatus = {
                    currentAction: ACTIONS.PatientSelection,
                    enableContinueButton: false,
                    creatingAppointment: false,
                    errorWhileCreatingAppointment: true,
                    appointmentCreatedSuccessfully: false,
                    errorReason: 'Error',
                  }
                  dispatch(
                    appointmentManagerSlice.actions.updatedStatus(
                      errorSearchDoctor
                    )
                  )
                }
              }
            }
          }
        } else {
          // slot is occupied
        }
      } else {
        const currentSlotState: R4.ISlot | undefined = selectedDoctorSlot.slot
        const patient: R4.IPatient = getPatientResourceFromQuick(
          firstName,
          middleName,
          lastName,
          phone
        )
        const practRole: R4.IPractitionerRole =
          getCurrentUserPractitionerRoleDetails()
        const transObj: R4.IBundle = getPatientBundle(patient)
        const resource = {
          patient: transObj,
          practitionerRoleId: practRole.id ?? '',
          relatedPatientId: referredId || '',
          isShared: !!referredId,
          primaryPatientId:
            isPrimary && isPrimary === true ? referredId || '' : null,
          relation: relation ? [relation] : [],
        }

        //   const fhirClient: FHIRApiClient = new FHIRApiClient()
        const enRolClient: EnrolCient = new EnrolCient()
        const responseForpatient: any =
          await enRolClient.doCreateEnrolmentFlowRequest(
            '/enrolment/create-patient',
            resource
          )

        const relatedFhirDecodeResForPatient: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(responseForpatient)
        if (relatedFhirDecodeResForPatient._tag === 'Right') {
          const orderResponse: R4.IBundle = relatedFhirDecodeResForPatient.right
          let patientId: string = ''

          if (orderResponse.entry) {
            for (let i = 0; i < orderResponse.entry.length; i++) {
              const entryResponse = orderResponse.entry[i].response
              if (entryResponse) {
                if (entryResponse.location) {
                  if (entryResponse.location.includes('Patient')) {
                    patientId = entryResponse.location.replace(
                      '/_history/1',
                      ''
                    )
                  }
                }
              }
            }
          }
          const selectedPatient: R4.IPatient = getPatientResourceAfterAdd(
            firstName,
            middleName,
            lastName,
            phone,
            patientId.split('/')[1]
          )

          const bundleObject: R4.IBundle =
            getWalkInAppointmentTransactionObject(
              selectedDoctorSlot,
              isPrePaid,
              selectedServiceType,
              selectedPatient,
              selectedDoctorSlot,
              fhirAppointment,
              undefined,
              question
            )

          //   const bundleObject: R4.IBundle = getAppointmentTransactionObject(
          //     currentSlotState,
          //     isPrePaid,
          //     selectedServiceType,
          //     selectedPatient,
          //     selectedDoctorSlot,
          //     fhirAppointment,
          //     question
          //   )
          const fhirApi: FHIRApiClient = new FHIRApiClient()
          const response = await fhirApi.doCreateFHIRTransaction(
            '',
            bundleObject
          )
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (relatedFhirDecodeRes._tag === 'Right') {
            const appointmentResponse: R4.IBundle = relatedFhirDecodeRes.right
            let appointment: string = ''
            let paymentReconciliation: string = ''
            if (appointmentResponse.entry) {
              for (let i = 0; i < appointmentResponse.entry.length; i++) {
                const entryResponse = appointmentResponse.entry[i].response
                if (entryResponse) {
                  if (entryResponse.location) {
                    if (entryResponse.location.includes('Appointment')) {
                      appointment = entryResponse.location.replace(
                        '/_history/1',
                        ''
                      )
                    }
                    if (
                      entryResponse.location.includes('PaymentReconciliation')
                    ) {
                      paymentReconciliation = entryResponse.location.replace(
                        '/_history/1',
                        ''
                      )
                    }
                  }
                }
              }
            }
            if (!isPrePaid) {
              const invResp: boolean = await finishTransaction(
                selectedServiceType,
                selectedDoctorSlot,
                appointment,
                paymentReconciliation,
                'cash',
                'doctor-appointment',
                patientId.split('/')[1]
              )
              if (invResp) {
                const linkResponse: boolean = await sendAppointmentLink(
                  appointment,
                  patientId.split('/')[1]
                )
                if (linkResponse) {
                  state.appointmentCreatedSuccessfully = true
                  state.creatingAppointment = false

                  dispatch(
                    showSuccessAlert(
                      getAppointmentSuccessfulMessage(
                        selectedPatient,
                        selectedDoctorSlot.practitioner,
                        currentSlotState
                      )
                    )
                  )
                } else {
                  state.appointmentCreatedSuccessfully = true
                  state.creatingAppointment = false

                  dispatch(
                    showSuccessAlert(
                      getAppointmentSuccessfulMessage(
                        selectedPatient,
                        selectedDoctorSlot.practitioner,
                        currentSlotState!
                      )
                    )
                  )
                  dispatch(showErrorAlert('Sending link failed'))
                }
                const linkResponseForFinal: boolean = await handleNotification(
                  appointment,
                  patientId.split('/')[1]
                )

                dispatch(requestAppointmentsCountForToday())
                dispatch(appointmentManagerSlice.actions.updatedStatus(state))
              } else {
                const errorSearchDoctor: AppointmentManagementStatus = {
                  currentAction: ACTIONS.PatientSelection,
                  enableContinueButton: false,
                  creatingAppointment: false,
                  errorWhileCreatingAppointment: true,
                  appointmentCreatedSuccessfully: false,
                  errorReason: 'Error',
                }
                dispatch(
                  appointmentManagerSlice.actions.updatedStatus(
                    errorSearchDoctor
                  )
                )
              }
            } else {
              const transactionResp: boolean = await createTransactionLink(
                selectedServiceType,
                selectedDoctorSlot,
                appointment,
                paymentReconciliation,
                'online',
                'doctor-appointment',
                patientId.split('/')[1]
              )

              if (transactionResp) {
                const linkResponse: boolean = await sendAppointmentLink(
                  appointment,
                  patientId.split('/')[1]
                )
                if (linkResponse) {
                  state.appointmentCreatedSuccessfully = true
                  state.creatingAppointment = false

                  dispatch(
                    showSuccessAlert(
                      getAppointmentSuccessfulMessage(
                        selectedPatient,
                        selectedDoctorSlot.practitioner,
                        currentSlotState
                      )
                    )
                  )
                } else {
                  state.appointmentCreatedSuccessfully = true
                  state.creatingAppointment = false

                  dispatch(
                    showSuccessAlert(
                      getAppointmentSuccessfulMessage(
                        selectedPatient,
                        selectedDoctorSlot.practitioner,
                        currentSlotState
                      )
                    )
                  )
                  dispatch(showErrorAlert('Sending link failed'))
                }

                const linkResponseForFinal: boolean = await handleNotification(
                  appointment,
                  patientId.split('/')[1]
                )

                dispatch(requestAppointmentsCountForToday())
                dispatch(appointmentManagerSlice.actions.updatedStatus(state))
              } else {
                const errorSearchDoctor: AppointmentManagementStatus = {
                  currentAction: ACTIONS.PatientSelection,
                  enableContinueButton: false,
                  creatingAppointment: false,
                  errorWhileCreatingAppointment: true,
                  appointmentCreatedSuccessfully: false,
                  errorReason: 'Error',
                }
                dispatch(
                  appointmentManagerSlice.actions.updatedStatus(
                    errorSearchDoctor
                  )
                )
              }
            }
          }
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: AppointmentManagementStatus = {
        currentAction: ACTIONS.PatientSelection,
        enableContinueButton: false,
        creatingAppointment: false,
        errorWhileCreatingAppointment: true,
        appointmentCreatedSuccessfully: false,
        errorReason: 'Error',
      }
      dispatch(appointmentManagerSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export async function finishTransaction(
  selectedServiceType: string,
  slotDetails: FhirSlotDetail,
  serviceId: string,
  paymentReconciliationId: string,
  paymentType: string,
  transactionType: string,
  patientId: string
): Promise<boolean> {
  const requestBody: R4.IParameters = getTransactionBodyForOfflinePayment(
    selectedServiceType,
    slotDetails,
    serviceId,
    paymentReconciliationId,
    paymentType,
    transactionType,
    patientId
  )
  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const fhirApi: EnrolCient = new EnrolCient()
    const response: any = await fhirApi.doCreateEnrolmentFlowRequest(
      `/payment/offline`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.status === 'active') {
      return true
    }
  }

  return false
}

export async function sendAppointmentLink(
  appointmentId: string,
  patientId: string
): Promise<boolean> {
  const requestBody = {
    patientId,
    appointmentId,
  }

  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/invitation/form/pre-appointment`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.includes(process.env.REACT_APP_PRE_APPOINTMENT_URL)) {
      return true
    }
  }

  return false
}

async function handleNotification(
  appointment: string,
  patientId: string
): Promise<boolean> {
  const requestBody = {
    appointmentId: appointment.split('/')[1],
    unitType: 'clinic',
  }

  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/reminder/appointmentReminder`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.includes(process.env.REACT_APP_PRE_APPOINTMENT_URL)) {
      return true
    }
  }

  return false
}

async function createTransactionLink(
  selectedServiceType: string,
  slotDetails: FhirSlotDetail,
  serviceId: string,
  paymentReconciliationId: string,
  paymentType: string,
  transactionType: string,
  patientId: string
): Promise<boolean> {
  const requestBody: R4.IParameters =
    getTransactionBodyResourceAppointmentOnline(
      selectedServiceType,
      slotDetails,
      serviceId,
      paymentReconciliationId,
      paymentType,
      transactionType,
      patientId
    )
  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const fhirApi: EnrolCient = new EnrolCient()
    const response: any = await fhirApi.doCreateEnrolmentFlowRequest(
      `/payment/initiate`,
      requestBody
    )
    logger.info('Payment Response')

    if (response.status === 'created') {
      return true
    }
  }

  return false
}

async function getSlotCurrentState(
  slot: R4.ISlot
): Promise<R4.ISlot | undefined> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doGetResource(`/Slot/${slot.id}`)
  const relatedFhirDecodeRes: E.Either<Errors, R4.ISlot> =
    R4.RTTI_Slot.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const slotResponse: R4.ISlot = relatedFhirDecodeRes.right
    return slotResponse
  }
  return undefined
}

function getWalkInAppointmentTransactionObject(
  currentSlot: FhirSlotDetail,
  isPrePaid: boolean,
  selectedServiceType: string,
  selectedPatient: R4.IPatient,
  selectedDoctorSlot: FhirSlotDetail,
  fhirAppointmentDetail?: FhirAppointmentDetail,
  followUp?: boolean,
  questionData?: R4.IQuestionnaire
): R4.IBundle {
  let appointmentType: string = ''

  const serviceTypeCodeable: R4.ICodeableConcept = {}
  const serviceTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
  const serviceTypeList: React.SetStateAction<R4.ICodeableConcept[]> = []

  appointmentType = 'In-Person'
  const serviceTypeInPerson: R4.ICoding = {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
    code: '556',
    display: 'Walk-in Centre /Non-Emergency',
  }
  serviceTypeCodingList.push(serviceTypeInPerson)
  serviceTypeCodeable.coding = serviceTypeCodingList
  serviceTypeList.push(serviceTypeCodeable)

  //   if (selectedServiceType === '538') {
  //     const serviceTypeInPerson: R4.ICoding = {
  //       system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
  //       code: '556',
  //       display: 'Walk-in Centre /Non-Emergency',
  //     }
  //     serviceTypeCodingList.push(serviceTypeInPerson)
  //     serviceTypeCodeable.coding = serviceTypeCodingList
  //     serviceTypeList.push(serviceTypeCodeable)
  //     appointmentType = 'Phone'
  //   }

  //   if (selectedServiceType === 'video-counselling') {
  //     appointmentType = 'Video'
  //     const serviceTypeVideo: R4.ICoding = {
  //       system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
  //       code: 'video-counselling',
  //       display: 'Video Counselling',
  //     }
  //     serviceTypeCodingList.push(serviceTypeVideo)
  //     serviceTypeCodeable.coding = serviceTypeCodingList
  //     serviceTypeList.push(serviceTypeCodeable)
  //   }

  const requestId: string = getUniqueTempId()

  const appointmentObject: R4.IAppointment = {
    resourceType: 'Appointment',
    id: requestId,
    slot: [
      {
        reference: `${currentSlot.slot.resourceType}/${currentSlot.slot.id}`,
        id: currentSlot.slot.id,
        type: currentSlot.slot.resourceType,
      },
    ],
    serviceType: serviceTypeList,
    start: selectedDoctorSlot.slot.start,
    end: selectedDoctorSlot.slot.end,
    status: R4.AppointmentStatusKind._booked,
    specialty: selectedDoctorSlot.slot.specialty,

    participant: [
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          display: getNameOfPatient(selectedPatient),
          reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
          id: selectedPatient.id,
          type: selectedPatient.resourceType,
        },
      },
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          display: getNameFromHumanName(
            selectedDoctorSlot.practitioner?.name ?? []
          ),
          reference: `${selectedDoctorSlot.practitioner?.resourceType}/${selectedDoctorSlot.practitioner?.id}`,
          id: selectedDoctorSlot.practitioner?.id,
          type: selectedDoctorSlot.practitioner?.resourceType,
        },
      },
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          reference: `${selectedDoctorSlot.practitionerRole?.resourceType}/${selectedDoctorSlot.practitionerRole?.id}`,
          id: selectedDoctorSlot.practitionerRole?.id,
          type: selectedDoctorSlot.practitionerRole?.resourceType,
        },
      },
    ],
  }

  if (questionData) {
    if (appointmentObject.supportingInformation) {
      appointmentObject.supportingInformation.push({
        reference: `${questionData.resourceType}/${questionData.id}`,
        id: questionData.id,
        type: questionData.resourceType,
      })
    } else {
      appointmentObject.supportingInformation = [
        {
          reference: `${questionData.resourceType}/${questionData.id}`,
          id: questionData.id,
          type: questionData.resourceType,
        },
      ]
    }
  }

  if (fhirAppointmentDetail) {
    appointmentObject.appointmentType = {
      coding: [
        {
          code: 'FOLLOWUP',
          system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
          display: 'A follow up visit from a previous appointment',
        },
      ],
    }
    appointmentObject.supportingInformation = [
      {
        reference: `${fhirAppointmentDetail.appointment.resourceType}/${fhirAppointmentDetail.appointment.id}`,
        id: fhirAppointmentDetail.appointment.id,
        type: fhirAppointmentDetail.appointment.resourceType,
      },
    ]
  }

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Slot`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'Slot',
        },
        resource: currentSlot.slot,
      },
      {
        fullUrl: `Schedule`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: `Schedule`,
        },
        resource: currentSlot.schedule,
      },
      {
        fullUrl: 'Appointment',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'Appointment',
        },
        resource: appointmentObject,
      },
    ],
  }

  requestBundle.entry?.push({
    fullUrl: `ChargeItem/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'ChargeItem',
    },
    resource: getChargeItemForSelectedAppointment(
      selectedPatient,
      {
        reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
      },
      selectedDoctorSlot.chargeItemDefiniton,
      undefined,
      undefined,
      followUp
    ),
  })

  //   requestBundle.entry?.push({
  //     fullUrl: `Task/`,
  //     request: {
  //       method: R4.Bundle_RequestMethodKind._post,
  //     },
  //     resource: getChargeItemForSelectedAppointment(
  //       selectedPatient,
  //       {
  //         reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
  //       },
  //       selectedDoctorSlot.chargeItemDefiniton,
  //       undefined,
  //       undefined,
  //       followUp
  //     ),
  //   })
  requestBundle.entry?.push({
    fullUrl: `PaymentReconciliation`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'PaymentReconciliation',
    },
    resource: getPaymentReconciliationResource(
      {
        reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
      },
      {
        currency: 'INR',
        value: getSlotAmountForAppointment(
          selectedDoctorSlot.chargeItemDefiniton
        ),
      },
      isPrePaid
    ),
  })

  return requestBundle
}

function getAppointmentTransactionObject(
  currentSlot: R4.ISlot,
  isPrePaid: boolean,
  selectedServiceType: string,
  selectedPatient: R4.IPatient,
  selectedDoctorSlot: FhirSlotDetail,
  fhirAppointmentDetail?: FhirAppointmentDetail,
  question?: R4.IQuestionnaire
): R4.IBundle {
  console.log(question)
  let appointmentType: string = ''
  const serviceTypeCodeable: R4.ICodeableConcept = {}
  const serviceTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
  const serviceTypeList: React.SetStateAction<R4.ICodeableConcept[]> = []
  if (selectedServiceType === '556') {
    appointmentType = 'In-Person'
    const serviceTypeInPerson: R4.ICoding = {
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
      code: '556',
      display: 'Walk-in Centre /Non-Emergency',
    }
    serviceTypeCodingList.push(serviceTypeInPerson)
    serviceTypeCodeable.coding = serviceTypeCodingList
    serviceTypeList.push(serviceTypeCodeable)
  }

  if (selectedServiceType === '538') {
    const serviceTypeInPerson: R4.ICoding = {
      code: '538',
      display: 'Telephone Counselling',
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
    }
    serviceTypeCodingList.push(serviceTypeInPerson)
    serviceTypeCodeable.coding = serviceTypeCodingList
    serviceTypeList.push(serviceTypeCodeable)
    appointmentType = 'Phone'
  }

  if (selectedServiceType === 'video-counselling') {
    appointmentType = 'Video'
    const serviceTypeVideo: R4.ICoding = {
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
      code: 'video-counselling',
      display: 'Video Counselling',
    }
    serviceTypeCodingList.push(serviceTypeVideo)
    serviceTypeCodeable.coding = serviceTypeCodingList
    serviceTypeList.push(serviceTypeCodeable)
  }

  const requestId: string = getUniqueTempId()

  const appointmentObject: R4.IAppointment = {
    resourceType: 'Appointment',
    id: requestId,
    slot: [
      {
        reference: `${currentSlot.resourceType}/${currentSlot.id}`,
        id: currentSlot.id,
        type: currentSlot.resourceType,
      },
    ],
    serviceType: serviceTypeList,
    start: selectedDoctorSlot.slot.start,
    end: selectedDoctorSlot.slot.end,
    status: R4.AppointmentStatusKind._booked,
    specialty: selectedDoctorSlot.slot.specialty,

    participant: [
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          display: getNameOfPatient(selectedPatient),
          reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
          id: selectedPatient.id,
          type: selectedPatient.resourceType,
        },
      },
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          display: getNameFromHumanName(
            selectedDoctorSlot.practitioner?.name ?? []
          ),
          reference: `${selectedDoctorSlot.practitioner?.resourceType}/${selectedDoctorSlot.practitioner?.id}`,
          id: selectedDoctorSlot.practitioner?.id,
          type: selectedDoctorSlot.practitioner?.resourceType,
        },
      },
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          reference: `${selectedDoctorSlot.practitionerRole?.resourceType}/${selectedDoctorSlot.practitionerRole?.id}`,
          id: selectedDoctorSlot.practitionerRole?.id,
          type: selectedDoctorSlot.practitionerRole?.resourceType,
        },
      },
    ],
  }

  if (question) {
    if (appointmentObject.supportingInformation) {
      appointmentObject.supportingInformation.push({
        reference: `${question.resourceType}/${question.id}`,
        id: question.id,
        type: question.resourceType,
      })
    } else {
      appointmentObject.supportingInformation = [
        {
          reference: `${question.resourceType}/${question.id}`,
          id: question.id,
          type: question.resourceType,
        },
      ]
    }
  }

  if (fhirAppointmentDetail) {
    appointmentObject.appointmentType = {
      coding: [
        {
          code: 'FOLLOWUP',
          system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
          display: 'A follow up visit from a previous appointment',
        },
      ],
    }
    appointmentObject.supportingInformation = [
      {
        reference: `${fhirAppointmentDetail.appointment.resourceType}/${fhirAppointmentDetail.appointment.id}`,
        id: fhirAppointmentDetail.appointment.id,
        type: fhirAppointmentDetail.appointment.resourceType,
      },
    ]
  }
  const modifiedSlot: R4.ISlot = currentSlot
  modifiedSlot.status = R4.SlotStatusKind._busy
  const matchString: string = `W/${JSON.stringify(
    modifiedSlot.meta?.versionId ?? ' '
  )}`
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Slot/${currentSlot.id}`,
        request: {
          ifMatch: matchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Slot/${currentSlot.id}`,
        },
        resource: modifiedSlot,
      },
      {
        fullUrl: 'Appointment',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'Appointment',
        },
        resource: appointmentObject,
      },
    ],
  }

  requestBundle.entry?.push({
    fullUrl: `ChargeItem/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'ChargeItem',
    },
    resource: getChargeItemForSelectedAppointment(
      selectedPatient,
      {
        reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
      },
      selectedDoctorSlot.chargeItemDefiniton
    ),
  })

  const appointmentTask: R4.ITask = getTaskForAppointment(
    selectedPatient,
    {
      reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
    },
    selectedDoctorSlot.chargeItemDefiniton
  )

  requestBundle.entry?.push({
    fullUrl: `Task/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'Task',
    },
    resource: appointmentTask,
  })

  requestBundle.entry?.push({
    fullUrl: `Provenance/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'Provenance',
    },
    resource: getProvenanceForTask(
      selectedPatient,
      {
        reference: `${appointmentTask.resourceType}/${appointmentTask.id}`,
      },
      selectedDoctorSlot.chargeItemDefiniton
    ),
  })
  requestBundle.entry?.push({
    fullUrl: `PaymentReconciliation`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'PaymentReconciliation',
    },
    resource: getPaymentReconciliationResource(
      {
        reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
      },
      {
        currency: 'INR',
        value: getSlotAmountForAppointment(
          selectedDoctorSlot.chargeItemDefiniton
        ),
      },
      isPrePaid
    ),
  })

  return requestBundle
}

export function getPaymentReconciliationResource(
  taskRef: R4.IReference,
  amount: R4.IMoney,
  isPrePaid: boolean
): R4.IPaymentReconciliation {
  const paymentReconciliation: R4.IPaymentReconciliation = {
    resourceType: 'PaymentReconciliation',
    status: 'active',
    paymentAmount: amount,
    requestor: getCurrentUserPractitionerRoleRef(),
    paymentIssuer: getCurrentUserUnitReference(),
    outcome: R4.PaymentReconciliationOutcomeKind._queued,
  }
  if (isPrePaid) {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-ref',
        valueReference: {
          reference: `${taskRef.reference}`,
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'online',
          display: 'Online',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
        valueCoding: {
          code: 'prepaid',
          display: 'Prepaid',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
        },
      },
    ]
    const typeOptions: R4.ICoding[] = [
      {
        code: 'prepaid',
        display: 'Prepaid',
        system: 'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
      },
    ]
    const codeType: R4.ICodeableConcept = {}
    codeType.coding = typeOptions
    paymentReconciliation.detail = [
      {
        type: codeType,
        request: taskRef,
      },
    ]
  } else {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-ref',
        valueReference: {
          reference: `${taskRef.reference}`,
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'cash',
          display: 'Cash',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
        valueCoding: {
          code: 'postpaid',
          display: 'Postpaid',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
        },
      },
    ]
    const typeOptions1: R4.ICoding[] = [
      {
        code: 'postpaid',
        display: 'postpaid',
        system: 'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
      },
    ]
    const codeType1: R4.ICodeableConcept = {}
    codeType1.coding = typeOptions1
    paymentReconciliation.detail = [
      {
        type: codeType1,
        request: taskRef,
      },
    ]
  }

  return paymentReconciliation
}

function getPatientBundle(patientDetails: R4.IPatient): R4.IBundle {
  const seqNo = (Math.floor(Math.random() * 10000) + 10000)
    .toString()
    .substring(1)
  const bundleObject: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `urn:uuid:patient${seqNo}`,
        id: `urn:uuid:patient${seqNo}`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: `Patient`,
        },
        resource: patientDetails,
      },
    ],
  }

  return bundleObject
}

async function getQuestion(
  selectedDoctors: R4.IPractitionerRole
): Promise<R4.IQuestionnaire | undefined> {
  const fhirApi: FHIRDefaultApiClient = new FHIRDefaultApiClient()
  const response: any = await fhirApi.doGetResource(
    `Questionnaire?questionnaire-practitioner=${selectedDoctors.id!}`
  )
  logger.info('ChargeItemDefinition Fetching')
  logger.info(response)
  const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
  if (response) {
    if (response.entry && response.entry.length > 0) {
      return response.entry[0].resource as R4.IQuestionnaire
    }
  }
  return undefined
}

export default appointmentManagerSlice.reducer
