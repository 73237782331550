import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Link,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloGeneralExam } from 'models/WelloConditions'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { requestGeneralExaminationOfPatient } from 'redux/consultation/generalExamination/generalExaminationSlice'
import {
  requestVitalDetailsOfPatient,
  resetVitalSearchState,
} from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { resetAddGeneralExaminationState } from 'redux/fhirMedicalResources/addGeneralExam/addGeneralExamSlice'
import { resetAddVitalsState } from 'redux/fhirMedicalResources/addVital/addVitalSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getClubbing,
  getClubbingRemarks,
  getCyn,
  getCynRemarks,
  getIct,
  getIctRemarks,
  getLymp,
  getLympRemarks,
  getNourishData,
  getPallor,
  getPallorRemarks,
  getPreBuiltData,
} from 'utils/appointment_handle/vitals_util'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getDateWiseVitals,
  getDateWiseVitalsForGeneralExam,
  getDateWiseVitalsForOthers,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { SOAPIndicativeElement } from 'wello-web-components'
import { AddGeneralExamination } from '../addition/addExamination'
import { AddVital } from '../addition/addVitals'
import { EditGeneralExxam } from '../edition/editGeneralExam'
import { GeneralExaminationTile } from './tiles/generalExaminationTile'

interface VitalsProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const GeneralExamination: React.FC<VitalsProp> = ({
  fhirAppointmentDetails,
  split,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)

  const dispatch = useDispatch()
  const vitalsDetailsSlice = useSelector(
    (state: RootState) => state.generalExaminationSearchSlice
  )
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  const [currentCondition, setCurrentCondition] = useState<WelloGeneralExam>({
    id: _.random(1, 10000000).toString(),
    built: '',
    nourishment: '',
    pallor: '',
    pallorRemarks: '',
    ict: '',
    ictRemarks: '',
    cyn: '',
    cynRemarks: '',
    clubbing: '',
    clubbingRemarks: '',
    lymp: '',
    lympRemarks: '',
  })

  const [showEdit, setShowEdit] = useState<boolean>(false)

  function createUpdateConditionData(conditionData: R4.IObservation[]) {
    setCurrentCondition({
      ...currentCondition,
      built:
        getPreBuiltData(conditionData).length > 0
          ? getPreBuiltData(conditionData)
          : 'built',
      nourishment:
        getNourishData(conditionData).length > 0
          ? getNourishData(conditionData)
          : 'wellNourished',
      pallor:
        getPallor(conditionData).length > 0
          ? getPallor(conditionData)
          : 'absent',
      pallorRemarks:
        getPallorRemarks(conditionData).length > 0
          ? getPallorRemarks(conditionData)
          : '',
      ict: getIct(conditionData).length > 0 ? getIct(conditionData) : 'absent',
      ictRemarks:
        getIctRemarks(conditionData).length > 0
          ? getIctRemarks(conditionData)
          : '',
      cyn: getCyn(conditionData).length > 0 ? getCyn(conditionData) : 'absent',
      cynRemarks:
        getCynRemarks(conditionData).length > 0
          ? getCynRemarks(conditionData)
          : '',
      clubbing:
        getClubbing(conditionData).length > 0
          ? getClubbing(conditionData)
          : 'absent',
      clubbingRemarks:
        getClubbingRemarks(conditionData).length > 0
          ? getClubbingRemarks(conditionData)
          : '',
      lymp:
        getLymp(conditionData).length > 0 ? getLymp(conditionData) : 'absent',
      lympRemarks:
        getLympRemarks(conditionData).length > 0
          ? getLympRemarks(conditionData)
          : '',

      existingObs: conditionData,
    })
  }
  useEffect(() => {
    dispatch(resetVitalSearchState())
    dispatch(
      requestGeneralExaminationOfPatient(
        fhirAppointmentDetails.patient,
        fhirAppointmentDetails.appointment.id!
      )
    )
  }, [dispatch, fhirAppointmentDetails])
  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid
          item
          onMouseEnter={() => showAddButton(true)}
          onMouseLeave={() => showAddButton(false)}
        >
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'> General Examination </Typography>
            </Box>{' '}
            {/* {!split && (
              <IconButton
                style={{ padding: '4px' }}
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
              >
                <Add style={{ height: '16px' }} color='primary' />
              </IconButton>
            )} */}
          </Grid>

          {!split && vitalsDetailsSlice.noResultsAvailable && (
            <Box
              display='flex'
              flexGrow
              flexDirection='row'
              justifyContent='flex-start'
            >
              <Tooltip title='' id='gen_1'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  color='primary'
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                  id='gen_2'
                  style={{ padding: 0 }}
                >
                  <Typography
                    variant='subtitle2'
                    color='primary'
                    component={Link}
                    style={{ fontSize: 13 }}
                    id='gen_4'
                  >
                    {' '}
                    General Examination
                  </Typography>
                  <AddCircleIcon
                    style={{ height: '22px', padding: 0 }}
                    color='primary'
                    id='gen_3'
                  />{' '}
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {vitalsDetailsSlice.searchingConditions && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {vitalsDetailsSlice.noResultsAvailable && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {split ? 'No data available' : ''}{' '}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSlice.errorWhileSearchingProcedures && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {vitalsDetailsSlice.errorReason ??
                  'Error while fetching general examination'}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.groupedExamData && (
              <Grid item style={{ padding: 0, margin: 0 }} xs={split ? 12 : 12}>
                <Box
                  flexGrow
                  width='100%'
                  display='flex'
                  flexDirection='column'
                >
                  {vitalsDetailsSlice.groupedExamData.map((val) => (
                    <Box py={1} key={val.date ?? ''}>
                      <Box
                        paddingX={1}
                        borderRadius={2}
                        style={{
                          backgroundColor: 'lightGrey',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Recorded on{' '}
                            {moment(val.date).format('Do MMM YYYY hh:mm A')}
                          </Typography>
                        </Box>
                        {split === false && (
                          <Box
                            justifyContent='flex-end'
                            display='flex'
                            flexGrow={1}
                            alignItems='center'
                          >
                            <Tooltip title='Edit' id='chief_tool_edit'>
                              <IconButton
                                aria-label='btn_ord_cancel'
                                color='primary'
                                id='exam_tool_edit_button'
                                style={{ padding: 0 }}
                                onClick={() => {
                                  createUpdateConditionData(val.occupation)
                                  setShowEdit(true)
                                }}
                              >
                                <img
                                  id='chief_tool_edit_img'
                                  src={`${process.env.PUBLIC_URL}/editVector.png`}
                                  alt='Edit'
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>

                      <Grid
                        container
                        direction='row'
                        id='vitals'
                        style={{
                          backgroundColor: kDialogueBackground,
                        }}
                      >
                        <Grid item xs={3}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Parameter Name
                                </Typography>
                              </Box>
                            </Box>
                            <Box display='flex' width='2%' paddingTop={0.1}>
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30px',

                                  width: '2px',
                                  //   backgroundColor: 'white',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Value
                                </Typography>
                              </Box>
                            </Box>
                            <Box display='flex' width='2%' paddingTop={0.1}>
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30px',

                                  width: '2px',
                                  //   backgroundColor: 'white',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item key={1} xs={7}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Remarks
                                </Typography>
                              </Box>
                            </Box>
                            {/* <Box display='flex' width='2%' paddingTop={0.1}>
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30px',

                                  width: '2px',
                                  backgroundColor: 'white',
                                }}
                              />
                            </Box> */}
                          </Box>
                        </Grid>
                      </Grid>
                      <GeneralExaminationTile obsData={val.occupation} />
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
        </Grid>
        {showText && (
          <NoteDialog
            id='notesTile'
            open={showText}
            onClose={() => {
              setFullText('')
              setShowText(false)
            }}
            notesData={fullText!}
            dialogText='Remarks'
          />
        )}

        {openAddDialogue && (
          <AddGeneralExamination
            open={openAddDialogue}
            preSelectedGeneralExam={
              vitalsDetailsSlice.groupedExamData &&
              vitalsDetailsSlice.groupedExamData.length > 0
                ? vitalsDetailsSlice.groupedExamData[0]
                : undefined
            }
            fhirAppointmentDetails={fhirAppointmentDetails}
            onVitalAdded={(condition) => {
              setOpenAddDialogue(false)
              dispatch(resetAddGeneralExaminationState())
            }}
            onClose={() => {
              setOpenAddDialogue(false)
            }}
          />
        )}

        {showEdit && (
          <EditGeneralExxam
            open={showEdit}
            preSelectedGeneralExam={
              vitalsDetailsSlice.groupedExamData &&
              vitalsDetailsSlice.groupedExamData.length > 0
                ? vitalsDetailsSlice.groupedExamData[0]
                : undefined
            }
            fhirAppointmentDetails={fhirAppointmentDetails}
            onVitalAdded={(condition) => {
              setShowEdit(false)
              dispatch(
                requestGeneralExaminationOfPatient(
                  fhirAppointmentDetails.patient,
                  fhirAppointmentDetails.appointment.id!
                )
              )
            }}
            onClose={() => {
              setShowEdit(false)
            }}
            existingExam={currentCondition}
          />
        )}
      </Grid>
    </ReactVisibilitySensor>
  )
}
