import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Theme,
  makeStyles,
} from '@material-ui/core'
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import { Autocomplete } from '@material-ui/lab'
import {
  CloseOutlined,
  Delete,
  DeleteOutlineOutlined,
  ClearOutlined,
} from '@material-ui/icons'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight, kDialogueBackground } from 'configs/styles/muiThemes'
import {
  WelloLoadingIndicator,
  WelloLoadingPage,
  WelloTextField,
} from 'wello-web-components'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addHistoryConditionDetails,
  resetAddConditionState,
} from 'redux/fhirMedicalResources/addCondition/addConditionSlice'
import { RootState } from 'redux/rootReducer'
import {
  getAgeInDays,
  getAgeInMonths,
  getAgeInWeeks,
  getAgeInYears,
  getAgeOfPatientForChecking,
} from 'utils/fhirResourcesHelper'
import { validateCheckoutDateForChiefComplaints } from 'utils/formValidators'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import {
  durationData,
  durationDataForChiefComplaints,
  durationDataForChiefComplaintsWithOne,
  durationDataWithOne,
} from 'utils/constants'
import { RadioRaisedButton } from 'views/components/LeftMenu/RadioButtons'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { PurposeOfUse } from 'models/purposeOfUse'
import { WelloCondition } from 'models/WelloConditions'
import _ from 'lodash'
import { ConditonMasterChosser } from '../subjective/conditonMasterChosser'
import { ConditionTileForDisplay } from './conditionTile'

interface Props {
  open: boolean
  patient: R4.IPatient
  purposeOfUse: PurposeOfUse
  onClose: () => void
  onMedicalConditionAdded: (createdCondition: R4.ICondition | undefined) => void
  appointmentId?: string
}
export const AddMedicalConditionHandler: React.FC<Props> = ({
  open,
  onClose,
  purposeOfUse,
  onMedicalConditionAdded,
  patient,
  appointmentId,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '35%',
    },
  }))
  const classes = useStyles()
  const { t } = useTranslation(['common', 'condition'])
  const [value, setValue] = React.useState(new Date())
  const [currentCondition, setCurrentCondition] = useState<WelloCondition>({
    id: _.random(1, 10000000).toString(),
    selectedType: 'days',
    days: 1,
    month: 1,
    year: 1,
    week: 1,
    selectedDate: new Date(),
    isActive: true,
  })
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  )

  const [otherData, setOtherData] = useState<string>()
  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [selectedSeverity, setSeverity] = useState<R4.ICoding>()
  const [isActive, setIsActive] = useState<boolean>(true)
  const [isActiveCondition, setIsActiveCondtion] = useState<boolean>(false)
  const handleDateChange = (date: MaterialUiPickersDate) => {
    setCurrentCondition({
      ...currentCondition,
      selectedDate: date !== null ? moment(date).toDate() : new Date(),
    })
  }

  const [addedConditions, setAddedConditions] = useState<WelloCondition[]>([])

  const [typeData, setTypeData] = useState('days')

  const [other, setOther] = useState<boolean>(false)

  function handleMonths(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      month: rate,
    })
  }
  function handleYear(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      year: rate,
    })
  }

  function handleDays(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      days: rate,
    })
  }

  function handleWeeks(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      week: rate,
    })
  }

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloCondition[] = [...addedConditions]
    const newMedication: WelloCondition = {
      ...currentCondition,
    }

    tempMedication.push(newMedication)
    Promise.resolve().then(() => {
      setAddedConditions(tempMedication)
      resetDetails()
    })

    if (pushToSave) {
      dispatch(
        addHistoryConditionDetails(
          patient,
          tempMedication,
          purposeOfUse === PurposeOfUse.OPDAppointment
            ? appointmentId
            : undefined
        )
      )
    }
  }

  function isEnableCreateButton(): boolean | undefined {
    let result: boolean = false

    if (currentCondition.symptomProfile === undefined) {
      result = true
    }
    if (currentCondition.symptomProfile) {
      if (currentCondition.selectedSeverity === undefined) {
        result = true
      }
      if (currentCondition.selectedType) {
        if (
          currentCondition.selectedType === 'days' &&
          currentCondition.days &&
          (currentCondition.days < 1 ||
            currentCondition.days > getAgeInDays(patient))
        ) {
          result = true
        }
        if (
          currentCondition.selectedType === 'months' &&
          currentCondition.month &&
          (currentCondition.month < 1 ||
            currentCondition.month > getAgeInMonths(patient))
        ) {
          result = true
        }
        if (
          currentCondition.selectedType === 'years' &&
          currentCondition.year &&
          (currentCondition.year < 1 ||
            currentCondition.year > getAgeInYears(patient))
        ) {
          result = true
        }
        if (
          currentCondition.selectedType === 'weeks' &&
          currentCondition.week &&
          (currentCondition.week < 1 ||
            currentCondition.week > getAgeInWeeks(patient))
        ) {
          result = true
        }
        if (
          currentCondition.selectedType === 'custom' &&
          currentCondition.selectedDate &&
          validateCheckoutDateForChiefComplaints(
            moment(currentCondition.selectedDate).format('YYYY-MM-DD'),
            getAgeOfPatientForChecking(patient),
            false
          ).length > 0
        ) {
          result = true
        } else {
          result = false
        }
      }
    }

    return result
  }

  const dispatch = useDispatch()
  const [additionalNotes, setAdditionalNotes] = useState<string>()

  const historyConditionSlice = useSelector(
    (state: RootState) => state.historyConditionSlice
  )

  function resetDetails() {
    setSelectedDate(new Date())
    setSelectedCondition(undefined)
    setAdditionalNotes(undefined)
    setSeverity(undefined)
    setIsActive(true)
    setTypeData('days')

    setCurrentCondition({
      id: _.random(1, 10000000).toString(),
      selectedType: 'days',
      days: 1,
      month: 1,
      year: 1,
      week: 1,
      selectedDate: new Date(),
      isActive: true,
    })
  }

  useEffect(() => {
    if (historyConditionSlice.additionSuccessful) {
      onMedicalConditionAdded(historyConditionSlice.condition)
      dispatch(resetAddConditionState())
      setAddedConditions([])
      resetDetails()
    }
  }, [dispatch, historyConditionSlice, onMedicalConditionAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setAddedConditions([])
        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:add_medical')}
      </DialogTitle>
      <DialogContent dividers={true}>
        {historyConditionSlice.adding && (
          <WelloLoadingPage title='Adding details' />
        )}

        {!historyConditionSlice.adding && (
          <Box
            marginX={1}
            marginY={1}
            key={currentCondition.id}
            display='flex'
            flexDirection='column'
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container direction='column' spacing={1} wrap='nowrap'>
                <Grid item>
                  <Grid container direction='column' spacing={1} wrap='nowrap'>
                    <Grid item>
                      <Box display='flex' width='100%' flexDirection='column'>
                        <Box display='flex' flexDirection='row'>
                          <WelloFormItemLabel title='Select Condition' />{' '}
                        </Box>
                        <Box>
                          <ConditonMasterChosser
                            disabled={false}
                            onSelectionChanges={(
                              conditions: R4.ICodeableConcept
                            ) => {
                              if (conditions) {
                                setCurrentCondition({
                                  ...currentCondition,
                                  symptomProfile: conditions,
                                })
                              } else {
                                setCurrentCondition({
                                  ...currentCondition,
                                  condition: undefined,
                                })
                              }
                            }}
                            selectOther={isActiveCondition}
                            id='med_5'
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row'>
                    <Grid
                      item
                      xs={
                        currentCondition.selectedType &&
                        currentCondition.selectedType === 'days'
                          ? 8
                          : currentCondition.selectedType &&
                            currentCondition.selectedType === 'custom'
                          ? 6
                          : 7
                      }
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        paddingTop={1.2}
                      >
                        <WelloFormItemLabel title='Onset Duration' />{' '}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'days' && (
                            <Box paddingLeft={1} width='70%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  id='onset_days'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    currentCondition.days! === 1 ||
                                    currentCondition.week! === 1
                                      ? durationDataWithOne[0]
                                      : durationData[0]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.days! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      id='med_6'
                                      variant='outlined'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                              {/* <WelloSelectUnit
                                id='onset_days'
                                title=''
                                duration='Select Duration Unit'
                                availableCodings={
                                  currentCondition.days! === 1
                                    ? durationDataForChiefComplaintsWithOne
                                    : durationDataForChiefComplaints
                                }
                                onChanged={(type) => {
                                  if (type.display && type.display.length > 0) {
                                    if (
                                      type.display === 'Days' ||
                                      type.display === 'Day'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'days',
                                      })
                                    else if (
                                      type.display === 'Months' ||
                                      type.display === 'Month'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'months',
                                      })
                                    else if (
                                      type.display === 'Weeks' ||
                                      type.display === 'Week'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'weeks',
                                      })
                                    else if (
                                      type.display === 'Years' ||
                                      type.display === 'Year'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'years',
                                      })
                                    else
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'custom',
                                      })
                                  }
                                }}
                                textFieldProps={{
                                  size: 'small',
                                  fullWidth: true,
                                  id: 'med_6',
                                }}
                                preSelectedCoding={
                                  currentCondition.days! === 1 ||
                                  currentCondition.week! === 1
                                    ? durationDataWithOne[0]
                                    : durationData[0]
                                }
                              /> */}
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'weeks' && (
                            <Box paddingLeft={1} width='66%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  id='onset_weeks'
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    currentCondition.week! === 1
                                      ? durationDataWithOne[1]
                                      : durationData[1]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.week! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      variant='outlined'
                                      id='med_7'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'months' && (
                            <Box paddingLeft={1} width='66%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  id='onset_months'
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    currentCondition.month! === 1
                                      ? durationDataWithOne[2]
                                      : durationData[2]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.month! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      variant='outlined'
                                      id='med_9'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                                {/* <WelloSelectUnit
                                  title=''
                                  id='onset_months'
                                  duration='Select Duration Unit'
                                  availableCodings={
                                    currentCondition.month! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChanged={(type) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                    // setDuration(type.display ?? '')
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                    id: 'med_9',
                                  }}
                                  preSelectedCoding={
                                    currentCondition.month! === 1
                                      ? durationDataWithOne[2]
                                      : durationData[2]
                                  }
                                /> */}
                              </Box>
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'years' && (
                            <Box paddingLeft={1} width='66%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  id='onset_years'
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    currentCondition.year! === 1
                                      ? durationDataWithOne[3]
                                      : durationData[3]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.year! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      variant='outlined'
                                      id='med_9'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                                {/* <WelloSelectUnit
                                  title=''
                                  id='onset_years'
                                  duration='Select Duration Unit'
                                  availableCodings={
                                    currentCondition.year! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChanged={(type) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                    // setDuration(type.display ?? '')
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                    id: 'med_9',
                                  }}
                                  preSelectedCoding={
                                    currentCondition.year! === 1
                                      ? durationDataWithOne[3]
                                      : durationData[3]
                                  }
                                /> */}
                              </Box>
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'custom' && (
                            <Box paddingLeft={1} width='60%'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Autocomplete
                                  style={{
                                    borderRadius: '4px',
                                  }}
                                  id='onset_custom'
                                  fullWidth
                                  size='small'
                                  componentName='unit'
                                  autoFocus
                                  disableClearable
                                  defaultValue={
                                    durationDataForChiefComplaints[4]
                                  }
                                  multiple={false}
                                  getOptionSelected={(option, valueData) =>
                                    option.display === valueData.display
                                  }
                                  getOptionLabel={(option) =>
                                    option.display ?? ''
                                  }
                                  options={
                                    currentCondition.days! === 1 &&
                                    currentCondition.month! === 1 &&
                                    currentCondition.week! === 1 &&
                                    currentCondition.year! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChange={(e, type, reason) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  autoComplete
                                  ChipProps={{
                                    deleteIcon: (
                                      <ClearOutlined
                                        style={{
                                          height: '15px',
                                          color: 'white',
                                        }}
                                      />
                                    ),
                                    style: {
                                      backgroundColor: kPrimaryLight,
                                      borderRadius: '4px',
                                    },
                                  }}
                                  renderOption={(option) => (
                                    <Typography variant='subtitle2'>
                                      {option.display}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Duration Unit'
                                      fullWidth
                                      variant='outlined'
                                      id='med_10'
                                      size='small'
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                                {/* <WelloSelectUnit
                                  id='onset_custom'
                                  title=''
                                  duration='Select Duration Unit'
                                  availableCodings={
                                    currentCondition.days! === 1 &&
                                    currentCondition.month! === 1 &&
                                    currentCondition.week! === 1 &&
                                    currentCondition.year! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChanged={(type) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                    // setDuration(type.display ?? '')
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                    id: 'med_10',
                                  }}
                                  preSelectedCoding={
                                    durationDataForChiefComplaints[4]
                                  }
                                /> */}
                              </Box>
                            </Box>
                          )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={
                        currentCondition.selectedType &&
                        currentCondition.selectedType === 'days'
                          ? 4
                          : currentCondition.selectedType &&
                            currentCondition.selectedType === 'custom'
                          ? 6
                          : 5
                      }
                    >
                      {currentCondition.selectedType &&
                        currentCondition.selectedType === 'days' && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            paddingLeft={1}
                            paddingTop={1.2}
                          >
                            <WelloFormItemLabel title='Enter Days' />{' '}
                            <Box width='55%' paddingLeft={1}>
                              <WelloTextFieldWithoutTitle
                                textProps={{
                                  id: `med_11`,
                                  value: currentCondition.days!,

                                  type: 'number',
                                  inputProps: {
                                    max: 365,
                                    min: 1,
                                  },
                                  error:
                                    currentCondition.days! < 1 ||
                                    currentCondition.days! >
                                      getAgeInDays(patient) ||
                                    Number.isNaN(currentCondition.days!),
                                  helperText:
                                    currentCondition.days! < 1 ||
                                    currentCondition.days! >
                                      getAgeInDays(patient) ||
                                    Number.isNaN(currentCondition.days)
                                      ? `Days should be in between 1 to ${getAgeInDays(
                                          patient
                                        )}`
                                      : '',
                                  onChange: (rate) => {
                                    handleDays(parseInt(rate.target.value, 10))
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        )}

                      {currentCondition.selectedType &&
                        currentCondition.selectedType === 'weeks' && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            paddingLeft={1}
                            paddingTop={1.2}
                          >
                            <WelloFormItemLabel title='Enter Weeks' />{' '}
                            <Box paddingLeft={1} width='59.5%'>
                              <WelloTextFieldWithoutTitle
                                textProps={{
                                  id: `med_12`,
                                  value: currentCondition.week!,
                                  type: 'number',
                                  inputProps: {
                                    max: 52,
                                    min: 1,
                                  },
                                  error:
                                    currentCondition.week! < 1 ||
                                    currentCondition.week! >
                                      getAgeInWeeks(patient) ||
                                    Number.isNaN(currentCondition.week!),
                                  helperText:
                                    currentCondition.week! < 1 ||
                                    currentCondition.week! > 52 ||
                                    Number.isNaN(currentCondition.week!)
                                      ? `Weeks should be in between 1 to ${getAgeInWeeks(
                                          patient
                                        )}`
                                      : '',
                                  onChange: (rate) => {
                                    handleWeeks(parseInt(rate.target.value, 10))
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        )}

                      {currentCondition.selectedType &&
                        currentCondition.selectedType === 'months' && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                            paddingLeft={0.5}
                            paddingTop={1.2}
                          >
                            <WelloFormItemLabel title='Enter Months' />{' '}
                            <Box width='57%' paddingLeft={1}>
                              <WelloTextFieldWithoutTitle
                                textProps={{
                                  id: `med_13`,
                                  value: currentCondition.month!,
                                  type: 'number',
                                  inputProps: {
                                    max: 12,
                                    min: 1,
                                  },
                                  error:
                                    currentCondition.month! < 1 ||
                                    currentCondition.month! >
                                      getAgeInMonths(patient) ||
                                    Number.isNaN(currentCondition.month!),
                                  helperText:
                                    currentCondition.month! < 1 ||
                                    currentCondition.month! >
                                      getAgeInMonths(patient) ||
                                    Number.isNaN(currentCondition.month!)
                                      ? `Months should be in between 1 to ${getAgeInMonths(
                                          patient
                                        )}`
                                      : '',
                                  onChange: (rate) => {
                                    handleMonths(
                                      parseInt(rate.target.value, 10)
                                    )
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        )}

                      {currentCondition.selectedType &&
                        currentCondition.selectedType === 'years' && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                            paddingLeft={0.5}
                            paddingTop={1.2}
                          >
                            <WelloFormItemLabel title='Enter Years' />{' '}
                            <Box
                              width={
                                currentCondition.selectedType &&
                                currentCondition.selectedType === 'years'
                                  ? '63%'
                                  : '69%'
                              }
                              paddingLeft={1}
                            >
                              <WelloTextFieldWithoutTitle
                                textProps={{
                                  id: `med_14`,
                                  value: currentCondition.year!,
                                  type: 'number',
                                  inputProps: {
                                    max: 12,
                                    min: 1,
                                  },
                                  error:
                                    currentCondition.year! < 1 ||
                                    currentCondition.year! >
                                      getAgeInYears(patient) ||
                                    Number.isNaN(currentCondition.year!),
                                  helperText:
                                    currentCondition.year! < 1 ||
                                    currentCondition.year! >
                                      getAgeInYears(patient) ||
                                    Number.isNaN(currentCondition.year!)
                                      ? `Years should be in between 1 to ${getAgeInYears(
                                          patient
                                        )}`
                                      : '',
                                  onChange: (rate) => {
                                    handleYear(parseInt(rate.target.value, 10))
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        )}

                      {currentCondition.selectedType &&
                        currentCondition.selectedType === 'custom' && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            paddingTop={1.2}
                          >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <WelloFormItemLabel title='Choose Date' />
                              <Box width='67.3%' paddingLeft={0.5}>
                                <KeyboardDatePicker
                                  clearable
                                  id='date'
                                  tabIndex={0}
                                  maxDate={moment(
                                    currentCondition.selectedDate &&
                                      currentCondition.selectedDate
                                  )}
                                  minDate={moment(
                                    getAgeOfPatientForChecking(patient)
                                  )}
                                  value={
                                    currentCondition.selectedDate
                                      ? currentCondition.selectedDate
                                      : new Date()
                                  }
                                  format='DD-MM-YYYY'
                                  onChange={handleDateChange}
                                  error={
                                    validateCheckoutDateForChiefComplaints(
                                      moment(
                                        currentCondition.selectedDate!
                                      ).format('YYYY-MM-DD'),
                                      getAgeOfPatientForChecking(patient),
                                      false
                                    ).length > 0
                                  }
                                  helperText={validateCheckoutDateForChiefComplaints(
                                    moment(
                                      currentCondition.selectedDate!
                                    ).format('YYYY-MM-DD'),
                                    getAgeOfPatientForChecking(patient),
                                    false
                                  )}
                                  size='small'
                                  inputVariant='outlined'
                                />
                              </Box>
                            </MuiPickersUtilsProvider>
                          </Box>
                        )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box width='100%' flexDirection='row' display='flex'>
                    <Box width='20.5%' py={1.3} paddingLeft={0.3}>
                      <Typography variant='subtitle2'>
                        {t('labelCommon:Status')}{' '}
                      </Typography>
                    </Box>
                    <Box width='50%'>
                      <Typography component='div'>
                        <Box px={0.5}>
                          <Grid component='label' container alignItems='center'>
                            <Box>
                              <Typography variant='subtitle2'>
                                {t('Inactive')}
                              </Typography>
                            </Box>
                            <Box>
                              <Switch
                                checked={currentCondition.isActive!}
                                color='primary'
                                id='med_15'
                                onChange={(val: any, checked) => {
                                  setCurrentCondition({
                                    ...currentCondition,
                                    isActive: checked,
                                  })
                                }}
                                name='checkedA'
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography variant='subtitle2'>
                                Active{' '}
                              </Typography>
                            </Box>
                          </Grid>
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box width='100%' flexDirection='row' display='flex'>
                    <Box width='20.5%' py={1} paddingLeft={0.3}>
                      <Typography variant='subtitle2'>
                        {t('labelCondition:attr_labels.Condition.severity')}
                      </Typography>
                    </Box>

                    <Box width='50%'>
                      <RadioRaisedButton
                        allowChanging={true}
                        availableOptions={[
                          {
                            title: `${t('labelCommon:mild')}`,
                            code: 'mild',
                          },
                          {
                            title: `${t('labelCommon:moderate')}`,
                            code: 'moderate',
                          },
                          {
                            title: `${t('labelCommon:severe')}`,
                            code: 'severe',
                          },
                        ]}
                        displayField='title'
                        onValueChanged={(val) => {
                          setCurrentCondition({
                            ...currentCondition,
                            selectedSeverity: val,
                          })
                        }}
                        valuesField='code'
                        selectedValue={[]}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    paddingRight={0.5}
                  >
                    <WelloFormItemLabel title='Additional Notes' />

                    <TextField
                      size='small'
                      fullWidth
                      id='med_17'
                      multiline
                      type='number'
                      value={additionalNotes}
                      variant='outlined'
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,
                          notes: event.target.value,
                        })
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <Box marginY={1} display='flex' flexDirection='column'>
              {addedConditions && addedConditions.length > 0 && (
                <ConditionTileForDisplay referrals={addedConditions} />
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box
          width='100%'
          justifyContent='flex-end'
          paddingRight={3.4}
          display='flex'
          flexDirection='row'
        >
          <Button
            onClick={() => {
              setAddedConditions([])
              resetDetails()
              onClose()
            }}
            id='med_17'
            variant='outlined'
            disabled={historyConditionSlice.adding}
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>

          <Button
            onClick={() => {
              setOtherData(undefined)
              addCurrentDetails()
            }}
            variant='contained'
            color='primary'
            id='add_more'
            disableElevation
            disabled={currentCondition.symptomProfile === undefined}
          >
            {t('labelCommon:add_more_new')}
          </Button>

          <Button
            onClick={() => {
              if (currentCondition.symptomProfile !== undefined) {
                addCurrentDetails(true)
              } else if (addedConditions.length > 0) {
                dispatch(
                  addHistoryConditionDetails(
                    patient,
                    addedConditions,
                    purposeOfUse === PurposeOfUse.OPDAppointment
                      ? appointmentId
                      : undefined
                  )
                )
              }
            }}
            variant='contained'
            color='primary'
            disableElevation
            id='med_18'
            disabled={
              historyConditionSlice.adding ||
              (isEnableCreateButton() && addedConditions.length === 0)
            }
          >
            {t('labelCommon:Save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
