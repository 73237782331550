import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  continueButtonClicked,
  disableButtonClicked,
  onSlotSelected,
  requestReschedulingAppointment,
  resetAppointmentStateForModifySlot,
  resetRescheduleAppointmentState,
} from 'redux/appointments/rescheduleAppointmentManager/reschedulingAppointmentManagerSlice'
import { RescheduleActions } from 'redux/appointments/rescheduleAppointmentManager/reschedulingAppointmentManagerStatus'
import { resetSlotSelectionStatus } from 'redux/appointments/slotSelectionHandler/practitionerSlotSearchSlice'
import { RootState } from 'redux/rootReducer'
import { WelloLoadingIndicator } from 'wello-web-components'
import { ProviderSlotSelectorNew } from './providerSlotSelector_new'
import { RescheduleConfirmation } from './rescheduleAppointmentConfirmation'

interface Props {
  open: boolean
  onClose: () => void
  onAppointmentCreated: (
    createdAppointment: R4.IAppointment | undefined
  ) => void
  currentAppointment: FhirAppointmentDetail
}
export const RescheduleAppointmentHandler: React.FC<Props> = ({
  open,
  onClose,
  onAppointmentCreated,
  currentAppointment,
}) => {
  const appointmentManagerSlice = useSelector(
    (state: RootState) => state.reschedulingAppointmentManagerSlice
  )
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '50%',
    },
  }))
  const classes = useStyles()
  const selectedAppointmentType = useRef('')
  const [note, setNote] = useState('')
  const [checkPaymentInfo, setCheckPaymentInfo] = useState(false)
  const [selectedCancellationReason, setCancelReason] = useState<R4.ICoding>()
  const dispatch = useDispatch()
  useEffect(() => {
    if (appointmentManagerSlice.appointmentCreatedSuccessfully) {
      if (onAppointmentCreated) {
        onAppointmentCreated(
          appointmentManagerSlice.createdAppointment ?? undefined
        )
      }
    }
  }, [appointmentManagerSlice, onAppointmentCreated])

  function requestForAppointment() {
    if (appointmentManagerSlice.selectedSlot && selectedCancellationReason) {
      dispatch(
        requestReschedulingAppointment(
          appointmentManagerSlice.selectedSlot,
          currentAppointment.patient,
          currentAppointment,
          selectedAppointmentType.current,
          false,
          selectedCancellationReason,
          note
        )
      )
    }
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        {appointmentManagerSlice.currentAction ===
        RescheduleActions.Confirmation
          ? 'Reschedule Appointment '
          : ' Reschedule Appointment > Select New Time Slot'}
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          padding: 0,
          overflow: 'hidden',
          margin: 0,
        }}
      >
        {appointmentManagerSlice.creatingAppointment && (
          <WelloLoadingIndicator message='Requesting for Appointment ' />
        )}
        {appointmentManagerSlice.currentAction ===
          RescheduleActions.SlotSelection &&
          !appointmentManagerSlice.creatingAppointment &&
          currentAppointment.slot && (
            <ProviderSlotSelectorNew
              onSlotSelected={(type: string, selectedSlot?: FhirSlotDetail) => {
                if (selectedSlot) {
                  selectedAppointmentType.current = type
                  dispatch(
                    onSlotSelected(appointmentManagerSlice, selectedSlot)
                  )
                } else {
                  dispatch(disableButtonClicked(appointmentManagerSlice))
                }
              }}
              preSelectedSlot={{
                slot: currentAppointment.slot,
                practitioner:
                  currentAppointment.practitionerDetail.practitioner,
                practitionerRole:
                  currentAppointment.practitionerDetail.practitionerRole,
              }}
            />
          )}
        {appointmentManagerSlice.currentAction ===
          RescheduleActions.Confirmation &&
          appointmentManagerSlice.selectedSlot &&
          !appointmentManagerSlice.creatingAppointment && (
            <RescheduleConfirmation
              selectedServiceType={selectedAppointmentType.current}
              selectedSlot={appointmentManagerSlice.selectedSlot}
              selectedPatient={currentAppointment.patient}
              isReschedulingChargeApplicable={true}
              onPatientNoteTextChanged={(val) => {
                setNote(val)
              }}
              patientNoteText={note}
              onCancelReasonChanged={(reason) => {
                setCancelReason(reason)
              }}
              onInformCheckChanged={(check) => {
                setCheckPaymentInfo(check)
              }}
              onClickModifySlot={(
                selectedSlot: FhirSlotDetail,
                patient?: R4.IPatient
              ) => {
                dispatch(
                  resetAppointmentStateForModifySlot(
                    appointmentManagerSlice,
                    patient
                  )
                )
              }}
              preSelectedCancelReason={selectedCancellationReason}
              preSelectedInformCheck={checkPaymentInfo}
            />
          )}
      </DialogContent>
      <DialogActions
        style={{ background: useTheme().palette.background.default }}
      >
        <Button
          onClick={() => {
            dispatch(resetRescheduleAppointmentState())
            dispatch(resetSlotSelectionStatus())
            onClose()
          }}
          variant='outlined'
          color='primary'
          disableElevation
          id='cancel'
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (
              appointmentManagerSlice.currentAction ===
              RescheduleActions.Confirmation
            ) {
              requestForAppointment()
            } else {
              dispatch(continueButtonClicked(appointmentManagerSlice))
            }
          }}
          variant='contained'
          color='primary'
          disableElevation
          id={
            appointmentManagerSlice.currentAction ===
            RescheduleActions.Confirmation
              ? 'done'
              : 'Continue'
          }
          disabled={
            appointmentManagerSlice.currentAction ===
            RescheduleActions.Confirmation
              ? !(selectedCancellationReason != null)
              : !appointmentManagerSlice.enableContinueButton
          }
        >
          {appointmentManagerSlice.currentAction ===
          RescheduleActions.Confirmation
            ? 'Reschedule Appointment'
            : 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
