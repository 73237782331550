import { R4 } from '@ahryman40k/ts-fhir-types'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleRef } from 'services/userDetailsService'
import { updateDetails } from './instructionPlanHelpers'

export async function addNewImagingPrescriptionsInFhir(
  prescriptionsBundle: R4.IBundle
) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', prescriptionsBundle)

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function addNewImagingPrescriptions(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  imagingCode: R4.ICoding[],
  category?: R4.ICoding
) {
  let result = false
  try {
    const svs = imagingCode.map((code) =>
      getServiceRequestObjectFrom(patientReference, encounterId, code, category)
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: 'ServiceRequest/',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'ServiceRequest',
        },
      })),
    }

    result = await addNewImagingPrescriptionsInFhir(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

function getServiceRequestObjectFrom(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  instruction: R4.ICoding,
  category?: R4.ICoding
): R4.IServiceRequest {
  const communicationRequest: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: patientReference,
    status: 'active',
    priority: 'routine',
    intent: 'proposal',
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '363679005',
            display: 'Imaging',
          },
        ],
      },
    ],
    code: {
      coding: [instruction],
    },
    encounter: encounterId,
    authoredOn: new Date().toISOString(),

    requester: getCurrentUserPractitionerRoleRef(),
  }
  if (category) {
    communicationRequest.orderDetail = [
      {
        coding: [
          {
            system: category.system,
            code: category.code,
            display: category.display,
          },
        ],
      },
    ]
  }
  return communicationRequest
}

export async function UpdateImaging(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  imagingCode: R4.ICoding[],
  existingImaging: R4.IServiceRequest,
  appointmentId: string,
  category?: R4.ICoding
) {
  let result = false
  try {
    const svs = imagingCode.map((code) =>
      getServiceRequestObjectFrom(patientReference, encounterId, code, category)
    )

    const oldImaging: R4.IServiceRequest = { ...existingImaging }
    oldImaging.status = 'entered-in-error'

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: 'ServiceRequest/',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'ServiceRequest',
        },
      })),
    }
    requestBundle.entry?.push({
      resource: oldImaging,
      fullUrl: `${oldImaging.resourceType}/${oldImaging.id}`,
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `${oldImaging.resourceType}/${oldImaging.id}`,
      },
    })

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export function getImagingUrl(category: R4.ICoding): string {
  let url: string = ''

  if (category.code) {
    if (category.code === 'LP6207-7') {
      url =
        'http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-ct-vs'
    }
    if (category.code === 'LP6406-5') {
      url =
        'http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-mri-vs'
    }
    if (category.code === 'LA15415-5') {
      url =
        'http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-usg-vs'
    }
    if (category.code === 'LA9481-8') {
      url =
        'http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-xrays-vs'
    }
  }

  return url
}

export function getImagingCategory(
  imagingReq: R4.IServiceRequest
): R4.ICoding | undefined {
  const details = imagingReq.orderDetail ?? []
  if (details.length > 0) {
    const codingData = details[0].coding ?? []
    if (codingData.length > 0) {
      return codingData[0]
    }
  }
  return undefined
}
