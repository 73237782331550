import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { WelloCondition } from 'models/WelloConditions'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getNameOfPatient } from 'wello-web-components'
import { ConditionAddStatus } from './addSymptomProfileStatus'

const initialState: ConditionAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const historySymptomProfile = createSlice({
  name: 'historySymptomProfile',
  initialState,
  reducers: {
    updateAddConditionStatus(state, action: PayloadAction<ConditionAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.condition = action.payload.condition
    },

    resetPatientDetails(state, action: PayloadAction<ConditionAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.condition = initialState.condition
    },
  },
})

export const addSymptomProfileDetails =
  (
    patientDetails: R4.IPatient,
    addedConditions: WelloCondition[],
    appointmentId?: string,
    isIPD?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: ConditionAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      historySymptomProfile.actions.updateAddConditionStatus(addingState)
    )
    const practitionerDetail = getCurrentUserPractitionerDetails()
    const practitionerRoleDetail = getCurrentUserPractitionerRoleDetails()

    try {
      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: [],
      }

      for (let i = 0; i < addedConditions.length; i++) {
        const condition: R4.ICondition = {
          resourceType: 'Condition',
          meta: {
            profile: [
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndConditionBase',
            ],
          },

          subject: {
            display: getNameOfPatient(patientDetails),
            id: patientDetails.id,
            reference: `${patientDetails.resourceType}/${patientDetails.id}`,
            type: patientDetails.resourceType,
          },

          recorder: {
            display: getNameFromHumanName(practitionerDetail.name ?? []),
            id: practitionerRoleDetail.id,
            reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
            type: practitionerRoleDetail.resourceType,
          },
          recordedDate: new Date().toISOString(),
          category: [
            {
              coding: [
                {
                  code: 'C0439655',
                  display: 'Symptom Profile',
                  system: 'http://terminology.hl7.org/CodeSystem/umls',
                },
              ],
            },
          ],
          verificationStatus: {
            text: 'Unconfirmed',
            coding: [
              {
                code: 'unconfirmed',
                display: 'Unconfirmed',
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-ver-status',
              },
            ],
          },
        }

        if (appointmentId) {
          condition.extension = [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
              valueReference: {
                reference: `Appointment/${appointmentId!}`,
              },
            },
          ]
        }

        if (
          addedConditions[i].selectedType &&
          addedConditions[i].selectedType!.length > 0
        ) {
          if (addedConditions[i].selectedType === 'days') {
            if (addedConditions[i].days && addedConditions[i].days! > 0) {
              condition.onsetAge = {
                system: 'http://unitsofmeasure.org',
                unit: 'd',
                code: 'd',
                value: addedConditions[i].days,
              }
            }
          } else if (addedConditions[i].selectedType === 'months') {
            condition.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'mo',
              code: 'mo',
              value: addedConditions[i].month!,
            }
          } else if (addedConditions[i].selectedType === 'weeks') {
            condition.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'wk',
              code: 'wk',
              value: addedConditions[i].week!,
            }
          } else if (addedConditions[i].selectedType === 'years') {
            condition.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'a',
              code: 'a',
              value: addedConditions[i].year!,
            }
          } else if (addedConditions[i].selectedType === 'custom') {
            if (addedConditions[i].selectedDate)
              condition.onsetDateTime =
                addedConditions[i].selectedDate!.toISOString()
          }
        }
        if (addedConditions[i].notes && addedConditions[i].notes!.length > 0) {
          condition.note = [
            {
              authorReference: {
                reference: `${practitionerDetail.resourceType}/${practitionerDetail.id}`,
              },
              text: addedConditions[i].notes,
              time: new Date().toISOString(),
            },
          ]
        }
        if (addedConditions[i].symptomProfile) {
          condition.code = addedConditions[i].symptomProfile
        }

        const entry: R4.IBundle_Entry = {
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: condition.resourceType,
          },
          resource: condition,
        }
        requestBundle.entry?.push(entry)
      }
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        requestBundle
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Symptom Profile added successfully'))

        dispatch(
          historySymptomProfile.actions.updateAddConditionStatus(addingState)
        )
      } else {
        const errorCreatePersonState: ConditionAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          historySymptomProfile.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: ConditionAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        historySymptomProfile.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetSymptomProfileAddRequest = () => (dispatch: AppDispatch) => {
  dispatch(historySymptomProfile.actions.resetPatientDetails(initialState))
}

export default historySymptomProfile.reducer
