import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { MemberRelation } from 'models/Memberrelation'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sendActorInvitations } from 'redux/administration/userSetup/actorInvitationSlice/actorInvitationSetupSlice'
import { resetUnitAdminAdditionReducerState } from 'redux/administration/userSetup/unitActorInvitationSlice/unitActorInvitationSetupSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserUnitDetails,
  isOrgAdmin,
} from 'services/userDetailsService'
import {
  ClinicUserRole,
  OrgUserRole,
  CollectionCenterRole,
} from 'utils/constants/user_roles'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  validateEmail,
  validateMobileNumber,
  WelloTextField,
} from 'wello-web-components'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
}
export const AddPartnerLabHandler: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
}) => {
  const actorInvitationSetupSlice = useSelector(
    (state: RootState) => state.actorInvitationSetupSlice
  )
  const dispatch = useDispatch()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const [relation, setRelations] = useState<MemberRelation[]>([
    {
      id: getUniqueTempId(),
      roleType: CollectionCenterRole[0],
      practitionerEmail: '',
      practitionerName: '',
      practitionerPhoneNumber: '',
      unitOrganization: getCurrentUserUnitDetails(),
    },
  ])

  function resetDetails() {
    setRelations([
      {
        id: getUniqueTempId(),
        roleType: CollectionCenterRole[0],
        practitionerEmail: '',
        practitionerName: '',
        practitionerPhoneNumber: '',
        unitOrganization: getCurrentUserUnitDetails(),
      },
    ])
  }
  function handleChangeRoleSelection(event: R4.ICoding, index: number) {
    const values: MemberRelation[] = [...relation]
    values[index].roleType = event
    setRelations(values)
  }

  function handleNameChange(event: any, index: number) {
    const values: MemberRelation[] = [...relation]
    values[index].practitionerName = event.target.value
    setRelations(values)
  }
  function handleEmailChange(event: any, index: number) {
    const values: MemberRelation[] = [...relation]
    values[index].practitionerEmail = event.target.value
    setRelations(values)
  }

  function handlePhoneNumberChange(event: any, index: number) {
    const values: MemberRelation[] = [...relation]
    values[index].practitionerPhoneNumber = event
    setRelations(values)
  }

  const handleAddMore = () => {
    const values: MemberRelation[] = [...relation]
    values.push({
      id: getUniqueTempId(),
      roleType: CollectionCenterRole[0],
      practitionerEmail: '',
      practitionerName: '',
      practitionerPhoneNumber: '',
      unitOrganization: getCurrentUserUnitDetails(),
    })
    setRelations(values)
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    relation.forEach((e) => {
      if (!e.practitionerName || e.practitionerName?.length === 0) {
        hasErrorsInData = true
      }
      if (
        !e.practitionerEmail ||
        e.practitionerEmail?.length === 0 ||
        validateEmail(e.practitionerEmail, true).length > 0
      ) {
        hasErrorsInData = true
      }
      if (
        !e.practitionerPhoneNumber ||
        e.practitionerPhoneNumber?.length === 0 ||
        validateMobileNumber(e.practitionerPhoneNumber, true).length > 0
      ) {
        hasErrorsInData = true
      }
    })
    if (!hasErrorsInData) {
      dispatch(sendActorInvitations(relation))
    }
    setHasErrors(hasErrorsInData)
  }

  useEffect(() => {
    // i18n.changeLanguage(language ?? '')
    if (actorInvitationSetupSlice.additionSuccessful) {
      onClose()
      resetDetails()
      dispatch(resetUnitAdminAdditionReducerState())
    }
    return () => {}
  }, [actorInvitationSetupSlice.additionSuccessful, onClose, dispatch])

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetDetails()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:add_partner_lab_title')}
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column'>
          {actorInvitationSetupSlice.error && (
            <Box display='flex'>
              <Alert severity='error'>
                {actorInvitationSetupSlice.errorMessage ??
                  'Error while sending invitations. Please try later'}
              </Alert>
            </Box>
          )}
          {actorInvitationSetupSlice.additionSuccessful && (
            <Box display='flex'>
              <Alert severity='success'>
                {actorInvitationSetupSlice.errorMessage ??
                  'Invitation sent Successfully'}
              </Alert>
            </Box>
          )}
          {!actorInvitationSetupSlice.additionSuccessful && (
            <Box display='flex' flexDirection='column'>
              {relation.map((memRel: MemberRelation, index: number) => (
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='flex-start'
                  key={`unit_actor_small_details_${memRel.id}`}
                >
                  <Box flexGrow={1} p={0.5} width='20%'>
                    <WelloSelectFHIRCoding
                      title={t('labelCommon:unit_person_role_label')}
                      id='partnerLab_unit_person'
                      availableCodings={CollectionCenterRole}
                      onChanged={(e) => {
                        if (e) {
                          handleChangeRoleSelection(e, index)
                        }
                      }}
                      textFieldProps={{
                        size: 'small',
                        disabled: actorInvitationSetupSlice.adding,
                      }}
                      preSelectedCoding={memRel.roleType}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-end'
                    justifyContent='center'
                    p={0.5}
                    width='20%'
                  >
                    <WelloTextField
                      title={t('labelCommon:person_name_label')}
                      textProps={{
                        id: `actor_name_unit_${t}`,
                        value: memRel.practitionerName,
                        disabled: actorInvitationSetupSlice.adding,
                        error:
                          hasErrors &&
                          (!memRel.practitionerName ||
                            memRel.practitionerName?.length === 0),
                        helperText:
                          hasErrors &&
                          (!memRel.practitionerName ||
                            memRel.practitionerName?.length === 0)
                            ? 'Enter valid name'
                            : '',
                        onChange: (changedValue) => {
                          handleNameChange(changedValue, index)
                        },
                      }}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-end'
                    justifyContent='center'
                    p={0.5}
                    width='20%'
                  >
                    <WelloTextField
                      title={t('labelCommon:email_name_label')}
                      textProps={{
                        id: `actor_email_unit`,
                        disabled: actorInvitationSetupSlice.adding,
                        error:
                          hasErrors &&
                          validateEmail(memRel.practitionerEmail, true).length >
                            0,
                        helperText:
                          hasErrors &&
                          validateEmail(memRel.practitionerEmail, true).length >
                            0
                            ? 'Enter valid email address'
                            : '',
                        value: memRel.practitionerEmail,
                        onChange: (changeEmail) => {
                          handleEmailChange(changeEmail, index)
                        },
                      }}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-end'
                    justifyContent='center'
                    p={0.5}
                    width='20%'
                  >
                    <WelloPhoneNumberField
                      textProps={{
                        id: `actor_phone_unit_${t}`,
                        countryCodeEditable: false,
                        defaultCountry: 'in',
                        fullWidth: true,
                        name: t('labelCommon:phone_name_label'),
                        inputMode: 'tel',
                        onlyCountries: ['in'],
                        disableDropdown: false,
                        inputProps: { maxLength: 13 },
                        disabled: actorInvitationSetupSlice.adding,
                        onChange: (
                          e:
                            | React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            | string
                        ) => {
                          if (typeof e === 'string') {
                            handlePhoneNumberChange(e, index)
                          }
                        },
                        regions: ['asia'],
                        autoFormat: false,
                        size: 'small',
                        variant: 'outlined',
                        error:
                          hasErrors &&
                          validateMobileNumber(
                            memRel.practitionerPhoneNumber,
                            true
                          ).length > 0,
                        helperText:
                          hasErrors &&
                          validateMobileNumber(
                            memRel.practitionerPhoneNumber,
                            true
                          ).length > 0
                            ? 'Enter valid phone number'
                            : '',

                        required: true,
                      }}
                      title={t('labelCommon:phone_name_label')}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {!actorInvitationSetupSlice.additionSuccessful && (
            <Box
              display='flex'
              alignItems='center'
              py={0.5}
              flexDirection='row'
            >
              <Box>
                <Button
                  variant='text'
                  onClick={handleAddMore}
                  disabled={actorInvitationSetupSlice.adding}
                >
                  <Typography variant='subtitle2'>
                    {t('labelCommon:add_more_partner')}
                  </Typography>
                </Button>
              </Box>
              <Box flexGrow={1} paddingLeft={2}>
                {' '}
                <Divider />{' '}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {actorInvitationSetupSlice.adding && <CircularProgress />}
        <Button
          onClick={() => {
            resetDetails()
            onClose()
          }}
          variant='outlined'
          disabled={actorInvitationSetupSlice.adding}
          disableElevation
        >
          {t('labelCommon:back')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={actorInvitationSetupSlice.adding}
          onClick={() => {
            handleSubmit()
          }}
        >
          {t('labelCommon:invite_PartnerLab')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
