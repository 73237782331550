import { Box } from '@material-ui/core'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  requestDietPlanDetails,
  resetDietSearchState,
} from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerRoleDetails,
  isAyurvedaDoctor,
} from 'services/userDetailsService'
import { DietPlanManagementOfEncounter } from 'views/components/dietplan/addDietPlan'
import { AddMedicationsHandler } from '../assessment/addMedication'
import { MedicationsList } from '../assessment/medicationsList'
import { AddAyurvedaMedicationsHandler } from './ayurveda/addMedicationForAyurveda'
import { DietPlanList } from './dietPlanList'
import { NextStepPlan } from './nextStepPlan'
import { WeeklyDietPlanList } from './weeklyDietPlanList'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const FullWidthNextStepsOfConsultation: React.FC<NextStepsOfConsultationProps> =
  ({ fhirAppointmentDetails, split }: NextStepsOfConsultationProps) => {
    const { t } = useTranslation(['common', 'mediction'])
    const existingWeeklyDietPlanSearchSlice = useSelector(
      (state: RootState) => state.existingWeeklyDietPlanSearchSlice
    )

    const dietTimeSearchSlice = useSelector(
      (state: RootState) => state.dietTImeSearchSlice
    )
    const [ayurvedaMedicationState, setAyurvedaMedicationState] =
      useState<string>(_.random(0, 10000).toString())

    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<boolean>(false)

    const [openDietPlan, setOpenDietPlan] = useState<boolean>(false)

    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(resetDietSearchState())
    }, [dispatch])

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
          }
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box
            display='flex'
            flexDirection='row'
            padding={0.5}
            flexGrow
            width='100%'
          >
            <NextStepPlan
              planName={
                isAyurvedaDoctor()
                  ? `Oushadhi (${t('labelMedication:resourceName')})`
                  : t('labelMedication:resourceName')
              }
              subString=''
              onClicked={() => {
                setOpenAddMedicationDialogue(true)
              }}
              bottomWidget={
                <MedicationsList
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  split={split}
                />
              }
              /* count={2} */
            />
          </Box>

          <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
            <NextStepPlan
              planName='Diet Plan'
              subString=''
              bottomWidget={
                <Box display='flex' flexDirection='column' width='100%'>
                  <DietPlanList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    onClick={() => {
                      //   setOpenDietPlan(true)
                    }}
                  />
                  <WeeklyDietPlanList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    onClick={() => {
                      console.log('weekly diet plan clicked')
                    }}
                  />
                </Box>
              }
              onClicked={() => {
                if (
                  fhirAppointmentDetails.encounter !== undefined &&
                  fhirAppointmentDetails.encounter.id !== undefined
                ) {
                  if (
                    existingWeeklyDietPlanSearchSlice.dietList === undefined ||
                    existingWeeklyDietPlanSearchSlice.dietList!.length === 0
                  ) {
                    setOpenDietPlan(true)
                  } else {
                    dispatch(showErrorAlert('Weekly Diet plan already added.'))
                  }
                } else {
                  dispatch(
                    showErrorAlert(
                      'No details have been added as part of Appointment. Please add few details about patient visit'
                    )
                  )
                }
              }}
            />
          </Box>

          {openDietPlan && (
            <DietPlanManagementOfEncounter
              practitionerRoleId={getCurrentUserPractitionerRoleDetails().id!}
              open={openDietPlan}
              appointmentStartDate={
                fhirAppointmentDetails.appointment.start
                  ? fhirAppointmentDetails.appointment.start
                  : moment().toISOString()
              }
              encounterID={
                fhirAppointmentDetails.encounter === undefined
                  ? ''
                  : fhirAppointmentDetails.encounter!.id ?? ''
              }
              patientID={fhirAppointmentDetails.patient.id!}
              onClose={() => {
                /*  dispatch(
                                         getWellnessServiceRequestsOfAppointment(
                                             fhirAppointmentDetails
                                         )
                                     ) */
                setOpenDietPlan(false)
                if (
                  fhirAppointmentDetails.encounter &&
                  fhirAppointmentDetails.encounter.id
                )
                  dispatch(
                    requestDietPlanDetails({
                      dietTime: dietTimeSearchSlice.prSelectedCoding ?? [],
                      patientId: fhirAppointmentDetails.patient.id!,
                      encounterId: fhirAppointmentDetails.encounter.id,
                      practitionerRoleId:
                        fhirAppointmentDetails.practitionerDetail
                          .practitionerRole.id,
                      showPlanSuggestedBySameDoctor: true,
                    })
                  )
              }}
              onReferralsAdded={() => {
                if (
                  fhirAppointmentDetails.encounter &&
                  fhirAppointmentDetails.encounter.id
                )
                  dispatch(
                    requestDietPlanDetails({
                      dietTime: dietTimeSearchSlice.prSelectedCoding ?? [],
                      patientId: fhirAppointmentDetails.patient.id!,
                      encounterId: fhirAppointmentDetails.encounter.id,
                      practitionerRoleId:
                        fhirAppointmentDetails.practitionerDetail
                          .practitionerRole.id,
                      showPlanSuggestedBySameDoctor: true,
                    })
                  )
                setOpenDietPlan(false)
              }}
            />
          )}
          {!isAyurvedaDoctor() && (
            <AddMedicationsHandler
              open={openAddMedicationDialogue}
              fhirAppointmentDetails={fhirAppointmentDetails}
              onClose={() => {
                dispatch(getMedicationsOfAppointment(fhirAppointmentDetails))
                setOpenAddMedicationDialogue(false)
              }}
              onMedicalConditionAdded={() => {
                dispatch(getMedicationsOfAppointment(fhirAppointmentDetails))
                setOpenAddMedicationDialogue(false)
              }}
            />
          )}
          {isAyurvedaDoctor() && (
            <AddAyurvedaMedicationsHandler
              open={openAddMedicationDialogue}
              fhirAppointmentDetails={fhirAppointmentDetails}
              onClose={() => {
                dispatch(getMedicationsOfAppointment(fhirAppointmentDetails))
                setOpenAddMedicationDialogue(false)
              }}
              onMedicalConditionAdded={() => {
                dispatch(getMedicationsOfAppointment(fhirAppointmentDetails))
                setOpenAddMedicationDialogue(false)
                setAyurvedaMedicationState(_.random(0, 1000).toString())
              }}
            />
          )}
        </Box>
      </ReactVisibilitySensor>
    )
  }
