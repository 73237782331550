import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Alert, Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addBankAccountDetails,
  resetAccountAdding,
} from 'redux/administration/accounts/newAccount/AddBankAccountSlice'
import {
  addReferralDetails,
  resetReferralAdd,
} from 'redux/administration/referralSetup/referralInvite/addLabReferralSlice'
import { searchUnits } from 'redux/administration/referralSetup/unitSearch/unitSearchSlice'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import { accountTypeForBank } from 'utils/constants'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
}
export const AddAccount: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
}) => {
  const addNewBankAccountForUnit = useSelector(
    (state: RootState) => state.addNewBankAccountForUnit
  )

  const dispatch = useDispatch()

  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const [bankName, setBankName] = React.useState<string>()
  const [accountType, setAccountType] = React.useState<R4.ICoding>(
    accountTypeForBank[0]
  )
  const [accountNo, setBankAccountNo] = React.useState<string>()
  const [panNo, setPanNo] = React.useState<string>()
  const [gstNo, setGstNo] = React.useState<string>()

  function resetDetails() {
    setBankName('')
    setAccountType(accountTypeForBank[0])
    setPanNo(undefined)
    setGstNo(undefined)
    setBankAccountNo(undefined)
  }

  function checkForDisabled(): string[] {
    const errorArr: string[] = []
    if (bankName === undefined) {
      errorArr.push('1')
    }
    if (panNo === undefined) {
      errorArr.push('2')
    }
    if (panNo && (panNo.length === 0 || panNo.length > 10)) {
      errorArr.push('3')
    }
    if (accountNo === undefined) {
      errorArr.push('2')
    }
    if (accountNo && (accountNo.length === 0 || accountNo.length > 11)) {
      errorArr.push('3')
    }
    if (gstNo === undefined) {
      errorArr.push('2')
    }
    return errorArr
  }

  function handleSubmit() {
    dispatch(
      addBankAccountDetails(bankName!, accountType!, accountNo!, panNo!, gstNo!)
    )
  }

  useEffect(() => {
    if (addNewBankAccountForUnit.additionSuccessful) {
      resetDetails()
      dispatch(resetAccountAdding())
      onClose()
    }
    return () => {}
  }, [addNewBankAccountForUnit.additionSuccessful])

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(resetAccountAdding())
        resetDetails()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        Add New Account Details
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='column' width='100%'>
            <Box display='flex' width='100%' flexDirection='column'>
              <WelloFormItemLabel title='Bank Name' />{' '}
              <WelloTextFieldWithoutTitle
                textProps={{
                  id: `chief_id_2`,
                  type: 'text',
                  error: validateOthers(bankName!, true).length > 0,

                  onChange: (changeData) => {
                    setBankName(changeData.target.value)
                  },
                }}
              />
            </Box>
            <WelloSelectFHIRCoding
              title='Account Type'
              availableCodings={accountTypeForBank}
              id='account_type'
              onChanged={(e) => {
                setAccountType(e)
              }}
              preSelectedCoding={accountTypeForBank[0]}
              textFieldProps={{
                size: 'small',
                fullWidth: true,
              }}
            />
            <Box display='flex' width='100%' flexDirection='column'>
              <WelloFormItemLabel title='Bank Account Number' />{' '}
              <WelloTextFieldWithoutTitle
                textProps={{
                  id: `chief_id_3`,
                  type: 'tel',
                  value: accountNo,
                  error:
                    validateOthers(accountNo!, true).length > 0 ||
                    accountNo!.length > 11,
                  onChange: (e) => {
                    const regex = /^[0-9\b]+$/
                    if (e.target.value === '' || regex.test(e.target.value)) {
                      setBankAccountNo(e.target.value)
                    }
                  },
                }}
              />
            </Box>
            <Box display='flex' width='100%' flexDirection='column'>
              <WelloFormItemLabel title='Pan Number' />{' '}
              <WelloTextFieldWithoutTitle
                textProps={{
                  id: `chief_id_3`,
                  type: 'string',
                  error:
                    panNo === undefined ||
                    panNo!.length > 10 ||
                    panNo!.length === 0,

                  onChange: (changeData) => {
                    setPanNo(changeData.target.value)
                  },
                }}
              />
            </Box>
            <Box display='flex' width='100%' flexDirection='column'>
              <WelloFormItemLabel title='GST number' />{' '}
              <WelloTextFieldWithoutTitle
                textProps={{
                  id: `chief_id_3`,
                  type: 'string',
                  error:
                    gstNo === undefined ||
                    gstNo!.length > 9 ||
                    gstNo!.length === 0,

                  onChange: (changeData) => {
                    setGstNo(changeData.target.value)
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {addNewBankAccountForUnit.adding && (
          <CircularProgress color='inherit' size={20} />
        )}
        <Box paddingRight={1.5}>
          <Button
            onClick={() => {
              resetDetails()
              onClose()
            }}
            variant='outlined'
            disabled={addNewBankAccountForUnit.adding}
            disableElevation
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={
              checkForDisabled().length > 0 || addNewBankAccountForUnit.adding
            }
            onClick={() => {
              handleSubmit()
            }}
          >
            Add
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
