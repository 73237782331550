/* eslint-disable no-useless-catch */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'

import { AppDispatch, AppThunk } from 'redux/store'

import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getUnitFromProvidedBy } from 'utils/fhirResoureHelpers/referralHelpers'
import { IncomingLabReferralsListStatus } from './incomingLabReferralsListStatus'

const initialState: IncomingLabReferralsListStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
  selectedStatuses: ['active'],
}

let currentSelectedDate: Date | undefined

const incomingReferralsSliceForLab = createSlice({
  name: 'incomingReferralsSliceForLab',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<IncomingLabReferralsListStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
      state.selectedStatuses = action.payload.selectedStatuses

      state.dateWiseAppointments = action.payload.dateWiseAppointments
      state.pageState = action.payload.pageState
      state.recordsCount = action.payload.recordsCount
    },
  },
})

export const requestForIncomingReferralsListsForLab =
  (
    selectedStatus?: string[],
    pageState?: string,
    offSet?: number,
    existingDateWiseData?: R4.IServiceRequest[],
    recordsCount?: number
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IncomingLabReferralsListStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      selectedStatuses: selectedStatus ?? [],

      dateWiseAppointments: existingDateWiseData,
      pageState,
      recordsCount,
    }
    dispatch(incomingReferralsSliceForLab.actions.updatedStatus(state))
    try {
      const unitName = getCurrentUserUnitDetails().name ?? ''

      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const searchParams: any = {
        perPageCount: 300,
        unitName,
      }

      if (pageState && offSet) {
        searchParams.stateId = pageState
        searchParams.offSetNumber = offSet
      }
      searchParams.gender = getGenderOfDoctor(
        getCurrentUserPractitionerDetails()
      )
      if (selectedStatus && selectedStatus.length > 0) {
        searchParams.status = selectedStatus.join(',')
      }

      const response: any = await getReferralListForLabOrder(
        searchParams.startDate,
        searchParams.status,
        searchParams.perPageCount,
        searchParams.stateId,
        searchParams.offSetNumber,
        searchParams.unitName
      )

      if (response !== undefined) {
        const appointmentResponse: R4.IBundle = response
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const refOrders: R4.IServiceRequest[] = []
          const fhirAppointments: R4.IServiceRequest[] =
            appointmentResponse.entry?.map(
              (e) =>
                (e.resource as R4.IServiceRequest) &&
                (e.resource as R4.IServiceRequest)
            ) ?? []

          for (let i = 0; i < fhirAppointments.length; i++) {
            if (fhirAppointments[i].contained) {
              const { contained } = fhirAppointments[i]
              if (contained && contained.length > 1) {
                const org: string | undefined = getUnitFromProvidedBy(
                  fhirAppointments[i]
                )
                if (org) {
                  refOrders.push(fhirAppointments[i])
                }
              }
            }
          }
          console.log(refOrders)
          state.pageState = appointmentResponse.id
          state.recordsCount = refOrders.length
          state.resultsAvailable = true
          state.searchingAppointments = false

          state.dateWiseAppointments = [
            ...(existingDateWiseData ?? []),
            ...(refOrders ?? []),
          ]
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          dispatch(incomingReferralsSliceForLab.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: IncomingLabReferralsListStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            selectedStatuses: selectedStatus ?? [],

            dateWiseAppointments: existingDateWiseData,
            pageState,
            recordsCount,
          }
          dispatch(
            incomingReferralsSliceForLab.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      }
    } catch (error) {
      console.error('---------error------------')
      console.error(error)

      if (error) {
        if ((error as any).message) {
          if ((error as any).message === 'new param added') {
            return
          }
        }
      }
      const errorSearchDoctor: IncomingLabReferralsListStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching therapies',
        selectedStatuses: selectedStatus ?? [],

        dateWiseAppointments: existingDateWiseData,
        pageState,
        recordsCount,
      }
      dispatch(
        incomingReferralsSliceForLab.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

async function getReferralListForLabOrder(
  startDate: string,
  status?: string,
  perPageCount?: number,
  stateId?: string,
  pageNumber?: number,
  organizationId?: string
): Promise<R4.IBundle | R4.IOperationOutcome | undefined> {
  axios.CancelToken.source()
  if (cancelTokenStore.has('therapiesSearchControlToken')) {
    const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
      'therapiesSearchControlToken'
    )
    controlTokenForSearch.cancel('new param added')
    cancelTokenStore.delete('therapiesSearchControlToken')
  }
  cancelTokenStore.set(
    'therapiesSearchControlToken',
    axios.CancelToken.source()
  )
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()

    if (stateId && perPageCount && pageNumber) {
      const searchParameters: any = {
        _getpages: stateId,
        _sort: '-_lastUpdated',
        _getpagesoffset: pageNumber,
        code: '266753000',
        category: '108252007',
        _count: perPageCount,
      }

      const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
        '/',
        searchParameters,
        (
          cancelTokenStore.get(
            'therapiesSearchControlToken'
          ) as CancelTokenSource
        ).token
      )

      return r
    }

    let searchParameters: any = {}

    if (perPageCount === 0) {
      searchParameters = {
        _count: 300,
        intent: 'proposal',
        code: '266753000',
        category: '108252007',
        _include: ['ServiceRequest:patient'],
        _sort: '-_lastUpdated',
        status: status ?? 'active',
        '_include:iterate': [
          'ServiceRequest:requester',
          'ServiceRequest:instantiates-canonical',
          'PractitionerRole:practitioner',
        ],
      }
    } else {
      searchParameters = {
        _count: perPageCount ?? 500,
        code: '266753000',
        category: '108252007',
        status: status ?? 'active,cancelled',
        _sort: '-_lastUpdated',
        _include: ['ServiceRequest:patient'],
        '_include:iterate': [
          'ServiceRequest:requester',
          'ServiceRequest:instantiates-canonical',
          'PractitionerRole:practitioner',
        ],
      }
    }
    searchParameters.performer = `Organization/${
      getCurrentUserUnitDetails().id
    }`

    const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
      '/ServiceRequest',
      searchParameters
    )

    const completeDate = r

    return completeDate
  } catch (error) {
    throw error
  }

  return undefined
}

export default incomingReferralsSliceForLab.reducer
