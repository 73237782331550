import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { getPrescriptionDetails } from 'redux/prescription/medicationsListSlice/prescriptionSearchSlice'
import { RootState } from 'redux/rootReducer'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { html2pdf } from 'html2pdf-ts'
import { AyurvedaPrescriptionData } from './AyurvddeaPrescription'
import { PrescriptionData } from './prescriptionData'

interface Props {
  open: boolean
  appointmentDetails: FhirAppointmentDetail
  onClose: () => void
}
export const Prescription: React.FC<Props> = ({
  open,
  appointmentDetails,
  onClose,
}) => {
  const dispatch = useDispatch()
  const prescriptionSearchSlice = useSelector(
    (state: RootState) => state.prescriptionSearchSlice
  )
  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )
  const [showSysmptoms, setShowSymptoms] = useState<boolean>(true)
  const [showPhone, setShowPhone] = useState<boolean>(true)
  const pageStyle = `
  @page {
    size: A4 portrait;
  }`

  const handleAfterPrint = () => {
    // Access iframe content after printing
    const iframe = document.getElementById('pdf-viewer')
    if (iframe !== null) {
      const iframeContent = iframe!.innerHTML
      console.log('Printed content:', iframeContent)
    }
  }

  const componentRef = useRef<any>()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => handleAfterPrint(),
    // print: async (printIframe) => {
    //   const document = printIframe.contentDocument
    //   if (document) {
    //     const html = document.getElementsByClassName(
    //       'App'
    //     )[0] as unknown as string
    //     const options = {
    //       margin: 0,
    //       filename: 'the-joys-of-buying-over-building.pdf',
    //       format: {},
    //       landscape: false,
    //       resolution: {
    //         height: 1920,
    //         width: 1080,
    //       },
    //     }
    //     const exporter = await html2pdf.createPDF(html, options)
    //   }
    // },
    pageStyle: `@media print {
        @page {
            size: auto;
          margin: 0;
        }
        ::-webkit-scrollbar {
            display: none;
        }
      
      }`,
  })

  const { t } = useTranslation(['common'])

  const handleChangeForSymptoms = (event: any) => {
    setShowSymptoms(event.target.checked)
  }

  const handleChangeForPhone = (event: any) => {
    setShowPhone(event.target.checked)
  }

  useEffect(() => {
    if (open) {
      dispatch(
        getPrescriptionDetails(
          appointmentDetails,
          dietTimeSearchSlice.prSelectedCoding ?? []
        )
      )
    }
  }, [appointmentDetails, dispatch, open])

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>View Prescription</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          //   overflow: 'auto',
          backgroundColor: '#FFFFFF',
          padding: 0,
        }}
      >
        {prescriptionSearchSlice.fetchingImpressions && (
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            alignContent='center'
            alignItems='center'
            justifyContent='center'
            justifyItems='center'
          >
            <CircularProgress size={28} />
          </Box>
        )}

        {prescriptionSearchSlice.resultsAvailable &&
          prescriptionSearchSlice.medications && (
            <Box width='100%' display='flex' flexDirection='column'>
              <SimpleBar
                style={{
                  height: '100%',
                  width: '100%',
                }}
              >
                {isAyurvedaDoctor() && (
                  <AyurvedaPrescriptionData
                    appointmentDetails={appointmentDetails}
                    refData={componentRef}
                    planData={prescriptionSearchSlice.medications}
                    symptoms={showSysmptoms}
                    phone={showPhone}
                  />
                )}

                {isAyurvedaDoctor() === false && (
                  <PrescriptionData
                    appointmentDetails={appointmentDetails}
                    refData={componentRef}
                    planData={prescriptionSearchSlice.medications}
                    symptoms={showSysmptoms}
                    phone={showPhone}
                  />
                )}
              </SimpleBar>
            </Box>
          )}
      </DialogContent>
      <DialogActions>
        {prescriptionSearchSlice.resultsAvailable &&
          prescriptionSearchSlice.medications && (
            <Box py={1} display='flex' flexDirection='row' px={3.5}>
              <Checkbox
                edge='start'
                checked={showSysmptoms}
                onChange={handleChangeForSymptoms}
                tabIndex={-1}
                size='small'
                disableRipple
                color='primary'
                inputProps={{ 'aria-labelledby': 'consent' }}
                id='symptoms'
              />
              <Box py={1.3}>
                <Typography variant='subtitle2' color='primary'>
                  Show Symptoms
                </Typography>
              </Box>
            </Box>
          )}
        {prescriptionSearchSlice.resultsAvailable &&
          prescriptionSearchSlice.medications && (
            <Box py={1} display='flex' flexDirection='row' px={3.5}>
              <Checkbox
                edge='start'
                checked={showPhone}
                onChange={handleChangeForPhone}
                tabIndex={-1}
                size='small'
                disableRipple
                color='primary'
                inputProps={{ 'aria-labelledby': 'consent' }}
                id='phone'
              />
              <Box py={1.3}>
                <Typography variant='subtitle2' color='primary'>
                  Show Phone No.
                </Typography>
              </Box>
            </Box>
          )}
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            handlePrint()
          }}
          disabled={prescriptionSearchSlice.fetchingImpressions}
        >
          Print Prescription
        </Button>
      </DialogActions>
    </Dialog>
  )
}
