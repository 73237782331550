/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'
import {
  addAppetite,
  resetAddAppetite,
} from 'redux/fhirMedicalResources/ipd/AddAppetite/appetiteAddSlice'
import {
  addBowels,
  resetAddBowels,
} from 'redux/fhirMedicalResources/ipd/AddBowels/bowelsAddSlice'
import {
  addDiet,
  resetAddDiet,
} from 'redux/fhirMedicalResources/ipd/diet/dietAddSlice'
import {
  addMicturition,
  resetAddMicturition,
} from 'redux/fhirMedicalResources/ipd/Micturition/micturitionAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  appetite,
  bowels,
  breathe,
  dietType,
  drugCount,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  micturition,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  RadioRaisedButton,
  WelloFormItemLabel,
  WelloLoadingIndicator,
} from 'wello-web-components'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onDietAdd: () => void
}
export const AddBowels: React.FC<Props> = ({
  open,
  onClose,
  onDietAdd,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [diet, setDiet] = useState<R4.ICoding>()
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const dispatch = useDispatch()
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '30%',
    },
  }))
  const classes = useStyles()

  const bowelsAddSlice = useSelector((state: RootState) => state.bowelsAddSlice)

  useEffect(() => {
    if (bowelsAddSlice.additionSuccessful) {
      onDietAdd()
      dispatch(resetAddBowels())
      setDiet(undefined)
      setAdditionalNotes('')
    }
  }, [dispatch, bowelsAddSlice, onDietAdd])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setDiet(undefined)
        setAdditionalNotes('')
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'> Add Bowels</DialogTitle>
      <DialogContent dividers={true}>
        {bowelsAddSlice.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!bowelsAddSlice.adding && (
          <Box marginX={2} marginY={1}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <WelloSelectFHIRCodingWithAutocomplete
                      title='Bowels'
                      id='bow_5'
                      availableCodings={bowels}
                      onChanged={(type) => {
                        setDiet(type)
                      }}
                      //   textFieldProps={{
                      //     size: 'small',
                      //     fullWidth: true,
                      //   }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Additional Notes' />
                  </Grid>
                  <Grid item style={{ paddingBottom: '16px' }}>
                    <TextField
                      size='small'
                      fullWidth
                      id='bow_6'
                      multiline
                      type='number'
                      value={additionalNotes}
                      variant='outlined'
                      onChange={(event) => {
                        setAdditionalNotes(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              onClose()
              setDiet(undefined)
              setAdditionalNotes('')
            }}
            id='bow_7'
            variant='outlined'
            disabled={bowelsAddSlice.adding}
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                addBowels(fhirAppointmentDetails, additionalNotes ?? '', diet)
              )
            }}
            id='bow_8'
            variant='contained'
            color='primary'
            disableElevation
            disabled={diet === undefined || bowelsAddSlice.adding}
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
