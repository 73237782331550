import { Avatar, Box, CircularProgress, Typography } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getContentOfResource,
  getProfileContentOfResource,
} from 'services/fileServices'
import { SelectedFile, WelloFilePicker } from '../LeftMenu/WelloFilePicker'

export interface AttachmentImageViewer {
  imagePath: string
  existingFile: boolean
  resourceId: string
  decodingRequired: boolean
  resourceType: string
  contentType: string
  onSelectionChanged: (selectedFile: SelectedFile) => void
  onDataFetched?: (data: string) => void
  update: boolean
  purpose: string
  prescription?: boolean
  name?: string
}

export const ProfilePicViewer: React.FC<AttachmentImageViewer> = ({
  imagePath,
  existingFile,
  resourceId,
  decodingRequired,
  resourceType,
  contentType,
  onSelectionChanged,
  onDataFetched,
  update,
  purpose,
  prescription,
  name,
}) => {
  const { t } = useTranslation()
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })

  const [imgSrc, setImgSrc] = useState<string>()

  function fetchInvoice() {
    setFetchingStatus({
      requesting: true,
    })

    getProfileContentOfResource(
      resourceType,
      '',
      resourceId,
      contentType,
      imagePath,
      decodingRequired
    )
      .then((src) => {
        setImgSrc(src)
        setFetchingStatus({
          requesting: false,
          requestSuccess: true,
        })
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  useEffect(() => {
    fetchInvoice()
  }, [])

  return (
    <>
      {fetchingStatus.requesting && <CircularProgress size={20} />}

      {imgSrc === undefined &&
        fetchingStatus.requesting === false &&
        prescription === undefined && (
          <Box py={1}>
            <WelloFilePicker
              fileTypeToPick='photo'
              id='unit_actor_profile_pic'
              labelOrientation='column'
              name='logo'
              labelName={name ?? 'Add Photo'}
              allowUpdate={true}
              displaySelectedPhoto='circle'
              inputProps={{}}
              onFileChanged={(selectedField: SelectedFile) => {
                if (selectedField && selectedField.path) {
                  setImgSrc(selectedField.path as string)
                }
                if (selectedField && selectedField.path) {
                  onSelectionChanged(selectedField)
                }
              }}
            />
          </Box>
        )}
      {imgSrc === undefined &&
        fetchingStatus.requesting === false &&
        prescription && (
          <Avatar
            alt='Patient'
            src=''
            style={{ height: '60px', width: '60px' }}
          />
        )}
      {fetchingStatus.requestSuccess && imgSrc && (
        <WelloFilePicker
          fileTypeToPick='photo'
          id={`unit_actor_profile_pic${purpose}`}
          labelOrientation='column'
          name='profilePic'
          allowUpdate={update}
          displaySelectedPhoto='circle'
          preSelectedFile={{
            type: contentType.includes('pdf') ? 'document' : 'image',
            path: imgSrc,
            name: contentType.includes('pdf') ? 'pdf' : 'image',
          }}
          inputProps={{}}
          onFileChanged={(selectedField) => {
            if (selectedField && selectedField.path) {
              setImgSrc(selectedField.path as string)
            }
            if (selectedField && selectedField.path) {
              onSelectionChanged(selectedField)
            }
          }}
          required={false}
          prescription={prescription}
        />
      )}
    </>
  )
}
