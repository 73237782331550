/* eslint-disable radix */
/* eslint-disable no-alert */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentUserUnitLocationDetails } from 'services/userDetailsService'
import { getCustomDays } from 'utils/fhirResourcesHelper'
import { DaySelect } from '../LeftMenu/DaySelect'
import { RepeatSelect } from '../LeftMenu/RepeatSelect'
import { WelloSelect } from '../LeftMenu/WelloSelect'

interface Props {
  open: boolean
  onClose: () => void
  onDiscard: (
    selecteDay: string[],
    repeatValue: number,
    week: string,
    repeatOnString: string[],
    isCustom: boolean
  ) => void
  day: Date
  repeat: string
  repeatDays: string
  customString: string
  customLabel: string
  repeatValData: number
}

export const RepeatSettings: React.FC<Props> = ({
  open,
  onClose,
  onDiscard,
  day,
  repeat,
  repeatDays,
  customString,
  customLabel,
  repeatValData,
}) => {
  let reptVal = 1
  if (
    customString.includes('Every 2 weeks') ||
    customString.includes('Repeats every 2 days')
  ) {
    reptVal = 2
  } else if (customString.includes('Repeats every')) {
    reptVal = 1
  }
  if (repeatValData > 0) {
    reptVal = repeatValData
  }

  const monthData = []
  if (customLabel.length === 0) {
    if (customString.includes('Month')) {
      if (customString.includes('3rd')) monthData.push('3rd')
      if (customString.includes('2nd')) monthData.push('2nd')
      if (customString.includes('4th')) monthData.push('4th')
      if (customString.includes('5th')) monthData.push('5th')
      if (customString.includes('1st')) monthData.push('1st')
    }
    monthData.push('All')
  } else {
    for (let i = 0; i < customLabel.length; i++) {
      monthData.push(customLabel[i])
    }
  }
  const locationDetails = getCurrentUserUnitLocationDetails()
  const dayType = getCustomDays(locationDetails.hoursOfOperation ?? [])
  const repeatOn = ['All', '1st', '2nd', '3rd', '4th', '5th']
  const data: any = []
  if (
    customString.includes('Repeats everyday') &&
    repeat.includes('Repeats every ') === false
  ) {
    data.push('Su')
    data.push('M')
    data.push('T')
    data.push('W')
    data.push('Th')
    data.push('F')
    data.push('S')
  } else if (customLabel.length > 0) {
    const NoRepeatLabel =
      moment(day).format('ddd') === 'Thu' || moment(day).format('ddd') === 'Sun'
        ? moment(day).format('ddd').slice(0, 2)
        : moment(day).format('ddd').slice(0, 1)
    data.push(NoRepeatLabel)
  }
  if (repeatDays !== undefined) {
    if (
      repeatDays.includes('Every') ||
      repeatDays.includes('Every 2 weeks on ') ||
      repeatDays.includes('Every week ')
    ) {
      if (
        repeatDays.includes('Repeats Every Month') ||
        repeatDays.includes('Every Month')
      ) {
        if (repeatDays.includes('Friday')) {
          data.push('F')
        }
        if (repeatDays.includes('Monday')) {
          data.push('M')
        }
        if (repeatDays.includes('Tuesday')) {
          data.push('T')
        }

        if (repeatDays.includes('Wednesday')) {
          data.push('W')
        }
        if (repeatDays.includes('Thursday')) {
          data.push('Th')
        }
        if (repeatDays.includes('Saturday')) {
          data.push('S')
        }
        if (repeatDays.includes('Sunday')) {
          data.push('Su')
        }
      } else if (repeatDays.includes('Repeats everyday') === false) {
        let daysString = ''
        if (repeatDays.includes('Every 2 weeks on')) {
          daysString = repeatDays.replace('Every 2 weeks on ', '')
        } else if (repeatDays.includes('Every 3 weeks on')) {
          daysString = repeatDays.replace('Every 3 weeks on ', '')
        } else if (repeatDays.includes('Every 4 weeks on')) {
          daysString = repeatDays.replace('Every 4 weeks on ', '')
        } else if (repeatDays.includes('Every week on')) {
          daysString = repeatDays.replace('Every week on ', '')
        } else if (repeatDays.includes('Every week ')) {
          daysString = repeatDays.replace('Every week', '')
        } else {
          daysString = repeatDays.replace('Every ', '')
        }
        const dataDays = daysString.split(',')
        for (let i = 0; i < dataDays.length; i++) {
          let configDays
          if (dataDays[i].trim() === 'Thursday') {
            configDays = dataDays[i].trim().substring(0, 2)
          } else if (dataDays[i].trim() === 'Sunday') {
            configDays = dataDays[i].trim().substring(0, 2)
          } else {
            configDays = dataDays[i].trim().substring(0, 1)
          }
          data.push(configDays)
        }
      }
    } else {
      const NoRepeatLabel =
        moment(day).format('ddd') === 'Thu' ||
        moment(day).format('ddd') === 'Sun'
          ? moment(day).format('ddd').slice(0, 2)
          : moment(day).format('ddd').slice(0, 1)
      data.push(NoRepeatLabel)
    }
  } else {
    data.push(
      moment(day).format('ddd') === 'Thu' || moment(day).format('ddd') === 'Sun'
        ? moment(day).format('ddd').slice(0, 2)
        : moment(day).format('ddd').slice(0, 1)
    )
  }
  const [errorText, setErrorText] = React.useState<string>('')
  const [type, setType] = React.useState<string[]>(data)
  const [repeatVal, setRepeatVal] = React.useState<string[]>(monthData)
  const [repeatType, setRepeatType] = React.useState<string[]>(monthData)
  const [appointmentType, setAppointmentType] = React.useState<string[]>(data)
  const [repeatValue, setSelectedRePeatValue] = React.useState(reptVal)
  const repeatFrequency = [
    {
      id: 'Weekly',
      title: `${repeatValue > 1 ? 'Weeks' : 'Week'}`,
    },
    { id: `Day`, title: `${repeatValue > 1 ? 'Days' : 'Day'}` },
    { id: `Month`, title: `${repeatValue > 1 ? 'Months' : 'Month'}` },
  ]

  const { t } = useTranslation()
  let dataRepeat: string
  if (repeat === undefined) dataRepeat = ''
  else dataRepeat = customString
  const preSelectedRepeat = getPreConfiguredFrequency(dataRepeat)
  const [week, setSelectedWeek] = React.useState(preSelectedRepeat.id)
  const availableOptions = [
    moment(day).format('ddd') === 'Thu' || moment(day).format('ddd') === 'Sun'
      ? moment(day).format('ddd').slice(0, 2)
      : moment(day).format('ddd').slice(0, 1),
  ]

  const repeatOnOptions = [repeatOn[0]]

  function getPreConfiguredFrequency(frequency: string): any {
    if (
      frequency.includes('Does') ||
      frequency.includes('Repeats everyday') ||
      frequency.includes('Repeats every 2 days') ||
      frequency.includes('Repeats every 3 days') ||
      frequency.includes('Repeats every 4 days') ||
      frequency.includes('Repeats every 5 days') ||
      frequency.includes('Repeats every 6 days')
    ) {
      return repeatFrequency[1]
    }
    if (
      frequency.includes('Repeats every') ||
      frequency.includes('Every') ||
      frequency.includes('Repeats Weekly')
    ) {
      if (frequency.includes('Every Month')) {
        return repeatFrequency[2]
      }
      return repeatFrequency[0]
    }

    if (
      frequency.includes('Every Month ') ||
      frequency.includes('Repeats monthly')
    ) {
      return repeatFrequency[2]
    }
    return repeatFrequency[0]
  }

  const handleSettingsChangeTab = (settingsTab: string) => {
    availableOptions.push(settingsTab)
    setType(availableOptions)
  }

  const handleRepeatOnChangeTab = (settingsTab: string) => {
    repeatOnOptions.push(settingsTab)
    setRepeatVal(repeatOnOptions)
  }
  let max = 4
  if (week === 'Weekly' || week === 'weeks') max = 4
  if (week === 'Day') max = 30
  if (week === 'Month') max = 1

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'> {t('Custom Repeat')}</DialogTitle>
      <DialogContent dividers={true}>
        <Box
          flexDirection='column'
          display='flex'
          justifyContent='flex-start'
          alignContent='space-between'
        >
          <Box
            flexDirection='row'
            flexGrow={1}
            display='flex'
            justifyContent='flex-start'
            paddingLeft={0.5}
            alignItems='center'
          >
            <Typography
              variant='subtitle2'
              style={{
                paddingTop: 5,
              }}
            >
              {t('Repeat Every')}{' '}
            </Typography>
            <Box paddingLeft={3.5}>
              <TextField
                id='OccurrencesCustom'
                variant='outlined'
                type='number'
                label=''
                size='small'
                color='primary'
                defaultValue={1}
                fullWidth
                InputProps={{
                  style: {
                    width: 60,
                    //   opacity: 1,
                  },
                  inputProps: {
                    min: 1,
                    max,
                  },

                  // classes,
                }}
                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                value={repeatValue}
                onChange={(e: any) => {
                  if (
                    parseInt(e.target.value) > 0 &&
                    parseInt(e.target.value) <= max &&
                    e.target.value !== 'NaN'
                  ) {
                    setSelectedRePeatValue(e.target.value)
                    setErrorText('')
                  } else {
                  }
                }}
                helperText={errorText}
              />
              {/* <WelloInput
                    name='name'
                    required={false}
                    label=''
                    type='number'
                    size='small'
                    value={repeatValue}
                    placeholder='First Name'
                    width={40}
                    onChange={handleInputChange}
                    max={2}
                    min={1}
                    defaultValue={1}
                  /> */}
            </Box>
            <Box paddingLeft={3.5}>
              <Box>
                <WelloSelect
                  key={preSelectedRepeat.id}
                  label=''
                  id='customRepeat'
                  availableOptions={repeatFrequency}
                  required={true}
                  type='Repeat'
                  displayField='title'
                  valueField='id'
                  preSelectedOption={preSelectedRepeat}
                  onSelectionChanged={(value: any) => {
                    setSelectedWeek(value.id)
                  }}
                />
              </Box>
            </Box>
          </Box>

          {week === 'Month' && (
            <Box
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
              paddingY={2}
            >
              <RepeatSelect
                preSelectedTab={repeatVal}
                onTabChange={(e: any) => handleRepeatOnChangeTab(e)}
                menu={repeatOn}
                menuLabel='Repeat on'
                onValuesChanged={(selectedOptions) => {
                  setRepeatType(selectedOptions)
                }}
              />
            </Box>
          )}
          {week !== 'Day' && (
            <Box
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
              paddingY={2}
            >
              <DaySelect
                preSelectedTab={type}
                onTabChange={(e: any) => handleSettingsChangeTab(e)}
                menu={dayType}
                menuLabel={t('Every')}
                onValuesChanged={(selectedOptions) => {
                  setAppointmentType(selectedOptions)
                }}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          variant='outlined'
          disableElevation
          id='customClose'
        >
          <Typography variant='button'>{t('Cancel')}</Typography>
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            onDiscard(appointmentType, repeatValue, week, repeatType, true)
          }}
          variant='contained'
          color='primary'
          id='customDone'
        >
          <Typography variant='button'>{t('Done')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
