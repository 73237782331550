import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import {
  ChiefComplaintData,
  ChiefComplaintTerminology,
} from 'models/chiefComplaintData'
import { LocationData } from 'models/location'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  resetState,
  searchChiefComplaints,
} from 'redux/umls/chiefComplaint/chiefComplaintSearchSlice'
import { titleCase } from 'utils/fhirResourcesHelper'

import { logger } from 'utils/logger'

interface LabOfferingSelectorProps {
  onSelectionChanges?: (selectedValues: ChiefComplaintTerminology[]) => void
  doctorListId?: string
  preSelectedComplaints?: ChiefComplaintTerminology[]
  preSelectAll?: boolean
  disabled: boolean
  selectOther: boolean
  id?: string
}

export const ChiefComplaintsSelectorMultiple: React.FC<LabOfferingSelectorProps> =
  ({
    onSelectionChanges,
    doctorListId,
    preSelectedComplaints,
    preSelectAll = false,
    disabled,
    selectOther,
    id,
  }: LabOfferingSelectorProps) => {
    const chiefComplaintSeachSlice = useSelector(
      (state: RootState) => state.chiefComplaintSeachSlice
    )
    const { t } = useTranslation(['labelCommon'])
    const dispatch = useDispatch()
    const [text, setText] = useState<string>('')

    //   useEffect(() => {
    //     dispatch(searchLabOfferings())
    //   }, [dispatch])
    return (
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <div role='alert'>
            <div>Error while fetching provider list</div>
            <pre>{error.message}</pre>
            <Button
              onClick={() => {
                dispatch(searchChiefComplaints(text, 0))
              }}
            >
              Try again
            </Button>
          </div>
        )}
      >
        <Box width='100%'>
          <Box width='100%'>
            <Autocomplete
              id={id}
              multiple={true}
              onOpen={() => {}}
              onClose={() => {
                dispatch(resetState())
              }}
              style={{
                borderRadius: '4px',
              }}
              disableClearable
              fullWidth
              size='small'
              getOptionSelected={(option, value) => option.text === value.text}
              disabled={disabled}
              defaultValue={preSelectedComplaints}
              getOptionLabel={(option) => titleCase(option.text)}
              options={chiefComplaintSeachSlice.chiefComplaintTermArray ?? []}
              loading={chiefComplaintSeachSlice.searching}
              autoComplete
              includeInputInList
              filterSelectedOptions
              onChange={(e, changedValue, reason) => {
                logger.info(changedValue)
                if (onSelectionChanges) onSelectionChanges(changedValue ?? [])
              }}
              ChipProps={{
                deleteIcon: (
                  <ClearOutlined
                    style={{
                      height: '15px',
                      color: 'white',
                    }}
                  />
                ),
                style: {
                  backgroundColor: kPrimaryLight,
                  borderRadius: '4px',
                },
              }}
              renderOption={(option, { inputValue }) => {
                const matches = match(option.text, inputValue)
                const parts = parse(option.text, matches)
                return (
                  <ListItem id={`lab_test_opt_${option.text}`}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={part.text}
                          style={{
                            backgroundColor: part.highlight
                              ? kPrimaryLight
                              : undefined,
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </ListItem>
                )
              }}
              filterOptions={(x) => x}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('labelCommon:type_atleast_3_chars')}
                  variant='outlined'
                  onChange={(e) => {
                    if (e.target.value.length > 2) {
                      dispatch(searchChiefComplaints(e.target.value, 0))
                      setText(e.target.value)
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {chiefComplaintSeachSlice.searching ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
          {chiefComplaintSeachSlice.error && (
            <Typography>
              {' '}
              {chiefComplaintSeachSlice.errorMessage ?? 'Error while searching'}
            </Typography>
          )}
          {chiefComplaintSeachSlice.noResultsAvailable && (
            <Typography> No Results available</Typography>
          )}
        </Box>
      </ErrorBoundary>
    )
  }
