import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Typography,
} from '@material-ui/core'
import { DayCareRatesData } from 'models/dayCareRatesData'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateCatalog } from 'redux/administration/management/addDaycareRoomRatesSlice'
import { getDaycareRoomRateBundleUpdate } from 'redux/administration/management/dayCareSearchSliceForRate'
import { WelloTextField } from 'wello-web-components'

export interface EditDayCareRatesProps {
  open: boolean
  onCancelClick: () => void
  rowDetails: DayCareRatesData
}

export function EditDayCareRateData(props: EditDayCareRatesProps) {
  const { open, rowDetails, onCancelClick: onClose } = props

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  const dispatch = useDispatch()

  const [dayCareRateData, setdayCareRateData] =
    React.useState<DayCareRatesData>({
      price: rowDetails.price,
      description: rowDetails.description,
      name: rowDetails.name,
      dayCareDetailsWithPrice: rowDetails.dayCareDetailsWithPrice,
    })

  function handlePriceChange(priceDaycareRate: number) {
    setdayCareRateData({ ...dayCareRateData, price: priceDaycareRate })
  }

  function handleDescriptionChange(descriptionDaycare: string) {
    setdayCareRateData({ ...dayCareRateData, description: descriptionDaycare })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    if (
      dayCareRateData.description?.length === 0 ||
      !dayCareRateData.description?.trim() ||
      dayCareRateData.description?.length > 300
    ) {
      hasErrorsInData = true
    } else if (
      !dayCareRateData.price ||
      dayCareRateData.price <= 0 ||
      dayCareRateData.price > 10000 ||
      Number.isNaN(dayCareRateData.price)
    ) {
      hasErrorsInData = true
    } else {
      hasErrorsInData = false
    }

    if (!hasErrorsInData) {
      const bundleData = getDaycareRoomRateBundleUpdate(dayCareRateData)

      if (bundleData) {
        dispatch(updateCatalog(bundleData, '', 'Daycare service details'))
      }
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Update Daycare Service Charges')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
        >
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Daycare Service Type'
              // disabled={true}
              textProps={{
                id: 'Daycare Service Type',
                value: dayCareRateData?.name,
                disabled: true,
                placeholder: 'Daycare Service name',
                onChange: (changeName) => {
                  // handleNameChange(changeName.target.value)
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Price'
              textProps={{
                id: 'Price',
                InputProps: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box>
                        {' '}
                        <Typography variant='subtitle2' align='center'>
                          {' '}
                          &#x20b9;
                        </Typography>
                      </Box>
                    </InputAdornment>
                  ),
                },
                inputProps: {
                  max: 10000,
                  maxLength: 5,
                },
                error:
                  (dayCareRateData?.price ?? '') <= 0 ||
                  (dayCareRateData?.price ?? '') > 10000 ||
                  Number.isNaN(dayCareRateData.price),
                helperText:
                  (dayCareRateData?.price ?? '') <= 0 ||
                  (dayCareRateData.price ?? '') > 10000
                    ? 'Price should be between 1 to 10000'
                    : '',
                defaultValue: dayCareRateData.price,
                type: 'number',
                placeholder: 'Price',
                onChange: (changePrice) => {
                  handlePriceChange(parseInt(changePrice.target.value, 10))
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Description'
              textProps={{
                id: 'description',
                //   value: dayCareRateData?.description,
                defaultValue: dayCareRateData.description,
                placeholder: 'Description',
                type: 'text',
                multiline: true,
                inputProps: {
                  maxLength: 310,
                },
                error:
                  dayCareRateData.description?.length === 0 ||
                  !dayCareRateData.description?.trim() ||
                  dayCareRateData.description?.length > 300,
                helperText:
                  dayCareRateData.description?.length === 0 ||
                  !dayCareRateData.description?.trim() ||
                  dayCareRateData.description?.length > 300
                    ? 'Description is required and characters between 1 and 300'
                    : '',
                onChange: (changeDescription) => {
                  handleDescriptionChange(changeDescription.target.value)
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button
            variant='contained'
            disableElevation
            onClick={() => {
              handleSubmit()
            }}
            id='Update'
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
