/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  ListItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'
import {
  addAddictions,
  resetAddictions,
} from 'redux/fhirMedicalResources/ipd/addictions/addictionsAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  breathe,
  drugCount,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  mensClots,
  mensLeukorrhea,
  mensType,
  occupations,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  RadioRaisedButton,
  WelloFormItemLabel,
  WelloLoadingIndicator,
  WelloTextField,
} from 'wello-web-components'
import { ClearOutlined } from '@material-ui/icons'
import {
  addOccupation,
  resetOccupationDetailsAdd,
} from 'redux/fhirMedicalResources/ipd/addOccupation/occupationAddSlice'
import { MensurationMasterSelector } from 'views/components/consultation/subjective/mensurationMasterSelector'
import {
  addMenstrualAdd,
  resetMesturationDetailsAdd,
} from 'redux/fhirMedicalResources/ipd/addMensuration/AddMensurationSlice'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onAddictionAdded: () => void
}
export const AddMensuration: React.FC<Props> = ({
  open,
  onClose,
  onAddictionAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [occupation, setOccupation] = useState<R4.ICoding>()
  const [clots, setClots] = useState<R4.ICoding>()
  const [leukorrhea, setLeukorrhea] = useState<R4.ICoding>()

  const [duration, setDuration] = useState<number>(1)
  const [occupationsData, setOccupationData] =
    useState<R4.ICoding[]>(occupations)
  const [additionalNotes, setAdditionalNotes] = useState<string>()

  const [text, setText] = useState<string>('')

  const dispatch = useDispatch()

  const occupationalAddSlice = useSelector(
    (state: RootState) => state.menstrualAddSlice
  )

  useEffect(() => {
    if (occupationalAddSlice.additionSuccessful) {
      onAddictionAdded()
      dispatch(resetMesturationDetailsAdd())
      setOccupation(undefined)
      setClots(undefined)
      setLeukorrhea(undefined)
      setDuration(1)
      setAdditionalNotes(undefined)
    }
  }, [dispatch, occupationalAddSlice, onAddictionAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setOccupation(undefined)
        setAdditionalNotes(undefined)
        setClots(undefined)
        setLeukorrhea(undefined)
        setDuration(1)
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Add Menstrual Details</DialogTitle>
      <DialogContent dividers={true}>
        {occupationalAddSlice.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!occupationalAddSlice.adding && (
          <Box marginX={2} marginY={1}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title='Menstrual' />
                  <Box display='flex' flexDirection='row' width='100%'>
                    <WelloSelectFHIRCodingWithAutocomplete
                      title='Type'
                      id='unit_person'
                      availableCodings={mensType}
                      onChanged={(e) => {
                        if (e) {
                          setOccupation(e)
                        }
                      }}
                      disabled={occupationalAddSlice.adding}
                    />
                    {/* <MensurationMasterSelector
                      onSelectionChanges={(e: R4.ICoding) => {
                        setOccupation(e)
                      }}
                      disabled={false}
                    /> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title='Duration' />
                  <Box display='flex' flexDirection='row' width='100%'>
                    <TextField
                      variant='outlined'
                      fullWidth
                      type='number'
                      id='men_6'
                      size='small'
                      value={duration}
                      onChange={(event) => {
                        setDuration(parseInt(event.target.value, 10))
                      }}
                      error={
                        duration < 1 || duration > 30 || Number.isNaN(duration)
                      }
                      helperText={
                        duration < 1 || duration > 30 || Number.isNaN(duration)
                          ? `Duration should be between 1 to 30 (in days)`
                          : ''
                      }
                      InputProps={{
                        inputProps: {
                          max: 30,
                          min: 1,
                          step: 1,
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            {' '}
                            <Typography>In Days</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <WelloSelectFHIRCodingWithAutocomplete
                      title='Clots'
                      id='unit_person1'
                      availableCodings={mensClots}
                      onChanged={(e) => {
                        if (e) {
                          setClots(e)
                        }
                      }}
                      disabled={occupationalAddSlice.adding}
                    />
                    {/* <MensurationMasterSelector
                      onSelectionChanges={(e: R4.ICoding) => {
                        setOccupation(e)
                      }}
                      disabled={false}
                    /> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <WelloSelectFHIRCodingWithAutocomplete
                      title='Leukorrhea'
                      id='unit_person4'
                      availableCodings={mensLeukorrhea}
                      onChanged={(e) => {
                        if (e) {
                          setLeukorrhea(e)
                        }
                      }}
                      disabled={occupationalAddSlice.adding}
                    />
                    {/* <MensurationMasterSelector
                      onSelectionChanges={(e: R4.ICoding) => {
                        setOccupation(e)
                      }}
                      disabled={false}
                    /> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Additional Notes' />
                  </Grid>
                  <Grid item style={{ paddingBottom: '16px' }}>
                    <TextField
                      size='small'
                      fullWidth
                      multiline
                      id='men_10'
                      type='number'
                      value={additionalNotes}
                      variant='outlined'
                      onChange={(event) => {
                        setAdditionalNotes(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
            setOccupation(undefined)
            setAdditionalNotes(undefined)
          }}
          variant='outlined'
          id='men_11'
          disabled={occupationalAddSlice.adding}
          disableElevation
        >
          {t('labelCommon:cancel')}
        </Button>
        <Button
          onClick={() => {
            dispatch(
              addMenstrualAdd(
                fhirAppointmentDetails,
                occupation,
                duration,
                clots,
                leukorrhea,
                additionalNotes
              )
            )
          }}
          variant='contained'
          color='primary'
          disableElevation
          id='men_12'
          disabled={
            occupation === undefined ||
            leukorrhea === undefined ||
            clots === undefined ||
            duration < 1 ||
            duration > 30 ||
            occupationalAddSlice.adding ||
            Number.isNaN(duration)
          }
        >
          {t('labelCommon:add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
