/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  ListItem,
  Switch,
  TextField,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'
import {
  addAddictions,
  resetAddictions,
} from 'redux/fhirMedicalResources/ipd/addictions/addictionsAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  breathe,
  drugCount,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  occupations,
  range,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { RadioRaisedButton, WelloLoadingIndicator } from 'wello-web-components'
import { ClearOutlined } from '@material-ui/icons'
import {
  addOccupation,
  resetOccupationDetailsAdd,
} from 'redux/fhirMedicalResources/ipd/addOccupation/occupationAddSlice'
import { getColorBasedOnNumber } from 'utils/fhirResourcesHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onAddictionAdded: () => void
}
export const AddOccupation: React.FC<Props> = ({
  open,
  onClose,
  onAddictionAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '40%',
    },
  }))
  const classes = useStyles()

  const [occupation, setOccupation] = useState<R4.ICoding>()
  const [occupationsData, setOccupationData] =
    useState<R4.ICoding[]>(occupations)
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const [value, setValue] = React.useState<number>(0)
  const [physicalVal, setPhysicalVal] = React.useState<number>(0)

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number)
  }

  const handleChangeForPhysical = (event: any, newValue: number | number[]) => {
    setPhysicalVal(newValue as number)
  }
  const [text, setText] = useState<string>('')

  const dispatch = useDispatch()

  const occupationalAddSlice = useSelector(
    (state: RootState) => state.occupationalAddSlice
  )

  useEffect(() => {
    if (occupationalAddSlice.additionSuccessful) {
      onAddictionAdded()
      dispatch(resetOccupationDetailsAdd())
      setOccupation(undefined)
      setAdditionalNotes(undefined)
      setValue(0)
      setPhysicalVal(0)
    }
  }, [dispatch, occupationalAddSlice, onAddictionAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setOccupation(undefined)
        setAdditionalNotes(undefined)
        setValue(0)
        setPhysicalVal(0)
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        Add Occupational Details
      </DialogTitle>
      <DialogContent dividers={true}>
        {occupationalAddSlice.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!occupationalAddSlice.adding && (
          <Box marginX={2} marginY={1}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <ValueSetSelectSingle
                      key='occupation_Search'
                      title='Select Occupation'
                      url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-occupation-vs'
                      id='occupation_search'
                      fuzzySearch={true}
                      alwaysShowAllOptions={false}
                      disabled={false}
                      onOptionSelected={(selectedKriya) => {
                        // setOccupation(changedValue)
                        if (selectedKriya) {
                          setOccupation(selectedKriya)
                        }
                      }}
                      helperText={undefined}
                      placeHolder='Search Occupation'
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Mental stress level' />
                  </Grid>
                  <Box width='100%' px={1}>
                    <Slider
                      defaultValue={10}
                      step={10}
                      id='mental_stress'
                      marks={range}
                      style={getColorBasedOnNumber(10, 100, value)}
                      value={value}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Physical stress level' />
                  </Grid>
                  <Box width='100%' px={1}>
                    <Slider
                      defaultValue={10}
                      step={10}
                      id='physical_stress'
                      marks={range}
                      style={getColorBasedOnNumber(10, 100, physicalVal)}
                      value={physicalVal}
                      onChange={handleChangeForPhysical}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Additional Notes' />
                  </Grid>
                  <Grid item style={{ paddingBottom: '16px' }}>
                    <TextField
                      size='small'
                      fullWidth
                      multiline
                      id='occ_6'
                      type='number'
                      value={additionalNotes}
                      variant='outlined'
                      onChange={(event) => {
                        setAdditionalNotes(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          justifyContent='flex-end'
          paddingRight={4}
        >
          <Button
            onClick={() => {
              onClose()
              setOccupation(undefined)
              setAdditionalNotes(undefined)
              setValue(0)
              setPhysicalVal(0)
            }}
            variant='outlined'
            disabled={occupationalAddSlice.adding}
            disableElevation
            id='occ_cancel'
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                addOccupation(
                  fhirAppointmentDetails,
                  value,
                  physicalVal,
                  occupation,
                  additionalNotes
                )
              )
            }}
            variant='contained'
            color='primary'
            id='occ_add'
            disableElevation
            disabled={occupation === undefined || occupationalAddSlice.adding}
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
