/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core'
import * as _ from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addLabTestIPD,
  resetAddVitalsState,
} from 'redux/ipd/addLabTestIPD/addLabTestSlice'
import { RootState } from 'redux/rootReducer'
import { cToF, fToC } from 'utils/appointment_handle/vitals_util'
import { WelloLoadingPage, WelloTextField } from 'wello-web-components'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

import moment from 'moment'
import {
  isAfter,
  isAfterSame,
  isBeforeTime,
  isTherapybetweenForMorning,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import {
  cbcpanel,
  lft,
  lipidPanel,
  obsDataForLabTest,
  panelData,
  rft,
  urinepanel,
} from 'utils/constants'
import { ExpandMore } from '@material-ui/icons'
import {
  LabTestReport,
  LabTestReport1,
  LabTestReport2,
  LabTestReport3,
  Panel1,
} from 'models/labTestReport'
import { addLabTestOPD } from 'redux/consultation/addlabTest/addLabTestIPD/addLabTestSlice'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import {
  getAgeOfPatientForChecking,
  getGenderOfPatient,
} from 'utils/fhirResourcesHelper'
import { validate } from 'uuid'
import { ObsRecord } from 'models/obsRecords'
import { getErrorStatus } from 'utils/fhirResoureHelpers/planDefinitionHelper'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onVitalAdded: (createdCondition: R4.IBundle | undefined) => void
}
export const AddLabTest: React.FC<Props> = ({
  open,
  onClose,
  onVitalAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'observation'])
  const useStylesDialog = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '50%',
    },
  }))
  const classes = useStyles()
  const classesDialog = useStylesDialog()

  const labTestSearchSlice = useSelector(
    (state: RootState) => state.diagnosticObsFinderSlice
  )

  const [testData, setTestData] = useState<ObsRecord[]>([])
  const [lipdPanel, setLipidPanel] = useState<ObsRecord[]>([])
  const [lftData, setLftData] = useState<ObsRecord[]>([])
  const [rftData, setRftData] = useState<ObsRecord[]>([])
  const [tftData, setTftData] = useState<ObsRecord[]>([])
  const [urineDataPanel, setUrineDataPanel] = useState<ObsRecord[]>([])

  const [bloodSugar, setBloodSugar] = useState<number>(0)
  const [hba1c, setHba1c] = useState<number>(0)
  const [homo, setHomo] = useState<number>(0)
  const [pp, setPP] = useState<number>(0)
  const [serumC, setSercumC] = useState<number>(0)
  const [serum, setSerum] = useState<number>(0)
  const [serumU, setSerumU] = useState<number>(0)
  const [homaPanel, setHomaPanel] = useState<ObsRecord[]>([])
  const [dexaPanel, setDexaPanel] = useState<ObsRecord[]>([])
  const [serumLytes, setSerumLytes] = useState<ObsRecord[]>([])

  const [r3, setR3] = useState<number | undefined>(undefined)
  const [r4, setR4] = useState<number | undefined>(undefined)

  const [reportDate, setReportDate] = useState<Date>(new Date())

  const dispatch = useDispatch()

  function getMinError(
    valData: R4.IObservationDefinition,
    patient: R4.IPatient
  ) {
    let lowVal: number | undefined = 0

    if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
      for (let i = 0; i < valData.qualifiedInterval.length; i++) {
        if (valData.qualifiedInterval[i].gender) {
          if (
            valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
          ) {
            lowVal = valData.qualifiedInterval[i].range
              ? valData.qualifiedInterval[i].range?.low?.value
              : undefined
          }
        } else {
          lowVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.low?.value
            : 0
        }
      }
    }

    return lowVal
  }

  function getHighError(
    valData: R4.IObservationDefinition,
    patient: R4.IPatient
  ) {
    let highVal: number | undefined
    if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
      for (let i = 0; i < valData.qualifiedInterval.length; i++) {
        if (valData.qualifiedInterval[i].gender) {
          if (
            valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
          ) {
            highVal = valData.qualifiedInterval[i].range
              ? valData.qualifiedInterval[i].range?.high?.value
              : 0
          }
        } else {
          highVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.high?.value
            : 0
        }
      }
    }

    return highVal
  }

  function checkCBCPanel() {
    const countArr: string[] = []
    for (let i = 0; i < testData.length; i++) {
      if (testData[i].quantity !== undefined) {
        countArr.push('1')
      }
    }

    return countArr
  }

  function checkLipid() {
    const countArr: string[] = []
    for (let i = 0; i < lipdPanel.length; i++) {
      if (lipdPanel[i].code !== undefined) {
        countArr.push('1')
      }
    }

    return countArr
  }
  function checkLft() {
    const countArr: string[] = []
    for (let i = 0; i < lftData.length; i++) {
      if (lftData[i].quantity !== undefined) {
        countArr.push('1')
      }
    }

    return countArr
  }
  function checkRft() {
    const countArr: string[] = []
    for (let i = 0; i < rftData.length; i++) {
      if (rftData[i].quantity !== undefined) {
        countArr.push('1')
      }
    }

    return countArr
  }

  function checkTft() {
    const countArr: string[] = []
    for (let i = 0; i < tftData.length; i++) {
      if (tftData[i].quantity !== undefined) {
        countArr.push('1')
      }
    }

    return countArr
  }

  function checkUrine() {
    const countArr: string[] = []
    for (let i = 0; i < urineDataPanel.length; i++) {
      if (urineDataPanel[i].code !== undefined) {
        countArr.push('1')
      }
    }

    return countArr
  }

  function isNumber(obs: any): boolean | undefined {
    const unitMeasure: string = obs.quantitativeDetails.unit
      ? obs.quantitativeDetails.unit.coding[0].code
      : ''
    if (unitMeasure.length > 0) return true
    return false
  }

  function checkHoma() {
    const countArr: string[] = []
    for (let i = 0; i < homaPanel.length; i++) {
      if (homaPanel[i].quantity !== undefined) {
        countArr.push('1')
      }
    }

    return countArr
  }

  function checkDexa() {
    const countArr: string[] = []
    for (let i = 0; i < dexaPanel.length; i++) {
      if (dexaPanel[i].quantity !== undefined) {
        countArr.push('1')
      }
    }

    return countArr
  }

  function checkSerumLytes() {
    const countArr: string[] = []
    for (let i = 0; i < serumLytes.length; i++) {
      if (serumLytes[i].quantity !== undefined) {
        countArr.push('1')
      }
    }

    return countArr
  }

  useEffect(() => {
    if (
      labTestSearchSlice.testData &&
      labTestSearchSlice.testData.length > 0 &&
      labTestSearchSlice.testData[0].obsDef
    ) {
      updateCbcPanelArray(labTestSearchSlice.testData[0].obsDef)
    }

    if (
      labTestSearchSlice.lipidData &&
      labTestSearchSlice.lipidData.length > 0 &&
      labTestSearchSlice.lipidData[0].obsDef
    ) {
      updateLipidArray(labTestSearchSlice.lipidData[0].obsDef)
    }
    if (
      labTestSearchSlice.lftData &&
      labTestSearchSlice.lftData.length > 0 &&
      labTestSearchSlice.lftData[0].obsDef
    ) {
      updateLftArray(labTestSearchSlice.lftData[0].obsDef)
    }

    if (
      labTestSearchSlice.rftData &&
      labTestSearchSlice.rftData.length > 0 &&
      labTestSearchSlice.rftData[0].obsDef
    ) {
      updateRftArray(labTestSearchSlice.rftData[0].obsDef)
    }

    if (
      labTestSearchSlice.tftData &&
      labTestSearchSlice.tftData.length > 0 &&
      labTestSearchSlice.tftData[0].obsDef
    ) {
      updateTftArray(labTestSearchSlice.tftData[0].obsDef)
    }

    if (
      labTestSearchSlice.urineData &&
      labTestSearchSlice.urineData.length > 0 &&
      labTestSearchSlice.urineData[0].obsDef
    ) {
      updateUrinepanelArray(labTestSearchSlice.urineData[0].obsDef)
    }
    if (
      labTestSearchSlice.homa &&
      labTestSearchSlice.homa.length > 0 &&
      labTestSearchSlice.homa[0].obsDef
    ) {
      updateHomaData(labTestSearchSlice.homa[0].obsDef)
    }
    if (
      labTestSearchSlice.dexa &&
      labTestSearchSlice.dexa.length > 0 &&
      labTestSearchSlice.dexa[0].obsDef
    ) {
      updateDexa(labTestSearchSlice.dexa[0].obsDef)
    }

    if (
      labTestSearchSlice.serumElectraLytes &&
      labTestSearchSlice.serumElectraLytes.length > 0 &&
      labTestSearchSlice.serumElectraLytes[0].obsDef
    ) {
      updateSerumLytes(labTestSearchSlice.serumElectraLytes[0].obsDef)
    }
  }, [])

  function updateHomaData(cbcPanelData: R4.IObservationDefinition[]) {
    const cbcArray: ObsRecord[] = []
    for (let i = 0; i < cbcPanelData.length; i++) {
      const data: ObsRecord = {
        panelName: '',
        code: undefined,
        unit: '',
        measureInString: '',
        quantity: undefined,
      }
      cbcArray.push(data)
    }

    setHomaPanel(cbcArray)
  }

  function updateDexa(cbcPanelData: R4.IObservationDefinition[]) {
    const cbcArray: ObsRecord[] = []
    for (let i = 0; i < cbcPanelData.length; i++) {
      const data: ObsRecord = {
        panelName: '',
        code: undefined,
        unit: '',
        measureInString: '',
        quantity: undefined,
      }
      cbcArray.push(data)
    }
    setDexaPanel(cbcArray)
  }

  function updateSerumLytes(cbcPanelData: R4.IObservationDefinition[]) {
    const cbcArray: ObsRecord[] = []
    for (let i = 0; i < cbcPanelData.length; i++) {
      const data: ObsRecord = {
        panelName: '',
        code: undefined,
        unit: '',
        measureInString: '',
        quantity: undefined,
      }
      cbcArray.push(data)
    }
    setSerumLytes(cbcArray)
  }

  function updateCbcPanelArray(cbcPanelData: R4.IObservationDefinition[]) {
    const cbcArray: ObsRecord[] = []
    for (let i = 0; i < cbcPanelData.length; i++) {
      const data: ObsRecord = {
        panelName: '',
        code: undefined,
        unit: '',
        measureInString: '',
        quantity: undefined,
      }
      cbcArray.push(data)
    }
    setTestData(cbcArray)
  }

  function updateLftArray(cbcPanelData: R4.IObservationDefinition[]) {
    const cbcArray: ObsRecord[] = []
    for (let i = 0; i < cbcPanelData.length; i++) {
      const data: ObsRecord = {
        panelName: '',
        code: undefined,
        unit: '',
        measureInString: '',
        quantity: undefined,
      }
      cbcArray.push(data)
    }
    setLftData(cbcArray)
  }

  function updateRftArray(cbcPanelData: R4.IObservationDefinition[]) {
    const cbcArray: ObsRecord[] = []
    for (let i = 0; i < cbcPanelData.length; i++) {
      const data: ObsRecord = {
        panelName: '',
        code: undefined,
        unit: '',
        measureInString: '',
        quantity: undefined,
      }
      cbcArray.push(data)
    }
    setRftData(cbcArray)
  }

  function updateTftArray(cbcPanelData: R4.IObservationDefinition[]) {
    const cbcArray: ObsRecord[] = []
    for (let i = 0; i < cbcPanelData.length; i++) {
      const data: ObsRecord = {
        panelName: '',
        code: undefined,
        unit: '',
        measureInString: '',
        quantity: undefined,
      }
      cbcArray.push(data)
    }
    setTftData(cbcArray)
  }

  function updateUrinepanelArray(cbcPanelData: R4.IObservationDefinition[]) {
    const cbcArray: ObsRecord[] = []
    for (let i = 0; i < cbcPanelData.length; i++) {
      const data: ObsRecord = {
        panelName: '',
        code: undefined,
        unit: '',
        measureInString: '',
        quantity: undefined,
      }
      cbcArray.push(data)
    }
    setUrineDataPanel(cbcArray)
  }

  function updateLipidArray(cbcPanelData: R4.IObservationDefinition[]) {
    const cbcArray: ObsRecord[] = []
    for (let i = 0; i < cbcPanelData.length; i++) {
      const data: ObsRecord = {
        panelName: '',
        code: undefined,
        unit: '',
        measureInString: '',
        quantity: undefined,
      }
      cbcArray.push(data)
    }

    setLipidPanel(cbcArray)
  }

  const addLabTestSliceIPD = useSelector(
    (state: RootState) => state.addLabTestSliceIPD
  )

  function resetDetails() {
    // setPulseRate(0)
    setBloodSugar(0)
    setHba1c(0)
    setHomo(0)
    setPP(0)
    setSercumC(0)
    setSerumU(
      getGenderOfPatient(fhirAppointmentDetails.patient) === 'male' ? 0.7 : 0.5
    )
    setSerum(0)
  }

  useEffect(() => {
    if (addLabTestSliceIPD.additionSuccessful) {
      onVitalAdded(addLabTestSliceIPD.addedVitalsBundle)
      resetDetails()
    }
  }, [addLabTestSliceIPD.additionSuccessful, addLabTestSliceIPD, onVitalAdded])

  function handleDateChange(date: any) {
    setReportDate(moment(date.target.value).toDate())
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classesDialog.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>Add Lab Test Result</DialogTitle>
      <DialogContent dividers={true}>
        <Box>
          <Grid container direction='column' spacing={1}>
            <Box width='30%' py={1}>
              <WelloTextField
                title='Select Date'
                textProps={{
                  id: `date`,
                  value: moment(reportDate).format('YYYY-MM-DD'),
                  inputProps: {
                    max: moment().format('YYYY-MM-DD'),
                    min: getAgeOfPatientForChecking(
                      fhirAppointmentDetails.patient
                    ),
                  },
                  onKeyDown: (e) => {
                    e.preventDefault()
                  },

                  type: 'date',
                  onChange: (changeData) => {
                    handleDateChange(changeData)
                  },
                }}
              />
            </Box>
            <Accordion>
              <AccordionSummary
                aria-controls='panel1a-content'
                id='lab_test'
                expandIcon={<ExpandMore />}
              >
                <Typography className={classes.titleText}>Lab Test</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display='flex'
                  flexDirection='column'
                  py={0.25}
                  width='100%'
                  //   style={{ backgroundColor: ' #DADBDD' }}
                >
                  <Grid
                    container
                    direction='row'
                    id='vitals'
                    style={{ backgroundColor: ' #DADBDD' }}
                  >
                    <Grid item xs={4}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Parameter Name
                            </Typography>
                          </Box>
                        </Box>
                        <Box display='flex' width='2%' paddingTop={0.1}>
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                              backgroundColor: 'white',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Value
                            </Typography>
                          </Box>
                        </Box>
                        <Box display='flex' width='2%' paddingTop={0.1}>
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                              backgroundColor: 'white',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item key={1} xs={2}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Unit
                            </Typography>
                          </Box>
                        </Box>
                        <Box display='flex' width='2%' paddingTop={0.1}>
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                              backgroundColor: 'white',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item key={1} xs={3}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Normal Range
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item key={1}>
                    <Grid container direction='row' spacing={1}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box px={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[0].obs[0].preferredReportName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box py={1}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            size='small'
                            id='bloodSugar'
                            type='number'
                            value={bloodSugar}
                            onChange={(event) => {
                              setBloodSugar(
                                parseFloat(
                                  parseFloat(event.target.value).toFixed(2)
                                )
                              )
                            }}
                            error={
                              (bloodSugar < 76 && bloodSugar > 0) ||
                              bloodSugar > 99
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1} px={0.5}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[0].obs[0].quantitativeDetails
                              .unit.coding
                              ? obsDataForLabTest[0].obs[0].quantitativeDetails
                                  .unit.coding[0].code ?? ''
                              : ''}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            76 - 99
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item key={1}>
                    <Grid container direction='row' spacing={1} key={2}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box px={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[1].obs[0].preferredReportName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box py={1}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            size='small'
                            id='hba1c'
                            type='number'
                            value={hba1c}
                            onChange={(event) => {
                              setHba1c(
                                parseFloat(
                                  parseFloat(event.target.value).toFixed(2)
                                )
                              )
                            }}
                            error={hba1c > 5.6 || Number.isNaN(hba1c)}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1} px={0.5}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[1].obs[0].quantitativeDetails
                              .unit.coding
                              ? obsDataForLabTest[1].obs[0].quantitativeDetails
                                  .unit.coding[0].code ?? ''
                              : ''}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >{`< 5.7`}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item key={1}>
                    <Grid container direction='row' spacing={1} key={2}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box px={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[2].obs[0].preferredReportName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box py={1}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            size='small'
                            id='homo'
                            type='number'
                            value={homo}
                            onChange={(event) => {
                              setHomo(
                                parseFloat(
                                  parseFloat(event.target.value).toFixed(2)
                                )
                              )
                            }}
                            error={
                              (homo < 14 && homo > 0) ||
                              homo > 18 ||
                              Number.isNaN(homo)
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1} px={0.5}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[2].obs[0].quantitativeDetails
                              .unit.coding
                              ? obsDataForLabTest[2].obs[0].quantitativeDetails
                                  .unit.coding[0].code ?? ''
                              : ''}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            14 - 18
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item key={1}>
                    <Grid container direction='row' spacing={1} key={2}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box px={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[3].obs[0].preferredReportName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box py={1}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            type='number'
                            size='small'
                            id='pp'
                            value={pp}
                            onChange={(event) => {
                              setPP(
                                parseFloat(
                                  parseFloat(event.target.value).toFixed(2)
                                )
                              )
                            }}
                            error={
                              (pp < 100 && pp > 0) ||
                              pp > 140 ||
                              Number.isNaN(pp)
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1} px={0.5}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[3].obs[0].quantitativeDetails
                              .unit.coding
                              ? obsDataForLabTest[3].obs[0].quantitativeDetails
                                  .unit.coding[0].code ?? ''
                              : ''}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            100 - 140
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item key={1}>
                    <Grid container direction='row' spacing={1} key={2}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box px={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[4].obs[0].preferredReportName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box py={1}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            type='number'
                            id='serumC'
                            size='small'
                            value={serumC}
                            onChange={(event) => {
                              setSercumC(
                                parseFloat(
                                  parseFloat(event.target.value).toFixed(2)
                                )
                              )
                            }}
                            error={
                              (serumC < 0.5 && serumC > 0) ||
                              serumC > 2 ||
                              Number.isNaN(serumC)
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1} px={0.5}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[4].obs[0].quantitativeDetails
                              .unit.coding
                              ? obsDataForLabTest[4].obs[0].quantitativeDetails
                                  .unit.coding[0].code ?? ''
                              : ''}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            0.5 - 2
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item key={1}>
                    <Grid container direction='row' spacing={1} key={2}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box px={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[5].obs[0].preferredReportName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box py={1}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            type='number'
                            id='serumU'
                            size='small'
                            value={serumU}
                            onChange={(event) => {
                              setSerumU(
                                parseFloat(
                                  parseFloat(event.target.value).toFixed(2)
                                )
                              )
                            }}
                            error={
                              ((getGenderOfPatient(
                                fhirAppointmentDetails.patient
                              ) === 'male'
                                ? serumU < 0.7
                                : serumU < 0.5) &&
                                serumU > 0) ||
                              (getGenderOfPatient(
                                fhirAppointmentDetails.patient
                              ) === 'male'
                                ? serumU > 1.35
                                : serumU > 1.1) ||
                              Number.isNaN(serumU)
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1} px={0.5}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[5].obs[0].quantitativeDetails
                              .unit.coding
                              ? obsDataForLabTest[5].obs[0].quantitativeDetails
                                  .unit.coding[0].code ?? ''
                              : ''}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {`${
                              getGenderOfPatient(
                                fhirAppointmentDetails.patient
                              ) === 'male'
                                ? 0.7
                                : 0.5
                            } - ${
                              getGenderOfPatient(
                                fhirAppointmentDetails.patient
                              ) === 'male'
                                ? 1.35
                                : 1.1
                            }`}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item key={1}>
                    <Grid container direction='row' spacing={1} key={2}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box px={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[6].obs[0].preferredReportName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box py={1}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            type='number'
                            size='small'
                            id='serum'
                            value={serum}
                            onChange={(event) => {
                              setSerum(
                                parseFloat(
                                  parseFloat(event.target.value).toFixed(2)
                                )
                              )
                            }}
                            error={
                              (serum < 7 && serum > 0) ||
                              serum > 20 ||
                              Number.isNaN(serum)
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1} px={0.5}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {obsDataForLabTest[6].obs[0].quantitativeDetails
                              .unit.coding
                              ? obsDataForLabTest[6].obs[0].quantitativeDetails
                                  .unit.coding[0].code ?? ''
                              : ''}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <Box py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            7 - 20
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                aria-controls='panel1a-content'
                id='panel1a-header'
                expandIcon={<ExpandMore />}
              >
                <Typography className={classes.titleText}>Panel</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width='100%' display='flex' flexDirection='column'>
                  <Box display='flex' flexDirection='row' width='100%'>
                    {labTestSearchSlice.testData &&
                      labTestSearchSlice.testData.length > 0 && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          {labTestSearchSlice.testData.map((item, index) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              py={0.25}
                              key={item.name}
                            >
                              <Box border={1} py={0.5} width='100%'>
                                <Box py={1} px={1}>
                                  <Typography variant='subtitle1'>
                                    {item.name}
                                  </Typography>
                                </Box>

                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{ backgroundColor: ' #DADBDD' }}
                                >
                                  <Grid item xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Unit
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                {item.obsDef.map((obsData, index1) => (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    py={0.5}
                                    key={item.name}
                                  >
                                    <Grid item>
                                      <Box>
                                        <Box px={1}>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={1}
                                            key={2}
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {obsData.preferredReportName ??
                                                  ''}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                              <Box py={1}>
                                                {(item.name ===
                                                  'Complete Blood Counts - CBC' ||
                                                  item.name ===
                                                    'Liver Function Test') && (
                                                  <TextField
                                                    variant='outlined'
                                                    fullWidth
                                                    type='number'
                                                    size='small'
                                                    id={`cbc${index1}`}
                                                    value={
                                                      testData[index1]
                                                        ? testData[index1]
                                                            .quantity ??
                                                          undefined
                                                        : undefined
                                                    }
                                                    onChange={(event) => {
                                                      const values: ObsRecord[] =
                                                        [...testData]

                                                      values[index1].quantity =
                                                        parseFloat(
                                                          parseFloat(
                                                            event.target.value
                                                          ).toFixed(2)
                                                        )
                                                      values[index1].panelName =
                                                        item.name
                                                      values[index1].unit =
                                                        obsData.quantitativeDetails
                                                          ? obsData
                                                              .quantitativeDetails
                                                              .unit
                                                            ? obsData
                                                                .quantitativeDetails
                                                                .unit.coding![0]
                                                                .code!
                                                            : ''
                                                          : ''

                                                      values[index1].code =
                                                        obsData.code.coding![0]

                                                      setTestData(values)
                                                    }}
                                                    error={
                                                      testData[index1]
                                                        ? getErrorStatus(
                                                            obsData,
                                                            fhirAppointmentDetails.patient,
                                                            testData[index1]
                                                              .quantity!
                                                          )
                                                        : false
                                                    }
                                                    InputProps={{
                                                      inputProps: {
                                                        step: 0.1,
                                                      },
                                                    }}
                                                  />
                                                )}
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box py={1} px={1}>
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {obsData.quantitativeDetails
                                                    ? obsData
                                                        .quantitativeDetails
                                                        .unit
                                                      ? obsData
                                                          .quantitativeDetails
                                                          .unit.coding![0].code!
                                                      : ''
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box
                                                py={1}
                                                //   px={1}
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                paddingLeft={1}
                                              >
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` >= ${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}

                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) === undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` <= ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {`${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )} - ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    {labTestSearchSlice.lipidData &&
                      labTestSearchSlice.lipidData.length > 0 && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          {labTestSearchSlice.lipidData.map((item, index) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              py={0.25}
                              key={item.name}
                            >
                              <Box border={1} py={0.5} width='100%'>
                                <Box py={1} px={1}>
                                  <Typography variant='subtitle1'>
                                    {item.name}
                                  </Typography>
                                </Box>

                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{ backgroundColor: ' #DADBDD' }}
                                >
                                  <Grid item xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Unit
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                {item.obsDef.map((obsData, index1) => (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    py={0.5}
                                    key={item.name}
                                  >
                                    <Grid item>
                                      <Box>
                                        <Box px={1}>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={1}
                                            key={2}
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {obsData.preferredReportName ??
                                                  ''}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                              <Box py={1}>
                                                <TextField
                                                  variant='outlined'
                                                  fullWidth
                                                  type='number'
                                                  size='small'
                                                  id={`lipid${index1}`}
                                                  value={
                                                    lipdPanel[index1]
                                                      ? lipdPanel[index1]
                                                          .quantity ?? undefined
                                                      : undefined
                                                  }
                                                  onChange={(event) => {
                                                    const values: ObsRecord[] =
                                                      [...lipdPanel]
                                                    values[index1].quantity =
                                                      parseFloat(
                                                        parseFloat(
                                                          event.target.value
                                                        ).toFixed(2)
                                                      )
                                                    values[index1].panelName =
                                                      item.name
                                                    values[index1].unit =
                                                      obsData.quantitativeDetails
                                                        ? obsData
                                                            .quantitativeDetails
                                                            .unit
                                                          ? obsData
                                                              .quantitativeDetails
                                                              .unit.coding![0]
                                                              .code!
                                                          : ''
                                                        : ''

                                                    values[index1].code =
                                                      obsData.code.coding![0]

                                                    setLipidPanel(values)
                                                  }}
                                                  error={
                                                    lipdPanel[index1]
                                                      ? getErrorStatus(
                                                          obsData,
                                                          fhirAppointmentDetails.patient,
                                                          lipdPanel[index1]
                                                            .quantity!
                                                        )
                                                      : false
                                                  }
                                                  InputProps={{
                                                    inputProps: {
                                                      step: 0.1,
                                                    },
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box py={1} px={1}>
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {obsData.quantitativeDetails
                                                    ? obsData
                                                        .quantitativeDetails
                                                        .unit
                                                      ? obsData
                                                          .quantitativeDetails
                                                          .unit.coding![0].code!
                                                      : ''
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box
                                                py={1}
                                                //   px={1}
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                paddingLeft={1}
                                              >
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` >= ${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}

                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) === undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` <= ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {`${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )} - ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    {labTestSearchSlice.lftData &&
                      labTestSearchSlice.lftData.length > 0 && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          {labTestSearchSlice.lftData.map((item, index) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              py={0.25}
                              key={item.name}
                            >
                              <Box border={1} py={0.5} width='100%'>
                                <Box py={1} px={1}>
                                  <Typography variant='subtitle1'>
                                    {item.name}
                                  </Typography>
                                </Box>

                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{ backgroundColor: ' #DADBDD' }}
                                >
                                  <Grid item xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Unit
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                {item.obsDef.map((obsData, index1) => (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    py={0.5}
                                    key={item.name}
                                  >
                                    <Grid item>
                                      <Box>
                                        <Box px={1}>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={1}
                                            key={2}
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {obsData.preferredReportName ??
                                                  ''}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                              <Box py={1}>
                                                {(item.name ===
                                                  'CBC panel - Blood' ||
                                                  item.name ===
                                                    'Liver Function Test') && (
                                                  <TextField
                                                    variant='outlined'
                                                    fullWidth
                                                    type='number'
                                                    size='small'
                                                    id={`lft${index1}`}
                                                    value={
                                                      lftData[index1]
                                                        ? lftData[index1]
                                                            .quantity ??
                                                          undefined
                                                        : undefined
                                                    }
                                                    onChange={(event) => {
                                                      const values: ObsRecord[] =
                                                        [...lftData]
                                                      values[index1].quantity =
                                                        parseFloat(
                                                          parseFloat(
                                                            event.target.value
                                                          ).toFixed(2)
                                                        )
                                                      values[index1].panelName =
                                                        item.name
                                                      values[index1].unit =
                                                        obsData.quantitativeDetails
                                                          ? obsData
                                                              .quantitativeDetails
                                                              .unit
                                                            ? obsData
                                                                .quantitativeDetails
                                                                .unit.coding![0]
                                                                .code!
                                                            : ''
                                                          : ''

                                                      values[index1].code =
                                                        obsData.code.coding![0]
                                                      setLftData(values)
                                                    }}
                                                    error={
                                                      lftData[index1]
                                                        ? getErrorStatus(
                                                            obsData,
                                                            fhirAppointmentDetails.patient,
                                                            lftData[index1]
                                                              .quantity!
                                                          )
                                                        : false
                                                    }
                                                    InputProps={{
                                                      inputProps: {
                                                        step: 0.1,
                                                      },
                                                    }}
                                                  />
                                                )}
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box py={1} px={1}>
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {obsData.quantitativeDetails
                                                    ? obsData
                                                        .quantitativeDetails
                                                        .unit
                                                      ? obsData
                                                          .quantitativeDetails
                                                          .unit.coding![0].code!
                                                      : ''
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box
                                                py={1}
                                                //   px={1}
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                paddingLeft={1}
                                              >
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` >= ${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}

                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) === undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` <= ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {`${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )} - ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    {labTestSearchSlice.rftData &&
                      labTestSearchSlice.rftData.length > 0 && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          {labTestSearchSlice.rftData.map((item, index) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              py={0.25}
                              key={item.name}
                            >
                              <Box border={1} py={0.5} width='100%'>
                                <Box py={1} px={1}>
                                  <Typography variant='subtitle1'>
                                    {item.name}
                                  </Typography>
                                </Box>

                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{ backgroundColor: ' #DADBDD' }}
                                >
                                  <Grid item xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Unit
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                {item.obsDef.map((obsData, index1) => (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    py={0.5}
                                    key={item.name}
                                  >
                                    <Grid item>
                                      <Box>
                                        <Box px={1}>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={1}
                                            key={2}
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {obsData.preferredReportName ??
                                                  ''}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                              <Box py={1}>
                                                <TextField
                                                  variant='outlined'
                                                  fullWidth
                                                  type='number'
                                                  size='small'
                                                  id={`rft${index1}`}
                                                  value={
                                                    rftData[index1]
                                                      ? rftData[index1]
                                                          .quantity ?? undefined
                                                      : undefined
                                                  }
                                                  onChange={(event) => {
                                                    const values: ObsRecord[] =
                                                      [...rftData]
                                                    values[index1].quantity =
                                                      parseFloat(
                                                        parseFloat(
                                                          event.target.value
                                                        ).toFixed(2)
                                                      )
                                                    values[index1].panelName =
                                                      item.name
                                                    values[index1].unit =
                                                      obsData.quantitativeDetails
                                                        ? obsData
                                                            .quantitativeDetails
                                                            .unit
                                                          ? obsData
                                                              .quantitativeDetails
                                                              .unit.coding![0]
                                                              .code!
                                                          : ''
                                                        : ''

                                                    values[index1].code =
                                                      obsData.code.coding![0]
                                                    setRftData(values)
                                                  }}
                                                  error={
                                                    rftData[index1]
                                                      ? getErrorStatus(
                                                          obsData,
                                                          fhirAppointmentDetails.patient,
                                                          rftData[index1]
                                                            .quantity!
                                                        )
                                                      : false
                                                  }
                                                  InputProps={{
                                                    inputProps: {
                                                      step: 0.1,
                                                    },
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box py={1} px={1}>
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {obsData.quantitativeDetails
                                                    ? obsData
                                                        .quantitativeDetails
                                                        .unit
                                                      ? obsData
                                                          .quantitativeDetails
                                                          .unit.coding![0].code!
                                                      : ''
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box
                                                py={1}
                                                //   px={1}
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                paddingLeft={1}
                                              >
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` >= ${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}

                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) === undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` <= ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {`${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )} - ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    {labTestSearchSlice.tftData &&
                      labTestSearchSlice.tftData.length > 0 && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          {labTestSearchSlice.tftData.map((item, index) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              py={0.25}
                              key={item.name}
                            >
                              <Box border={1} py={0.5} width='100%'>
                                <Box py={1} px={1}>
                                  <Typography variant='subtitle1'>
                                    {item.name}
                                  </Typography>
                                </Box>

                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{ backgroundColor: ' #DADBDD' }}
                                >
                                  <Grid item xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Unit
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                {item.obsDef.map((obsData, index1) => (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    py={0.5}
                                    key={item.name}
                                  >
                                    <Grid item>
                                      <Box>
                                        <Box px={1}>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={1}
                                            key={2}
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {obsData.preferredReportName ??
                                                  ''}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                              <Box py={1}>
                                                <TextField
                                                  variant='outlined'
                                                  fullWidth
                                                  type='number'
                                                  id={`tft${index1}`}
                                                  size='small'
                                                  value={
                                                    tftData[index1]
                                                      ? tftData[index1]
                                                          .quantity ?? undefined
                                                      : undefined
                                                  }
                                                  onChange={(event) => {
                                                    const values: ObsRecord[] =
                                                      [...tftData]
                                                    values[index1].quantity =
                                                      parseFloat(
                                                        parseFloat(
                                                          event.target.value
                                                        ).toFixed(2)
                                                      )
                                                    values[index1].panelName =
                                                      item.name
                                                    values[index1].unit =
                                                      obsData.quantitativeDetails
                                                        ? obsData
                                                            .quantitativeDetails
                                                            .unit
                                                          ? obsData
                                                              .quantitativeDetails
                                                              .unit.coding![0]
                                                              .code!
                                                          : ''
                                                        : ''

                                                    values[index1].code =
                                                      obsData.code.coding![0]

                                                    setTftData(values)
                                                  }}
                                                  error={
                                                    tftData[index1]
                                                      ? getErrorStatus(
                                                          obsData,
                                                          fhirAppointmentDetails.patient,
                                                          tftData[index1]
                                                            .quantity!
                                                        )
                                                      : false
                                                  }
                                                  InputProps={{
                                                    inputProps: {
                                                      step: 0.1,
                                                    },
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box py={1} px={1}>
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {obsData.quantitativeDetails
                                                    ? obsData
                                                        .quantitativeDetails
                                                        .unit
                                                      ? obsData
                                                          .quantitativeDetails
                                                          .unit.coding![0].code!
                                                      : ''
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box
                                                py={1}
                                                //   px={1}
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                paddingLeft={1}
                                              >
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` >= ${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}

                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) === undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` <= ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {`${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )} - ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    {labTestSearchSlice.urineData &&
                      labTestSearchSlice.urineData.length > 0 && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          {labTestSearchSlice.urineData.map((item, index) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              py={0.25}
                              key={item.name}
                            >
                              <Box border={1} py={0.5} width='100%'>
                                <Box py={1} px={1}>
                                  <Typography variant='subtitle1'>
                                    {item.name}
                                  </Typography>
                                </Box>

                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{ backgroundColor: ' #DADBDD' }}
                                >
                                  <Grid item xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Unit
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                {item.obsDef.map((obsData, index1) => (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    py={0.5}
                                    key={item.name}
                                  >
                                    <Grid item>
                                      <Box>
                                        <Box px={1}>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={1}
                                            key={2}
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {obsData.preferredReportName ??
                                                  ''}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                              <Box py={1}>
                                                {obsData.permittedDataType &&
                                                  obsData
                                                    .permittedDataType[0] !==
                                                    'Quantity' && (
                                                    <TextField
                                                      variant='outlined'
                                                      fullWidth
                                                      size='small'
                                                      type='text'
                                                      id={`urine${index1}`}
                                                      value={
                                                        urineDataPanel[index1]
                                                          ? urineDataPanel[
                                                              index1
                                                            ].measureInString ??
                                                            ''
                                                          : ''
                                                      }
                                                      onChange={(event) => {
                                                        const values: ObsRecord[] =
                                                          [...urineDataPanel]
                                                        values[
                                                          index1
                                                        ].measureInString =
                                                          event.target.value

                                                        values[
                                                          index1
                                                        ].panelName = item.name
                                                        values[index1].unit =
                                                          obsData.quantitativeDetails
                                                            ? obsData
                                                                .quantitativeDetails
                                                                .unit
                                                              ? obsData
                                                                  .quantitativeDetails
                                                                  .unit
                                                                  .coding![0]
                                                                  .code!
                                                              : ''
                                                            : ''

                                                        values[index1].code =
                                                          obsData.code.coding![0]

                                                        setUrineDataPanel(
                                                          values
                                                        )
                                                      }}
                                                    />
                                                  )}

                                                {obsData.permittedDataType &&
                                                  obsData
                                                    .permittedDataType[0] ===
                                                    'Quantity' && (
                                                    <TextField
                                                      variant='outlined'
                                                      fullWidth
                                                      id={`urine${index1}`}
                                                      type='number'
                                                      size='small'
                                                      value={
                                                        urineDataPanel[index1]
                                                          ? urineDataPanel[
                                                              index1
                                                            ].quantity ??
                                                            undefined
                                                          : undefined
                                                      }
                                                      onChange={(event) => {
                                                        const values: ObsRecord[] =
                                                          [...urineDataPanel]
                                                        values[
                                                          index1
                                                        ].quantity = parseFloat(
                                                          parseFloat(
                                                            event.target.value
                                                          ).toFixed(2)
                                                        )
                                                        values[
                                                          index1
                                                        ].panelName = item.name
                                                        values[index1].unit =
                                                          obsData.quantitativeDetails
                                                            ? obsData
                                                                .quantitativeDetails
                                                                .unit
                                                              ? obsData
                                                                  .quantitativeDetails
                                                                  .unit
                                                                  .coding![0]
                                                                  .code!
                                                              : ''
                                                            : ''

                                                        values[index1].code =
                                                          obsData.code.coding![0]

                                                        setUrineDataPanel(
                                                          values
                                                        )
                                                      }}
                                                      error={
                                                        urineDataPanel[index1]
                                                          ? getErrorStatus(
                                                              obsData,
                                                              fhirAppointmentDetails.patient,
                                                              urineDataPanel[
                                                                index1
                                                              ].quantity!
                                                            )
                                                          : false
                                                      }
                                                      InputProps={{
                                                        inputProps: {
                                                          step: 0.1,
                                                        },
                                                      }}
                                                    />
                                                  )}
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box py={1} px={1}>
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {obsData.quantitativeDetails
                                                    ? obsData
                                                        .quantitativeDetails
                                                        .unit
                                                      ? obsData
                                                          .quantitativeDetails
                                                          .unit.coding![0].code!
                                                      : ''
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box
                                                py={1}
                                                //   px={1}
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                paddingLeft={1}
                                              >
                                                {obsData.permittedDataType &&
                                                  obsData
                                                    .permittedDataType[0] ===
                                                    'Quantity' &&
                                                  getMinError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` >= ${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}

                                                {obsData.permittedDataType &&
                                                  obsData
                                                    .permittedDataType[0] ===
                                                    'Quantity' &&
                                                  getMinError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` <= ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                                {obsData.permittedDataType &&
                                                  obsData
                                                    .permittedDataType[0] ===
                                                    'Quantity' &&
                                                  getMinError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {`${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )} - ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    {labTestSearchSlice.homa &&
                      labTestSearchSlice.homa.length > 0 && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          {labTestSearchSlice.homa.map((item, index) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              py={0.25}
                              key={item.name}
                            >
                              <Box border={1} py={0.5} width='100%'>
                                <Box py={1} px={1}>
                                  <Typography variant='subtitle1'>
                                    {item.name}
                                  </Typography>
                                </Box>

                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{ backgroundColor: ' #DADBDD' }}
                                >
                                  <Grid item xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Unit
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                {item.obsDef.map((obsData, index1) => (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    py={0.5}
                                    key={`${item.name}${index1}`}
                                  >
                                    <Grid item>
                                      <Box>
                                        <Box px={1}>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={1}
                                            key={2}
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {obsData.preferredReportName ??
                                                  ''}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                              <Box py={1}>
                                                <TextField
                                                  variant='outlined'
                                                  fullWidth
                                                  id={`homaPanel${index1}`}
                                                  type='number'
                                                  size='small'
                                                  value={
                                                    homaPanel[index1]
                                                      ? homaPanel[index1]
                                                          .quantity ?? undefined
                                                      : undefined
                                                  }
                                                  onChange={(event) => {
                                                    const values: ObsRecord[] =
                                                      [...homaPanel]

                                                    values[index1].quantity =
                                                      parseFloat(
                                                        parseFloat(
                                                          event.target.value
                                                        ).toFixed(2)
                                                      )
                                                    values[index1].panelName =
                                                      item.name
                                                    values[index1].unit =
                                                      obsData.quantitativeDetails
                                                        ? obsData
                                                            .quantitativeDetails
                                                            .unit
                                                          ? obsData
                                                              .quantitativeDetails
                                                              .unit.coding![0]
                                                              .code!
                                                          : ''
                                                        : ''

                                                    values[index1].code =
                                                      obsData.code.coding![0]

                                                    setHomaPanel(values)
                                                  }}
                                                  error={
                                                    homaPanel[index1]
                                                      ? getErrorStatus(
                                                          obsData,
                                                          fhirAppointmentDetails.patient,
                                                          homaPanel[index1]
                                                            .quantity!
                                                        )
                                                      : false
                                                  }
                                                  InputProps={{
                                                    inputProps: {
                                                      step: 0.1,
                                                    },
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box py={1} px={1}>
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {obsData.quantitativeDetails
                                                    ? obsData
                                                        .quantitativeDetails
                                                        .unit
                                                      ? obsData
                                                          .quantitativeDetails
                                                          .unit.coding![0].code!
                                                      : ''
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box
                                                py={1}
                                                //   px={1}
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                paddingLeft={1}
                                              >
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` >= ${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}

                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) === undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` <= ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                                {getMinError(
                                                  obsData,
                                                  fhirAppointmentDetails.patient
                                                ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {`${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )} - ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    {labTestSearchSlice.dexa &&
                      labTestSearchSlice.dexa.length > 0 && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          {labTestSearchSlice.dexa.map((item, index) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              py={0.25}
                              key={item.name}
                            >
                              <Box border={1} py={0.5} width='100%'>
                                <Box py={1} px={1}>
                                  <Typography variant='subtitle1'>
                                    {item.name}
                                  </Typography>
                                </Box>

                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{ backgroundColor: ' #DADBDD' }}
                                >
                                  <Grid item xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Unit
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                {item.obsDef.map((obsData, index1) => (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    py={0.5}
                                    key={item.name}
                                  >
                                    <Grid item>
                                      <Box>
                                        <Box px={1}>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={1}
                                            key={2}
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {obsData.preferredReportName ??
                                                  ''}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                              <Box py={1}>
                                                {obsData.permittedDataType &&
                                                  obsData
                                                    .permittedDataType[0] ===
                                                    'Quantity' && (
                                                    <TextField
                                                      variant='outlined'
                                                      fullWidth
                                                      id={`dexaNumber${index1}`}
                                                      type='number'
                                                      size='small'
                                                      value={
                                                        dexaPanel[index1]
                                                          ? dexaPanel[index1]
                                                              .quantity ??
                                                            undefined
                                                          : undefined
                                                      }
                                                      onChange={(event) => {
                                                        const values: ObsRecord[] =
                                                          [...dexaPanel]
                                                        values[
                                                          index1
                                                        ].quantity = parseFloat(
                                                          parseFloat(
                                                            event.target.value
                                                          ).toFixed(2)
                                                        )
                                                        values[
                                                          index1
                                                        ].panelName = item.name
                                                        values[index1].unit =
                                                          obsData.quantitativeDetails
                                                            ? obsData
                                                                .quantitativeDetails
                                                                .unit
                                                              ? obsData
                                                                  .quantitativeDetails
                                                                  .unit
                                                                  .coding![0]
                                                                  .code!
                                                              : ''
                                                            : ''

                                                        values[index1].code =
                                                          obsData.code.coding![0]

                                                        setDexaPanel(values)
                                                      }}
                                                      error={
                                                        dexaPanel[index1]
                                                          ? getErrorStatus(
                                                              obsData,
                                                              fhirAppointmentDetails.patient,
                                                              dexaPanel[index1]
                                                                .quantity!
                                                            )
                                                          : false
                                                      }
                                                      InputProps={{
                                                        inputProps: {
                                                          step: 0.1,
                                                        },
                                                      }}
                                                    />
                                                  )}
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box py={1} px={1}>
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {obsData.quantitativeDetails
                                                    ? obsData
                                                        .quantitativeDetails
                                                        .unit
                                                      ? obsData
                                                          .quantitativeDetails
                                                          .unit.coding![0].code!
                                                      : ''
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <Box
                                                py={1}
                                                //   px={1}
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                paddingLeft={1}
                                              >
                                                {obsData.permittedDataType &&
                                                  obsData
                                                    .permittedDataType[0] ===
                                                    'Quantity' &&
                                                  getMinError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` >= ${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}

                                                {obsData.permittedDataType &&
                                                  obsData
                                                    .permittedDataType[0] ===
                                                    'Quantity' &&
                                                  getMinError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) === undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {` <= ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                                {obsData.permittedDataType &&
                                                  obsData
                                                    .permittedDataType[0] ===
                                                    'Quantity' &&
                                                  getMinError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined &&
                                                  getHighError(
                                                    obsData,
                                                    fhirAppointmentDetails.patient
                                                  ) !== undefined && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {`${getMinError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )} - ${getHighError(
                                                        obsData,
                                                        fhirAppointmentDetails.patient
                                                      )}`}
                                                    </Typography>
                                                  )}
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    {labTestSearchSlice.serumElectraLytes &&
                      labTestSearchSlice.serumElectraLytes.length > 0 && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          {labTestSearchSlice.serumElectraLytes.map(
                            (item, index) => (
                              <Box
                                display='flex'
                                flexDirection='column'
                                py={0.25}
                                key={item.name}
                              >
                                <Box border={1} py={0.5} width='100%'>
                                  <Box py={1} px={1}>
                                    <Typography variant='subtitle1'>
                                      {item.name}
                                    </Typography>
                                  </Box>

                                  <Grid
                                    container
                                    direction='row'
                                    id='vitals'
                                    style={{ backgroundColor: ' #DADBDD' }}
                                  >
                                    <Grid item xs={4}>
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='column'
                                          width='100%'
                                          flexGrow={1}
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Parameter Name
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          display='flex'
                                          width='2%'
                                          paddingTop={0.1}
                                        >
                                          <Divider
                                            orientation='vertical'
                                            flexItem
                                            style={{
                                              height: '30px',

                                              width: '2px',
                                              backgroundColor: 'white',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='column'
                                          width='100%'
                                          flexGrow={1}
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Value
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          display='flex'
                                          width='2%'
                                          paddingTop={0.1}
                                        >
                                          <Divider
                                            orientation='vertical'
                                            flexItem
                                            style={{
                                              height: '30px',

                                              width: '2px',
                                              backgroundColor: 'white',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item key={1} xs={2}>
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='column'
                                          width='100%'
                                          flexGrow={1}
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Unit
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          display='flex'
                                          width='2%'
                                          paddingTop={0.1}
                                        >
                                          <Divider
                                            orientation='vertical'
                                            flexItem
                                            style={{
                                              height: '30px',

                                              width: '2px',
                                              backgroundColor: 'white',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item key={1} xs={3}>
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='column'
                                          width='100%'
                                          flexGrow={1}
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Normal Range
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  {item.obsDef.map((obsData, index1) => (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      py={0.5}
                                      key={`${item.name}${index1}`}
                                    >
                                      <Grid item>
                                        <Box>
                                          <Box px={1}>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={1}
                                              key={2}
                                            >
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  alignSelf: 'center',
                                                }}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {obsData.preferredReportName ??
                                                    ''}
                                                </Typography>
                                              </Grid>

                                              <Grid item xs={3}>
                                                <Box py={1}>
                                                  {obsData.permittedDataType &&
                                                    obsData
                                                      .permittedDataType[0] ===
                                                      'Quantity' && (
                                                      <TextField
                                                        variant='outlined'
                                                        fullWidth
                                                        id={`serumLytesNumber${index1}`}
                                                        type='number'
                                                        size='small'
                                                        value={
                                                          serumLytes[index1]
                                                            ? serumLytes[index1]
                                                                .quantity ??
                                                              undefined
                                                            : undefined
                                                        }
                                                        onChange={(event) => {
                                                          const values: ObsRecord[] =
                                                            [...serumLytes]
                                                          values[
                                                            index1
                                                          ].quantity = parseFloat(
                                                            parseFloat(
                                                              event.target.value
                                                            ).toFixed(2)
                                                          )
                                                          values[
                                                            index1
                                                          ].panelName =
                                                            item.name
                                                          values[index1].unit =
                                                            obsData.quantitativeDetails
                                                              ? obsData
                                                                  .quantitativeDetails
                                                                  .unit
                                                                ? obsData
                                                                    .quantitativeDetails
                                                                    .unit
                                                                    .coding![0]
                                                                    .code!
                                                                : ''
                                                              : ''

                                                          values[index1].code =
                                                            obsData.code.coding![0]

                                                          setSerumLytes(values)
                                                        }}
                                                        error={
                                                          dexaPanel[index1]
                                                            ? getErrorStatus(
                                                                obsData,
                                                                fhirAppointmentDetails.patient,
                                                                serumLytes[
                                                                  index1
                                                                ].quantity!
                                                              )
                                                            : false
                                                        }
                                                        InputProps={{
                                                          inputProps: {
                                                            step: 0.1,
                                                          },
                                                        }}
                                                      />
                                                    )}
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={2}
                                                style={{
                                                  alignSelf: 'center',
                                                }}
                                              >
                                                <Box py={1} px={1}>
                                                  <Typography
                                                    variant='subtitle2'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {obsData.quantitativeDetails
                                                      ? obsData
                                                          .quantitativeDetails
                                                          .unit
                                                        ? obsData
                                                            .quantitativeDetails
                                                            .unit.coding![0]
                                                            .code!
                                                        : ''
                                                      : ''}
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={3}
                                                style={{
                                                  alignSelf: 'center',
                                                }}
                                              >
                                                <Box
                                                  py={1}
                                                  //   px={1}
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  paddingLeft={1}
                                                >
                                                  {obsData.permittedDataType &&
                                                    obsData
                                                      .permittedDataType[0] ===
                                                      'Quantity' &&
                                                    getMinError(
                                                      obsData,
                                                      fhirAppointmentDetails.patient
                                                    ) !== undefined &&
                                                    getHighError(
                                                      obsData,
                                                      fhirAppointmentDetails.patient
                                                    ) === undefined && (
                                                      <Typography
                                                        variant='subtitle2'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 12,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        {` >= ${getMinError(
                                                          obsData,
                                                          fhirAppointmentDetails.patient
                                                        )}`}
                                                      </Typography>
                                                    )}

                                                  {obsData.permittedDataType &&
                                                    obsData
                                                      .permittedDataType[0] ===
                                                      'Quantity' &&
                                                    getMinError(
                                                      obsData,
                                                      fhirAppointmentDetails.patient
                                                    ) === undefined &&
                                                    getHighError(
                                                      obsData,
                                                      fhirAppointmentDetails.patient
                                                    ) !== undefined && (
                                                      <Typography
                                                        variant='subtitle2'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 12,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        {` <= ${getHighError(
                                                          obsData,
                                                          fhirAppointmentDetails.patient
                                                        )}`}
                                                      </Typography>
                                                    )}
                                                  {obsData.permittedDataType &&
                                                    obsData
                                                      .permittedDataType[0] ===
                                                      'Quantity' &&
                                                    getMinError(
                                                      obsData,
                                                      fhirAppointmentDetails.patient
                                                    ) !== undefined &&
                                                    getHighError(
                                                      obsData,
                                                      fhirAppointmentDetails.patient
                                                    ) !== undefined && (
                                                      <Typography
                                                        variant='subtitle2'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 12,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        {`${getMinError(
                                                          obsData,
                                                          fhirAppointmentDetails.patient
                                                        )} - ${getHighError(
                                                          obsData,
                                                          fhirAppointmentDetails.patient
                                                        )}`}
                                                      </Typography>
                                                    )}
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Box>
                                      </Grid>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            )
                          )}
                        </Box>
                      )}
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {addLabTestSliceIPD.adding && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Button
          onClick={() => {
            onClose()
            dispatch(resetAddVitalsState())
            resetDetails()
          }}
          id='cancel'
          variant='outlined'
          disabled={addLabTestSliceIPD.adding}
          disableElevation
        >
          {t('labelCommon:cancel')}
        </Button>

        <Button
          onClick={() => {
            // const result: LabTestReport[] = labTest.filter(
            //   (person, index) =>
            //     index ===
            //     labTest.findIndex((other) => person.name === other.name)
            // )

            dispatch(
              addLabTestIPD(
                reportDate,
                fhirAppointmentDetails,
                testData,
                lipdPanel,
                lftData,
                rftData,
                tftData,
                urineDataPanel,
                homaPanel,
                dexaPanel,
                serumLytes,
                bloodSugar,
                hba1c,
                homo,
                pp,
                serumC,
                serum,
                serumU,
                r3,
                r4
              )
            )
          }}
          disabled={
            (bloodSugar === 0 &&
              hba1c === 0 &&
              homo === 0 &&
              pp === 0 &&
              serumC === 0 &&
              serum === 0 &&
              serumU === 0 &&
              checkCBCPanel().length === 0 &&
              checkLipid().length === 0 &&
              checkTft().length === 0 &&
              checkLft().length === 0 &&
              checkRft().length === 0 &&
              checkHoma().length === 0 &&
              checkDexa().length === 0 &&
              checkSerumLytes().length === 0 &&
              checkUrine().length === 0) ||
            (Number.isNaN(bloodSugar) &&
              Number.isNaN(hba1c) &&
              Number.isNaN(homo) &&
              Number.isNaN(serumC) &&
              Number.isNaN(pp)) ||
            addLabTestSliceIPD.adding
          }
          variant='contained'
          color='primary'
          id='add'
          disableElevation
        >
          {t('labelCommon:add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
