import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { SubstanceDataUpdate } from 'models/substanceDataUpdate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// import { getSubstanceBundleUpdate } from 'utils/labHelpers/uploadHelper';
import { updateCatalog } from 'redux/valueSet/substanceList/addSubstanceCatalougeSlice'
import { unitCodes } from 'utils/constants'
import { getSubstanceBundleUpdate } from 'utils/labHelpers/uploadHelper'
import { WelloFormItemLabel, WelloTextField } from 'wello-web-components'

export interface EditSubstanceProps {
  open: boolean
  onCancelClick: () => void
  selectedValue: SubstanceDataUpdate
}

export interface DeleteSubstanceProps {
  open: boolean
  onCancelClick: () => void
}

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: theme.palette.primary.contrastText,
  },
  popupIndicator: {
    color: theme.palette.primary.contrastText,
  },
  root: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}))

export function EditSubstance(props: EditSubstanceProps) {
  const { open, selectedValue, onCancelClick: onClose } = props

  const substanceDetails = selectedValue

  const dispatch = useDispatch()

  const [hasErrors, setHasErrors] = React.useState<boolean>(false)

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  const [substanceData, setSubstanceData] = React.useState<SubstanceDataUpdate>(
    {
      id: substanceDetails?.id,
      name: substanceDetails?.name,
      price: substanceDetails?.price ?? 0,
      unit: substanceDetails?.unit,
      unitCoding: substanceDetails?.unitCoding,
      substance: substanceDetails?.substance,
    }
  )

  function handleNameChange(nameSubstance: string) {
    setSubstanceData({ ...substanceData, name: nameSubstance })
  }

  function handlePriceChange(priceSubstance: number) {
    setSubstanceData({ ...substanceData, price: priceSubstance })
  }

  function handleUnitChange(unitSubstance: R4.ICoding) {
    setSubstanceData({ ...substanceData, unitCoding: unitSubstance })
  }

  function handleSubmit() {
    let hasPriceErrorInData: boolean = false
    let hasNameErrorInData: boolean = false

    if (
      !substanceData.price ||
      substanceData.price <= 0 ||
      substanceData.price > 10000 ||
      Number.isNaN(substanceData.price)
    ) {
      hasPriceErrorInData = true
    } else {
      hasPriceErrorInData = false
    }

    //   if (
    //     !substanceData.name || (substanceData.name.length > 100)
    //   ) {
    //     hasPriceErrorInData = true
    //   } else {
    //     hasPriceErrorInData = false
    //   }

    if (
      substanceData.name?.length === 0 ||
      !substanceData.name?.trim() ||
      substanceData.name?.length > 100
    ) {
      hasNameErrorInData = true
    } else {
      hasNameErrorInData = false
    }

    if (!hasPriceErrorInData && !hasNameErrorInData) {
      const bundleData = getSubstanceBundleUpdate(substanceData)

      if (bundleData) {
        dispatch(updateCatalog(bundleData, '', 'Substance'))
      }
    }
  }

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Update Consumable')}
      </DialogTitle>
      <DialogContent>
        <Box marginX={2} marginY={1}>
          <Box display='flex' width='100%' flexDirection='column' flexGrow={1}>
            <WelloTextField
              title='Consumable'
              textProps={{
                defaultValue: selectedValue?.name,
                type: 'text',
                placeholder: 'Substance',
                onChange: (changeName) => {
                  handleNameChange(changeName.target.value)
                },
                inputProps: {
                  maxLength: 110,
                },
                error:
                  substanceData.name?.length === 0 ||
                  !substanceData.name?.trim() ||
                  substanceData.name?.length > 100,
                helperText:
                  substanceData.name?.length === 0 ||
                  !substanceData.name?.trim() ||
                  substanceData.name?.length > 100
                    ? 'Consumable Name is required and characters between 1 and 100'
                    : '',
              }}
            />
          </Box>
          <Box display='flex' width='100%' flexDirection='column' flexGrow={1}>
            <WelloTextField
              title='Price'
              textProps={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box>
                        {' '}
                        <Typography variant='subtitle2' align='center'>
                          {' '}
                          &#x20b9;
                        </Typography>
                      </Box>
                    </InputAdornment>
                  ),
                },
                inputProps: {
                  max: 10000,
                  maxLength: 5,
                },
                error:
                  substanceData.price <= 0 ||
                  substanceData.price > 10000 ||
                  Number.isNaN(substanceData.price),
                helperText:
                  substanceData.price <= 0 || substanceData.price > 10000
                    ? 'Price should be between 1 to 10000'
                    : '',
                defaultValue: substanceData.price,
                type: 'number',
                placeholder: 'Price',
                onChange: (changePrice) => {
                  handlePriceChange(parseInt(changePrice.target.value, 10))
                },
              }}
            />
          </Box>

          <Box display='flex' width='100%' flexDirection='column' flexGrow={1}>
            <Box display='flex' flexDirection='row'>
              <WelloFormItemLabel title={t('labelCommon: Unit')} />{' '}
            </Box>
            <Box width='100%'>
              <Autocomplete
                id='substance-unit'
                multiple={false}
                onOpen={() => {}}
                style={{
                  borderRadius: '4px',
                }}
                disableClearable
                fullWidth
                size='small'
                getOptionSelected={(option, value) =>
                  option.display === value.display
                }
                disabled={false}
                defaultValue={selectedValue?.unitCoding}
                getOptionLabel={(option) => option.display || ''}
                options={unitCodes}
                autoComplete
                includeInputInList
                filterSelectedOptions
                onChange={(e, changedValue) => {
                  if (changedValue != null) {
                    handleUnitChange(changedValue)
                  }
                }}
                ChipProps={{
                  // deleteIcon: (
                  //   <ClearOutlined
                  //     style={{
                  //       height: '15px',
                  //       color: 'white',
                  //     }}
                  //   />
                  // ),
                  style: {
                    backgroundColor: kPrimaryLight,
                    borderRadius: '4px',
                  },
                }}
                renderOption={(option, { inputValue }) => {
                  const matches = match(option.display ?? '', inputValue)
                  const parts = parse(option.display ?? '', matches)
                  return (
                    <ListItem id='data-id'>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={part.text}
                            style={{
                              backgroundColor: part.highlight
                                ? kPrimaryLight
                                : undefined,
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </ListItem>
                  )
                }}
                filterOptions={(x) => x}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                    //   InputProps={{
                    //   ...params.InputProps,
                    //     style: { height: 36}
                    //   }}
                    //   style={{ height: 10 }}
                    variant='outlined'
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button
            variant='contained'
            disableElevation
            onClick={() => {
              handleSubmit()
            }}
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export function DeleteSubstance(props: DeleteSubstanceProps) {
  const { open, onCancelClick: onClose } = props

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Delete Consumable')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
        >
          <Box display='flex' px={2} py={1} width='100%'>
            <Typography>
              Are you sure you want to delete the Consumable?
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button variant='contained' disableElevation>
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
