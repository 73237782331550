/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ObservationStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  Typography,
  makeStyles,
  Theme,
  TextField,
} from '@material-ui/core'
import {
  CloseOutlined,
  Delete,
  DeleteOutlineOutlined,
  ClearOutlined,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight, kDialogueBackground } from 'configs/styles/muiThemes'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { UmlResponse } from 'models/umlResponse'
import { WelloAddiction } from 'models/WelloConditions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  addAddictionsOP,
  createBundleObjectForObservations,
  resetAddictions,
} from 'redux/fhirMedicalResources/opd/addictions/addictionsAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  breathe,
  drugCount,
  drugOpinion,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import { updateAddictions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { RadioRaisedButton, WelloLoadingIndicator } from 'wello-web-components'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { AllergyMasterChosser } from '../subjective/allergyMasterChosser'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onAddictionAdded: () => void
  existingAddictions: WelloAddiction
}
export const EditAddictions: React.FC<Props> = ({
  open,
  onClose,
  onAddictionAdded,
  fhirAppointmentDetails,
  existingAddictions,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '30%',
    },
  }))
  const classes = useStyles()
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [currentCondition, setCurrentCondition] = useState<WelloAddiction>({
    id: existingAddictions.id,
    existingObs: existingAddictions.existingObs,
    tobacoCondition: existingAddictions.tobacoCondition,
    smoking: existingAddictions.smoking,
    alcoholCondition: existingAddictions.alcoholCondition,
    drugs: existingAddictions.drugs,
  })
  const dispatch = useDispatch()

  const addAddictionsIPD = useSelector(
    (state: RootState) => state.addAddictionsOPD
  )
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  function updateObsFinal() {
    setUpdateStatus({ requesting: true })
    const oldObsList: R4.IObservation[] = []
    if (
      currentCondition.existingObs &&
      currentCondition.existingObs.length > 0
    ) {
      for (let i = 0; i < currentCondition.existingObs!.length; i++) {
        const oldProcedure: R4.IObservation = {
          ...currentCondition.existingObs![i],
        }
        oldProcedure.status = ObservationStatusKind._enteredInError
        oldObsList.push(oldProcedure)
      }
    }
    console.log(currentCondition)
    const bundleObject: R4.IBundle = createBundleObjectForObservations(
      fhirAppointmentDetails,
      currentCondition.smoking,
      currentCondition.tobacoCondition,
      currentCondition.alcoholCondition,
      undefined,
      undefined,
      currentCondition.drugs
    )

    for (let i = 0; i < oldObsList.length; i++) {
      bundleObject.entry?.push({
        resource: oldObsList[i],
        fullUrl: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        },
      })
    }

    setLoading(true)

    updateAddictions(bundleObject, fhirAppointmentDetails.appointment.id!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Addiction details updated successfully'))
          onAddictionAdded()
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Addiction details. Please try again later'
            )
          )
        }
      }
    )
  }

  useEffect(() => {
    setCurrentCondition({
      id: existingAddictions.id,
      existingObs: existingAddictions.existingObs,
      tobacoCondition: existingAddictions.tobacoCondition,
      smoking: existingAddictions.smoking,
      alcoholCondition: existingAddictions.alcoholCondition,
      drugs: existingAddictions.drugs,
    })
  }, [existingAddictions])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Edit Addictions</DialogTitle>
      <DialogContent dividers={true}>
        {loading && <WelloLoadingIndicator message='Adding details' />}

        {!loading && (
          <Box marginX={2} marginY={1}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                {addictions.map((val) => (
                  <Box display='flex' flexDirection='column' width='100%'>
                    {val.code === '68518-0' && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='How often do you have a drink containing alcohol' />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          id='hab_10'
                          fullWidth
                          size='small'
                          componentName='unit'
                          defaultValue={currentCondition.alcoholCondition}
                          autoFocus
                          disableClearable
                          multiple={false}
                          getOptionSelected={(option, valueData) =>
                            option.display === valueData.display
                          }
                          getOptionLabel={(option) => option.display ?? ''}
                          options={alcoholCount}
                          onChange={(e, type, reason) => {
                            if (type) {
                              setCurrentCondition({
                                ...currentCondition,
                                alcoholCondition: type,
                              })
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.display}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select'
                              fullWidth
                              variant='outlined'
                              id='hab_10'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                      </Box>
                    )}

                    {/* {val.code === '10000-2' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='How many cups of coffee do you drink per day'
                          availableCodings={teaCount}
                          onChanged={(type) => {
                            setCofee(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        />
                      </Box>
                    )} */}
                    {val.code === '96103-7' && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Tobacco/Paan/Gutkha per day' />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          id='hab_10'
                          fullWidth
                          size='small'
                          componentName='unit'
                          defaultValue={currentCondition.tobacoCondition}
                          autoFocus
                          disableClearable
                          multiple={false}
                          getOptionSelected={(option, valueData) =>
                            option.display === valueData.display
                          }
                          getOptionLabel={(option) => option.display ?? ''}
                          options={tobacoCount}
                          onChange={(e, type, reason) => {
                            if (type) {
                              setCurrentCondition({
                                ...currentCondition,
                                tobacoCondition: type,
                              })
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.display}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select'
                              fullWidth
                              variant='outlined'
                              id='hab_10'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />

                        {/* <WelloSelectFHIRCoding
                          title='Tobacco/Paan/Gutkha per day'
                          availableCodings={tobacoCount}
                          onChanged={(type) => {
                            setCurrentCondition({
                              ...currentCondition,
                              tobacoCondition: type,
                            })
                          }}
                          id='addictions_option2'
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                          preSelectedCoding={currentCondition.tobacoCondition}
                        /> */}
                      </Box>
                    )}

                    {val.code === '10000-3' && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Do you take drugs' />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          id='hab_10'
                          fullWidth
                          size='small'
                          componentName='unit'
                          defaultValue={currentCondition.drugs}
                          autoFocus
                          disableClearable
                          multiple={false}
                          getOptionSelected={(option, valueData) =>
                            option.display === valueData.display
                          }
                          getOptionLabel={(option) => option.display ?? ''}
                          options={drugOpinion}
                          onChange={(e, type, reason) => {
                            if (type) {
                              setCurrentCondition({
                                ...currentCondition,
                                drugs: type,
                              })
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.display}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select'
                              fullWidth
                              variant='outlined'
                              id='hab_10'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />

                        {/* <WelloSelectFHIRCoding
                          title='Do you take drugs'
                          id='addictions_option3'
                          availableCodings={drugOpinion}
                          onChanged={(type) => {
                            setCurrentCondition({
                              ...currentCondition,
                              drugs: type,
                            })
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                          preSelectedCoding={currentCondition.drugs}
                        /> */}
                      </Box>
                    )}

                    {val.code === '64218-1' && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Do you take drugs' />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          id='hab_10'
                          fullWidth
                          size='small'
                          componentName='unit'
                          defaultValue={currentCondition.smoking}
                          autoFocus
                          disableClearable
                          multiple={false}
                          getOptionSelected={(option, valueData) =>
                            option.display === valueData.display
                          }
                          getOptionLabel={(option) => option.display ?? ''}
                          options={smokeCounts}
                          onChange={(e, type, reason) => {
                            if (type) {
                              setCurrentCondition({
                                ...currentCondition,
                                smoking: type,
                              })
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.display}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select'
                              fullWidth
                              variant='outlined'
                              id='hab_10'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                        {/* <WelloSelectFHIRCoding
                          title='How many cigarettes do you smoke per day now'
                          availableCodings={smokeCounts}
                          id='addictions_option4'
                          onChanged={(type) => {
                            setCurrentCondition({
                              ...currentCondition,
                              smoking: type,
                            })
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                          preSelectedCoding={currentCondition.smoking}
                        /> */}
                      </Box>
                    )}

                    {/* {val.code === '10000-1' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='How many cups of tea do you drink per day'
                          availableCodings={teaCount}
                          onChanged={(type) => {
                            setTea(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        />
                      </Box>
                    )} */}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              onClose()
            }}
            disabled={loading}
            variant='outlined'
            disableElevation
            id='cancsel_addictions'
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              updateObsFinal()
            }}
            variant='contained'
            color='primary'
            id='save_addiction'
            disableElevation
            disabled={loading}
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
