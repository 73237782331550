import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import femaleIcon from '../../../assets/icons/f.png'
import maleIcon from '../../../assets/icons/m.png'
import { PatientDataWithAppointment } from '../../../models/patientDataWithAppointment'

export interface TabProps {
  open: boolean
  onClose: () => void
  onDiscard: () => void
  displayText: string
  title: string
  doneButtonText: string
  cancelButtonText: string
  patientData?: PatientDataWithAppointment
}

export const PatientProfile: React.FC<TabProps> = ({
  open,
  onClose,
  onDiscard,
  displayText,
  title,
  doneButtonText,
  cancelButtonText,
  patientData,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>{t(`${title}`)}</DialogTitle>
      <DialogContent dividers={true}>
        <Box
          display='flex'
          flexDirection='Column'
          // paddingX={2}
          alignContent='center'
          width='100%'
        >
          <Box width='100%'>
            <Typography variant='subtitle1' color='primary'>
              Patient Details
            </Typography>
            <Paper>
              <Box display='flex' paddingTop={1} px={1}>
                <Box paddingRight={2}>
                  <Avatar alt='Profile' src={patientData?.profilePic} />
                </Box>

                <Box display='flex' flexDirection='column'>
                  <Box display='flex' flexDirection='row' alignItems='center'>
                    <Box paddingRight={2}>
                      <Typography variant='subtitle1' color='primary'>
                        {patientData?.patientName}
                      </Typography>
                    </Box>
                    <Paper
                      style={{ height: 30, width: 59, textAlign: 'center' }}
                    >
                      <Box paddingTop={1}>
                        {patientData?.gender === 'male' && (
                          <Box display='flex' alignItems='center' px={0.2}>
                            <Box paddingLeft={0.3}>
                              <img
                                src={maleIcon}
                                alt=''
                                style={{ height: 15 }}
                              />
                            </Box>
                            <Typography
                              variant='subtitle2'
                              gutterBottom
                              color='primary'
                            >
                              &nbsp; Male
                            </Typography>
                          </Box>
                        )}
                        {patientData?.gender === 'female' && (
                          <Box display='flex' alignItems='center'>
                            <Box paddingLeft={0.3}>
                              <img
                                src={femaleIcon}
                                alt=''
                                style={{ height: 15 }}
                              />
                            </Box>
                            <Typography
                              variant='subtitle2'
                              gutterBottom
                              color='primary'
                            >
                              &nbsp; Female
                            </Typography>
                          </Box>
                        )}
                        {patientData?.gender === 'other' && (
                          <Box display='flex' alignItems='center'>
                            <Typography
                              variant='subtitle2'
                              gutterBottom
                              color='primary'
                            >
                              &nbsp; Other
                            </Typography>
                          </Box>
                        )}
                        {patientData?.gender === 'unknown' && (
                          <Box display='flex' alignItems='center'>
                            <Typography
                              variant='subtitle2'
                              gutterBottom
                              color='primary'
                            >
                              &nbsp; Unknown
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Paper>
                  </Box>

                  <Box paddingY={0.5}>
                    {patientData && (
                      <Typography variant='subtitle2' color='primary'>
                        {patientData.labReference ?? ''}
                      </Typography>
                    )}
                  </Box>
                  <Box paddingY={0.5}>
                    {patientData && (
                      <Typography variant='subtitle2' color='primary'>
                        {patientData.age}
                        {patientData.age > 1 ? 'y' : 'y'}
                      </Typography>
                    )}
                  </Box>
                  <Box paddingBottom={0.5}>
                    <Typography variant='subtitle2' color='primary'>
                      {patientData?.maritalStaTus}
                    </Typography>
                  </Box>
                  <Box paddingBottom={0.5}>
                    <Typography variant='subtitle2' color='primary'>
                      {patientData?.phoneNumber}
                    </Typography>
                  </Box>
                  <Box paddingBottom={0.5}>
                    <Typography variant='subtitle2' color='primary'>
                      {patientData?.emailAddress}
                    </Typography>
                  </Box>
                  <Typography variant='subtitle2' color='primary'>
                    {patientData?.address}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          variant='outlined'
          disableElevation
        >
          <Typography variant='button'>{t(`${cancelButtonText}`)}</Typography>
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            onDiscard()
          }}
          variant='contained'
          color='primary'
        >
          <Typography variant='button'>{t(`${doneButtonText}`)}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
