import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getWellnessServiceRequestsOfAppointment } from 'redux/consultation/wellness_referrals_list/wellnessReferralsListSlice'
import { RootState } from 'redux/rootReducer'
import { WellnessTabularTile } from '../assessment/wellnessReferralTabularTile'
import { WellNessReferralTile } from './wellnessReferralTile'

interface Props {
  fhirAppointmentDetails: FhirAppointmentDetail
  followUp?: boolean
}

export const WellnessReferralsList: React.FC<Props> = ({
  fhirAppointmentDetails,
  followUp,
}: Props) => {
  const labDiagnosticsListSlice = useSelector(
    (state: RootState) => state.wellnessReferralsListSlice
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      getWellnessServiceRequestsOfAppointment(fhirAppointmentDetails, followUp)
    )
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(labDiagnosticsListSlice.updatingImpressions ||
        labDiagnosticsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}
      {/* {labDiagnosticsListSlice.noResultsAvailable && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            No data available
          </Typography>
        </Box>
      )} */}
      {labDiagnosticsListSlice.lanDiagnosticRequests &&
        labDiagnosticsListSlice.lanDiagnosticRequests.length > 0 && (
          <Box marginY={1} display='flex' flexDirection='column' width='100%'>
            <WellnessTabularTile
              referrals={labDiagnosticsListSlice.lanDiagnosticRequests}
            />
            {/* {labDiagnosticsListSlice.lanDiagnosticRequests.map(
              (e: R4.IServiceRequest) => (
                <WellNessReferralTile medication={e} key={`well_ref_${e.id}`} />
              )
            )} */}
          </Box>
        )}
    </Box>
  )
}
