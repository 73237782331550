import {
  Box,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { uploadingDocument } from 'redux/lab/upload/uploadReportSlice'
import { RootState } from 'redux/rootReducer'

interface Props {
  files: any
  labOrderDetails: FhirLabOrderDetail
  onUpload?: () => void
}

const useStyles = makeStyles((theme) => ({
  test: {
    border: '1px',
    backgroundColor: 'white',
  },
  root: {
    width: 100,
  },
}))

const StyledLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
})(LinearProgress)
export const ReportDataUploadSuccess: React.FC<Props> = ({
  files,
  labOrderDetails,
  onUpload,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [filesData, setFilesData] = React.useState(files)

  const uploadReportSlice = useSelector(
    (state: RootState) => state.uploadReportSlice
  )
  const [uploadOrDownloadCount, setUploadOrDownloadCount] = React.useState(5)

  useEffect(() => {
    if (files) {
      dispatch(uploadingDocument(files, labOrderDetails))
    }
  }, [dispatch, files])

  useEffect(() => {
    if (uploadReportSlice.uploadingSuccessful) {
      if (onUpload) onUpload()
    }
  }, [onUpload, uploadReportSlice.uploadingSuccessful])

  React.useEffect(() => {
    const timer = setInterval(() => {
      setUploadOrDownloadCount((beforeValue) =>
        beforeValue >= 100 ? 0 : beforeValue + 5
      )
    }, 800)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div>
      <Box
        flexDirection='column'
        display='flex'
        justifyContent='flex-start'
        alignItems='flex-start'
        className={classes.test}
        p={1}
      >
        {uploadReportSlice.uploading && (
          <Box>
            <Box paddingY={0.5}>
              <Typography variant='body1' color='primary'>
                {t(' Please wait while report(s) are getting uploaded.')}
              </Typography>
            </Box>

            <div className={classes.root}>
              <Box display='flex' alignItems='center'>
                <Box minWidth={30}>
                  <Typography variant='body2' color='primary'>{`${Math.round(
                    uploadOrDownloadCount
                  )}%`}</Typography>
                </Box>
                <Box width='100%' mr={0.8}>
                  <StyledLinearProgress
                    variant='determinate'
                    value={uploadOrDownloadCount}
                  />
                </Box>
              </Box>
            </div>
          </Box>
        )}
        {uploadReportSlice.uploading === false &&
          uploadReportSlice.error === false && (
            <Box>
              <Box paddingY={0.5}>
                <Typography variant='body1' color='primary'>
                  {t(' Report(s) has been uploaded successfully.')}
                </Typography>
              </Box>
              {/* <Box paddingY={0.5}>
                <Typography variant='body1' color='primary'>
                  {t(
                    ' We will notify you within 5 working days after the Tests Catalogue is setup.'
                  )}
                </Typography>
              </Box> */}
            </Box>
          )}

        {uploadReportSlice.uploading === false &&
          uploadReportSlice.error === true && (
            <Box display='flex' flexDirection='row'>
              <Box p={0.5}>
                <img
                  src={`${process.env.PUBLIC_URL}/Alerterror.png}`}
                  alt='test'
                  height={12.5}
                  width={12}
                />
              </Box>
              <Box paddingY={0.5}>
                <Typography variant='body1' color='secondary'>
                  {t(' Something went wrong while uploading. ')}
                </Typography>
              </Box>
            </Box>
          )}
      </Box>
    </div>
  )
}
