import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Card, CardContent, Divider } from '@material-ui/core'
import React from 'react'
import { ReportViewerTile } from 'views/components/common/reportViewer/reportViewerTile'
import { ReportTileForAppointmentDiagnosticReport } from 'views/components/consultation/history/OPD/diaognisticReportTile'

interface Props {
  reports: R4.IDiagnosticReport[]
}

export const DiagnosticReport: React.FC<Props> = ({ reports }: Props) => (
  <Box width='100%' border={1} borderRadius={4} borderColor='lightGrey'>
    <Card
      elevation={0}
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      <CardContent
        style={{
          padding: 0,
        }}
      >
        <Box width='100%'>
          <ReportTileForAppointmentDiagnosticReport
            reports={reports}
            type='Report'
          />
        </Box>
      </CardContent>
    </Card>
  </Box>
)
