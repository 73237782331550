import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestForCollectionUpdate,
  resetOrderStatus,
} from 'redux/lab/order/orderManagementSlice/orderManagementSlice'
import { RootState } from 'redux/rootReducer'
import {
  getTotalAmount,
  getTotalSpeiciment,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { AgentSearch } from 'views/components/practitioner/agentSearch'
import { AgentTile } from 'views/components/practitioner/agentTile'
import { OrderSearch } from 'views/components/practitioner/orderSearch'
import { WelloFormItemLabel } from 'wello-web-components'

interface Props {
  open: boolean
  onClose: () => void
  onFinishCollection: () => void
}

export const CashCollectionHandler: React.FC<Props> = ({
  open,
  onClose,
  onFinishCollection,
}) => {
  const orderManagementSlice = useSelector(
    (state: RootState) => state.orderManagementSlice
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selectedAgent, setSelectedAgent] = useState<PractitionerWithRole>()
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(true)

  const [selectedOrders, setSelectedOrders] = useState<FhirLabOrderDetail[]>()

  useEffect(() => {
    if (orderManagementSlice.orderDetailsUpdated) {
      if (onFinishCollection) {
        onFinishCollection()
        resetForm()
      }
    }
  }, [orderManagementSlice, onFinishCollection])

  function isEnableCreateButton(): boolean | undefined {
    return selectedAgent && selectedOrders && selectedOrders.length > 0
  }

  function resetForm() {
    setSelectedAgent(undefined)
    setSelectedOrders(undefined)
    setIsPatientEditMode(true)
    dispatch(resetOrderStatus())
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={1}> {t('labelCommon:collect_sample')}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='row' px={2}>
          <Box display='flex' flexDirection='column' flexGrow={4}>
            <Box display='flex' flexDirection='row'>
              <Box display='flex' flexDirection='column' width='100%'>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                >
                  <WelloFormItemLabel title={t('labelCommon:select_agent')} />
                </Box>
                <Box display='flex' flexDirection='row'>
                  {isPatientEditMode && (
                    <AgentSearch
                      onAgentSelected={(e) => {
                        setSelectedAgent(e)
                        setIsPatientEditMode(false)
                      }}
                    />
                  )}
                </Box>
                <Box display='flex' flexDirection='row'>
                  {!isPatientEditMode && selectedAgent && (
                    <AgentTile practitoner={selectedAgent} />
                  )}
                </Box>

                {!isPatientEditMode && selectedAgent && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    my={2}
                    width='100%'
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'
                    >
                      <WelloFormItemLabel
                        title={t('labelCommon:select_samples')}
                      />
                    </Box>
                    <OrderSearch
                      selectedAgent={selectedAgent}
                      onOrderSelected={(e) => {
                        setSelectedOrders(e)
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {!isPatientEditMode && selectedAgent && selectedOrders && (
              <Grid container>
                <Grid item>
                  <Typography color='initial' variant='subtitle2'>
                    {t('labelCommon:total_specimen')} :{' '}
                    {getTotalSpeiciment(selectedOrders)}
                  </Typography>
                </Grid>
                <Grid item xs container justifyContent='flex-end'>
                  <Typography color='initial' variant='subtitle2'>
                    {t('labelCommon:total_cash')} : &#x20b9;{' '}
                    {getTotalAmount(selectedOrders)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {orderManagementSlice.updatingOrderDetail && (
          <CircularProgress size={30} />
        )}
        {!isPatientEditMode && selectedAgent && (
          <Button
            variant='contained'
            style={{ backgroundColor: 'white' }}
            disableElevation
            onClick={() => {
              dispatch(resetOrderStatus())
              setSelectedAgent(undefined)
              setSelectedOrders(undefined)
              setIsPatientEditMode(true)
            }}
          >
            <Typography color='primary' variant='subtitle1'>
              {t('labelCommon:agent_change')}
            </Typography>
          </Button>
        )}
        {!isPatientEditMode && selectedAgent && selectedOrders && (
          <Button
            variant='contained'
            color='primary'
            disableElevation
            disabled={!isEnableCreateButton}
            onClick={() => {
              if (selectedAgent && selectedOrders) {
                dispatch(requestForCollectionUpdate(selectedOrders))
                if (onFinishCollection) {
                  onFinishCollection()
                }
              }
            }}
          >
            {t('labelCommon:finish_collection')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
