import { R4 } from '@ahryman40k/ts-fhir-types'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Switch,
  TablePagination,
  TextField,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import _ from 'lodash'
import { DietDataModel } from 'models/dietData'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  activateCatalog,
  deletingCatalog,
} from 'redux/valueSet/nutrition_catalogue/addDietCatalogueSlice'
import { dietDuration } from 'utils/constants'
import { getExtensionValueOfUrl } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import {
  getDietBundleActivate,
  getDietBundleDelete,
} from 'utils/labHelpers/uploadHelper'
import { ConfirmationDialog } from 'views/components/common/confirmationDialog'
import { EditDiet } from './editDiet'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      padding: 4,
    },
  },
})

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

interface DietTableProperties {
  usersData: R4.ICatalogEntry[]
}

export const DietDataTable: React.FC<DietTableProperties> = ({
  usersData,
}: DietTableProperties) => {
  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const [deleteDiet, setDeleteDiet] = useState(false)

  const [dietDetail, setDietDetail] = React.useState<DietDataModel>()

  const [toActive, setToActive] = useState(false)

  const [status, setStatus] = useState(true)

  const [inactive, setInactive] = useState(false)

  const dispatch = useDispatch()

  const classes = useStyles()

  const convertedData = usersData

  const { height, width } = useWindowDimensions()
  const { t } = useTranslation()

  const tableRef = React.useRef<undefined | HTMLElement>()

  const array: DietDataModel[] = []

  for (let i = 0; i < convertedData.length; i++) {
    const foodName = getExtensionValueOfUrl(
      convertedData[i].extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext'
    )

    const dietAllDetails: DietDataModel = {
      name: '',
      duration: '',
      allDuration: [],
      timeData: '',
      orderable: false,
    }

    dietAllDetails.name = _.capitalize(foodName?.valueCodeableConcept?.text)

    const time = getExtensionValueOfUrl(
      convertedData[i].extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-intake-time-ext'
    )

    const allTime: Array<string> = time?.valueTiming?.repeat?.when
      ? time?.valueTiming?.repeat?.when ?? []
      : []

    const mapDurationToCodes: R4.ICoding[] = []
    const displayTime: string[] = []

    allTime.map((e) => {
      if (e === 'MORN.early') mapDurationToCodes.push(dietDuration[0])
      if (e === 'MORN') mapDurationToCodes.push(dietDuration[1])
      if (e === 'MORN.late') mapDurationToCodes.push(dietDuration[2])
      if (e === 'NOON') mapDurationToCodes.push(dietDuration[3])
      if (e === 'EVE') mapDurationToCodes.push(dietDuration[4])
      if (e === 'NIGHT') mapDurationToCodes.push(dietDuration[5])
      if (e === 'PCV') mapDurationToCodes.push(dietDuration[6])
    })

    const allTimings: Array<string> = allTime

    dietAllDetails.durationCodes = mapDurationToCodes
    if (mapDurationToCodes.length > 0) {
      for (let j = 0; j < mapDurationToCodes.length; j++) {
        displayTime.push(mapDurationToCodes[j].display ?? '')
      }
    }
    dietAllDetails.duration = displayTime.join(', ')
    dietAllDetails.allDuration = allTimings

    dietAllDetails.catalogue = convertedData[i]

    if (convertedData[i].orderable === true) dietAllDetails.status = 'Active'
    else dietAllDetails.status = 'Inactive'

    dietAllDetails.orderable = convertedData[i].orderable

    array.push(dietAllDetails)
  }

  array.sort((a, b) =>
    _.capitalize(a.name!) > _.capitalize(b.name!)
      ? 1
      : _.capitalize(b.name!) > _.capitalize(a.name!)
      ? -1
      : 0
  )

  const [nameFocus, setNameFocus] = useState(true)
  const [timeFocus, setTimeFocus] = useState(true)
  const [statusFocus, setStatusFocus] = useState(true)

  const [nameFilter, setNameFilter] = useState('')
  const [timeFilter, setTimeFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const [data, setData] = useState([...array])

  const ref = useRef(null)

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = array.filter((d) =>
        (d?.name ?? '')
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...array])
    }
    setNameFilter(value)
    setTimeFocus(false)
    setStatusFocus(false)
  }

  const filterTime = (value: any, filed: string) => {
    setTimeFocus(true)
    if (value) {
      const filteredData = array.filter((d) =>
        (d?.durationCodes ? d?.durationCodes[0]?.display ?? '' : '')
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...array])
    }
    setTimeFilter(value)
    setNameFocus(false)
    setStatusFocus(false)
  }

  const filterStatus = (value: any, filed: string) => {
    setStatusFocus(true)
    if (value) {
      const filteredData = array.filter((d) =>
        (d?.status ?? '')
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...array])
    }
    setStatusFilter(value)
    setNameFocus(false)
    setTimeFocus(false)
  }

  const columns = [
    {
      title: (
        <Box
          minWidth='150px'
          minHeight='15px'
          maxHeight='15px'
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
        >
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            {t('labelCommon:Diet')}
          </Typography>
        </Box>
      ),
      field: 'name',
      customSort: (a: any, b: any) =>
        a.name.localeCompare(b.name, 'en', {
          sensitivity: 'base',
        }),
      cellStyle: {
        width: 250,
        maxWidth: 250,
      },
      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            &nbsp;&nbsp;&nbsp;{rowData.name}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={nameFocus}
            placeholder={t('labelCommon:Diet')}
            value={nameFilter}
            onChange={(e) => filterName(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle1' color='primary' noWrap>
            {t('labelCommon:Time')}
          </Typography>
        </Box>
      ),
      field: 'duration',
      cellStyle: {
        width: 400,
        maxWidth: 400,
      },
      customSort: (a: any, b: any) =>
        a.duration.localeCompare(b.duration, 'en', {
          sensitivity: 'base',
        }),
      render: (rowData: any) => (
        <Box paddingLeft={1.5}>
          <Typography variant='subtitle2' color='textPrimary'>
            {rowData.duration}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={timeFocus}
            placeholder={t('labelCommon:Time')}
            value={timeFilter}
            onChange={(e) => filterTime(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box
          minWidth='90px'
          minHeight='15px'
          maxHeight='15px'
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
        >
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            {t('labelCommon:Status')}
          </Typography>
        </Box>
      ),
      field: 'status',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              &nbsp;&nbsp;&nbsp;{rowData.status}
            </Typography>
          </Box>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width={100}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={statusFocus}
            placeholder={t('labelCommon: Status')}
            value={statusFilter}
            onChange={(e) =>
              filterStatus(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: <Box minWidth='180px' minHeight='15px' maxHeight='15px' />,
      field: 'status',
      render: (rowData: any) => (
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Grid container>
            <Grid item sm={6}>
              <Box display='flex' justifyContent='flex-end' p={2}>
                <Box mr={2} mt={1}>
                  {rowData.orderable === true && (
                    <CreateIcon
                      fontSize='small'
                      color='primary'
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          setDietDetail(rowData)
                          setShowPopup(true)
                        }
                      }}
                      onClick={() => {
                        setDietDetail(rowData)
                        setShowPopup(true)
                      }}
                    />
                  )}
                </Box>
                <Box mr={2} mt={1}>
                  {rowData.orderable === true && (
                    <AntSwitch
                      checked={status}
                      onChange={(event) => {
                        setDeleteDiet(true)
                        setDietDetail(rowData)
                      }}
                      tabIndex={0}
                      name='Orderable'
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          setDeleteDiet(true)
                          setDietDetail(rowData)
                        }
                      }}
                    />
                  )}
                  {rowData.orderable === false && (
                    <AntSwitch
                      checked={inactive}
                      onChange={(event) => {
                        setToActive(true)
                        setDietDetail(rowData)
                      }}
                      name='Not-orderable'
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          setToActive(true)
                          setDietDetail(rowData)
                        }
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
      filtering: false,
    },
  ]

  return (
    <Box
      style={{ overflow: 'none', width: `${width - 224}px` }}
      paddingLeft={1.5}
    >
      <MaterialTable
        ref={ref}
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
                height: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '3px',
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        tableRef={tableRef}
        options={{
          showTitle: false,
          padding: 'dense',
          search: false,
          searchFieldVariant: 'outlined',
          filtering: true,
          thirdSortClick: false,

          pageSize: 10,
          pageSizeOptions: [10, 20, 40, 60, 80, 110],
          // columnsButton: true,
          maxBodyHeight: `${height - 230}px`,
          minBodyHeight: `${height - 230}px`,
          toolbarButtonAlignment: 'right',
          keepSortDirectionOnColumnSwitch: true,
          sorting: true,
          headerStyle: {
            backgroundColor: '#ececec',
            color: '#333333',
            width: 110,
            position: 'sticky',
            top: 0,
            height: 10,
          },

          filterRowStyle: {
            position: 'sticky',
            zIndex: 5,
            top: 10,
            backgroundColor: '#ececec',
            color: '#333333',
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),

          searchFieldAlignment: 'left',
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />

      {showPopup && (
        <EditDiet
          selectedValue={dietDetail as DietDataModel}
          open={showPopup}
          onCancelClick={() => {
            setShowPopup(false)
          }}
        />
      )}
      <ConfirmationDialog
        open={deleteDiet}
        displayText='Diet will be deactivated. Do you want to continue?'
        title='Deactivate Diet'
        doneButtonText='Deactivate'
        cancelButtonText='Back'
        onClose={() => {
          setDeleteDiet(false)
        }}
        onDiscard={() => {
          if (dietDetail) {
            const bundleData = getDietBundleDelete(dietDetail)

            if (bundleData) {
              dispatch(deletingCatalog(bundleData, '', 'Diet'))
            }
          }

          setDeleteDiet(false)
        }}
      />

      <ConfirmationDialog
        open={toActive}
        displayText='Diet will be Activated. Do you want to continue?'
        title='Activate Diet'
        doneButtonText='Activate'
        cancelButtonText='Back'
        onClose={() => {
          setToActive(false)
        }}
        onDiscard={() => {
          if (dietDetail) {
            const bundleData = getDietBundleActivate(dietDetail)

            if (bundleData) {
              dispatch(activateCatalog(bundleData, '', 'Diet'))
            }
          }

          setToActive(false)
        }}
      />
    </Box>
  )
}
