import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getOrdersForAgent,
  getPaymentType,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { AgentOrderSearchStatus } from './agentOrderSearchStatus'

const initialState: AgentOrderSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
}

let currentSelectedDoctors: PractitionerWithRole[]
let currentSelectedOrderTypes: string[] | undefined
let currentSelectedTests: LabOfferingDetail[] | undefined

const agentOrderSlice = createSlice({
  name: 'agentOrderSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AgentOrderSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableOrders = action.payload.availableOrders
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
    },
  },
})

export const requestForOrdersForAgent =
  (
    selectedAgent: PractitionerWithRole,
    selectedDate?: Date,
    orderNo?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AgentOrderSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(agentOrderSlice.actions.updatedStatus(state))
    try {
      const dateString = moment(new Date()).toISOString().trim()
      const tomorrow = moment(moment(new Date()).add(1, 'days'))
        .startOf('day')
        .toISOString()

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        owner: `PractitionerRole/${selectedAgent.roleObject.id}`,
        _count: 500,
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/Task?_include:iterate=Task:part-of&_include:iterate=Task:focus&_include:iterate=Task:subject&_include:iterate=ServiceRequest:instantiates-canonical&_include:iterate=PlanDefinition%3Aservice-billing&period=le${dateString}&_revinclude%3Aiterate=Provenance:target&status=completed&_include:iterate=ServiceRequest:specimen&_include:iterate=Task:owner&code=home-sample-collection`,
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingOrders = true
        state.searchingAppointments = false

        dispatch(agentOrderSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          let fhirOrders: FhirLabOrderDetail[] = []
          const fhirAppointments: FhirLabOrderDetail[] =
            getOrdersForAgent(appointmentResponse)
          console.log(fhirAppointments)
          if (fhirAppointments.length > 0) {
            for (let i = 0; i < fhirAppointments.length; i++) {
              if (orderNo) {
                // eslint-disable-next-line no-await-in-loop
                const fhirOrder = await getOrderPaymentType(fhirAppointments[i])
                const id = fhirOrder.serviceRequest.id ?? ''
                if (id.includes(orderNo)) {
                  fhirOrders.push(fhirOrder)
                }
              } else {
                // eslint-disable-next-line no-await-in-loop
                const fhirOrder = await getOrderPaymentType(fhirAppointments[i])
                fhirOrders.push(fhirOrder)
              }
            }
          }
          if (fhirOrders.length === 0) fhirOrders = fhirAppointments
          fhirOrders.sort((a, b) => moment(a.start).diff(b.start))
          logger.info('Orders length')
          if (fhirOrders.length > 0) {
            state.resultsAvailable = true
            state.searchingAppointments = false
            state.availableOrders = fhirOrders
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingOrders = false
            dispatch(agentOrderSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: AgentOrderSearchStatus = {
              searchingAppointments: false,
              errorWhileSearchingOrders: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(agentOrderSlice.actions.updatedStatus(errorSearchDoctor))
          }
        } else {
          const errorSearchDoctor: AgentOrderSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(agentOrderSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: AgentOrderSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching orders',
      }
      dispatch(agentOrderSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function getOrderPaymentType(
  orderDetails: FhirLabOrderDetail
): Promise<FhirLabOrderDetail> {
  let fhirDetail: FhirLabOrderDetail = orderDetails
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/PaymentReconciliation?request=${orderDetails.task?.id}`
  )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          fhirDetail = getPaymentType(orgResponse, orderDetails)
        }
      }
      // else{
      //     fhirDetail.isPrepaid=false
      // }
    }
  }
  return fhirDetail
}

export default agentOrderSlice.reducer
