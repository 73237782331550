import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'

import React, { useEffect, useState } from 'react'
import { ReportTileForAppointment } from 'views/components/consultation/history/OPD/reportTileForAppointment'

interface IProps {
  documentRef: R4.IDocumentReference[]
  appointmentId: string
  showPreview: boolean
  onCloseClicked: () => void
}

export const MultiReport: React.FC<IProps> = ({
  documentRef,
  appointmentId,
  showPreview,
  onCloseClicked,
}: IProps) => {
  console.log(documentRef)
  return (
    <Dialog
      maxWidth='md'
      open={showPreview}
      onClose={() => {
        onCloseClicked()
      }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Reports</Box>
          <Box>
            <Tooltip title='Close'>
              <CloseOutlined
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  onCloseClicked()
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box width='100%' px={2} py={2}>
          <ReportTileForAppointment
            reports={documentRef}
            type='Report'
            appointmentId={appointmentId}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
