import { R4 } from '@ahryman40k/ts-fhir-types'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { RoomWithCategory } from 'models/units/roomWithCategory'
import { EnrolCient } from 'services/EnrrolmentClient'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodingOfSystemFromCodableConceptList,
} from 'utils/fhirResourcesHelper'
import { dataURItoBlob } from 'utils/fileHelper'

export function getParsedRoomsFromBundle(response: R4.IBundle) {
  const dateWiseSlots: RoomWithCategory[] = []
  if (response.entry && response.entry.length > 0) {
    response.entry.forEach((item) => {
      const currentLoc = item.resource as R4.ILocation
      if (currentLoc.alias && currentLoc.alias.length > 0) {
        const type = currentLoc.alias[0].split(' ')[0]

        const index = dateWiseSlots.findIndex(
          (e) => e.category.toLowerCase() === type.toLowerCase()
        )
        if (index > -1) {
          dateWiseSlots[index].rooms.push(currentLoc)
        } else {
          dateWiseSlots.push({
            category: type,
            rooms: [currentLoc],
            price: 0,
          })
        }
      }
    })
  }

  return dateWiseSlots
}

export function getDisplayOfBedOperationalStatus(bed: R4.ILocation): string {
  if (bed.operationalStatus) {
    switch (bed.operationalStatus.code) {
      case 'o':
      case 'O':
        return 'Occupied'

      case 'u':
      case 'U':
        return 'Free'

      default:
        return bed.operationalStatus.display ?? ''
    }
  }
  return ''
}

export function getRoomType(category: string): string {
  switch (category) {
    case 'Premium':
      return 'premium'
    case 'Suite':
      return 'suite'
    case 'Sharing':
      return 'sharing'
    case 'Non-Ac':
      return 'non-ac'
    case 'non-ac':
      return 'non-ac'
    case 'Eco-Premium':
      return 'eco-premium'
    case 'Eco Premium':
      return 'eco-premium'

    default:
      return ''
  }
}

export function getRoomTypeLabel(category: string): string {
  switch (category) {
    case 'Premium':
      return 'Premium'
    case 'Suite':
      return 'Suite'
    case 'Sharing':
      return 'Sharing'
    case 'Non-Ac':
      return 'Non-AC'
    case 'non-ac':
      return 'Non-AC'
    case 'eco-premium':
      return 'Eco Premium'
    case 'Eco-Premium':
      return 'Eco Premium'

    default:
      return ''
  }
}

export function getRoomTypeForDisplay(category: string): string {
  const data = category.split(' ')[0]

  switch (data) {
    case 'Premium':
      return category
    case 'Suite':
      return category
    case 'Sharing':
      return category
    case 'non-ac':
      return category.replace('non-ac', 'Non-AC')
    case 'eco-premium':
      return category.replace('eco-premium', 'Eco-Premium')
    case 'Non-Ac':
      return category.replace('Non-Ac', 'Non-AC')
    case 'Eco-Premium':
      return category

    default:
      return ''
  }
}

export function isBedFree(bed: R4.ILocation): boolean {
  if (bed.operationalStatus) {
    switch (bed.operationalStatus.code) {
      case 'o':
      case 'O':
        return false

      case 'u':
      case 'U':
        return true

      default:
        return false
    }
  }
  return false
}

export async function updateRoomStatusInFhir(
  roomId: string,
  changedStatus: 'block' | 'free'
): Promise<boolean | FHIRErrorResponses> {
  const enRolClient: EnrolCient = new EnrolCient()

  let body: any = {}
  if (changedStatus === 'block') {
    body = {
      eventType: 'book-beds',
      beds: [roomId],
    }
  }

  if (changedStatus === 'free') {
    body = {
      eventType: 'release-beds',
      beds: [roomId],
    }
  }

  const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
    `/ipd/bedBooking`,
    body
  )
  if (response) {
    return true
  }

  return {
    status: 400,
    data: {},
    displayText: 'Rooms List is not available',
  }
}
