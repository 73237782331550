import { R4 } from '@ahryman40k/ts-fhir-types'
import { IOrganization } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Errors } from 'io-ts'
import * as E from 'fp-ts/lib/Either'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { LabReferralService } from 'models/labOfferDetail'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateAppointmentDetails } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  addAndGetEncounterDetailsOfAppointment,
  addAndGetEncounterIdOfAppointment,
} from 'utils/appointment_handle/cds_recommendations_util'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
  getCommunicationResourceForFollowUp,
  hasPlanOfferedInOrg,
} from 'utils/careplan_utils/careplan_utils'
import {
  getIdentifierValueBySystem,
  getNameFromHumanName,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { getLoincCodeFromPlanDef } from 'utils/patientHelper/cds_helper'
import { getVendorPartId } from 'utils/routes_helper'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import _, { uniqBy } from 'lodash'
import { LabTestsAdditionStatus } from './labTestsAdditionState'

const initialState: LabTestsAdditionStatus = {
  addingLabTests: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const labTestReferralAddition = createSlice({
  name: 'labTestReferralAddition',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<LabTestsAdditionStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingLabTests = action.payload.addingLabTests
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },
  },
})

export const requestLabReferral =
  (
    patient: R4.IPatient,
    services: LabReferralService[],
    preAddedLabTest: R4.IPlanDefinition[],
    appointment: FhirAppointmentDetail,
    carePlanUrl?: string,
    carePlanId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabTestsAdditionStatus = {
      ...initialState,
    }
    state.addingLabTests = true
    dispatch(labTestReferralAddition.actions.updatedStatus(state))
    try {
      const carePlanOfPatient: R4.ICarePlan | undefined =
        carePlanId !== undefined
          ? await getCarePlanByIdCurrentState(carePlanId)
          : await getCarePlanOfPatient(patient.id!, carePlanUrl ?? '', true)
      if (carePlanOfPatient) {
        let encounterId: string | undefined = appointment.encounter?.id
        if (
          appointment.encounter === undefined ||
          appointment.encounter?.id === undefined ||
          appointment.encounter?.id?.length === 0
        ) {
          const encounter: R4.IEncounter | undefined =
            await addAndGetEncounterDetailsOfAppointment(appointment)
          dispatch(updateAppointmentDetails(appointment.appointment.id!))

          if (encounter) {
            encounterId = encounter.id!
          }
        }

        if (carePlanUrl) {
          const finalService: LabReferralService[] = []
          let finalArray: R4.IPlanDefinition[] = []
          if (preAddedLabTest.length > 0) {
            console.log(preAddedLabTest)
            for (let i = 0; i < preAddedLabTest.length; i++) {
              if (preAddedLabTest[i] !== undefined) {
                finalArray.push(preAddedLabTest[i])
              }
            }

            finalArray = _.uniqBy(finalArray, (e) =>
              getIdentifierValueBySystem(e.identifier ?? [], 'http://loinc.org')
            )

            const plan: R4.IPlanDefinition[] = []
            for (let i = 0; i < finalArray.length; i++) {
              if (services.length > 0) {
                const data = services.filter(
                  (d: LabReferralService) =>
                    getIdentifierValueBySystem(
                      d.planDefinition.identifier ?? [],
                      'http://loinc.org'
                    ) ===
                    getIdentifierValueBySystem(
                      finalArray[i].identifier ?? [],
                      'http://loinc.org'
                    )
                )
                if (data.length === 0) {
                  plan.push(finalArray[i])
                }
              } else {
                plan.push(finalArray[i])
              }
            }

            if (services.length > 0) {
              for (let i = 0; i < services.length; i++) {
                if (services[i].healthService.id === undefined) {
                  plan.push(services[i].planDefinition)
                } else {
                  finalService.push(services[i])
                }
              }
            }

            const bundleObject: R4.IBundle =
              createBundleObjectForLabWithoutReferral(
                appointment,
                encounterId!,
                plan,
                '',
                carePlanOfPatient.id
              )
            const fhirClient: FHIRApiClient = new FHIRApiClient()
            const responseForBundle = await fhirClient.doCreateFHIRTransaction(
              '',
              bundleObject
            )

            const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
              R4.RTTI_Bundle.decode(responseForBundle)

            if (finalService.length === 0) {
              if (relatedFhirDecodeRes._tag === 'Right') {
                if (relatedFhirDecodeRes.right) {
                  state.addingLabTests = false
                  state.additionSuccessful = true
                  state.errorWhileAdding = false
                  state.successMessage = 'Referrals created successfully'
                  state.errorReason = undefined
                  dispatch(
                    labTestReferralAddition.actions.updatedStatus(initialState)
                  )
                  dispatch(showSuccessAlert(state.successMessage!))

                  setTimeout(() => {
                    dispatch(getLabRequestsOfAppointment(appointment))
                  }, 500)
                } else {
                  state.addingLabTests = false
                  state.additionSuccessful = false
                  state.errorWhileAdding = true
                  state.successMessage = undefined
                  state.errorReason =
                    'Error while adding lab tests recommendation. Try later'
                  dispatch(showErrorAlert(state.errorReason!))
                  dispatch(
                    labTestReferralAddition.actions.updatedStatus(initialState)
                  )
                }
              } else {
                state.addingLabTests = false
                state.additionSuccessful = false
                state.errorWhileAdding = true
                state.successMessage = undefined
                state.errorReason = 'User is not subscribed to Care Plan'
                dispatch(showErrorAlert(state.errorReason!))
                dispatch(labTestReferralAddition.actions.updatedStatus(state))
              }
            }
          }

          //   for (let i = 0; i < preAddedLabTest.length; i++) {
          //     if (services && services.length > 0) {
          //       for (let j = 0; j < services.length; j++) {
          //         if (services[j].planDefinition.id !== preAddedLabTest[i].id) {
          //           remainLabReferral.push(preAddedLabTest[i])
          //         }
          //       }
          //     }
          //   }
          if (finalService.length > 0) {
            const response = await requestLabOrders(
              appointment.patient,
              appointment,
              carePlanOfPatient!.id!,
              encounterId!,
              finalService ?? []
            )
            if (response) {
              if (response[0].entry && response[0].entry.length > 0) {
                const bundleObject: R4.IBundle =
                  createBundleObjectForLabReferral(
                    appointment,
                    encounterId!,
                    finalService,
                    ''
                  )

                const fhirClient: FHIRApiClient = new FHIRApiClient()
                const responseForBundle =
                  await fhirClient.doCreateFHIRTransaction('', bundleObject)

                const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
                  R4.RTTI_Bundle.decode(responseForBundle)

                if (relatedFhirDecodeRes._tag === 'Right') {
                  if (relatedFhirDecodeRes.right) {
                    state.addingLabTests = false
                    state.additionSuccessful = true
                    state.errorWhileAdding = false
                    state.successMessage = 'Referrals created successfully'
                    state.errorReason = undefined
                    dispatch(
                      labTestReferralAddition.actions.updatedStatus(
                        initialState
                      )
                    )
                    dispatch(showSuccessAlert(state.successMessage!))

                    setTimeout(() => {
                      dispatch(getLabRequestsOfAppointment(appointment))
                    }, 500)
                  } else {
                    state.addingLabTests = false
                    state.additionSuccessful = false
                    state.errorWhileAdding = true
                    state.successMessage = undefined
                    state.errorReason =
                      'Error while adding lab tests recommendation. Try later'
                    dispatch(showErrorAlert(state.errorReason!))
                    dispatch(
                      labTestReferralAddition.actions.updatedStatus(
                        initialState
                      )
                    )
                  }
                } else {
                  state.addingLabTests = false
                  state.additionSuccessful = false
                  state.errorWhileAdding = true
                  state.successMessage = undefined
                  state.errorReason = 'User is not subscribed to Care Plan'
                  dispatch(showErrorAlert(state.errorReason!))
                  dispatch(labTestReferralAddition.actions.updatedStatus(state))
                }
              } else {
                state.addingLabTests = false
                state.additionSuccessful = false
                state.errorWhileAdding = true
                state.successMessage = undefined
                state.errorReason = 'User is not subscribed to Care Plan'
                dispatch(showErrorAlert(state.errorReason!))
                dispatch(labTestReferralAddition.actions.updatedStatus(state))
              }
            } else {
              state.addingLabTests = false
              state.additionSuccessful = false
              state.errorWhileAdding = true
              state.successMessage = undefined
              state.errorReason = 'User is not subscribed to Care Plan'
              dispatch(showErrorAlert(state.errorReason!))
              dispatch(labTestReferralAddition.actions.updatedStatus(state))
            }
          }
        }
      }
    } catch (error) {
      console.error(error)
      const resState: LabTestsAdditionStatus = {
        addingLabTests: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch(showErrorAlert(state.errorReason!))
      dispatch(labTestReferralAddition.actions.updatedStatus(resState))
    }
  }

export const requestLabOrders = async (
  patient: R4.IPatient,
  appointment: FhirAppointmentDetail,
  careplanId: string,
  encounterId: string,
  services: LabReferralService[]
): Promise<any> => {
  try {
    const serviceData: any[] = []

    const hvService: string[] = []
    for (let i = 0; i < services.length; i++) {
      hvService.push(services[i].healthService.id ?? '')
    }
    const uniqueArray = _.uniq(hvService)
    for (let i = 0; i < uniqueArray.length; i++) {
      const labTestArray: any[] = []
      for (let j = 0; j < services.length; j++) {
        if (uniqueArray[i] === services[j].healthService.id) {
          labTestArray.push({
            system: 'http://loinc.org',
            code: getLoincCodeFromPlanDef(services[j].planDefinition),
            display: services[j].planDefinition.title ?? '',
          })
        }
      }
      const body = {
        referralServiceId: uniqueArray[i],
        encounterId,
        labTests: labTestArray,
      }
      serviceData.push(body)
    }
    const body: any = {
      patientId: patient.id ?? '',

      labReferralDetails: serviceData,
    }

    const enRolClient: EnrolCient = new EnrolCient()
    const response = await enRolClient.doCreateEnrolmentFlowRequest(
      'referral/lab-referral',
      body
    )

    return response
  } catch (error) {
    console.error('--------error in referring lab tests---------')
    console.error(error)
  }
  return undefined
}

export function createBundleObjectForLabReferral(
  appointment: FhirAppointmentDetail,
  encounter: string,
  labReferral: LabReferralService[],
  addInformation?: string
): R4.IBundle {
  const allergyDetailsFinal: R4.IServiceRequest[] = []
  const bundleEntries: R4.IBundle_Entry[] = []
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const encounterRef: R4.IReference = {
    reference: `Encounter/${encounter}`,
    type: 'Encounter',
  }

  const hvService: R4.IHealthcareService[] = []
  for (let i = 0; i < labReferral.length; i++) {
    hvService.push(labReferral[i].healthService ?? '')
  }
  const uniqueArray = uniqBy(hvService, (obj) => obj.id)
  let providerDetails: R4.IOrganization | undefined
  for (let k = 0; k < uniqueArray.length; k++) {
    const details: any[] = []
    const planDefDetails: string[] = []
    for (let i = 0; i < labReferral.length; i++) {
      if (uniqueArray[k].id === labReferral[i].healthService.id) {
        if (labReferral[i].healthService.providedBy) {
          if (labReferral[i].healthService.providedBy!.reference) {
            const id = labReferral[i].healthService.providedBy!.reference ?? ''
            const searchId = id.split('/')[1]
            if (
              labReferral[i].healthService.contained &&
              labReferral[i].healthService.contained!.length > 0
            ) {
              const index = labReferral[i].healthService.contained?.findIndex(
                (e) => e.id === searchId
              )
              providerDetails = labReferral[i].healthService.contained![
                index!
              ] as IOrganization
            }
          }
        }
        details.push({
          coding: [
            {
              system: 'http://loinc.org',
              code: getLoincCodeFromPlanDef(labReferral[i].planDefinition),
              display: labReferral[i].planDefinition.title ?? '',
            },
          ],
        })
        planDefDetails.push(labReferral[i].planDefinition.title ?? '')
      }
    }

    const allergyDetails: R4.IServiceRequest = {
      resourceType: 'ServiceRequest',
      subject: {
        display: getNameOfPatient(appointment.patient),
        reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
        id: appointment.patient.id,
        type: appointment.patient.resourceType,
      },
      contained: providerDetails === undefined ? [] : [providerDetails],
      asNeededBoolean: true,
      category: uniqueArray[k].category?.map((e) => ({
        coding: e.coding?.map((c) => ({
          ...c,
          display: 'Laboratory procedure',
        })),
      })),
      code: uniqueArray[k].type?.[0],
      performer:
        providerDetails !== undefined
          ? [
              {
                reference: `#${providerDetails.id}`,
                display: providerDetails.name,
                type: 'Organization',
              },
            ]
          : [],
      intent: 'proposal',
      status: 'active',
      encounter: encounterRef,
      orderDetail: details,
      authoredOn: new Date().toISOString(),
      requester: {
        display: getNameFromHumanName(
          appointment.practitionerDetail.practitioner.name ?? []
        ),
        id: appointment.practitionerDetail.practitionerRole.id,
        reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
        type: appointment.practitionerDetail.practitionerRole.resourceType,
      },
      patientInstruction: planDefDetails.join(', '),
    }
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: allergyDetails.resourceType,
      },
      resource: allergyDetails,
    }
    bundleEntries.push(newBundleEntry)
  }
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function createBundleObjectForLabWithoutReferral(
  appointment: FhirAppointmentDetail,
  encounter: string,
  labReferral: R4.IPlanDefinition[],
  addInformation?: string,
  carePlanId?: string
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const encounterRef: R4.IReference = {
    reference: `Encounter/${encounter}`,
    type: 'Encounter',
  }

  const finalService: R4.IServiceRequest[] = []
  const details: any[] = []
  const planDefDetails: string[] = []

  for (let i = 0; i < labReferral.length; i++) {
    details.push({
      coding: [
        {
          system: 'http://loinc.org',
          code: getLoincCodeFromPlanDef(labReferral[i]),
          display: labReferral[i].title ?? '',
        },
      ],
    })
    planDefDetails.push(labReferral[i].title ?? '')
  }

  let providerDetails: R4.IOrganization | undefined

  const allergyDetails: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },
    contained: providerDetails === undefined ? [] : [providerDetails],
    asNeededBoolean: true,

    performer: [getCurrentUserUnitReference()!],
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '108252007',
            display: 'Laboratory procedure',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '266753000',
          display: 'Laboratory Tests',
        },
      ],
    },

    intent: 'proposal',
    status: 'active',
    encounter: encounterRef,
    orderDetail: details,
    authoredOn: new Date().toISOString(),
    requester: {
      display: getNameFromHumanName(
        appointment.practitionerDetail.practitioner.name ?? []
      ),
      id: appointment.practitionerDetail.practitionerRole.id,
      reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
      type: appointment.practitionerDetail.practitionerRole.resourceType,
    },
    patientInstruction: planDefDetails.join(', '),
  }
  if (carePlanId) {
    allergyDetails.basedOn = [
      {
        reference: `CarePlan/${carePlanId}`,
        type: 'CarePlan',
      },
    ]
  }
  finalService.push(allergyDetails)
  const bundleEntries: R4.IBundle_Entry[] = finalService.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export default labTestReferralAddition.reducer
