/* eslint-disable no-useless-catch */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseTherapies } from 'models/dateSeparatedTherapies'

import { FhirClinicTherapiesDetails } from 'models/fhirClinicTherapiesDetails'
import { FhirClinicTherapyBasic } from 'models/fhirClinicTherapyBasic'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'

import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import {
  getDateWiseTherapies,
  getExpandedServiceRequestFromBundleForIpd,
  getTherapiesBasicsFromBundleForIpd,
  getTherapiesFromBundleForIpd,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { TherapiesListStatus } from './therapiesListStatus'

const initialState: TherapiesListStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
  selectedStatuses: ['active'],
  selectedDate: moment(new Date()).startOf('day').toDate(),
}

let currentSelectedDate: Date | undefined

const therapiesListSlice = createSlice({
  name: 'therapiesListSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<TherapiesListStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
      state.selectedStatuses = action.payload.selectedStatuses
      state.selectedDate = action.payload.selectedDate
      state.dateWiseAppointments = action.payload.dateWiseAppointments
      state.pageState = action.payload.pageState
      state.recordsCount = action.payload.recordsCount
    },
  },
})

export const requestForDateWiseIpdAppointmentLists =
  (
    typeOfAppointment: 'opd' | 'ipd' | 'opd-daycare',
    name: string,
    selectedDate?: Date,
    selectedStatus?: string[],
    pageState?: string,
    offSet?: number,
    existingDateWiseData?: DateWiseTherapies[],
    existingDataRawData?: FhirClinicTherapyBasic[],
    recordsCount?: number,
    gender?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate

    const state: TherapiesListStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      selectedStatuses: selectedStatus ?? [],
      selectedDate,
      availableAppointments: existingDataRawData,
      dateWiseAppointments: existingDateWiseData,
      pageState,
      recordsCount,
    }
    dispatch(therapiesListSlice.actions.updatedStatus(state))
    try {
      const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const searchParams: any = {
        perPageCount: 75,
        unitId,
        type: typeOfAppointment,
      }
      if (selectedDate) {
        const date = `${moment(moment(selectedDate).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()}`
        searchParams.startDate = date
      }

      if (pageState && offSet) {
        searchParams.stateId = pageState
        searchParams.offSetNumber = offSet
      }
      searchParams.gender = getGenderOfDoctor(
        getCurrentUserPractitionerDetails()
      )
      if (selectedStatus && selectedStatus.length > 0) {
        searchParams.status = selectedStatus.join(',')
      }

      const response: any = await getIpdSListForTherapist(
        searchParams.startDate,
        undefined,
        searchParams.status,
        searchParams.perPageCount,
        searchParams.stateId,
        searchParams.offSetNumber,
        searchParams.unitId,
        searchParams.type,
        searchParams.gender,
        name
      )

      if (response !== undefined) {
        const resp: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (resp._tag === 'Left') {
          state.errorWhileSearchingOrders = true
          state.searchingAppointments = false

          dispatch(therapiesListSlice.actions.updatedStatus(state))
        } else {
          const appointmentResponse: R4.IBundle = resp.right
          if (
            appointmentResponse?.entry &&
            appointmentResponse?.entry.length > 0
          ) {
            const fhirAppointments: FhirClinicTherapyBasic[] =
              getTherapiesBasicsFromBundleForIpd(
                appointmentResponse,
                getGenderOfDoctor(getCurrentUserPractitionerDetails())
              )

            const therapistListFinal: FhirClinicTherapyBasic[] = []
            await Promise.all(
              fhirAppointments.map(async (e) => {
                const data = await getGenderSpecificTherapy(
                  e,
                  getGenderOfDoctor(getCurrentUserPractitionerDetails())
                )
                if (data !== undefined) {
                  therapistListFinal.push(data)
                }
              })
            )
            if (therapistListFinal.length > 0) {
              const data = [
                ...(existingDataRawData ?? []),
                ...therapistListFinal,
              ]
              const finalData = data.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex((t) => t.therapy.id === value.therapy.id)
              )

              const dateWiseDataForSorted = getDateWiseTherapies(
                therapistListFinal,
                existingDateWiseData ?? []
              )

              const finalDateWise: DateWiseTherapies[] = []
              for (let k = 0; k < dateWiseDataForSorted.length; k++) {
                finalDateWise.push({
                  date: dateWiseDataForSorted[k].date,
                  orders: dateWiseDataForSorted[k].orders.filter(
                    (value, index, self) =>
                      index ===
                      self.findIndex((t) => t.therapy.id === value.therapy.id)
                  ),
                })
              }

              state.pageState = appointmentResponse.id
              state.recordsCount = appointmentResponse.entry.length
              state.resultsAvailable = true
              state.searchingAppointments = false
              state.availableAppointments = finalData
              state.dateWiseAppointments = finalDateWise
              state.noResultsAvailable = false
              state.errorReason = undefined
              state.errorWhileSearchingOrders = false
              dispatch(therapiesListSlice.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: TherapiesListStatus = {
                searchingAppointments: false,
                errorWhileSearchingOrders: false,
                resultsAvailable: false,
                noResultsAvailable: true,
                selectedStatuses: selectedStatus ?? [],
                selectedDate,
                availableAppointments: existingDataRawData,
                dateWiseAppointments: existingDateWiseData,
                pageState,
                recordsCount,
              }
              dispatch(
                therapiesListSlice.actions.updatedStatus(errorSearchDoctor)
              )
            }
          } else {
            const errorSearchDoctor: TherapiesListStatus = {
              searchingAppointments: false,
              errorWhileSearchingOrders: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              selectedStatuses: selectedStatus ?? [],
              selectedDate,
              availableAppointments: existingDataRawData,
              dateWiseAppointments: existingDateWiseData,
              pageState,
              recordsCount,
            }
            dispatch(
              therapiesListSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } /* */
      }
    } catch (error) {
      console.error('---------error------------')
      console.error(error)
      if (error) {
        if ((error as any).message) {
          if ((error as any).message === 'new param added') {
            return
          }
        }
      }
      const errorSearchDoctor: TherapiesListStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching therapies',
        selectedStatuses: selectedStatus ?? [],
        selectedDate,
        availableAppointments: existingDataRawData,
        dateWiseAppointments: existingDateWiseData,
        pageState,
        recordsCount,
      }
      dispatch(therapiesListSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function getIpdSListForTherapist(
  startDate: string,
  endDate?: string,
  status?: string,
  perPageCount?: number,
  stateId?: string,
  pageNumber?: number,
  organizationId?: string,
  type?: string,
  gender?: string,
  nameSearchString?: string
): Promise<R4.IBundle | R4.IOperationOutcome | undefined> {
  axios.CancelToken.source()
  if (cancelTokenStore.has('therapiesSearchControlToken')) {
    const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
      'therapiesSearchControlToken'
    )
    controlTokenForSearch.cancel('new param added')
    cancelTokenStore.delete('therapiesSearchControlToken')
  }
  cancelTokenStore.set(
    'therapiesSearchControlToken',
    axios.CancelToken.source()
  )
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()

    let firstName: string = ''
    let middleName: string = ''
    let lastName: string = ''

    if (nameSearchString && nameSearchString.includes(' ')) {
      const localNameArr: string[] = nameSearchString.split(' ')
      if (localNameArr.length > 2) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        middleName = localNameArr[2]
        lastName = localNameArr[1]
        firstName = localNameArr[0]
      } else if (localNameArr.length === 2) {
        lastName = localNameArr[1]
        firstName = localNameArr[0]
      } else {
        firstName = nameSearchString
      }
    } else {
      firstName = nameSearchString ?? ''
    }

    if (stateId && perPageCount && pageNumber) {
      const searchParameters: any = {
        _getpages: stateId,
        _getpagesoffset: pageNumber,
        _count: perPageCount,
      }
      const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
        '/',
        searchParameters,
        (
          cancelTokenStore.get(
            'therapiesSearchControlToken'
          ) as CancelTokenSource
        ).token
      )

      return r
    }
    const occurrences: string[] = []
    if (startDate) {
      occurrences.push(`ge${moment(startDate).toISOString()}`)
      occurrences.push(`lt${moment(startDate).add(1, 'days').toISOString()}`)
    }

    let searchParameters: any = {}

    if (perPageCount === 0) {
      searchParameters = {
        _count: 0,
        intent: 'order',
        performer: `${`Organization/`}${organizationId}`,

        occurrence: `ge${moment()
          .startOf('day')
          .utcOffset('+05:30')
          .toISOString()}`,
        category: '225365006',
        status: status ?? 'active',
        // _total: 'accurate',
      }
    } else {
      searchParameters = {
        _count: perPageCount ?? 40,
        // _total: 'accurate',
        intent: 'order',
        performer: `${'Organization/'}${organizationId}`,

        category: '225365006',
        status: status ?? 'active,cancelled',
        _sort: 'occurrence',
      }
      if (occurrences.length > 0) {
        searchParameters.occurrence = occurrences
      }
      if (stateId && pageNumber) {
        searchParameters.stateid = stateId
        searchParameters.page = pageNumber
      }
    }

    // if (gender && gender.length > 0) {
    //   searchParameters['based-on:ServiceRequest.patient:Patient.gender'] =
    //     gender
    // }

    if (type === 'ipd' || !type) {
      searchParameters['based-on:ServiceRequest.code'] = '281685003'
    } else if (type === 'opd') {
      searchParameters['based-on:ServiceRequest.code'] = '33022008'
    } else {
      searchParameters['based-on:ServiceRequest.code'] =
        'http://snomed.info/sct|304903009'
    }

    const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
      `/ServiceRequest?subject.name:contains=${firstName}&subject.given=${middleName}&subject.family=${lastName}`,
      searchParameters,
      (cancelTokenStore.get('therapiesSearchControlToken') as CancelTokenSource)
        .token
    )

    const completeDate = r

    return completeDate
  } catch (error) {
    throw error
  }

  return undefined
}

export async function getGenderSpecificTherapy(
  appointment: FhirClinicTherapyBasic,
  genderData: string
): Promise<FhirClinicTherapyBasic | undefined> {
  const appointmentData: FhirClinicTherapyBasic = {
    ...appointment,
  }

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  if (appointment.patientReference.length > 0) {
    const searchParameters: any = {
      gender: genderData,
    }

    const response = await fhirClient.doGetResource(
      `/Patient?_id=${appointment.patientReference.split('/')[1]}`,
      searchParameters
    )

    logger.info('Org  Response')
    logger.info(response)

    if (response && response.entry && response.entry.length > 0) {
      return appointmentData
    }
  }

  return undefined
}

export default therapiesListSlice.reducer
