import { R4 } from '@ahryman40k/ts-fhir-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Checkbox,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { kPrimaryMain, kPrimaryLight } from 'configs/styles/muiThemes'
import { Autocomplete, Alert } from '@material-ui/lab'
import { ClearOutlined, DeleteOutlineOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationAddressType } from 'utils/constants/organization_address_type'
import {
  addExtensionToExtensionsOfUrl,
  getAddress,
  getFileSizeInMB,
  getTelecomFromContactPoints,
  getTelecomFromContactPointsForUnit,
  getValueAttachmentFromExtensionData,
  getValueAttachmentFromExtensionDataForLogo,
  hasAddress,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  hasLogoDetails,
  isClinic,
  isValidCodeableConcent,
} from 'utils/fhirResoureHelpers/unit_setup_helpers'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloFilePicker } from 'views/components/LeftMenu/WelloFilePicker'
import {
  validateEmail,
  validateMobileNumber,
  WelloTextField,
} from 'wello-web-components'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { logger } from 'utils/logger'
import {
  ORG_LOGO_SPECIALIZATION_URL,
  PROFILE_SELF_DESCRIPTION_URL,
} from 'utils/constants/fhir_vs_ext_constants'
import { WelloAddress } from 'views/mapComponent/addressAutoComplete'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { validateMobileNumberForAdd } from 'utils/formValidators'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { ConsentDialogReferral } from 'views/components/LeftMenu/consentForLabReferral'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { MapComp } from '../../mapComponent'

interface Props {
  organizationDetails: R4.IOrganization
  locationDetails: R4.ILocation
  mainOrganizationDetails: R4.IOrganization
  hasError: boolean
  onAgree: (chk: boolean) => void
}

export const AddUnitsPrimaryDetailsSetUp: React.FC<Props> = ({
  organizationDetails,
  locationDetails,
  mainOrganizationDetails,
  hasError,
  onAgree,
}: Props) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('custom')
  const [logoChoice, setLogoChoice] = useState('custom')
  const [organizationContacts, setOrganizationContacts] = useState<
    R4.IOrganization_Contact[]
  >([{}])
  const [locationLatLng, setLocationLatlng] = useState<R4.ILocation_Position>()
  const [description, setDescription] = useState<string>('')
  const [address, setAddress] = useState<string>('')

  const [attachment, setAttachment] = useState<R4.IAttachment>()
  const [checked, setChecked] = useState<boolean>(false)
  const [openConsent, setOpenConsent] = useState<boolean>(false)
  const [data, setData] = useState<boolean>(false)
  const [address1, setAddress1] = useState('')
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  })

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '30%',
    },
  }))
  const classes = useStyles()
  const [size, setSize] = useState<number>(0)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })
  console.log(organizationDetails)
  useEffect(() => {
    organizationDetails.contact = organizationContacts
  }, [organizationContacts, organizationDetails])

  useEffect(() => {
    const visibilityExt: R4.IExtension = {}
    // if (data) {
    //   visibilityExt.url =
    //     'http://terminology.hl7.org/CodeSystem/v3-Confidentiality'
    //   visibilityExt.valueCoding = {
    //     system: 'http://terminology.hl7.org/CodeSystem/v3-Confidentiality',
    //     code: 'U',
    //     display: 'unrestricted',
    //   }
    //   if (
    //     organizationDetails.extension &&
    //     organizationDetails.extension.length > 0
    //   ) {
    //     organizationDetails.extension.push(visibilityExt)
    //   } else {
    //     organizationDetails.extension = [visibilityExt]
    //   }
    // }
  }, [data, organizationDetails])

  useEffect(() => {
    locationDetails.description = description
  }, [description, locationDetails])

  useEffect(() => {
    locationDetails.position = locationLatLng
  }, [locationDetails, locationLatLng])

  useEffect(() => {
    if (address) {
      organizationDetails.address = [{ line: [address] }]
    } else {
      organizationDetails.address = undefined
    }
  }, [address, organizationDetails])

  useEffect(() => {
    if (attachment) {
      const selfExt: R4.IExtension = {
        url: ORG_LOGO_SPECIALIZATION_URL,
        valueAttachment: {
          url: attachment.url,
          contentType: attachment.contentType,
          title: attachment.title,
          size: attachment.size,
        },
      }
      if (
        organizationDetails.extension &&
        organizationDetails.extension.length > 0
      ) {
        organizationDetails.extension = [
          ...organizationDetails.extension,
          selfExt,
        ]
      } else organizationDetails.extension = [selfExt]
    }
  }, [attachment, organizationDetails])

  function handleEmailChange(event: any, index: number) {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    const currentContactDetail: R4.IOrganization_Contact = values[index]
    const valueTelecom: R4.IContactPoint[] = [
      ...(currentContactDetail.telecom ?? []),
    ]

    const filteredContact: R4.IContactPoint[] = valueTelecom.filter(
      (e) => e.system !== R4.ContactPointSystemKind._email
    )

    const emailContact: R4.IContactPoint = {
      rank: 1,
      system: R4.ContactPointSystemKind._email,
      value: event.target.value,
    }
    values[index].telecom = [...filteredContact, emailContact]
    setOrganizationContacts(values)
  }

  function handlePhoneNumberChangeChange(event: string, index: number) {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    const currentContactDetail: R4.IOrganization_Contact = values[index]
    const valueTelecom: R4.IContactPoint[] = [
      ...(currentContactDetail.telecom ?? []),
    ]
    const filteredContact: R4.IContactPoint[] = valueTelecom.filter(
      (e) => e.system !== R4.ContactPointSystemKind._phone
    )

    const emailContact: R4.IContactPoint = {
      rank: 1,
      system: R4.ContactPointSystemKind._phone,
      value: event,
    }
    values[index].telecom = [...filteredContact, emailContact]
    setOrganizationContacts(values)
  }

  function handleChangeUnitTypeSelection(event: R4.ICoding, index: number) {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    values[index].purpose = {
      coding: [event],
    }

    const oldOrg: R4.IOrganization = {
      ...organizationDetails,
    }
    oldOrg.contact = values
    setOrganizationContacts(values)
  }

  const handleChange = (event: any) => {
    setChecked(event.target.checked)
    setOpenConsent(event.target.checked)
  }

  function handleRemove(id: number) {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    values.splice(id, 1)
    setOrganizationContacts(values)
  }

  const handleSelect = (valueData: any) => {
    const addressData = getAddress(valueData)
    geocodeByAddress(addressData)
      .then((results: any[]) => getLatLng(results[0]))
      .then((latLng: any) => {
        setMapCenter(latLng)
        const locDetails: R4.ILocation_Position = {
          latitude: latLng.lat,
          longitude: latLng.lng,
        }
        setLocationLatlng(locDetails)
      })
      .catch((error: any) => console.error('Error', error))
  }

  const handleAddMore = () => {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    values.push({ id: getUniqueTempId() })
    setOrganizationContacts(values)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
    >
      <Box
        display='flex'
        flexDirection='column'
        id='unit_act_prof_pic_container'
        width='100%'
      />

      <Box display='flex' flexDirection='row' width='100%'>
        <Box width='15%'>
          <WelloFormItemLabel
            title={
              isClinic(organizationDetails)
                ? 'Clinic Name'
                : t('labelCommon:lab_name_label')
            }
          />
        </Box>
        <Box width='85%' paddingLeft={2.4} alignItems='center' paddingTop={1}>
          <Typography variant='subtitle2' style={{ color: 'black' }}>
            {' '}
            {organizationDetails.name}
          </Typography>
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box width='15%'>
          <Box display='flex' flexDirection='row' width='100%' paddingTop={1}>
            <Box display='flex' flexDirection='column'>
              <WelloFormItemLabel title='Upload Logo' />{' '}
              {hasLogoDetails(mainOrganizationDetails.extension ?? []) && (
                <Box display='flex' flexDirection='row'>
                  <RadioGroup
                    id='add_unit_logo_choice_rad_group'
                    row
                    aria-label='logo'
                    name='logo'
                    value={logoChoice}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLogoChoice((event.target as HTMLInputElement).value)
                    }}
                  >
                    <FormControlLabel
                      id='add_unit_logo_choice_rad_same_as_org_opt'
                      value='same_as_org'
                      control={
                        <Radio
                          color='primary'
                          id='add_unit_logo_choice_rad_btn_same_as_org'
                        />
                      }
                      label={
                        <Typography variant='subtitle2'>
                          {t('labelCommon:same_as_logo_organization')}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value='custom'
                      id='add_unit_logo_choice_rad_custom_opt'
                      control={
                        <Radio
                          color='primary'
                          id='add_unit_logo_choice_rad_btn_custom_as_org'
                        />
                      }
                      label={
                        <Typography variant='subtitle2'>
                          {t('labelCommon:custom')}
                        </Typography>
                      }
                    />
                  </RadioGroup>{' '}
                </Box>
              )}
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='center'
                marginY={1}
                bgcolor='background.paper'
                borderRadius={1}
                width='100%'
                padding={0.7}
              >
                <WelloFilePicker
                  fileTypeToPick='any'
                  id='unit_actor_id_front_file'
                  name='identityProofImageFront'
                  onFileChanged={(selectedFile) => {
                    if (selectedFile) {
                      if (selectedFile.path && selectedFile.files) {
                        setAttachment({
                          url: selectedFile?.path as string,
                          contentType: selectedFile?.files?.type,
                          title: selectedFile.files?.name,
                          size: selectedFile.files
                            ? selectedFile.files.size
                            : 0,
                        })

                        setSize(
                          selectedFile.files ? selectedFile.files.size : 0
                        )
                        //   organizationDetails.extension =
                        //     addExtensionToExtensionsOfUrl(
                        //       organizationDetails.extension,
                        //       selfExt
                        //     )
                      }
                    }
                  }}
                  size='medium'
                  showSelectedFileName={false}
                  onDeleteClicked={() => {
                    organizationDetails.extension =
                      removeExtensionFromExtensions(
                        organizationDetails.extension,
                        ORG_LOGO_SPECIALIZATION_URL
                      )
                  }}
                  allowUpdate={true}
                  allowDelete={true}
                  labelIcon={
                    <FontAwesomeIcon
                      icon={faUpload}
                      color={kPrimaryMain}
                      size='1x'
                    />
                  }
                  labelName={t('labelCommon:select_file')}
                />
              </Box>
              {getFileSizeInMB(size) > 1 && (
                <Box>
                  <Box>
                    <Typography variant='subtitle2' color='error'>
                      Logo size should be less than equal to 1 MB
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box width='85%' paddingLeft={2} alignItems='center' paddingTop={1}>
          <Box display='flex' flexDirection='column' width='100%'>
            <WelloFormItemLabel
              title={
                isClinic(organizationDetails)
                  ? t('labelCommon:clinic_description')
                  : t('labelCommon:lab_description')
              }
            />
            <Box display='flex' flexDirection='row'>
              <WelloTextFieldWithoutTitle
                textProps={{
                  required: true,
                  name: 'clinicDescriptions',
                  id: 'add_unit_first_name',
                  value: description,
                  multiline: true,
                  rows: 4,
                  rowsMax: 6,

                  defaultValue: locationDetails.description,
                  error:
                    hasError &&
                    (locationDetails.description === undefined ||
                      locationDetails.description.length === 0),
                  helperText:
                    hasError &&
                    (locationDetails.description === undefined ||
                      locationDetails.description.length === 0)
                      ? 'Enter description'
                      : '',

                  onChange: (e) => {
                    setDescription(e.target.value)
                  },
                  placeholder: isClinic(organizationDetails)
                    ? t('labelCommon:clinic_description')
                    : t('labelCommon:lab_description'),
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display='flex' flexDirection='row'>
        <Box>
          <Checkbox
            edge='start'
            checked={checked}
            disabled={data === true}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                if (checked === false) {
                  setChecked(true)
                  setOpenConsent(true)
                } else {
                  setChecked(false)
                  setOpenConsent(false)
                }
              }
            }}
            size='small'
            color='primary'
            inputProps={{ 'aria-labelledby': 'consent' }}
            role='unit_search_consent'
          />
        </Box>
        <Box py={1.3}>
          <Typography variant='subtitle2' color='initial'>
            I want to appear in the search results of other units/organization
          </Typography>
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' width='100%'>
            <Box display='flex' flexDirection='row' width='25%'>
              <WelloFormItemLabel
                title={
                  isClinic(organizationDetails)
                    ? 'Your Current Clinic Address'
                    : t('labelCommon:lab_address_label')
                }
              />{' '}
            </Box>

            {hasAddress(mainOrganizationDetails.address ?? []) && (
              <Box
                display='flex'
                flexDirection='row'
                width='75%'
                paddingLeft={2}
              >
                <RadioGroup
                  id='add_unit_address_choice_rad_group'
                  row
                  tabIndex={-1}
                  aria-label='address'
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if ((event.target as HTMLInputElement).value === 'custom') {
                      setAddress('')
                    } else {
                      setAddress(
                        getAddress(mainOrganizationDetails.address ?? [])
                      )
                      handleSelect(mainOrganizationDetails.address ?? [])
                    }
                    setValue((event.target as HTMLInputElement).value)
                  }}
                >
                  <Box display='flex' flexDirection='row'>
                    <FormControlLabel
                      id='add_unit_address_choice_rad_same_as_org_opt'
                      value='same_as_org'
                      tabIndex={-1}
                      control={
                        <Radio
                          color='primary'
                          id='add_unit_address_choice_rad_btn_same_as_org'
                        />
                      }
                      label={
                        <Typography variant='subtitle2'>
                          {t('labelCommon:same_as_organization')}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value='custom'
                      id='add_unit_address_choice_rad_custom_opt'
                      tabIndex={-1}
                      control={
                        <Radio
                          color='primary'
                          id='add_unit_address_choice_rad_btn_custom_as_org'
                        />
                      }
                      label={
                        <Typography variant='subtitle2'>
                          {t('labelCommon:custom')}
                        </Typography>
                      }
                    />
                  </Box>
                </RadioGroup>{' '}
              </Box>
            )}
          </Box>

          <Box display='flex' flexDirection='row'>
            <WelloAddress
              title='Your Current Home Address'
              textProps={{
                required: true,
                id: 'unit_actor_current_address',
                placeholder: 'Your Home address',
                value: address1,
                onChange: (e) => {
                  setAddress(e.target.value)
                },
                name: 'address1',
              }}
              valueData={address1}
              mapkey=''
              onUpdated={(
                addressData: string,
                mapLatlog: {
                  lat: number
                  lng: number
                }
              ) => {
                setAddress(addressData)
                mapCenter.lat = mapLatlog.lat
                mapCenter.lat = mapLatlog.lng
                const locDetails: R4.ILocation_Position = {
                  latitude: mapLatlog.lat,
                  longitude: mapLatlog.lng,
                }
                setLocationLatlng(locDetails)
                setMapCenter(mapLatlog)
              }}
            />
          </Box>

          <Box display='flex' flexDirection='row' paddingY={2}>
            <MapComp
              address=''
              latitude={25}
              longitude={85}
              mapLatlog={mapCenter}
              settingPage={false}
            />
          </Box>
          <Box display='flex' flexDirection='row'>
            <TextField
              fullWidth
              size='small'
              autoComplete='new-password'
              variant='outlined'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              disabled={value === 'same_as_org'}
              placeholder={t('labelCommon:add_address')}
            />
          </Box>
        </Box>
      </Box>

      <Box paddingTop={0.5}>
        <Typography variant='subtitle1' style={{ color: 'black' }}>
          {t('labelCommon:contact_information')}
        </Typography>
      </Box>

      <Box display='flex' flexDirection='column'>
        <Box display='flex' flexDirection='column'>
          {organizationContacts.map(
            (org: R4.IOrganization_Contact, index: number) => (
              <Box
                display='flex'
                flexDirection='column'
                key={`unit_actor_small_details_${org.id}`}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='flex-start'
                  key={`unit_actor_small_details_${org.id}`}
                >
                  <Box py={0.5} paddingRight={1} width='33%'>
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                    >
                      <WelloFormItemLabel title='Contact Type' />
                      <Autocomplete
                        style={{
                          borderRadius: '4px',
                        }}
                        fullWidth
                        size='small'
                        defaultValue={
                          organizationContacts[index].purpose?.coding?.[0] ?? {
                            code: '',
                          }
                        }
                        id={`unit_actor_contact_${org.id}`}
                        autoFocus
                        disableClearable
                        getOptionSelected={(option, valueData) =>
                          option.display === valueData.display
                        }
                        getOptionLabel={(option) => option.display ?? ''}
                        options={OrganizationAddressType}
                        onChange={(e, changedValue, reason) => {
                          if (changedValue) {
                            handleChangeUnitTypeSelection(changedValue, index)
                          }
                        }}
                        autoComplete
                        ChipProps={{
                          deleteIcon: (
                            <ClearOutlined
                              style={{
                                height: '15px',
                                color: 'white',
                              }}
                            />
                          ),
                          style: {
                            backgroundColor: kPrimaryLight,
                            borderRadius: '4px',
                          },
                        }}
                        renderOption={(option) => (
                          <Typography variant='subtitle2'>
                            {option.display}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Select Unit'
                            fullWidth
                            id='unit_search'
                            variant='outlined'
                            size='small'
                            error={
                              hasError &&
                              !isValidCodeableConcent(
                                organizationContacts[index].purpose
                              )
                            }
                            helperText={
                              hasError &&
                              !isValidCodeableConcent(
                                organizationContacts[index].purpose
                              )
                                ? 'Select type'
                                : ''
                            }
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                    </Box>
                    {/* <WelloSelectFHIRCoding
                      title='Contact Type'
                      availableCodings={OrganizationAddressType}
                      onChanged={(e) => {
                        if (e) {
                          handleChangeUnitTypeSelection(e, index)
                        }
                      }}
                      id={`unit_actor_contact_${org.id}`}
                      textFieldProps={{
                        size: 'small',
                        error:
                          hasError &&
                          !isValidCodeableConcent(
                            organizationContacts[index].purpose
                          ),
                        helperText:
                          hasError &&
                          !isValidCodeableConcent(
                            organizationContacts[index].purpose
                          )
                            ? 'Select type'
                            : '',
                      }}
                      preSelectedCoding={
                        organizationContacts[index].purpose?.coding?.[0] ?? {
                          code: '',
                        }
                      }
                    /> */}
                  </Box>

                  <Box
                    display='flex'
                    flexGrow={1}
                    justifyContent='flex-start'
                    py={0.5}
                    px={1}
                    width='33%'
                  >
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Email' />

                      <WelloTextFieldWithoutTitle
                        textProps={{
                          id: `actor_email_unit`,
                          defaultValue: getTelecomFromContactPoints(
                            organizationContacts[index].telecom ?? [],
                            R4.ContactPointSystemKind._email
                          ),
                          error:
                            hasError &&
                            validateEmail(
                              getTelecomFromContactPoints(
                                organizationContacts[index].telecom ?? [],
                                R4.ContactPointSystemKind._email
                              ) ?? '',
                              true
                            )?.length > 0,
                          helperText: hasError
                            ? validateEmail(
                                getTelecomFromContactPoints(
                                  organizationContacts[index].telecom ?? [],
                                  R4.ContactPointSystemKind._email
                                ) ?? '',
                                true
                              )
                            : '',
                          onChange: (changeEmail) => {
                            handleEmailChange(changeEmail, index)
                          },
                          autoComplete: 'off',
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    justifyContent='flex-start'
                    py={0.5}
                    paddingLeft={1}
                    width='33%'
                  >
                    <WelloPhoneNumberField
                      textProps={{
                        id: `actor_phone_unit_${t}`,
                        countryCodeEditable: false,
                        defaultCountry: 'in',
                        inputProps: { maxLength: 13 },
                        fullWidth: true,
                        name: t('labelCommon:phone_name_label'),
                        defaultValue: getTelecomFromContactPointsForUnit(
                          organizationContacts[index].telecom ?? [],
                          R4.ContactPointSystemKind._phone
                        ),
                        inputMode: 'tel',
                        onlyCountries: ['in'],
                        enableLongNumbers: false,

                        disableDropdown: false,

                        onChange: (
                          e:
                            | React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            | string
                        ) => {
                          if (typeof e === 'string') {
                            handlePhoneNumberChangeChange(e, index)
                          }
                        },
                        regions: ['asia'],
                        autoFormat: false,
                        size: 'small',
                        variant: 'outlined',
                        error:
                          hasError &&
                          validateMobileNumber(
                            getTelecomFromContactPoints(
                              organizationContacts[index].telecom ?? [],
                              R4.ContactPointSystemKind._phone
                            ) ?? '',
                            true
                          ).length > 0,
                        helperText:
                          hasError &&
                          validateMobileNumber(
                            getTelecomFromContactPoints(
                              organizationContacts[index].telecom ?? [],
                              R4.ContactPointSystemKind._phone
                            ) ?? '',
                            true
                          ).length > 0
                            ? 'Phone is not valid'
                            : '',

                        required: true,
                      }}
                      title={t('labelCommon:phone_label')}
                    />
                    {/* <WelloTextField
                        title={t('labelCommon:phone_number_label')}
                        textProps={{
                          id: `actor_phone_unit_${t}`,

                          defaultValue: getTelecomFromContactPoints(
                            organizationContacts[index].telecom ?? [],
                            R4.ContactPointSystemKind._phone
                          ),

                          error:
                            hasError &&
                            validateMobileNumberForAdd(
                              getTelecomFromContactPoints(
                                organizationContacts[index].telecom ?? [],
                                R4.ContactPointSystemKind._phone
                              ) ?? '',
                              true
                            )?.length > 0,
                          helperText: hasError
                            ? validateMobileNumberForAdd(
                                getTelecomFromContactPoints(
                                  organizationContacts[index].telecom ?? [],
                                  R4.ContactPointSystemKind._phone
                                ) ?? '',
                                true
                              )
                            : '',
                          onChange: (changedPhoneNumber) => {
                            handlePhoneNumberChangeChange(
                              changedPhoneNumber,
                              index
                            )
                          },
                          type: 'tel',
                          autoComplete: 'off',
                        }}
                      /> */}
                  </Box>
                  {organizationContacts.length !== 1 && (
                    <Box display='flex' justifyContent='center' paddingTop={4}>
                      <DeleteOutlineOutlined
                        fontSize='medium'
                        id={`$${index}orgContact`}
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setShowDeleteConfirmation({
                            open: true,
                            onContinueActionClicked: () => {
                              setShowDeleteConfirmation({
                                open: false,
                              })
                              handleRemove(index)
                            },
                            onCancelActionClicked: () => {
                              setShowDeleteConfirmation({
                                open: false,
                              })
                            },
                            displayText:
                              'Are you sure you want to delete this record?',
                            continueActionButtonText: 'Delete',
                            cancelActionButtonText: 'Cancel',
                          })
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <Box>
                  {organizationContacts.length - 1 === index &&
                    organizationContacts.length < 3 && (
                      <Box
                        display='flex'
                        alignItems='center'
                        py={0.5}
                        flexDirection='row'
                      >
                        <Box>
                          <Button variant='text' onClick={handleAddMore}>
                            <Typography variant='subtitle2' color='primary'>
                              {t('labelCommon:add_more')}
                            </Typography>
                          </Button>
                        </Box>
                        <Box flexGrow={1} paddingLeft={2}>
                          {' '}
                          <Divider />{' '}
                        </Box>
                      </Box>
                    )}
                </Box>
              </Box>
            )
          )}
        </Box>
      </Box>

      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} />
      )}

      <ConsentDialogReferral
        open={openConsent}
        data={false}
        onClose={(checkData: boolean) => {
          setData(false)
          setChecked(false)
          setOpenConsent(checkData)
        }}
        onAgree={(checkData: boolean) => {
          setData(true)
          onAgree(true)
          setOpenConsent(false)
        }}
      />
      <Box
        display='flex'
        flexDirection='column'
        marginY={2}
        width='25%'
        alignItems='flex-end'
      />
    </Box>
  )
}
