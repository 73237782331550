import {
  Box,
  Grid,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
  Link,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Add, Done } from '@material-ui/icons'
import React from 'react'

interface NextStepsOfConsultationProps {
  planName: string
  subString: string
  onClicked?: () => void
  count?: number
  isEditAllowed?: boolean
  bottomWidget?: React.ReactNode
  isAddDisplay?: boolean
}

export const NextStepPlan: React.FC<NextStepsOfConsultationProps> = ({
  planName,
  subString,
  count = 0,
  onClicked,
  isEditAllowed = true,
  bottomWidget,
  isAddDisplay,
}: NextStepsOfConsultationProps) => {
  const primary = useTheme().palette.primary.main
  const isSelected: boolean = count > 0

  return (
    <Box
      display='flex'
      borderRadius={4}
      borderColor='lightGrey'
      border={1}
      width='100%'
      flexDirection='column'
      flexGrow
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        flexGrow
        paddingX={1}
        paddingTop={1}
      >
        <Grid container style={{ padding: '0px', margin: '0px' }}>
          {/* <Grid
            item
            xs={1}
            style={{ alignSelf: 'center', padding: '0px', margin: '0px' }}
          >
            {!isSelected && (
              <Add
                style={{
                  color: isSelected ? 'white' : primary,
                  height: '12px',
                }}
              />
            )}
            {isSelected && (
              <Done
                style={{
                  color: isSelected ? 'white' : primary,
                }}
              />
            )}
          </Grid> */}
          <Grid item xs={4} style={{ alignSelf: 'center' }}>
            <Box px={0.4}>
              <Typography color='initial' variant='h6' id='planName_label'>
                {planName}
              </Typography>
            </Box>
          </Grid>
          {count > 0 && (
            <Grid
              item
              xs={5}
              style={{ alignSelf: 'center', padding: '0px', margin: '0px' }}
            >
              <Typography style={{ color: isSelected ? 'white' : primary }}>
                {subString}
              </Typography>
            </Grid>
          )}
          {count > 0 && (
            <Grid
              item
              xs={2}
              style={{ alignSelf: 'center', padding: '0px', margin: '0px' }}
            >
              <Typography style={{ color: isSelected ? 'white' : primary }}>
                Change
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      {isAddDisplay === undefined && (
        <Box
          display='flex'
          flexGrow
          flexDirection='row'
          justifyContent='flex-start'
          paddingTop={0.5}
          paddingBottom={1}
          p={1}
          style={{
            backgroundColor: isSelected ? primary : 'white',
            cursor: 'pointer',
          }}
        >
          <Tooltip title=''>
            <IconButton
              aria-label='btn_ord_cancel'
              color='primary'
              id={`${planName}_icon`}
              onClick={() => {
                if (onClicked) onClicked()
              }}
              style={{ padding: 0 }}
            >
              <Typography
                variant='subtitle2'
                color='primary'
                component={Link}
                id={`${planName}_link`}
                style={{ fontSize: 13 }}
              >
                {' '}
                {planName}
              </Typography>
              <AddCircleIcon
                style={{ height: '22px', padding: 0 }}
                color='primary'
              />{' '}
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {bottomWidget && (
        <Box display='flex' flexDirection='row' width='100%' flexGrow>
          {bottomWidget}
        </Box>
      )}
    </Box>
  )
}
