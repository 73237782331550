/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getCurrentUserPractitionerDetails } from 'services/userDetailsService'
import { getValueAttachmentFromExtension } from 'utils/fhirResourcesHelper'
import {
  getUpdatedPractitionerDetails,
  updateSignaturesForPrescription,
} from 'utils/fhirResoureHelpers/invoice_helper'
import { WebCamCapturePhoto } from 'views/components/administration/actors/micro-components/webcam_capture'
import { LogoViewer } from 'views/components/common/logoViewer'
import {
  SelectedFile,
  WelloFilePicker,
} from 'views/components/LeftMenu/WelloFilePicker'

interface SignaturesOfIPDProps {
  isReadOnly?: boolean
}

export const Signature: React.FC<SignaturesOfIPDProps> = ({
  isReadOnly = false,
}: SignaturesOfIPDProps) => {
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })
  const dispatch = useDispatch()

  const [openWebCam, setOpenWebCam] = useState<boolean>(false)

  const [callBackForCapturePhoto, setCallBackForCapturePhoto] = React.useState(
    () => (imageName: any, imageDataType: any, imageUri: any) => {}
  )
  // eslint-disable-next-line no-restricted-globals
  const isNotSecure = location.protocol !== 'https:'
  const [pract, setPract] = useState<R4.IPractitioner>(
    getCurrentUserPractitionerDetails()
  )

  const [updatingStatus, setUpdatingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  function updateSignature() {
    setUpdatingStatus({
      requesting: true,
    })

    updateSignaturesForPrescription(pract, selectedDoctorSignature)
      .then((e) => {
        if ('resourceType' in e!) {
          setUpdatingStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Signature details updated'))
          setPract(e)
          setSelectedDoctorSignature(undefined)
          setSelectedPatientSignature(undefined)
        } else {
          setUpdatingStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(showErrorAlert('Error while updating signatures'))
        }
      })
      .catch((e) => {
        setUpdatingStatus({
          requesting: false,
          requestError: true,
        })
        dispatch(showErrorAlert('Error while updating signatures'))
      })
  }

  function getPractionerDetails() {
    setFetchingStatus({
      requesting: true,
    })

    getUpdatedPractitionerDetails(getCurrentUserPractitionerDetails().id ?? '')
      .then((e) => {
        if ('resourceType' in e!) {
          setFetchingStatus({
            requesting: false,
            requestSuccess: true,
          })

          setPract(e)
          setSelectedDoctorSignature(undefined)
          setSelectedPatientSignature(undefined)
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(showErrorAlert('Error while fetching signatures'))
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
        dispatch(showErrorAlert('Error while fetching signatures'))
      })
  }

  const { t } = useTranslation(['common'])

  const [selectedDoctorSignature, setSelectedDoctorSignature] =
    useState<SelectedFile>()
  const [selectedPatientSignature, setSelectedPatientSignature] =
    useState<SelectedFile>()

  useEffect(() => {
    getPractionerDetails()
  }, [])

  return (
    <Box width='100%' display='flex'>
      <Grid
        container
        direction='row'
        style={{ padding: 0, margin: 0 }}
        spacing={1}
      >
        <Grid item xs={12} style={{ padding: 0, margin: 0 }}>
          <Box
            sx={{ width: '100%' }}
            display='flex'
            alignItems='center'
            flexDirection='row'
            bgcolor='#999999'
            minHeight={40}
          >
            <Box p={1}>
              <Typography
                variant='subtitle1'
                style={{
                  color: 'black',

                  fontWeight: 'bold',
                }}
              >
                {' '}
                Signature
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ padding: '8px' }}>
          {(updatingStatus.requesting || fetchingStatus.requesting) && (
            <CircularProgress size={20} />
          )}
          {!updatingStatus.requesting && !fetchingStatus.requesting && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='center'
            >
              {getValueAttachmentFromExtension(
                pract!.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext'
              ) === '1' && (
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='row'
                  justifyContent='center'
                  alignItems='flex-start'
                >
                  <LogoViewer
                    contentType='image/png'
                    decodingRequired={true}
                    imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext').value.as(Attachment)"
                    resourceId={pract!.id ?? ''}
                    resourceType='Practitioner'
                    onSelectionChanged={(value: SelectedFile) => {
                      if (value) setSelectedDoctorSignature(value)
                    }}
                    update={true}
                    purpose='tile3'
                  />
                </Box>
              )}
              <Box display='flex' flexDirection='column' tabIndex={0}>
                {getValueAttachmentFromExtension(
                  pract!.extension,
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext'
                ) === '0' && (
                  <WelloFilePicker
                    fileTypeToPick='photo'
                    id='doc_signature'
                    labelOrientation='column'
                    name='logo'
                    labelName='Add Signature'
                    allowUpdate={true}
                    displaySelectedPhoto='square'
                    preSelectedFile={selectedDoctorSignature}
                    inputProps={{}}
                    onFileChanged={(selectedField: SelectedFile) => {
                      if (selectedField)
                        setSelectedDoctorSignature(selectedField)
                    }}
                  />
                )}

                {/* {selectedDoctorSignature && selectedDoctorSignature.path && (
                  <Box display='flex' flexDirection='column'>
                    <WelloFilePicker
                      fileTypeToPick='any'
                      id='unit_actor_id_front_file'
                      name='identityProofImageFront'
                      onFileChanged={(selectedFile) => {
                        

                        setSelectedDoctorSignature(selectedFile)
                        // setCallBackForCapturePhoto(
                        //   () => (imageName: any, dataType: any, image: any) => {
                        //     setSelectedDoctorSignature(selectedFile)
                        //     // setSelectedDoctorSignature({
                        //     //   path: image,
                        //     //   type: dataType,
                        //     //   local: false,
                        //     //   name: `doctor_sign`,
                        //     // })
                        //   }
                        // )
                      }}
                      size='medium'
                      showSelectedFileName={false}
                      allowUpdate={true}
                      allowDelete={false}
                      labelIcon={
                        <FontAwesomeIcon
                          icon={faUpload}
                          color={kPrimaryMain}
                          size='1x'
                        />
                      }
                      labelName='Select Signature'
                    />
                  </Box>
                )} */}

                {/* {taskHasDoctorSign(mainTask) && (
                <img
                  src={getHasDoctorSign(mainTask)}
                  alt='doc_sign'
                  height='90px/'
                  width='180px'
                />
              )} */}
                {selectedDoctorSignature && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      updateSignature()
                    }}
                  >
                    Upload
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Grid>
        {/* <Grid item direction='row' style={{ padding: 0, margin: 0 }}>
          <Box display='flex' flexDirection='row' width='100%'>
            {(selectedPatientSignature || selectedDoctorSignature) && (
              <Button
                color='default'
                variant='contained'
                onClick={() => {
                  updateSignature()
                }}
              >
                Save
              </Button>
            )}
          </Box>
        </Grid> */}
        {openWebCam && (
          <WebCamCapturePhoto
            id='webcam4'
            open={openWebCam}
            onBackClick={() => {
              setOpenWebCam(false)
            }}
            cropAspectRatio={16 / 9}
            onContinueClick={(imgSrc) => {
              const data = imgSrc
                .toString()
                .split(',')[0]
                .replace('data:', '')
                .replace(';base64', '')
              callBackForCapturePhoto(
                undefined,
                imgSrc!.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)![0]!,
                imgSrc
              )
              /* values.imgName = imgSrc.toString().split(',')[1]
              values.profilePhotoMimeType = data
              values.photoUri = imgSrc */
            }}
          />
        )}
      </Grid>
    </Box>
  )
}
