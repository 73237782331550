import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloImaging } from 'models/WelloConditions'

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getImagingRequestsOfAppointment } from 'redux/consultation/imaging_diagnostics_requests_list/imagingDiagnosticsListSlice'

import { RootState } from 'redux/rootReducer'
import { getImagingCategory } from 'utils/sopaNotes/plan/imagingPlanHelpers'
import { LabDiagnosticTabularForScreen } from '../assessment/labTestTabularDisplayForScreen'
import { LabDiagnosticTabular } from '../assessment/labTestTabularTile'
import { EditImagingTest } from '../edition/editImaging'
import { ImagingDiagnosticTile } from './imagingDiagnosticsTile'

interface Props {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
  followUp?: boolean
}

export const ImagingDiagnosticsList: React.FC<Props> = ({
  fhirAppointmentDetails,
  split,
  followUp,
}: Props) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const imagingDiagnosticsListSlice = useSelector(
    (state: RootState) => state.imagingDiagnosticsListSlice
  )
  const [showEdit, setShowEdit] = React.useState<boolean>(false)
  const [currentCondition, setCurrentCondition] = useState<WelloImaging>({
    id: _.random(1, 10000000).toString(),
  })

  useEffect(() => {
    dispatch(getImagingRequestsOfAppointment(fhirAppointmentDetails, followUp))
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(imagingDiagnosticsListSlice.updatingImpressions ||
        imagingDiagnosticsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}

      {/* {imagingDiagnosticsListSlice.noResultsAvailable && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            No data available
          </Typography>
        </Box>
      )} */}

      {imagingDiagnosticsListSlice.serviceRequest &&
        imagingDiagnosticsListSlice.serviceRequest.length > 0 && (
          <Box display='flex' flexDirection='column' width='100%'>
            <LabDiagnosticTabularForScreen
              referrals={imagingDiagnosticsListSlice.serviceRequest}
              type='imaging'
              onEditClicked={(insData: R4.IServiceRequest) => {
                setCurrentCondition({
                  ...currentCondition,
                  existingObs: insData,
                  category: getImagingCategory(insData),
                  coding: insData.code
                    ? insData.code.coding && insData.code.coding.length > 0
                      ? insData.code.coding
                      : undefined
                    : undefined,
                })

                setShowEdit(true)
              }}
              split={split}
            />
            {/* {imagingDiagnosticsListSlice.serviceRequest.map(
              (e: R4.IServiceRequest) => (
                <Box key={e.id ?? ''}>
                  <ImagingDiagnosticTile
                    referrals={e}
                    key={`${e.id ?? 't'}_${e.id ?? 's'}`}
                  />
                </Box>
              )
            )} */}
          </Box>
        )}

      {showEdit && (
        <EditImagingTest
          open={showEdit}
          patientReference={{
            reference: `Patient/${fhirAppointmentDetails.patient.id}`,
          }}
          encounterReference={{
            reference: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
          }}
          onClose={() => {
            dispatch(getImagingRequestsOfAppointment(fhirAppointmentDetails))
            setShowEdit(false)
          }}
          onLabDiagnosticsAdded={() => {
            dispatch(getImagingRequestsOfAppointment(fhirAppointmentDetails))
            setShowEdit(false)
          }}
          existingImaging={currentCondition}
          appointmentId={fhirAppointmentDetails.appointment.id!}
        />
      )}
    </Box>
  )
}
