import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Tooltip,
  Link,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import moment from 'moment'

import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestAppetiteHistoryOfPatient } from 'redux/ipd/patientMedicalHistory/appetiteHistory/appetiteSearchSlice'
import { requestDietHistoryOfPatient } from 'redux/ipd/patientMedicalHistory/dietHistory/dietHistorySlice'
import { requestFamilyConditionHistoryOfPatient } from 'redux/patientMedicalHistory/familyConditionHistory/familyMedicalConditionsHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasAddedObsBySameDoc,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getAddButoonShow,
  getObservationCodeText,
  getObservationContent,
  getRelationFromFamilyResource,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  HistoryObjectiveIndicative,
  SOAPIndicativeElement,
} from 'wello-web-components'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { getValueRefValueExtensionsOfUrl } from 'utils/fhirResourcesHelper'
import { WelloSubjective } from 'models/WelloConditions'
import _ from 'lodash'
import { EditAppetiteOPD } from 'views/components/consultation/edition/editAppetitOPD'
import { AddAppetiteOPD } from './addAppetiteOPD'
import { HistoryTabularFormatTile } from '../../historyTabularFormatTile'
// import { AddFamilyMedicalCondtionHandler } from '../addFamilyHistory'
// import { AddMedicalConditionHandler } from '../addition/addMedicalConditions'
// import { AddDiet } from './diet/AddDiet'

interface DietProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  status: string
  splitView: boolean
}

export const AppetiteOPD: React.FC<DietProps> = ({
  fhirAppointmentDetails,
  status,
  splitView,
}: DietProps) => {
  const { t } = useTranslation(['common'])
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const appetiteSearchSlice = useSelector(
    (state: RootState) => state.appetiteSearchSlice
  )
  const [currentCondition, setCurrentCondition] = useState<WelloSubjective>({
    id: _.random(1, 10000000).toString(),
  })
  const [showEdit, setShowEdit] = useState<boolean>(false)

  function createUpdateConditionData(conditionData: R4.IObservation) {
    setCurrentCondition({
      ...currentCondition,
      condition: conditionData.valueCodeableConcept
        ? conditionData.valueCodeableConcept.coding &&
          conditionData.valueCodeableConcept.coding!.length > 0
          ? conditionData.valueCodeableConcept.coding[0]
          : undefined
        : undefined,

      existingObs: conditionData,
      notes: getNotesString(conditionData.note),
    })
  }
  useEffect(() => {
    dispatch(
      requestAppetiteHistoryOfPatient(
        '',
        fhirAppointmentDetails.patient,
        splitView
      )
    )
  }, [dispatch, fhirAppointmentDetails])
  return (
    <>
      <Box display='flex' flexDirection='column' flexGrow width='100%'>
        <Box display='flex' flexDirection='row' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Appetite</Typography>
          </Box>
          {/* {isMedicalServiceProvider() && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
        {appetiteSearchSlice.noResultsAvailable && (
          <Box>
            {isMedicalServiceProvider() && !splitView && (
              <Box
                display='flex'
                flexGrow
                flexDirection='row'
                justifyContent='flex-start'
                paddingBottom={1}
              >
                <Tooltip title='' id='app_1'>
                  <IconButton
                    aria-label='btn_ord_cancel'
                    color='primary'
                    id='app_2'
                    onClick={() => {
                      setOpenAddDialogue(true)
                    }}
                    style={{ padding: 0 }}
                  >
                    <Typography
                      variant='subtitle2'
                      color='primary'
                      component={Link}
                      style={{ fontSize: 13 }}
                      id='app_4'
                    >
                      {' '}
                      Appetite
                    </Typography>
                    <AddCircleIcon
                      style={{ height: '22px', padding: 0 }}
                      color='primary'
                      id='app_3'
                    />{' '}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        )}
        {appetiteSearchSlice.resultsAvailable &&
          appetiteSearchSlice.groupedObs && (
            <Box>
              {isMedicalServiceProvider() &&
                !splitView &&
                getAddButoonShow(
                  appetiteSearchSlice.groupedObs ?? [],
                  fhirAppointmentDetails.appointment.id!
                ) === false && (
                  <Box
                    display='flex'
                    flexGrow
                    flexDirection='row'
                    justifyContent='flex-start'
                    paddingBottom={1}
                  >
                    <Tooltip title='' id='app_1'>
                      <IconButton
                        aria-label='btn_ord_cancel'
                        color='primary'
                        id='app_2'
                        onClick={() => {
                          setOpenAddDialogue(true)
                        }}
                        style={{ padding: 0 }}
                      >
                        <Typography
                          variant='subtitle2'
                          color='primary'
                          component={Link}
                          style={{ fontSize: 13 }}
                          id='app_4'
                        >
                          {' '}
                          Appetite
                        </Typography>
                        <AddCircleIcon
                          style={{ height: '22px', padding: 0 }}
                          color='primary'
                          id='app_3'
                        />{' '}
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
            </Box>
          )}

        {appetiteSearchSlice.noResultsAvailable && (
          <Box display='flex' flexDirection='row' flexGrow width='100%'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {!isMedicalServiceProvider() ||
              status === 'completed' ||
              splitView
                ? 'No data available'
                : ''}{' '}
            </Typography>
          </Box>
        )}

        {appetiteSearchSlice.searchingConditions && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <CircularProgress size={15} />
          </Box>
        )}
        {appetiteSearchSlice.errorWhileSearchingProcedures && (
          <Box display='flex' flexDirection='row' flexGrow width='100%'>
            <Typography
              variant='subtitle2'
              color='error'
              style={{
                fontWeight: 400,
              }}
            >
              Error while fetching Appetite
            </Typography>
          </Box>
        )}

        {appetiteSearchSlice.resultsAvailable &&
          appetiteSearchSlice.groupedObs && (
            <Box flexGrow width='100%' display='flex' flexDirection='row'>
              <Box display='flex' flexDirection='column' width='100%' flexGrow>
                {appetiteSearchSlice.groupedObs.map((val) => (
                  <Box py={1} key={val.date ?? ''}>
                    <Box
                      paddingX={1}
                      borderRadius={2}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow={1}
                        alignItems='center'
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Recorded on{' '}
                          {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                        </Typography>
                      </Box>
                      {hasAddedObsBySameDoc(val.occupation[0]) &&
                        getValueRefValueExtensionsOfUrl(
                          val.occupation[0].extension ?? [],
                          'http://hl7.org/fhir/StructureDefinition/event-partOf'
                        ).split('/')[1] ===
                          fhirAppointmentDetails.appointment.id! &&
                        splitView === false && (
                          <Box
                            display='flex'
                            justifyContent='flex-end'
                            paddingLeft={1}
                            paddingTop={1}
                            paddingRight={2}
                          >
                            <Tooltip title='Edit' id='chief_tool_edit'>
                              <IconButton
                                aria-label='btn_ord_cancel'
                                color='primary'
                                id='appetite_tool_edit_button'
                                style={{ padding: 0 }}
                                onClick={() => {
                                  createUpdateConditionData(val.occupation[0])
                                  setShowEdit(true)
                                }}
                              >
                                <img
                                  id='chief_tool_edit_img'
                                  src={`${process.env.PUBLIC_URL}/editVector.png`}
                                  alt='Edit'
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                    </Box>
                    <HistoryTabularFormatTile
                      obsData={val.occupation}
                      type='Appetite'
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
      </Box>

      <AddAppetiteOPD
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onDietAdd={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestAppetiteHistoryOfPatient(
              '',
              fhirAppointmentDetails.patient,
              false
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />
      <EditAppetiteOPD
        open={showEdit}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onDietAdd={() => {
          setShowEdit(false)
          dispatch(
            requestAppetiteHistoryOfPatient(
              '',
              fhirAppointmentDetails.patient,
              splitView
            )
          )
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingDiet={currentCondition}
      />
    </>
  )
}
