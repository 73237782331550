import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import {
  addVitalDetails,
  resetAddVitalsState,
} from 'redux/fhirMedicalResources/addVital/addVitalSlice'
import { RootState } from 'redux/rootReducer'
import { cToF, fToC } from 'utils/appointment_handle/vitals_util'
import { WelloLoadingPage } from 'wello-web-components'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onVitalAdded: (createdCondition: R4.IBundle | undefined) => void
}
export const AddVital: React.FC<Props> = ({
  open,
  onClose,
  onVitalAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'observation'])

  const [temp, setTemp] = useState<number>()
  const [pulseRate, setPulseRate] = useState<number>()
  const [systolicBP, setSystolicBP] = useState<number>()
  const [diastolicBP, setDiastolicBP] = useState<number>()
  const [weight, setWeight] = useState<number>()
  const [height, setHeight] = useState<number>()
  const [rr, setRR] = useState<number>()

  const dispatch = useDispatch()

  const addVitalSlice = useSelector((state: RootState) => state.addVitalsSlice)

  function resetDetails() {
    setTemp(undefined)
    setPulseRate(undefined)
    setDiastolicBP(undefined)
    setHeight(undefined)
    setSystolicBP(undefined)
    setWeight(undefined)
    setRR(undefined)
  }

  function vitalsCheck() {
    if (
      temp === undefined &&
      height === undefined &&
      weight === undefined &&
      rr === undefined &&
      temp === undefined &&
      (systolicBP === undefined || diastolicBP === undefined) &&
      pulseRate === undefined
    )
      return true
    return false
  }

  //   useEffect(() => {
  //     if (addVitalSlice.additionSuccessful) {
  //       dispatch(
  //         requestVitalDetailsOfPatient(
  //           fhirAppointmentDetails.patient,
  //           fhirAppointmentDetails.appointment.id!
  //         )
  //       )
  //       resetDetails()
  //       onVitalAdded(addVitalSlice.addedVitalsBundle)
  //     }
  //     resetDetails()
  //   }, [addVitalSlice.additionSuccessful, addVitalSlice, onVitalAdded])

  useEffect(() => {
    if (addVitalSlice.additionSuccessful) {
      onVitalAdded(addVitalSlice.addedVitalsBundle)
      resetDetails()
    }
  }, [addVitalSlice.additionSuccessful, addVitalSlice, onVitalAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
        // dispatch(resetAddVitalsState())
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:add_vitals')}
      </DialogTitle>
      <DialogContent dividers={true}>
        {addVitalSlice.adding && <WelloLoadingPage title='Adding details' />}

        <Box marginY={1}>
          <Grid container direction='column' spacing={1}>
            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                />

                <Grid item xs={4}>
                  <Box paddingLeft={6}>
                    <Typography variant='h6'>Value</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={6}>
                    <Typography variant='h6'>Unit</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'>
                    {t('labelCommon:body_temperature')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='number'
                    id='obj_4'
                    size='small'
                    value={temp}
                    onChange={(event) => {
                      setTemp(
                        parseFloat(parseFloat(event.target.value).toFixed(1))
                      )
                    }}
                    error={(temp! < 76 && temp! > 0) || temp! > 122}
                    // helperText={
                    //   (temp! < 76 && temp! > 0) ||
                    //   temp! > 122 ||
                    //   Number.isNaN(temp)
                    //     ? `Enter between 76 to 122`
                    //     : ''
                    // }
                    InputProps={{
                      inputProps: {
                        max: 122,
                        min: 76,
                        step: 0.1,
                      },
                      //   endAdornment: (
                      //     <InputAdornment position='end'>
                      //       {' '}
                      //       <Typography>{'\u00B0F'}</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>{'\u00B0F'}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'>
                    {' '}
                    {t('labelCommon:pulse_rate')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='obj_5'
                    size='small'
                    type='number'
                    value={pulseRate}
                    onChange={(event) => {
                      setPulseRate(parseInt(event.target.value, 10))
                    }}
                    error={
                      (pulseRate! < 20 && pulseRate! > 0) || pulseRate! > 500
                    }
                    // helperText={
                    //   (pulseRate! < 20 && pulseRate! > 0) ||
                    //   pulseRate! > 500 ||
                    //   Number.isNaN(pulseRate)
                    //     ? `Enter between 20 to 500`
                    //     : ''
                    // }
                    InputProps={{
                      color: 'primary',
                      inputProps: {
                        max: 500,
                        min: 20,
                      },
                      //   endAdornment: (
                      //     <InputAdornment
                      //       position='end'
                      //       style={{
                      //         WebkitAppearance: 'none',
                      //         MozAppearance: 'textfield',
                      //         margin: 0,
                      //       }}
                      //     >
                      //       {' '}
                      //       <Typography>bpm</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>bpm</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'> Height</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='number'
                    id='obj_6'
                    size='small'
                    value={height}
                    onChange={(event) => {
                      setHeight(
                        parseFloat(parseFloat(event.target.value).toFixed(1))
                      )
                    }}
                    error={(height! < 2.5 && height! > 0) || height! > 271}
                    // helperText={
                    //   (height! < 2.5 && height! > 0) ||
                    //   height! >= 271 ||
                    //   Number.isNaN(height)
                    //     ? `Enter between 2.5 to 271`
                    //     : ''
                    // }
                    InputProps={{
                      color: 'primary',
                      inputProps: {
                        max: 271,
                        min: 2.5,
                      },
                      //   endAdornment: (
                      //     <InputAdornment
                      //       position='end'
                      //       style={{
                      //         WebkitAppearance: 'none',
                      //         MozAppearance: 'textfield',
                      //         margin: 0,
                      //       }}
                      //     >
                      //       {' '}
                      //       <Typography>cm</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>cm</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'> Weight</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='obj_7'
                    type='number'
                    size='small'
                    value={weight}
                    onChange={(event) => {
                      setWeight(
                        parseFloat(parseFloat(event.target.value).toFixed(1))
                      )
                    }}
                    error={(weight! < 1 && weight! > 0) || weight! > 300}
                    // helperText={
                    //   (weight! < 1 && weight! > 0) ||
                    //   weight! > 300 ||
                    //   Number.isNaN(weight)
                    //     ? `Enter between 1 to 300`
                    //     : ''
                    // }
                    InputProps={{
                      color: 'primary',
                      inputProps: {
                        max: 300,
                        min: 1,
                        step: 0.1,
                      },

                      //   endAdornment: (
                      //     <InputAdornment
                      //       position='end'
                      //       style={{
                      //         WebkitAppearance: 'none',
                      //         MozAppearance: 'textfield',
                      //         margin: 0,
                      //       }}
                      //     >
                      //       {' '}
                      //       <Typography>kg</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>kg</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'> Respiration Rate</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='number'
                    id='obj_8'
                    value={rr}
                    size='small'
                    onChange={(event) => {
                      setRR(parseInt(event.target.value, 10))
                    }}
                    error={(rr! < 6 && rr! > 0) || rr! > 60}
                    // helperText={
                    //   (rr! < 6 && rr! > 0) || rr! > 60 || Number.isNaN(rr)
                    //     ? `Enter between 6 to 60`
                    //     : ''
                    // }
                    InputProps={{
                      color: 'primary',
                      inputProps: {
                        max: 60,
                        min: 12,
                      },
                      //   endAdornment: (
                      //     <InputAdornment
                      //       position='end'
                      //       style={{
                      //         WebkitAppearance: 'none',
                      //         MozAppearance: 'textfield',
                      //         margin: 0,
                      //       }}
                      //     >
                      //       {' '}
                      //       <Typography>per min</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>per min</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'>
                    {t('labelCommon:blood_pressure')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box display='flex' flexDirection='column'>
                    <Box
                      paddingBottom={1}
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      <TextField
                        variant='outlined'
                        type='number'
                        value={systolicBP}
                        size='small'
                        id='obj_9'
                        label='Sys'
                        onChange={(event) => {
                          if (Number.isNaN(parseInt(event.target.value, 10))) {
                            setSystolicBP(undefined)
                          } else {
                            setSystolicBP(parseInt(event.target.value, 10))
                          }
                        }}
                        error={
                          (systolicBP! < 20 && systolicBP! > 0) ||
                          systolicBP! > 500
                        }
                        // helperText={
                        //   (systolicBP! < 20 && systolicBP! > 0) ||
                        //   systolicBP! > 500 ||
                        //   Number.isNaN(systolicBP)
                        //     ? `Enter between 20 to 500`
                        //     : ''
                        // }
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>mmHg</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                />

                <Grid item xs={4}>
                  <Box display='flex' flexDirection='column'>
                    <TextField
                      variant='outlined'
                      type='number'
                      label='Dia'
                      id='obj_10'
                      size='small'
                      value={diastolicBP}
                      onChange={(event) => {
                        setDiastolicBP(parseInt(event.target.value, 10))
                        if (Number.isNaN(parseInt(event.target.value, 10))) {
                          setDiastolicBP(undefined)
                        } else {
                          setDiastolicBP(parseInt(event.target.value, 10))
                        }
                      }}
                      error={
                        (diastolicBP! < 20 && diastolicBP! > 0) ||
                        diastolicBP! > 500
                      }
                      //   helperText={
                      //     (diastolicBP! < 20 && diastolicBP! > 0) ||
                      //     diastolicBP! > 500 ||
                      //     Number.isNaN(diastolicBP)
                      //       ? `Enter between 20 to 500`
                      //       : ''
                      //   }
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>mmHg</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          width='100%'
          flexDirection='row'
          display='flex'
          justifyContent='flex-end'
          paddingRight={5}
        >
          {addVitalSlice.adding && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          <Button
            onClick={() => {
              onClose()
              dispatch(resetAddVitalsState())
              resetDetails()
            }}
            variant='outlined'
            disableElevation
            id='obj_11'
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              console.log(isNaN(temp!))
              console.log(isNaN(pulseRate!))
              dispatch(
                addVitalDetails(
                  fhirAppointmentDetails,
                  Number(temp),
                  Number(pulseRate),
                  Number(systolicBP),
                  Number(diastolicBP),
                  Number(weight),
                  Number(height),
                  Number(rr)
                )
              )
            }}
            id='obj_12'
            variant='contained'
            color='primary'
            disableElevation
            disabled={
              temp! < 76 ||
              temp! > 122 ||
              pulseRate! < 20 ||
              pulseRate! >= 500 ||
              systolicBP! < 0 ||
              systolicBP! > 500 ||
              diastolicBP! < 0 ||
              diastolicBP! > 500 ||
              rr! < 6 ||
              rr! > 60 ||
              height! < 2.5 ||
              height! > 271 ||
              weight! < 1 ||
              weight! > 300 ||
              addVitalSlice.adding ||
              vitalsCheck()
            }
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
